import { useEffect, useState } from 'react'
import HighchartsStock from 'highcharts/highstock';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';

highchartsMore(HighchartsStock);

// let intervals = [[1308096000000.0, 941.6942592393406, 1978.4192012577882],
//  [1308182400000.0, 1069.803535455331, 2078.2703603575187],
//  [1308268800000.0, 682.3701398388046, 1696.5524470131838],
//  [1308355200000.0, -576.3305505347029, 461.61859328260937],
//  [1308441600000.0, 441.14657545726925, 1450.36555096819],
//  [1308528000000.0, 849.0466320278998, 1907.2109462927717],
//  [1308614400000.0, 1012.1437557419306, 2057.367518755463],
//  [1308700800000.0, 984.549930417783, 1981.568510819348],
//  [1308787200000.0, 1080.7223636511235, 2088.290436611072],
//  [1308873600000.0, 675.6201564207671, 1697.2712653743106],
//  [1308960000000.0, -543.1143871769042, 436.6757598073276],
//  [1309046400000.0, 480.0530470439095, 1546.0630909621923],
//  [1309132800000.0, 876.7848622864085, 1907.2455491589737],
//  [1309219200000.0, 1026.6090720315165, 2053.8317298844354],
//  [1309305600000.0, 965.8532125632236, 1981.5952309483796],
//  [1309392000000.0, 1093.7919200071228, 2112.4314594985804],
//  [1309478400000.0, 706.1290360853657, 1745.1476441012276],
//  [1309564800000.0, -549.0138831817119, 488.5094102919634],
//  [1309651200000.0, 488.8959273476864, 1520.5271375478649],
//  [1309737600000.0, 870.2543920987114, 1938.643853286464],
//  [1309824000000.0, 1064.1312947445786, 2078.6028731158513],
//  [1309910400000.0, 961.253888462716, 1999.6820380075333],
//  [1309996800000.0, 1070.7013418321235, 2082.9618203552636],
//  [1310083200000.0, 663.1168569683504, 1707.94505571389],
//  [1310169600000.0, -523.1389189179775, 492.3815542833582],
//  [1310256000000.0, 509.5002233597736, 1540.454097838801],
//  [1310342400000.0, 909.3121680772413, 1954.7890432126794],
//  [1310428800000.0, 1037.9170715123405, 2067.981660544707],
//  [1310515200000.0, 971.0305036865277, 2071.37227104031],
//  [1310601600000.0, 1096.14865796487, 2133.9759317437906],
//  [1310688000000.0, 701.6911723017189, 1758.4338771067187],
//  [1310774400000.0, -565.9774872292871, 496.5145736627579],
//  [1310860800000.0, 525.671525329367, 1535.2154209471666],
//  [1310947200000.0, 901.57136526374, 1944.9541912872105],
//  [1311033600000.0, 1017.1617507850495, 2090.186049843268],
//  [1311120000000.0, 990.8675097635247, 2034.5876755837892],
//  [1311206400000.0, 1083.1289008454082, 2149.781694332779],
//  [1311292800000.0, 744.1811548309248, 1764.3368450564565],
//  [1311379200000.0, -475.99038471326594, 498.45927460754524],
//  [1311465600000.0, 508.88921125666815, 1525.4600524238524],
//  [1311552000000.0, 917.8931172647887, 1972.0155192963173],
//  [1311638400000.0, 1043.231086780853, 2123.247587779481],
//  [1311724800000.0, 1048.5926323049919, 2079.6536399270935],
//  [1311811200000.0, 1100.888439246195, 2181.9210074191406],
//  [1311897600000.0, 773.6037090760987, 1783.9210071666703],
//  [1311984000000.0, -538.2790118591421, 492.75342207278817],
//  [1312070400000.0, 545.2580860937649, 1563.0294538530104],
//  [1312156800000.0, 935.6079346049345, 1971.4158811926684],
//  [1312243200000.0, 1048.3558971116006, 2107.4822631171837],
//  [1312329600000.0, 1077.4243682506262, 2041.6083234058424],
//  [1312416000000.0, 1097.975104348245, 2151.858937497808],
//  [1312502400000.0, 695.1962185446497, 1758.451185033571],
//  [1312588800000.0, -478.6947395303216, 554.620668979971],
//  [1312675200000.0, 517.2620941658554, 1587.0334046019054],
//  [1312761600000.0, 970.5325013973176, 1992.8971419742163],
//  [1312848000000.0, 1081.3941204346725, 2126.7424637034273],
//  [1312934400000.0, 1058.5491651765187, 2052.8397434615886],
//  [1313020800000.0, 1149.638147736855, 2188.0029655658727],
//  [1313107200000.0, 752.4359132571727, 1785.9661445899435],
//  [1313193600000.0, -523.9179227109954, 522.8936599314297],
//  [1313280000000.0, 554.5071992040832, 1562.6143846528548],
//  [1313366400000.0, 967.7933804873795, 1976.5513913138402],
//  [1313452800000.0, 1106.4710497339495, 2124.6696535809156],
//  [1313539200000.0, 1069.7869474332574, 2087.2724375887465],
//  [1313625600000.0, 1155.7646151056404, 2191.6216921515243],
//  [1313712000000.0, 728.9554820469788, 1777.5706221391658],
//  [1313798400000.0, -472.94749625465164, 572.5049743218467],
//  [1313884800000.0, 554.1392605286171, 1578.8466305079653],
//  [1313971200000.0, 960.9788767132231, 1961.1801630637995],
//  [1314057600000.0, 1097.1996603846087, 2158.263377599053],
//  [1314144000000.0, 1063.3386846154863, 2061.615439361482],
//  [1314230400000.0, 1148.6449535110416, 2161.2915685120524],
//  [1314316800000.0, 743.1280401690399, 1809.3964136364814],
//  [1314403200000.0, -483.74318832793364, 570.2904914765995],
//  [1314489600000.0, 551.2023379449624, 1640.241647241178],
//  [1314576000000.0, 1013.392612611474, 1992.7392100665436],
//  [1314662400000.0, 1111.113030916557, 2162.0056604430065],
//  [1314748800000.0, 1088.3967597549722, 2138.47112520616],
//  [1314835200000.0, 1200.306146365286, 2181.2402977240304],
//  [1314921600000.0, 795.2333241969387, 1847.5996195463167],
//  [1315008000000.0, -474.7458152089699, 542.4918384628545],
//  [1315094400000.0, 574.5396301100283, 1617.0300803790024],
//  [1315180800000.0, 997.2437030769546, 2045.7353211636123],
//  [1315267200000.0, 1157.0933747832246, 2142.9683081286403],
//  [1315353600000.0, 1040.5034626581341, 2103.424551969695],
//  [1315440000000.0, 1165.4011047937963, 2179.4853588759165],
//  [1315526400000.0, 763.1133125152583, 1826.6944455688672],
//  [1315612800000.0, -470.45406215868775, 573.8125309875228],
//  [1315699200000.0, 635.1767872905166, 1651.4189605065171],
//  [1315785600000.0, 977.0626922630171, 2071.338169145983],
//  [1315872000000.0, 1158.0061344772678, 2181.1336270120896],
//  [1315958400000.0, 1117.826788498326, 2101.5073632517583]]

// let values = [[1308096000000.0, 1683],
//  [1308182400000.0, 1503],
//  [1308268800000.0, 942],
//  [1308355200000.0, 0],
//  [1308441600000.0, 1157],
//  [1308528000000.0, 1978],
//  [1308614400000.0, 1453],
//  [1308700800000.0, 1008],
//  [1308787200000.0, 1948],
//  [1308873600000.0, 1024],
//  [1308960000000.0, 0],
//  [1309046400000.0, 667],
//  [1309132800000.0, 1156],
//  [1309219200000.0, 999],
//  [1309305600000.0, 1315],
//  [1309392000000.0, 1609],
//  [1309478400000.0, 978],
//  [1309564800000.0, 0],
//  [1309651200000.0, 598],
//  [1309737600000.0, 2185],
//  [1309824000000.0, 2175],
//  [1309910400000.0, 1795],
//  [1309996800000.0, 1896],
//  [1310083200000.0, 1579],
//  [1310169600000.0, 0],
//  [1310256000000.0, 815],
//  [1310342400000.0, 1490],
//  [1310428800000.0, 1646],
//  [1310515200000.0, 1610],
//  [1310601600000.0, 1663],
//  [1310688000000.0, 1118],
//  [1310774400000.0, 0],
//  [1310860800000.0, 1246],
//  [1310947200000.0, 2167],
//  [1311033600000.0, 1576],
//  [1311120000000.0, 1993],
//  [1311206400000.0, 1494],
//  [1311292800000.0, 1281],
//  [1311379200000.0, 0],
//  [1311465600000.0, 1116],
//  [1311552000000.0, 1935],
//  [1311638400000.0, 1233],
//  [1311724800000.0, 1228],
//  [1311811200000.0, 1510],
//  [1311897600000.0, 1119],
//  [1311984000000.0, 0],
//  [1312070400000.0, 1270],
//  [1312156800000.0, 1189],
//  [1312243200000.0, 1332],
//  [1312329600000.0, 1521],
//  [1312416000000.0, 1782],
//  [1312502400000.0, 1442],
//  [1312588800000.0, 0],
//  [1312675200000.0, 529],
//  [1312761600000.0, 1455],
//  [1312848000000.0, 1087],
//  [1312934400000.0, 1360],
//  [1313020800000.0, 1935],
//  [1313107200000.0, 1113],
//  [1313193600000.0, 0],
//  [1313280000000.0, 538],
//  [1313366400000.0, 918],
//  [1313452800000.0, 1022],
//  [1313539200000.0, 1651],
//  [1313625600000.0, 1487],
//  [1313712000000.0, 811],
//  [1313798400000.0, 0],
//  [1313884800000.0, 1065],
//  [1313971200000.0, 1267],
//  [1314057600000.0, 1434],
//  [1314144000000.0, 1820],
//  [1314230400000.0, 1291],
//  [1314316800000.0, 925],
//  [1314403200000.0, 0],
//  [1314489600000.0, 1196],
//  [1314576000000.0, 0],
//  [1314662400000.0, 3218],
//  [1314748800000.0, 1176],
//  [1314835200000.0, 1399],
//  [1314921600000.0, 2333],
//  [1315008000000.0, 0],
//  [1315094400000.0, 1333],
//  [1315180800000.0, 1569],
//  [1315267200000.0, 1122],
//  [1315353600000.0, 2080],
//  [1315440000000.0, 1750],
//  [1315526400000.0, 1558],
//  [1315612800000.0, 0],
//  [1315699200000.0, 2027],
//  [1315785600000.0, 1638],
//  [1315872000000.0, 2428],
//  [1315958400000.0, 1333]];


function findAnomalyZones(intervals, values) {
    let validColor = 'rgba(96, 165, 250,0.3)', anomalyColor = '#dc2626';
    let zones = Array();
    let prev = null;
    let anomalyType = null; // 1 for above Confidence interval. -1 for below #60a5fa
    for (let i = 0; i < values.length; i++) {
        let interval = intervals[i], value = values[i];
      let zone = {value: value[0]};
  
      // point is an anomaly	
      if (value[1] < interval[1]) {
        anomalyType = -1;
        zone.color = anomalyColor;
      }
      else if (value[1] > interval[2]) {
              anomalyType = 1;
              zone.color = anomalyColor;
      }
      else {
          zone.color = validColor;
      }
      
      // Push prev zone if colors should be different
      // Update prev zone
        if (prev != null && prev.color != zone.color) {
          const interIdx = anomalyType == 1 ? 2 : 1;
          let {m: m1, b: b1} = findSlopeAndYIntercept([intervals[i-1][0], intervals[i-1][interIdx]], [interval[0], [interval[interIdx]]]);
          let {m: m2, b: b2} = findSlopeAndYIntercept(values[i-1], value);
          let {x, y} = findLineIntercept(m1, b1, m2, b2);
          
          prev.value = x
            zones.push(prev);
        }
      prev = zone;
    }
    
    zones.push({value: Date.UTC(9999), color: validColor})
    return zones
  }
  
  function findSlopeAndYIntercept(p1, p2) {
      const m = (p2[1] - p1[1]) / (p2[0] - p1[0]);
    const b = p1[1] - (m * p1[0])
    return {m, b}
  }
  
  function findLineIntercept(m1, b1, m2, b2) {
      let x = (b2 - b1) / (m1 - m2);
    let y = (m1 * x) + b1
    return {x, y}
  }


//  let zones = findAnomalyZones(intervals, values)

function AreaRangeLineChart({intervals, values, chartHeight}) {
    const isDark = useRecoilValue(darkModeState);
    const [chartOptions, setChartOptions] = useState({})

      useEffect(() => {
        let zones = findAnomalyZones(intervals, values)
        setChartOptions({
          chart: {
            zoomType: 'x',
            height: chartHeight,
            backgroundColor: 'transparent',
        },
        time: {
          useUTC: false
        },
        credits : { enabled : false},
        title: {
            text: null
          },
        tooltip: {
            crosshairs: true,
            shared: true,
            valueSuffix: null,
            xDateFormat: '%H:%M:%S - %A, %b %e, %Y',
          },
        xAxis: {
            type: 'datetime',
        },
        yAxis: {
            // title: {
            //     text: 'KPI',
            //   },
            lineWidth: 0,
            minorGridLineWidth: 0,
            lineColor: 'transparent',
            // labels: {
            //     enabled: false
            // },
            // min:0,
            minorTickLength: 0,
            tickLength: 0,
            gridLineWidth: 0,
        },
        legend: { enabled: false},
        // navigator : { enabled : false},
        navigator: {
            height: 20
        },
        scrollbar : { enabled : false},
        rangeSelector: {
          allButtonsEnabled: true,
          selected: 3,
          buttons: [
            {
              type: 'hour',
              count: 1,
              text: 'Hours',
              dataGrouping: {
                  forced: true,
                  units: [['hour', [1]]]
              }
            },{
            type: 'month',
            count: 1,
            text: 'Day',
            dataGrouping: {
                forced: true,
                units: [['day', [1]]]
            }
          },  {
              type: 'month',
              count: 3,
              text: 'Week',
              dataGrouping: {
                  forced: true,
                  units: [['week', [1]]]
              }
          }, {
              type: 'all',
              text: 'Month',
              dataGrouping: {
                  forced: true,
                  units: [['month', [1]]]
              }
          }],
          buttonTheme: { // styles for the buttons
            width: 60,
            fill: 'none',
            stroke: 'none',
            'stroke-width': 0,
            r: 8,
            style: {
              color: '#2563eb',
              fontWeight: 'bold'
            },
            states: {
              hover: {
              fill: '#94a3b8',
              },
              select: {
                fill: '#2563eb',
                style: {
                  color: 'white'
                }
              },
              disabled: { 
                // fill: '#2563eb',
                style: {
                  color: '#d1d5db',
                }
              }
            }
          },
          inputStyle: {
            color: '#9ca3af',
            fontWeight: 'bold'
          },
          labelStyle: {
            color: '#6b7280',
            fontWeight: 'bold'
          },

        },
        // plotOptions: {
        //     area: {
        //         fillColor: {
        //             linearGradient: {
        //                 x1: 0,
        //                 y1: 0,
        //                 x2: 0,
        //                 y2: 1
        //             },
        //             stops: [
        //                 [0, Highcharts.getOptions().colors[0]],
        //                 [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
        //             ]
        //         },
        //         marker: {
        //             radius: 2
        //         },
        //         lineWidth: 1,
        //         states: {
        //             hover: {
        //                 lineWidth: 1
        //             }
        //         },
        //         threshold: null
        //     }
        // },
        plotOptions: {
          series: {
            dataGrouping: {
              enabled: false
            },
          },
          dataSorting: {
            enabled: true,
            sortKey: 'x'
          },
        },
        series: [{
            zoneAxis: 'x',
            zones: zones,
            data: values,
            cropThreshold: 10000,
            zIndex: 1,
            // color:'rgba(147, 197, 253, 0.4)',
            color: '#FF0000',
            tooltip: {
              pointFormatter: function() {
                let yFormatted = this.y
                if(this.y && this.y.toFixed(3)){
                  yFormatted = this.y.toFixed(3)
                }
                return 'Data Point: <strong>' + yFormatted + '<strong>' ;
              }
            },
            marker: {
                // enabled: false,
                fillColor: 'white',
                lineWidth: 1,
                lineColor: 'grey',
                // enabled: true,
            },
            },
             {
            name: 'Range',
            data: intervals,
            cropThreshold: 10000,
            type: 'arearange',
            lineWidth: 0,
            linkedTo: ':previous',
            fillOpacity: isDark ? 0.2 : 0.3,
            zIndex: 0,
            color: '#93c5fd',
            tooltip: {
              pointFormatter: function() {
                let lowPoint = this.low
                let highPoint = this.high
                if(this.low && this.low.toFixed(3)){
                  lowPoint = this.low.toFixed(3)
                }
                if(this.high && this.high.toFixed(3)){
                  highPoint = this.high.toFixed(3)
                }
                return 'Range: <strong>' + lowPoint + ' - ' + highPoint + '</strong>' ;
              }
            },
            marker: {
                // enabled: false,
                fillColor: 'grey',
            }
          }
        ],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
            }]
        }
        })

      }, [intervals, values])


  return (
    <div>
        <HighchartsReact
            highcharts={HighchartsStock}
            constructorType={'stockChart'}
            options={chartOptions}
        />
    </div>
  )
}

export default AreaRangeLineChart