import React, { useEffect, useRef, useState } from 'react'
import { formatRelativeTime } from '../../utils/DateFormating';
import { formatFileSize } from '../../utils/FileFormating';
import { darkModeState } from '../../atoms/darkModeState';
import { motion } from 'framer-motion';
import { useRecoilValue } from 'recoil';
import { getData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import VoiceMessageForVoicePage from '../messagingComponent/utils/VoiceMessageForVoicePage';

function VoicePageRow({message, userInfo}) {
    const isDark = useRecoilValue(darkModeState)
    const [audioUrl, setAudioUrl] = useState(null)
    const [loadingURL, setLoadingURL] = useState(false)
    const [isLoadingFile, setIsLoadingFile] = useState(false)


    const handleDownloadVoice = (message) => {
        setLoadingURL(true);
        try {
            getData(apiLink + '/v1/files/' + message.file_uuid + '/download_request' )
            .then(async response => {
                const data = await response.json();
                if(response.ok ){
                    // console.log('Voice data', data)
                if(data && data.data && data.data.signed_url){
                    setAudioUrl(data.data.signed_url)
                    setLoadingURL(false);
                    
                }
                }
            })
        } catch (error) {
            toast.error('Error downloading file:', error);
        } 
    };


  return (
    <div >
        {(audioUrl && !loadingURL) ? (
            <VoiceMessageForVoicePage audioUrl={audioUrl} message={message} userInfo={userInfo} isLoadingFile={isLoadingFile} />
        ):(
            <div className='flex  w-full px-2 pt-1'>
            <motion.button 
                whileHover={{ scale: 1.05  }} 
                whileTap={{ scale: 0.98  }} 
                className={`h-10 w-10 items-center justify-center  rounded-full bg-slate-500 dark:bg-slate-200 text-slate-100 dark:text-sky-800 text-base`} 
                onClick={() => handleDownloadVoice(message)}
                > 
                {loadingURL ?(
                <svg className={`animate-spin w-10 h-10 `} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                
                ):(
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mx-auto">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
                    </svg>

                )}
            </motion.button>
            <div className={`pl-2  flex flex-col space-y-0.5 py-1 border-b-[0.01em] ${isDark ? 'border-slate-600':' border-slate-100 '} grow`}>
                <h2 className={`text-sm  ${isDark ? 'text-gray-300':' text-gray-600 '} `}>
                    {message.user_first_name? message.user_first_name : ''}{` `}{message.user_last_name? message.user_last_name : ''}
                </h2>
                <div className='flex items-center '>
                    <span className={`text-xs font-light ${isDark ? 'text-gray-400':' text-gray-500 '}`}>
                        {formatFileSize(message.file_size)} - {formatRelativeTime(message.message_created_at)}
                    </span>
                </div>
            </div>
            </div>

        )}
        {/* {audioUrl && !loadingURL &&
            <VoiceMessageForVoicePage audioUrl={audioUrl} message={message} userInfo={userInfo} isLoadingFile={isLoadingFile} />
        } */}
    </div>
  )
}

export default VoicePageRow

