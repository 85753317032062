import React, { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { getData } from '../ApiCalls/DataApis';
import { apiLink } from '../ApiCalls/ApisVariables';
import { globalSelectedOrganization } from '../atoms/orgStates';
import { useRecoilValue } from 'recoil';
import TeamsTableRow from './TeamsTableRow';
import CreateNewTeamModal from '../components/modals/teamsModals/CreateNewTeamModal';

function TeamsPage({userInfo}) {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [teamsList, setTeamsList] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const [isMobile, setIsMobile] = useState(false)
    let location = useLocation();

    useEffect(() => {
        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
          setIsMobile(true)
        }else{
          setIsMobile(false)
        }
      },[location])


    useEffect(() => {
        updatedTeamsList()
      },[selectedOrganization])

    const updatedTeamsList = () => {
        try {
            if(selectedOrganization){
              getData(apiLink + '/v1/organizations/' + selectedOrganization + '/teams')
              .then(async response => {
                if(response.ok ){
                  const data = await response.json();
                  if(data && data.data){
                    // console.log('teams', data.data)
                    setTeamsList(data.data)
                  }
                }
              }) 
            }
          } catch (error) {
            console.log(error.message)
          }
    }


  return (
    <div>
        <div className='flex justify-end'>
            <CreateNewTeamModal btnType='standardBtn' userInfo={userInfo} updatedTeamsList={updatedTeamsList} />

        </div>
        <div className='overflow-x-auto'>
            <table className="min-w-full table-auto ">
            <thead>
            <tr className='border-b-[0.01em] dark:border-zinc-600 text-left'>
                <th className="text-sm font-light text-gray-500 dark:text-gray-400 p-2 w-40 text-left bg-white dark:bg-slate-900">Name</th>
                <th className="text-sm font-light text-gray-500 dark:text-gray-400 px-4 py-2 text-left">Handle</th>
                <th className="text-sm font-light text-gray-500 dark:text-gray-400 p-2 text-left">Members</th>
            </tr>
            </thead>
            <tbody>
            {teamsList.map((currentTeam) => (
                <TeamsTableRow key={currentTeam.team_uuid} currentTeam={currentTeam} selectedOrganization={selectedOrganization} isMobile={isMobile} />
            ))}
            </tbody>
        </table>
        </div>
    </div>
  )
}

export default TeamsPage