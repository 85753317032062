import { useRecoilValue } from "recoil";
import { darkModeState } from "../../atoms/darkModeState";

function StatusBar({statusBarData, barChartCategories}){
  const isDark = useRecoilValue(darkModeState);
  let firstElement = ''
  let lastElement = ''
  if(barChartCategories && barChartCategories.length > 0 &&  barChartCategories[0]){
    firstElement = barChartCategories[0] ;
    lastElement = barChartCategories[barChartCategories.length - 1];
  }
  
    const containerStyles = {
      // height: 24,
      width: '100%',
      // borderRadius: 50,
      // marginTop: 4, 
      // marginLeft:'auto',
      // marginRight:'auto',
      // position:'relative',
      // borderRadius: '0rem 0rem 0.5rem 0.5rem'
    }
  
    const fillerStyles = (index, width, color) => { 
      return{
        height: 8,
        width: width,
        backgroundColor: color ? color : "#94a3b8",
        // borderRadius: 'inherit',
        borderRadius: index === 0 ?'0rem 0rem 0rem 0.5rem' :'0rem 0rem 0rem 0rem',
        textAlign: 'right',
        transition: 'width 1s ease-in-out',
      }
    }
  
    const labelStyles = {
      padding: 5,
      color: 'white',
      fontWeight: 'bold'
    }
    
    const TooltipTest = {
      position:'absolute',
      left:`50%`,
      top:'-25px',
      height: '12px',
      width: `24px`,
      padding: 5,
      // color: 'white',
      fontWeight: 'bold'
    }
  
    
    return (
      <div style={containerStyles} className=" flex flex-col">
        <div className='flex justify-between px-0.5'>
          <div className={`text-xs ${isDark? 'text-gray-400':'text-gray-600'} font-normal`}>{firstElement ? firstElement : ''}</div>
          <div className={`text-xs ${isDark? 'text-gray-400':'text-gray-600'} font-normal`}>{lastElement ? lastElement : ''}</div>
        </div>
        {/* <div  className='flex bg-slate-200 dark:bg-slate-600' >
          {statusBarData.map((status, index) => (
            <div key={index} style={fillerStyles(index, status.amount, status.utilization_state_color)} >
              <span style={labelStyles}/>
            </div>
          ))}
        </div> */}
      </div>
      
    );
  };
  
  export default StatusBar;