

import { Dialog, Transition, DialogTitle, DialogPanel, TransitionChild } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { PlusSmIcon } from "@heroicons/react/solid"
import { useRecoilState, useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { motion, AnimatePresence } from "framer-motion";
import TagInput from '../../forms/TagInput';
import DepartmentInput from '../../forms/DepartmentInput';
import GeneralFormInput from '../../forms/GeneralFormInput';
import {addSensorModalState, addAssetModalState} from '../../../atoms/modalStates'
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import { getData, putData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import { PencilIcon } from "@heroicons/react/solid"
import toast from 'react-hot-toast';
import ImageZoomCrop from '../ImageZoomCrop';
import { Avatar } from '@mui/material';
import { formatDateTime } from '../../../utils/DateFormating';


function ExpandImgMediaModal({message, remoteOpenAssetModal, imageUrl, size, participants, handleDownloadFile}) {
    let [isOpen, setIsOpen] = useState(false)
    const isDark = useRecoilValue(darkModeState);
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)


    useEffect(() => {
      if(remoteOpenAssetModal){
        setIsOpen(true)
      }
    },[remoteOpenAssetModal])


    

    function closeModal() {
      setIsOpen(false)
    }

    function openModal() {
      setIsOpen(true)
    }

    

  return (
    <>
        <button 
            onClick={openModal} 
            className="relative aspect-square overflow-hidden"
            >
            <img  src={imageUrl} className={`object-cover w-full h-full transition-transform duration-300 hover:scale-110`} />
        </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[102]" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-90" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-hidden">
            <div className='flex p-4 xl:p-6'>
                <Avatar
                    sx={{ 
                        // bgcolor: handleUserBGColor(message.user_username), 
                        width: 48, 
                        height: 48 }}
                        alt={participants[message.user_username].user_first_name? participants[message.user_username].user_first_name : ''}
                        src={participants[message.user_username].user_image_url}
                />
                <div className='flex flex-col ml-2'>
                    <span className={`text-gray-300`}>{message.user_first_name}</span>
                    <span className={`text-gray-400 text-sm`}>{formatDateTime(message.message_created_at)}</span>
                
                </div>
                <motion.button
                    whileHover={{ scale:1.05 }} 
                    whileTap={{ scale: 0.95  }}  
                    className={`ml-auto  bg-transparent text-gray-300 `}
                    onClick={() => handleDownloadFile(message)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" viewBox="0 -960 960 960" fill="currentColor">
                        <path d="M480-337q-8 0-15-2.5t-13-8.5L308-492q-12-12-11.5-28t11.5-28q12-12 28.5-12.5T365-549l75 75v-286q0-17 11.5-28.5T480-800q17 0 28.5 11.5T520-760v286l75-75q12-12 28.5-11.5T652-548q11 12 11.5 28T652-492L508-348q-6 6-13 8.5t-15 2.5ZM240-160q-33 0-56.5-23.5T160-240v-80q0-17 11.5-28.5T200-360q17 0 28.5 11.5T240-320v80h480v-80q0-17 11.5-28.5T760-360q17 0 28.5 11.5T800-320v80q0 33-23.5 56.5T720-160H240Z"/>
                    </svg>
                </motion.button>
                <motion.button
                    whileHover={{ scale:1.05 }} 
                    whileTap={{ scale: 0.95  }}  
                    className={`ml-2  bg-transparent  text-gray-300 `}
                    onClick={closeModal}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </motion.button>
            </div>
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className={`w-full h-full flex flex-col transform  shadow-xl transition-all bg-transparent`}>
                    <div className='flex justify-center'>
                        <img  src={imageUrl} className={`max-h-[80vh] max-w-[90vh] rounded-xl`} />
                    </div>

                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default ExpandImgMediaModal