import { formatDateToAmPm, formatDateToDayMonthYear } from '../../utils/DateFormating';
import { Avatar, AvatarGroup } from '@mui/material';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { motion } from 'framer-motion';
import MqttService from '../../core/services/MqttService';
import { createMessagingMQTT } from '../../core/services/MqttMessaging';
import { toast } from 'react-hot-toast';
import { formatFileSize } from '../../utils/FileFormating';
import ProgressCircle from '../../utils/ProgressCircle';
import { removeMessage } from '../../utils/MessageDataSorting';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { getData } from '../../ApiCalls/DataApis';
import SenderMessageComponent from '../messagingComponent/senderComponents/SenderMessageComponent';
import ReceiverMessageComponent from '../messagingComponent/receiverComponents/ReceiverMessageComponent';



function MessageComponent({messages, message, participants, onIntersectionChange, isGroup, userInfo, onRightClick, contextMenu, setMessages, index,lastMessageReadUntilDate,lastMessageReadUntilUuid, messagesRef,goToBottomButtonRef, chatInfo, navigateSearchMessageUuid, setNavigateSearchMessageUuid,navigatePinnedMessageUuid, setNavigatePinnedMessageUuid, setGlobalSearchMessage, processedRef, interactionsEmojis, onReply, onEdit, allEmojis, pinnedMessages, setPinnedMessages}) {
    const isDark = useRecoilValue(darkModeState);
    const [isLoadingFile, setIsLoadingFile] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [showUnreadMsg, setShowUnreadMsg] = useState(false);
    const [shouldStartCheck, setShouldStartCheck] = useState(false);
    

    useEffect(()=>{
        let isLastMessage= index === (Object.keys(messages).length - 1)
        if(!showUnreadMsg && !isLastMessage){
            // console.log('isLastMessage', isLastMessage)
            if( message.message_uuid === chatInfo.last_read_message_uuid){
                // console.log('SHOW UNREAFD')
                setShowUnreadMsg(true)
            }
        }
    },[chatInfo])
    
    var emojiTextSize = 12
    

    if(message && message.message_is_only_emoji_count){
        if(message.message_is_only_emoji_count === 1){
            emojiTextSize = 100
        }else if(message.message_is_only_emoji_count === 2){
            emojiTextSize = 90
        }else if(message.message_is_only_emoji_count === 3){
            emojiTextSize = 80
        }else if(message.message_is_only_emoji_count === 4){
            emojiTextSize = 70
        }else if(message.message_is_only_emoji_count === 5){
            emojiTextSize = 60
        }else if(message.message_is_only_emoji_count === 6){
            emojiTextSize = 50
        }else{
            emojiTextSize = 40
        }
    }


    let styles = {
        marginTop: 2,
    }
    let showImage = true
    let showName = false
    let prevSender = null
    let currentSender = null
    let nextSender = null
    let shouldRoundTopCorner = false
    let shouldRoundBottomCorner = false

    if(message.user_username){
        currentSender = message.user_username        
    }


    let currentIndex = Object.keys(messages).indexOf(message.message_uuid)
    let p_index = Object.keys(messages).indexOf(message.message_uuid) - 1
    let p_message_uuid = Object.keys(messages)[p_index]
    let n_index = Object.keys(messages).indexOf(message.message_uuid) + 1
    let n_message_uuid = Object.keys(messages)[n_index]
    

    if(currentIndex != 0){
        if(currentIndex > 0){
            prevSender = messages[p_message_uuid].user_username
        }
        currentSender = message.user_username
        if(currentSender != prevSender){
            styles = {
                marginTop: 16,
            }  
        }
        
        
    }
    

    var dateString = ""
    let showDate = false
    let prevDate = null
    let currentDate = new Date(message.message_created_at).setHours(0, 0, 0, 0)
    let nextDate = null
    if(currentIndex === 0){
        dateString = message.message_created_at
        showDate = true
        
    }else{
        if(messages && messages[p_message_uuid] && messages[p_message_uuid].message_created_at){
            prevDate = new Date(messages[p_message_uuid].message_created_at).setHours(0, 0, 0, 0); // Set time to midnight
            if(prevDate < currentDate){
                // previous date is older than current date. So add new date to show in messages
                dateString = currentDate
                showDate = true
            }
        }else{
            dateString = message.message_created_at
            showDate = true
        }
    }

    if(messages[n_message_uuid] && messages[n_message_uuid].user_username){
        nextDate = new Date(messages[n_message_uuid].message_created_at).setHours(0, 0, 0, 0)
        nextSender = messages[n_message_uuid].user_username
        if(currentSender == nextSender){
            if(currentDate == nextDate){
                showImage = false
            }else{
                showImage = true
            }
        }
    }

    // checking to see if its the first message or nth messages from the same send. In order to only put the name on the first one
    if(message.user_username != userInfo.user_username){
        if(currentIndex > 0 && messages && messages[p_message_uuid] && messages[p_message_uuid].user_username){
            prevSender = messages[p_message_uuid].user_username
        }

        if(currentDate == nextDate){
            if(currentSender != prevSender){
                showName = true
            }else{
                showName = false
            }
        }else if(prevDate < currentDate){
            if(currentSender == prevSender){
                showName = true
            }
        }else if(prevDate == currentDate){
            if(currentSender != prevSender){
                showName = true
            }else{
                showName = false
            }
        }  
    }

    // I need to check if the previous sender is the same and if they're in the same date 
    if(currentSender == prevSender && prevDate == currentDate){
        shouldRoundTopCorner = true 
    }
    if(currentSender == nextSender && nextDate == currentDate){
        shouldRoundBottomCorner = true 
    }

    
    // useEffect(()=>{
    //     if(navigateSearchMessageUuid){
    //         // when a user searched for a message, this is what will be triggered to navigate to that message
    //         // console.log('scrollToMessage(navigateSearchMessageUuid)', navigateSearchMessageUuid)
    //         // setTimeout(()=>{
    //         //     console.log('setTimeout messageUuid', navigateSearchMessageUuid)
    //         //     scrollToMessage(navigateSearchMessageUuid)
    //         //     setGlobalSearchMessage([]) //This will only be clearned if there was a global search which won't always be the case
    //         //     setNavigateSearchMessageUuid('')
    //         //   },500)
              
    //         // setTimeout(()=>{
    //         //     setNavigateSearchMessageUuid('')
    //         //   },500)
    //     }

    // }, [navigateSearchMessageUuid])

    const checkForNewMessage = useCallback(() => {
        
        return messages[navigateSearchMessageUuid] || null;
    }, [messages, navigateSearchMessageUuid]);

    // Use the periodic check hook
    const newMessage = usePeriodicCheck(checkForNewMessage, 100, 3000, shouldStartCheck)

    useEffect(() => {
        if (newMessage && Object.keys(newMessage).length > 0 && navigateSearchMessageUuid && navigateSearchMessageUuid.length > 0 && !processedRef.current) {
            // Setting this processedRed to true bcause the newMessage kept getting called alot of times duering the usePeriodicCheck
            processedRef.current = true;
            setTimeout(()=>{
                scrollToMessage(navigateSearchMessageUuid)
                setGlobalSearchMessage([]) //This will only be clearned if there was a global search which won't always be the case
                // setNavigateSearchMessageUuid('')
            },300)
            
        }
      }, [newMessage]);

    useEffect(() => {
        if(navigateSearchMessageUuid && navigateSearchMessageUuid.length > 0 ){
            setShouldStartCheck(true)
        }else{
            setShouldStartCheck(false)
        }
        processedRef.current = false;
    }, [navigateSearchMessageUuid]);

    useEffect(() => {
        if(navigatePinnedMessageUuid){
            
            scrollToMessage(navigatePinnedMessageUuid)
            setNavigatePinnedMessageUuid('')
        }
    },[navigatePinnedMessageUuid])



    const scrollToMessage = (messageId) => {
        
        const messageElement = document.getElementById(`${messageId}`);
        const containerElement = document.getElementById('scrollable-element');
        
        if (messageElement && containerElement) {
            const elementRect = messageElement.getBoundingClientRect();
            const containerRect = containerElement.getBoundingClientRect();
            
            // Adding the 75 due to the search bar being in the way whenever a user searches for a message
            // Calculate the scroll position relative to the container
            const scrollToY = elementRect.top - containerRect.top + containerElement.scrollTop - 75;
            
            // Scroll to the calculated position inside the container
            containerElement.scrollTo({ top: scrollToY, behavior: 'smooth'});

            

            
            if(scrollToY > containerElement.scrollTop){
                messageElement.style.backgroundColor = 'rgba(96,165,250,0.2)';
                messageElement.style.borderRadius = '20px';
                messageElement.style.transition = ' 0.5s'; // Add transition effect
                setTimeout(() => {
                    messageElement.style.backgroundColor = 'rgba(96,165,250,0.2)'; // Fade in
                }, 10);
                setTimeout(() => {
                    messageElement.style.backgroundColor = 'rgba(96,165,250,0)'; // Fade out
                }, 1000);

            }

            // Highlight the message after scrolling is complete
            const highlightAfterScroll = () => {
                
                messageElement.style.backgroundColor = 'rgba(96,165,250,0.2)';
                messageElement.style.borderRadius = '20px';
                messageElement.style.transition = ' 0.5s'; // Add transition effect
                setTimeout(() => {
                    messageElement.style.backgroundColor = 'rgba(96,165,250,0.2)'; // Fade in
                }, 10);
                setTimeout(() => {
                    messageElement.style.backgroundColor = 'rgba(96,165,250,0)'; // Fade out
                }, 1000);
            };

            // Execute the highlight function after scrolling is complete
            containerElement.addEventListener('scroll', () => {
                // I am rounding the values because they're not exact at times 
                if (Math.round(containerElement.scrollTop)  === Math.round(scrollToY)) {
                    containerElement.removeEventListener('scroll', highlightAfterScroll); // Remove event listener to prevent multiple executions
                    highlightAfterScroll(); // Execute highlight function
                }
            });
          }
      };




      const updateEmoji = (mess, userInfo, interaction) => {
        let usernamesArray = interaction.usernames

        if (MqttService.isConnected) {
            let msg = {}
            if(usernamesArray.includes(userInfo.user_username)){
                msg = {
                    from_username: userInfo.user_username,
                    action: 'update_emoji_interaction',
                    message_uuid: mess.message_uuid,
                    emoji_codepoints: ''
                }
            }else{
                msg = {
                    from_username: userInfo.user_username,
                    action: 'update_emoji_interaction',
                    message_uuid: mess.message_uuid,
                    emoji_codepoints: interaction.emoji_codepoints
                }
                
    
            }
            msg = createMessagingMQTT(msg);
            if(msg.topic && msg.payload){
                MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
            }
          }else{
           toast.error('Trying to connect')
          }


      }

    

    const handleDownloadFile = async (message) => {
        setIsLoadingFile(true);
        try {
            getData(apiLink + '/v1/files/' + message.file_uuid + '/download_request' )
            .then(async response => {
                const data = await response.json();
                if(response.ok ){
                if(data && data.data && data.data.signed_url){
                    
                    // console.log('data', data.data.signed_url)
                    let url = data.data.signed_url
                    const response = await fetch(url);
                    const blob = await response.blob();
                    const downloadUrl = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.download = message.file_name || 'download'; // Set the download filename
                    link.click();
                    window.URL.revokeObjectURL(downloadUrl); // Clean up the object URL
                    
                }
                }
            })
        } catch (error) {
          toast.error('Error downloading file:', error);
        } finally {
            setIsLoadingFile(false);
        }
    };
   

    const startAtMessage = (inView, entry, messageId) => {
        if ( !isScrolled) {
            setTimeout(() => {
            //   entry.target.scrollIntoView({ behavior: 'instant', block: 'start' });
              setIsScrolled(true);
                // Doing multiple timeouts so make sure any images that are getting loaded don't mess up the position of where the user should start at
                setTimeout(() =>{
                    moveToMessage(messageId)
                }, 100);
                setTimeout(() =>{
                    moveToMessage(messageId)
                }, 300);
                setTimeout(() =>{
                    moveToMessage(messageId)
                }, 500);
                setTimeout(() =>{
                    moveToMessage(messageId)
                }, 1000);

            }, 0);
        }
    };

    const moveToMessage= (uuid) => {

        const messageElement = document.getElementById(`${uuid}`);
        const containerElement = document.getElementById('scrollable-element');

        if (messageElement && containerElement) {
            const elementRect = messageElement.getBoundingClientRect();
            const containerRect = containerElement.getBoundingClientRect();
            
            // Calculate the scroll position relative to the container
            const scrollToY = elementRect.top - containerRect.top + containerElement.scrollTop;
            // Scroll to the calculated position inside the container
            containerElement.scrollTo({ top: scrollToY, behavior: 'instant'});

            // This is the reference for the hover button to show up if the user starts scrolling up towards older messages
            if (messagesRef.current && goToBottomButtonRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = messagesRef.current;
                const isScrolledUp = scrollHeight - scrollTop - clientHeight > 100; // More than 100px from bottom
                
                if(isScrolledUp){
                goToBottomButtonRef.current.style.display = 'block'
                }else{
                goToBottomButtonRef.current.style.display = 'none'
                }
            }
        }
    }

    // console.log('message', message)

  return (
    <InView 
        id={`${message.message_uuid}`}
        as="div" 
        className={`w-full px-4`}
        onChange={(inView, entry) => {
            onIntersectionChange(inView, { target: { getAttribute: () => message.message_created_at } })
            if (navigateSearchMessageUuid == '' && message.message_uuid === lastMessageReadUntilUuid ) {
                startAtMessage(inView, entry, message.message_uuid);
            }
            }
        } 
        data-date={message.message_created_at}
    >
        {showDate &&
            <div className='my-5 text-center text-sm text-gray-500 dark:text-gray-400'>
                {formatDateToDayMonthYear(message.message_created_at)}
            </div>
        }
        {message.user_username === userInfo.user_username ? (
            <SenderMessageComponent 
                message={message}           userInfo={userInfo}                 handleDownloadFile={handleDownloadFile} 
                contextMenu={contextMenu}   styles={styles}                     participants={participants} 
                showImage={showImage}       onRightClick={onRightClick}         
                showName={showName}         scrollToMessage={scrollToMessage}   emojiTextSize={emojiTextSize} 
                messages={messages}         setMessages={setMessages}           isLoadingFile={isLoadingFile} 
                updateEmoji={updateEmoji}   shouldRoundTopCorner={shouldRoundTopCorner}
                shouldRoundBottomCorner={shouldRoundBottomCorner}                isGroup={isGroup} 
                interactionsEmojis={interactionsEmojis}                          allEmojis={allEmojis}
                onReply={onReply}           onEdit={onEdit}                     pinnedMessages={pinnedMessages}      setPinnedMessages={setPinnedMessages}  
                
            />
        ):(
            <ReceiverMessageComponent 
                message={message}           userInfo={userInfo}                 handleDownloadFile={handleDownloadFile} 
                contextMenu={contextMenu}   styles={styles}                     participants={participants} 
                showImage={showImage}       onRightClick={onRightClick}         
                showName={showName}         scrollToMessage={scrollToMessage}   emojiTextSize={emojiTextSize} 
                messages={messages}         setMessages={setMessages}           isLoadingFile={isLoadingFile} 
                updateEmoji={updateEmoji}   shouldRoundBottomCorner={shouldRoundBottomCorner}
                shouldRoundTopCorner={shouldRoundTopCorner}                     isGroup={isGroup} 
                interactionsEmojis={interactionsEmojis}                          allEmojis={allEmojis}
                onReply={onReply}           pinnedMessages={pinnedMessages}      setPinnedMessages={setPinnedMessages}  
            />
        )
        }
        {showUnreadMsg &&  message.message_uuid === chatInfo.last_read_message_uuid && 
            <div className='w-full bg-gray-200/50 dark:bg-gray-600/50 text-gray-500 dark:text-gray-400  text-center rounded-md my-2 text-sm'>Unread Messages</div>
        }
    </InView>
  )
}

export default MessageComponent


// Custom hook for periodic checking
function usePeriodicCheck(checkFunction, interval = 100, timeout = 3000,shouldCheck = false) {
    const [result, setResult] = useState(null);
  
    useEffect(() => {
        if (!shouldCheck) return;
    
        let timeoutId;
        let intervalId;
        let elapsed = 0;
    
        const stopChecking = () => {
          clearTimeout(timeoutId);
          clearInterval(intervalId);
        };
    
        const performCheck = () => {
          const value = checkFunction();
          if (value) {
            setResult(value);
            stopChecking();
          } else if (elapsed >= timeout) {
            setResult(null);
            stopChecking();
          }
          elapsed += interval;
        };
    
        intervalId = setInterval(performCheck, interval);
        timeoutId = setTimeout(stopChecking, timeout);
    
        return stopChecking;
      }, [checkFunction, interval, timeout, shouldCheck]);
    
      return result;
  }
