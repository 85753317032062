import React, { useEffect, useState } from 'react'
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { LightTooltip } from '../../styles/Tooltip';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { getData, putData } from '../../ApiCalls/DataApis';
import toast from 'react-hot-toast';

function PriorityComponent({selectedWorkOrder}) {
    const [isEditing, setIsEditing] = useState(false)   
    const isDark = useRecoilValue(darkModeState); 
    const [loading, setLoading] = useState(false);
    const [workOrderPriority, setWorkOrderPriority] = useState([])
    const [priorities, setPriorities] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState([]);

    useEffect(() => {
        getData(apiLink + '/v1/workorder_priorities' )
        .then(async response => {
            // JSON data parsed by response.json() call
            if(response.ok ){
            const data = await response.json();
                if(data && data.data){
                    // console.log('Priority', data.data)
                  setPriorities(data.data)
                }
            }
        })
    },[])

    useEffect(() => {
        if(selectedWorkOrder && selectedWorkOrder.workorder_uuid){
            if(selectedWorkOrder.workorder_priority && Object.keys(selectedWorkOrder.workorder_priority).length > 0){
                setWorkOrderPriority(selectedWorkOrder.workorder_priority)
                setSelectedPriority(selectedWorkOrder.workorder_priority)
            }
        }

    },[selectedWorkOrder])

    const handleCancel = () => {
        setIsEditing(false)
        setSelectedPriority(selectedWorkOrder.workorder_priority)
        setWorkOrderPriority(selectedWorkOrder.workorder_priority)
    }

    const handleUpdate = () => {

        if(!loading){
            setLoading(true)
            setIsEditing(false)
            setWorkOrderPriority(selectedPriority)
            putData(apiLink + '/v1/workorders/' + selectedWorkOrder.workorder_uuid , 
            {  "workorder_priority_uuid": selectedPriority.workorder_priority_uuid})
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoading(false)
                    if(data && data.data && data.data[0] && data.data[0].workorder_workorder_priority_id == selectedPriority.workorder_priority_id ){
                        // setWorkOrderPriority(selectedPriority)
                    }else{
                        toast.error('Issues updating priority')
                        setSelectedPriority(selectedWorkOrder.workorder_priority)
                        setWorkOrderPriority(selectedWorkOrder.workorder_priority)
                    }
                }
                else{
                    setSelectedPriority(selectedWorkOrder.workorder_priority)
                    setWorkOrderPriority(selectedWorkOrder.workorder_priority)
                    setLoading(false)
                    try{
                        toast.error(data.message)
                    }catch (e){
                        toast.error(e)
                    }
                }
            })
        }

    }


  return (
    <div>
        <div className='flex flex-col group'>
            <div className='flex text-gray-800 dark:text-white mb-2'>
                <span>Priority</span>
                {isEditing ? (
                    <div className='flex ml-1 space-x-2'>
                        <div 
                            className={`shadow rounded-md ml-1 py-1 px-2 border-[1px] border-blue-600 text-xs bg-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white cursor-pointer`} 
                            onClick={handleUpdate}
                        >
                            Save
                        </div>
                        <div 
                            className={`shadow rounded-md py-1 px-2 border-[1px] text-xs ${isDark?'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} cursor-pointer flex ml-2`} 
                            onClick={handleCancel}
                        >
                            Cancel
                        </div>
                    </div>
                ):(
                    <LightTooltip title='Edit priority'>
                        <button 
                            className={`text-gray-500 dark:text-gray-400 hover:text-gray-400 dark:hover:text-gray-500 ml-2 opacity-0 ease-in-out duration-300 group-hover:opacity-100`}
                            onClick={() => setIsEditing(true)}
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                            </svg>

                        </button>
                    </LightTooltip>

                )}
            </div>
            {isEditing ? (
                <div className="flex items-center">
                    <div className={`relative flex items-center ${isDark?'bg-slate-700':'bg-slate-100'}  rounded-full p-0`}>
                    {priorities && Object.keys(priorities).length > 0 && priorities.map((item) => (
                        <button
                        key={item.workorder_priority_name}
                        onClick={() => setSelectedPriority(item)}
                            
                        className={`
                            relative z-[9] p-0 w-[75px] sm:w-[125px] py-0.5 rounded-full transition-colors duration-500  ${isDark?'text-gray-100':'text-gray-600'}
                            ${selectedPriority.workorder_priority_name === item.workorder_priority_name ? item.workorder_priority_color_dark_mode : 'bg-transparent'}
                            focus:outline-none
                        `}
                        >
                        {item.workorder_priority_name} 
                        </button>
                    ))}
                    <div 
                        className={`absolute left-0 w-1/4 h-full ${isDark?'bg-slate-600':'bg-slate-100'} rounded-full shadow-md transition-all duration-300 ease-in-out`}
                        style={{
                        transform: `translateX(${
                            priorities.findIndex(p => p.workorder_priority_name === selectedPriority.workorder_priority_name) * 100
                        }%)`,
                        }}
                    />
                    </div>
                </div>

            ):(
                <div className='relative flex space-x-2 items-center'>
                    <div  className={`${workOrderPriority.workorder_priority_icon_color ? workOrderPriority.workorder_priority_icon_color : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5' viewBox="0 -960 960 960"  fill="currentColor" >
                            <path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-160q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Z"/>
                        </svg>
                    </div>
                    <span className='text-gray-600 dark:text-gray-300 text-lg'>
                        {workOrderPriority.workorder_priority_name}
                    </span>
                    {loading && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-1/4 left-1/2 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                    }
                </div>
            )}
        </div>
    </div>
  )
}

export default PriorityComponent