import React, { useEffect, useState } from 'react'
import { formatFileSize } from '../../../utils/FileFormating'
import { formatDateToAmPm } from '../../../utils/DateFormating'
import InteractionsComponent from '../utils/InteractionsComponent'
import { motion } from 'framer-motion';
import VoiceMessage from '../utils/VoiceMessage';
import { getData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import { Avatar } from '@mui/material';
import DOMPurify from 'dompurify';
import LinkifyIt from 'linkify-it';
import tlds from 'tlds';
import { createHyperlinks } from '../utils/HyperlinkUtils';
import { shouldAddPin } from '../../../utils/messagingUtils';


const linkify = new LinkifyIt();
linkify.tlds(tlds);

function ReceiverVoiceMessageComponent({isMobile, message, scrollToMessage, isGroup,showName, showImage, handleTailCss, participants, userInfo, updateEmoji,handleDownloadFile, isLoadingFile }) {
    const [audioUrl, setAudioUrl] = useState(null)
    const [loadingURL, setLoadingURL] = useState(false)

    let cleanHTML = DOMPurify.sanitize(message.message_text_html || message.message_text); // Sanitize the HTML content
    
    const links = linkify.match(cleanHTML);
    if(links && links.length > 0){
        cleanHTML = createHyperlinks(message.message_text_html);
    }

    // var cleanReplyHTML = ''; // Sanitize the HTML content
    // if(message.message_replied_from && Object.keys(message.message_replied_from).length > 0 && message.message_replied_from.message_uuid){
    //     cleanReplyHTML = DOMPurify.sanitize(message.message_replied_from.message_text);
    // }

    useEffect(() => {
        if(message && message.file_uuid){
            handleDownloadVoice(message)
        }
        

    },[message])

    const handleDownloadVoice = async (message) => {
        setLoadingURL(true);
        try {
            getData(apiLink + '/v1/files/' + message.file_uuid + '/download_request' )
            .then(async response => {
                const data = await response.json();
                if(response.ok ){
                    // console.log('Voice data', data)
                if(data && data.data && data.data.signed_url){
                    setAudioUrl(data.data.signed_url)
                    setLoadingURL(false);
                    // console.log('data', data.data.signed_url)
                    // let url = data.data.signed_url
                    // const response = await fetch(url);
                    // const blob = await response.blob();
                    // const downloadUrl = URL.createObjectURL(blob);
                    // const link = document.createElement('a');
                    // link.href = downloadUrl;
                    // link.download = message.file_name || 'download'; // Set the download filename
                    // link.click();
                    // window.URL.revokeObjectURL(downloadUrl); // Clean up the object URL
                    
                }
                }
            })
        } catch (error) {
          toast.error('Error downloading file:', error);
        } finally {
            setLoadingURL(false);
        }
    };

    return (
    <>
        <div 
            className={` pl-4 pr-4 py-1.5 flex flex-col rounded-2xl min-w-[250px] ${isMobile ? 'max-w-[300px]':'max-w-[500px]'} ml-2 order-2 bg-slate-100 dark:bg-slate-800 text-gray-600 dark:text-gray-300`}
        >
            {isGroup && showName &&
                <p 
                    style={{color: participants && participants[message.user_username] && participants[message.user_username].user_color ? participants[message.user_username].user_color:'', }}
                    className='text-sm'
                >
                    {participants[message.user_username].user_first_name? participants[message.user_username].user_first_name : ''}
                </p>
            }
            {message.message_replied_from && Object.keys(message.message_replied_from).length > 0 && message.message_replied_from.message_uuid &&
                <div className='w-full rounded-xl flex my-1 cursor-pointer text-sm' onClick={() => scrollToMessage(message.message_replied_from.message_uuid)}>
                    <div className={`bg-sky-400 dark:bg-sky-600  w-[3px] min-w-[3px] rounded-l-full`} />
                    <div className={`bg-sky-400/20 dark:bg-sky-600/20  rounded-r-lg pl-2 py-0.5 pr-2 flex flex-col truncate overflow-hidden grow`}>
                        <span className={`text-sky-600 dark:text-sky-500 `}>{`${message.message_replied_from.user_first_name? message.message_replied_from.user_first_name : ''} ${message.message_replied_from.user_last_name ? message.message_replied_from.user_last_name : ''}`}</span>
                        <span className={`text-gray-500 dark:text-gray-400 truncate `} >{message.message_replied_from.message_text}</span>
                        {/* <div
                            className="text-gray-500 dark:text-gray-400 h-5"
                            dangerouslySetInnerHTML={{ __html: cleanReplyHTML }} // Set the HTML content directly
                        /> */}
                    </div>
                </div>
            }
            <div className='flex flex-col w-full pt-1'>
                {audioUrl && !loadingURL &&
                    <VoiceMessage audioUrl={audioUrl} message={message} userInfo={userInfo}  handleDownloadFile={handleDownloadFile} isLoadingFile={isLoadingFile} />
                }
                {/* <span className='text-sm'>{message.message_text}</span> */}
                <div
                    className={`message-content ${isMobile ? ' message-content-mobile ':' message-content-web '}`}
                    dangerouslySetInnerHTML={{ __html: cleanHTML }} // Set the HTML content directly
                />
                <div className='flex justify-between'>
                    <InteractionsComponent message={message} userInfo={userInfo} updateEmoji={updateEmoji} participants={participants} />
                    <span className={`flex text-gray-500 dark:text-gray-400 text-xs ml-auto mt-auto mb-[-5px] min-w-[60px] italic pl-3 py-0.5`}>
                    {shouldAddPin(message, userInfo)}{message.message_is_edited && 'edited  '} {formatDateToAmPm(message.message_created_at)}
                    </span>
                </div>
            </div>

        </div>
        <div className={`${isGroup ? 'w-8 h-8': 'hidden'} order-1 `}>
            {showImage &&
                <Avatar
                    sx={{ 
                        bgcolor: participants && participants[message.user_username] && participants[message.user_username].user_color ? participants[message.user_username].user_color:'',  
                        width: 32, 
                        height: 32 }}
                    style={{
                        background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${participants && participants[message.user_username] && participants[message.user_username].user_color ? participants[message.user_username].user_color:''} 100%)`,
                        }}
                    alt={participants[message.user_username].user_first_name? participants[message.user_username].user_first_name : ''}
                    src={participants[message.user_username].user_image_url}
                />
            }
        </div>
            {showImage && isGroup &&
            <div 
                className={`absolute bottom-[-13px] left-[20px] rotate-[80deg] text-slate-100 dark:text-slate-800`}
                style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
            >
                <svg width="30" height="30" viewBox="0 0 20 10">
                    <path d={`M 10 5 L 10 -5 L 0 -10 Z`} fill="currentColor" />
                </svg>
            </div>
            }
            {showImage && !isGroup &&
            <div 
                className={`absolute bottom-[-13px] left-[-16px] rotate-[80deg] text-slate-100 dark:text-slate-800`}
                style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
                >
                <svg width="30" height="30" viewBox="0 0 20 10">
                    <path d={`M 10 5 L 10 -5 L 0 -10 Z`} fill="currentColor" />
                </svg>
            </div>
            }
    </>
  )
}

export default ReceiverVoiceMessageComponent