import React, { useEffect, useState } from 'react'
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid"
import { getData } from '../../ApiCalls/DataApis'
import { apiLink } from '../../ApiCalls/ApisVariables'
import { globalSelectedOrganization } from '../../atoms/orgStates'
import { useRecoilValue } from 'recoil'
import OptimizeLargeImage from '../../components/OptimizeLargeImage';
import { useLocation, useNavigate } from 'react-router-dom'
import SectionedPieChartSmall from '../../components/charts/AdvancedReportsCharts/SectionedPieChartSmall'
import RanpakStackedBarChartSmall from '../../components/charts/AdvancedReportsCharts/RanpakStackedBarChartSmall'

function AdvanceUsageReportTableRow({asset}) {
    const location = useLocation();
    const navigate = useNavigate();
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)


    const addCommasInNumbers = (num) => {
        if(num){
            const out = num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

            // const out = num.toLocaleString();
    
            return out
        }else{
            return '0'
        }
    }



  return (
    <tr className='w-full overflow-hidden' >
        <td 
            onClick={() => navigate(`/${selectedOrganization}/assets/selected-asset/${asset.asset_uuid}`)}
            className='w-24 cursor-pointer text-sm font-normal bg-slate-50 dark:bg-slate-800 text-gray-600 dark:text-gray-300 p-2 rounded-l-full'
        >
            <div className={`rounded-full bg-white z-10 h-24 w-24 `}>
                <OptimizeLargeImage alt={asset.machine_name} source={asset.asset_custom_picture_url  ? asset.asset_custom_picture_url : asset.machine_picture_url} size='xl' />
            </div>
        </td>
        <td 
            onClick={() => navigate(`/${selectedOrganization}/assets/selected-asset/${asset.asset_uuid}`)}
            className='w-40 cursor-pointer text-sm font-normal bg-slate-50  dark:bg-slate-800 text-gray-600 dark:text-gray-300 p-2'
        >
            <div className='flex flex-col'>
                <p className='text-left truncate text-lg text-gray-600 dark:text-gray-300 font-medium'>{asset.asset_custom_name  ? asset.asset_custom_name :asset.machine_name}</p>
                <p className='text-left truncate text-base text-gray-500 dark:text-gray-400 font-medium'>{asset.asset_custom_identifier  ? asset.asset_custom_identifier :asset.asset_uuid}</p>
                <p className='text-left truncate text-base text-gray-500 dark:text-gray-400 font-normal'>{asset.department_name}</p>
            </div>
        </td>
        <td className='bg-slate-50 dark:bg-slate-800'>
            <RanpakStackedBarChartSmall totalUtilizations={asset.asset_utilizations} /> 
            
        </td>
        {/* <td className='bg-slate-50 dark:bg-slate-800 overflow-hidden items-center justify-center px-1 py-1'>
            <SectionedPieChartSmall downtimePercent={asset.total_downtime_hours} uptimePercent={asset.total_uptime_hours} unknownPercent={asset.total_unknown_hours} totalUtilizationPercent={asset.total_utilization_percentage}/>
        </td> */}
        <td className='bg-slate-50 dark:bg-slate-800 rounded-r-xl pl-1 pr-4 w-40 overflow-x-scroll'>
            <div className='flex flex-col justify-around'>
                <div className='flex flex-col font-medium text-center' >
                    <span className='text-xl text-sky-600 dark:text-sky-500 '>
                        {`${addCommasInNumbers(asset.total_uptime_hours)}`}
                    </span>
                    <span className='text-base text-gray-500 dark:text-gray-400 '>
                        total feet
                    </span>
                </div>
            </div> 
            <div className='flex flex-col justify-around mt-1'>
                <div className='flex flex-col font-medium text-center' >
                    <span className='text-lg text-gray-600 dark:text-gray-300 '>
                        {`${asset.feet_per_interval ? addCommasInNumbers(asset.feet_per_interval):''}`}
                    </span>
                    <span className='text-base text-gray-500 dark:text-gray-400 '>
                    {`${asset.interval_name ? `feet/${asset.interval_name}`:''}`}
                    </span>
                </div>
            </div> 
        </td>
    </tr>
  )
}

export default AdvanceUsageReportTableRow