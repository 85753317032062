import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import HighchartsReact from "highcharts-react-official";
import HighchartsStock from 'highcharts/highstock';
import highchartsMore from "highcharts/highcharts-more.js";
import Highcharts from "highcharts/highcharts.js";
import { darkModeState } from '../../../atoms/darkModeState';

highchartsMore(Highcharts);


function StackedBarChartLargeParts({dateCategories, mainAllData, toggleShowScrap}) {
    const [hourCountBarChartOptions, setHourCountBarChartOptions] = useState({
        credits: {enabled: false},
        chart: {
            type: 'column',
            zoomType: 'x',
            backgroundColor:'transparent',
            // height:'110px',
        },
        time: {
            useUTC: false
          },
        title:{
            text:''
        },
    })
    const isDark = useRecoilValue(darkModeState);

    useEffect(() => {
        setHourCountBarChartOptions({
            credits: {enabled: false},
            chart: {
                type: 'column',
                zoomType: 'x',
                backgroundColor:'transparent',
                // height:'110px',
            },
            time: {
                useUTC: false
            },
            title:{
                text:''
            },
        })
    
        if(mainAllData && Object.keys(mainAllData).length > 0 && dateCategories && Object.keys(dateCategories).length > 0){

            
            let seriesData = []
            if(toggleShowScrap){
                seriesData = processData(mainAllData, true)
                
            }else{
                seriesData = processData(mainAllData, false)
            }

            setHourCountBarChartOptions({
                credits: {enabled: false},
                chart: {
                    type: 'column',
                    zoomType: 'x',
                    backgroundColor:'transparent',
                    // height:'110px',
                },
                time: {
                    useUTC: false
                  },
                title:{
                    text:''
                },
                xAxis: {
                    categories: dateCategories,
                    labels: {
                        style: {
                            color: isDark? '#d1d5db':'#4b5563'
                        }
                    }
                },
                colors:['#2A9DF4','#799fb3', '#187BCD', '#557cad','#5caff7', '#257bdb','#5285c4', '#3b82f6','#7f92b0', '#60a5fa','#70a3db'],
                // colors:['#2A9DF4','#D0EFFF', '#187BCD', '#bfdbfe','#5caff7', '#257bdb','#dbeafe', '#3b82f6','#eff6ff', '#60a5fa','#93c5fd'],
                yAxis: [{
                    visible: false,
                    // max: 10
                }, {
                    visible: false,
                    // max: 10,
                    opposite: true
                }],
                legend: {
                    enabled: false 
                },
                plotOptions: {
                    column: {
                        // stacking: 'normal',
                        backgroundColor:'#F3F4F6',
                        dataLabels: {
                            enabled: true,
                            style: {
                                // fontSize: '18px',
                                color: isDark ? '#fff' :'#fff',
                                // color: isDark ? '#fff' :'rgba(75, 85, 99, 0.5)',
                            },
                        },
                        borderWidth:0
                    },
                    series: {
                        borderRadius: {
                            radius: 10
                        },
                        dataLabels: {
                            enabled: true,
                            // format: '{y} parts' 
                        },
                        stacking: 'normal'
                    }
                },
                tooltip: {
                    shared: true,
                    // split: false,
                    // enabled: true,
                    formatter: function() {
                        var tooltip = '<b>' + this.x + '</b><br/>',
                            stackValues = {};
        
                        // Iterate through all points in the shared tooltip
                        this.points.forEach(function(point) {
                            var stackName = point.series.options.stack;
                            var color = point.series.color;
                            if (!stackValues[stackName]) {
                                stackValues[stackName] = 0;
                            }
                            tooltip += `<div style="padding: 4px 0;color:${color}">` + point.series.name + ': ' + point.y + '</div><br/>';
                            stackValues[stackName] += point.y;
                        });
        
                        // Add totals for each stack
                        for (var stack in stackValues) {
                            tooltip += '<div style="padding: 4px 0;"><b>Total ' + stack + ': ' + stackValues[stack] + '</b></div><br/>';
                        }
        
                        return tooltip;
                    }
                  },
                series: seriesData
            })

            
        }

    }, [dateCategories, mainAllData,toggleShowScrap, isDark])

    function processData(data, showScrap) {
        let allData = [];
        if(data && data.data && data.data.operations && Object.keys(data.data.operations).length > 0){
            if(showScrap){
                data.data.operations.map(operation => {
                    allData.push({
                        name: operation.operation_name,
                        data: operation.data_good,
                        pointPadding: 0,
                        stack:'Good'
                    },{
                        name: `Scrap ${operation.operation_name}`,
                        data: operation.data_bad,
                        pointPadding: 0,
                        color: 'rgba(220, 38, 38, 0.5)',
                        stack:'Bad',
                        visible: true
                    })
                });
                return allData ;
            }else{
                
                data.data.operations.map(operation => {
                    allData.push({
                        name: operation.operation_name,
                        data: operation.data_good,
                        pointPadding: 0,
                        stack:'Good'
                    },{
                        name: `Scrap ${operation.operation_name}`,
                        data: operation.data_bad,
                        pointPadding: 0,
                        color: 'rgba(220, 38, 38, 0.5)',
                        stack:'Bad',
                        visible: false
                    })
                });
                return allData ;
            }
            
        }
        
        // return [goodSeriesData,badSeriesData] ;
      }

    
  return (
    <div className='mb-[-5px]'>
        <HighchartsReact
            constructorType={"chart"}
            containerProps={{ style: { height: '300px', maxWidth:'100%'} }} 
            highcharts={HighchartsStock}
            options={hourCountBarChartOptions}               
        />
    </div>
  )
}

export default StackedBarChartLargeParts