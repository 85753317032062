import ProgressBar from '../components/charts/ProgressBar.js';
import { useState, Fragment, useEffect} from 'react';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
// import CollapsibleCardSub from './CollapsibleCardSub.js';
import { motion } from "framer-motion";
import { Menu, Transition } from '@headlessui/react'
import moment from 'moment';
import { MemberTooltip } from '../styles/Tooltip.js';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Zoom } from '@mui/material';
import { darkModeState } from '../atoms/darkModeState.js';
import { useRecoilValue } from 'recoil';
import AnimateLine from '../components/AnimateLine.js';
import { refreshOrganizationApis } from '../atoms/refreshStates.js';
import { apiLink } from '../ApiCalls/ApisVariables.js';
import { getData } from '../ApiCalls/DataApis.js';
import DeletePartModal from '../components/modals/partModals/DeletePartModal.js';
import EditPartModal from '../components/modals/partModals/EditPartModal.js';
import BarChartList from '../components/charts/BarChartList.js';
import ProgressHalfGauge from '../components/charts/ProgressHalfGauge.js';
import AssetsCollapsibleCard from './AssetsCollapsibleCard.js';
import ScrapsBarChart from '../components/charts/ScrapsBarChart.js';

highchartsMore(Highcharts);
solidGauge(Highcharts);


function OpsCollapsibleCard({operation, index, currentShiftData, getOpsList, dateCategories}) {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const isDark = useRecoilValue(darkModeState)
    const isOrganizationApisRefreshed = useRecoilValue(refreshOrganizationApis)
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [renderLines, setRenderLines] = useState(true)
    const [isCollapsedStates, setIsCollapsedStates] = useState([]);
    const [loadDefaultImg, setLoadDefaultImg] = useState(false)
    const [barChartHourlyGoal, setBarChartHourlyGoal] = useState(0)
    const [barChartData, setBarChartData] = useState([])
    const [barChartCategories, setBarChartCategories] = useState([])
    const [opMaxCycleTime, setOpMaxCycleTime] = useState(0)
    const [totalShiftSecs, setTotalShiftSecs] = useState(0)
    const [grayoutSection, setGrayoutSection] = useState(operation.should_gray_out)
    const [avgOpsPerHour, setAvgOpsPerHour] = useState(0)
    const [avgOpsActualPerHour, setAvgOpsActualPerHour] = useState(0)
    const [operationsCount, setOperationsCount] = useState(0)
    const [operationShiftGoal, setOperationShiftGoal] = useState(0)
    const [operationCycleTime, setOperationCycleTime] = useState(0)
    const [optimalProgressbarPercent, setOptimalProgressbarPercent] = useState(0)
    const [arrowAvg, setArrowAvg] = useState(3600)

    
    useEffect(() => {
        if(operation && operation.operation_uuid){
            if(operation.assets && Object.keys(operation.assets).length > 0 ){
                setIsCollapsedStates( new Array(Object.keys(operation.assets).length).fill(false))
            }
            // getHourlyOpsData()

            if(operation && operation.overridden_good_count){
                setOperationsCount(operation.overridden_good_count)
            }
            if(operation && operation.actual_cycle_time_s){
                setOperationCycleTime(operation.actual_cycle_time_s)
            }
            
            if(currentShiftData && currentShiftData.max_goal_or_cycle_time_s){
                let max = Number(currentShiftData.max_goal_or_cycle_time_s) 
                setOpMaxCycleTime(max)
            }
            

            // if(operation.hourly_operations_made && Object.keys(operation.hourly_operations_made).length > 0){
            //     let hourlyGoal = operation.asset_operation_hourly_goal_count ? operation.asset_operation_hourly_goal_count : 0
            //     setBarChartHourlyGoal(hourlyGoal)

            //     let countData = []
            //     let timeCategories = []
            //     let lastDataIndex = Object.keys(operation.hourly_operations_made).length -1
            //     operation.hourly_operations_made.map((hourCount, index) => {
            //         let time = ''
            //         if(index === 0) {
            //             time = formatAMPM(hourCount.interval_timestamp)
            //             timeCategories.push(time)
            //         }else if(index === lastDataIndex){
            //             time = formatAMPM(hourCount.interval_timestamp)
            //             timeCategories.push(time)
            //         }else{
            //             timeCategories.push(time)
            //         }

            //         let amount = 0
            //         if(hourCount.overridden_good_count){
            //             amount = hourCount.overridden_good_count
            //         }
            //         let array = [formatAMPM(hourCount.interval_timestamp), amount]
            //         countData.push(array)
            //     })
            //     setBarChartData(countData)
            //     setBarChartCategories(timeCategories)

            // }
        }
    }, [location, isOrganizationApisRefreshed])

    useEffect(() => {
        if(operationCycleTime && opMaxCycleTime){
            let  optimalProgressbarPercent = Math.round((operationCycleTime / opMaxCycleTime)*100)
            
            setOptimalProgressbarPercent(optimalProgressbarPercent)

            // if(operation.asset_operation_hourly_goal_count){
            //     let desiredSecs = (60 / operation.asset_operation_hourly_goal_count ) * 60
            //     let arrow = Math.round((desiredSecs / opMaxCycleTime)*100)
            //     setArrowAvg(arrow)
            // }else{
            //     setArrowAvg(optimalProgressbarPercent)
            // }
            console.log()
            if(operation.ideal_cycle_time_s){
                let arrow = Math.round((Number(operation.ideal_cycle_time_s) / opMaxCycleTime)*100)
                setArrowAvg(arrow)
            }else{
                setArrowAvg(optimalProgressbarPercent)
            }
        }

    }, [opMaxCycleTime, operationCycleTime])



    useEffect(() => {
        let operations_expanded = searchParams.get('operations_expanded')
        if(operations_expanded && operations_expanded === 'isExpanded'){
            setIsCollapsed(true)
        }else{
            setIsCollapsed(false)
        }
    }, [location])


    // function formatAMPM(date) {
    //     let event = new Date(date)
    //     let currentTime = event.toLocaleTimeString('en-US', { hour: '2-digit'})
    //     currentTime = currentTime.replace(/\s/g, '')
    //     if(currentTime[0] == '0'){
    //         currentTime = currentTime.substring(1)
    //     }

    //     return currentTime
    //   }
    

    const addDefaultSrc = (ev) => {
        setLoadDefaultImg(true)
    }


    const handleCollapseStates = () => {
        setIsCollapsed(!isCollapsed)
        setIsCollapsedStates(new Array(Object.keys(operation.assets).length).fill(false))
    }

    function updateCollapseState(id, collapseState){
        let currentCollapseState = isCollapsedStates
        currentCollapseState[id] =  collapseState
        setIsCollapsedStates(currentCollapseState)
        setRenderLines(false)
    }

    useEffect(() => {
        if(!renderLines){
            setRenderLines(true)
        }
      },[renderLines]);

  
    // function handleTimeFormat(seconds) {
    //     if(seconds){
    //         let formatted = moment.utc(seconds*1000).format('HH:mm:ss')
    //         return formatted
    //     }else{
    //         let formatted = moment.utc(0).format('HH:mm:ss')
    //         return formatted
    //     }
    // }

    const formatCycleTime = (cycleTimeString) => {
        if(cycleTimeString){
            const parts = cycleTimeString.split('.'); // Split at the decimal point
            const milliseconds = parts[1] || '0'; // Get milliseconds (or default to '0')
            const formattedMilliseconds = milliseconds.slice(0, 2); // Keep only 2 characters
            return `${parts[0]}.${formattedMilliseconds}`; 

        }else{
            return ''
        }
    }


    

    return (
        <div >
            {index ===0 &&
            <>
                {/* List Header */}
                <div className='hidden xl:grid grid-cols-12 gap-2 pb-4 pt-3 text-center shadow-iotflows-lg rounded-t-3xl rounded-b-lg dark:bg-slate-800 mb-2'>
                    <div className='col-span-3 text-base text-gray-400 dark:text-gray-300 text-left pl-10'>Operation</div>
                    <div className='col-span-1 text-base text-gray-400 dark:text-gray-300'>Calculated Goal</div>
                    <div className='col-span-1 text-base text-gray-400 dark:text-gray-300'>Progress</div>
                    <div className='col-span-3 text-base text-gray-400 dark:text-gray-300 text-left'>Production Rate</div>
                    <div className='col-span-1 text-base text-gray-400 dark:text-gray-300'>Quality</div>
                    <div className='col-span-3 text-base text-gray-400 dark:text-gray-300 text-left'>Hourly Production</div>
                </div>
            </>
            }
            <div className={`relative bg-transparent w-full dark:bg-slate-800 shadow-iotflows-lg rounded-lg`}>
                {/* Screens for medium screens or below */}
                <div className='flex flex-col p-2 pb-4 xl:hidden'>
                    <div className='flex z-0 justify-between'>
                        <div className='flex items-center' >
                            {/* Collapse button */}
                            {operation.assets && Object.keys(operation.assets).length > 0 && operation.assets[0] && operation.assets[0].asset_uuid ? (
                                <div className='relative z-0'>
                                    <motion.div
                                        className="box pl-2"
                                        onClick={handleCollapseStates}
                                        whileHover={{ scale: 1.2 }}
                                        whileTap={{ scale: 0.9 }}
                                        transition={{ type: "spring", stiffness: 400, damping: 17 }}
                                        >
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-7 w-7 text-slate-700 dark:text-slate-300 transition ease-in-out duration-500 ${isCollapsed? '-rotate-180': 'rotate-0'} `} viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                    </motion.div>
                                </div>
                                
                            ): (
                                <div disabled  className={`pl-2 opacity-0`} >
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-7 w-7 text-slate-700 dark:text-slate-300  transition ease-in-out duration-500 ${isCollapsed? '-rotate-180': 'rotate-0'} `} viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            )}
                            {/* Avatar for part image */}
                            <button className='p-1 z-10' onClick={() => setIsCollapsed(!isCollapsed)}>
                                {!loadDefaultImg && operation.part_picture_url != null ? (
                                    <div className='w-16 h-16'>
                                        <img
                                            onError={addDefaultSrc}
                                            alt={operation.part_name}
                                            src={operation.part_picture_url}
                                            className={`w-16 h-16 rounded-full ${grayoutSection && 'opacity-50'}`}
                                        />
                                    </div>
                                ):(
                                    <div className='w-12 h-12 items-center text-center justify-center'>
                                        <GearIcon
                                            className={`w-8 h-8 mt-4 rounded-full ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'dark:text-gray-400 text-gray-600'} mx-auto`}
                                            aria-hidden="true"
                                        />
                                    </div>
                                )}
                            </button>
                            <button className='z-10 text-left truncate w-[175px]' onClick={() => setIsCollapsed(!isCollapsed)}>
                                <div className='flex flex-col justify-center text-left truncate'>
                                    <div className={`text-lg leading-4 font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} p-1 truncate`}>{operation.operation_name}</div>
                                    <div className={`text-sm leading-4 font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'} p-1 truncate`}>{operation.part_name}</div>
                                </div>
                            </button>
                        </div>
                        <div className={`hidden md:flex items-center my-auto `}>
                            <div className='flex flex-col justify-center text-center'>
                                <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'} truncate`}>Calculatedss Goal</div>
                                <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} truncate`}>{operation.operation_count_goal}</div>
                            </div>
                        </div>
                        <div className={`hidden md:flex items-center my-auto `}>
                            <div className='flex flex-col justify-center text-center'>
                                <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'} truncate`}>Quality</div>
                                <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} truncate`}>{operation.quality ? `${operation.quality}%`: '100%'}</div>
                            </div>
                        </div>
                        <div className={`hidden md:flex items-center my-auto `}>
                            <div className='flex flex-col justify-center text-center'>
                                <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'} truncate`}>Bad</div>
                                <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} truncate`}>{operation.overridden_bad_count ? `${operation.overridden_bad_count}`: '0'}</div>
                            </div>
                        </div>
                        <div className={`hidden md:flex items-center my-auto`}>
                            <ProgressHalfGauge gaugeData={operation} number={operationsCount} unit={'made'} currentShiftData={currentShiftData} shiftGoalCount={operation.asset_operation_shift_goal_count} grayoutSection={grayoutSection} />
                        </div>
                        
                    </div>
                    <div className='grid grid-cols-2 gap-2 md:hidden my-1 '>
                        {/* <div className={`items-center mr-auto my-auto pl-1`}>
                            <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} p-1 truncate`}>Hourly Goal: {operation.asset_operation_hourly_goal_count}</div>
                        </div> */}
                        <div className={` items-center my-auto mx-auto`}>
                            <ProgressHalfGauge gaugeData={operation} number={operationsCount} unit={'made'} currentShiftData={currentShiftData} shiftGoalCount={operation.asset_operation_shift_goal_count} grayoutSection={grayoutSection} />
                        </div>
                        <div className={`items-center my-auto pl-2 `}>
                            <div className='flex flex-col justify-center text-center'>
                                <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'} truncate`}>Calculated Goal</div>
                                <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} truncate`}>{operation.operation_count_goal}</div>
                            </div>
                        </div>
                        <div className={`items-center my-auto `}>
                            <div className='flex flex-col justify-center text-center'>
                                <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'} truncate`}>Quality</div>
                                <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} truncate`}>{operation.quality ? `${operation.quality}%`: '100%'}</div>
                            </div>
                        </div>
                        <div className={`items-center my-auto `}>
                            <div className='flex flex-col justify-center text-center'>
                                <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'} truncate`}>Bad</div>
                                <div className={`text-lg font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} truncate`}>{operation.overridden_bad_count ? `${operation.overridden_bad_count}`: '0'}</div>
                            </div>
                        </div>
                        
                    </div>
                    {/* Production time linear bar */}
                    <div className=' grid grid-cols-4 h-20 mt-1 pl-2'>
                        <div className='col-span-4 flex items-center relative overflow-x-hidden'>
                            <div className='flex absolute top-2 left-0 '>
                                <p className={`text-base font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'}`}>{operation.cycle_time ? `Cycle Time: ${formatCycleTime(operation.cycle_time)}`:''}</p>
                            </div>
                            <div className='w-full mt-2'>
                                <ProgressBar completed={optimalProgressbarPercent} arrowAvg={arrowAvg}  grayoutSection={grayoutSection} isMobile={true}  /> 
                            </div>
                        </div>
                    </div>
                    {/* Hourly count bar chart */}
                    <div className={`flex items-center relative h-20 mt-1`}>
                        <div className='w-full mt-1 z-0'>
                        <ScrapsBarChart height='120px' operation_uuid={operation.operation_uuid} countArray={operation.hourly_operations_made} hourlyGoal={operation.operation_hourly_goal} dateCategories={dateCategories}/>
                            {/* <BarChartList chartHeight={75} containerHeight={'60px'} barChartData={barChartData} barChartCategories={barChartCategories} barChartHourlyGoal={barChartHourlyGoal}  /> */}
                        </div>
                    </div>
                </div>
                {/* Screen for large and above */}
                <div className='hidden xl:grid xl:grid-cols-12 xl:gap-2 xl:h-20 z-0'>
                    <div className='col-span-3 flex items-center' >
                        {/* Collapse button */}
                        {operation.assets && Object.keys(operation.assets).length > 0 && operation.assets[0] && operation.assets[0].asset_uuid ? (
                            <div className='relative z-0'>
                                <div className={`hidden xl:flex ${isCollapsed ? '' : ' hidden '} ${renderLines ? 'transition-opacity duration-1000 ease-out opacity-100 ' : 'opacity-0 z-0'}`}>  
                                    <AnimateLine operation={operation} isCollapsedStates={isCollapsedStates} renderLines={renderLines} isCollapsed={isCollapsed} />
                                </div>
                                <motion.div
                                    className="box pl-2"
                                    onClick={handleCollapseStates}
                                    whileHover={{ scale: 1.2 }}
                                    whileTap={{ scale: 0.9 }}
                                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                                    >
                                <svg xmlns="http://www.w3.org/2000/svg" className={`h-7 w-7 text-slate-700 dark:text-slate-300 transition ease-in-out duration-500 ${isCollapsed? '-rotate-180': 'rotate-0'} `} viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                                </motion.div>
                            </div>
                            
                        ): (
                            <div disabled  className={`pl-2 opacity-0`} >
                                <svg xmlns="http://www.w3.org/2000/svg" className={`h-7 w-7 text-slate-700 dark:text-slate-300  transition ease-in-out duration-500 ${isCollapsed? '-rotate-180': 'rotate-0'} `} viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                </svg>
                            </div>
                        )}

                        {/* Avatar for part image */}
                        <button className='p-1 z-10' onClick={() => setIsCollapsed(!isCollapsed)}>
                            {!loadDefaultImg && operation.part_picture_url != null ? (
                                <div className='w-12 h-12'>
                                    <img
                                        onError={addDefaultSrc}
                                        alt={operation.part_name}
                                        src={operation.part_picture_url}
                                        className={`w-12 h-12 rounded-full ${grayoutSection && 'opacity-50'}`}
                                    />
                                </div>
                            ):(
                                <div className='w-12 h-12 items-center text-center justify-center'>
                                    <GearIcon
                                        className={`w-8 h-8 mt-4 rounded-full ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'dark:text-gray-400 text-gray-600'} mx-auto`}
                                        aria-hidden="true"
                                    />
                                </div>
                            )}
                        </button>
                        <button className='z-10 truncate' onClick={() => setIsCollapsed(!isCollapsed)}>
                            <div className='flex flex-col justify-center text-left truncate'>
                                <div className={`text-base leading-4 font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} p-1 truncate`}>{operation.operation_name}</div>
                                <div className={`text-sm leading-4 font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'} p-1 truncate`}>{operation.part_name}</div>
                            </div>
                        </button>
                    </div>
                    <div className={`col-span-1 items-center mx-auto my-auto`}>
                        <div className={`text-base font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'} p-1 truncate`}>{operation.operation_count_goal}</div>
                    </div>
                    <div className={`col-span-1 items-center mx-auto my-auto`}>
                       <ProgressHalfGauge gaugeData={operation} number={operationsCount} unit={'made'} currentShiftData={currentShiftData} shiftGoalCount={operation.asset_operation_shift_goal_count} grayoutSection={grayoutSection} />
                    </div>
                    <div className='col-span-3 flex items-center relative overflow-x-hidden'>
                        <div className='flex absolute top-2 left-0 items-baseline'>
                            <p className={`lg:text-sm xl:text-base font-normal ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-500 dark:text-gray-400'}`}>{operation.cycle_time ? `Cycle Time: ${formatCycleTime(operation.cycle_time)}`:''}</p>

                        </div>
                        <div className='w-full mt-2'>
                            <ProgressBar completed={optimalProgressbarPercent} arrowAvg={arrowAvg} grayoutSection={grayoutSection} />
                        </div>
                    </div>
                    <div className={`col-span-1 items-center mx-auto my-auto`}>
                        <div className='flex flex-col items-center'>
                        <div className={`text-xl font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-gray-300'}  truncate`}>{operation.quality ? `${operation.quality}%`: '100%'}</div>
                        <div className={`text-base font-medium ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-rose-600 dark:text-rose-300'}  truncate`}>{operation.overridden_bad_count ? `${operation.overridden_bad_count} Bad`: '0 Bad'}</div>
                        </div>
                    </div>
                    <div className={`col-span-3 flex items-center relative`}>
                        <div className='w-full mt-1 z-0'>
                            <ScrapsBarChart height='90px' operation_uuid={operation.operation_uuid} countArray={operation.hourly_operations_made} hourlyGoal={barChartHourlyGoal} dateCategories={dateCategories}/>
                            {/* <BarChartList chartHeight={75} containerHeight={'60px'} barChartData={barChartData} barChartCategories={barChartCategories} barChartHourlyGoal={barChartHourlyGoal}  /> */}
                        </div>
                        
                    </div>
                    
                </div>
                {/* Collapsed portion */}
                {isCollapsed && operation.assets && Object.keys(operation.assets).length > 0 &&
                    <div className={`${isCollapsed ? 'flex' : ' hidden'} flex flex-col z-10`}>
                        {operation.assets.map((assetOp, id) =>(
                            <div key={id} className='xl:border-t xl:dark:border-slate-600 xl:ml-11'>
                                <AssetsCollapsibleCard operation={operation} assetOp={assetOp} index={id} updateCollapseState={updateCollapseState} currentShiftData={currentShiftData} getOpsList={getOpsList} opMaxCycleTime={opMaxCycleTime}  grayoutSection={grayoutSection} dateCategories={dateCategories} />
                            </div>  
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}

export default OpsCollapsibleCard


function GearIcon(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      
      
    )
}

function EditInactiveIcon(props) {
    return (
        <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
            d="M4 13V16H7L16 7L13 4L4 13Z"
            fill="transparent"
            stroke="#94a3b8"
            strokeWidth="2"
        />
        </svg>
    )
}

function EditActiveIcon(props) {
    return (
        <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
            d="M4 13V16H7L16 7L13 4L4 13Z"
            fill="transparent"
            stroke="#a5b4fc"
            strokeWidth="2"
        />
        </svg>
    )
}

function DeleteInactiveIcon(props) {
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="5"
          y="6"
          width="10"
          height="10"
          fill="transparent"
          stroke="#94a3b8"
          strokeWidth="2"
        />
        <path d="M3 6H17" stroke="#94a3b8" strokeWidth="2" />
        <path d="M8 6V4H12V6" stroke="#94a3b8" strokeWidth="2" />
      </svg>
    )
  }
  
  function DeleteActiveIcon(props) {
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="5"
          y="6"
          width="10"
          height="10"
          fill="transparent"
          stroke="#a5b4fc"
          strokeWidth="2"
        />
        <path d="M3 6H17" stroke="#a5b4fc" strokeWidth="2" />
        <path d="M8 6V4H12V6" stroke="#a5b4fc" strokeWidth="2" />
      </svg>
    )
  }

