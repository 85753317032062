import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { globalSelectedOrganization, globalSelectedOrganizationTimezone } from '../atoms/orgStates';
import { useRecoilState } from 'recoil';
import { tzStrings } from '../data/TimezonesList';
import toast from 'react-hot-toast';

const NavigationBridge = ({orgList}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [currentSelectedOrg, setCurrentSelectedOrg] = useRecoilState(globalSelectedOrganization)
    const [currentSelectedOrgTimeZone, setCurrentSelectedOrgTimeZone] = useRecoilState(globalSelectedOrganizationTimezone)

    

    // useEffect(() => {
    //     if(location && location.pathname){
            
    //         let paths = location.pathname.split("/")

    //         if(orgList && Object.keys(orgList).length > 0 && paths && paths[1]){
    //             orgList.map(org => {
    //                 if(org.organization_uuid == paths[1]){
    //                     // setSelectedOrganization(org)
    //                     setCurrentSelectedOrg(org.organization_uuid)
    //                     let timeZoneLabel = ''
    //                     {tzStrings.map((tz, index) => {
    //                         if(tz.value == org.organization_time_zone){
    //                             timeZoneLabel = tz.label.slice(4, 10)
    //                             setCurrentSelectedOrgTimeZone(
    //                                 {
    //                                     name: org.organization_time_zone,
    //                                     value: timeZoneLabel
    //                                 }
    //                             )
                                
    //                         }
    //                     })}
    //                 }
    //             }) 
    //         }else{
    //             // setSelectedOrganization(orgList[0])
    //             setCurrentSelectedOrg(orgList[0].organization_uuid)
    //             let timeZoneLabel = ''
    //             {tzStrings.map((tz, index) => {
    //                 if(tz.value == orgList[0].organization_time_zone){
    //                     timeZoneLabel = tz.label.slice(4, 10)
    //                     setCurrentSelectedOrgTimeZone({
    //                         name: orgList[0].organization_time_zone,
    //                         value:timeZoneLabel
    //                     })
    //                 }
    //             })}
    //             navigate(`/${orgList[0].organization_uuid}`)
    //         }

    //     }
    // },[orgList,location])

    useEffect(() => {
        window.navigateReactApp = (url) => {
                let paths = url.split("/")
                
                if(orgList && Object.keys(orgList).length > 0 && paths && paths[1]){
                    orgList.map(org => {
                        if(org.organization_uuid == paths[1]){
                            // setSelectedOrganization(org)
                            
                            setCurrentSelectedOrg(org.organization_uuid)
                            let timeZoneLabel = ''
                            {tzStrings.map((tz, index) => {
                                if(tz.value == org.organization_time_zone){
                                    timeZoneLabel = tz.label.slice(4, 10)
                                    setCurrentSelectedOrgTimeZone(
                                        {
                                            name: org.organization_time_zone,
                                            value: timeZoneLabel
                                        }
                                    )
                                    
                                }
                            })}
                        }
                    }) 
                }else{
                    // setSelectedOrganization(orgList[0])
                    setCurrentSelectedOrg(orgList[0].organization_uuid)
                    let timeZoneLabel = ''
                    {tzStrings.map((tz, index) => {
                        if(tz.value == orgList[0].organization_time_zone){
                            timeZoneLabel = tz.label.slice(4, 10)
                            setCurrentSelectedOrgTimeZone({
                                name: orgList[0].organization_time_zone,
                                value:timeZoneLabel
                            })
                        }
                    })}
                    // navigate(`/${orgList[0].organization_uuid}`)
                }
            navigate(url);
        };
    }, [navigate]);

    return null; // This component doesn't render anything visible
};

export default NavigationBridge;