import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { LightTooltip } from '../../../styles/Tooltip';
import Waveform from './Waveform';
import toast from 'react-hot-toast';
import { darkModeState } from '../../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { formatRelativeTime } from '../../../utils/DateFormating';
import { formatFileSize } from '../../../utils/FileFormating';

function VoiceMessageForVoicePage({ audioUrl, handleDownloadFile, isLoadingFile, message, userInfo }) {
    const isDark = useRecoilValue(darkModeState)
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const audioRef = useRef(new Audio(audioUrl));
    const audioContextRef = useRef(null);
    const [audioData, setAudioData] = useState([]);

    useEffect(() => {
      if(audioUrl){
          const fetchAudioData = async () => {
              const data = await analyzeAudio(audioUrl);
              setAudioData(data);
            };
        
            fetchAudioData();
            
          const fetchAudioDuration = async () => {
            try {
              const response = await fetch(audioUrl);
              const arrayBuffer = await response.arrayBuffer();
              
              audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
              const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);
              
              setDuration(audioBuffer.duration);
            } catch (error) {
              console.error('Error fetching audio duration:', error);
            }
          };
      
          fetchAudioDuration();
      
          const audio = audioRef.current;
          audio.addEventListener('timeupdate', () => setCurrentTime(audio.currentTime));
          audio.addEventListener('ended', () => setIsPlaying(false));
      
          return () => {
            audio.removeEventListener('timeupdate', () => setCurrentTime(audio.currentTime));
            audio.removeEventListener('ended', () => setIsPlaying(false));
            if (audioContextRef.current ) {
              audioContextRef.current.close();
            }
          };

      }
      }, [audioUrl]);
      

    const togglePlayPause = () => {
        if (isPlaying) {
        audioRef.current.pause();
        } else {
        audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = Math.floor(time % 60);
        
        if (hours > 0) {
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        } else {
            return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        }
      };

  return (
    <div className='flex bg-transparent items-center rounded-xl w-full px-2 pt-1'>
        <motion.button 
            whileHover={{ scale: 1.05  }} 
            whileTap={{ scale: 0.98  }} 
            className={`h-10 w-10 items-center justify-center  rounded-full bg-slate-500 dark:bg-slate-200 text-slate-100 dark:text-sky-800 text-base`} onClick={togglePlayPause}> 
            {isPlaying ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 mx-auto">
                    <path fillRule="evenodd" d="M6.75 5.25a.75.75 0 0 1 .75-.75H9a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75V5.25Zm7.5 0A.75.75 0 0 1 15 4.5h1.5a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H15a.75.75 0 0 1-.75-.75V5.25Z" clipRule="evenodd" />
                </svg>
              
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 mx-auto">
                    <path fillRule="evenodd" d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z" clipRule="evenodd" />
                </svg>
              
            )
            }
        </motion.button>
      <div className={` pl-2 py-1 border-b-[0.01em] ${isDark ? 'border-slate-600':' border-slate-100 '} grow`}>
        <div className='flex flex-col '>
            {/* <progress value={currentTime} max={duration} /> */}
            {audioData &&
                <div className='mr-auto'>
                    <Waveform audioData={audioData} progress={currentTime / duration} />
                </div>
            }
            <div className='flex items-baseline'>
                <span className={`text-sm ${isDark ? 'text-gray-400':' text-gray-500 '}`} >
                {isPlaying ? (
                    formatTime(currentTime)
                    ):(
                    formatTime(duration)
                )}
                </span>
                <span className={`text-xs ${isDark ? 'text-gray-400':' text-gray-500 '} pl-1`} >
                {` - `}{message.user_first_name? message.user_first_name : ''}{` `}{message.user_last_name? message.user_last_name : ''} {` - `}
                </span>
                <span className={`text-xs font-light ml-1 ${isDark ? 'text-gray-400':' text-gray-500 '}`}>
                    {formatFileSize(message.file_size)} - {formatRelativeTime(message.message_created_at)}
                </span>
            </div>
        </div>
      </div>
        {/* <motion.button 
            whileHover={{ scale: 1.05  }} 
            whileTap={{ scale: 0.98  }} 
            className={`h-8 w-8 p-1 items-center justify-center  rounded-full bg-transparent ${message.user_username === userInfo.user_username ? 'text-white dark:text-gray-100':'text-gray-500 dark:text-gray-300'}`} onClick={() => handleDownloadFile(message)}> 
            {isLoadingFile ? (
                <svg class="animate-spin h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            ):(
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>

            )}
            
        </motion.button> */}
    </div>
  )
}

export default VoiceMessageForVoicePage


const analyzeAudio = async (audioUrl) => {
    try{
        const response = await fetch(audioUrl);
        const arrayBuffer = await response.arrayBuffer();
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        
        const audioData = audioBuffer.getChannelData(0); // Get data from first channel
        const samples = 100; // Number of samples we want to have in our final data
        const blockSize = Math.floor(audioData.length / samples);
        const filteredData = [];
        
        for (let i = 0; i < samples; i++) {
          let blockStart = blockSize * i;
          let sum = 0;
          for (let j = 0; j < blockSize; j++) {
            sum = sum + Math.abs(audioData[blockStart + j]);
          }
          filteredData.push(sum / blockSize);
        }
      
        const multiplier = Math.pow(Math.max(...filteredData), -1);
        const normalizedData = filteredData.map(n => n * multiplier);
        
        return normalizedData;
    }catch (e) {
        console.log(e.message)

    }
  };