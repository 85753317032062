import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { motion, AnimatePresence } from "framer-motion";
import { useRecoilValue } from 'recoil';
import {darkModeState} from '../../../atoms/darkModeState'
import { putData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import OptimizeImage from '../../OptimizeImage';


function ConfirmAlgoChangeModal({value, operation,conflictingAssets,conflictingInfo, remoteOpenModal, handleCloseConfirmAlgoChangeModal, getOpsList, setOperationAlgorithmId, setOperationAlgorithmName}) {
  const isDark = useRecoilValue(darkModeState);
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
    handleCloseConfirmAlgoChangeModal()
  }

  useEffect(() => {
    if(remoteOpenModal){
      openModal()
    }
  }, [remoteOpenModal])

  const submitConfirm = () => {
    try {
        if(!loading){
          setLoading(true)
          putData(apiLink + '/v2/operations/' + operation.operation_uuid , 
            {'operation_operation_detection_algorithm_id': conflictingInfo.operation_detection_algorithm_id,
            'remove_other_operations': true})
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoading(false)
                    getOpsList()
                    closeModal()
                }
                else{
                    try{
                        setOperationAlgorithmId(operation.operation_detection_algorithm_id)
                        setOperationAlgorithmName(operation.operation_detection_algorithm_name)
                        setLoading(false)
                        toast.error(data.message)
                    }catch (e){
                        setOperationAlgorithmId(operation.operation_detection_algorithm_id)
                        setOperationAlgorithmName(operation.operation_detection_algorithm_name)
                        toast.error(e.message)
                        setLoading(false)
                    }
                }
            })
  
        }
        
      } catch (error) {
        console.log(error.message)
      }
}

 



  return (
    <>
      {/* <div className="flex items-center justify-center">
          <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
              <PlusSmIcon className='btnMainIcon' />
              <span className='btnMainText'>Add Sensor</span>
          </motion.button>
      </div> */}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className={`w-full max-w-lg transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <div
                    className={`flex items-center mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>

                    Confirm Algorithm Change
                  </div>
                  <AnimatePresence mode='wait'>

                      <motion.div
                        key='signoutLoading'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className='w-full justify-center px-4'
                      > 
                        

                        <div className={`flex text-sm mt-4 ${isDark?'text-amber-100 bg-amber-700/20':'text-amber-800 bg-amber-100/50'}  rounded-xl p-4`}>
                            <div className='mr-1'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-5 h-5  ${isDark?'text-amber-300':'text-amber-600'}`}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                                </svg>
                            </div>
                            {conflictingInfo.message}
                        </div>
                        {conflictingAssets.map((asset) => (
                            <div key={asset.asset_uuid} className={`${isDark ? ' border-slate-700': ''} border-b-[0.01em] py-1.5 px-2 flex my-2 mx-2`}>
                                <OptimizeImage alt={asset.machine_name}  source={asset.asset_custom_picture_url  ? asset.asset_custom_picture_url : asset.machine_picture_url} size='medium' />
                                <div className='flex flex-col ml-2 justify-between overflow-hidden ' >
                                    <div className={`${isDark?'text-gray-300 ':' text-gray-600 '} text-base font-medium truncate pr-2`} >
                                    {asset.asset_custom_name ? asset.asset_custom_name : asset.machine_name }{' - '}<span className='text-sm font-normal'></span>
                                    </div>
                                    <div className={`${isDark?'text-gray-400 ':' text-gray-500 '} text-xs font-light italic truncate pr-2`} >
                                    {asset.asset_custom_identifier ? asset.asset_custom_identifier : asset.asset_uuid }
                                    </div>
                                </div>
                            </div>
                        ))}
                          
                      </motion.div>


                  </AnimatePresence>
                  <form >
                    <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                      <button
                        type="button"
                        className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className={`cursor-pointer ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                        onClick={submitConfirm}
                        disabled={loading}
                      >
                        Confirm
                        {loading && 
                            <div >
                                <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                </svg>
                            </div>
                          }
                      </button>
                    </div>
                  </form>
                  
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default ConfirmAlgoChangeModal