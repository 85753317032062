import { useEffect, Fragment, useState } from 'react'
import { Popover, Transition, PopoverButton, PopoverPanel } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { motion } from "framer-motion"
import { useLocation, useSearchParams } from 'react-router-dom'


const sortByListAll = [
    {
        name: 'Name (A to Z)',
        id: 'name_a_z',
        index: 0
    },
    {
        name: 'Name (Z to A)',
        id: 'name_z_a',
        index: 1
    },
    {
        name: 'Department',
        id: 'department',
        index: 2
    },
    {
        name: 'Machine type',
        id: 'machine_type',
        index: 3
    },
    // {
    //     name: 'Production count (High to Low)',
    //     id: 'production_count_high_low',
    //     index: 4
    // },
    // {
    //     name: 'Production count (Low to High)',
    //     id: 'production_count_low_high',
    //     index: 5
    // },
    // {
    //     name: 'Cycle time (High to Low)',
    //     id: 'cycle_time_high_low',
    //     index: 6
    // },
    // {
    //     name: 'Cycle time (Low to High)',
    //     id: 'cycle_time_low_high',
    //     index: 7
    // },
    
]

function SortByFilterBtn({select}) {
   const [tempSelectedSortBy, setTempSelectedSortBy] = useState([])
   const [selectedSortBy, setSelectedSortBy] = useState(sortByListAll[0])
   const [sortByList, setSortByList] = useState(sortByListAll)
   const [searchParams, setSearchParams] = useSearchParams()
   const location = useLocation();

   useEffect(() => {
    let currentPage = searchParams.get('select')
    if(currentPage === 'project'){
        const projectSortByList = [
            {
                name: 'Name (A to Z)',
                id: 'name_a_z',
                index: 0
            },
            {
                name: 'Name (Z to A)',
                id: 'name_z_a',
                index: 1
            },
        ]
        setSelectedSortBy(projectSortByList[0])
        setSortByList(projectSortByList)
    }else if(currentPage === 'parts_list'){
        let sortList = [
            {
                name: 'Date: New to Old',
                id: 'date_new_old',
                index: 0
            },
            {
                name: 'Date: Old to New',
                id: 'date_old_new',
                index: 1
            },
            {
                name: 'Name (A to Z)',
                id: 'name_a_z',
                index: 2
            },
            {
                name: 'Name (Z to A)',
                id: 'name_z_a',
                index: 3
            },
            
        ]
        setSelectedSortBy(sortList[0])
        setSortByList(sortList)
    }else if(currentPage === 'downtimes'){
        let sortList = [
            {
                name: 'Date: New to Old',
                id: 'date_new_old',
                index: 0
            },
            {
                name: 'Date: Old to New',
                id: 'date_old_new',
                index: 1
            },
            {
                name: 'Duration: High to Low',
                id: 'duration_high_low',
                index: 2
            },
            {
                name: 'Duration: Low to High',
                id: 'duration_low_high',
                index: 2
            },
            
        ]
        setSelectedSortBy(sortList[0])
        setSortByList(sortList)
    }

   },[location])




   useEffect(() => {
    // Gettting search parameters from URL
        let currentSearch = location.search
        if(currentSearch && Object.keys(sortByList).length >0){
            // Pulling all parts search parameters into an array
            let searchSortBy = searchParams.get('sort_by')

        sortByList.map(sort =>{
                if(sort.id == searchSortBy){
                    setSelectedSortBy(sort)
                }
            })
            
        }

    }, [location])


   const handleChangeSortBy = (e) => {
            setTempSelectedSortBy(sortByList[e.target.value])
        
        
        
    }

    const handleApplyFilter = () => {
        if(tempSelectedSortBy && Object.keys(tempSelectedSortBy).length > 0){ 
            let parts = searchParams.getAll('parts')
            let departments = searchParams.getAll('departments')
            let machines = searchParams.getAll('machines')
            let machine_types = searchParams.getAll('machine_types')
            let tags = searchParams.getAll('tags')
            let group_by = searchParams.getAll('group_by')
            let calculate_oee_by = searchParams.getAll('calculate_oee_by')
            let shift_name = searchParams.getAll('shift_name')
            let shift_start_time = searchParams.getAll('shift_start_time')
            let shift_date = searchParams.getAll('shift_date')
            let shift_entire_day = searchParams.getAll('shift_entire_day')
            let shift_yesterday = searchParams.getAll('shift_yesterday')
            let maximize = searchParams.getAll('maximize')
            let url_token = searchParams.getAll('url_token')
            let operations_expanded = searchParams.getAll('operations_expanded')
            let assets_expanded = searchParams.getAll('assets_expanded')
            let platform = searchParams.getAll('platform')
            let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')
            let downtime_severity_uuids = searchParams.getAll('downtime_severity_uuids')
            let only_with_notes = searchParams.getAll('only_with_notes')
            let from = searchParams.getAll('from')
            let to = searchParams.getAll('to')
            let time_period = searchParams.getAll('time_period')
            let q = searchParams.getAll('q')
    
            setSelectedSortBy(tempSelectedSortBy)
            setSearchParams({select,parts, departments,machines, machine_types,tags, group_by, sort_by: tempSelectedSortBy.id, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, shift_yesterday, maximize, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled, downtime_severity_uuids, only_with_notes, from, to, time_period, q}) 
        }
    }

  return (
    <div >
        <Popover className="relative">
        {({ open, close }) => (
          <>
            <PopoverButton
              className={`
                ${open ? 'bg-slate-100 dark:bg-slate-700' : ''}
                border border-blue-600 border-dashed rounded-full flex pl-1 pr-3 py-0.5 items-center outline-none max-w-[250px] `}
                onClick={() => setTempSelectedSortBy([])}
                
            >
                <div className='bg-white w-3 h-3 rounded-full m-1'>
                    <svg xmlns="http://www.w3.org/2000/svg" className={`transition ease-in-out duration-300 ${Object.keys(selectedSortBy).length > 0? ' rotate-45 ':'rotate-0'} h-5 w-5 ml-[-4px] mt-[-4px] text-blue-600 `} viewBox="0 0 20 20" fill="currentColor" >
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                    </svg>
                </div>
                <div className='pl-1 text-sm text-gray-600 dark:text-gray-300 truncate min-w-fit'>
                    Sort by
                </div>
                {Object.keys(selectedSortBy).length > 0 &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                        <div className='flex '>
                            <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{selectedSortBy.name}</div>
                        </div>
                    <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                </div>
                }
                
            </PopoverButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel className="absolute left-32 z-20 mt-3 w-[260px] -translate-x-1/2 transform ">
                <div className=" overflow-scroll scrollbar-hide rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-slate-800 p-3">
                        <div className='text-sm font-bold text-gray-900 dark:text-gray-300'>Sort by</div>
                        
                        
                        <div className="flex justify-center">
                            <div className="my-3 w-full outline-none">
                                <select defaultValue={selectedSortBy?.index} onChange={handleChangeSortBy}  className="form-select form-select-sm shadow-iotflows-lg appearance-none block w-full
                                px-2 py-1 text-sm font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 bg-clip-padding bg-no-repeat border-1 border-slate-50 dark:border-slate-400 rounded
                                transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-50 focus:outline-none focus:shadow-blue-300" aria-label=".form-select-sm ">
                                    {sortByList.map((sort, index) => (
                                        <option key={sort.id} value={index}  >{sort.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>    
                        <motion.button  
                            whileHover={{ scale: 1.02 }} 
                            whileTap={{ scale: 0.98 }} 
                            onClick={ () => {
                                handleApplyFilter()
                                close()
                            }} 
                            className='w-full text-sm p-1 bg-blue-600 text-white rounded-md' >
                                Apply
                        </motion.button>
                </div>
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default SortByFilterBtn