
import { useRecoilValue } from "recoil";
import {darkModeState} from '../atoms/darkModeState'
import { motion } from "framer-motion"
import { WifiIcon } from '@heroicons/react/solid'
import SenseAI from '../images/SenseAI.svg'
import { Fragment } from "react";
import AnimateLineCurved from "../components/AnimateLineCurved";
import { useNavigate } from "react-router-dom";
import GatewayIoTFlows from '../images/GatewayIoTFlows.svg'
import { globalSelectedOrganization } from "../atoms/orgStates";
import OptimizeLargeImage from '../components/OptimizeLargeImage';

function DeviceSectionGraph({section}) {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const isDark = useRecoilValue(darkModeState);
    const navigate = useNavigate()

    

    var x2Pos = 26.6;
    // var x2Pos = 161;

    if(window.innerWidth >= 3072){
        x2Pos = window.innerWidth * .126139
    }else if(window.innerWidth >= 3024){
        x2Pos = window.innerWidth * .125165
    }else if(window.innerWidth >= 2716){
        x2Pos = window.innerWidth * .12039
    }else if(window.innerWidth >= 2560){
        x2Pos = window.innerWidth * .11679
    }else if(window.innerWidth >= 1920){
        x2Pos = window.innerWidth * .09895
    }else if(window.innerWidth >= 1800){
        x2Pos = window.innerWidth * .0956
    }else if(window.innerWidth >= 1700){
        x2Pos = window.innerWidth * .09
    }else if(window.innerWidth >= 1600){
        x2Pos = window.innerWidth * .08422
    }else if(window.innerWidth >= 1500){
        x2Pos = window.innerWidth * .07866
    }else if(window.innerWidth >= 1440){
        x2Pos = window.innerWidth * .07479
    }else if(window.innerWidth >= 1366){
        x2Pos = window.innerWidth * .06918
    }else if(window.innerWidth >= 1180){
        x2Pos = window.innerWidth * .05338
    }else if(window.innerWidth >= 1024){
        x2Pos = window.innerWidth * .035644
    }



    const draw = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: (i) => {
          const delay = 1 + i * 0.5;
          return {
            pathLength: 1,
            opacity: 1,
            transition: {
              pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
              opacity: { delay, duration: 0.01 }
            }
          };
        }
      };


  return (
        <div className={`grid grid-cols-7 gap-4 pl-1 mt-4 pb-2 overflow-hidden relative`}>
        {section.map((sensor, index) => (
            <Fragment key={sensor.node_uuid}>
                <div  className=' row-span-1 col-span-2 flex'>
                    {sensor.asset_uuid &&
                    <>
                    <motion.button  whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.9 }} className='z-10 flex w-56 h-24 shadow-iotflows-lg rounded-lg dark:bg-slate-800 pl-4 items-center' onClick={() => navigate(`/${selectedOrganization}/assets/selected-asset/${sensor.asset_uuid}`)}>
                        <OptimizeLargeImage source={sensor.asset_custom_picture_url  ? sensor.asset_custom_picture_url : sensor.machine_picture_url} alt={sensor.machine_name} size='lg' />
                        <div className='flex flex-col justify-center pl-2 max-w-[120px]' >
                            <div className='text-left leading-6 truncate text-base text-gray-600 dark:text-white font-medium'>{sensor.asset_custom_name  ? sensor.asset_custom_name :sensor.machine_name}</div>
                            <div className='text-left leading-6 truncate text-sm text-gray-500 dark:text-gray-400 font-bold'>{sensor.asset_custom_identifier ? sensor.asset_custom_identifier : sensor.asset_uuid}</div>
                        </div>
                    </motion.button>
                    <div className='relative'>
                        <motion.svg
                            width="1000"
                            height="1000"
                            viewBox="0 0 600 600"
                            initial="hidden"
                            animate="visible"
                            className='absolute'
                        >
                            <motion.line
                                x1="0"
                                y1="32"
                                x2={x2Pos}
                                y2="32"
                                rx="20"
                                stroke= {isDark ? "#475569" : "#cbd5e1"}
                                variants={draw}
                                custom={1}
                            />
                        </motion.svg>
                        {/* <svg
                            width="1000"
                            height="1000"
                            viewBox="0 0 600 600"
                            initial="hidden"
                            animate="visible"
                            className='absolute'
                        >
                            <line
                                x1="0"
                                y1="32"
                                x2={x2Pos}
                                y2="32"
                                rx="20"
                                stroke= {isDark ? "#475569" : "#cbd5e1"}
                                variants={draw}
                                custom={1}
                            />
                        </svg> */}
                    </div>
                    </>
                    }
                </div>
                <div className='z-0 row-span-1 col-span-2' >
                    <div className='z-[-10] absolute'>
                        <AnimateLineCurved sensor={sensor} index={index}/>
                    </div>
                    <div className='flex w-52 h-24 shadow-iotflows-lg rounded-lg dark:bg-slate-800 pl-6 items-center cursor-pointer' >
                        <img src={SenseAI} alt={sensor.node_name} loading={'lazy'} className='h-20' />
                        <div className='flex flex-col justify-center pl-2 w-[120px]'>
                            <div className='text-left leading-6 truncate text-base text-gray-600 dark:text-white font-medium'>{sensor.node_name}</div>
                            <div className='text-left leading-6 truncate text-sm text-gray-500 dark:text-gray-400 font-bold'>{sensor.node_uuid}</div>
                            <div className='flex items-center justify-between px-3 py-1'>
                                <div>
                                    {/* <WifiIcon className='w-5 h-5 text-gray-400 dark:text-gray-400' /> */}
                                    {handleWifiIcon(sensor, isDark)}
                                </div>
                                <div>
                                    {sensor.node_is_online ? (
                                        <div className="relative">
                                            <div className="w-4 h-4 bg-transparent"></div>
                                            <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-green-400 dark:bg-green-600 animate-ping"></div>
                                            <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-green-400 dark:bg-green-600"></div>
                                        </div>
                                    ):(
                                        <div className="relative">
                                            <div className="w-4 h-4 bg-transparent"></div>
                                            <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-gray-400 dark:bg-gray-600"></div>
                                            <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-gray-400 dark:bg-gray-600"></div>
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {index === 0  &&
                    <div  className={` col-span-3 row-span-2`}>
                        <div className='flex w-80 h-36 pl-6 shadow-iotflows-lg rounded-lg dark:bg-slate-800'>
                            <img src={GatewayIoTFlows} alt={`IoTFlows Cloud`} loading={'lazy'} className=' w-28 ' />
                            <div className='flex flex-col justify-center pl-2 w-[175px]'>
                                <div className='text-left leading-6 truncate text-base text-gray-600 dark:text-white font-medium'>Cloud</div>
                                <div className='text-left leading-6 truncate text-sm text-gray-500 dark:text-gray-400 font-bold'>Cloud</div>
                                <div className='flex items-center justify-between px-3 py-1'>
                                    <div>
                                        <WifiIcon className='w-5 h-5 text-gray-400 dark:text-gray-400' />
                                        
                                    </div>
                                    <div>
                                        <div className="relative">
                                            <div className="w-4 h-4 bg-transparent"></div>
                                            <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-green-400 dark:bg-green-600 animate-ping"></div>
                                            <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-green-400 dark:bg-green-600"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    
                }
                {index > 1  &&
                    <div className={` col-span-3 row-span-1`}>
                        <div >
                        {''}
                        </div>
                    </div>
                }
            </Fragment>
        ))}       
    </div>
    
  )
}

export default DeviceSectionGraph


function handleWifiIcon(sensor, isDark) {
        if(sensor.node_is_online){
          if(sensor && sensor.node_vitals && sensor.node_vitals.wifi_rssi){
            
            let rssi = parseInt(sensor.node_vitals.wifi_rssi)
            
            if(rssi > -50){
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={`w-6 h-6 ${isDark? 'text-gray-400' : 'text-gray-500'}`} viewBox="0 0 16 16">
                        <path d="M15.384 6.115a.485.485 0 0 0-.047-.736A12.444 12.444 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.716 2.164.205.148.49.13.668-.049z"/>
                        <path d="M13.229 8.271a.482.482 0 0 0-.063-.745A9.455 9.455 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.576 1.336c.206.132.48.108.653-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.61-.091l.016-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.707-.707z"/>
                    </svg>
                )
            }else if(rssi > -60 && rssi <= -50){
                return(
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={`w-6 h-6 ${isDark? 'text-gray-400' : 'text-gray-500'}`} viewBox="0 0 16 16">
                        <path d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.456 9.456 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.577 1.336c.205.132.48.108.652-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .708 0l.707-.707z"/>
                    </svg>
                )
            }else if(rssi <= -60){
                return(
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={`w-6 h-6 ${isDark? 'text-gray-400' : 'text-gray-500'}`} viewBox="0 0 16 16">
                        <path d="M11.046 10.454c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.708-.707z"/>
                    </svg>
                )
            }
          }else{
            return (
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={`w-5 h-5 ${isDark? 'text-gray-400' : 'text-gray-500'}`} viewBox="0 0 16 16">
                    <path d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z"/>
                </svg>
            )
          }
        }else{
          return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={`w-5 h-5 ${isDark? 'text-gray-400' : 'text-gray-500'}`} viewBox="0 0 16 16">
                <path d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z"/>
            </svg>
          )
        }
    
    }




