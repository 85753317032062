import HighchartsStock from 'highcharts/highstock';
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from 'react';
import { darkModeState } from '../../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";

highchartsMore(Highcharts);

function SectionedPieChartLarge({downtimePercent, uptimePercent, unknownPercent, totalUtilizationPercent}) {
    const [utilizationPieChartOptions, setUtilizationPieChartOptions] = useState({
      chart: {
        // renderTo: 'container',
        backgroundColor: null,
        height: 200 ,
        width:200,
      },
      title: {
        text: "",
        verticalAlign: 'top',
        style: { 
          color:'#475569',
          fontSize: '14px',
        } 
      },
      credits: {enabled: false},
    })
    const [centerValue, setCenterValue] = useState('')
    const isDark = useRecoilValue(darkModeState);

    useEffect(() => {
      if(totalUtilizationPercent > 0){
        let percent = totalUtilizationPercent + '%'
        setCenterValue(percent)
      }

    }, [totalUtilizationPercent])

    useEffect(() => {
      // console.log('uptimePercent', uptimePercent, downtimePercent, unknownPercent)
      let unknown = 0
      let uptime = 0
      let downtime = 0
      if(unknownPercent){
        unknown= unknownPercent
      }
      if(uptimePercent){
        uptime= uptimePercent
      }
      if(downtimePercent){
        downtime= downtimePercent
      }
      if( uptimePercent || downtimePercent || unknownPercent){
        // let red = '#f43f5e'
        let red = 'rgba(220, 38, 38, 0.3)'
        // let gray = '#6b7280'
        let gray = 'rgba(107, 114, 128, 0.3)'
        let sky = '#0ea5e9'

        // let uptimeColorGradient = [
        //   [0, 'rgba(14, 165, 233, 0.1)'],
        //   [0.25, 'rgba(14, 165, 233, 0.3)'],
        //   [0.5, 'rgba(14, 165, 233, 0.5)'],
        //   [0.75, 'rgba(14, 165, 233, 0.7)'],
        //   [1, 'rgba(14, 165, 233, 0.9)'],
        // ]
        
        if(!isDark){
            red = 'rgba(220, 38, 38, 0.5)' //red 600

            // red = '#fb7185'
            // red = 'rgba(251, 113, 133, 0.7)'


            gray = '#d1d5db'
            sky = '#3b82f6'
            // sky = '#38bdf8'
            // uptimeColorGradient = [
            //   [0, 'rgba(59, 130, 246, 0.1)'],
            //   [0.25, 'rgba(59, 130, 246, 0.3)'],
            //   [0.5, 'rgba(59, 130, 246, 0.5)'],
            //   [0.75, 'rgba(59, 130, 246, 0.7)'],
            //   [1, 'rgba(59, 130, 246, 0.9)'],
            // ]
        }
        

        setUtilizationPieChartOptions({
          chart: {
            // renderTo: 'container',
            backgroundColor: null,
            height: 200 ,
            width:200,
          },
          time: {
            useUTC: false
          },
          colors: [sky,red, gray,'rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
          // colors: Highcharts.map(Highcharts.getOptions().colors, function (color) {
          //   console.log(color, color)
          //   return {
          //       radialGradient: {
          //           cx: 0.8,
          //           cy: 0.3,
          //           r: 0.7
          //       },
          //       stops: [
          //           [0, color],
          //           [1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
          //       ]
          //   };
          // }),
          // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
          credits: {enabled: false},
          title: {
            text: "",
            verticalAlign: 'top',
            style: { 
              color:'#475569',
              fontSize: '14px',
            } 
          },
          exporting: {
            enabled: false
          },
          series: [{
            type: 'pie',
            innerSize: '70%',
            data: [{
              name: 'Running',
              y: Number(uptime),
              // color:{
              //   linearGradient: {x1: 0, y1: 0, x2: 1, y2: 1 },
              //   stops: uptimeColorGradient
              // }
              
          } ,{
                name: 'Stopped',
                y: Number(downtime),
            },{
                name: 'Unknown',
                y: Number(unknown),
            }]
          }],
          plotOptions: {
            // series: {
            //   animation: false
            // },
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                size: 190,
                dataLabels: {
                    enabled: false
                },
                borderWidth: 0,
                margin: 0,
            }
          },
          legend: {
            itemDistance:0,
          },
          tooltip: {
            pointFormat: '{point.y}%'
          }
        })
      }

    }, [uptimePercent, downtimePercent,unknownPercent, isDark])
    
    return (
        <div className='relative'>
            <div className='absolute inset-0 flex justify-center items-center'>
                <h6 className='text-gray-600 dark:text-gray-300 text-3xl font-medium'>{centerValue}</h6>
            </div>
            <HighchartsReact
                highcharts={HighchartsStock}
                constructorType={'chart'}
                options={utilizationPieChartOptions}
            />
            
        </div>
    )
}

export default SectionedPieChartLarge