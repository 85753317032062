import { Popover, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { toast } from 'react-hot-toast';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import { putData } from '../../../ApiCalls/DataApis';

function NotificationDropdown({asset, getOrgAssets}) {
    const [currentTime, setCurrentTime] = useState(asset.asset_alert_downtime_after_min)
    const [loadingSave, setLoadingSave] = useState(false)


    const handleSetNewTime = () => {
        if(!loadingSave){
            setLoadingSave(true)
            putData(apiLink + '/v1/organization_assets/' + asset.asset_uuid + '/notifications/down/setting' , 
            {'asset_alert_downtime_after_min': currentTime})
            .then(async response => {
                // const data = await response.json();
                if(response.ok){
                    setLoadingSave(false)
                    getOrgAssets()
                    toast.success('Time has been edited')
                }
                else{
                    setCurrentTime(asset.asset_alert_downtime_after_min)
                    try{
                        const data = await response.json();
                        setLoadingSave(false)
                        toast.error(data.message)
                    }catch (e){
                        toast.error('Something went wrong')
                        setLoadingSave(false)
                    }
                }
            })
            
        }

    }

  return (
    <div>
        
        <Popover className="relative">
            {({ open, close }) => (
            <>
                <Popover.Button className='outline-none focus:outline-none items-center pt-1 text-gray-400 dark:text-gray-300'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 sm:w-5 sm:h-5 md:w-6 md:h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </Popover.Button>
                <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
                >
                <Popover.Panel className="absolute z-50 right-[-10px] origin-top-right mt-1 w-[350px] bg-white dark:bg-slate-700 shadow-iotflows-lg rounded-lg outline-none px-2.5 pt-1 pb-2.5">
                    <div className='flex justify-between pt-2'>
                        <p className='text-base font-light text-gray-600 dark:text-gray-300'>
                            Time to be notified after the asset is down
                        </p>
                    </div>
                    <div className='flex items-center pt-3'>
                        <div className='max-w-[100px]'>
                            <input 
                            className={` block bg-transparent w-full border rounded-md py-0.5 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800`} 
                            type={'numeric'}
                            onChange={(e) => setCurrentTime(e.target.value)}
                            value={currentTime}
                            />
                        </div>
                        
                        <div className='text-gray-400  text-base mx-2'>
                            {'Minute(s)'}
                        </div>
                        <div className='ml-auto'>
                            <button disabled={loadingSave} className={`relative shadow rounded-md py-0 px-2 border-[1px] border-blue-600 text-base bg-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white`} onClick={() => handleSetNewTime(close())}>
                                Set
                                {loadingSave && 
                                    <div >
                                        <svg aria-hidden="true" className={`absolute top-0 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                        </svg>
                                    </div>
                                }
                            </button>

                        </div>
                    </div>
                    
                </Popover.Panel>
                </Transition>
            </>
            )}
        </Popover>
    </div>
  )
}

export default NotificationDropdown