
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {darkModeState} from '../../atoms/darkModeState'

function DropdownSelectFormForOperations({name, operationsList,handleOnChange, label, value }) {
    const isDark = useRecoilValue(darkModeState);
    const [selectedDropdown, setSelectedDropdown] = useState('')

    useEffect(() => {
        if(value){
            operationsList.map((type, index) => {
                if(type.operation_uuid === value){
                    setSelectedDropdown(index)
                }
            })
        }else {
            setSelectedDropdown('')
            
        }

    }, [value])

    

    

  return (
    <div className='w-full items-center'>
        {label && 
        <label htmlFor='machineType' className={`pt-4 text-sm font-bold ${isDark?'text-gray-300':'text-gray-600'}`}>
            {label}
        </label>
        }
        <div className="flex justify-center">
            <div className={`${label? 'mt-2':''} w-full outline-none`}>
                <select 
                    name={name}
                    id={name}
                    value={selectedDropdown}
                    onChange={handleOnChange}  
                    className={`${isDark?'text-gray-200 border-gray-600 focus:text-gray-300 focus:bg-slate-700 focus:border-slate-400 ':'text-gray-700 border-slate-50 focus:text-gray-700 focus:bg-white focus:border-slate-50 '} 
                    bg-transparent form-select form-select-sm shadow-iotflows-lg appearance-none block w-full px-2 py-1 text-sm font-normal bg-clip-padding bg-no-repeat border-1 rounded transition ease-in-out m-0 focus:outline-none focus:shadow-blue-300`} 
                    aria-label="Machine Type selection"
                >
                    {/* <option value={''}>{'unclassified'}</option> */}
                    {operationsList.map((type, index) => (
                        <option key={type.operation_uuid} value={index}>{type.operation_name}{type.part_name ? ` - ${type.part_name}`: ''}</option>
                    ))}
                </select>
            </div>
        </div> 
    </div>
  )
}

export default DropdownSelectFormForOperations