import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 14,
  },
}));

export const MemberTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#1e293b',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    minWidth:350,
    border: '0px solid #dadde9',
    padding:'0px',
    borderRadius:'0.75rem',
    fontFamily:'Inter',
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      border: "0px solid #1e293b"
    },
    color: '#1e293b'
  },
}));
