import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { motion, AnimatePresence } from "framer-motion";
import { PencilIcon } from '@heroicons/react/solid';
import ProjectRowForModal from './ProjectRowForModal';


function EditProjectsListModal({btnType, projectList,operationsList, selectedOrganization, getProjectList}) {
    let [isOpen, setIsOpen] = useState(false)
    const isDark = useRecoilValue(darkModeState);
    

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }



  return (
    <>
      <div className="flex items-center justify-center">
      {btnType ==='standardBtn' &&
        <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
            <PencilIcon className='btnMainIcon mr-1' />
            <span className='btnMainText'>Edit Project</span>
        </motion.button>
        }
      {btnType ==='tableBtn' &&
       <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='flex w-20 text-sm items-baseline ml-2' onClick={openModal} >
          <PencilIcon className='text-blue-600 w-4 h-4 mt-auto mb-0.5' />
          <span className=' text-blue-600'>Edit</span>
      </motion.button>
        }
        {btnType ==='hideBtn' &&
          <div className='w-0 h-0'>{''}</div>
          }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-lg transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <Dialog.Title
                    as="h3"
                    className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}
                  >
                    Project List
                  </Dialog.Title>
                  <AnimatePresence exitBeforeEnter>
                    
                  
                  {/* Adding new Organization section */}
                <motion.div
                key='projectList'
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
                className={`max-h-[500px] overflow-y-scroll`}
                >
                  <div className='px-4 mt-2'>
                    {projectList.map((project, index) => (
                      <div key={project.production_uuid}>
                        <ProjectRowForModal index={index} project={project} operationsList={operationsList} selectedOrganization={selectedOrganization} getProjectList={getProjectList}/>
                      </div>
                      ))}
                      
                  </div>
                </motion.div>

                </AnimatePresence>

                <form >
                    <div className={`flex p-4 px-5 items-center mt-1 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                      <button
                        type="button"
                        className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      {/* <button
                        type="button"
                        className={`cursor-pointer ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                        onClick={closeModal}
                        disabled={false}
                      >
                        Edit Organization
                      </button> */}
                    </div>
                  </form>
                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default EditProjectsListModal

