import { motion } from "framer-motion";
import { useRecoilValue } from "recoil";
import {darkModeState} from '../atoms/darkModeState'

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const delay = 1 + i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: "spring", duration: 2, bounce: 0 },
        opacity: { delay, duration: 0.01 }
      }
    };
  }
};

function AnimateLineCurved({sensor, index}) {
    const isDark = useRecoilValue(darkModeState);
    var d=''

    if(index === 0){
        if(window.innerWidth < 1100){
            let lastL = 32.7
            if(window.innerWidth > 930){
                lastL = window.innerWidth *0.03365
            }
            d=`
            M 25,6.4
            L 27 ,6.4
            Q 29, 6.4
              29, 7.5
            L 29, 8.2
            Q 29, 8.8
              30, 8.8
            L ${lastL}, 8.8
            `
        } else {
            // let lastL = window.innerWidth *0.03385
            // d=`
            // M 25,6.4
            // L 29 ,6.4
            // Q 31, 6.4
            //   31, 7.5
            // L 31, 8.2
            // Q 31, 8.8
            //   32, 8.8
            // L ${lastL}, 8.8
            // `

            // Test *************
                let lastL = window.innerWidth *0.03385
                d=`
                M 25,6.4
                C 30, 6.4
                  34, 9.5
                  ${lastL}, 8.8
                `
                // Test *************
        }
    }
    else{
        // // Staight line up and curved at the top then stair again (SHAPE OF two Ls )
        // // this initial Y is to bring the SVG to each div in order to connect it from SenseAI div to gateway Div
        // let initialY = 19.85 + 13.45*(index-1)
        // // best at 1920px
        //     let firstCurveY = initialY - (1.7*index) + 1
        // // best at 1500px
        //     // let firstCurveY = initialY - (2*index) + 1
        // // best at 1366px
        // // let firstCurveY = initialY - (3*index) +1

        // // best at 1180px
        // // let firstCurveY = initialY - (4*index) 
        // console.log('firstCurveY', index, firstCurveY)
        // if(window.innerWidth < 1100){
        //     let lastL = window.innerWidth *0.03535
        //     if(window.innerWidth > 930){
        //         lastL = window.innerWidth *0.03365
        //     }
        //     d=`
        //     M 25,${initialY}
        //     L 27 ,${initialY}
        //     Q 29, ${initialY}
        //       29, ${firstCurveY}
        //     L 29, 9.5
        //     Q 29, 8.8
        //       30, 8.8
        //     L ${lastL}, 8.8
        //     `
        // } else {
        //     let lastL = window.innerWidth *0.03385
        //     console.log('lastL', lastL)
        //     d=`
        //     M 25,${initialY}
        //     Q 28, ${initialY}
        //       31, ${firstCurveY}
        //     Q ${lastL-4}, 8.8
        //       ${lastL}, 8.8
        //     `
        // }


        // Staight line up and curved at the top then stair again (SHAPE OF two Ls )
        if(window.innerWidth < 1100){
            let initialY = 19.85 + 13.45*(index-1)
            let firstCurveY = initialY - 1
            let lastL = 32.7
            if(window.innerWidth > 925){
                lastL = window.innerWidth *0.03365
            }
            d=`
            M 25,${initialY}
            L 27 ,${initialY}
            Q 29, ${initialY}
            29, ${firstCurveY}
            L 29, 9.5
            Q 29, 8.8
            30, 8.8
            L ${lastL}, 8.8
            `
        }else{
            
            if(window.innerWidth < 1100){
                // this initial Y is to bring the SVG to each div in order to connect it from SenseAI div to gateway Div
                let initialY = 19.85 + 13.45*(index-1)
                let firstCurveY = initialY - 1
                let lastL = window.innerWidth *0.03535
                if(window.innerWidth > 930){
                    lastL = window.innerWidth *0.03365
                }
                d=`
                M 25,${initialY}
                L 27 ,${initialY }
                Q 29, ${initialY}
                29, ${firstCurveY}
                L 29, 9.5
                Q 29, 8.8
                30, 8.8
                L ${lastL}, 8.8
                `
            } else {
                
                // Test *************
                // this initial Y is to bring the SVG to each div in order to connect it from SenseAI div to gateway Div
                let initialY = 19.85 + 13.45*(index-1)
                let lastL = window.innerWidth *0.03385
                lastL = lastL - 0.5
                d=`
                M 25,${initialY}
                C 30, ${initialY +2}
                  35, 8
                  ${lastL}, 8.8
                `
                // Test *************

                // // this initial Y is to bring the SVG to each div in order to connect it from SenseAI div to gateway Div
                // let initialY = 19.85 + 13.45*(index-1)
                // let firstCurveY = initialY - 1
                // let lastL = window.innerWidth *0.03385
                // lastL = lastL - 0.5
                // let extendX1 = 29 + (index*0.5)
                // let extendX2 = extendX1 + 1
                // let extendX3 = extendX2 + 2
                // d=`
                // M 25,${initialY}
                // L ${extendX1} ,${initialY}
                // Q ${extendX2}, ${initialY}
                // ${extendX2}, ${firstCurveY}
                // L ${extendX2 + index}, 9.5
                // Q ${extendX2+ index}, 8.8
                // ${extendX3 + index}, 8.8
                // L ${lastL}, 8.8
                // `
                // d=`
                // M 25,${initialY}
                // L 29 ,${initialY}
                // Q 31, ${initialY}
                //   31, ${firstCurveY}
                // L 31, 9.5
                // Q 31, 8.8
                //   32, 8.8
                // L ${lastL}, 8.8
                // `
            }
        }
    }
    // if(window.innerWidth < 1100){
    //     let lastL = window.innerWidth *0.03535
    //     if(window.innerWidth > 930){
    //         lastL = window.innerWidth *0.03365
    //     }
    //     d=`
    //     M 25,${initialY}
    //     L 27 ,${initialY}
    //     Q 29, ${initialY}
    //       29, ${firstCurveY}
    //     L 29, 9.5
    //     Q 29, 8.8
    //       30, 8.8
    //     L ${lastL}, 8.8
    //     `
    // }else{
    //     // this initial Y is to bring the SVG to each div in order to connect it from SenseAI div to gateway Div
    //     let initialY = 19.85 + 13.45*(index-1)
    //     let firstCurveY = initialY - 1
    //     if(window.innerWidth < 1100){
    //         let lastL = window.innerWidth *0.03535
    //         if(window.innerWidth > 930){
    //             lastL = window.innerWidth *0.03365
    //         }
    //         d=`
    //         M 25,${initialY}
    //         L 27 ,${initialY}
    //         Q 29, ${initialY}
    //           29, ${firstCurveY}
    //         L 29, 9.5
    //         Q 29, 8.8
    //           30, 8.8
    //         L ${lastL}, 8.8
    //         `
    //     } else {
    //         let lastL = window.innerWidth *0.03385
    //         lastL = lastL - 0.5
    //         let extendX1 = 29 + (index*0.5)
    //         let extendX2 = extendX1 + 1
    //         let extendX3 = extendX2 + 2
    //         d=`
    //         M 25,${initialY}
    //         L ${extendX1} ,${initialY}
    //         Q ${extendX2}, ${initialY}
    //           ${extendX2}, ${firstCurveY}
    //         L ${extendX2}, 9.5
    //         Q ${extendX2}, 8.8
    //           ${extendX3}, 8.8
    //         L ${lastL}, 8.8
    //         `
    //         // d=`
    //         // M 25,${initialY}
    //         // L 29 ,${initialY}
    //         // Q 31, ${initialY}
    //         //   31, ${firstCurveY}
    //         // L 31, 9.5
    //         // Q 31, 8.8
    //         //   32, 8.8
    //         // L ${lastL}, 8.8
    //         // `
    //     }
    // }

    const constainerStyles = (index) => {
        let calcMargin = ''
        if(index !== 0){
            let num = -112*index
            calcMargin = num + 'px'
        }
        return{
          marginTop: calcMargin,
        }
      }

  return (
    <div style={constainerStyles(index)} className='r-[500px]'>
        {/* <svg
        width="5000"
        height="5000"
        viewBox={`0 0 600 600`}
        initial="hidden"
        animate="visible"
      >
        <path
          d={d}
          fill='none'
          stroke={isDark ? "#475569" : "#cbd5e1"}
          strokeWidth={0.2}
          variants={draw}
          custom={3}
        />
        </svg> */}
        <motion.svg
        width="5000"
        height="5000"
        viewBox={`0 0 600 600`}
        initial="hidden"
        animate="visible"
      >
        <motion.path
          d={d}
          fill='none'
          stroke={isDark ? "#475569" : "#cbd5e1"}
          strokeWidth={0.2}
          variants={draw}
          custom={3}
        />
        </motion.svg>
        
    </div>
  )
}

export default AnimateLineCurved