import React, { useEffect, useRef, useState } from 'react'
import { darkModeState } from '../../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { motion } from "framer-motion";
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import { getData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import { CircularProgress } from '@mui/material';
import toast from 'react-hot-toast';

function WorkOrderImageUploadSection({setImageUuid}) {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const isDark = useRecoilValue(darkModeState);
    const hiddenImageVideoInput = useRef(null);
    const [selectedImagesVideos, setSelectedImagesVideos] = useState([]);
    const [isDragOver, setIsDragOver] = useState(false);

    useEffect(() => {
        if (selectedImagesVideos && Object.keys(selectedImagesVideos).length > 0) {
            let uuid = selectedImagesVideos.filter(file => file.file_uuid !== null && file.file_uuid !== undefined).map(file => file.file_uuid);
            setImageUuid(uuid)
        }else{
            setImageUuid([])
        }

    }, [selectedImagesVideos])



    const handleImageVideoClick = event => {
        hiddenImageVideoInput.current.click();
      };

    const handleImageVideoChange = event => {
        
        let uploadedImages = Array.from(event.target.files)
        const newImages = uploadedImages.map((file) => ({
            file,
            preview: URL.createObjectURL(file),
            file_uuid: null, // Initialize as null until the server provides it
            uploading: true, // Set uploading to true initially
        }));

        setSelectedImagesVideos((prev) => [...newImages, ...prev]);
        event.target.value = ''; // Reset input value
        // Reset input value
        event.target.value = '';

        
        newImages.map( async (currentImg, index) => {
            let compressedFile = await convertToWebP(currentImg.file);
            compressedFile.name = currentImg.name + '.webp'
            
            await handleUpload(compressedFile, currentImg)
            
        })
    };

    const handleFileDrop = (files) => {
        validateAndProcessImages(files, (validImageFiles) => {
            // Your image processing logic here
            // For example:
            const processedFiles = Array.from(validImageFiles).map(file => ({
              file: file,
              preview: URL.createObjectURL(file),
              uploading: true,
              file_uuid: null
            }));
        
            // Update your state with processed files
            setSelectedImagesVideos(prevFiles => [...processedFiles, ...prevFiles ]);

            processedFiles.map( async (currentImg, index) => {
                let compressedFile = await convertToWebP(currentImg.file);
                compressedFile.name = currentImg.name + '.webp'
                
                await handleUpload(compressedFile, currentImg)
                
            })
          });
        
      };
    
      const handleDragOver = (event) => {
        event.preventDefault();
        // console.log('handleDragOver event', event)
        setIsDragOver(true);
      };
    
      const handleDragLeave = () => {
        setIsDragOver(false);
      };

      const validateAndProcessImages = (files, processFunction) => {
        // Check if files exist
        if (!files || files.length === 0) {
            toast.error('No files selected');
          return;
        }
      
        // Supported image MIME types
        const imageMimeTypes = [
          'image/jpeg', 
          'image/png', 
          'image/gif', 
          'image/webp', 
          'image/bmp', 
          'image/svg+xml'
        ];
      
        // Separate valid and invalid files
        const fileArray = Array.from(files);
        const validImageFiles = fileArray.filter(file => 
          imageMimeTypes.includes(file.type)
        );
        const invalidFiles = fileArray.filter(file => 
          !imageMimeTypes.includes(file.type)
        );
      
        // Alert about invalid files if any exist
        if (invalidFiles.length > 0) {
          const invalidFileDetails = invalidFiles.map(file => 
            `${file.name} (${file.type})`
          ).join(', ');
      
          toast.error(`The following files are not images and will be skipped:
          ${invalidFileDetails}`);
        }
      
        // Process only valid image files
        if (validImageFiles.length > 0) {
          processFunction(validImageFiles);
        } else {
            toast.error('No valid image files found.');
        }
      };

    

    const convertToWebP = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
              const canvas = document.createElement('canvas');
              let width = img.width;
              let height = img.height;
    
              // Calculate new dimensions
              if (width > height) {
                if (width > 1600) {
                  height *= 1600 / width;
                  width = 1600;
                }
              } else {
                if (height > 1600) {
                  width *= 1600 / height;
                  height = 1600;
                }
              }
    
              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0, width, height);
              
              canvas.toBlob((blob) => {
                if (blob) {
                  resolve(blob);
                } else {
                  reject(new Error('WebP conversion failed'));
                }
              }, 'image/webp');
            };
            img.onerror = () => reject(new Error('Image loading failed'));
            img.src = event.target.result;
          };
          reader.onerror = () => reject(new Error('File reading failed'));
          // console.log('file', file)
          reader.readAsDataURL(file);
        });
      };

    const handleUpload = async (current_file, current_img) => {
    
        return new Promise((resolve, reject) => {
          try {
    
            let url = `?file_name=${current_file.name}&file_size=${current_file.size}&file_type=${current_file.type}&file_is_compressed=true`
            var previewUrl = current_img.preview
            
            // console.log('current_img', current_img)
            getData(apiLink + '/v1/organizations/' + selectedOrganization + `/file_upload_request` + url)
            .then(async response => {
                if(response.ok ){
                const data = await response.json();
    
                  if(data){
                    console.log('data', data.data)
                      let url = data.data.signed_url
                      let file_uuid = data.data.file_uuid

                        
                      setSelectedImagesVideos(prevFiles => 
                        prevFiles.map(file => 
                          file.preview === previewUrl 
                            ? { 
                                ...file, 
                                uploading: false, 
                                file_uuid: file_uuid
                              }
                            : file
                        )
                      );
    
    
                  }else{
                    reject('error');
                  }
                }else{
                  reject('error');
                }
            })
    
            
          } catch (error) {
            console.error('Error uploading files:', error);
            reject(error);
          }
        })
      }


  return (
    <div>
        <div 
            className='mt-2' 
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={(event) => {
            event.preventDefault();
            setIsDragOver(false);
            handleFileDrop(event.dataTransfer.files);
            }}
        >
        <input
            type="file"
            multiple
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleImageVideoChange}
            ref={hiddenImageVideoInput}
        />
        <div
            onClick={handleImageVideoClick}
            className={`flex items-center justify-center border-[1px] border-dashed  ${isDark? `${isDragOver ? 'border-slate-400 bg-slate-950 text-gray-300':'border-slate-600 bg-slate-900 text-gray-400'}`:`${isDragOver ? 'border-blue-500 bg-blue-100 text-blue-600':'border-blue-300 bg-blue-50 text-blue-500'}`}  w-full transition-all duration-500 ease-in-out h-28 rounded-xl cursor-pointer mt-2`}
        >
            <div className='flex flex-col justify-center text-center'>
            <div className={`flex justify-center text-center `}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="group-hover:scale-110 group-hover:duration-500 w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                </svg>
            </div>
            <div className={`text-sm text-center my-auto `}>
                Add or drag pictures
            </div>

            </div>
        </div>
        <div className=''>
            {selectedImagesVideos && Object.keys(selectedImagesVideos).length > 0 && (
                <div className={`mt-2 flex space-x-2 overflow-x-auto message_window_scrollbar ${isDark ? 'scrollbar_dark' : 'scrollbar_light'} `}
                >
                    {selectedImagesVideos.map((file, index) => (
                    <motion.div
                        key={`compressedSelectedImageContainer-${file.preview}`}
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className='relative shrink-0 w-full max-w-[250px]'
                    >
                        <motion.button  
                        whileHover={{scale: 1.05}} 
                        whileTap={{scale:0.95}}   
                        className={`absolute right-0 top-0 z-10 ${isDark?'bg-slate-800/80 text-gray-300':'bg-slate-800/80 text-gray-300'} rounded-full`}
                        onClick={() => {
                            // Logic to remove the file
                            setSelectedImagesVideos(prevFiles => 
                              prevFiles.filter(f => f.preview !== file.preview)
                            )
                          }}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        </motion.button>
                        {file.uploading && (
                            <div className="absolute inset-0 flex items-center justify-center ">
                                <CircularProgress size="24px" />
                            </div>
                        )}

                        <img 
                        src={file.preview} 
                        alt="Captured" 
                        className='object-fill rounded-lg w-full max-h-[300px]' 
                        />
                    </motion.div>
                    ))}
                </div>
                )}
        </div>
        </div>
    </div>
  )
}

export default WorkOrderImageUploadSection