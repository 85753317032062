import { PencilIcon } from '@heroicons/react/solid';
import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import { useRecoilValue } from 'recoil';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import { putData } from '../../../ApiCalls/DataApis';
import { darkModeState } from '../../../atoms/darkModeState';
import DeleteDowntimeCategoryModal from './DeleteDowntimeCategoryModal'
import { userAuthorization } from '../../../atoms/userStates';

function DowntimeCategoryButton({categoryType, selectedDowntimeCategory, handleSelectedDowntimeCategory, refreshDowntimeCategories}) {
    const isDark = useRecoilValue(darkModeState)
    const [editCategory, setEditCategory]= useState(false)
    const [categoryName, setCategoryName] = useState(categoryType.downtime_category_name)
    const [loadingSave, setLoadingSave] = useState(false)
    const isUserAuthorized = useRecoilValue(userAuthorization)

    const handleCancel = () => {
        setCategoryName(categoryType.downtime_category_name)
        setEditCategory(false)
    }

    const handleSave = () => {
        try {
            if(!loadingSave){
                setLoadingSave(true)
                putData(apiLink + '/v1/downtime_categories/' + categoryType.downtime_category_id , 
                {"downtime_category_name": categoryName })
                .then(async response => {
                    if(response.ok){
                        // const data = await response.json();
                        toast.success('Downtime category name changed')
                        setLoadingSave(false)
                        setEditCategory(false)
                        refreshDowntimeCategories()
                    }
                    else{
                        try{
                            const data = await response.json();
                            setLoadingSave(false)
                            toast.error(data.message)
                        }catch (e){
                            toast.error('Something went wrong')
                            setLoadingSave(false)
                            }
                    }
                })
            }
            
        } catch (error) {
            console.log(error.message)
        }
    }

  return (
    <div key={categoryType.downtime_category_id} >
        {!editCategory ? (
            <div key={categoryType.downtime_category_id}
                className={`${isDark?'text-gray-400 border-blue-800':'text-gray-500 border-blue-800'} ${Object.keys(selectedDowntimeCategory).length > 0 && selectedDowntimeCategory.downtime_category_id == categoryType.downtime_category_id && isDark && 'bg-slate-100/20'} ${Object.keys(selectedDowntimeCategory).length > 0 &&  selectedDowntimeCategory.downtime_category_id == categoryType.downtime_category_id && !isDark && 'bg-slate-100'} px-2.5 mr-2 border-[0.01em] rounded my-1 text-base`}>
                <button 
                    onClick={() => handleSelectedDowntimeCategory(categoryType)}
                    className='py-0.5'
                    >
                    {categoryType.downtime_category_name}
                </button>
                {categoryType.downtime_category_organization_id && 
                <button 
                    className={`${isDark?'text-blue-400 hover:text-blue-200 disabled:text-blue-400':'text-blue-500 hover:text-blue-900 disabled:text-blue-500'} ml-1 align-middle disabled:opacity-50`}
                    onClick={() => setEditCategory(true)}
                    disabled={!isUserAuthorized}
                >
                    <PencilIcon className={` w-5 h-5 pb-0.5`}/>
                </button>
                }
            </div>

        ):(
            <div className='h-7 flex my-1.5 mr-2'>
                <input 
                    className={`w-fit block bg-transparent border rounded-md py-0.5 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
                    type="text" 
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                />
                <button className={`shadow rounded-md py-1 px-2 border-[1px] text-sm ${isDark?'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} flex ml-2`} onClick={handleCancel}>
                    Cancel
                </button>
                <button disabled={loadingSave} className={`relative shadow rounded-md ml-1 py-1 px-2 border-[1px] border-blue-600 text-sm bg-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white`} onClick={handleSave}>
                    Edit
                    {loadingSave && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-0 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                    }
                </button>
                <DeleteDowntimeCategoryModal categoryType={categoryType} refreshDowntimeCategories={refreshDowntimeCategories} />

            </div>
        )}
    </div>
    
  )
}

export default DowntimeCategoryButton