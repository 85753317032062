const ProgressBar = ({ completed, arrowAvg, secondCompleted, grayoutSection, isMobile }) => {

  // console.log('completed', completed)
  // console.log('secondCompleted', secondCompleted)
  // console.log('arrowAvg', arrowAvg)
  // console.log('grayoutSection', grayoutSection)
  
    var bgColor = '#16a34a'
    if(completed && arrowAvg && completed >= arrowAvg){
      bgColor = '#dc2626'
      // bgColor = '#ef4444'
    }


    const containerStyles = {
      height: 10,
      width: isMobile? '100%':'80%',
      backgroundColor: "#e0e0de",
      borderRadius: 50,
      marginTop: 4,
      opacity: grayoutSection && .4,
      // marginLeft:'auto',
      // marginRight:'auto',
      // position:'relative'
    }
  
    // const fillerStyles3 = {
    //   height: '100%',
    //   width: `${secondCompleted ? secondCompleted : '0'}%`,
    //   // backgroundColor: '#bbf7d0',
    //   backgroundColor: bgSecondColor,
    //   borderRadius: 50,
    //   transition: 'width 1s ease-in-out',
    //   top:0,
    //   left:0,
    //   position:'absolute',
    // }
    
    const fillerStyles = {
      height: '100%',
      width: `${completed ? completed : '0'}%`,
      backgroundColor: bgColor,
      borderRadius: 'inherit',
      textAlign: 'right',
      transition: 'width 1s ease-in-out',
      position:'relative',
      
    }
    const fillerStyles2 = {
      height: '1%',
      width: `100%`,
      width: `${arrowAvg? arrowAvg: '0'}%`,
      backgroundColor: 'transparent',
      borderRadius: 'inherit',
      textAlign: 'right',
      transition: 'width 1s ease-in-out',
      position:'relative',
      opacity: .5,
    }
    
    const TooltipTest = {
      position:'absolute',
      left:`100%`,
      top:'-22px',
      marginLeft:'-8px',
      // padding: 5,
      // color: 'white',
      // fontWeight: 'bold'
    }
  
    return (
      
      <div style={containerStyles} className='flex flex-col bg-slate-200 dark:bg-slate-600 relative'>
         
        <div style={fillerStyles}>
        </div>
        <div style={fillerStyles2}>
          <div style={TooltipTest}>
            <svg xmlns="http://www.w3.org/2000/svg"  className="h-3 w-3 text-gray-600 dark:text-gray-400" fill="currentColor" viewBox="0 0 16 16">
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
            </svg>
          </div>
        </div>
        {/* <div style={fillerStyles3} /> */}
      </div>
    );
  };
  
  export default ProgressBar;