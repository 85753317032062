import Avatar from '@mui/material/Avatar';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { formatRelativeTime } from '../../utils/DateFormating';

function DirectChartRow({convo, selectedOrganization, selectedChatId, userInfo, index, chatUnreadDic}) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const isDark = useRecoilValue(darkModeState)
    const [messageHeader, setMessageHeader] = useState([])
    const [unreadCount, setUnreadCount] = useState(0)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {

        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
  
    },[])

    useEffect(() => {
        if(convo && Object.keys(convo).length > 0 && convo.chat_uuid){
            if(chatUnreadDic[convo.chat_uuid] ){

                if(chatUnreadDic[convo.chat_uuid].unread_count == '0' || chatUnreadDic[convo.chat_uuid].unread_count == 0 || !chatUnreadDic[convo.chat_uuid].unread_count){
                    setUnreadCount(null)
                }else{
                    let count = chatUnreadDic[convo.chat_uuid].unread_count
                    setUnreadCount(Number(count))
                }
            }

        }
        // if(convo && Object.keys(convo).length > 0 && convo.chatroom_uuid){
        //     if(chatUnreadDic[convo.chatroom_uuid] ){

        //         if(chatUnreadDic[convo.chatroom_uuid].unread_count == '0' || chatUnreadDic[convo.chatroom_uuid].unread_count == 0 || !chatUnreadDic[convo.chatroom_uuid].unread_count){
        //             setUnreadCount(null)
        //         }else{
        //             let count = chatUnreadDic[convo.chatroom_uuid].unread_count
        //             setUnreadCount(Number(count))
        //         }
        //     }

        // }

    },[chatUnreadDic, convo])

    
    
    useEffect(() => {
        if(convo && convo.members && Object.keys(convo.members).length > 0){
            for (let userHandle in convo.members) {
                // I dont know if I should use "user_username" or "user_username_public" or the array user_usernames
                if (userHandle !== userInfo.user_username) {
                    setMessageHeader(convo.members[userHandle])
                  return ; // Return the key of the first non-matching value
                }
              }
        }

    }, [convo])

    const lastMessage = (messages) => {
        try{
            if(messages && Object.keys(messages).length > 0){
                return messages.message_text
            }
        }catch(e){
            
        }
        
    }

    
    const navToChat = () => {
        if(isMobile){
            navigate(`/${selectedOrganization}/chats?chatId=${convo.chat_uuid}&section=messages&platform=mobile`)
        }else{
            navigate(`/${selectedOrganization}/chats?chatId=${convo.chat_uuid}&section=messages`)
        }
    }
   


  return (
    <div 
        className={`flex ${selectedChatId == convo.chat_uuid ? 'bg-slate-200 dark:bg-slate-700': 'hover:bg-slate-100 dark:hover:bg-slate-800'} items-center truncate  cursor-pointer rounded `}
        // className={`flex ${selectedChatId == convo.chat_uuid ? 'bg-slate-200 dark:bg-slate-700': 'hover:bg-slate-100 dark:hover:bg-slate-800'} items-center truncate pl-1 pr-2 py-1 cursor-pointer rounded-xl border-b-[0.01em] dark:border-slate-600`}
        onClick={navToChat} 
    >
        <div className={`flex w-full items-center truncate pl-1 pr-2 py-1 cursor-pointer border-b-[0.01em] dark:border-slate-600`}>
            <div className='flex items-center'>
                <Avatar
                    sx={{ width: 48, height: 48, bgcolor: messageHeader.user_color? messageHeader.user_color : '' }}
                    style={{
                        background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${messageHeader.user_color? messageHeader.user_color:''} 100%)`,
                      }}
                    alt={messageHeader.user_first_name || ''}
                    src={messageHeader.user_image_url || ''}
                />
            </div>
            <div className={`flex flex-col truncate w-full `}>
                <div className='flex justify-between items-end'>
                    <div className={`pl-2 ${!convo.chatroom_member_communication_accepted && 'opacity-50'}  text-gray-600 dark:text-gray-300 truncate text-sm  font-medium`}>{messageHeader.user_first_name || ''}</div>
                    <div className={`pl-2 ${!convo.chatroom_member_communication_accepted && 'opacity-50'} text-gray-500 dark:text-gray-400 text-xs`}>{formatRelativeTime(convo.last_message_created_at)}</div>
                </div>
                <div className='flex justify-between items-end'>
                    <span className={`${!convo.chatroom_member_communication_accepted && 'opacity-50'} pl-2 text-gray-500 dark:text-gray-400 truncate text-sm`}>{lastMessage(convo.last_message)}</span>
                    {(unreadCount != null && unreadCount &&  unreadCount > 0 && unreadCount != "0" || unreadCount != 0) ? (
                        <div className={`${unreadCount === null || unreadCount === 0 || unreadCount === '0'? 'hidden':'bg-slate-100'}  text-gray-500 dark:bg-slate-600 dark:text-white text-xs text-center my-auto min-w-[24px] rounded-full p-1 ml-auto`}>
                        {unreadCount}
                        </div>
                    ):(
                        <div/>
                    )
                    }
                </div>
            </div>

        </div>
    </div>
  )
}

export default DirectChartRow