import React, { useEffect, useState } from 'react';
import { Popover, Transition, PopoverPanel } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Fragment } from 'react'
import { motion } from "framer-motion"
import { useLocation, useSearchParams } from 'react-router-dom'
import { globalSelectedOrganization, globalSelectedOrganizationTimezone } from '../../../atoms/orgStates'
import { useRecoilValue } from 'recoil'
import { getData } from '../../../ApiCalls/DataApis'
import { apiLink } from '../../../ApiCalls/ApisVariables'



function PartOperationFilterBtn() {
  const [mainCheckboxes, setMainCheckboxes] = useState([])
    // const [mainCheckboxes, setMainCheckboxes] = useState(operationsJson.map((checkboxData) => ({
    //   checked: false,
    //   subCheckboxes: Array(checkboxData.operation_count).fill(false),
    //   })));
    const [filterText, setFilterText] = useState([])
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const selectedOrganizationTimeZone = useRecoilValue(globalSelectedOrganizationTimezone); 
   const [searchParams, setSearchParams] = useSearchParams()
   const location = useLocation();
   const [operationsJson, setOperationsJson] = useState([])
   const [showCheckboxes, setShowCheckboxes] = useState(false)


   useEffect(() => {
    setMainCheckboxes([])
    setShowCheckboxes(false)
    if(selectedOrganization ){
      getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/part_operations_grouped')
      .then(async response => {
          const data = await response.json();
          if(response.ok ){
              if(data && data.data){
                  // console.log('data.data', data.data)
                  setOperationsJson(data.data)
                  setMainCheckboxes(data.data.map((checkboxData) => ({
                    checked: false,
                    subCheckboxes: Array(checkboxData.operation_count).fill(false),
                    })));
              }
          }
      })

    }

  }, [selectedOrganization])


   useEffect(() => {
     setShowCheckboxes(false)
     if(selectedOrganization && operationsJson && Object.keys(operationsJson).length > 0 && mainCheckboxes && Object.keys(mainCheckboxes).length > 0 ){
      // check to make sure that the main checkboxes length is the same as the asset json
      let assetDepartmentsLength  = Object.keys(operationsJson).length
      let checkboxDepartmentsLength = Object.keys(mainCheckboxes).length
      if(assetDepartmentsLength === checkboxDepartmentsLength){
        let doesMatch = []
        operationsJson.map((operationsList, index) =>{
          let operationsLength = Object.keys(operationsList.operations).length
          let checkLength = Object.keys(mainCheckboxes[index].subCheckboxes).length
          if(operationsLength === checkLength){
            doesMatch.push(true)
          }else{
            doesMatch.push(false)
          }
        })
        let isAllTrue = doesMatch.every(element => element === true);
        if(isAllTrue){
          setShowCheckboxes(true)
        }else{
          setShowCheckboxes(false)
        }
      }else{
        setShowCheckboxes(false)
      }

    }

   }, [selectedOrganization, operationsJson, mainCheckboxes])


   useEffect(() => {
        let currentSearch = location.search
        if(currentSearch &&  mainCheckboxes && Object.keys(mainCheckboxes).length > 0 && operationsJson && Object.keys(operationsJson).length > 0){
            let searchOperations = searchParams.getAll('operations')
            // console.log('searchOperations ', searchOperations)
            let operationNames = []
            // let checkboxState = mainCheckboxes
            // I had to create this below because the filter element wasn't getting reset if I removed everything from the URL
            let checkboxState = mainCheckboxes.map((checkboxData) => ({
              checked: false,
              subCheckboxes: checkboxData.subCheckboxes.map(() => false),
              }))
            searchOperations.map(currentOperationUuid => {
              operationsJson.map((part, index) =>{
                part.operations.map((op, subindex) => {
                  if(currentOperationUuid == op.operation_uuid){
                    operationNames.push(op.operation_name)

                    checkboxState[index].subCheckboxes[subindex] = true
                  }
                })
              })
            })
            

            checkboxState.map((checks, index) => {
              let partChecks = checks.subCheckboxes
              let isAllTrue = partChecks.every(v => v === true);
              if(isAllTrue){
                checkboxState[index].checked = true
                operationNames.unshift(operationsJson[index].part_name)
              }
              
            })
            setFilterText(operationNames)
            setMainCheckboxes(checkboxState)

        }

    }, [location, operationsJson])



    const handleMainCheckboxChange = (index) => {
        setMainCheckboxes((prevCheckboxes) =>
        prevCheckboxes.map((checkbox, i) =>
            i === index
            ? { ...checkbox, checked: !checkbox.checked, subCheckboxes: Array(checkbox.subCheckboxes.length).fill(!checkbox.checked) }
            : checkbox
        )
        );
    };

    const handleSubCheckboxChange = (index, subIndex) => {
        setMainCheckboxes((prevCheckboxes) =>
        prevCheckboxes.map((checkbox, i) =>
            i === index
            ? {
                ...checkbox,
                subCheckboxes: [
                    ...checkbox.subCheckboxes.slice(0, subIndex),
                    !checkbox.subCheckboxes[subIndex], // Update subcheckbox state
                    ...checkbox.subCheckboxes.slice(subIndex + 1),
                ],
                checked: !checkbox.subCheckboxes[subIndex]
                    ? checkbox.subCheckboxes.every((subChecked) => subChecked) // Check main checkbox only if subcheckbox is checked
                    : !checkbox.subCheckboxes.some((subChecked) => subChecked), // Uncheck main checkbox if any subcheckbox is unchecked
                }
            : checkbox
        )
        );
    };


    const handleApplyFilter = () => {
      // putting all selected parts into the button for visualization
      let tempOps = []
      mainCheckboxes.map((part, index) =>{
          if(part.checked){
            part.subCheckboxes.map((operation, subIndex) => {
              tempOps.push(operationsJson[index].operations[subIndex].operation_uuid)
            })
          }else {
            part.subCheckboxes.map((operation, subIndex) => {
              if(operation){
                tempOps.push(operationsJson[index].operations[subIndex].operation_uuid)
              }
            })
          }
      })
      // console.log('tempOps',tempOps)

      
      let select = searchParams.getAll('select')
      let departments = searchParams.getAll('departments')
      let machines = searchParams.getAll('machines')
      let parts = searchParams.getAll('parts')
      let machine_types = searchParams.getAll('machine_types') 
      let tags = searchParams.getAll('tags')
      let group_by = searchParams.getAll('group_by')
      let sort_by = searchParams.getAll('sort_by')
      let calculate_oee_by = searchParams.getAll('calculate_oee_by')
      let shift_name = searchParams.getAll('shift_name')
      let shift_start_time = searchParams.getAll('shift_start_time')
      let shift_date = searchParams.getAll('shift_date')
      let shift_entire_day = searchParams.getAll('shift_entire_day')
      let shift_by_min = searchParams.getAll('shift_by_min')
      let maximize = searchParams.getAll('maximize')
      let view = searchParams.getAll('view')
      let url_token = searchParams.getAll('url_token')
      let operations_expanded = searchParams.getAll('operations_expanded')
      let assets_expanded = searchParams.getAll('assets_expanded')
      let platform = searchParams.getAll('platform')
      let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')
      let from = searchParams.getAll('from')
      let to = searchParams.getAll('to')
      let downtime_severity_uuids = searchParams.getAll('downtime_severity_uuids')
      let only_with_notes = searchParams.getAll('only_with_notes')
      let group_by_advanced = searchParams.getAll('group_by_advanced')
      let sort_by_advanced = searchParams.getAll('sort_by_advanced')
      let time_period = searchParams.getAll('time_period')
      let show_percentage = searchParams.getAll('show_percentage')
      
      setSearchParams({select,parts, departments, machines, machine_types, tags, group_by, sort_by,calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day,shift_by_min, maximize, view, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled, from, to, downtime_severity_uuids, only_with_notes, group_by_advanced, sort_by_advanced, time_period, show_percentage, operations: tempOps})
    
  }

  return (
    <div >
      {showCheckboxes &&
        <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? 'bg-slate-100 dark:bg-slate-700' : ''}
                border border-blue-600 border-dashed rounded-full flex pl-1 pr-3 py-0.5 items-center outline-none max-w-[250px] `}
            >
                <div className='bg-white w-3 h-3 rounded-full m-1'>
                    <svg xmlns="http://www.w3.org/2000/svg" className={`transition ease-in-out duration-300 ${Object.keys(operationsJson).length > 0? ' rotate-45 ':'rotate-0'} h-5 w-5 ml-[-4px] mt-[-4px] text-blue-600 `} viewBox="0 0 20 20" fill="currentColor" >
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                    </svg>
                </div>
                <div className='pl-1 text-sm text-gray-600 dark:text-gray-300'>
                    Operations
                </div>
                {Object.keys(filterText).length > 0 && Object.keys(filterText).length < 3 &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                    {filterText.map((filtered,index) => (
                        <div key={index} className='flex '>
                        {index > 0 && <div className='text-blue-600 dark:text-blue-400 text-xs'>,</div>}
                        <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{filtered}</div>
                        </div>
                    ))}
                    <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                </div>
                }
                {Object.keys(filterText).length >= 3 &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                    <div className='flex '>
                        <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{filterText[0]} and {Object.keys(filterText).length - 1} more</div>
                    </div>
                    <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                </div>
                }
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel className="absolute left-32 z-20 mt-3 w-[275px] -translate-x-1/2 transform ">
                <div className=" overflow-hidden rounded-lg shadow-lg dark:shadow-lg-invert ring-1 ring-black ring-opacity-5 bg-white dark:bg-slate-800 p-3">
                   {mainCheckboxes && Object.keys(mainCheckboxes).length > 0 && operationsJson && Object.keys(operationsJson).length > 0 && 
                   <div className='max-h-[400px] overflow-y-scroll scrollbar-hide'>
                     {mainCheckboxes.map((checkbox, index) => (
                      <div key={index} className={`mb-1.5`}>
                        <input
                          id={`department-${index}`}
                          type="checkbox"
                          className="checkboxBtn"
                          checked={checkbox.checked}
                          onChange={() => handleMainCheckboxChange(index)}
                        />
                          <label htmlFor={`department-${index}`} className="ml-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                              {operationsJson[index].part_name}
                          </label>
                        <ul className='pl-4'>
                          {checkbox.subCheckboxes.map((subChecked, subIndex) => (
                            <li key={subIndex} className='flex items-center mb-2'>
                              <input
                                  id={`department-machine-${index}-${subIndex}`}
                                type="checkbox"
                                className="checkboxBtn"
                                checked={subChecked}
                                onChange={() => handleSubCheckboxChange(index, subIndex)}
                              />
                              <label htmlFor={`department-machine-${index}-${subIndex}`} className=" flex flex-col ml-2 text-sm font-medium text-gray-600 dark:text-gray-300">
                                 <span>
                                   {operationsJson[index].operations[subIndex].operation_name}
                                 </span>
                              </label>
 
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}

                   </div>
                   }
                        

                        <motion.button  
                            whileHover={{ scale: 1.02 }} 
                            whileTap={{ scale: 0.98 }} 
                            onClick={ () => {
                                handleApplyFilter()
                                close()
                            }} 
                            className='w-full text-sm p-1 bg-blue-600 text-white rounded-md mt-2' >
                                Apply
                        </motion.button>
                </div>
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
      }
    </div>
  )
}

export default PartOperationFilterBtn