
export const createColorPalette = (isDark) => ({
    mainPalette: {
      primaryGreen: isDark ? '#4ade80' : '#16a34a',
      secondaryGreen: isDark ? '#166534' : '#4ade80',
      primaryRed: isDark ? '#b91c1c' : '#dc2626',
      secondaryRed: isDark ? '#450a0a' : '#f87171',
    },
    secondaryPalette: {
      primaryGreen: '#22c55e', // Same in both modes
      secondaryGreen: isDark ? '#14532d' : '#86efac',
      primaryRed: isDark ? '#991b1b' : '#ef4444',
      secondaryRed: isDark ? '#450a0a' : '#fca5a5',
    },
  });