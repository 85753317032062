import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { darkModeState } from "../../atoms/darkModeState";
import { useRecoilValue } from "recoil";

function BarChartList({chartHeight,containerHeight, barChartData, barChartCategories, barChartHourlyGoal}) {
    const isDark = useRecoilValue(darkModeState);
    const [hourCountBarChartOptions, setHourCountBarChartOptions] = useState({
        chart: {
            height: chartHeight,
            type: 'column',
            backgroundColor:'transparent',
        },
        time: {
            useUTC: false
          },
        credits: {
            text: 'iotflows.com',
            href: 'http://www.iotflows.com'
          },
        legend:{ enabled:false },
        title: {
            text: null
        },
        xAxis: {
            categories: ['', '', '', '', '', '', '', '', ''],
            labels: {
                rotation: 0,
                style: {
                    color: '#6b7280',
                    // fontWeight: 600
                }
            }
        },
        yAxis: [{ // Primary yAxis
            gridLineWidth: 0,
            // visible:false,
            title: {
                text: null,
                // style: {
                //     color: Highcharts.getOptions().colors[2]
                // }
            },
            // opposite: true
        }, 
        { // Secondary yAxis
            gridLineWidth: 0,
            // visible:false,
            title: {
                text: null,
                // style: {
                //     color: '#0284c7'
                // }
            },
            opposite: true
    
        }
        ],
        
        tooltip: {
            shared: true
        },
        plotOptions: {
            column: {
                grouping: false,
                shadow: false,
                borderWidth: 0
            },
            series: {
                borderRadius: 3,
            }
        },
        series: [
        //     {
        //     name: 'Part Goal',
        //     color: 'rgba(2, 132, 199,0.8)',
        //     data: [50, 70, 70, 80, 50, 0, 0],
        //     pointPadding: 0.3,
        //     // pointPlacement: -0.2
        // }, 
        // {
        //     name: 'Loading...',
        //     color: 'rgba(56, 189, 248,.9)',
        //     data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
        //     pointPadding: 0,
        //     // pointPlacement: -0.2
        //     zones: [{
        //         value: barChartHourlyGoal,
        //         color: '#dc2626'
        //     }, {
        //         value: barChartHourlyGoal,
        //         // color: '#38bdf8'
        //         color: '#16a34a'
        //     }, {
        //         color: '#16a34a'
        //     }]
        // }, 
        ]
    })

    useEffect(() => {
        if(barChartData && barChartData.length > 0 && barChartCategories && barChartCategories.length > 0){
            setHourCountBarChartOptions({
                chart: {
                    height: chartHeight,
                    type: 'column',
                    backgroundColor:'transparent',
                },
                time: {
                    useUTC: false
                  },
                credits: {
                    text: 'iotflows.com',
                    href: 'http://www.iotflows.com'
                  },
                legend:{ enabled:false },
                title: {
                    text: null
                },
                xAxis: {
                    categories: barChartCategories ,
                    labels: {
                        rotation: 0,
                        style: {
                            color: isDark? '#d1d5db':'#4b5563'
                        }
                    }
                },
                yAxis: [{ // Primary yAxis
                    gridLineWidth: 0,
                    // visible:false,
                    labels: {
                        style: {
                            color: isDark? '#d1d5db':'#4b5563'
                        }
                    },
                    title: {
                        text: null,
                        // style: {
                        //     color: Highcharts.getOptions().colors[2]
                        // }
                    },
                    // opposite: true
                }, 
                { // Secondary yAxis
                    gridLineWidth: 0,
                    labels: {
                        style: {
                            color: isDark? '#d1d5db':'#4b5563'
                        }
                    },
                    title: {
                        text: null,
                        // style: {
                        //     color: '#0284c7'
                        // }
                    },
                    opposite: true
            
                }
                ],
                tooltip: {
                    shared: true
                },
                plotOptions: {
                    column: {
                        grouping: false,
                        shadow: false,
                        borderWidth: 0
                    },
                    series: {
                        borderRadius: 3,
                    }
                },
                series: [
                {
                    name: 'Part Count',
                    color: 'rgba(56, 189, 248,.9)',
                    data: barChartData,
                    pointPadding: 0,
                    // pointPlacement: -0.2
                    zones: [{
                        value: barChartHourlyGoal,
                        color: '#dc2626' //red
                    }, {
                        value: barChartHourlyGoal,
                        // color: '#38bdf8'
                        color: '#16a34a' //green
                    }, {
                        color: '#16a34a'
                    }]
                }, 
                ]
            })
        }
    }, [barChartCategories, barChartData, barChartHourlyGoal, isDark])

    
    return (
        <div>
            {/* highcharts bar chart */}
            <HighchartsReact
                constructorType={"chart"}
                containerProps={{ style: { height: containerHeight, maxWidth:'100%'} }} 
                highcharts={Highcharts}
                options={hourCountBarChartOptions}               
            />
            
        </div>
    )
}

export default BarChartList
