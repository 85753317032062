import React, { useEffect, useRef, useState } from 'react'
import { darkModeState } from '../../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { FlatTree, motion } from "framer-motion";
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import { getData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import { CircularProgress } from '@mui/material';
import toast from 'react-hot-toast';
import { formatFileSize } from '../../../utils/FileFormating';

function WOFileUploadSection({setFilesUuid}) {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const isDark = useRecoilValue(darkModeState);
    const hiddenFileInput = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isDragOver, setIsDragOver] = useState(false);

    useEffect(() => {
        if (selectedFiles && Object.keys(selectedFiles).length > 0) {
            let uuid = selectedFiles.filter(file => file.file_uuid !== null && file.file_uuid !== undefined).map(file => file.file_uuid);
            setFilesUuid(uuid)
        }else{
            setFilesUuid([])
        }

    }, [selectedFiles])



    const handleFileClick = event => {
        hiddenFileInput.current.click();
      };

    const handleFileChange = event => {
        
        let uploadedImages = Array.from(event.target.files)
        const newImages = uploadedImages.map((file) => ({
            file,
            preview: URL.createObjectURL(file),
            file_uuid: null, // Initialize as null until the server provides it
            uploading: true, // Set uploading to true initially
        }));

        setSelectedFiles((prev) => [...newImages, ...prev]);
        event.target.value = ''; // Reset input value
        // Reset input value
        event.target.value = '';

        
        newImages.map( async (currentFile, index) => {
            await handleUpload(currentFile.file, currentFile)
        })
    };


    const handleFileDrop = (files) => {
        const processedFiles = Array.from(files).map(file => ({
          file: file,
          preview: URL.createObjectURL(file),
          uploading: true,
          file_uuid: null
        }));
    
        // Update your state with processed files
        setSelectedFiles(prevFiles => [...processedFiles, ...prevFiles ]);

        processedFiles.map( async (currentFile, index) => {
            await handleUpload(currentFile.file, currentFile)
        })
        
      };
    
      const handleDragOver = (event) => {
        event.preventDefault();
        // console.log('handleDragOver event', event)
        setIsDragOver(true);
      };
    
      const handleDragLeave = () => {
        setIsDragOver(false);
      };

      const validateAndProcessImages = (files, processFunction) => {
        // Check if files exist
        if (!files || files.length === 0) {
            toast.error('No files selected');
          return;
        }
      
        // Supported image MIME types
        const imageMimeTypes = [
          'image/jpeg', 
          'image/png', 
          'image/gif', 
          'image/webp', 
          'image/bmp', 
          'image/svg+xml'
        ];
      
        // Separate valid and invalid files
        const fileArray = Array.from(files);
        const validImageFiles = fileArray.filter(file => 
          imageMimeTypes.includes(file.type)
        );
        const invalidFiles = fileArray.filter(file => 
          !imageMimeTypes.includes(file.type)
        );
      
        // Alert about invalid files if any exist
        if (invalidFiles.length > 0) {
          const invalidFileDetails = invalidFiles.map(file => 
            `${file.name} (${file.type})`
          ).join(', ');
      
          toast.error(`The following files are not images and will be skipped:
          ${invalidFileDetails}`);
        }
      
        // Process only valid image files
        if (validImageFiles.length > 0) {
          processFunction(validImageFiles);
        } else {
            toast.error('No valid image files found.');
        }
      };

    

    const handleUpload = async (current_file, current_img) => {
    
        return new Promise((resolve, reject) => {
          try {
    
            let url = `?file_name=${current_file.name}&file_size=${current_file.size}&file_type=${current_file.type}&file_is_compressed=false`
            var previewUrl = current_img.preview
            
            console.log('current_img', current_img)
            getData(apiLink + '/v1/organizations/' + selectedOrganization + `/file_upload_request` + url)
            .then(async response => {
                if(response.ok ){
                const data = await response.json();
    
                  if(data){
                    console.log('data', data.data)
                      let url = data.data.signed_url
                      let file_uuid = data.data.file_uuid

                        
                      setSelectedFiles(prevFiles => 
                        prevFiles.map(file => 
                          file.preview === previewUrl 
                            ? { 
                                ...file, 
                                uploading: false, 
                                file_uuid: file_uuid
                              }
                            : file
                        )
                      );
    
    
                  }else{
                    reject('error');
                  }
                }else{
                  reject('error');
                }
            })
    
            
          } catch (error) {
            console.error('Error uploading files:', error);
            reject(error);
        }
    })
}

console.log('selectedFiles', selectedFiles);

  return (
    <div>
        <div 
            className='mt-2' 
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={(event) => {
            event.preventDefault();
            setIsDragOver(false);
            handleFileDrop(event.dataTransfer.files);
            }}
        >
        <input
            type="file"
            multiple
            style={{ display: 'none' }}
            onChange={handleFileChange}
            ref={hiddenFileInput}
        />
        <div
            onClick={handleFileClick}
            className={`flex items-center justify-center border-[1px] border-dashed  ${isDark? `${isDragOver ? 'border-slate-400 bg-slate-950 text-gray-300':'border-slate-600 bg-slate-900 text-gray-400'}`:`${isDragOver ? 'border-blue-500 bg-blue-100 text-blue-600':'border-blue-300 bg-blue-50 text-blue-500'}`}  w-full transition-all duration-500 ease-in-out h-28 rounded-xl cursor-pointer mt-2`}
        >
            <div className='flex flex-col justify-center text-center'>
                <div className={`flex justify-center text-center `}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="group-hover:scale-110 group-hover:duration-500 w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                    </svg>

                </div>
                <div className={`text-sm text-center my-auto `}>
                    Add or drag files
                </div>
            </div>
        </div>
        <div className=''>
            {selectedFiles && Object.keys(selectedFiles).length > 0 && (
                <div className={`pt-2 pb-2 px-1 flex space-x-2 overflow-x-auto message_window_scrollbar ${isDark ? 'scrollbar_dark' : 'scrollbar_light'} `}
                >
                    {selectedFiles.map((file, index) => (
                    <motion.div
                        key={`fileContainer-${file.preview}`}
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className={`relative shrink-0 w-full max-w-[250px] truncate ${isDark ? 'bg-slate-700':'border-[0.01em] border-slate-200 bg-transparent'} px-2 py-1.5 rounded-xl shadow-md`}
                    >
                        {file.uploading && (
                            <div className={`absolute inset-0 flex items-center justify-center`}>
                                <CircularProgress size="24px" />
                            </div>
                        )}
                        <div className={`flex ${isDark ? 'text-gray-300':'text-gray-500'}  truncate items-center`}>
                            <div className='w-6'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                </svg>
                            </div>
                            <div className='pl-2 flex flex-col truncate'>
                                <h2 className={`text-sm ${isDark ? 'text-gray-300':'text-gray-600'} truncate`}>
                                    {file && file.file && file.file.name? file.file.name : 'Unknown'}
                                </h2>
                                <span className={`text-xs ${isDark ? 'text-gray-400':'text-gray-500'}`}>
                                    {formatFileSize(file.file.size)}
                                </span>
                            </div>
                            <motion.button  
                                whileHover={{scale: 1.05}} 
                                whileTap={{scale:0.95}}   
                                className={`ml-2 w-6 ${isDark?'text-gray-300':'text-gray-600'} rounded-full ml-auto z-[1]`}
                                onClick={() => {
                                    setSelectedFiles(prevFiles => 
                                    prevFiles.filter(f => f.preview !== file.preview)
                                    )
                                }}
                                >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                            </motion.button>
                        </div>
                    </motion.div>
                    ))}
                </div>
                )}
        </div>
        </div>
    </div>
  )
}

export default WOFileUploadSection