import React, { useEffect, useState, useRef } from 'react'
import { darkModeState } from '../../../../atoms/darkModeState'
import { useRecoilValue } from 'recoil'
import { getData, postData, putData } from '../../../../ApiCalls/DataApis'
import { apiLink } from '../../../../ApiCalls/ApisVariables'
import { toast } from 'react-hot-toast';
import BasicAreaLineChart from '../../../charts/SensorCalibrationCharts/BasicAreaLineChart'
import MqttService from '../../../../core/services/MqttService';
import { globalSelectedOrganization } from '../../../../atoms/orgStates';
import { AnimatePresence, motion } from "framer-motion";
import FinalAnalysisAreaLineChart from '../../../charts/SensorCalibrationCharts/FinalAnalysisAreaLineChart'
import CalibrationInfoModal from './CalibrationInfoModal'
import { LightTooltip } from '../../../../styles/Tooltip'

var finalData = []

function AnalyzeSensorData({sensor, getSensorList}) {
    const chartVibRef = useRef();
    const chartAcousticRef = useRef();
    const finalAnalyzesChartRef = useRef();
    const isDark = useRecoilValue(darkModeState)
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [firmwareVersion, setFirmwareVersion] = useState(null)
    const [loadingSensitivity, setLoadingSensitivity] = useState(false)
    const [loadingMomentum, setLoadingMomentum] = useState(false)
    const [loadingShockAccelerationThreshold, setLoadingShockAccelerationThreshold] = useState(false)
    const [loadingUptimeFilter, setLoadingUptimeFilter] = useState(false)
    const [sensorSensitivity, setSensorSensitivity] = useState(0.5)
    const [sensorMomentum, setSensorMomentum] = useState(0.5)
    const [sensorShockAccelerationThreshold, setSensorShockAccelerationThreshold] = useState(2)
    const [isVibChecked, setIsVibChecked] = useState(true);
    const [isAcousticsChecked, setIsAcousticsChecked] = useState(false);
    const [startRecordingData, setStartRecordingData] = useState(false)
    const [analyzedData, setAnalyzedData] = useState([])
    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [lastCalibratedString, setLastCalibratedString] = useState('');
    const [showGODview, setShowGODview] = useState(false)
    const [uptimeFilter, setUptimeFilter] = useState('')
    const [autoClassifyMins, setAutoClassifyMins] = useState('')
    const [loadingAutoClassifyMins, setLoadingAutoClassifyMins] = useState(false)

    let timerId;

    useEffect(() => {
        if(sensor && sensor.node_analysis_mode){
            // 0: VIBRATION, 1: ACOUSTIC, 2: VIBRATION_ACOUSTIC
            if(sensor.node_analysis_mode == 0 ){
                setIsVibChecked(true)
                setIsAcousticsChecked(false)
            }else if(sensor.node_analysis_mode == 1){
                setIsVibChecked(false)
                setIsAcousticsChecked(true)
            }else {
                setIsVibChecked(true)
                setIsAcousticsChecked(true)
            }
            
        }

    }, [sensor])

    useEffect(() => {
        // Use this inital function to start publishing and then use the timerId to have it called every 10 seconds afterwards
        if(startRecordingData){
            try {
                postData(apiLink + '/v1/nodes/' + sensor.node_uuid + '/calibration_request',
                { "should_publish_calibration": true,
                    "is_first_time": true})
                  .then(async response => {
                  const data = await response.json();
                  if(response.ok){
                    toast.success('Publishing Data')
                  }
                  else{
                      try{
                          toast.error('Something went wrong')
                          toast.error(data.message)
                      }catch (e){
                          toast.error(e.message)
                      }
                  }
                  })
                  
              } catch (error) {
                  console.log(error.message)
              }
        }
        timerId = setInterval(() => {
            if (startRecordingData && sensor) {
              try {
                  postData(apiLink + '/v1/nodes/' + sensor.node_uuid + '/calibration_request',
                  { "should_publish_calibration": true,
                    "is_first_time": false})
                    .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                        // console.log('Publishing Data')
                    }
                    else{
                        try{
                            toast.error('Something went wrong')
                            toast.error(data.message)
                        }catch (e){
                            toast.error(e.message)
                        }
                    }
                    })
                    
                } catch (error) {
                    console.log(error.message)
                }

            }
          }, 10000);

          return () => clearInterval(timerId); // Cleanup function
      }, [startRecordingData]);




    useEffect(() => {
        if(sensor && sensor.node_sensitivity){
        let sensitivity = parseFloat(sensor.node_sensitivity)
        setSensorSensitivity(sensitivity)

        }

        if(sensor && sensor.node_momentum){
        let momentum = parseFloat(sensor.node_momentum)
        setSensorMomentum(momentum)
        }

        if(sensor && sensor.node_shock_threshold){
        let shock_threshold = parseFloat(sensor.node_shock_threshold)
        setSensorShockAccelerationThreshold(shock_threshold)
        }
        if(sensor && sensor.asset_uptime_filter_seconds){
            let uptime_filter = parseFloat(sensor.asset_uptime_filter_seconds)
            setUptimeFilter(uptime_filter)
        }
        if(sensor && sensor.asset_auto_categorize_downtimes_less_than_min){
            let auto_classify_mins = parseFloat(sensor.asset_auto_categorize_downtimes_less_than_min)
            setAutoClassifyMins(auto_classify_mins)
        }

    },[])

    useEffect(() => {
        if(sensor && sensor.node_firmware_version){
            // Split the string and get the first substring
            const version = sensor.node_firmware_version.split(".");
            const initialString = version[0];

            // Check if the initial string is empty or not a valid number
            if (!initialString || isNaN(parseFloat(initialString))) {
            // Handle the invalid input as needed
            console.error("Error: Could not extract a valid number.");
            return null; // Or return a default value like 0 or NaN
            }

            // Convert the initial string to a number
            const initialNumber = parseFloat(initialString);
            setFirmwareVersion(initialNumber)
        }

    }, [sensor])

    useEffect(() => {
        try {
            if(sensor && sensor.node_uuid && selectedOrganization){
                setTimeout(() => {
                     //  MQTT Subcribe - We need a delay to ensure MQTT is connected before trying to subscribe
                     if(MqttService.isConnected) {
                        MqttService.subscribe("v1/organizations/" + selectedOrganization + "/senseai/nodes/" + sensor.node_uuid + "/calibrations/six_values", onMQTTReceived)
                    }
                  }, 3000)
               
            }
            
        } catch (error) {
            
        }

    }, [sensor, selectedOrganization])

    useEffect(() => {
        // Determining if user had GOD view access
        getData(apiLink + '/v1/users/iotflows')
        .then(async response => {
          if(response && response.ok ){
            const data = await response.json();
            if(data && data.data.length > 0 && data.data[0].iotflows_member_role_id){
              setShowGODview(true)
            } 
          }
        });
  
      }, [])

    const onMQTTReceived = message => {
        try{
            var payloadMessage = message.payloadString
            try { payloadMessage = JSON.parse(payloadMessage) } catch(e){}
            if(payloadMessage && payloadMessage.data){
                // console.log('payloadMessage.data.vibration', payloadMessage.data.vibration)
                // console.log('payloadMessage.data.acoustic', payloadMessage.data.acoustic)
                chartVibRef.current.chart.series[0].addPoint(payloadMessage.data.vibration, true, false, true)
                chartAcousticRef.current.chart.series[0].addPoint(payloadMessage.data.acoustic, true, false, true)

                // finalData.push({bipayloadMessage.data.vibration})

                // finalAnalyzesChartRef.current.chart.series[0].addPoint(payloadMessage.data.machine_state, true, false, true)

            }
          }
          catch(e){
            // console.log("Error in reading MQTT");
            console.log(e.message);
          }
    }

    const submitEditedSensitivity = (e) => {
    
        try {
          if(!loadingSensitivity ){
            setLoadingSensitivity(true)
            putData(apiLink + '/v1/nodes/' + sensor.node_uuid + '/sensitivity' , 
            {'node_sensitivity': sensorSensitivity,})
              .then(async response => {
                const data = await response.json();
                if(response.ok){
                  setLoadingSensitivity(false)
                  getSensorList()
                  toast.success('Sensor sensitivity has been changed')
                  // closeModal()
                }
                else{
                  try{
                    const data = await response.json();
                    setLoadingSensitivity(false)
                    toast.error(data.message)
                  }catch (e){
                    toast.error('Something went wrong')
                    setLoadingSensitivity(false)
                  }
                }
              })
    
          }
          
        } catch (error) {
          console.log(error.message)
        }
    
      }
    
      const submitEditedMomentum = (e) => {
        
        try {
          if(!loadingMomentum ){
            setLoadingMomentum(true)
            putData(apiLink + '/v1/nodes/' + sensor.node_uuid + '/momentum' , 
            {'node_momentum': sensorMomentum,})
              .then(async response => {
                const data = await response.json();
                if(response.ok){
                  setLoadingMomentum(false)
                  getSensorList()
                  toast.success('Sensor momentum has been changed')
                  // closeModal()
                }
                else{
                  try{
                    const data = await response.json();
                    setLoadingMomentum(false)
                    toast.error(data.message)
                  }catch (e){
                    toast.error('Something went wrong')
                    setLoadingMomentum(false)
                  }
                }
              })
    
          }
          
        } catch (error) {
          console.log(error.message)
        }
    
      }
      const submitEditedShockAccelerationThreshold = (e) => {
        
        try {
          if(!loadingShockAccelerationThreshold ){
            setLoadingShockAccelerationThreshold(true)
            putData(apiLink + '/v1/nodes/' + sensor.node_uuid + '/shock_threshold' , 
            {'node_shock_threshold': sensorShockAccelerationThreshold,})
              .then(async response => {
                const data = await response.json();
                if(response.ok){
                  setLoadingShockAccelerationThreshold(false)
                  getSensorList()
                  toast.success('Sensor Shock Acceleration Threshold has been changed')
                }
                else{
                  try{
                    const data = await response.json();
                    setLoadingShockAccelerationThreshold(false)
                    toast.error(data.message)
                  }catch (e){
                    toast.error('Something went wrong')
                    setLoadingShockAccelerationThreshold(false)
                  }
                }
              })
    
          }
          
        } catch (error) {
          console.log(error.message)
        }
    
      }

      const handleVibrationCheckbox = (e) => {
            if(!e.target.checked && !isAcousticsChecked){
                setIsVibChecked(true)
            }else{
                setIsVibChecked(e.target.checked)

                let modeType
                if(e.target.checked && isAcousticsChecked){
                    modeType = 2
                }else if(e.target.checked && !isAcousticsChecked){
                    modeType = 0
                }else if(!e.target.checked && isAcousticsChecked){
                    modeType = 1
                }
                putData(apiLink + `/v1/nodes/${sensor.node_uuid}/analysis_mode` , 
                {'node_analysis_mode':modeType})
                .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                    // toast.success(' Lower Threshold Updated')
                    
                        if(data && data.data && data.data.calibration_results){
                            getSensorList()
                            setAnalyzedData(data.data.calibration_results)
                        }
                    }
                    else{
                    try{
                        toast.error(data.message)
                    }catch (e){
                        toast.error(e.message)
                    }
                    }
                })
            }
      }

      const handleAcousticsCheckbox = (e) => {
            if(!e.target.checked && !isVibChecked){
                setIsAcousticsChecked(true)
            }else{
                
                setIsAcousticsChecked(e.target.checked)
                let modeType
                if(e.target.checked && isVibChecked){
                    modeType = 2
                }else if(e.target.checked && !isVibChecked){
                    modeType = 1
                }else if(!e.target.checked && isVibChecked){
                    modeType = 0
                }
                putData(apiLink + `/v1/nodes/${sensor.node_uuid}/analysis_mode` , 
                {'node_analysis_mode':modeType})
                .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                    // toast.success(' Lower Threshold Updated')
                    // console.log('data.data', data.data)
                        getSensorList()
                        if(data && data.data && data.data.calibration_results){
                            setAnalyzedData(data.data.calibration_results)
                        }
                    }
                    else{
                    try{
                        toast.error(data.message)
                    }catch (e){
                        toast.error(e.message)
                    }
                    }
                })
            }

      }


      const handleStopRecording = () => {
        try {
            setStartRecordingData(false)
            setIsActive(false);
            postData(apiLink + '/v1/nodes/' + sensor.node_uuid + '/calibration_request',
            { "should_publish_calibration": false})
                .then(async response => {
                const data = await response.json();
                if(response.ok){
                    toast.success('Stopped Publishing Data')
                    getSensorList()
                    if(data && data.data && data.data.calibration_results){
                        setAnalyzedData(data.data.calibration_results)
                    }
                    if(data && data.data && data.data.calibration_started_at && data.data.calibration_finished_at){
                        formatDateTime(data.data.calibration_started_at, data.data.calibration_finished_at)
                    }
                }
                else{
                    try{
                        toast.error('Something went wrong')
                        toast.error(data.message)
                    }catch (e){
                        toast.error(e.message)
                    }
                }
                })
                
            } catch (error) {
                console.log(error.message)
            }
      }


      const handleShockSliderRelease = (e) => {

        if(!loadingShockAccelerationThreshold){
            setLoadingShockAccelerationThreshold(true)
            putData(apiLink + '/v1/nodes/' + sensor.node_uuid + '/shock_threshold' , 
                {'node_shock_threshold': e.target.value,})
                  .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                        getSensorList()
                      setLoadingShockAccelerationThreshold(false)
                      toast.success('Sensor Shock Acceleration Threshold has been changed')
                    }
                    else{
                      try{
                        const data = await response.json();
                        setLoadingShockAccelerationThreshold(false)
                        toast.error(data.message)
                      }catch (e){
                        toast.error('Something went wrong')
                        setLoadingShockAccelerationThreshold(false)
                      }
                    }
                  })

        }

    }

    useEffect(() => {
        let intervalId;
    
        if (isActive) {
          intervalId = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds + 1);
          }, 1000);
        }
    
        return () => clearInterval(intervalId); // Clear interval on unmount
      }, [isActive]);

    const handleStartRecording = () => {
        setSeconds(0)
        setStartRecordingData(true)
        setIsActive(true);
    }

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
          .toString()
          .padStart(2, "0")}`;
      };

      const formatDateTime = (start,end) => {
          if(start && end){
                const startDate = new Date(start);
                const endDate = new Date(end);
                const timeDiffMs = endDate.getTime() - startDate.getTime();
                // Convert milliseconds to seconds
                const secondsDiff = Math.ceil(timeDiffMs / 1000);

                var startHours = startDate.getHours();
                var startMinutes = startDate.getMinutes();
                var startAmPm = startHours >= 12 ? 'pm' : 'am';
                startHours = startHours % 12;
                startHours = startHours ? startHours : 12; // the hour '0' should be '12'
                startMinutes = startMinutes < 10 ? '0'+startMinutes : startMinutes;
                var startStrTime = startHours + ':' + startMinutes + ' ' + startAmPm;
                var shortstartDate = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2)
                // var duration = formatTime(secondsDiff)
                let minutes = Math.floor(secondsDiff / 60);
                let remainingSeconds = secondsDiff % 60;
                let str = ''
                if(minutes > 0 && remainingSeconds == 0){
                    str = `${minutes} minutes`
                }else if(minutes == 0 && remainingSeconds > 0){
                    str = `${remainingSeconds} seconds`
                }else{
                    str = `${minutes} minutes and ${remainingSeconds} seconds`
                }

                const string =  "Acquired " + shortstartDate +" for " + str;
                setLastCalibratedString(string)

        }
      }

      useEffect(() => {
        if(sensor && sensor.calibration_started_at && sensor.calibration_finished_at){
            formatDateTime(sensor.calibration_started_at, sensor.calibration_finished_at)
        }

    },[sensor])


    const handleResetShockAccel = () => {

        putData(apiLink + '/v1/nodes/' + sensor.node_uuid + '/reset_shock_accel_zero_offset' , 
            {'reset_shock_accel_zero_offset': true})
              .then(async response => {
                const data = await response.json();
                if(response.ok){
                  toast.success('Shock Acceleration Reset to Zero')
                }
                else{
                  try{
                    const data = await response.json();
                    toast.error(data.message)
                  }catch (e){
                    toast.error('Something went wrong')
                    setLoadingSensitivity(false)
                  }
                }
              })

    }

    
    
    const handleUptimeFilterBlur = (event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          // Focus moved outside the div (not to a child input)
        //   console.log('Blurred outside the div'); // Your desired action
            
            if(!loadingUptimeFilter){
                setLoadingUptimeFilter(true)
                console.log('uptimeFilter', uptimeFilter)
                putData(apiLink + '/v1/organization_assets/' + sensor.asset_uuid + '/short_uptime_filter' , 
                {'asset_uptime_filter_seconds': uptimeFilter})
                .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                        setLoadingUptimeFilter(false)
                        if(uptimeFilter != data.data[0].asset_uptime_filter_seconds){
                            // console.log('NOT THE SAME')
                            setUptimeFilter(data.data[0].asset_uptime_filter_seconds)
                        }
                        toast.success('Uptime filter updated')
                    }
                    else{
                        try{
                            setUptimeFilter(sensor.asset_uptime_filter_seconds)
                            setLoadingUptimeFilter(false)
                            toast.error(data.message)
                        }catch (e){
                            setUptimeFilter(sensor.asset_uptime_filter_seconds)
                            toast.error(e.message)
                            setLoadingUptimeFilter(false)
                        }
                    }
                })
            }
        }
      };

    const handleChangeUptimeFilter = (e) => {
        if(e.target.value > 60){
            setUptimeFilter(60)
        }else{
            setUptimeFilter(e.target.value)
        }
    }

    const handleAutoClassifyMinsBlur = (event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            if(!loadingAutoClassifyMins){
                setLoadingAutoClassifyMins(true)
                console.log('autoClassifyMins', autoClassifyMins)
                putData(apiLink + '/v1/organization_assets/' + sensor.asset_uuid + '/short_downtime_classification' , 
                {'asset_auto_classify_downtimes_less_than_min': autoClassifyMins})
                .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                        if(data && data.data){
                            if(autoClassifyMins != data.data[0].asset_auto_classify_downtimes_less_than_min){
                                // console.log('NOT THE SAME')
                                setAutoClassifyMins(data.data[0].asset_auto_classify_downtimes_less_than_min)
                            }
                            
                        }
                        setLoadingAutoClassifyMins(false)
                        toast.success('Auto downtime classification updated')
                    }
                    else{
                        try{
                            setAutoClassifyMins(sensor.asset_auto_classify_downtimes_less_than_min)
                            setLoadingAutoClassifyMins(false)
                            toast.error(data.message)
                        }catch (e){
                            setAutoClassifyMins(sensor.asset_auto_classify_downtimes_less_than_min)
                            toast.error(e.message)
                            setLoadingAutoClassifyMins(false)
                        }
                    }
                })
            }
        }
      };

    const handleChangeAutoClassifyTime = (e) => {
        setAutoClassifyMins(e.target.value)
    }

    
  return (
    <div>
        {firmwareVersion && firmwareVersion >= 2 && (
            <AnimatePresence mode='wait' >
                <div className=''>
                    {/* Play Pause Section */}
                    <div className={`flex mt-2 pt-2 space-x-2 border-t-[0.1em] border-dotted ${isDark?' border-gray-600':'border-gray-300'} items-start`}>
                        <div className={`${isDark? 'text-gray-400':'text-gray-500'} mr-auto text-sm`}>{lastCalibratedString}</div>
                        <div className={`${isDark? 'text-gray-300':'text-gray-600'} `}>{formatTime(seconds)}</div>
                        <motion.button 
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }} 
                            onClick={handleStartRecording}
                            className={`${isDark? 'text-white bg-blue-600 hover:bg-blue-500':'text-white bg-blue-500 hover:bg-blue-600'} rounded-lg p-1 ${startRecordingData ? 'opacity-70 ': 'opacity-100'}`}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 `}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
                            </svg>
                        </motion.button>
                        <motion.button 
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }} 
                            onClick={handleStopRecording}
                            className={`${isDark? 'text-white bg-red-600 hover:bg-red-500':'text-white bg-red-500 hover:bg-red-600'} rounded-lg p-1 ${!startRecordingData && 'opacity-70'}`}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 `}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 7.5A2.25 2.25 0 0 1 7.5 5.25h9a2.25 2.25 0 0 1 2.25 2.25v9a2.25 2.25 0 0 1-2.25 2.25h-9a2.25 2.25 0 0 1-2.25-2.25v-9Z" />
                            </svg>

                        </motion.button>
                        
                    </div>
                    {/* Section for vibration calibration */}
                    <div>
                        <div className="flex items-center pb-0.5">
                            <input id={`vibration-checkbox`} type="checkbox" checked={isVibChecked} onChange={handleVibrationCheckbox}  className="checkboxBtn w-5 h-5"/>
                            <label htmlFor={`vibration-checkbox`} className={`ml-2 text-base font-normal ${isDark? 'text-gray-300':'text-gray-600'}`}>Vibration</label>
                            <div>
                                <CalibrationInfoModal btnType='iconBtn' />
                            </div>
                        </div>
                            <motion.div key='vibrationChart' className='flex w-full'>
                                <BasicAreaLineChart chartRef={chartVibRef} parameter_type='vibration' sensor={sensor} startRecordingData={startRecordingData} isChecked={isVibChecked} setAnalyzedData={setAnalyzedData} getSensorList={getSensorList}/>

                            </motion.div>
                    </div>
                    {/* Section for Acoustics calibration */}
                    <div className={` pt-2 border-t-2 ${isDark?' border-gray-600':'border-gray-300'}`}>
                        <div className="flex items-center pb-0.5">
                            <input id={`vibration-checkbox`} type="checkbox" checked={isAcousticsChecked} onChange={handleAcousticsCheckbox}  className="checkboxBtn w-5 h-5"/>
                            <label htmlFor={`vibration-checkbox`} className={`ml-2 text-base font-normal ${isDark? 'text-gray-300':'text-gray-600'}`}>Acoustics</label>
                            <div>
                                <CalibrationInfoModal btnType='iconBtn' />
                            </div>
                        </div>
                        <motion.div key='acousticsChart' className='flex w-full'>
                            <BasicAreaLineChart chartRef={chartAcousticRef} parameter_type='acoustic' sensor={sensor} startRecordingData={startRecordingData} isChecked={isAcousticsChecked} setAnalyzedData={setAnalyzedData} getSensorList={getSensorList} />

                        </motion.div>

                    </div>
                    <div className={` py-2 border-t-2 ${isDark?' border-gray-600':'border-gray-300'}`}>
                        <div className={`ml-2 text-base font-normal ${isDark? 'text-gray-300':'text-gray-600'}`}>
                            Detected Uptimes
                        </div>
                        <motion.div key='finalAnalyzesChart' className='flex w-full'>
                            <FinalAnalysisAreaLineChart finalData={analyzedData}  sensor={sensor} />

                        </motion.div>

                    </div>
                    <div className={`flex items-end border-t-[0.1em] border-dotted ${isDark?' border-gray-600':'border-gray-300'}`}>
                        <div className='grow relative pb-2'>
                        <div className='flex items-center mt-4 mb-1'>
                            <label htmlFor="minmax-range" className={`block ${isDark? 'text-gray-300' : 'text-gray-500'} text-base font-medium tracking-wide`}>Shock Acceleration Threshold: {sensorShockAccelerationThreshold}g</label>
                            {showGODview && firmwareVersion && firmwareVersion >= 3 && (
                                <LightTooltip title='Reset Shock Acceleration Zero Offset'>
                                    <button className={`${isDark? 'text-gray-300' : 'text-gray-500'} ml-auto`} onClick={handleResetShockAccel}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                        </svg>
                                    </button>
                                </LightTooltip>
                            )}
                        </div>
                        {firmwareVersion && firmwareVersion >= 3 ? (
                            <input id="minmax-range" type="range" min="5" max="100" step="0.5"
                                className={`w-full h-2 range-lg rounded-lg appearance-none cursor-pointer ${isDark? 'bg-slate-300 accent-blue-600 hover:accent-blue-400' : 'bg-slate-300'} `}
                                value={sensorShockAccelerationThreshold} 
                                onChange={(e) => setSensorShockAccelerationThreshold(e.target.value)} 
                                onMouseUp={handleShockSliderRelease}
                            />
                        ):(
                                <input id="minmax-range" type="range" min="1.5" max="5" step="0.001"
                                    className={`w-full h-2 range-lg rounded-lg appearance-none cursor-pointer ${isDark? 'bg-slate-300 accent-blue-600 hover:accent-blue-400' : 'bg-slate-300'} `}
                                    value={sensorShockAccelerationThreshold} 
                                    onChange={(e) => setSensorShockAccelerationThreshold(e.target.value)} 
                                    onMouseUp={handleShockSliderRelease}
                                />
                        )}
                        {loadingShockAccelerationThreshold && 
                            <div >
                                <svg aria-hidden="true" className={`absolute top-1/2 right-1/2 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                </svg>
                            </div>
                            }
                        </div>
                    </div> 
                    <div className={` pt-4 flex justify-around border-t-2 border-dotted ${isDark?' border-gray-600':'border-gray-300'}`}>
                        <LightTooltip title={`This will filter out any uptime shorter than ${uptimeFilter} seconds, primarily to eliminate noise generated by other machines or processes.`}>
                            <div className='flex flex-col items-center'>
                                <div className={` ${isDark? 'text-gray-300' : 'text-gray-500'} text-base font-medium tracking-wide mb-2`}>
                                    Uptime Filter
                                </div>
                                <div className='relative flex items-center max-w-[60px] mb-2'>
                                    <input 
                                        className={`text-center block bg-transparent w-full border-[0.01em] rounded-md py-1 px-0 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm   ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
                                        min={0}
                                        placeholder={`00`}
                                        type={'number'}
                                        name={'uptime_filter'}
                                        value={uptimeFilter}
                                        onBlur={handleUptimeFilterBlur}
                                        onChange={handleChangeUptimeFilter}
                                    />
                                    {loadingUptimeFilter && 
                                    <div >
                                        <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                        </svg>
                                    </div>
                                    }
                                </div>
                                <div className={`text-base font-light ${isDark?' text-gray-300':'text-gray-600'}`}>
                                    <span>{`seconds`}</span>
                                </div>
                                {/* <div className='relative flex items-center max-w-[125px] ml-2'>
                                    <input 
                                        className={`text-center block bg-transparent w-full border-[0.01em] rounded-md py-1 px-0 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm   ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
                                        min={0}
                                        placeholder={`00`}
                                        type={'number'}
                                        name={'uptime_filter'}
                                        value={uptimeFilter}
                                        onBlur={handleUptimeFilterBlur}
                                        onChange={handleChangeUptimeFilter}
                                    />
                                    <div className={`text-sm ${isDark?' text-gray-300':'text-gray-600'} pl-2`}>
                                        <span>{`seconds`}</span>
                                    </div>
                                    {loadingUptimeFilter && 
                                    <div >
                                        <svg aria-hidden="true" className={`absolute top-1/4 right-1/2 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                        </svg>
                                    </div>
                                    }
                                </div> */}

                            </div>

                        </LightTooltip>
                            <LightTooltip title={`This feature will automatically classify any downtime lasting less than ${autoClassifyMins} minutes.`} >
                        <div className='flex flex-col items-center'>
                                <div className={` ${isDark? 'text-gray-300' : 'text-gray-500'} text-base font-medium tracking-wide mb-2`}>
                                    Auto-Classify Downtimes
                                </div>
                                <div className='relative flex items-center max-w-[60px] mb-2'>
                                    <input 
                                        className={`text-center block bg-transparent w-full border-[0.01em] rounded-md py-1 px-0 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm   ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
                                        min={0}
                                        placeholder={`00`}
                                        type={'number'}
                                        name={'auto_classify_downtime_mins'}
                                        value={autoClassifyMins}
                                        onBlur={handleAutoClassifyMinsBlur}
                                        onChange={handleChangeAutoClassifyTime}
                                    />
                                    {loadingAutoClassifyMins && 
                                    <div >
                                        <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                        </svg>
                                    </div>
                                    }
                                </div>
                                <div className={`text-base font-light ${isDark?' text-gray-300':'text-gray-600'}`}>
                                    <span>{`minutes`}</span>
                                </div>
                        </div>
                            </LightTooltip>

                    </div>
                </div>
            </AnimatePresence>


        )}
        {firmwareVersion && firmwareVersion < 2 &&
            <div className='mt-4 '>  
                <div className='flex items-end'>
                    <div className='grow'>
                    <label htmlFor="minmax-range" className={`block mb-1 ${isDark? 'text-gray-300' : 'text-gray-500'} text-base font-medium tracking-wide`}>Sensitivity: {sensorSensitivity}</label>
                    <input id="minmax-range" type="range" min="0.001" max="0.96" step="0.001"
                        className={`w-full h-2 range-lg rounded-lg appearance-none cursor-pointer ${isDark? 'bg-slate-300 accent-blue-600 hover:accent-blue-400' : 'bg-slate-300'} `}
                        value={sensorSensitivity} 
                        onChange={(e) => setSensorSensitivity(e.target.value)} 
                    />
                    </div>
                    <div className='w-14 pl-4'>
                    <button className={`relative text-base text-blue-500 ${isDark? 'hover:text-blue-400':'hover:text-blue-700'}`} onClick={submitEditedSensitivity}>
                        Send
                        {loadingSensitivity && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                        }
                    </button>
                    </div>
                </div> 
                <div className='flex items-end'>
                    <div className='grow'>
                    <label htmlFor="minmax-range" className={`block mt-4 mb-1 ${isDark? 'text-gray-300' : 'text-gray-500'} text-base font-medium tracking-wide`}>Momentum: {sensorMomentum}</label>
                    <input id="minmax-range" type="range" min="0.001" max="2" step="0.001"
                        className={`w-full h-2 range-lg rounded-lg appearance-none cursor-pointer ${isDark? 'bg-slate-300 accent-blue-600 hover:accent-blue-400' : 'bg-slate-300'} `}
                        value={sensorMomentum} 
                        onChange={(e) => setSensorMomentum(e.target.value)} 
                    />
                    </div>
                    <div className='w-14 pl-4'>
                    <button className={`relative text-base text-blue-500 ${isDark? 'hover:text-blue-400':'hover:text-blue-700'}`} onClick={submitEditedMomentum}>
                        Send 
                        {loadingMomentum && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                        }
                    </button>
                    </div>
                </div> 
                <div className='flex items-end'>
                    <div className='grow'>
                    <label htmlFor="minmax-range" className={`block mt-4 mb-1 ${isDark? 'text-gray-300' : 'text-gray-500'} text-base font-medium tracking-wide`}>Shock Acceleration Threshold: {sensorShockAccelerationThreshold}g</label>
                    <input id="minmax-range" type="range" min="1.5" max="3.5" step="0.001"
                        className={`w-full h-2 range-lg rounded-lg appearance-none cursor-pointer ${isDark? 'bg-slate-300 accent-blue-600 hover:accent-blue-400' : 'bg-slate-300'} `}
                        value={sensorShockAccelerationThreshold} 
                        onChange={(e) => setSensorShockAccelerationThreshold(e.target.value)} 
                    />
                    </div>
                    <div className='w-14 pl-4'>
                    <button className={`relative text-base text-blue-500 ${isDark? 'hover:text-blue-400':'hover:text-blue-700'}`} onClick={submitEditedShockAccelerationThreshold}>
                        Send
                        {loadingShockAccelerationThreshold && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                        }
                    </button>
                    </div>
                </div> 

            </div>
        }
    </div>
  )
}

export default AnalyzeSensorData