import Konva from 'konva';
import React, { useState, useEffect, useRef } from 'react';


function CanvasRightSideDrawer({ selectedObject, onUpdate, onClose, isOpen, handleColorChange }) {
    const [properties, setProperties] = useState({
        id: selectedObject && selectedObject && selectedObject.id ? selectedObject.id : '',
        color: selectedObject && selectedObject && selectedObject.fill ? selectedObject.fill : 'red',
        x: selectedObject && selectedObject.x ? selectedObject.x : 0,
        y: selectedObject && selectedObject.y ? selectedObject.y : 0,
        opacity: selectedObject && selectedObject.opacity ? selectedObject.opacity : 1,
        fill: selectedObject && selectedObject.fill ?  selectedObject.fill : '#000',
        width: selectedObject && selectedObject.width ? selectedObject.width : 100,
        height: selectedObject && selectedObject.height ? selectedObject.height: 100,
        rotation: selectedObject && selectedObject.rotation ? selectedObject.rotation : 0,
        stroke: selectedObject && selectedObject.stroke ? selectedObject.stroke : '#fff',
        strokeWidth: selectedObject && selectedObject.strokeWidth ? selectedObject.strokeWidth : 0,
        pointerLength: selectedObject && selectedObject.pointerLength ? selectedObject.pointerLength : 10,
        pointerWidth: selectedObject && selectedObject.pointerWidth ? selectedObject.pointerWidth : 10,
        points: selectedObject && selectedObject.points ? selectedObject.points : [0, 0, 200, 200],
        cornerRadius: selectedObject && selectedObject.cornerRadius ? selectedObject.cornerRadius : 0,
        fontSize: selectedObject && selectedObject.fontSize ? selectedObject.fontSize : 20,
        numPoints: selectedObject && selectedObject.numPoints ? selectedObject.numPoints : 5,
        innerRadius: selectedObject && selectedObject.innerRadius ? selectedObject.innerRadius : 0,
        outerRadius: selectedObject && selectedObject.outerRadius ? selectedObject.outerRadius : 0,
        radius: selectedObject && selectedObject.radius ? selectedObject.radius : 10,
        radiusY: selectedObject && selectedObject.radiusY ? selectedObject.radiusY : 10,
        radiusX: selectedObject && selectedObject.radiusX ? selectedObject.outerRadius : 10,
        scaleX: selectedObject && selectedObject.scaleX ? selectedObject.scaleX : 10,
        scaleY: selectedObject && selectedObject.scaleY ? selectedObject.scaleY : 10,
        assetWidth: 350,
        assetHeight: 120,
        text: selectedObject && selectedObject.text ? selectedObject.text : '',
        fontFamily: selectedObject && selectedObject.fontFamily ? selectedObject.fontFamily : 'Inter',
        sides: selectedObject && selectedObject.sides ? selectedObject.sides : 5,
        rotationValue: selectedObject && selectedObject.rotationValue ? selectedObject.rotationValue : 0,
      });
    const [title, setTitle]= useState('')
    const inputColorRef = useRef(null);

    

  // Function to handle updates only on "Enter", "Tab", or "Blur"
  const handleCommitChange = (name, value) => {
    if (name === 'lineWidth') {
      const [x1, y1, x2, y2] = properties.points;
      const currentLength = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
      const directionX = (x2 - x1) / currentLength;
      const directionY = (y2 - y1) / currentLength;
      const newX2 = x1 + directionX * value;
      const newY2 = y1 + directionY * value;
      const newPoints = [x1, y1, parseFloat(newX2.toFixed(2)), parseFloat(newY2.toFixed(2))];
      const roundedPoints = newPoints.map(value => Math.round(value));

      setProperties((prev) => ({ ...prev, length: value, points: roundedPoints }));
      onUpdate({ ...properties, points: newPoints, length: Math.round(value) });

    }else if(name === 'assetWidth' ){
        let newAssetWidth = value
        let newScaleX = value/350
        let newAssetHeight = Math.round(120 * newScaleX)

        if(value < 350){
            newAssetWidth = 350
            newScaleX = 1;
            newAssetHeight = 120
        }

        setProperties((prev) => ({ ...prev, assetWidth: newAssetWidth, assetHeight: newAssetHeight, scaleX: Number(newScaleX), scaleY: Number(newScaleX)  }));
        onUpdate({ ...properties, scaleX: Number(newScaleX), scaleY: Number(newScaleX), assetWidth: value, assetHeight: newAssetHeight,   });

    }else if(name === 'assetHeight'){
        let newAssetHeight = value
        let newScaleY = value/120
        let newAssetWidth = Math.round(350 * newScaleY)

        if(value < 120){
            newAssetWidth = 350
            newScaleY = 1;
            newAssetHeight = 120
        }

        

        setProperties((prev) => ({ ...prev, assetHeight: value, assetWidth: newAssetWidth, scaleX: Number(newScaleY), scaleY: Number(newScaleY) }));
        onUpdate({ ...properties, assetHeight: value, assetWidth: newAssetWidth, scaleX: Number(newScaleY), scaleY: Number(newScaleY),  });

    }else{
        const newValue = name === 'color' ? value : parseFloat(value.toFixed(2));
        
        setProperties((prev) => ({ ...prev, [name]: newValue }));
        onUpdate({ ...properties, [name]: newValue });
    }
  };

  // Event handler for "Enter", "Tab", and "Blur"
  const handleKeyDownOrBlur = (e) => {
    const { name, value } = e.target;
    if (e.key === 'Enter' || e.key === 'Tab' || e.type === 'blur') {
      handleCommitChange(name, parseFloat(value));
    }
  };
    
  
    useEffect(() => {
        if(selectedObject){

            let length = 10
            let angleDegrees = 0
            if(selectedObject.type === "line" || selectedObject.type === "arrow"){
                
                const startX = selectedObject.points ? selectedObject.points[0] : selectedObject.x;
                const startY = selectedObject.points ? selectedObject.points[1] : selectedObject.y;
    
                length = Math.sqrt(
                    (selectedObject.points[2] - startX) ** 2 +
                    (selectedObject.points[3] - startY) ** 2
                );

                const [x1, y1, x2, y2] = selectedObject.points;
                const angleRadians = Math.atan2(y2 - y1, x2 - x1);  // Get angle in radians
                angleDegrees = angleRadians * (180 / Math.PI);
            }
            let assetWidth = 350
            let assetHeight = 120
            if(selectedObject.type === "asset"){
                if(selectedObject.assetWidth){
                    assetWidth = selectedObject.assetWidth
                    assetHeight = selectedObject.assetHeight
                }else{
                    assetWidth = Math.round(350 * selectedObject.scaleX)
                    assetHeight = Math.round(120 * selectedObject.scaleY)

                }
                setProperties({
                    id: selectedObject && selectedObject && selectedObject.asset_uuid ? selectedObject.asset_uuid : '',
                    color: selectedObject && selectedObject.fill ? selectedObject.fill : 'red',
                    x: selectedObject.x ? selectedObject.x : 0,
                    y: selectedObject.y ? selectedObject.y : 0,
                    opacity: selectedObject.opacity ? selectedObject.opacity : 1,
                    fill: selectedObject.fill || selectedObject.stroke || 'black',
                    width: selectedObject.width ? selectedObject.width : 100,
                    height: selectedObject.height ? selectedObject.height: 100,
                    rotation: selectedObject.rotation ? selectedObject.rotation : 0,
                    length: Math.round(length) ,
                    strokeWidth: selectedObject.strokeWidth ? selectedObject.strokeWidth : 0,
                    pointerLength: selectedObject.pointerLength ? selectedObject.pointerLength : 10,
                    pointerWidth: selectedObject.pointerWidth ? selectedObject.pointerWidth : 10,
                    points: selectedObject.points ? selectedObject.points : [0, 0, 200, 200],
                    cornerRadius: selectedObject.cornerRadius ? selectedObject.cornerRadius : 0,
                    fontSize: selectedObject.fontSize ? selectedObject.fontSize : 20,
                    numPoints: selectedObject.numPoints ? selectedObject.numPoints : 5,
                    innerRadius: selectedObject.innerRadius ? selectedObject.innerRadius : 0,
                    outerRadius: selectedObject.outerRadius ? selectedObject.outerRadius : 0,
                    radius: selectedObject && selectedObject.radius ? selectedObject.radius : 10,
                    radiusY: selectedObject && selectedObject.radiusY ? selectedObject.radiusY : 10,
                    radiusX: selectedObject && selectedObject.radiusX ? selectedObject.radiusX : 10,
                    scaleX: selectedObject && selectedObject.scaleX ? selectedObject.scaleX : 1,
                    scaleY: selectedObject && selectedObject.scaleY ? selectedObject.scaleY : 1,
                    assetWidth: assetWidth,
                    assetHeight: assetHeight,
                });
    
                
            }else{
                setProperties({
                    id: selectedObject && selectedObject && selectedObject.id ? selectedObject.id : '',
                    color: selectedObject && selectedObject.fill ? selectedObject.fill : 'red',
                    x: selectedObject.x ? selectedObject.x : 0,
                    y: selectedObject.y ? selectedObject.y : 0,
                    opacity: selectedObject.opacity ? selectedObject.opacity : 1,
                    fill: selectedObject.fill || selectedObject.stroke || 'black',
                    width: selectedObject.width ? selectedObject.width : 100,
                    height: selectedObject.height ? selectedObject.height: 100,
                    rotation: selectedObject.rotation ? selectedObject.rotation : 0,
                    length: Math.round(length) ,
                    stroke: selectedObject && selectedObject.stroke ? selectedObject.stroke : '#fff',
                    strokeWidth: selectedObject.strokeWidth ? selectedObject.strokeWidth : 0,
                    pointerLength: selectedObject.pointerLength ? selectedObject.pointerLength : 10,
                    pointerWidth: selectedObject.pointerWidth ? selectedObject.pointerWidth : 10,
                    points: selectedObject.points ? selectedObject.points : [0, 0, 200, 200],
                    cornerRadius: selectedObject.cornerRadius ? selectedObject.cornerRadius : 0,
                    fontSize: selectedObject.fontSize ? selectedObject.fontSize : 20,
                    numPoints: selectedObject.numPoints ? selectedObject.numPoints : 5,
                    innerRadius: selectedObject.innerRadius ? selectedObject.innerRadius : 0,
                    outerRadius: selectedObject.outerRadius ? selectedObject.outerRadius : 0,
                    radius: selectedObject && selectedObject.radius ? selectedObject.radius : 10,
                    radiusY: selectedObject && selectedObject.radiusY ? selectedObject.radiusY : 10,
                    radiusX: selectedObject && selectedObject.radiusX ? selectedObject.radiusX : 10,
                    scaleX: selectedObject && selectedObject.scaleX ? selectedObject.scaleX : 1,
                    scaleY: selectedObject && selectedObject.scaleY ? selectedObject.scaleY : 1,
                    assetWidth: assetWidth,
                    assetHeight: assetHeight,
                    text: selectedObject && selectedObject.text ? selectedObject.text : '',
                    fontFamily: selectedObject && selectedObject.fontFamily ? selectedObject.fontFamily : 'Inter',
                    sides: selectedObject && selectedObject.sides ? selectedObject.sides : 5,
                    rotationValue: Math.round(angleDegrees),
                });
            }
            
            

        }

        if(selectedObject){
            if(selectedObject.type === "asset"){
                setTitle('Asset')
            }else if(selectedObject.type === "rectangle"){
                setTitle('Rectangle')
            }else if(selectedObject.type === "line"){
                setTitle('Line')
            }else if(selectedObject.type === "arrow"){
                setTitle('Arrow')
            }else if(selectedObject.type === "ellipse"){
                setTitle('Ellipse')
            }else if(selectedObject.type === "polygon"){
                setTitle("Polygon")
            }else if(selectedObject.type === "star"){
                setTitle("Star")
            }else if(selectedObject.type === "text"){
                setTitle("Text")
            }else if(selectedObject.type === "image"){
                setTitle("Image")
            }
        }
    }, [selectedObject]);

    
    
      if (!selectedObject) return null; // Prevent rendering when no object is selected

      const handleClick = () => {
        inputColorRef.current.click();
      };

      

      const handlePositionChange = (newX, newY) => {
        const [x1, y1, x2, y2] = selectedObject.points; // Extract current points
      
        // Calculate the length and angle of the line
        const deltaX = x2 - x1;
        const deltaY = y2 - y1;
        const length = Math.sqrt(deltaX ** 2 + deltaY ** 2); // Distance between points
        const angle = Math.atan2(deltaY, deltaX); // Angle in radians
      
        // Update the first point with the new values
        const newX1 = newX;
        const newY1 = newY;
      
        // Calculate the new second point to maintain the same angle and length
        const newX2 = newX1 + Math.cos(angle) * length;
        const newY2 = newY1 + Math.sin(angle) * length;
      
        // Update the line's points
        const updatedPoints = [newX1, newY1, newX2, newY2];
        const roundedPoints = updatedPoints.map(value => Math.round(value));
        setProperties((prev) => ({ ...prev, points: roundedPoints }));
        // onUpdate({ ...properties, points: updatedPoints });
      };

      const handleLinePositionKeyDownOrBlur = (e) => {
        const { name, value } = e.target;
        if (e.key === 'Enter' || e.key === 'Tab' || e.type === 'blur') {
            onUpdate({ ...properties, points: properties.points });
        }
      };

      const rotateLine = (angleDegrees) => {
        const [x1, y1, x2, y2] = properties.points;

        const angleRadians = angleDegrees * (Math.PI / 180);

        // Calculate the length of the line (distance between x1, y1 and x2, y2)
        const deltaX = x2 - x1;
        const deltaY = y2 - y1;
        const length = Math.sqrt(deltaX ** 2 + deltaY ** 2);

        // Calculate the new position of (x2, y2) after rotation
        const newX2 = x1 + Math.cos(angleRadians) * length;
        const newY2 = y1 + Math.sin(angleRadians) * length;

        const updatedPoints = [x1, y1, newX2, newY2];
      
        setProperties((prev) => ({ ...prev, points: updatedPoints, rotationValue: angleDegrees }));
        onUpdate({ ...properties, points: updatedPoints });
      };
      

      

      const renderFields = () => {
        
    
        switch (selectedObject.type) {
          case 'line':
            return (
                <>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Position</h6>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Position</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                X
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="x"
                                value={Math.round(properties.points[0])}  // x1
                                onChange={(e) => handlePositionChange(e.target.value, properties.points[1])} // update x1                              
                                onKeyDown={handleLinePositionKeyDownOrBlur} 
                                onBlur={handleLinePositionKeyDownOrBlur}  
                            />     
                        </div>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                Y
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="y"
                                value={Math.round(properties.points[1])}
                                onChange={(e) => handlePositionChange(properties.points[0], e.target.value)} // update y1
                                onKeyDown={handleLinePositionKeyDownOrBlur} 
                                onBlur={handleLinePositionKeyDownOrBlur}  
                            />     
                        </div>
                    </div>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mt-1 mb-1'>Transform</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2 '>
                            <div className='absolute inset-y-0 pl-1  flex items-center pointer-events-none dark:text-gray-300 text-gray-500'>
                                <svg className='w-7 h-7' fill="none" viewBox="0 0 24 24">
                                    <path fill="currentColor" fillRule="evenodd" d="M9 8.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1H13a4 4 0 0 0-4-4zM9 12v3h3a3 3 0 0 0-3-3" clipRule="evenodd"></path>
                                    </svg>
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="rotation"
                                value={properties.rotationValue}
                                onChange={(e) => rotateLine(parseFloat(e.target.value))}
                            />    
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Layout</h6>
                <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Dimensions</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                W
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="lineWidth"
                                value={properties && properties.length ? properties.length : 200}
                                onChange={(e) => setProperties((prev) => ({ ...prev, length: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur}  // Handle Enter and Tab
                                onBlur={handleKeyDownOrBlur}     // Handle clicking outside
                            />     
                        </div>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                H
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 disabled:opacity-50 `}
                                type="number"
                                name="height"
                                placeholder='0'
                                value={0}
                                disabled={true}
                            />     
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Apperance</h6>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Opacity</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960" fill="currentColor">
                                <path d="M480-120q-133 0-226.5-92T160-436q0-65 25-121.5T254-658l184-181q9-8 20-12.5t22-4.5q11 0 22 4.5t20 12.5l184 181q44 44 69 100.5T800-436q0 132-93.5 224T480-120ZM242-400h474q12-72-13.5-123T650-600L480-768 310-600q-27 26-53 77t-15 123Z"/>
                            </svg>
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="opacity"
                                min="0"
                                max="100"
                                step="1"
                                value={`${Math.round(properties.opacity * 100)}`}
                                onChange={(e) => {
                                    const newOpacity = parseFloat(e.target.value) / 100;  // Convert percentage back to decimal
                                    setProperties((prev) => ({ ...prev, opacity: newOpacity }));
                                    onUpdate({ ...properties, opacity: newOpacity });
                                  }}
                            />     
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Stroke</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='flex flex-col'>
                            <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Color</h6>
                            <div className='relative rounded-full w-full mb-2'>
                                <div className='absolute inset-y-0 pl-1.5  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                    <div 
                                        className="relative inline-block w-5 h-5 rounded-md cursor-pointer "
                                        onClick={handleClick}
                                        style={{ backgroundColor: properties.stroke, boxShadow: `0 0 0 1px ${properties.stroke}` }}
                                        >
                                        <input
                                            className="absolute inset-0 w-full h-full opacity-0"
                                            ref={inputColorRef}
                                            type="color"
                                            name="color"
                                            value={properties.stroke}
                                            onChange={(e) => {
                                                setProperties((prev) => ({ ...prev, stroke: e.target.value }))
                                                onUpdate({ ...properties, stroke: e.target.value });
                                                }
                                            }
                                            // onKeyDown={handleKeyDownOrBlur} 
                                            // onBlur={handleKeyDownOrBlur} 
                                        />

                                    </div>
                                </div>
                                <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                    block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                    type="text"
                                    name="color"
                                    value={properties.stroke}
                                    onClick={handleClick}
                                    onChange={(e) => {
                                        setProperties((prev) => ({ ...prev, stroke: e.target.value }))
                                        onUpdate({ ...properties, stroke: e.target.value });
                                        }
                                    }
                                />     
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Weight</h6>
                            <div className='relative rounded-full w-full mb-2'>
                                <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960" fill="currentColor">
                                    <path xmlns="http://www.w3.org/2000/svg" d="M140-160q-8 0-14-6t-6-14q0-8 6-14t14-6h680q8 0 14 6t6 14q0 8-6 14t-14 6H140Zm20-120q-17 0-28.5-11.5T120-320q0-17 11.5-28.5T160-360h640q17 0 28.5 11.5T840-320q0 17-11.5 28.5T800-280H160Zm0-160q-17 0-28.5-11.5T120-480v-40q0-17 11.5-28.5T160-560h640q17 0 28.5 11.5T840-520v40q0 17-11.5 28.5T800-440H160Zm0-200q-17 0-28.5-11.5T120-680v-80q0-17 11.5-28.5T160-800h640q17 0 28.5 11.5T840-760v80q0 17-11.5 28.5T800-640H160Z"/>
                                </svg>
                                </div>
                                <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                    block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                    type="number"
                                    name="strokeWidth"
                                    value={properties.strokeWidth}
                                    onChange={(e) => setProperties((prev) => ({ ...prev, strokeWidth: e.target.value }))}
                                    onKeyDown={handleKeyDownOrBlur}  // Handle Enter and Tab
                                        onBlur={handleKeyDownOrBlur}     // Handle clicking outside
                                />     
                            </div>

                        </div>
                    </div>
                </div>

                </>
              );
          case 'arrow':
            return (
              <>
              <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Position</h6>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Position</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                X
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="x"
                                value={Math.round(properties.points[0])}  // x1
                                onChange={(e) => handlePositionChange(e.target.value, properties.points[1])} // update x1                              
                                onKeyDown={handleLinePositionKeyDownOrBlur} 
                                onBlur={handleLinePositionKeyDownOrBlur}  
                            />     
                        </div>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                Y
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="y"
                                value={Math.round(properties.points[1])}
                                onChange={(e) => handlePositionChange(properties.points[0], e.target.value)} // update y1
                                onKeyDown={handleLinePositionKeyDownOrBlur} 
                                onBlur={handleLinePositionKeyDownOrBlur}   
                            />     
                        </div>
                    </div>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mt-1 mb-1'>Transform</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2 '>
                            <div className='absolute inset-y-0 pl-1  flex items-center pointer-events-none dark:text-gray-300 text-gray-500'>
                                <svg className='w-7 h-7' fill="none" viewBox="0 0 24 24">
                                    <path fill="currentColor" fillRule="evenodd" d="M9 8.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1H13a4 4 0 0 0-4-4zM9 12v3h3a3 3 0 0 0-3-3" clipRule="evenodd"></path>
                                    </svg>
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                value={properties.rotationValue}
                                onChange={(e) => rotateLine(parseFloat(e.target.value))}
                            />    
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Layout</h6>
                <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Dimensions</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                W
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="lineWidth"
                                value={properties && properties.length ? properties.length : 200}
                                onChange={(e) => setProperties((prev) => ({ ...prev, length: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur}  // Handle Enter and Tab
                                onBlur={handleKeyDownOrBlur}     // Handle clicking outside
                            />     
                        </div>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                H
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 disabled:opacity-50 `}
                                type="number"
                                name="height"
                                placeholder='0'
                                disabled={true}
                                value={0}
                                // onChange={(e) => setProperties((prev) => ({ ...prev, height: e.target.value }))}
                                // onKeyDown={handleKeyDownOrBlur} 
                                // onBlur={handleKeyDownOrBlur}  
                            />     
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Apperance</h6>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Opacity</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960" fill="currentColor">
                                <path d="M480-120q-133 0-226.5-92T160-436q0-65 25-121.5T254-658l184-181q9-8 20-12.5t22-4.5q11 0 22 4.5t20 12.5l184 181q44 44 69 100.5T800-436q0 132-93.5 224T480-120ZM242-400h474q12-72-13.5-123T650-600L480-768 310-600q-27 26-53 77t-15 123Z"/>
                            </svg>
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="opacity"
                                min="0"
                                max="100"
                                step="1"
                                value={`${Math.round(properties.opacity * 100)}`}
                                // onChange={(e) => setProperties((prev) => ({ ...prev, opacity: e.target.value }))}
                                onChange={(e) => {
                                    const newOpacity = parseFloat(e.target.value) / 100;  // Convert percentage back to decimal
                                    setProperties((prev) => ({ ...prev, opacity: newOpacity }));
                                    onUpdate({ ...properties, opacity: newOpacity });
                                  }}
                                // onKeyDown={handleKeyDownOrBlur} 
                                // onBlur={handleKeyDownOrBlur} 
                            />     
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Stroke</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='flex flex-col'>
                            <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Color</h6>
                            <div className='relative rounded-full w-full mb-2'>
                                <div className='absolute inset-y-0 pl-1.5  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                    <div 
                                        className="relative inline-block w-5 h-5 rounded-md cursor-pointer "
                                        onClick={handleClick}
                                        style={{ backgroundColor: properties.stroke, boxShadow: `0 0 0 1px ${properties.stroke}` }}
                                        >
                                        <input
                                            className="absolute inset-0 w-full h-full opacity-0"
                                            ref={inputColorRef}
                                            type="color"
                                            name="color"
                                            value={properties.stroke}
                                            onChange={(e) => {
                                                setProperties((prev) => ({ ...prev, stroke: e.target.value, fill: e.target.value }))
                                                // handleColorLineChange(e)
                                                onUpdate({ ...properties, stroke: e.target.value });
                                                }
                                            }
                                            // onKeyDown={handleKeyDownOrBlur} 
                                            // onBlur={handleKeyDownOrBlur} 
                                        />

                                    </div>
                                </div>
                                <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                    block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                    type="text"
                                    name="color"
                                    value={properties.stroke}
                                    onClick={handleClick}
                                    onChange={(e) => {
                                        setProperties((prev) => ({ ...prev, stroke: e.target.value, fill: e.target.value }))
                                        // handleColorLineChange(e)
                                        onUpdate({ ...properties, stroke: e.target.value });
                                        }
                                    }
                                />     
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Weight</h6>
                            <div className='relative rounded-full w-full mb-2'>
                                <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960" fill="currentColor">
                                    <path xmlns="http://www.w3.org/2000/svg" d="M140-160q-8 0-14-6t-6-14q0-8 6-14t14-6h680q8 0 14 6t6 14q0 8-6 14t-14 6H140Zm20-120q-17 0-28.5-11.5T120-320q0-17 11.5-28.5T160-360h640q17 0 28.5 11.5T840-320q0 17-11.5 28.5T800-280H160Zm0-160q-17 0-28.5-11.5T120-480v-40q0-17 11.5-28.5T160-560h640q17 0 28.5 11.5T840-520v40q0 17-11.5 28.5T800-440H160Zm0-200q-17 0-28.5-11.5T120-680v-80q0-17 11.5-28.5T160-800h640q17 0 28.5 11.5T840-760v80q0 17-11.5 28.5T800-640H160Z"/>
                                </svg>
                                </div>
                                <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                    block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                    type="number"
                                    name="strokeWidth"
                                    value={properties.strokeWidth}
                                    onChange={(e) => setProperties((prev) => ({ ...prev, strokeWidth: e.target.value }))}
                                    onKeyDown={handleKeyDownOrBlur}  // Handle Enter and Tab
                                        onBlur={handleKeyDownOrBlur}     // Handle clicking outside
                                />     
                            </div>

                        </div>
                    </div>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mt-1 mb-1'> Pointer</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                W
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="pointerWidth"
                                value={properties.pointerWidth}
                                onChange={(e) => setProperties((prev) => ({ ...prev, pointerWidth: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur} 
                            />     
                        </div>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                L
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="pointerLength"
                                value={properties.pointerLength}
                                onChange={(e) => setProperties((prev) => ({ ...prev, pointerLength: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}  
                            />     
                        </div>
                    </div>
                    
                </div>
              </>
            );
          case 'rectangle':
            return (
              <>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Position</h6>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Position</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                X
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="x"
                                value={properties.x}
                                onChange={(e) => setProperties((prev) => ({ ...prev, x: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}  
                            />     
                        </div>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                Y
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="y"
                                value={properties.y}
                                onChange={(e) => setProperties((prev) => ({ ...prev, y: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}   
                            />     
                        </div>
                    </div>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mt-1 mb-1'>Transform</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2 '>
                            <div className='absolute inset-y-0 pl-1  flex items-center pointer-events-none dark:text-gray-300 text-gray-500'>
                                <svg className='w-7 h-7' fill="none" viewBox="0 0 24 24">
                                    <path fill="currentColor" fillRule="evenodd" d="M9 8.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1H13a4 4 0 0 0-4-4zM9 12v3h3a3 3 0 0 0-3-3" clipRule="evenodd"></path>
                                    </svg>
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="rotation"
                                value={properties.rotation}
                                onChange={(e) => setProperties((prev) => ({ ...prev, rotation: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur} 
                            />    
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Layout</h6>
                <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Dimensions</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                W
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="width"
                                value={properties.width}
                                onChange={(e) => setProperties((prev) => ({ ...prev, width: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur} 
                            />     
                        </div>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                H
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="height"
                                value={properties.height}
                                onChange={(e) => setProperties((prev) => ({ ...prev, height: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}  
                            />     
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Apperance</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='flex flex-col'>
                            <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Opacity</h6>
                            <div className='relative rounded-full w-full mb-2'>
                                <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960" fill="currentColor">
                                    <path d="M480-120q-133 0-226.5-92T160-436q0-65 25-121.5T254-658l184-181q9-8 20-12.5t22-4.5q11 0 22 4.5t20 12.5l184 181q44 44 69 100.5T800-436q0 132-93.5 224T480-120ZM242-400h474q12-72-13.5-123T650-600L480-768 310-600q-27 26-53 77t-15 123Z"/>
                                </svg>
                                </div>
                                <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                    block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                    type="number"
                                    name="opacity"
                                    min="0"
                                    max="100"
                                    step="1"
                                    value={`${Math.round(properties.opacity * 100)}`}
                                    // onChange={(e) => setProperties((prev) => ({ ...prev, opacity: e.target.value }))}
                                    onChange={(e) => {
                                        const newOpacity = parseFloat(e.target.value) / 100;  // Convert percentage back to decimal
                                        setProperties((prev) => ({ ...prev, opacity: newOpacity }));
                                        onUpdate({ ...properties, opacity: newOpacity });
                                    }}
                                    // onKeyDown={handleKeyDownOrBlur} 
                                    // onBlur={handleKeyDownOrBlur} 
                                />     
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'>Corner radius</h6>
                            <div className='relative rounded-full w-full mb-2'>
                                <div className='absolute inset-y-0 pl-1  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                    <svg className='w-7 h-7' fill="none" viewBox="0 0 24 24">
                                        <path fill="currentColor" fillRule="evenodd" d="M12.478 8H15.5a.5.5 0 0 1 0 1h-3c-.708 0-1.21 0-1.601.032-.386.032-.622.092-.807.186a2 2 0 0 0-.874.874c-.094.185-.154.42-.186.807C9 11.29 9 11.792 9 12.5v3a.5.5 0 0 1-1 0v-3.022c0-.681 0-1.223.036-1.66.036-.448.113-.83.291-1.18a3 3 0 0 1 1.311-1.311c.35-.178.732-.255 1.18-.291C11.254 8 11.796 8 12.477 8" clipRule="evenodd"></path>
                                    </svg>
                                </div>
                                <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                    block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                    type="number"
                                    name="cornerRadius"
                                    value={properties.cornerRadius}
                                    onChange={(e) => setProperties((prev) => ({ ...prev, cornerRadius: e.target.value }))}
                                    onKeyDown={handleKeyDownOrBlur} 
                                    onBlur={handleKeyDownOrBlur} 
                                />     
                            </div>

                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Fill</h6>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Color</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-1.5  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                <div 
                                    className="relative inline-block w-5 h-5 rounded-md cursor-pointer "
                                    onClick={handleClick}
                                    style={{ backgroundColor: properties.fill, boxShadow: `0 0 0 1px ${properties.fill}` }}
                                    >
                                    <input
                                        className="absolute inset-0 w-full h-full opacity-0"
                                        ref={inputColorRef}
                                        type="color"
                                        name="color"
                                        value={properties.fill}
                                        onChange={(e) => {
                                            setProperties((prev) => ({ ...prev, fill: e.target.value }))
                                            handleColorChange(e)
                                            }
                                        }
                                        // onKeyDown={handleKeyDownOrBlur} 
                                        // onBlur={handleKeyDownOrBlur} 
                                    />

                                </div>
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="text"
                                name="color"
                                value={properties.fill}
                                onClick={handleClick}
                                onChange={(e) => {
                                    setProperties((prev) => ({ ...prev, fill: e.target.value }))
                                    handleColorChange(e)
                                    }
                                }
                            />     
                        </div>
                    </div>
                </div>
              </>
            );
          case 'ellipse':
            return (
              <>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Position</h6>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Position</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                X
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="x"
                                value={properties.x}
                                onChange={(e) => setProperties((prev) => ({ ...prev, x: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}  
                            />     
                        </div>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                Y
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="y"
                                value={properties.y}
                                onChange={(e) => setProperties((prev) => ({ ...prev, y: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}   
                            />     
                        </div>
                    </div>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mt-1 mb-1'>Transform</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2 '>
                            <div className='absolute inset-y-0 pl-1  flex items-center pointer-events-none dark:text-gray-300 text-gray-500'>
                                <svg className='w-7 h-7' fill="none" viewBox="0 0 24 24">
                                    <path fill="currentColor" fillRule="evenodd" d="M9 8.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1H13a4 4 0 0 0-4-4zM9 12v3h3a3 3 0 0 0-3-3" clipRule="evenodd"></path>
                                    </svg>
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="rotation"
                                value={properties.rotation}
                                onChange={(e) => setProperties((prev) => ({ ...prev, rotation: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur} 
                            />    
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Layout</h6>
                <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Dimensions</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-1.5  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                RX
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="radiusX"
                                value={properties.radiusX}
                                onChange={(e) => setProperties((prev) => ({ ...prev, radiusX: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur} 
                            />     
                        </div>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-1.5  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                RY
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="radiusY"
                                value={properties.radiusY}
                                onChange={(e) => setProperties((prev) => ({ ...prev, radiusY: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}  
                            />     
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Apperance</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='flex flex-col'>
                            <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Opacity</h6>
                            <div className='relative rounded-full w-full mb-2'>
                                <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960" fill="currentColor">
                                    <path d="M480-120q-133 0-226.5-92T160-436q0-65 25-121.5T254-658l184-181q9-8 20-12.5t22-4.5q11 0 22 4.5t20 12.5l184 181q44 44 69 100.5T800-436q0 132-93.5 224T480-120ZM242-400h474q12-72-13.5-123T650-600L480-768 310-600q-27 26-53 77t-15 123Z"/>
                                </svg>
                                </div>
                                <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                    block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                    type="number"
                                    name="opacity"
                                    min="0"
                                    max="100"
                                    step="1"
                                    value={`${Math.round(properties.opacity * 100)}`}
                                    // onChange={(e) => setProperties((prev) => ({ ...prev, opacity: e.target.value }))}
                                    onChange={(e) => {
                                        const newOpacity = parseFloat(e.target.value) / 100;  // Convert percentage back to decimal
                                        setProperties((prev) => ({ ...prev, opacity: newOpacity }));
                                        onUpdate({ ...properties, opacity: newOpacity });
                                    }}
                                    // onKeyDown={handleKeyDownOrBlur} 
                                    // onBlur={handleKeyDownOrBlur} 
                                />     
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Fill</h6>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Color</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-1.5  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                <div 
                                    className="relative inline-block w-5 h-5 rounded-md cursor-pointer "
                                    onClick={handleClick}
                                    style={{ backgroundColor: properties.fill, boxShadow: `0 0 0 1px ${properties.fill}` }}
                                    >
                                    <input
                                        className="absolute inset-0 w-full h-full opacity-0"
                                        ref={inputColorRef}
                                        type="color"
                                        name="color"
                                        value={properties.fill}
                                        onChange={(e) => {
                                            setProperties((prev) => ({ ...prev, fill: e.target.value }))
                                            handleColorChange(e)
                                            }
                                        }
                                        // onKeyDown={handleKeyDownOrBlur} 
                                        // onBlur={handleKeyDownOrBlur} 
                                    />

                                </div>
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="text"
                                name="color"
                                value={properties.fill}
                                onClick={handleClick}
                                onChange={(e) => {
                                    setProperties((prev) => ({ ...prev, fill: e.target.value }))
                                    handleColorChange(e)
                                    }
                                }
                            />     
                        </div>
                    </div>
                </div>
              </>
            );
          case 'text':
            return (
              <>
              <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Position</h6>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Position</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                X
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="x"
                                value={properties.x}
                                onChange={(e) => setProperties((prev) => ({ ...prev, x: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}  
                            />     
                        </div>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                Y
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="y"
                                value={properties.y}
                                onChange={(e) => setProperties((prev) => ({ ...prev, y: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}   
                            />     
                        </div>
                    </div>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mt-1 mb-1'>Transform</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2 '>
                            <div className='absolute inset-y-0 pl-1  flex items-center pointer-events-none dark:text-gray-300 text-gray-500'>
                                <svg className='w-7 h-7' fill="none" viewBox="0 0 24 24">
                                    <path fill="currentColor" fillRule="evenodd" d="M9 8.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1H13a4 4 0 0 0-4-4zM9 12v3h3a3 3 0 0 0-3-3" clipRule="evenodd"></path>
                                    </svg>
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="rotation"
                                value={properties.rotation}
                                onChange={(e) => setProperties((prev) => ({ ...prev, rotation: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur} 
                            />    
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Layout</h6>
                <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Dimensions</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                W
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="width"
                                value={properties.width}
                                onChange={(e) => setProperties((prev) => ({ ...prev, width: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur} 
                            />     
                        </div>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                H
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="height"
                                value={properties.height}
                                onChange={(e) => setProperties((prev) => ({ ...prev, height: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}  
                            />     
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Apperance</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='flex flex-col'>
                            <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Opacity</h6>
                            <div className='relative rounded-full w-full mb-2'>
                                <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960" fill="currentColor">
                                    <path d="M480-120q-133 0-226.5-92T160-436q0-65 25-121.5T254-658l184-181q9-8 20-12.5t22-4.5q11 0 22 4.5t20 12.5l184 181q44 44 69 100.5T800-436q0 132-93.5 224T480-120ZM242-400h474q12-72-13.5-123T650-600L480-768 310-600q-27 26-53 77t-15 123Z"/>
                                </svg>
                                </div>
                                <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                    block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                    type="number"
                                    name="opacity"
                                    min="0"
                                    max="100"
                                    step="1"
                                    value={`${Math.round(properties.opacity * 100)}`}
                                    // onChange={(e) => setProperties((prev) => ({ ...prev, opacity: e.target.value }))}
                                    onChange={(e) => {
                                        const newOpacity = parseFloat(e.target.value) / 100;  // Convert percentage back to decimal
                                        setProperties((prev) => ({ ...prev, opacity: newOpacity }));
                                        onUpdate({ ...properties, opacity: newOpacity });
                                    }}
                                    // onKeyDown={handleKeyDownOrBlur} 
                                    // onBlur={handleKeyDownOrBlur} 
                                />     
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Typography</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='flex flex-col'>
                            <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Font Size</h6>
                            <div className='relative rounded-full w-full mb-2'>
                                <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960" fill="currentColor">
                                    <path xmlns="http://www.w3.org/2000/svg" d="M560-680H420q-25 0-42.5-17.5T360-740q0-25 17.5-42.5T420-800h400q25 0 42.5 17.5T880-740q0 25-17.5 42.5T820-680H680v460q0 25-17.5 42.5T620-160q-25 0-42.5-17.5T560-220v-460ZM200-480h-60q-25 0-42.5-17.5T80-540q0-25 17.5-42.5T140-600h240q25 0 42.5 17.5T440-540q0 25-17.5 42.5T380-480h-60v260q0 25-17.5 42.5T260-160q-25 0-42.5-17.5T200-220v-260Z"/>
                                </svg>
                                </div>
                                <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                    block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                    type="number"
                                    name="fontSize"
                                    value={properties.fontSize}
                                    onChange={(e) => {
                                        const newFontSize = parseFloat(e.target.value);
                                        const textNode = new Konva.Text({
                                            text: properties.text,  // The current text
                                            fontSize: newFontSize,   // The updated font size
                                            fontFamily: properties.fontFamily ,  // Add your current fontFamily here
                                          });
                
                                        const newWidth = textNode.width();  // Get the width of the updated text
                                        const newHeight = textNode.height();  // Get the height of the updated text
                                        // Update the properties with the new width, height, and font size
                                        setProperties((prev) => ({
                                            ...prev,
                                            fontSize: newFontSize,
                                            width: newWidth,
                                            height: newHeight
                                        }));
                                        onUpdate({ ...properties, fontSize: newFontSize, width: newWidth, height: newHeight });
                
                                    }}
                                    // onKeyDown={handleKeyDownOrBlur} 
                                    // onBlur={handleKeyDownOrBlur} 
                                />     
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Fill</h6>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Color</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-1.5  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                <div 
                                    className="relative inline-block w-5 h-5 rounded-md cursor-pointer "
                                    onClick={handleClick}
                                    style={{ backgroundColor: properties.fill, boxShadow: `0 0 0 1px ${properties.fill}` }}
                                    >
                                    <input
                                        className="absolute inset-0 w-full h-full opacity-0"
                                        ref={inputColorRef}
                                        type="color"
                                        name="color"
                                        value={properties.fill}
                                        onChange={(e) => {
                                            setProperties((prev) => ({ ...prev, fill: e.target.value }))
                                            handleColorChange(e)
                                            }
                                        }
                                        // onKeyDown={handleKeyDownOrBlur} 
                                        // onBlur={handleKeyDownOrBlur} 
                                    />

                                </div>
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="text"
                                name="color"
                                value={properties.fill}
                                onClick={handleClick}
                                onChange={(e) => {
                                    setProperties((prev) => ({ ...prev, fill: e.target.value }))
                                    handleColorChange(e)
                                    }
                                }
                            />     
                        </div>
                    </div>
                </div>



              </>
            );
          case 'star':
            return (
                <>
                    <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Position</h6>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Position</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                X
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="x"
                                value={properties.x}
                                onChange={(e) => setProperties((prev) => ({ ...prev, x: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}  
                            />     
                        </div>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                Y
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="y"
                                value={properties.y}
                                onChange={(e) => setProperties((prev) => ({ ...prev, y: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}   
                            />     
                        </div>
                    </div>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mt-1 mb-1'>Transform</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2 '>
                            <div className='absolute inset-y-0 pl-1  flex items-center pointer-events-none dark:text-gray-300 text-gray-500'>
                                <svg className='w-7 h-7' fill="none" viewBox="0 0 24 24">
                                    <path fill="currentColor" fillRule="evenodd" d="M9 8.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1H13a4 4 0 0 0-4-4zM9 12v3h3a3 3 0 0 0-3-3" clipRule="evenodd"></path>
                                    </svg>
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="rotation"
                                value={properties.rotation}
                                onChange={(e) => setProperties((prev) => ({ ...prev, rotation: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur} 
                            />    
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Layout</h6>
                <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Dimensions</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                W
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="width"
                                value={properties.width}
                                onChange={(e) => setProperties((prev) => ({ ...prev, width: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur} 
                            />     
                        </div>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                H
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="height"
                                value={properties.height}
                                onChange={(e) => setProperties((prev) => ({ ...prev, height: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}  
                            />     
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Apperance</h6>
                    <div className='grid grid-cols-2 gap-2 mb-1'>
                        <div className='flex flex-col'>
                            <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Opacity</h6>
                            <div className='relative rounded-full w-full mb-2'>
                                <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960" fill="currentColor">
                                    <path d="M480-120q-133 0-226.5-92T160-436q0-65 25-121.5T254-658l184-181q9-8 20-12.5t22-4.5q11 0 22 4.5t20 12.5l184 181q44 44 69 100.5T800-436q0 132-93.5 224T480-120ZM242-400h474q12-72-13.5-123T650-600L480-768 310-600q-27 26-53 77t-15 123Z"/>
                                </svg>
                                </div>
                                <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                    block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                    type="number"
                                    name="opacity"
                                    min="0"
                                    max="100"
                                    step="1"
                                    value={`${Math.round(properties.opacity * 100)}`}
                                    // onChange={(e) => setProperties((prev) => ({ ...prev, opacity: e.target.value }))}
                                    onChange={(e) => {
                                        const newOpacity = parseFloat(e.target.value) / 100;  // Convert percentage back to decimal
                                        setProperties((prev) => ({ ...prev, opacity: newOpacity }));
                                        onUpdate({ ...properties, opacity: newOpacity });
                                    }}
                                    // onKeyDown={handleKeyDownOrBlur} 
                                    // onBlur={handleKeyDownOrBlur} 
                                />     
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-2 mb-1'>
                        <div className='flex flex-col'>
                            <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Count</h6>
                            <div className='relative rounded-full w-full mb-2'>
                                <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                    <svg className='w-3.5 h-3.5' fill="none" viewBox="0 0 12 12">
                                        <path fill="currentColor" fillOpacity="1" fillRule="nonzero" stroke="none" d="M5.5 0h1v12h-1zM12 5.5v1H0v-1zM9.89 1.404l.706.707-8.485 8.485-.707-.707 8.485-8.485zm.706 8.485-.707.707-8.485-8.485.707-.707z"></path>
                                    </svg>
                                </div>
                                <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                    block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                    type="number"
                                    name="numPoints"
                                    value={properties.numPoints}
                                    onChange={(e) => setProperties((prev) => ({ ...prev, numPoints: e.target.value }))}
                                    onKeyDown={handleKeyDownOrBlur} 
                                    onBlur={handleKeyDownOrBlur} 
                                />     
                            </div>
                        </div>
                        {/* <div className='flex flex-col'>
                            <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'>Corner radius</h6>
                            <div className='relative rounded-full w-full mb-2'>
                                <div className='absolute inset-y-0 pl-1  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                    <svg className='w-7 h-7' fill="none" viewBox="0 0 24 24">
                                        <path fill="currentColor" fillRule="evenodd" d="M12.478 8H15.5a.5.5 0 0 1 0 1h-3c-.708 0-1.21 0-1.601.032-.386.032-.622.092-.807.186a2 2 0 0 0-.874.874c-.094.185-.154.42-.186.807C9 11.29 9 11.792 9 12.5v3a.5.5 0 0 1-1 0v-3.022c0-.681 0-1.223.036-1.66.036-.448.113-.83.291-1.18a3 3 0 0 1 1.311-1.311c.35-.178.732-.255 1.18-.291C11.254 8 11.796 8 12.477 8" clipRule="evenodd"></path>
                                    </svg>
                                </div>
                                <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                    block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                    type="number"
                                    name="cornerRadius"
                                    value={properties.cornerRadius}
                                    onChange={(e) => setProperties((prev) => ({ ...prev, cornerRadius: e.target.value }))}
                                    onKeyDown={handleKeyDownOrBlur} 
                                    onBlur={handleKeyDownOrBlur} 
                                />     
                            </div>

                        </div> */}
                    </div>
                    <div className='grid grid-cols-2 gap-2 mb-1'>
                        <div className='flex flex-col'>
                            <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Inner Radius</h6>
                            <div className='relative rounded-full w-full mb-2'>
                                <div className='absolute inset-y-0 pl-2  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                    IR
                                </div>
                                <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                    block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                    type="number"
                                    name="innerRadius"
                                    value={properties.innerRadius }
                                    onChange={(e) => setProperties((prev) => ({ ...prev, innerRadius: e.target.value }))}
                                    onKeyDown={handleKeyDownOrBlur} 
                                    onBlur={handleKeyDownOrBlur} 
                                />     
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'>Outer radius</h6>
                            <div className='relative rounded-full w-full mb-2'>
                                <div className='absolute inset-y-0 pl-2  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                    OR
                                </div>
                                <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                    block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                    type="number"
                                    name="outerRadius"
                                    value={properties.outerRadius}
                                    onChange={(e) => setProperties((prev) => ({ ...prev, outerRadius: e.target.value }))}
                                    onKeyDown={handleKeyDownOrBlur} 
                                    onBlur={handleKeyDownOrBlur} 
                                />     
                            </div>

                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Fill</h6>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Color</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-1.5  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                <div 
                                    className="relative inline-block w-5 h-5 rounded-md cursor-pointer "
                                    onClick={handleClick}
                                    style={{ backgroundColor: properties.fill, boxShadow: `0 0 0 1px ${properties.fill}` }}
                                    >
                                    <input
                                        className="absolute inset-0 w-full h-full opacity-0"
                                        ref={inputColorRef}
                                        type="color"
                                        name="color"
                                        value={properties.fill}
                                        onChange={(e) => {
                                            setProperties((prev) => ({ ...prev, fill: e.target.value }))
                                            handleColorChange(e)
                                            }
                                        }
                                        // onKeyDown={handleKeyDownOrBlur} 
                                        // onBlur={handleKeyDownOrBlur} 
                                    />

                                </div>
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="text"
                                name="color"
                                value={properties.fill}
                                onClick={handleClick}
                                onChange={(e) => {
                                    setProperties((prev) => ({ ...prev, fill: e.target.value }))
                                    handleColorChange(e)
                                    }
                                }
                            />     
                        </div>
                    </div>
                </div>
                </>
              );
          case 'polygon':
            return (
              <>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Position</h6>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Position</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                X
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="x"
                                value={properties.x}
                                onChange={(e) => setProperties((prev) => ({ ...prev, x: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}  
                            />     
                        </div>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                Y
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="y"
                                value={properties.y}
                                onChange={(e) => setProperties((prev) => ({ ...prev, y: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}   
                            />     
                        </div>
                    </div>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mt-1 mb-1'>Transform</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2 '>
                            <div className='absolute inset-y-0 pl-1  flex items-center pointer-events-none dark:text-gray-300 text-gray-500'>
                                <svg className='w-7 h-7' fill="none" viewBox="0 0 24 24">
                                    <path fill="currentColor" fillRule="evenodd" d="M9 8.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1H13a4 4 0 0 0-4-4zM9 12v3h3a3 3 0 0 0-3-3" clipRule="evenodd"></path>
                                    </svg>
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="rotation"
                                value={properties.rotation}
                                onChange={(e) => setProperties((prev) => ({ ...prev, rotation: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur} 
                            />    
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Apperance</h6>
                    <div className='grid grid-cols-2 gap-2 mb-1'>
                        <div className='flex flex-col'>
                            <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Opacity</h6>
                            <div className='relative rounded-full w-full mb-2'>
                                <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960" fill="currentColor">
                                    <path d="M480-120q-133 0-226.5-92T160-436q0-65 25-121.5T254-658l184-181q9-8 20-12.5t22-4.5q11 0 22 4.5t20 12.5l184 181q44 44 69 100.5T800-436q0 132-93.5 224T480-120ZM242-400h474q12-72-13.5-123T650-600L480-768 310-600q-27 26-53 77t-15 123Z"/>
                                </svg>
                                </div>
                                <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                    block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                    type="number"
                                    name="opacity"
                                    min="0"
                                    max="100"
                                    step="1"
                                    value={`${Math.round(properties.opacity * 100)}`}
                                    // onChange={(e) => setProperties((prev) => ({ ...prev, opacity: e.target.value }))}
                                    onChange={(e) => {
                                        const newOpacity = parseFloat(e.target.value) / 100;  // Convert percentage back to decimal
                                        setProperties((prev) => ({ ...prev, opacity: newOpacity }));
                                        onUpdate({ ...properties, opacity: newOpacity });
                                    }}
                                    // onKeyDown={handleKeyDownOrBlur} 
                                    // onBlur={handleKeyDownOrBlur} 
                                />     
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-2 mb-1'>
                        <div className='flex flex-col'>
                            <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Count</h6>
                            <div className='relative rounded-full w-full mb-2'>
                                <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                    <svg className='w-3.5 h-3.5' fill="none" viewBox="0 0 12 12">
                                        <path fill="currentColor" fillOpacity="1" fillRule="nonzero" stroke="none" d="M5.5 0h1v12h-1zM12 5.5v1H0v-1zM9.89 1.404l.706.707-8.485 8.485-.707-.707 8.485-8.485zm.706 8.485-.707.707-8.485-8.485.707-.707z"></path>
                                    </svg>
                                </div>
                                <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                    block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                    type="number"
                                    name="sides"
                                    value={properties.sides}
                                    onChange={(e) => setProperties((prev) => ({ ...prev, sides: e.target.value }))}
                                    onKeyDown={handleKeyDownOrBlur} 
                                    onBlur={handleKeyDownOrBlur} 
                                />     
                            </div>
                        </div>
                        {/* <div className='flex flex-col'>
                            <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'>Corner radius</h6>
                            <div className='relative rounded-full w-full mb-2'>
                                <div className='absolute inset-y-0 pl-1  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                    <svg className='w-7 h-7' fill="none" viewBox="0 0 24 24">
                                        <path fill="currentColor" fillRule="evenodd" d="M12.478 8H15.5a.5.5 0 0 1 0 1h-3c-.708 0-1.21 0-1.601.032-.386.032-.622.092-.807.186a2 2 0 0 0-.874.874c-.094.185-.154.42-.186.807C9 11.29 9 11.792 9 12.5v3a.5.5 0 0 1-1 0v-3.022c0-.681 0-1.223.036-1.66.036-.448.113-.83.291-1.18a3 3 0 0 1 1.311-1.311c.35-.178.732-.255 1.18-.291C11.254 8 11.796 8 12.477 8" clipRule="evenodd"></path>
                                    </svg>
                                </div>
                                <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                    block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                    type="number"
                                    name="cornerRadius"
                                    value={properties.cornerRadius}
                                    onChange={(e) => setProperties((prev) => ({ ...prev, cornerRadius: e.target.value }))}
                                    onKeyDown={handleKeyDownOrBlur} 
                                    onBlur={handleKeyDownOrBlur} 
                                />     
                            </div>

                        </div> */}
                    </div>
                    <div className='grid grid-cols-2 gap-2 mb-1'>
                        <div className='flex flex-col'>
                            <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Radius</h6>
                            <div className='relative rounded-full w-full mb-2'>
                                <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                    R
                                </div>
                                <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                    block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                    type="number"
                                    name="radius"
                                    value={properties.radius}
                                    onChange={(e) => setProperties((prev) => ({ ...prev, radius: e.target.value }))}
                                    onKeyDown={handleKeyDownOrBlur} 
                                    onBlur={handleKeyDownOrBlur} 
                                />     
                            </div>
                        </div>
                        {/* <div className='flex flex-col'>
                            <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'>Outer radius</h6>
                            <div className='relative rounded-full w-full mb-2'>
                                <div className='absolute inset-y-0 pl-2  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                    OR
                                </div>
                                <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                    block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                    type="number"
                                    name="outerRadius"
                                    value={properties.outerRadius}
                                    onChange={(e) => setProperties((prev) => ({ ...prev, outerRadius: e.target.value }))}
                                    onKeyDown={handleKeyDownOrBlur} 
                                    onBlur={handleKeyDownOrBlur} 
                                />     
                            </div>

                        </div> */}
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Fill</h6>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Color</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-1.5  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 '>
                                <div 
                                    className="relative inline-block w-5 h-5 rounded-md cursor-pointer "
                                    onClick={handleClick}
                                    style={{ backgroundColor: properties.fill, boxShadow: `0 0 0 1px ${properties.fill}` }}
                                    >
                                    <input
                                        className="absolute inset-0 w-full h-full opacity-0"
                                        ref={inputColorRef}
                                        type="color"
                                        name="color"
                                        value={properties.fill}
                                        onChange={(e) => {
                                            setProperties((prev) => ({ ...prev, fill: e.target.value }))
                                            handleColorChange(e)
                                            }
                                        }
                                        // onKeyDown={handleKeyDownOrBlur} 
                                        // onBlur={handleKeyDownOrBlur} 
                                    />

                                </div>
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="text"
                                name="color"
                                value={properties.fill}
                                onClick={handleClick}
                                onChange={(e) => {
                                    setProperties((prev) => ({ ...prev, fill: e.target.value }))
                                    handleColorChange(e)
                                    }
                                }
                            />     
                        </div>
                    </div>
                </div>
              </>
            );
         case 'asset':
                return (
                  <>

                    <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Position</h6>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Position</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                X
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="x"
                                value={properties.x}
                                onChange={(e) => setProperties((prev) => ({ ...prev, x: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}  
                            />     
                        </div>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                Y
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="y"
                                value={properties.y}
                                onChange={(e) => setProperties((prev) => ({ ...prev, y: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}   
                            />     
                        </div>
                    </div>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mt-1 mb-1'>Transform</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2 '>
                            <div className='absolute inset-y-0 pl-1  flex items-center pointer-events-none dark:text-gray-300 text-gray-500'>
                                <svg className='w-7 h-7' fill="none" viewBox="0 0 24 24">
                                    <path fill="currentColor" fillRule="evenodd" d="M9 8.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1H13a4 4 0 0 0-4-4zM9 12v3h3a3 3 0 0 0-3-3" clipRule="evenodd"></path>
                                    </svg>
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="rotation"
                                value={properties.rotation}
                                onChange={(e) => setProperties((prev) => ({ ...prev, rotation: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur} 
                            />    
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Layout</h6>
                <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Dimensions</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                W
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="assetWidth"
                                value={properties.assetWidth}
                                onChange={(e) => setProperties((prev) => ({ ...prev, assetWidth: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur} 
                            />     
                        </div>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                H
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="assetHeight"
                                value={properties.assetHeight}
                                onChange={(e) => setProperties((prev) => ({ ...prev, assetHeight: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}  
                            />     
                        </div>
                    </div>
                </div>
                  </>
                );
         case 'image':
                return (
                  <>

                    <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4'>
                    <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Position</h6>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Position</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                X
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="x"
                                value={properties.x}
                                onChange={(e) => setProperties((prev) => ({ ...prev, x: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}  
                            />     
                        </div>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                Y
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="y"
                                value={properties.y}
                                onChange={(e) => setProperties((prev) => ({ ...prev, y: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}   
                            />     
                        </div>
                    </div>
                    <h6 className='text-xs text-gray-500 dark:text-gray-400 mt-1 mb-1'>Transform</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2 '>
                            <div className='absolute inset-y-0 pl-1  flex items-center pointer-events-none dark:text-gray-300 text-gray-500'>
                                <svg className='w-7 h-7' fill="none" viewBox="0 0 24 24">
                                    <path fill="currentColor" fillRule="evenodd" d="M9 8.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1H13a4 4 0 0 0-4-4zM9 12v3h3a3 3 0 0 0-3-3" clipRule="evenodd"></path>
                                    </svg>
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="rotation"
                                value={properties.rotation}
                                onChange={(e) => setProperties((prev) => ({ ...prev, rotation: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur} 
                            />    
                        </div>
                    </div>
                </div>
                <div className=' border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pb-4 pt-4'>
                <h6 className='text-sm text-gray-600 dark:text-gray-100 mb-4'> Layout</h6>
                <h6 className='text-xs text-gray-500 dark:text-gray-400 mb-1'> Dimensions</h6>
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                W
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="width"
                                value={properties.width}
                                onChange={(e) => setProperties((prev) => ({ ...prev, width: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur} 
                            />     
                        </div>
                        <div className='relative rounded-full w-full mb-2'>
                            <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none dark:text-gray-300 text-gray-500 text-sm'>
                                H
                            </div>
                            <input className={`bg-gray-100 text-gray-800 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-white dark:placeholder:text-gray-400
                                block w-full pl-8 text-sm rounded-lg text-ellipsis py-1 `}
                                type="number"
                                name="height"
                                value={properties.height}
                                onChange={(e) => setProperties((prev) => ({ ...prev, height: e.target.value }))}
                                onKeyDown={handleKeyDownOrBlur} 
                                onBlur={handleKeyDownOrBlur}  
                            />     
                        </div>
                    </div>
                </div>
                  </>
                );
          default:
            return ;
        }
      };

      
    
      return (
        <div
            className={`fixed right-0 top-0 w-64 h-full bg-slate-200 dark:bg-stone-900  shadow-lg  z-50 overflow-y-auto transform transition-transform duration-300 ${
            isOpen ? 'translate-x-0' : 'translate-x-full'
            }`}
        >
              <h2 className="text-base text-gray-600 dark:text-gray-300 font-semibold mb-4 border-b-[0.01em] border-slate-400 dark:border-slate-600 px-4 pt-4 pb-2">{title}</h2>
              {renderFields()}
             
        </div>
      );
}

export default CanvasRightSideDrawer


