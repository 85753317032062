import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MemberTooltip } from "../../styles/Tooltip";
import { Zoom } from '@mui/material';
import { darkModeState } from "../../atoms/darkModeState";
import { useRecoilValue } from "recoil";
import StatusBarSection from "./StatusBarSection";

function StatusBarLarge({statusBarData, barChartCategories, refreshActivityList,  operationsList, refreshDowntimeCategories, downtimeCategories}){
    const isDark = useRecoilValue(darkModeState)
    // console.log('statusBarData', statusBarData)
    // let num = Object.keys(statusBarData).length
    let categoriesLast = Object.keys(barChartCategories).length - 1
    let catPercent = 100/categoriesLast + '%'
    
    var lastHourIndex = Object.keys(barChartCategories).length -1
    // var tooltipPercent = 0
    // var widthCalc = 0

    let skip = false



    
  
    const containerStyles = {
      height: 22,
      width: '100%',
      borderRadius: 50,
      marginTop: 0,
      borderRadius: '999px',
      overflow: 'hidden'
    }
  
    const fillerStyles = (index, width, color) => { 
      let currentWidth = width
      // let stringNum = currentWidth.replace('%', '');
      // let num = parseFloat(stringNum)
      // if(num < 0 ){
      //   currentWidth = '0%'
      // }
      // widthCalc = num+widthCalc
      return{
        height: '100%',
        width: currentWidth,
        backgroundColor: color ? color : "#94a3b8",
        // backgroundColor: status === 'running' ? '#16a34a' : '#dc2626',
        // borderRadius: 'inherit',
        // borderRadius: 50,
        borderRadius: index === 0 ?'999px 0px 0px 999px' :'0rem 0rem 0rem 0rem',
        textAlign: 'right',
        transition: 'width 1s ease-in-out',
      }
    }
  
    const labelStyles = {
      padding: 0,
      color: 'white',
      fontWeight: 'bold'
    }
    
    const Tooltip = {
      position:'absolute',
      left:`80%`,
      top:'-25px',
      height: '12px',
      width: `24px`,
      padding: 5,
      // color: 'white',
      fontWeight: 'bold'
    }

    const categoriesContainerStyleMD = (index) => {
     
      if(categoriesLast > 10){
        let showNum = true
        if(index === 0 ){
          showNum = true
        }else if(index === categoriesLast - 1){
          showNum = true
        }else if(index % 2 == 0){
          if(!skip){
            showNum = true
            skip = true
          }else{
            showNum = false
            skip = false
          }
        }else{
          showNum = false
        }


        return {
          width: catPercent,
          opacity: showNum ? 1 : 0
          // textAlign: index === categoriesLast ? 'right' : 'left'
        }
      } else if(categoriesLast > 7){
          let showNum = true
          if(index === 0 ){
            showNum = true
          }else if(index === categoriesLast - 1){
            showNum = true
          }else if(index % 2 == 0){
            showNum = true
          }else{
            showNum = false
          }
          return {
            width: catPercent,
            opacity: showNum ? 1 : 0
            // textAlign: index === categoriesLast ? 'right' : 'left'
          }
        }else{
        return {
          width: catPercent,
        }
      }

    }
    const categoriesContainerStyleLG = (index) => {
      if(categoriesLast > 10){
        let showNum = true
        if(index === 0 ){
          showNum = true
        }else if(index === categoriesLast -1){
          showNum = true
        }else if(index % 2 == 0){
          if(!skip){
            showNum = true
            skip = true
          }else{
            showNum = false
            skip = false
          }
        }else{
          showNum = false
        }


        return {
          width: catPercent,
          opacity: showNum ? 1 : 0
          // textAlign: index === categoriesLast ? 'right' : 'left'
        }
      }else{
        return {
          width: catPercent,
        }
      }

    }

    const categoriesContainerStyle2XL = (index) => {
      if(categoriesLast > 10){
        let showNum = true
        if(index === 0 ){
          showNum = true
        }else if(index === categoriesLast - 1){
          showNum = true
        }else if(index % 2 == 0){
          showNum = true
        }else{
          showNum = false
        }


        return {
          width: catPercent,
          opacity: showNum ? 1 : 0
          // textAlign: index === categoriesLast ? 'right' : 'left'
        }
      }else{
        return {
          width: catPercent,
        }
      }

    }

    const hourlyLines = (index) => {
      return {
        width: catPercent,
      }

    }

    const formatDate = (end) => {
      if(end){
          // var startDate = new Date(start)
          // var startHours = startDate.getHours();
          // var startMinutes = startDate.getMinutes();
          // var startAmPm = startHours >= 12 ? 'pm' : 'am';
          // startHours = startHours % 12;
          // startHours = startHours ? startHours : 12; // the hour '0' should be '12'
          // startMinutes = startMinutes < 10 ? '0'+startMinutes : startMinutes;
          // var startStrTime = startHours + ':' + startMinutes + ' ' + startAmPm;
          // var shortStartDate = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2)

          var endDate = new Date(end)
          var endHours = endDate.getHours();
          var endMinutes = endDate.getMinutes();
          var endAmPm = endHours >= 12 ? 'pm' : 'am';
          endHours = endHours % 12;
          endHours = endHours ? endHours : 12; // the hour '0' should be '12'
          endMinutes = endMinutes < 10 ? '0'+endMinutes : endMinutes;
          var endStrTime = endHours + ':' + endMinutes + '' + endAmPm;
          var shortEndDate = endDate.getMonth()+1 + "/" + endDate.getDate() + "/" + endDate.getFullYear().toString().substr(-2)
          // var endTime = endDate.getMonth()+1 + "/" + endDate.getDate() + "/" + endDate.getFullYear().toString().substr(-2) + " " + endStrTime;
          // var startTime = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2) + " " + startStrTime;
          return endStrTime;
          // return shortEndDate + "  " + endStrTime;

          // if(shortStartDate == shortEndDate){
          //     return shortStartDate + "  " + startStrTime +  " - " + endStrTime;
          // }else{
          //     return shortStartDate + " " + startStrTime + " - " + shortEndDate + " " + endStrTime;
          // }
      }
  }
  
    return (
      <div className='relative' >
        <div style={containerStyles} className='bg-slate-200 dark:bg-slate-600 flex' >
          {statusBarData.map((activity, index) => (
            // <MemberTooltip 
            //     TransitionComponent={Zoom}
            //     title={
            //         <div className={`flex flex-col rounded-lg p-2 ${isDark? ' bg-slate-700':' bg-slate-100'} `}>
            //           <div className={`w-full text-center py-1 text-sm ${isDark? ' text-gray-400':'text-gray-700'} `}>
            //               <span>{activity.utilization_state_identifier}</span>
            //           </div>
            //           <div className={`w-full text-center py-1 text-sm ${isDark? ' text-gray-400':'text-gray-700'} `}>
            //               <span>{formatDate(activity.utilization_start_timestamp)} - {formatDate(activity.utilization_end_timestamp)} </span>
            //           </div>
            //         </div>
            //     }
            //     key={`tooltip-${index}`}
            //     >
            //   <div key={`status-${index}`} style={fillerStyles(index, activity.amount, activity.utilization_state_color)} />
            // </MemberTooltip>
              <StatusBarSection key={`status-${index}`} index={index} activity={activity} refreshActivityList={refreshActivityList} operationsList={operationsList} downtimeCategories={downtimeCategories} refreshDowntimeCategories={refreshDowntimeCategories}   />
          ))}
        </div>
        {barChartCategories && Object.keys(barChartCategories).length > 0 && catPercent &&
        <>
          <div className="absolute top-[130%] w-full  hidden 2xl:flex">
            {barChartCategories.map((time, index) => (
              index != categoriesLast &&
              <div key={`2XL-${index}`} style={categoriesContainerStyle2XL(index)}>
                <span className='text-gray-400 dark:text-gray-400 truncate'>{time}</span>
              </div>
            ))}
          </div>
          <div className="absolute top-[130%] w-full  hidden lg:flex 2xl:hidden">
            {barChartCategories.map((time, index) => (
              index != categoriesLast &&
              <div key={`LG-${index}`} style={categoriesContainerStyleLG(index)}>
                <span className='text-gray-400 dark:text-gray-400 truncate'>{time}</span>
              </div>
            ))}
          </div>
          <div className="absolute top-[130%] w-full  hidden md:flex lg:hidden">
            {barChartCategories.map((time, index) => (
              index != categoriesLast &&
              <div key={`MD-${index}`} style={categoriesContainerStyleMD(index)}>
                <span className='text-gray-400 dark:text-gray-400 text-sm truncate'>{time}</span>
              </div>
            ))}
          </div>
          <div className="absolute top-[130%] w-full flex md:hidden ">
            <div className='text-gray-400 text-sm '>
            {barChartCategories[0]}
            </div>
            <div className='text-gray-400 text-sm ml-auto'>
            {barChartCategories[lastHourIndex]}
            </div>
          </div>
        </>
        }
      

      </div>
    );
  };
  
  export default StatusBarLarge;