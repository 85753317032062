import DeviceSensorGraph from './DeviceSensorGraph';
import AddAssetModal from '../components/modals/assetmodals/AddAssetModal';
import AddSensorModal from '../components/modals/sensorModals/AddSensorModal';
import AddGatewayModal from '../components/modals/gatewayModals/AddGatewayModal';
import { useEffect, useState, Fragment } from 'react';
import { getData } from '../ApiCalls/DataApis';
import { apiLink } from '../ApiCalls/ApisVariables';
import { globalSelectedOrganization } from '../atoms/orgStates';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion"
import { Menu, Transition } from '@headlessui/react'
import AssignAssetToSensor from '../components/modals/assetmodals/AssignAssetToSensor';
import EditAssetModal from '../components/modals/assetmodals/EditAssetModal';
import DeleteAssetModal from '../components/modals/assetmodals/DeleteAssetModal';
import EditSensorListModal from '../components/modals/sensorModals/EditSensorListModal';
import EditGatewayListModal from '../components/modals/gatewayModals/EditGatewayListModal';
import OptimizeLargeImage from '../components/OptimizeLargeImage';
import { refreshOrganizationApis } from '../atoms/refreshStates';
import DeviceSectionGraph from './DeviceSectionGraph';



function DevicesManagementPage() {
    const navigate = useNavigate()
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const isOrganizationApisRefreshed = useRecoilValue(refreshOrganizationApis)
    const [gatewayList, setGatewayList] = useState([])
    const [isEditAssetModalOpen, setIsEditAssetModalOpen] = useState(false)
    const [isDeleteAssetModalOpen, setIsDeleteAssetModalOpen] = useState(false)
    const [isAssignAssetToSensorModalOpen, setIsAssignAssetToSensorModalOpen] = useState(false)
    const [assignedAssetUuid, setAssignedAssetUuid] = useState(false)
    const [sensorList, setSensorList] = useState([])
    const [sectionedSensorList, setSectionedSensorList] = useState([])

     
    const handleOpenEditAssetModal = () => {
        setIsEditAssetModalOpen(false)
    }
    const handleOpenDeleteAssetModal = () => {
        setIsDeleteAssetModalOpen(false)
    }

    const handleAssignAssetToSensor = (asset_uuid) => {
        setIsAssignAssetToSensorModalOpen(true)
        setAssignedAssetUuid(asset_uuid)
    }
    const handleCloseAssignAssetToSensor = () => {
        setIsAssignAssetToSensorModalOpen(false)
        setAssignedAssetUuid('')
    }

    useEffect(() => {
        try {
            if(selectedOrganization){
               getAssetsNodesGateways()
               getSensorList()
            } 
        } catch (error) {
            
        }

    }, [selectedOrganization, isOrganizationApisRefreshed])

    const getAssetsNodesGateways = () => {
        getData(apiLink + '/v1/organizations/' + selectedOrganization + '/assets-nodes-gateways')
        .then(async response => {
            if(response.ok ){
            const data = await response.json();
            if(data && data.data){
                // setGatewayList(data.data)

                // console.log('data.data[0]', data.data[0])
                let items = []
                if(data.data[0] && data.data[0].node_assets){
                    items = data.data[0].node_assets
                }
                // console.log('items', items)
                const chunkSize = 10;
                const chunks = [];
                let currentChunk = [];

                for (let i = 0; i < Object.keys(items).length; i++) {
                    currentChunk.push(items[i]);
                    if (Object.keys(currentChunk).length === chunkSize) {
                        chunks.push(currentChunk);
                        currentChunk = [];
                    }
                }
                // console.log('currentChunk', currentChunk)
                // Add the last chunk if it has items
                if (Object.keys(currentChunk).length > 0) {
                    chunks.push(currentChunk);
                }
                // console.log('chunks', chunks)
                setSectionedSensorList(chunks)
                
            }
            }
        });
    }

    const getSensorList = () => {
        try {
            if(selectedOrganization){
                getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/nodes/senseai' )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            // console.log('Sensor data', data.data)
                            setSensorList(data.data)
                        }
                    }
                })
            }
            
        } catch (error) {
          console.log(error.message)
        }

    }

    
  return (
    <div className='mt-2 p-1 overflow-scroll h-screen scrollbar-hide' >     
        <div className="flex flex-col min-w-[925px]">
            <div className=' flex items-center mb-4'>
                <div className='flex space-x-2 items-center ml-auto'>
                    <EditSensorListModal btnType='standardBtn' sensorList={sensorList} getSensorList={getSensorList} getAssetsNodesGateways={getAssetsNodesGateways} />
                    {/* <EditGatewayListModal btnType='standardBtn' /> */}
                </div>
            </div>
            {/* List Header */}
            <div className='grid grid-cols-7 pb-4 pt-3 text-left shadow-iotflows-lg rounded-t-3xl rounded-b-lg dark:bg-slate-800'>
                <div className='col-span-2 flex text-lg pl-4 text-gray-400 dark:text-gray-300 items-baseline'>
                    Asset
                    {/* <AddAssetModal btnType='tableBtn' getOrgAssets={getAssetsNodesGateways} /> */}
                </div>
                <div className='col-span-2 flex text-lg pl-4 text-gray-400 dark:text-gray-300 items-baseline'>
                    Sensor
                    {/* <AddSensorModal btnType='tableBtn' refreshSensorList={getAssetsNodesGateways} /> */}
                </div>
                <div className='col-span-3 flex text-lg pl-4 text-gray-400 dark:text-gray-300 items-baseline'>
                    Gateway
                    {/* <AddGatewayModal btnType='tableBtn' refreshGatewayList={getAssetsNodesGateways} /> */}
                </div>
            </div>
            
            {/* Old method but we were having issue with the rending due to so many devices in one gateway */}
            {/* {gatewayList && Object.keys(gatewayList).length > 0 &&
                gatewayList.map((gateway, gatewayIndex) =>(
                    gateway.device_uuid &&
                    <div key={gateway.device_uuid}>
                        <DeviceSensorGraph gateway={gateway} />
                    </div>
                ))
            } */}
            {sectionedSensorList && Object.keys(sectionedSensorList).length > 0 &&
                sectionedSensorList.map((section, sectionIndex) =>(
                    <div key={sectionIndex}>
                        <DeviceSectionGraph section={section} />
                    </div>
                ))
            }
            {/* Show assets that don't have a sensor tied to them */}
            <div className='flex flex-col pl-1 mt-4 pb-2 space-y-4'>
                {gatewayList && Object.keys(gatewayList).length > 0 &&
                    gatewayList.map((gateway, gatewayIndex) =>(
                        gateway.device_uuid === null &&
                        <div key={gatewayIndex} >
                            {gateway.node_assets && Object.keys(gateway.node_assets).length > 0 &&
                                gateway.node_assets.map(sensor => (
                                <div key={sensor.asset_uuid} className=' row-span-1 col-span-2 flex mb-4'>
                                    <div className='relative cursor-pointer flex w-56 h-24 shadow-iotflows-lg rounded-lg dark:bg-slate-800 pl-4 items-center' >
                                        <div className='absolute top-0 right-0 z-50' >
                                            <div className="relative">
                                                <div className="w-4 h-4 bg-transparent"></div>
                                                <div className="absolute top-1 right-0 -mr-1 -mt-1 w-3 h-3 rounded-full bg-rose-400 dark:bg-rose-600 animate-ping"></div>
                                                <div className="absolute top-1 right-0 -mr-1 -mt-1 w-3 h-3 rounded-full bg-rose-400 dark:bg-rose-600"></div>
                                            </div>
                                        </div>
                                        <div className='absolute top-0 right-0 z-50'>
                                            <Menu as="div" className="relative text-left">
                                                <div>
                                                <Menu.Button className="inline-flex w-full justify-center text-center rounded-full bg-transparent p-0.5 text-sm font-medium hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300 " aria-hidden="true" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                                    </svg>
                                                </Menu.Button>
                                                </div>
                                                <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                                >
                                                <Menu.Items className="absolute right-0 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white dark:bg-slate-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="px-1 py-1 ">
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                            <button
                                                                className={`${
                                                                active ? 'bg-blue-500 dark:bg-blue-700 text-white' : 'text-rose-600 dark:text-rose-300'
                                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                onClick={() => handleAssignAssetToSensor(sensor.asset_uuid)}
                                                            >
                                                                Assign sensor
                                                            </button>
                                                            )}
                                                        </Menu.Item>
                                                        {/* <Menu.Item>
                                                            {({ active }) => (
                                                            <button
                                                                className={`${
                                                                active ? 'bg-blue-500 dark:bg-blue-700 text-white' : 'text-gray-900 dark:text-gray-300'
                                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                onClick={()=> setIsEditAssetModalOpen(true)}
                                                            >
                                                                {active ? (
                                                                <EditActiveIcon
                                                                    className="mr-2 h-5 w-5"
                                                                    aria-hidden="true"
                                                                />
                                                                ) : (
                                                                <EditInactiveIcon
                                                                    className="mr-2 h-5 w-5"
                                                                    aria-hidden="true"
                                                                />
                                                                )}
                                                                Edit
                                                            </button>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                            <button
                                                                className={`${
                                                                active ? 'bg-blue-500 dark:bg-blue-700 text-white' : 'text-gray-900 dark:text-gray-300'
                                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                onClick={()=> setIsDeleteAssetModalOpen(true)}
                                                            >
                                                                {active ? (
                                                                <DeleteActiveIcon
                                                                    className="mr-2 h-5 w-5 text-blue-400"
                                                                    aria-hidden="true"
                                                                />
                                                                ) : (
                                                                <DeleteInactiveIcon
                                                                    className="mr-2 h-5 w-5 text-blue-400"
                                                                    aria-hidden="true"
                                                                />
                                                                )}
                                                                Delete
                                                            </button>
                                                            )}
                                                        </Menu.Item> */}
                                                    </div>
                                                </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                        <div  onClick={() => navigate(`/${selectedOrganization}/assets/selected-asset/${sensor.asset_uuid}`)}>
                                            <OptimizeLargeImage source={sensor.asset_custom_picture_url  ? sensor.asset_custom_picture_url : sensor.machine_picture_url} alt={sensor.machine_name} size='lg' />
                                        </div>
                                        <div className='flex flex-col justify-center pl-2 max-w-[120px]'  onClick={() => navigate(`/${selectedOrganization}/assets/selected-asset/${sensor.asset_uuid}`)}>
                                            <div className='text-left leading-6 truncate text-base text-gray-600 dark:text-white font-medium'>{sensor.asset_custom_name  ? sensor.asset_custom_name :sensor.machine_name}</div>
                                            <div className='text-left leading-6 truncate text-sm text-gray-500 dark:text-gray-400 font-bold'>{sensor.asset_custom_identifier ? sensor.asset_custom_identifier : sensor.asset_uuid}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        
                        </div>
                    ))
                }
            </div>
            
        </div>

        {isAssignAssetToSensorModalOpen && assignedAssetUuid && assignedAssetUuid.length > 0 &&
            <AssignAssetToSensor remoteOpenAssetModal={isAssignAssetToSensorModalOpen} handleCloseAssignAssetToSensor={handleCloseAssignAssetToSensor} assetUuid={assignedAssetUuid} getAssetsNodesGateways={getAssetsNodesGateways}/>
        }
        {isEditAssetModalOpen && 
            <EditAssetModal remoteOpenAssetModal={isEditAssetModalOpen} handleOpenEditAssetModal={handleOpenEditAssetModal} />
        }
        {isDeleteAssetModalOpen && 
            <DeleteAssetModal remoteOpenModal={isDeleteAssetModalOpen} handleOpenDeleteAssetModal={handleOpenDeleteAssetModal} />
        }

    </div>
  )
}

export default DevicesManagementPage


function EditInactiveIcon(props) {
    return (
        <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
            d="M4 13V16H7L16 7L13 4L4 13Z"
            fill="transparent"
            stroke="#94a3b8"
            strokeWidth="2"
        />
        </svg>
    )
}

function EditActiveIcon(props) {
    return (
        <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
            d="M4 13V16H7L16 7L13 4L4 13Z"
            fill="transparent"
            stroke="#a5b4fc"
            strokeWidth="2"
        />
        </svg>
    )
}

function DeleteInactiveIcon(props) {
return (
    <svg
    {...props}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <rect
        x="5"
        y="6"
        width="10"
        height="10"
        fill="transparent"
        stroke="#94a3b8"
        strokeWidth="2"
    />
    <path d="M3 6H17" stroke="#94a3b8" strokeWidth="2" />
    <path d="M8 6V4H12V6" stroke="#94a3b8" strokeWidth="2" />
    </svg>
)
}
  
function DeleteActiveIcon(props) {
return (
    <svg
    {...props}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <rect
        x="5"
        y="6"
        width="10"
        height="10"
        fill="transparent"
        stroke="#a5b4fc"
        strokeWidth="2"
    />
    <path d="M3 6H17" stroke="#a5b4fc" strokeWidth="2" />
    <path d="M8 6V4H12V6" stroke="#a5b4fc" strokeWidth="2" />
    </svg>
)
}





