import React, { useEffect, useState } from 'react'
import ScrapsBarChart from '../../components/charts/ScrapsBarChart';
import OptimizePartImage from '../../components/OptimizePartImage';
import { LightTooltip } from '../../styles/Tooltip';
// import ProgressGaugeLarge from '../../components/charts/ProgressGaugeLarge';
import EditShiftGoalModal from '../../components/modals/partModals/EditShiftGoalModal';
import CountGaugeLarge from '../../components/charts/CountGaugeLarge';

function AssetOperationRow({operation,getOpsList,  selectedAsset, dateCategories}) {
    const [totalPartsNum, setTotalPartsNum] = useState(0)
    const [totalPartsProgress, setTotalPartsProgress] = useState(0)
    const [shiftGoal, setShiftGoal] = useState(0)
    const [hrGoal, setHrGoal] = useState(0)
    const [openEditShiftGoalModal, setOpenEditShiftGoalModal] = useState(false)

   
    

    const addCommasInNumbers = (num) => {
        if(num){
            const out = num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

            // const out = num.toLocaleString();
    
            return out
        }else{
            return '0'
        }
    }

    const calculateBehind = (good_count, goal_count) => {
        if(good_count && goal_count){
            if(good_count < goal_count){
                let behind = Number(goal_count) - Number(good_count)
                const out = behind.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");    
                return (
                    <div 
                        className='flex flex-col w-full border-r-[0.01em] dark:border-slate-600 items-center  font-medium' 
                    >
                        <span className='text-xl leading-4 text-red-600 dark:text-red-400'>{out}</span>
                        <div className='text-base truncate mx-auto text-gray-500 dark:text-gray-400'> Behind</div>
                    </div>
                )
            }else if(good_count > goal_count){
                let ahead = Number(good_count) - Number(goal_count)
                const out = ahead.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");    
                return (
                    <div 
                        className='flex flex-col w-full border-r-[0.01em] dark:border-slate-600 items-center  font-medium' 
                    >
                        <span className='text-xl leading-4 text-green-600 dark:text-green-400'>{out}</span>
                        <div className='text-base truncate mx-auto text-gray-500 dark:text-gray-400'> Ahead</div>
                    </div>
                )
            }else if(good_count == goal_count){
                return (
                    <div 
                        className='flex flex-col w-full border-r-[0.01em] dark:border-slate-600 items-center  font-medium' 
                    >
                        <span className='text-green-600 dark:text-green-400 mt-[-4px]'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                            </svg>
                        </span>
                        <div className='text-base truncate mx-auto text-gray-500 dark:text-gray-400'> Goal Met</div>
                    </div>
                )
            }
        }
    }


    const formatCycleTime = (value) => {
        if(value){
            if(value.includes('.'))
            {
                value = value.split('.')[0] + '.' + value.split('.')[1].slice(0, 2);
            }
            return value
        }else return 0

    }

    

    const handleCloseEditShiftGoalModal = () => {
        setOpenEditShiftGoalModal(false)
    }


  return (
    <div className='mt-1.5 flex flex-col rounded-2xl bg-white dark:bg-slate-900/50 shadow-iotflows-lg overflow-hidden'>
        <div className='flex items-center'>
            <div className='bg-transparent p-1 w-full flex justify-between'>
                <div className='flex items-center w-full '>
                    <div className='ml-4'>
                        {operation && Object.keys(operation).length > 0 &&
                            <OptimizePartImage alt={operation.part_name} source={operation.part_picture_url} size='xl' />
                        }
                    </div>
                    <div className='text-gray-600 dark:text-gray-300 py-2 pr-2 pl-2 w-[150px]'>
                        <div className='flex flex-col'>
                            <p className='text-left leading-5 truncate text-lg text-gray-600 dark:text-gray-300 font-medium'>{operation.operation_name}</p>
                            <p className='text-left leading-5 truncate text-base text-gray-500 dark:text-gray-400 font-medium'>{operation.operation_description}</p>
                            <p className='text-left truncate text-sm text-gray-500 dark:text-gray-400 font-normal'>{operation.part_name}</p>
                        </div>
                    </div>
                </div>
                </div>
                <div className='flex flex-col w-full border-r-[0.01em] dark:border-slate-600 font-medium' >
                    <div className='flex mx-auto'>
                        <span className='text-xl leading-4 mx-auto text-gray-600 dark:text-gray-300 '>{`${addCommasInNumbers(operation.overridden_good_count)}`}</span>
                    </div>
                    <div className='text-base truncate mx-auto text-gray-500 dark:text-gray-400 '>Good</div>
                </div>
                <div className='flex flex-col w-full border-r-[0.01em] dark:border-slate-600 font-medium' >
                    <div className='flex mx-auto'>
                        <span className='text-xl leading-4 text-gray-600 dark:text-gray-300'>{`${addCommasInNumbers(operation.overridden_bad_count)}`}</span>
                    </div>
                    <div className='text-base truncate mx-auto text-gray-500 dark:text-gray-400'>Bad</div>
                </div>
                {calculateBehind(operation.overridden_good_count, operation.operation_count_goal)}
                {/* <div 
                    className='flex flex-col w-full border-r-[0.01em] dark:border-slate-600 items-center  font-medium' 
                >
                    <span className='text-xl leading-4 text-red-500 dark:text-red-400'>{calculateBehind(operation.overridden_good_count, operation.operation_count_goal)}</span>
                    <div className='text-base truncate mx-auto text-gray-500 dark:text-gray-400'> {isAhead(operation.overridden_good_count, operation.operation_count_goal)}</div>
                </div> */}
                <div 
                    // onClick={() => setOpenEditShiftGoalModal(true)} 
                    className='flex flex-col w-full border-r-[0.01em] dark:border-slate-600 items-center  font-medium' 
                >
                    <span className='text-xl leading-4 text-gray-600 dark:text-gray-300'>{operation.operation_count_goal}</span>
                    <div className='text-base truncate mx-auto text-gray-500 dark:text-gray-400'>Calculated Goal</div>
                    {/* <span className='text-xl leading-4 text-blue-500 dark:text-blue-400'>{hrGoal}</span>
                    <div className='text-base truncate mx-auto text-gray-500 dark:text-gray-400'>Hourly Goal</div> */}
                </div>
                <div className='flex flex-col w-full justify-center  font-medium' >
                    <div className='flex mx-auto'>
                        <span className='text-xl leading-4 text-gray-600 dark:text-gray-300'>{formatCycleTime(operation.cycle_time)}</span>
                    </div>
                    <div className='text-base truncate mx-auto text-gray-500 dark:text-gray-400'>Cycle Time</div>
                </div>
        </div>
        <div className='w-full flex'>
            <div className='flex-1'>
                <ScrapsBarChart operation_uuid={operation.operation_uuid} countArray={operation.operations_made} hourlyGoal={operation.operation_hourly_goal} dateCategories={dateCategories} height='150px'/>
            </div>
            <div >
            {/* <ProgressGaugeLarge stringValue={`${totalPartsNum}`} unit={'made'} numericValue={totalPartsProgress} currentCount={totalPartsNum} goalValue={currentProgress} thresholdValue={totalCurrentPartsGoal} shiftGoal={shiftGoal} /> */}
            <CountGaugeLarge operation={operation} unit={'made'}/>
            </div>
        </div>
        {openEditShiftGoalModal &&
            <EditShiftGoalModal btnType={'hideBtn'} remoteOpenModal={openEditShiftGoalModal} operation={operation} assetOp={selectedAsset} goal={operation.asset_operation_hourly_goal_count} refreshList={getOpsList} setHrGoal={setHrGoal} handleCloseEditShiftGoalModal={handleCloseEditShiftGoalModal} />
        }
    </div>
  )
}

export default AssetOperationRow