
import React from 'react';
import Paho from 'paho-mqtt';
import {getData} from '../../ApiCalls/DataApis';

// import Paho from '../mqttLib';
// import Paho from '../libraries/mqtt'

// init();

class MqttService {

  static instance = null;

  static getInstance() {

    if (!MqttService.instance) {

      MqttService.instance = new MqttService();

    }

    return MqttService.instance;

  }
 
  constructor() {

    var date = new Date();

    const clientId = 'console_client_' + date.getTime();    
    
    this.client = new Paho.Client('ws://wss.connections.iotflows.com:443/mqtt', clientId);    
    // this.client = new Paho.Client('ws://wss.connect2.iotflows.com:443/mqtt', clientId);    

    this.client.onMessageArrived = this.onMessageArrived;

    this.callbacks = {};

    this.onSuccessHandler = undefined;

    this.onConnectionLostHandler = undefined;

    this.isConnected = false;

  }

  connectClient = (onSuccessHandler, onConnectionLostHandler) => { 

    this.onSuccessHandler = onSuccessHandler;

    this.onConnectionLostHandler = onConnectionLostHandler;

    this.client.onConnectionLost = () => {

      this.isConnected = false;

      onConnectionLostHandler();

    };
    // console.log('this.client.isConnected', this.client.isConnected())
    if(!this.client.isConnected()){
      console.log('Getting credentials and connecting to MQTT')
      getData('https://api.iotflows.com/v1/users/authorize')
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          let client_credentials = data.data[0];
          localStorage.setItem('client_username', client_credentials.client_username)
          localStorage.setItem('client_password', client_credentials.client_password)
          
          this.client.connect({
            timeout: 10,
      
            onSuccess: () => {
              // console.log('client Onsuccess')
      
              this.isConnected = true;
      
              onSuccessHandler();
      
            },
            
            userName: client_credentials.client_username,
            password: client_credentials.client_password,
            
      
            useSSL: true, 
      
            onFailure: this.onFailure,
      
            reconnect: false,
            // reconnect: true,
      
            keepAliveInterval: 5,
      
            cleanSession: true,
      
          });

        }
      });
    }
    

  };

  // Testing
  connectTest = (onSuccessHandler, onConnectionLostHandler) => {

    this.onSuccessHandler = onSuccessHandler;

    this.onConnectionLostHandler = onConnectionLostHandler;

    this.client.onConnectionLost = () => {

      this.isConnected = false;

      onConnectionLostHandler();

    };
  };


  onFailure = ({ errorMessage }) => {

    console.info(errorMessage);

    this.isConnected = false;


  };

  onMessageArrived = message => {

    const { payloadString, topic } = message;
    // console.log("on Message Arrive" + message);
    // console.log("payloadString" + payloadString);
    // this.callbacks[topic](payloadString);
    var allWildcardedTopics = computeWildcardedTopics(topic, this.callbacks);
    // for(var i = 0; Object.keys(allWildcardedTopics).length; i++){
      this.callbacks[allWildcardedTopics[0]](message);
    //   // this.callbacks[topic](payloadString);

    // }

  };

  publishMessage = (topic, message) => {

    if (!this.isConnected) {

      console.info('not connected');

      return;

    }

    this.client.publish(topic, message);

  };

  subscribe = (topic, callback) => {

    if (!this.isConnected) {

      console.info('not connected');

      return;

    }
    
    this.callbacks[topic] = callback;
    this.client.subscribe(topic);

  };

  unsubscribe = topic => {

    if (!this.isConnected) {

      console.info('not connected');

      return;

    }

    delete this.callbacks[topic];

    this.client.unsubscribe(topic);

  };

  

}


export default MqttService.getInstance();

function computeWildcardedTopics(topicString, callbacks)
{
  // console.log('topicString')
  // console.log(topicString)

	// if the callback of this exact topic exists, then return it
	//if(callbacks[topicString]) return topicString;
    
    // create the wildcard formatted topic
	var allWildcardedTopics = [];
    
    // iterate in the callbacks to find the corresponding callback
    var callbacksLength = Object.keys(callbacks).length;
    for(var i = 0; i < callbacksLength; i++)
    {
    	// split the topic with / 
	    // e.g. A/B/C/D/E => ['A','B','C','D','E']
    	var topicStringSpliced = topicString.split('/');
	    var topicStringSplicedLength = Object.keys(topicStringSpliced).length;

        // get each callback topic
        var eachCallbackTopic = Object.keys(callbacks)[i];

        // split the callback topic with /
        // e.g. A/+/C/D/# => ['A','+','C','D','#']
        var eachCallbackTopicSpliced = eachCallbackTopic.split('/');    
        var eachCallbackTopicSplicedLength = Object.keys(eachCallbackTopicSpliced).length;
		
        // set # to topicStringSpliced based on this callbackTopic 
        var hashIndex = eachCallbackTopicSpliced.indexOf('#');
        if(hashIndex !== -1)
        {
        	topicStringSpliced[hashIndex] = "#";
        
            // cut out the rest of the segments after #
            topicStringSpliced = topicStringSpliced.slice(0, hashIndex + 1);
        }
        
        // set + to topicStringSpliced based on this callbackTopic
        var allPlusIndices = getAllIndices(eachCallbackTopicSpliced, '+');
        for(var x = 0; x < Object.keys(allPlusIndices).length; x++)
        {
        	topicStringSpliced[allPlusIndices[x]] = "+";
        }
        
		// if the callback of this formatet topic exists, then add it to the valid callbacks
        var thisWildcardedTopic = topicStringSpliced.join('/');
		if(callbacks[thisWildcardedTopic])
        {
        	// if not already exists
        	if(allWildcardedTopics.indexOf(thisWildcardedTopic) == -1)
	        {
            	allWildcardedTopics.push(thisWildcardedTopic);
            }
        }              
    }
    allWildcardedTopics.sort()
    // console.log('allWildcardedTopics')
    // console.log(allWildcardedTopics)
    return allWildcardedTopics;
}

function getAllIndices(array, value) 
{
    var Indices = [], i = -1;
    while ((i = array.indexOf(value, i+1)) != -1)
    {
        Indices.push(i);
    }
    return Indices;
}


