import { useEffect, useState } from 'react'
import HighchartsStock from 'highcharts/highstock';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
// import {data} from '../../MyOverviewPage/testData.js'

highchartsMore(Highcharts);

function LineAreaCart({chartData}) {
    const isDark = useRecoilValue(darkModeState);
    const [overallUtilizationOptions, setOverallUtilizationOptions] = useState({})

      useEffect(() => {
        if(chartData){
            // console.log('chartData', chartData)
            setOverallUtilizationOptions({
                credits: {enabled: false},
                chart: {
                    zoomType: 'x',
                    height: '250px',
                    // height: '225px',
                    backgroundColor: 'transparent',
                },
                time: {
                    useUTC: false
                  },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        style: {
                            color: isDark? '#d1d5db': '#4b5563'
                        }
                    }
                },
                yAxis: {
                    lineWidth: 0,
                    minorGridLineWidth: 0,
                    lineColor: 'transparent',
                    // title: 'testsdfv', 
                    // align: 'left',
                    opposite: false,
                    labels: {
                        style: {
                            color: isDark? '#d1d5db':'#4b5563'
                        },
                        formatter: function () {
                            return this.value + '%';
                          }
                    },
                    minorTickLength: 0,
                    tickLength: 0,
                    gridLineWidth: 0,
                    min:0,
                    // max:100
                },
                legend: { enabled: false},
                navigator : { enabled : false},
                scrollbar : { enabled : false},
                // rangeSelector : {
                //     enabled: false,
                //     selected : 5,
                //     inputEnabled: false
                // },
                rangeSelector: {
                    allButtonsEnabled: true,
                    selected: 3,
                    buttons: [{
                        type: 'month',
                        count: 1,
                        text: 'Day',
                        dataGrouping: {
                            forced: true,
                            units: [['day', [1]]]
                        }
                    }, {
                        type: 'month',
                        count: 3,
                        text: 'Week',
                        dataGrouping: {
                            forced: true,
                            units: [['week', [1]]]
                        }
                    }, {
                        type: 'all',
                        text: 'Month',
                        dataGrouping: {
                            forced: true,
                            units: [['month', [1]]]
                        }
                    }, {
                        type: 'all',
                        text: 'All',
                    }],
                    buttonTheme: { // styles for the buttons
                      width: 60,
                      fill: 'none',
                      stroke: 'none',
                      'stroke-width': 0,
                      r: 8,
                      style: {
                        color: '#2563eb',
                        fontWeight: 'bold'
                      },
                      states: {
                        hover: {
                        fill: '#94a3b8',
                        },
                        select: {
                          fill: '#2563eb',
                          style: {
                            color: 'white'
                          }
                        },
                        disabled: { 
                          // fill: '#2563eb',
                          style: {
                            color: '#d1d5db',
                          }
                        }
                      }
                    },
                  inputStyle: {
                    color: '#9ca3af',
                    fontWeight: 'bold'
                  },
                  labelStyle: {
                    color: '#6b7280',
                    fontWeight: 'bold'
                  },
        
                },
                plotOptions: {
                    area: {
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, Highcharts.getOptions().colors[0]],
                                [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                            ]
                        },
                        marker: {
                            radius: 2
                        },
                        lineWidth: 1,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        threshold: null
                    }
                },
                series: [{
                    type: 'area',
                    name: 'Assets OEE',
                    // step: true,
                    data: chartData
                }],
                tooltip: {
                    pointFormat: '{series.name}: {point.y}% '
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        // chartOptions: {
                        //     yAxis: {
                        //         labels: {
                        //             formatter: function () {
                        //                 return this.value.charAt(0);
                        //             }
                        //         }
                        //     }
                        // }
                    }]
                }
            })
        }

      }, [chartData, isDark])


  return (
    <div>
        <HighchartsReact
            highcharts={HighchartsStock}
            constructorType={'stockChart'}
            options={overallUtilizationOptions}
        />
    </div>
  )
}

export default LineAreaCart