import { AnimatePresence, motion } from 'framer-motion'
import KnownNetworkRow from '../KnownNetworkRow'
import { darkModeState } from '../../../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-hot-toast';
import { useEffect, useRef, useState } from 'react';
import MqttService from '../../../../core/services/MqttService';
import { globalSelectedOrganization } from '../../../../atoms/orgStates';
import GeneralFormInput from '../../../forms/GeneralFormInput';
import { getData, postData } from '../../../../ApiCalls/DataApis';
import { apiLink } from '../../../../ApiCalls/ApisVariables';

var temp_wifi_ssid = ''
function SensorNetworks({sensor, getSensorList}) {
    const isDark = useRecoilValue(darkModeState)
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [disableAddingNewConnections, setDisableAddingNewConnections] = useState(false)
    const [connectingPreviousWifi, setConnectingPreviousWifi] = useState(false)
    const [knownWifiNetworks, setKnownWifiNetworks] = useState([])
    const [loadingNewWifi, setLoadingNewWifi] = useState(false)
    const [loadingSetDifferentWifi, setLoadingSetDifferentWifi] = useState(false)
    const [newWifiCredentials, setNewWifiCredentials] = useState({
        wifiSsid: '',
        wifiPassword: '',
      })
    const [connectingWifi, setConnectingWifi] = useState(false)
    const [newWifiSubmitted, setNewWifiSubmitted] = useState(false)
    const [addNewNetwork, setAddNewNetwork] = useState(false)

    const newWifiSubmittedRef = useRef(newWifiSubmitted)
    const connectingPreviousWifiRef = useRef(connectingPreviousWifi)
    const connectingWifiRef = useRef(connectingWifi)

    newWifiSubmittedRef.current = newWifiSubmitted
    connectingWifiRef.current = connectingWifi
    connectingPreviousWifiRef.current = connectingPreviousWifi

    useEffect(() => {
        try{
          if(selectedOrganization){
            refreshWifiConnections()
          }
    
        }catch (e){
          console.log(e.message)
        }
    
      }, [selectedOrganization])

    useEffect(() => {
        try {
            if(connectingPreviousWifi){
              setTimeout(() => {
                if(connectingPreviousWifiRef.current){
                  toast.error('Wi-Fi connection failed, please try again')
                  setConnectingPreviousWifi(false)
                  setDisableAddingNewConnections(false)
                }
              }, 45000)
               
            }
            
        } catch (error) {
            
        }
    
      }, [connectingPreviousWifi])

    useEffect(() => {
        try {
            if(newWifiSubmitted && connectingWifi){
              setTimeout(() => {
                if(newWifiSubmittedRef.current && connectingWifiRef.current ){
                  toast.error('Wi-Fi connection failed, please try again')
                  setConnectingWifi(false)
                  setNewWifiSubmitted(false)
                }
              }, 45000 )
               
            }
            
        } catch (error) {
            
        }
    
    }, [newWifiSubmitted, connectingWifi])

    useEffect(() => {
        try {
            if(sensor && Object.keys(sensor).length > 0 && sensor.node_uuid){
                setTimeout(() => {
                     //  MQTT Subcribe - We need a delay to ensure MQTT is connected before trying to subscribe
                     if(MqttService.isConnected ) {
                      let topic =`v1/organizations/${selectedOrganization}/senseai/nodes/${sensor.node_uuid}/heartbeat`
                      MqttService.subscribe(topic, onMQTTReceived)
                    }
                  }, 3000)
               
            }
            
        } catch (error) {
            
        }
    
    }, [sensor])


    const handleConnectingWifi = (wifiSsid) => {
        temp_wifi_ssid = wifiSsid
        setConnectingPreviousWifi(true)
        setDisableAddingNewConnections(true)
      }

    const onMQTTReceived = message => {
        try{
            var payloadMessage = message.payloadString
            try { payloadMessage = JSON.parse(payloadMessage) } catch(e){}
            
            if(temp_wifi_ssid && payloadMessage && payloadMessage.data && payloadMessage.data.wifi_ssid  ){
              if(temp_wifi_ssid == payloadMessage.data.wifi_ssid){
                temp_wifi_ssid = ''
                setConnectingWifi(false)
                setNewWifiSubmitted(false)
                setAddNewNetwork(false)
                setConnectingPreviousWifi(false)
                setDisableAddingNewConnections(false)
                toast.success('New Wi-Fi connection added')
                setTimeout(() => {
                  refreshWifiConnections()
                  getSensorList()
                }, 1000)
              }else{
                temp_wifi_ssid = ''
                setConnectingWifi(false)
                setNewWifiSubmitted(false)
                setConnectingPreviousWifi(false)
                setDisableAddingNewConnections(false)
                toast.error('Wi-Fi connection failed, please try again')
              }
            }
          }
          catch(e){
            console.log("Error in reading MQTT");
            console.log(e.message);
          }
      }


    const submitNewWifi = () => {
        try {
            if(!loadingNewWifi ){
            setLoadingNewWifi(true)
            postData(apiLink + '/v1/nodes/' + sensor.node_uuid + '/replace-wifi',
            { "wifi_ssid": newWifiCredentials.wifiSsid,
                "wifi_password": newWifiCredentials.wifiPassword})
                .then(async response => {
                const data = await response.json();
                if(response.ok){
                    if(response.status == 200){
                    temp_wifi_ssid = newWifiCredentials.wifiSsid
                    toast.success('Wi-Fi credentials sent to device')
                    setNewWifiSubmitted(true)
                    setConnectingWifi(true)
                    setLoadingNewWifi(false)
                    }
                }
                else{
                    try{
                    setLoadingNewWifi(false)
                    toast.error(data.message)
                    }catch (e){
                    toast.error(e.message)
                    setLoadingNewWifi(false)
                    }
                }
                })

            }
            
        } catch (error) {
            console.log(error.message)
        }

    }

    const refreshWifiConnections = () => {
        if(selectedOrganization){
          getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/wifis')
          .then(async response => {
              // JSON data parsed by response.json() call
              if(response.ok ){
              const data = await response.json();
                if(data && data.data){
                  let networks =data.data
                  networks.unshift({
                    "wifi_uuid": 'iotflows_wifi_uuid',
                    "wifi_ssid": 'IoTFlows',
                    "wifi_password": 'IoTFlowsPassword$'
                })
                  setKnownWifiNetworks(networks)
                }
              }
          })
        }
      }

      const handleGeneralFormChange = (event) => {
        // Get the name of the field that caused this change event
        // Get the new value of this field
        const { name, value } = event.target;
    
        // Assign new value to the appropriate form field
        const updatedForm = {
          ...newWifiCredentials,
          [name]: value
        };
    
        // console.log('Form changed: ', updatedForm);
    
        // Update state
        setNewWifiCredentials(updatedForm);
    
      }


  return (
    <div className=''>
        <AnimatePresence mode='wait'>
            {!addNewNetwork && !newWifiSubmitted &&
                <div className={`flex flex-col mt-2 pt-6 border-t-[0] border-dashed ${isDark? 'border-slate-500' : 'border-slate-500'}`}>
                    {/* <div className={`mb-2 flex `}>
                    <span className={`${isDark? 'text-gray-400' : 'text-gray-500'} text-sm `}>
                        Known Networks
                    </span>
                    </div> */}
                    <div className={`border-[1px] ${isDark? 'border-slate-400' : 'border-slate-500'} rounded-lg `}>
                    {knownWifiNetworks.map((network, index) => (
                    <div key={network.wifi_uuid} className={`${isDark? 'border-slate-400' : 'border-slate-500'} ${Object.keys(knownWifiNetworks).length == index + 1 ? 'border-b-0' : 'border-b-[0.01em]'}`}>
                        <KnownNetworkRow  network={network} sensor={sensor} handleConnectingWifi={handleConnectingWifi} disableAddingNewConnections={disableAddingNewConnections} connectingPreviousWifi={connectingPreviousWifi}/>
                    </div>
                    ))}
                    </div>
                    <div className={`mt-2 flex `}>
                    <button 
                        className={`ml-auto ${isDark? 'text-blue-400' : 'text-blue-600'} text-sm hover:opacity-80 disabled:opacity-60`} 
                        onClick={() => setAddNewNetwork(true)}
                        disabled={disableAddingNewConnections}
                    >
                        Add Network
                    </button>

                    </div>
                </div>
            }
            {addNewNetwork && !newWifiSubmitted && !connectingWifi &&
                <motion.div
                key='addWifi'
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
                >
                <div className='p-4'>
                    <div className='flex justify-center'>
                    <span className={`${isDark? 'text-gray-300' : 'text-gray-500'} text-lg `}>New Wi-Fi credentials</span>
                    </div>
                    <div className='pt-2'>
                        <GeneralFormInput label='Wi-Fi SSID' type='text' placeholder='' name='wifiSsid' onChange={handleGeneralFormChange} />
                        </div>
                        <div className='pt-4'>
                        <GeneralFormInput label='Wi-Fi Password' type='password' placeholder='' name='wifiPassword' onChange={handleGeneralFormChange} />
                        </div>

                </div>
                <div className={`flex pb-2 px-5 items-center mt-4 border-t-[0px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                        <button
                        type="button"
                        className={`inline-flex ml-auto rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                        onClick={() => setAddNewNetwork(false)}
                        >
                        Back
                        </button>
                        <button
                        type="submit"
                        className={`relative ml-2 inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                        onClick={submitNewWifi}
                        disabled={loadingNewWifi || newWifiCredentials.wifiSsid == '' ||newWifiCredentials.wifiPassword == '' }
                        >
                        Connect Wi-Fi
                        {loadingNewWifi && 
                            <div >
                                <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                </svg>
                            </div>
                            }
                        </button>
                    </div>

                </motion.div>
            }
            {addNewNetwork && newWifiSubmitted && connectingWifi &&
                <motion.div
                key='connectingToWifi'
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
                >
                <div className='p-4'>
                    <div className='flex '>
                    <div className={`pr-2 `}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-green-500">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <span className={`${isDark? 'text-gray-300' : 'text-gray-600'} text-base `}>Wi-Fi credentials sent to device </span>
                    </div>
                    <div>
                    <div className='flex flex-col text-center mt-8'>
                        <div >
                        <svg aria-hidden="true" className={`inline w-14 h-14  animate-spin ${isDark? 'text-gray-600 fill-blue-600' : 'text-gray-400 fill-blue-500'} `} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        </div>
                        <div className='mt-4'>
                        <span className={`${isDark? 'text-gray-400' : 'text-gray-600'} text-base animate-pulse `}>Connecting to Wi-Fi</span>
                        </div>
                    </div>
                    </div>

                </div>

                </motion.div>
            }
        </AnimatePresence>
    </div>
  )
}

export default SensorNetworks