
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import HighchartsReact from "highcharts-react-official";
import HighchartsStock from 'highcharts/highstock';
import highchartsMore from "highcharts/highcharts-more.js";
import Highcharts from "highcharts/highcharts.js";
import { darkModeState } from '../../atoms/darkModeState';

highchartsMore(Highcharts);

function RanpakDoubleBarChart({countArray,countArrayFeet, showBoxData, dateCategories, height}) {
    const [chartOptions, setChartOptions] = useState({
        credits: {enabled: false},
        chart: {
            type: 'column',
            zoomType: 'x',
            backgroundColor:'transparent',
            // height:'110px',
        },
        time: {
            useUTC: false
          },
        title:{
            text:''
        },
    })
    const isDark = useRecoilValue(darkModeState); 


    useEffect(() => {
        if(dateCategories && dateCategories.length > 0 && countArray && countArray.length > 0){
            

            let red = 'rgba(220, 38, 38, 0.5)'
            let sky = '#0ea5e9'
            let skyLight = 'rgba(14, 165, 233,0.5)'
            
            if(!isDark){
                red = 'rgba(220, 38, 38, 0.5)' 
                sky = '#60a5fa'
                skyLight = 'rgba(96, 165, 250,0.5)'
            }



            let [boxesFilledSeries, feetSeriesData] = formatData(countArray, countArrayFeet)
            // console.log(`resetting Scrap Bar chart state ${operation_uuid}`)
            
            setChartOptions({
                credits: {enabled: false},
                chart: {
                    type: 'column',
                    zoomType: 'x',
                    backgroundColor:'transparent',
                    // height:'110px',
                },
                time: {
                    useUTC: false
                    },
                title:{
                    text:''
                },
                xAxis: {
                    // categories: ['8 AM', '','','','','','5 PM'],
                    categories: dateCategories,
                    lineWidth: 0, // Remove x-axis line
                    labels: {
                        // enabled:false,
                        style: {
                            color: isDark? '#9ca3af':'#6b7280',
                            // color: isDark? '#d1d5db':'#4b5563',
                            fontSize: 11
                        }
                    },
                },
                // yAxis: [{
                //     visible: false,
                //     // max: 10
                // }],
                yAxis: [{
                    title: {
                        text: null
                    },
                    labels: {
                        enabled: true, // Hide y-axis labels
                        style: {
                            color: isDark? '#9ca3af':'#6b7280', // gray 400/500
                            fontSize: 11
                        }
                    },
                    gridLineWidth: 0, // Hide default grid lines
                    plotLines: [{
                    value: 0,
                    color: isDark? '#475569':'#4b5563',
                    width: 1,
                    zIndex: 4
                    }]
                },{
                    opposite: true,
                    title: {
                        text: null
                    },
                    labels: {
                        enabled: true, // Hide y-axis labels
                        style: {
                            color: isDark? '#9ca3af':'#6b7280', // gray 400/500
                            fontSize: 11
                        }
                    },
                    gridLineWidth: 0, // Hide default grid lines
                    plotLines: [{
                    value: 0,
                    color: isDark? '#475569':'#4b5563',
                    width: 1,
                    zIndex: 4
                    }]
                }],
                legend: {
                    enabled: false 
                },
                tooltip: {
                    formatter: function () {
                        // The first returned item is the header, subsequent items are the
                        // points
                        let icon = ''
                        return ['<b>' + this.x + '</b>'].concat(
                            this.points ?
                                this.points.map(function (point) {
                                    return point.series.name + ': ' + point.y + icon;
                                }) : []
                        );
                    },
                    split: true
                },
                plotOptions: {
                    column: {
                        // stacking: 'normal',
                        backgroundColor:'#F3F4F6',
                        dataLabels: {
                            enabled: true,
                            style: {
                                // fontSize: '18px',
                                color: isDark ? '#fff' :'#fff',
                                // color: isDark ? '#fff' :'rgba(75, 85, 99, 0.5)',
                            },
                        },
                        borderWidth:0
                    },
                    series: {
                        borderRadius: {
                            radius: 5
                        },
                        dataLabels: {
                            enabled: true,
                            // format: toggleShowPercentData ? '{y}%' : '{y}h'
                        },
                        // stacking: 'normal'
                    }
                },
                series: [{
                    name: 'Total Feet',
                    data: feetSeriesData,
                    pointPadding: 0,
                    color: {
                        linearGradient: {
                          x1: 0,
                          x2: 0,
                          y1: 0,
                          y2: 1
                        },
                        stops: [
                          [0, skyLight],
                        //   [1, 'rgba(22, 163, 74,0.5)']
                        // [0, Highcharts.getOptions().colors[0]],
                        // [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                        [1, sky]
                        ]
                      }
                },
                {
                    name: 'Total Boxes',
                    data: boxesFilledSeries,
                    pointPadding: 0,
                    visible: showBoxData,
                    yAxis: 1,
                    // color: {
                    //     linearGradient: {
                    //       x1: 0,
                    //       x2: 0,
                    //       y1: 0,
                    //       y2: 1
                    //     },
                    //     stops: [
                    //       [0, 'rgba(124,63,0, 0.8)'],
                    //     //   [1, 'rgba(22, 163, 74,0.5)']
                    //     // [0, Highcharts.getOptions().colors[0]],
                    //     // [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                    //     [1, 'rgba(124,63,0, 0.8)']
                    //     ]
                    //   }
                },
                ]
            })

        }

    }, [isDark, countArray, dateCategories, countArrayFeet, showBoxData])

    const calculateTotalFt = (totalRuntimeSeconds) => {
        if(totalRuntimeSeconds){
            let totalInches = totalRuntimeSeconds * 55
            let totalFeet = totalInches * 0.0833333
            if(totalFeet){
                totalFeet = totalFeet.toFixed(1)
            }

            return totalFeet

        }
    }

    const formatData = (seriesData, feetData) => {
        let goodSeriesData = []
        let feetSeriesData = []
        if(seriesData && Object.keys(seriesData).length > 0){

            seriesData.map(opsTime => {
                let goodOps = opsTime.overridden_good_count ? opsTime.overridden_good_count : 0
                // let badOps = opsTime.overridden_bad_count ? -opsTime.overridden_bad_count : 0
                
                let sky = '#0ea5e9' //sky500
                let skyLight = 'rgba(14, 165, 233,1)'
                
                let brown = 'rgba(124,63,0,0.5)' 
                let brownLight = 'rgba(124,63,0,0.5)'

                goodSeriesData.push({
                    y: goodOps,
                    id: opsTime.interval_timestamp,
                    color: {
                        linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
                        stops: [
                            [0, brownLight],
                            [1, brown]
                        ]
                      }
                })
                // badSeriesData.push({
                //     y: badOps,
                //     id: opsTime.interval_timestamp
                // })
            })
            if(feetData && Object.keys(feetData).length > 0 ){
                
                feetData.map(opsTime => {
                    let runtime_hr = opsTime.runtime_hr ? opsTime.runtime_hr : 0
    
                    let runtimeFeet = runtime_hr * 16500
                    if(runtimeFeet){
                        runtimeFeet = Number(runtimeFeet.toFixed(1))
                    }
                    // runtimeFeet = runtimeFeet.toFixed(2)
                    
                    let sky = '#0ea5e9' //sky500
                    let skyLight = 'rgba(14, 165, 233,1)'
    
                    feetSeriesData.push({
                        y: runtimeFeet,
                        id: opsTime.interval_timestamp,
                        color: {
                            linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
                            stops: [
                                [0, sky],
                                [1, skyLight]
                            ]
                          }
                    })
                })

            }
            
        }
        
        return [goodSeriesData, feetSeriesData]
    }


  return (
    <div className=''>
        <HighchartsReact
            constructorType={"chart"}
            containerProps={{ style: { height: height, maxWidth:'100%'} }} 
            highcharts={HighchartsStock}
            options={chartOptions}               
        />
    </div>
  )
}

export default RanpakDoubleBarChart