import React, { useCallback, useEffect, useRef, useState } from 'react'
import { apiLink } from '../../ApiCalls/ApisVariables';
import { getData } from '../../ApiCalls/DataApis';
import { useLocation, useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { formatFileSize } from '../../utils/FileFormating';
import { motion } from 'framer-motion';
import { useRecoilValue } from 'recoil';
import { darkModeState } from '../../atoms/darkModeState';
import { formatRelativeTime } from '../../utils/DateFormating';
import FilePageRow from './FilePageRow';

const DEBOUNCE_DELAY = 500; // milliseconds

function FilesPage({participants, chatInfo, userInfo}) {
    const location = useLocation();
    const [isLoadingFiles, setIsLoadingFiles] = useState(false)
    const [files, setFiles] = useState({})
    const [searchParams, setSearchParams] = useSearchParams()

    const [initialLoading, setInitialLoading] = useState(true);
    const [hasMoreFiles, setHasMoreFiles] = useState(false);
    const observer = useRef();
    const loadMoreTimeoutRef = useRef();
    const lastLoadTimeRef = useRef(0);
    


    useEffect(() => {
        let selectedChatId = searchParams.get('chatId')
        if(selectedChatId){
            getData(apiLink + '/v1/chats/' + selectedChatId + '/messages?only_files=true&latest_messages=true')
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data){
                    // console.log('data',data)
                  if(data.data){
                    setFiles(data.data)
                    lastLoadTimeRef.current = Date.now();
                    setHasMoreFiles(data.has_older_messages)
                    setInitialLoading(false)
                  }
                }
              }else{
                setInitialLoading(false)
                setHasMoreFiles(false)
              }
            })
        }
    }, [location])

    const loadMoreFiles = (updatedFiles) => {
        try{
          if(!isLoadingFiles && hasMoreFiles){
            setIsLoadingFiles(true)
            // setisLoadingOlderMessages(true);
  
            let selectedChatId = searchParams.get('chatId')
  
            // Convert the object to an array of entries ([key, value] pairs)
            let messageArray = Object.entries(updatedFiles);
    
            // Get the last entry (assuming there is at least one message)
            let lastIndex = Object.keys(messageArray).length - 1
            let [lastKey, lastImage] = messageArray[lastIndex];
            let dateEnd = new Date(lastImage.message_created_at);
            let dateEndObject  = dateEnd.toISOString()


            getData(apiLink + '/v1/chats/' + selectedChatId + `/messages?only_files=true&messages_before_timestamp=${dateEndObject}`)
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data && data.data){
                    let newMessages = data.data
                    //   console.log('data.data', data.data)
                    
                    setFiles((prevMessages) => {
                        return { ...prevMessages, ...newMessages };
                    });
                    lastLoadTimeRef.current = Date.now();
                    setHasMoreFiles(data.has_older_messages)
                    setIsLoadingFiles(false)
                }else{
                    setIsLoadingFiles(false)
                }
              }
            })
          }
          
  
        }catch(e){
          toast.error(e.message)
          setIsLoadingFiles(false)
        }
      };

      const loadTriggerRef = useCallback(node => {
        if (isLoadingFiles || initialLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting && hasMoreFiles) {
          //   loadMoreFiles(images);
            const now = Date.now();
            if (now - lastLoadTimeRef.current > DEBOUNCE_DELAY) {
                loadMoreFiles(files);
            } else {
                clearTimeout(loadMoreTimeoutRef.current);
                loadMoreTimeoutRef.current = setTimeout(loadMoreFiles(files), DEBOUNCE_DELAY);
            }
          }
        });
        if (node) observer.current.observe(node);
      }, [isLoadingFiles, hasMoreFiles, files, initialLoading]);

    

  return (
    <div>
        {!initialLoading && Object.keys(files).length > 0 ? (
            Object.keys(files).map((file) => (
                <FilePageRow key={file} file={files[file]} />
                
            ))
        ):(
            <div/>
        )}
        {!initialLoading && !isLoadingFiles && Object.keys(files).length === 0 ? (
            <div>
                <div className='mt-2 flex-flex-col rounded-xl bg-white dark:bg-slate-800 shadow-iotflows-lg p-4'>
                    <h6 className='text-gray-600 dark:text-gray-300 text-2lx text-center text-light'>
                        { `(;-;)`}
                    </h6>
                    <h6 className='text-gray-600 dark:text-gray-300 text-center text-light'>
                        No files have been shared in this chat.
                    </h6>
                </div>
            </div>
        ):(
            <div/>
        )}
        {initialLoading || isLoadingFiles ? (
            <div>
                <div className='mt-2 flex justify-center p-4'>
                    <div className="flex items-center justify-center text-gray-600 dark:text-gray-300 text-light">
                        Loading files
                        <span className="dot1 animate-bounce ml-0.5" style={{ animationDelay: '0s' }}>.</span>
                        <span className="dot2 animate-bounce ml-0.5" style={{ animationDelay: '0.2s' }}>.</span>
                        <span className="dot3 animate-bounce ml-0.5" style={{ animationDelay: '0.4s' }}>.</span>
                    </div>
                </div>
            </div>
        ):(
            <div/>
        )}
        {(!initialLoading &&  !isLoadingFiles && hasMoreFiles ) ? (
            <div
                ref={loadTriggerRef}
                style={{ height: '5px', marginTop: '-10px' }}
            />
        ):(
            <div/>
        )}
    </div>
  )
}

export default FilesPage