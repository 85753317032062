import React, { useEffect, useRef, useState } from 'react'
import { deleteData, putData } from '../../../../ApiCalls/DataApis';
import { apiLink } from '../../../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import { motion } from "framer-motion";
import { LightTooltip } from '../../../../styles/Tooltip';

function OperationTimeCellData({opTimeData, isDark, index, dateCategories, operation,  assetId}) {
    const [goodOps, setGoodOps] = useState('')
    const [badOps, setBadOps] = useState('')
    const [loading, setLoading] = useState(false);
    const [loadingClear, setLoadingClear] = useState(false);
    const inputGoodOpRef = useRef(null);
    const inputBadOpRef = useRef(null);
    const detectedRef = useRef(null);
    
    
    useEffect(() => {
        let goodValue = 0
        let badValue = 0

        if(opTimeData.operation_count == null || opTimeData.operation_count == 0 || opTimeData.operation_count == '0' ){
            detectedRef.current.style.color = isDark ? '#4b5563':'#9ca3af'
        }

        if(opTimeData.override_operation_made_good_count != null){
                goodValue = opTimeData.override_operation_made_good_count
                
                setGoodOps(goodValue)
                inputGoodOpRef.current.style.fontWeight = 600
                detectedRef.current.style.color = isDark ? '#4b5563':'#cbd5e1'
                inputGoodOpRef.current.style.color = isDark ? 'rgba(56,189,248, 1)':'#0ea5e9'
                // inputGoodOpRef.current.style.backgroundColor = isDark ? 'rgba(56,189,248, 0.3)':'rgba(56,189,248, 0.8)'
                // inputGoodOpRef.current.style.color = isDark ? '#fff':'#fff'
            

        }else{
            setGoodOps('')
        }

        if(opTimeData.override_operation_made_bad_count  != null){
            badValue = opTimeData.override_operation_made_bad_count
            setBadOps(badValue)
            inputBadOpRef.current.style.fontWeight = 600
            inputBadOpRef.current.style.color = isDark ? 'rgba(251,113,133, 1)':'#f43f5e'
            // inputBadOpRef.current.style.backgroundColor = isDark ? 'rgba(251,113,133, 0.9)':'rgba(251,113,133, 0.8)'
            // inputBadOpRef.current.style.color = isDark ? '#fff':'#fff'
        }else{
            setBadOps('')
        }

        let goodCharacterCount = goodValue ? goodValue.length : 0
        if(goodCharacterCount > 6){
            inputGoodOpRef.current.style.fontSize = '12px'
        }else if(goodCharacterCount <= 6 && goodCharacterCount >= 5){
            inputGoodOpRef.current.style.fontSize = '14px'
        }else if(goodCharacterCount < 5 && goodCharacterCount >= 4){
            inputGoodOpRef.current.style.fontSize = '16px'
        }else if(goodCharacterCount <= 4 && goodCharacterCount > 3){
            inputGoodOpRef.current.style.fontSize = '18px'
        }else{
            inputGoodOpRef.current.style.fontSize = '20px'
        }

        let badCharacterCount = badValue ? badValue.length : 0
        if(badCharacterCount > 6){
            inputBadOpRef.current.style.fontSize = '12px'
        }else if(badCharacterCount <= 6 && badCharacterCount >= 5){
            inputBadOpRef.current.style.fontSize = '14px'
        }else if(badCharacterCount < 5 && badCharacterCount >= 4){
            inputBadOpRef.current.style.fontSize = '16px'
        }else if(badCharacterCount <= 4 && badCharacterCount > 3){
            inputBadOpRef.current.style.fontSize = '18px'
        }else{
            inputBadOpRef.current.style.fontSize = '20px'
        }

        let detectedCount = opTimeData.operation_count ? opTimeData.operation_count.length : 0
        if(detectedCount > 6){
            detectedRef.current.style.fontSize = '12px'
        }else if(detectedCount <= 6 && detectedCount >= 5){
            detectedRef.current.style.fontSize = '14px'
        }else if(detectedCount < 5 && detectedCount >= 4){
            detectedRef.current.style.fontSize = '16px'
        }else if(detectedCount <= 4 && detectedCount > 3){
            detectedRef.current.style.fontSize = '18px'
        }else{
            detectedRef.current.style.fontSize = '20px'
        }

    },[opTimeData])




      const handleGoodOpsChange = (text) => {
          
        setGoodOps(text)
        if(text.length){
            let characterCount = text.length
            if(characterCount > 6){
                inputGoodOpRef.current.style.fontSize = '12px'
            }else if(characterCount <= 6 && characterCount >= 5){
                inputGoodOpRef.current.style.fontSize = '14px'
            }else if(characterCount < 5 && characterCount >= 4){
                inputGoodOpRef.current.style.fontSize = '16px'
            }else if(characterCount <= 4 && characterCount > 3){
                inputGoodOpRef.current.style.fontSize = '18px'
            }else{
                inputGoodOpRef.current.style.fontSize = '20px'
            }
        }

        inputGoodOpRef.current.style.fontWeight = 600
        inputGoodOpRef.current.style.color = isDark ? 'rgba(56,189,248, 1)':'#0ea5e9'
        // inputGoodOpRef.current.style.backgroundColor = isDark ? 'rgba(56,189,248, 0.3)':'rgba(56,189,248, 0.8)'
        // inputGoodOpRef.current.style.color = isDark ? '#fff':'#fff'
        
      }

      const handleBadOpsChange = (text) => {
        setBadOps(text)

        if(text.length){
            let characterCount = text.length
            if(characterCount > 6){
                inputBadOpRef.current.style.fontSize = '12px'
            }else if(characterCount <= 6 && characterCount >= 5){
                inputBadOpRef.current.style.fontSize = '14px'
            }else if(characterCount < 5 && characterCount >= 4){
                inputBadOpRef.current.style.fontSize = '16px'
            }else if(characterCount <= 4 && characterCount > 3){
                inputBadOpRef.current.style.fontSize = '18px'
            }else{
                inputBadOpRef.current.style.fontSize = '20px'
            }
        }

        inputBadOpRef.current.style.fontWeight = 600
        // inputBadOpRef.current.style.backgroundColor = isDark ? 'rgba(251,113,133, 0.9)':'rgba(251,113,133, 0.8)'
        inputBadOpRef.current.style.color = isDark ? 'rgba(251,113,133, 1)':'#f43f5e'
        // inputBadOpRef.current.style.color = isDark ? '#fff':'#fff'
        
      }

      const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleInputsBlur(event)
            event.target.blur();
        }
    }

    const handleInputsBlur = (event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            if(!loading){
                setLoading(true)
                putData(apiLink + '/v1/assets/' + assetId + '/operations/' + operation.operation_uuid + '/override_operation_made' , 
                {"override_operation_made_good_count": goodOps,
                    "override_operation_made_bad_count": badOps,
                    "override_operation_made_timestamp": opTimeData.interval_timestamp
                })
                .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                        setLoading(false)
                        if(data && data.data[0]){
                            if(detectedRef.current){
                                detectedRef.current.style.color = isDark ? '#4b5563':'#cbd5e1'
                            }
                            // console.log('data.data[0]', data.data[0])
                            handleGoodOpsChange(data.data[0].override_operation_made_good_count)
                            handleBadOpsChange(data.data[0].override_operation_made_bad_count)
                        }
                    }
                    else{
                        try{
                            // setGoodOps(operation.operation_description)
                            setLoading(false)
                            toast.error(data.message)
                        }catch (e){
                            // setGoodOps(operation.operation_description)
                            toast.error(e.message)
                            setLoading(false)
                        }
                    }
                })
            }

        }
    }

    const handleClearOverrideInputs = () => {
        if(!loadingClear){
            setLoadingClear(true)
            deleteData(apiLink + '/v1/assets/'+ assetId + '/operations/' + operation.operation_uuid + '/override_operation_made',
            {override_operation_made_timestamp: opTimeData.interval_timestamp})
            .then(async response => {
            const data = await response.json();
                if(response.ok){ 
                setLoadingClear(false)
                detectedRef.current.style.color = isDark ? '#fff':'#4b5563'
                
                setGoodOps('')
                setBadOps('')
                }
                else{
                try{
                    toast.error(data.message)
                    setLoadingClear(false)
                }catch (e){
                    toast.error(e.messsage);
                    setLoadingClear(false)
                }
                }
            })
        }
    }

    

  return (
    <td 
        className={`text-sm ${isDark ? 'text-gray-300':'text-gray-600'}  border-0 dark:border-zinc-600 w-[85px] overflow-hidden`}
    >
        <div className={`text-center my-1 tracking-wide font-light ${isDark ? 'text-gray-400 border-zinc-500':'text-gray-500 '} border-b-[0.01em]`}>
            {dateCategories[index]}
        </div>
        <div ref={detectedRef} className={`${isDark ? 'text-white':'text-gray-600'} text-center text-xl`}>{opTimeData.operation_count ? opTimeData.operation_count : '0'}</div>
        <div className='flex flex-col relative' onBlur={handleInputsBlur}>
            <div className={`overflow-scroll scrollbar-hide mb-1 mx-2`}>
                <input
                    type="number"
                    name='good_operations'
                    ref={inputGoodOpRef}
                    value={goodOps}
                    // onChange={(e)}
                    onChange={(e) => handleGoodOpsChange(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder='0'
                    className={`border-[0.01em] rounded-xl px-0.5 ${isDark ? 'text-gray-600 placeholder:text-gray-600 border-gray-600 focus:border-gray-600':'text-gray-300 placeholder:text-gray-300 border-gray-300 focus:border-gray-300'} py-0.5 font-light text-xl bg-transparent w-full text-center focus:outline-none focus:bg-transparent focus:ring-0 `}
                    />
            </div>
            <div className={`overflow-scroll scrollbar-hide mx-2`}>
                <input
                    type="number"
                    name='bad_operations'
                    ref={inputBadOpRef}
                    value={badOps}
                    // onChange={(e)}
                    onChange={(e) => handleBadOpsChange(e.target.value)}
                    onKeyDown={handleKeyDown}
                    // onKeyDown={handleKeyPressGoodOps}
                    placeholder='0'
                    className={`border-[0.01em] rounded-xl px-0.5 ${isDark ? 'text-gray-600 placeholder:text-gray-600 border-gray-600 focus:border-gray-600':'text-gray-300 placeholder:text-gray-300 border-gray-300 focus:border-gray-300'} py-0.5 font-light text-xl bg-transparent border-0 w-full text-center focus:outline-none focus:bg-transparent focus:ring-0 `}
                    />
            </div>
            {loading && 
                <div >
                    <svg aria-hidden="true" className={`absolute top-1/3 right-1/3 ml-8 w-5 h-15 animate-spin ${isDark ? 'fill-white text-gray-500':'fill-blue-600 text-gray-300'} `} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                    </svg>
                </div>
                }
        </div>
        <div className='relative text-center h-10'>
            {typeof goodOps === 'string' && goodOps === '' && typeof badOps === 'string' && badOps === '' ? (
                    <div />
            ):(
                    <LightTooltip title={
                        <div className='flex flex-col'>
                            <span className='font-semibold'>
                            Clear Override
                            </span>
                            <span>
                                This will revert back the count to the auto-detected value.
                            </span>
                        </div> 
                        }
                    > 
                        <motion.button
                            whileHover={{ scale: 1.05  }} 
                            whileTap={{ scale:0.95 }}  
                            onClick={handleClearOverrideInputs}
                            className={`text-center rounded-full my-2 mx-1.5 py-0.5 px-0.5 font-light ${isDark ? 'text-blue-300 ':'text-blue-500 '} disabled:opacity-0 {goodOps == '' && badOps == ''}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mx-auto">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            {loadingClear && 
                                <div >
                                    <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                    </svg>
                                </div>
                            }
                        </motion.button>
                    </LightTooltip>
            )}
            </div>
    </td>
  )
}

export default OperationTimeCellData