import React, { useEffect, useState } from 'react'


const loadImage = (setImageDimensions, imageUrl) => {
    const img = new Image();
    img.src = imageUrl;
  
    img.onload = () => {
      setImageDimensions({
        height: img.height,
        width: img.width
      });
    };
    img.onerror = (err) => {
      // console.error(err);
      console.log("img error");
    };
  };

function OptimizeLargeImage({alt, source, size, lgBorder}) {
    const [imageDimensions, setImageDimensions] = useState({});
    const [imageWidth, setImageWidth] = useState(false);
    const [imageHeight, setImageHeight] = useState(false);

    useEffect(() => {
        loadImage(setImageDimensions, source);
    }, [])

    useEffect(() => {
        if(imageDimensions.height > imageDimensions.width){
            setImageHeight(true)
            setImageWidth(false)
        }else{
            setImageHeight(false)
            setImageWidth(true)
        }

    }, [imageDimensions])

  return (
    <div  className={`flex justify-center items-center border-white rounded-full ${size == '2xl' && 'h-24 w-24 md:h-40 md:w-40 md:border-[6px]'} ${size == 'xl' && 'border-4  h-24 w-24'} ${size == 'lg' && ' h-14 w-14'} ${lgBorder && 'border-2'}`}>
        {Object.keys(imageDimensions).length > 0 && imageHeight && 
          <img
              src={source}
              alt={alt}
              className={`rounded-full ${size == '2xl' && 'h-24 md:h-40'} ${size == 'xl' && 'h-24'} ${size == 'lg' && 'h-14'}`}
          />
        }
        {Object.keys(imageDimensions).length > 0 && imageWidth && 
            <img
                src={source}
                alt={alt}
                className={`rounded-full ${size == '2xl' && 'w-24 md:w-40'} ${size == 'xl' && 'w-24'} ${size == 'lg' && 'w-14'}`}
                />
        }
    </div>
  )
}

export default OptimizeLargeImage