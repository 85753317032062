import React, { useEffect, useState } from 'react'
import { getData } from '../../ApiCalls/DataApis'
import { apiLink } from '../../ApiCalls/ApisVariables'
import { globalSelectedOrganization } from '../../atoms/orgStates';
import { useRecoilValue } from 'recoil';
import AddMemberModal from '../../components/modals/memberModals/AddMemberModal';
import { darkModeState } from '../../atoms/darkModeState';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SearchIcon } from "@heroicons/react/outline"
import { PencilIcon, TrashIcon } from "@heroicons/react/solid"
import { motion, AnimatePresence } from "framer-motion";
import { MemberTooltip } from '../../styles/Tooltip';
import { Zoom } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AddPersonChatPage from './AddPersonChatPage';
import AddGroupChatPage from './AddGroupChatPage';

const subNav = [
    {
        name:'Add Person Chat',
        url:'addPersonChat'
    },
    {
        name:'Add Group Chat',
        url:'addGroupChat' 
    },
    // {
    //     name:'History',
    //     url:'history'
    // },
    // {
    //     name:'Insights',
    //     url:'insights'
    // },
]

function CreateNewChatSection({getChatList}) {
    const [selectedSubNav, setSelectedSubNav] = useState('addPersonChat')


  return (
    <div className=' pb-24 h-screen scrollbar-hide overflow-y-scroll '>
        {selectedSubNav &&
            subLinkPage(selectedSubNav, setSelectedSubNav, getChatList)
        }
    </div>
  )
}

export default CreateNewChatSection


const subLinkPage = (link, setSelectedSubNav, getChatList) => {
    const allLinks = {
        addPersonChat: { link: "addPersonChat", content: <AddPersonChatPage setSelectedSubNav={setSelectedSubNav} getChatList={getChatList} /> },
        addGroupChat: { link: "addGroupChat", content: <AddGroupChatPage setSelectedSubNav={setSelectedSubNav} getChatList={getChatList} /> },
    };

      return (
        <AnimatePresence mode='wait'>
        <motion.div
        key={allLinks[link].link}
          initial={{ x: 10, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {allLinks[link].content}
        </motion.div>
      </AnimatePresence>
    )  

            
}