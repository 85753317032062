import React, { useEffect, useState } from 'react'
import { apiLink } from '../ApiCalls/ApisVariables';
import { getData } from '../ApiCalls/DataApis';
import { useRecoilValue } from 'recoil';
import { globalSelectedOrganization } from '../atoms/orgStates';
import LogsTables from './LogsTables';
import UserFilterBtn from '../components/filterButtons/UserFilterBtn';
import { useLocation } from 'react-router-dom';
import SearchTextFilter from '../components/filterButtons/SearchTextFilter';
import DateRangeFilter from '../components/filterButtons/DateRangeFilter';

function LogsPage() {
    const location = useLocation()
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [logsList, setLogsList] = useState([])
    const [loadingTable, setLoadingTable] = useState(false)


    useEffect(() => {
        if(selectedOrganization){
          let currentSearch = location.search
            try{
              if(!loadingTable){
                setLoadingTable(true)
                getData(apiLink + '/v1/organizations/' + selectedOrganization + '/logs' + currentSearch)
                .then(async response => {
                  if(response && response.ok ){
                    const data = await response.json();
                    if(data && data.data){
                      setLogsList(data.data)
                      setLoadingTable(false)
                    } 
                  }
                });
              }
            }catch (e){
              setLoadingTable(false)
              console.log(e.message)
            }
        }
  
      }, [selectedOrganization, location])


  return (
    <div className='px-4 sm:px-1 sm:pl-6 sm:pr-3 py-1 min-h-[calc(100vh-10rem)] max-h-[calc(100vh-5rem)] overflow-scroll scrollbar-hide'>
      {/* Filters */}
      <div className='my-1 flex justify-between'>
          <UserFilterBtn />
          <DateRangeFilter />
      </div>
      <div className='w-full'>
            <SearchTextFilter />
      </div>
        <div className='w-full mt-1 p-4 bg-slate-50 dark:bg-slate-800 rounded-xl mb-4 overflow-scroll '>
            <LogsTables data={logsList} rowsPerPage={25} loadingTable={loadingTable} />
        </div>
    </div>
  )
}

export default LogsPage