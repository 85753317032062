import React, { useEffect, useState } from 'react'
import InteractionsComponent from '../utils/InteractionsComponent'
import { formatDateToAmPm } from '../../../utils/DateFormating'
import DOMPurify from 'dompurify';
import LinkifyIt from 'linkify-it';
import tlds from 'tlds';
import { createHyperlinks } from '../utils/HyperlinkUtils';
import { shouldAddPin } from '../../../utils/messagingUtils';


const linkify = new LinkifyIt();
linkify.tlds(tlds);


function SenderMessageOnlyComponent({isMobile, message, scrollToMessage, isGroup, showImage, handleTailCss, participants, userInfo, updateEmoji, shouldRoundTopCorner,shouldRoundBottomCorner}) {
    const [urlPreview, setUrlPreview] = useState(null);
    let cleanHTML = DOMPurify.sanitize(message.message_text_html || message.message_text); // Sanitize the HTML content

    const links = linkify.match(cleanHTML);
    if(links && links.length > 0){
        cleanHTML = createHyperlinks(message.message_text_html);
    }
      


    
    const cornersCSS = (topCorner, bottomCorner) => {
        return {
            borderTopLeftRadius: '1rem',
            borderBottomLeftRadius: '1rem',
            borderTopRightRadius: topCorner? '0.25rem': '1rem',
            borderBottomRightRadius: bottomCorner ? '0.25rem':'1rem',
        }
    }

  return (
    <>
        <div 
            className={` pl-4 pr-4 py-1.5 ${isMobile ? 'max-w-[300px]':'max-w-[500px]'}  flex flex-wrap items-center  mr-2 order-1 bg-sky-600 dark:bg-sky-800 text-white`}
            style={cornersCSS(shouldRoundTopCorner,shouldRoundBottomCorner)}
        >
        {message.message_replied_from && Object.keys(message.message_replied_from).length > 0 && message.message_replied_from.message_uuid &&
            <div className='w-full rounded-xl flex my-1 cursor-pointer text-sm ' onClick={() => scrollToMessage(message.message_replied_from.message_uuid)}>
                <div className={`bg-white dark:bg-slate-200 w-[3px] min-w-[3px] rounded-l-full`} />
                <div className={`bg-white/20 dark:bg-slate-200/20 rounded-r-lg pl-2 py-0.5 pr-2 flex flex-col truncate grow`}>
                    <div className={`text-white dark:text-slate-100`}>{`${message.message_replied_from.user_first_name? message.message_replied_from.user_first_name : ''} ${message.message_replied_from.user_last_name ? message.message_replied_from.user_last_name : ''}`}</div>
                    <div className=" text-gray-200 dark:text-gray-200 truncate ">
                        {message.message_replied_from.message_text}
                    </div>
                    {/* <div
                        className="text-gray-200 dark:text-gray-200 h-5"
                        dangerouslySetInnerHTML={{ __html: cleanReplyHTML }} // Set the HTML content directly
                    /> */}
                </div>
            </div>
        }
        {message.emoji_interactions && Object.keys(message.emoji_interactions).length > 0 ? (
                    <div className='flex flex-col w-full'>
                        <div className='text-sm overflow-x-scroll'>
                            {/* {links && links.length > 0 && (
                                <div>URLS</div>
                                // <LinkPreview url={ensureHttps(links[0].url)} width="300px" />
                            )} */}
                            <div
                            className={`message-content ${isMobile ? ' message-content-mobile ':' message-content-web '}`}
                             dangerouslySetInnerHTML={{ __html: cleanHTML }} // Set the HTML content directly
                            />
                            {/* <DisplayText text={message.message_text} />  */}
                        </div> 
                        <div className='flex items-end'>
                            <InteractionsComponent message={message} userInfo={userInfo} updateEmoji={updateEmoji} participants={participants} />
                            <span className={`${message.user_username === userInfo.user_username ?  'text-gray-300':'text-gray-500 dark:text-gray-400'} flex text-xs ml-auto mt-auto mb-[-5px] min-w-[60px] italic pl-3 py-0.5`}>
                               {shouldAddPin(message, userInfo)}{message.message_is_edited && 'edited  '} {formatDateToAmPm(message.message_created_at)}
                            </span>

                        </div>
                    </div>
        ):(
            <div className='flex flex-wrap w-full'>
                    <div className='text-sm overflow-x-scroll'>
                        {/* {links && links.length > 0 && (
                            
                            <img src={`https://www.google.com/s2/favicons?domain=${domain}`} alt={domain} />

                        )} */}
                        {urlPreview && (
                            <div>
                            <img src={urlPreview.image} alt={urlPreview.title} />
                            <p>{urlPreview.title}</p>
                            <p>{urlPreview.description}</p>
                            </div>
                        )}
                      <div
                        className={`message-content ${isMobile ? ' message-content-mobile ':' message-content-web '}`}
                        dangerouslySetInnerHTML={{ __html: cleanHTML }} // Set the HTML content directly
                        />
                    </div> 
                <div className={`flex ml-auto mt-auto text-right mb-[-5px]  pl-3 py-0.5`}>
                <div className='text-gray-300 text-xs italic flex'>
                {shouldAddPin(message, userInfo)} {message.message_is_edited && 'edited  '}{formatDateToAmPm(message.message_created_at)}
                </div>
                {/* <div className='ml-0.5' style={{ position: 'relative', width: '16px', height: '16px' }}>
                    <svg style={{ position: 'absolute', left: 0, top: 0 }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <path d="M2.5 8.5l3 3L13 4" fill="none" stroke="currentColor" stroke-width="1.5"/>
                    </svg>
                    <svg style={{ position: 'absolute', left: '4px', top: 0 }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <path d="M2.5 8.5l3 3L13 4" fill="none" stroke="currentColor" stroke-width="1.5"/>
                    </svg>
                </div> */}

                {/* Sent SVG && Delivered SVG */}
                {/* <div className='ml-0.5 text-gray-300 flex'>
                    <svg viewBox="0 0 12 11" height="11" width="16" fill="none">
                        <path d="M11.1549 0.652832C11.0745 0.585124 10.9729 0.55127 10.8502 0.55127C10.7021 0.55127 10.5751 0.610514 10.4693 0.729004L4.28038 8.36523L1.87461 6.09277C1.8323 6.04622 1.78151 6.01025 1.72227 5.98486C1.66303 5.95947 1.60166 5.94678 1.53819 5.94678C1.407 5.94678 1.29275 5.99544 1.19541 6.09277L0.884379 6.40381C0.79128 6.49268 0.744731 6.60482 0.744731 6.74023C0.744731 6.87565 0.79128 6.98991 0.884379 7.08301L3.88047 10.0791C4.02859 10.2145 4.19574 10.2822 4.38194 10.2822C4.48773 10.2822 4.58929 10.259 4.68663 10.2124C4.78396 10.1659 4.86436 10.1003 4.92784 10.0156L11.5738 1.59863C11.6458 1.5013 11.6817 1.40186 11.6817 1.30029C11.6817 1.14372 11.6183 1.01888 11.4913 0.925781L11.1549 0.652832Z" fill="currentcolor"></path>
                    </svg>
                    
                    <svg viewBox="0 0 16 11" height="11" width="16"  fill="none">
                        <path d="M11.0714 0.652832C10.991 0.585124 10.8894 0.55127 10.7667 0.55127C10.6186 0.55127 10.4916 0.610514 10.3858 0.729004L4.19688 8.36523L1.79112 6.09277C1.7488 6.04622 1.69802 6.01025 1.63877 5.98486C1.57953 5.95947 1.51817 5.94678 1.45469 5.94678C1.32351 5.94678 1.20925 5.99544 1.11192 6.09277L0.800883 6.40381C0.707784 6.49268 0.661235 6.60482 0.661235 6.74023C0.661235 6.87565 0.707784 6.98991 0.800883 7.08301L3.79698 10.0791C3.94509 10.2145 4.11224 10.2822 4.29844 10.2822C4.40424 10.2822 4.5058 10.259 4.60313 10.2124C4.70046 10.1659 4.78086 10.1003 4.84434 10.0156L11.4903 1.59863C11.5623 1.5013 11.5982 1.40186 11.5982 1.30029C11.5982 1.14372 11.5348 1.01888 11.4078 0.925781L11.0714 0.652832ZM8.6212 8.32715C8.43077 8.20866 8.2488 8.09017 8.0753 7.97168C7.99489 7.89128 7.8891 7.85107 7.75791 7.85107C7.6098 7.85107 7.4892 7.90397 7.3961 8.00977L7.10411 8.33984C7.01947 8.43717 6.97715 8.54508 6.97715 8.66357C6.97715 8.79476 7.0237 8.90902 7.1168 9.00635L8.1959 10.0791C8.33132 10.2145 8.49636 10.2822 8.69102 10.2822C8.79681 10.2822 8.89838 10.259 8.99571 10.2124C9.09304 10.1659 9.17556 10.1003 9.24327 10.0156L15.8639 1.62402C15.9358 1.53939 15.9718 1.43994 15.9718 1.32568C15.9718 1.1818 15.9125 1.05697 15.794 0.951172L15.4386 0.678223C15.3582 0.610514 15.2587 0.57666 15.1402 0.57666C14.9964 0.57666 14.8715 0.635905 14.7657 0.754395L8.6212 8.32715Z" fill="currentColor"></path>
                    </svg>
                </div> */}

                </div>
            </div>
        )}
        </div>
         {/*THIS WILL SHOW THE SENDERS IMAGE ON THE LEFT SIDE BUT I DONT THINK ITS NEEDED */}   
         {/* <div className={`${isGroup ? 'w-8 h-8': 'hidden'} order-2`}>
            {showImage &&
                <Avatar
                    sx={{ 
                        bgcolor: handleUserBGColor(message.user_username), 
                        width: 32, 
                        height: 32 }}
                    alt={participants[message.user_username].user_first_name? participants[message.user_username].user_first_name : ''}
                    src={participants[message.user_username].user_image_url}
                />
            }
        </div> */}
        {/*THIS tail is for when the is an image showing and we need to move the tail to the left of the screen*/}   
            {/* {showImage && isGroup &&
            <div 
                className={`absolute bottom-[-13px] right-[20px] rotate-6 text-sky-600 dark:text-sky-800`}
                style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
            >
                <svg width="30" height="30" viewBox="0 0 20 10">
                    <path d={`${message.user_username === userInfo.user_username ? 'M 0 0 L 10 5 L 0 5 Z':'M 10 5 L 10 -5 L 0 -10 Z'}`} fill="currentColor" />
                </svg>
            </div>
            } */}
        {/*THIS TAIL IS FOR WHEN THERE IS NO IMAGE AND WE NEED THE TAIL CLOSER TO THE RIGHT OF THE SCREEN*/}
        {showImage && 
        <div 
            className={`absolute bottom-[-13px] right-[-16px] rotate-6 text-sky-600 dark:text-sky-800`}
            style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
            >
            <svg width="30" height="30" viewBox="0 0 20 10">
                <path d={`M 0 0 L 10 5 L 0 5 Z`} fill="currentColor" />
            </svg>
        </div>
        }
    </>
  )
}

export default SenderMessageOnlyComponent


