import { useEffect, useState } from "react";
import { DateRangePicker, defaultStaticRanges, defaultInputRanges } from "react-date-range";
import { motion, AnimatePresence } from "framer-motion";
import {
    addDays,
    endOfDay,
    startOfDay,
    startOfYear,
    startOfMonth,
    endOfMonth,
    endOfYear,
    addMonths,
    addYears,
    startOfWeek,
    endOfWeek,
    isSameDay,
    differenceInCalendarDays
  } from "date-fns";
import { useLocation, useSearchParams } from "react-router-dom";


const now = new Date();
const maxCalendarDate = new Date(now.getTime()+ 1 * 24 * 60 * 60 * 1000)

function DateRangeFilter() {
    const [showCalendar, setShowCalendar] = useState(false)
    const [startDate, setStartDate] = useState(new Date(now.getTime() - 6 * 24 * 60 * 60 * 1000))
    const [endDate, setEndDate] = useState(new Date())
    const [startTempDate, setStartTempDate] = useState(new Date(now.getTime() - 6 * 24 * 60 * 60 * 1000))
    const [endTempDate, setEndTempDate] = useState(new Date())
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation();

    useEffect(() => {
        // Gettting search parameters from URL
            let currentSearch = location.search
            if(currentSearch){
                
                let fromDate = searchParams.get('from')
                if(startDate != fromDate){
                    setStartDate(fromDate)
                }

                let toDate = searchParams.get('to')
                if(endDate != toDate){
                    setEndDate(toDate)
                }
            }

        }, [location])

    useEffect(() => {
        // Gettting search parameters from URL
            if(startDate === null || endDate === null){
                setStartDate(new Date(now.getTime() - 6 * 24 * 60 * 60 * 1000))
                setEndDate(new Date())
            }

        }, [startDate,endDate])

    
    const selectionRange = {
        startDate: startTempDate,
        endDate: endTempDate,
        key:'selectionRange',
        background:['#f43f5e']
        
    }


    const handleOpenCalendar = () => {
        setShowCalendar(!showCalendar)
    }


    const closeCalendar = () => {
        setShowCalendar(false)
    }

    const handleSelect = (ranges) => {
        setStartTempDate(ranges.selectionRange.startDate)
        setEndTempDate(ranges.selectionRange.endDate)
    }

    const handleApplyDates = () => {
        setShowCalendar(false)
        setStartDate(startTempDate)
        setEndDate(endTempDate)
        
        let fromParameter = startTempDate.toISOString()
        let toParameter = endTempDate.toISOString()
    
        // tempMembers.map(member => {
        //     parameters.push(member.user_username)
        // })
        let select = searchParams.getAll('select')
        let departments = searchParams.getAll('departments')
        let parts = searchParams.getAll('parts')
        let machine_types = searchParams.getAll('machine_types')
        let tags = searchParams.getAll('tags')
        let group_by = searchParams.getAll('group_by')
        let sort_by = searchParams.getAll('sort_by')
        let calculate_oee_by = searchParams.getAll('calculate_oee_by')
        let shift_name = searchParams.getAll('shift_name')
        let shift_start_time = searchParams.getAll('shift_start_time')
        let shift_date = searchParams.getAll('shift_date')
        let shift_entire_day = searchParams.getAll('shift_entire_day')
        let shift_by_min = searchParams.getAll('shift_by_min')
        let maximize = searchParams.getAll('maximize')
        let view = searchParams.getAll('view')
        let url_token = searchParams.getAll('url_token')
        let operations_expanded = searchParams.getAll('operations_expanded')
        let assets_expanded = searchParams.getAll('assets_expanded')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')
        let users = searchParams.getAll('users')
        let text = searchParams.getAll('text')
        let downtime_severity_uuids = searchParams.getAll('downtime_severity_uuids')
        let only_with_notes = searchParams.getAll('only_with_notes')
        let group_by_advanced = searchParams.getAll('group_by_advanced')
        let machines = searchParams.getAll('machines')
        let sort_by_advanced = searchParams.getAll('sort_by_advanced')
        let time_period = searchParams.getAll('time_period')
        let show_percentage = searchParams.getAll('show_percentage')

        setSearchParams({select, departments, parts, machine_types, tags, group_by, sort_by, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, shift_by_min, maximize, view, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled, users, text,from: fromParameter, to:toParameter, downtime_severity_uuids, only_with_notes, group_by_advanced, machines, sort_by_advanced, time_period, show_percentage})
        
    }

    const formatDate = (date) => {
        if(date){
            var currentDate = new Date(date)
            var shortCurrentDate = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString()
            return shortCurrentDate;

        }
    }

  return (
    <div className='relative max-w-[300px]'>
        <motion.button  whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}  
            className={`w-full px-2 py-1 text-sm font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 bg-clip-padding bg-no-repeat border-[1px] border-slate-50 dark:border-slate-400 rounded shadow-iotflows-lg`} 
            onClick={handleOpenCalendar}
        >
            <span className='text-sm pl-2'>{formatDate(startDate)} {`-`} {formatDate(endDate)}</span>
        </motion.button>
        {showCalendar && (
            <div className={`absolute z-20 right-0 top-10 lg:right-0 flex flex-col`}>
                <div className='flex justify-end bg-slate-700 p-1 rounded-t-lg'>
                    <button 
                        onClick={closeCalendar} 
                        className='rounded-full hover:bg-slate-800 p-1'>
                        <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-300 ">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <DateRangePicker
                    ranges={[selectionRange]}
                    maxDate={maxCalendarDate}
                    rangeColors={['#3b82f6']}
                    onChange={handleSelect}
                    inputRanges={[{
                        ...defaultInputRanges[0],
                        }]}
                    staticRanges={[
                        // ...defaultStaticRanges,
                        {
                            ...defaultStaticRanges[2]
                        },
                        {
                            ...defaultStaticRanges[3]
                        },
                        {
                            ...defaultStaticRanges[4]
                        },
                        {
                            ...defaultStaticRanges[5]
                        },
                        {
                            label: "Last Year",
                            range: () => ({
                            startDate: startOfYear(addYears(new Date(), -1)),
                            endDate: endOfYear(addYears(new Date(), -1))
                            }),
                            isSelected(range) {
                            const definedRange = this.range();
                            return (
                                isSameDay(range.startDate, definedRange.startDate) &&
                                isSameDay(range.endDate, definedRange.endDate)
                            );
                            }
                        },
                        {
                            label: "This Year",
                            range: () => ({
                            startDate: startOfYear(new Date()),
                            endDate: endOfDay(new Date())
                            }),
                            isSelected(range) {
                            const definedRange = this.range();
                            return (
                                isSameDay(range.startDate, definedRange.startDate) &&
                                isSameDay(range.endDate, definedRange.endDate)
                            );
                            }
                        }
                        ]}
                />
                <div>
                <button  
                    onClick={handleApplyDates} 
                    className='w-full text-base p-2 bg-slate-700 text-white font-medium rounded-b-lg hover:bg-slate-900' >
                        Apply
                </button>
                </div>
            </div>
        )}
    </div>
  )
}

export default DateRangeFilter