import SubNav from "../components/SubNav"
import AssetsOverviewPage from "./AssetsOverviewPage";
import AssetsPerformancePage from "./AssetsPerformancePage";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {globalSelectedOrganization} from '../atoms/orgStates'
import AssetReportsPage from "../MyReportsPage/AssetReportsPage";
import DowntimeClassificationsPage from "./DowntimesPage/DowntimeClassificationsPage";
import AdvanceReportPage from "./MyAdvancedReportPage/AdvanceReportPage";
import AdvanceUsageReportPageRanpak from "./MyAdvancedReportPage/AdvanceUsageReportPageRanpak";
import CanvasPage from "../MyCanvasPages/CanvasPage";

const today = new Date();

const mondaySixWeeksAgo = new Date(today.getTime() - 6 * 7 * 24 * 60 * 60 * 1000);
// Force startDate to Monday if not already
if (mondaySixWeeksAgo.getDay() !== 1) {
    mondaySixWeeksAgo.setDate(mondaySixWeeksAgo.getDate() - (mondaySixWeeksAgo.getDay() % 7) + 1);
}
mondaySixWeeksAgo.setHours(0, 0, 0, 0)

let lastSunday = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000)
// Force endDate to Sunday if not already
if (lastSunday.getDay() !== 0) { 
    lastSunday.setDate(lastSunday.getDate() + (7 - lastSunday.getDay()));
}
lastSunday.setHours(23, 59, 59, 999)



function AssetsPage({user, subNav}) {
    const [selectedSubLink, setSelectedSubLink] = useState(null)
    let location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedOrganization = useRecoilValue(globalSelectedOrganization); 
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
      let currentIsMobile = searchParams.get('platform')
      if(currentIsMobile === 'mobile'){
        setIsMobile(true)
      }else{
        setIsMobile(false)
      }
    },[location])
    


    useEffect(() => {
        if(selectedOrganization) {
            setSelectedSubLink(subNav['overview'])
        }

    }, [selectedOrganization])

    useEffect(() => {
        // let currentPath = location.pathname.split('/')
        let currentSearch = location.search
        if(currentSearch &&  selectedOrganization && subNav){
            // Pulling to see how cards should be grouped
            let selected = searchParams.get('select')
            
            if(selected){
                setSelectedSubLink(subNav[selected])
            }
        }

    }, [location, selectedOrganization])

    

    return (
        <div className={`px-1 sm:pl-6 sm:pr-3 py-1 ${isMobile ? 'max-h-[calc(100vh-3rem)]' : 'max-h-[calc(100vh-5rem)]'} overflow-scroll scrollbar-hide`}> 
            {/* nagivations links within assets page */}
            {selectedOrganization && selectedSubLink && subNav && <SubNav subNav={subNav} selectedSubLink={selectedSubLink} />}

            {selectedOrganization && selectedSubLink && subNav && subLinkPage(selectedSubLink, user) }
            
        </div>
    )
}

export default AssetsPage

const subLinkPage = (link, user) => {
    let linkUrl = link.url
    const allLinks = {
        overview: { link: "overview", content: <AssetsOverviewPage user={user}  /> },
        downtimes: { link: "downtimes", content:<DowntimeClassificationsPage /> },
        advanced_report: { link: "advanced_report", content: <AdvanceReportPage /> },
        canvas: { link: "canvas", content: <CanvasPage  /> },
        usage_report: { link: "usage_report", content: <AdvanceUsageReportPageRanpak /> },
        // weekly_reports: { link: "weekly_reports", content: <AssetReportsPage /> },
        // performance: { link: "performance", content:<AssetsPerformancePage   />  },
        // gateways: { link: "gateways", content: <div className='text-[128px]'>🧀</div>  },
        // datastreams: { link: "datastreams", content: <div className='text-[128px]'>🧀</div> },
    };

    

      return ( 
        <AnimatePresence mode='wait'>
        <motion.div key={allLinks[linkUrl].link}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
            {allLinks[linkUrl].content} 
        </motion.div>
      </AnimatePresence>
    )  
            
}

