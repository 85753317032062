import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { PlusSmIcon } from "@heroicons/react/solid"
import { useRecoilState, useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import HaasLogo from '../../../images/Testing/HaasLogo.png';
import MazakLogo from '../../../images/Testing/MazakLogo.png';
import KukaLogo from '../../../images/Testing/KukaLogo.png';
import { SearchIcon } from '@heroicons/react/outline';
import { motion, AnimatePresence } from "framer-motion";
import TagInput from '../../forms/TagInput';
import DepartmentInput from '../../forms/DepartmentInput';
import GeneralFormInput from '../../forms/GeneralFormInput';
import DropdownSelectFormMachineType from '../../forms/DropdownSelectFormMachineType';
import SenseAI from '../../../images/SenseAI.svg'
import {addSensorModalState, addAssetModalState} from '../../../atoms/modalStates'
import { apiLink } from '../../../ApiCalls/ApisVariables';
import { getData, postData } from '../../../ApiCalls/DataApis';
import toast from 'react-hot-toast';
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import OptimizeImage from '../../OptimizeImage';
import ImageZoomCrop from '../ImageZoomCrop';


const manufacturerList = [
  {
      manufacturer_uuid: '1',
      manufacturer_name: 'HASS',
      manufacturer_logo_url: HaasLogo,
  }, {
      manufacturer_uuid: '2',
      manufacturer_name: 'Mazak',
      manufacturer_logo_url: MazakLogo,
  },{
      manufacturer_uuid: '3',
      manufacturer_name: 'Kuka',
      manufacturer_logo_url: KukaLogo,
  },
]

function AddAssetModal({btnType, remoteOpenAssetModal, getOrgAssets, isUserAuthorized}) {
    const [showGODview, setShowGODview] = useState(false)
    let [isOpen, setIsOpen] = useState(false)
    const isDark = useRecoilValue(darkModeState);
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    // const [isSensorModalOpen, setIsSensorModalOpen] = useState(false)
    const [showSensorPage, setShowSensorPage] = useState(true)
    const [showSensorList, setShowSensorList] = useState(false)
    const [sensorSearchText, setSensorSearchText] = useState('')
    const [machineSearchText, setMachineSearchText] = useState('')
    const [selectedSensor, setSelectedSensor] = useState([])
    const [selectedMachine, setSelectedMachine] = useState([])
    const [showMachineList, setShowMachineList] = useState(false)
    const [addNewMachine, setAddNewMachine] = useState(false)
    const [manufacturerSearchText, setManufacturerSearchText] = useState('')
    const [selectedManufacturer, setSelectedManufacturer] = useState([])
    const [addNewManufacturer, setAddNewManufacturer] = useState(false)
    const [showManufacturerList, setShowManufacturerList] = useState(false)
    const [selectedDepartment, setSelectedDepartment] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [machineForm, setMachineForm] = useState({
      machineCustomName: '',
      machineCustomIdentifier: '',
    });
    const [form, setForm] = useState({
      manufacturerName: '',
      manufacturerDescription: '',
      manufacturerUrl: '',
      machineName: '',
      machineDescription: '',
      machineIdentifier: '',
    });
    const [selectedMachineType, setSelectedMachineType] = useState([])
    const [isSensorModalOpen, setIsSensorModalOpen] = useRecoilState(addSensorModalState)
    const [isAssetModalOpen, setIsAssetModalOpen] = useRecoilState(addAssetModalState)
    const [machineList, setMachineList] = useState([])
    const [filteredMachineList, setFilteredMachineList] = useState([])
    const [loading, setLoading] = useState(false)
    const [sensorList, setSensorList] = useState([])
    const [allMachineTypes, setAllMachineTypes] = useState([])
    const [machineImage, setMachineImage] = useState(null)

    useEffect(() => {
      if(remoteOpenAssetModal){
        setIsOpen(true)
      }
    },[remoteOpenAssetModal])

    useEffect(() => {
      // Determining if user had GOD view access
      getData(apiLink + '/v1/users/iotflows')
      .then(async response => {
        if(response && response.ok ){
          const data = await response.json();
          if(data && data.data.length > 0 && data.data[0].iotflows_member_role_id){
            setShowGODview(true)
          } 
        }
      });

    }, [])

    useEffect(() =>{
        try {
            if(selectedOrganization){
                getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/machine_types' )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            setAllMachineTypes(data.data)
                        }
                    }
                })
            }
            
        } catch (error) {
        console.log(error.message)
        }
    }, [selectedOrganization])


    useEffect(() => {
      try {
        if( selectedOrganization){
          // Get list of machines
          getData(apiLink + '/v1/machines')
          .then(async response => {
            if(response.ok ){
              const data = await response.json();
              if(data.data){
                // console.log('machine list',data.data)
                setMachineList(data.data)
              }
            }
          });
  
          // Get list of gateways (not sensors)
          getData(apiLink + '/v1/organizations/' + selectedOrganization + '/nodes/senseai')
          .then(async response => {
            if(response.ok ){
              const data = await response.json();
              if(data && data.data){
                // console.log('sensor list', data.data)
                setSensorList(data.data)
              }
            }
          });

        }
      } catch (error) {
        console.log(error.message)
      }
    },[remoteOpenAssetModal, selectedOrganization])
    

  function closeModal() {
    setLoading(false)
    setSelectedMachine([])
    setSelectedSensor([])
    setSelectedDepartment([])
    setSelectedTags([])
    setMachineForm({
      machineCustomIdentifier: '',
      machineCustomName:''
    })
    setIsOpen(false)
    setIsAssetModalOpen(false)
    setMachineImage(null)
  }

  function openModal() {
    setIsOpen(true)
  }

  const handleShowMachineList = () => {
    if(machineSearchText){
        setShowMachineList(true)
    }else{
        setShowMachineList(!showMachineList)
    }
  }
  const handleShowSensorList = () => {
    if(sensorSearchText){
      setShowSensorList(true)
    }else{
      setShowSensorList(!showSensorList)
    }
  }

  const handleSelectSensor = (sensor) => {
    if(sensor === 'newSensor'){
        setIsOpen(false)
        setIsSensorModalOpen(true)
    }else{
        setSelectedSensor(sensor)
        setShowSensorPage(false)
    }
  }

  const handleSelectMachine = (machine) => {
    if(machine === 'NewMachine'){
        // setAddNewMachine(true)
    }else{
        setSelectedMachine(machine)
    }
  }

  const handleBackFromAddMachine = () => {
    setAddNewMachine(false)
    setSelectedMachine([])
  }

  const handleBackToSensorPage = () => {
    setShowSensorPage(true)
    setSelectedSensor([])
  }

  const handleSelectManufacturer = (manufacturer) => {
    if(manufacturer === 'NewManufacturer'){
        setAddNewManufacturer(true)
        setSelectedManufacturer([])
    }else{
        setSelectedManufacturer(manufacturer)
        setAddNewManufacturer(false)
    }
    setShowManufacturerList(false)
  }

  const handleShowManufacturerList = () => {
      if(manufacturerSearchText){
          setShowManufacturerList(true)
      }else{
          setShowManufacturerList(!showManufacturerList)
      }
      
  }


  // **** Functions for Input tag **** 
  const handleTagKeydown = (e) => {
    if(e.key === 'Enter' || e.key === 'Tab') {
      const value = e.target.value
      
      if(!value.trim()) return
      setSelectedTags([...selectedTags, value])
      e.target.value= ''
    } 
    
  };

  const handleAddTagFromList = (tag) => {
    if(selectedTags.includes(tag)) return
    setSelectedTags([...selectedTags, tag])
  }

  const addTag = (tagValue) => {
    if(!tagValue.trim()) return
    setSelectedTags([...selectedTags, tagValue])
  }

  const removeTag = (index) => {
    setSelectedTags(selectedTags.filter((el,i) => i !== index))
  }
  // **** Functions for Input tag **** 


  // **** Functions for Input Department **** 

  const handleAddDepartmentFromList = (selectedDatabaseDepartment) => {
    setSelectedDepartment(selectedDatabaseDepartment)
  }

  const removeDepartment = (index) => {
    setSelectedDepartment([])
  }
  // **** Functions for Input Department **** 


  // **** Functions for General Input forms **** 
  const handleGeneralMachineFormChange = (event) => {
    // Get the name of the field that caused this change event
    // Get the new value of this field
    const { name, value } = event.target;

    // Assign new value to the appropriate form field
    const updatedForm = {
      ...machineForm,
      [name]: value
    };


    // Update state
    setMachineForm(updatedForm);

  }
  const handleGeneralFormChange = (event) => {
    // Get the name of the field that caused this change event
    // Get the new value of this field
    const { name, value } = event.target;
    

    // Assign new value to the appropriate form field
    const updatedForm = {
      ...form,
      [name]: value
    };

    // console.log('Form changed: ', updatedForm);

    // Update state
    setForm(updatedForm);

  }
  // **** Functions for Input Department **** 


  const handleChangeMachineType = (event) => {
    
    // Get the name of the field that caused this change event
    // Get the new value of this field
    const { value } = event.target;

    if(value){
      setSelectedMachineType(allMachineTypes[value])
    }else{
      setSelectedMachineType('')
    }
  }

  // useEffect to filter through search for machines
  useEffect(() => {
    if(machineSearchText && machineSearchText.length > 0){
      setShowMachineList(true)
      // const filtered = machineList.filter(machine => {
      //   if(machine.machine_name.toLowerCase().includes(machineSearchText.toLowerCase()) || machine.machine_identifier.toLowerCase().includes(machineSearchText.toLowerCase()) || machine.machine_description.toLowerCase().includes(machineSearchText.toLowerCase()) || machine.manufacturer_name.toLowerCase().includes(machineSearchText.toLowerCase())){
      //     return machine
      //   }
      // })
      
      // Get list of filtered machiens machines
      getData(apiLink + `/v1/machines?q=${machineSearchText}`)
      .then(async response => {
        if(response.ok ){
          const data = await response.json();
          if(data.data){
            setFilteredMachineList(data.data)
          }
        }
      });

      // setFilteredMachineList(filtered)
    }else{
      setFilteredMachineList(machineList)
    }

  },[machineList, machineSearchText])

  

  const submitAddAsset = () => {
    try {
      if(!loading && selectedOrganization){
        setLoading(true)
        let nodeId = ''
        if(selectedSensor && Object.keys(selectedSensor).length > 0){
          nodeId = selectedSensor.node_uuid
        }
        // console.log('machineImage', machineImage)
        postData(apiLink + '/v1/organizations/' + selectedOrganization + '/assets', 
        {'machine_uuid': selectedMachine.machine_uuid,
          "asset_custom_name": machineForm.machineCustomName,
          "asset_custom_description": "",
          "asset_custom_identifier": machineForm.machineCustomIdentifier,
          "asset_custom_picture": machineImage,
          "asset_custom_homepage_url": "string",
          "department_uuid": selectedDepartment.department_uuid,
          "tags": selectedTags,
          "node_uuid": nodeId})
          .then(async response => {
            const data = await response.json();
            if(response.ok){
              // const data = await response.json();
              setLoading(false)
              setSelectedMachine([])
              setSelectedSensor([])
              setSelectedDepartment([])
              setSelectedTags([])
              setMachineForm({
                machineCustomIdentifier: '',
                machineCustomName: '',
              })
              if(getOrgAssets){
                getOrgAssets()
              }
              toast.success('Asset has been added')
              closeModal()
            }
            else{
              try{
                const data = await response.json();
                setLoading(false)
                toast.error(data.message)
              }catch (e){
                toast.error('Something went wrong')
                setLoading(false)
              }
            }
          })

      }
      
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleUpdateMachineImage = (image) => {
    setMachineImage(image)
  }
  

  return (
    <>
      <div className="flex items-center justify-center">
      {btnType ==='standardBtn' &&
        <motion.button
          whileHover={{ scale:isUserAuthorized ? 1.05 : 1 }} 
          whileTap={{ scale:isUserAuthorized? 0.95 : 1 }} 
          className='btnMain disabled:opacity-50' onClick={openModal}
          disabled={!isUserAuthorized}
        >
            <PlusSmIcon className='btnMainIcon' />
            <span className='btnMainText'>Add asset</span>
        </motion.button>
        }
      {btnType ==='tableBtn' &&
       <motion.button
          whileHover={{ scale:isUserAuthorized ? 1.05 : 1 }} 
          whileTap={{ scale:isUserAuthorized? 0.95 : 1 }} 
          className='flex w-20 text-sm items-baseline ml-2' onClick={openModal} 
          disabled={!isUserAuthorized}
       >
          <PlusSmIcon className='text-blue-600 w-4 h-4 mt-auto mb-0.5' />
          <span className=' text-blue-600'>Add</span>
      </motion.button>
        }
        {btnType ==='hideBtn' &&
          <div className='w-0 h-0'>{''}</div>
          }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-lg transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <Dialog.Title
                    as="h3"
                    className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}
                  >
                    Add Asset
                  </Dialog.Title>
                  <AnimatePresence exitBeforeEnter>
                    
                  

                  {/* Select Sensor */}
                  {Object.keys(selectedMachine).length === 0 && !addNewMachine && showSensorPage &&
                    <motion.div
                      key='selectSensorPage'
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    > 
                      <div className='px-4 mt-3'>
                      <div className='flex mb-4'>
                          <div className='flex flex-col'>
                              <span className={`text-base font-medium ${isDark?'text-gray-300':'text-gray-600'}`}>Select Sensor</span>
                              <span className={`text-sm leading-4 ${isDark?'text-gray-400':'text-gray-500'}`}>
                                  This is the IoTFlows sensor that you want to attach to a machine
                              </span>
                          </div>
                      </div>

                      {/* // Input search for sensor */}
                      <div className={`${showSensorList ? 'h-72': 'h-24'} min-w-[200px] lg:min-w-[350px] `}>
                            <div className={`flex relative grow`}>
                                
                                <div className='relative rounded-full w-full'>
                                  <div className='absolute inset-y-0 pl-3 flex items-center pointer-events-none'>
                                    <SearchIcon className='h-4 w-4 text-gray-400 ' />
                                  </div>
                                  <input className={`block w-full pl-8 sm:text-sm rounded-md text-ellipsis py-1
                                                  ${isDark?' text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800 bg-slate-700  placeholder:text-gray-500 ':' placeholder:text-gray-400  text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`}
                                          value={sensorSearchText} 
                                          onChange={(e)=> setSensorSearchText(e.target.value)} 
                                          onClick={handleShowSensorList} 
                                          type='text' 
                                          placeholder="Find or add a sensor..." />
                                </div>

                                {sensorList && Object.keys(sensorList).length > 0 && showSensorList &&
                                    <div className={`${isDark?'bg-slate-900 ':'bg-white '} w-full flex flex-col absolute p-0.5 z-50 shadow-iotflows-lg inset-x-0 top-10 rounded-lg max-h-[220px] overflow-auto scrollbar-hide`}   >
                                        <div className={`w-full h-10 cursor-pointer p-2  ${isDark?'hover:bg-slate-800 ':'hover:bg-slate-200'} rounded`} onClick={() => handleSelectSensor('newSensor')}>
                                            <div className={`flex ${isDark?'text-blue-400':'text-blue-600'}`} >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                                                </svg>
                                                <div className='text-sm pl-2 font-medium '>
                                                    Add new sensor
                                                </div>
                                            </div>
                                        </div>
                                      {sensorList.map((sensor, index) => (
                                          <div className={`${isDark?'hover:bg-slate-800':'hover:bg-slate-200'} w-full flex cursor-pointer p-2 rounded`} key={sensor.node_uuid} onClick={() => handleSelectSensor(sensor)}>
                                              <div>
                                                  <img
                                                  alt={sensor.node_name}
                                                  src={SenseAI}
                                                  className='w-10 h-10 rounded-full'
                                                  />
                                              </div>
                                              <div className='flex flex-col ml-2 justify-between' >
                                                  <div className={`${isDark?'text-gray-300 ':' text-gray-600 '} text-base font-medium `} >
                                                    {sensor.node_name}
                                                  </div>
                                                  <div className={`${isDark?'text-gray-400 ':' text-gray-500 '} text-xs font-light italic`} >
                                                    {sensor.node_uuid}
                                                  </div>
                                              </div>
                                          </div>
                                      ))}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='w-full flex'>
                          <div className={`${isDark?'text-blue-400 hover:text-blue-600 ':'text-blue-600 hover:text-blue-800'} ml-auto text-sm font-medium italic cursor-pointer `} onClick={() => setShowSensorPage(false)}>
                            Skip, I'll do this later.
                          </div>
                        </div>
                        
                    </div>
                    </motion.div>
                  }
                  {/* Select Machine */}
                  {Object.keys(selectedMachine).length === 0 && !addNewMachine && !showSensorPage &&
                    <motion.div
                      key='selectMachinePage'
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    > 
                      <div className='px-4'>
                        <div className='mb-4'>
                            <div onClick={handleBackToSensorPage}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={`h-7 w-7 p-0.5 rounded-full transition duration-300 ease-in-out ${isDark?'text-gray-400 hover:bg-gray-700':'text-gray-500 hover:bg-gray-200'} hover:-translate-y-0.5 ml-[-10px] mb-1`} viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                              </svg>
                            </div>
                            <div className='flex flex-col'>
                                <span className={`text-base font-medium ${isDark?'text-gray-300':'text-gray-600'}`}>Select Machine</span>
                                <span className={`text-sm leading-4 ${isDark?'text-gray-400':'text-gray-500'}`}>
                                    This is the machine your sensor is attached to
                                </span>
                            </div>
                        </div>

                      

                      {/* // Input search for machines */}
                      <div className={`${showMachineList ? 'h-64': 'h-24'} min-w-[200px] lg:min-w-[350px] `}>
                            <div className={`flex relative grow`}>
                                
                                <div className='relative rounded-full w-full'>
                                  <div className='absolute inset-y-0 pl-3 flex items-center pointer-events-none'>
                                    <SearchIcon className='h-4 w-4 text-gray-400 ' />
                                  </div>
                                  <input className={`block w-full pl-8 sm:text-sm rounded-md text-ellipsis py-1
                                                  ${isDark?' text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800 bg-slate-700  placeholder:text-gray-500 ':' placeholder:text-gray-400  text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`}
                                          value={machineSearchText} 
                                          onChange={(e)=> setMachineSearchText(e.target.value)} 
                                          onClick={handleShowMachineList} 
                                          type='text' 
                                          placeholder="Find or add a machine..." />
                                </div>

                                {Object.keys(filteredMachineList).length > 0 && showMachineList &&
                                    <div className={`${isDark?'bg-slate-900 ':'bg-white '} w-full flex flex-col absolute p-0.5 z-50 shadow-iotflows-lg inset-x-0 top-10 rounded-lg max-h-[220px] overflow-y-auto overflow-x-hidden scrollbar-hide`}   >
                                        {/* <div className={`w-full h-10 cursor-pointer p-2  ${isDark?'hover:bg-slate-800 ':'hover:bg-slate-200'} rounded`} onClick={() => handleSelectMachine('NewMachine')}>
                                            <div className={`flex ${isDark?'text-blue-400':'text-blue-600'}`} >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                                                </svg>
                                                <div className='text-sm pl-2 font-medium '>
                                                    Add new machine
                                                </div>
                                            </div>
                                        </div> */}
                                      {filteredMachineList.map((machine, index) => (
                                          <div className={`${isDark?'hover:bg-slate-800':'hover:bg-slate-200'} w-full flex cursor-pointer p-2 rounded `} key={machine.machine_uuid} onClick={() => handleSelectMachine(machine)}>
                                              <div className='w-10'>
                                                <OptimizeImage alt={machine.machine_name} source={machine.machine_picture_url}  size='medium' />
                                              </div>
                                              <div className='flex flex-col mx-2 justify-between truncate' >
                                                  <div className={`${isDark?'text-gray-300 ':' text-gray-600 '} text-base font-medium truncate `} >
                                                    <span>{machine.machine_name}</span>
                                                    {/* {machine.machine_name}{' - '}<span className='text-sm font-normal'><i>{machine.machine_identifier}</i></span> */}
                                                  </div>
                                                  <div className={`${isDark?'text-gray-400 ':' text-gray-500 '} text-xs font-light italic`} >
                                                  {machine.manufacturer_name}
                                                  </div>
                                              </div>
                                              <div className='ml-auto mt-auto w-7' >
                                                  <img
                                                  alt={machine.manufacturer_name}
                                                  src={machine.manufacturer_logo_url}
                                                  className='w-7 h-7 rounded-full'
                                                  />
                                              </div>
                                          </div>
                                      ))}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    </motion.div>
                  }
                  {/* Selected machine information from database */}
                  {Object.keys(selectedMachine).length > 0 && !addNewMachine && !showSensorPage &&
                    <motion.div
                      key='machineIsSelectedPage'
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <div className='flex flex-col relative min-w-[300px] justify-center align-center text-center px-4 mt-2'>
                          <div className={`flex ${isDark?'text-gray-400':'text-gray-500'} absolute top-0 left-2`}>
                              <div className={`p-1 my-auto rounded-full transition duration-300 ease-in-out ${isDark?'hover:bg-gray-700':'hover:bg-gray-200'} hover:-translate-y-0.5`} onClick={()=> setSelectedMachine([])}>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                      <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                  </svg>
                              </div>
                          </div>
                          <div className='mx-auto' >
                              <img
                                alt={selectedMachine.machine_name}
                                src={selectedMachine.machine_picture_url}
                                className='w-32  rounded-full'
                              />
                          </div>
                          <div className={`${isDark?'text-gray-300':'text-gray-600'} mt-0.5 text-lg font-bold`}>{selectedMachine.machine_name}</div>
                          <div className={`${isDark?'text-gray-300':'text-gray-600'} mt-0.5 text-base font-normal`}>{selectedMachine.machine_identifier}</div>
                          <div className={`${isDark?'text-gray-400':'text-gray-500'} mt-0.5 text-sm font-light`}>{selectedMachine.manufacturer_name}</div>
                          <div className='mt-2 mx-auto' >
                              <img
                                className='w-10 h-10 rounded-full'
                                alt={selectedMachine.manufacturer_name}
                                src={selectedMachine.manufacturer_logo_url}
                              />
                          </div>
                          <div className='text-left '>
                            {showGODview &&
                              <div className='flex flex-col text-left'>
                                <p className={`text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`}>
                                  Custum Machine Image
                                </p>
                                <ImageZoomCrop currentPicture={machineImage} submitType={'partImage'} title={'Machine Image'} isDisabled={true} isRounded={false} updateInfo={handleUpdateMachineImage} />
                              </div>
                            }
                            <div className="pt-2">
                              <GeneralFormInput label='Machine Identifier' type='text' placeholder='trx-as800' name='machineCustomIdentifier' onChange={handleGeneralMachineFormChange} />
                            </div>
                            <div className="pt-2">
                              <GeneralFormInput label='Machine Name' type='text' placeholder='' name='machineCustomName' onChange={handleGeneralMachineFormChange} />
                            </div>
                            <div className='pt-2'>
                              <DepartmentInput selectedDepartment={selectedDepartment} removeDepartment={removeDepartment} handleAddDepartmentFromList={handleAddDepartmentFromList}/>
                            </div>
                            <div className='pt-2'>
                              <TagInput tags={selectedTags} onKeyDown={handleTagKeydown} addTag={addTag} removeTag={removeTag} handleAddTagFromList={handleAddTagFromList}/>
                            </div>
                          </div>
                          
                          
                      </div>
                    </motion.div>
                  }
                  {/* Adding new machine section */}
                {addNewMachine &&
                <motion.div
                key='addNewMachinePage'
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
                >
                  <div className='px-4'>
                      <div className='mb-4 min-w-[300px]'>
                          <svg xmlns="http://www.w3.org/2000/svg" className={`h-7 w-7 p-0.5 rounded-full transition duration-300 ease-in-out ${isDark?'text-gray-400 hover:bg-gray-700':'text-gray-500 hover:bg-gray-200'} hover:-translate-y-0.5 ml-[-10px] mb-1`} onClick={handleBackFromAddMachine} viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                          </svg>
                          <div className='flex flex-col'>
                              <span className={`text-base font-medium ${isDark?'text-gray-300':'text-gray-600'}`}>Add Machine</span>
                              <span className={`text-sm leading-4 ${isDark?'text-gray-400':'text-gray-500'}`}>
                                Add your machine here if it's not in our database
                              </span>
                          </div>
                      </div>
                      <div className={`flex relative grow`}>
                          <div className='relative rounded-full w-full'>
                            <div className='absolute inset-y-0 pl-3 flex items-center pointer-events-none'>
                              <SearchIcon className='h-4 w-4 text-gray-400 ' />
                            </div>
                            <input className={`block w-full pl-8 sm:text-sm rounded-md text-ellipsis py-1
                                            ${isDark?' text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800 bg-slate-700 placeholder:text-gray-500 ':' placeholder:text-gray-400  text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`}
                                    value={manufacturerSearchText} 
                                    onChange={(e)=> setManufacturerSearchText(e.target.value)} 
                                    onClick={handleShowManufacturerList} 
                                    type='text' 
                                    placeholder="Find or add a manufacturer..." />
                          </div>
                          {Object.keys(manufacturerList).length > 0 && showManufacturerList &&
                              <div className={`${isDark?'bg-slate-900 ':'bg-white '}w-full flex flex-col absolute p-0.5 z-50 bg-white shadow-md inset-x-0 top-10 rounded-lg max-h-[200px] overflow-auto scrollbar-hide`}   >
                                  <div className={`w-full h-10 cursor-pointer p-2  ${isDark?'hover:bg-slate-800 ':'hover:bg-slate-200'} rounded`} onClick={() => handleSelectManufacturer('NewManufacturer')}>
                                      <div className={`flex ${isDark?'text-blue-400':'text-blue-600'}`} >
                                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                                          </svg>
                                          <div className='text-sm pl-2 font-medium '>
                                              Add new manufacturer
                                          </div>
                                      </div>
                                  </div>
                              {manufacturerList.map((manufacturer) => (
                                  <div className={`${isDark?'hover:bg-slate-800':'hover:bg-slate-200'} w-full flex cursor-pointer p-2 rounded`} key={manufacturer.manufacturer_uuid} onClick={() => handleSelectManufacturer(manufacturer)}>
                                      <div>
                                          <img
                                          alt={manufacturer.manufacturer_name}
                                          src={manufacturer.manufacturer_logo_url}
                                          className='w-10 h-10 rounded-full'
                                          />
                                      </div>
                                      <div className={`${isDark?'text-gray-300 ':' text-gray-600 '} ml-2 my-auto text-base font-medium `}>
                                          {manufacturer.manufacturer_name}
                                      </div>
                                  </div>
                              ))}
                              </div>
                          }
                      </div>

                      {/* selected mfg information */}
                      {Object.keys(selectedManufacturer).length > 0 && 
                          <motion.div
                            key='selectedManufacturerSection'
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                          >
                            <div className='text-center mx-auto'>
                                <div className={`my-2 text-lg ${isDark ? 'text-gray-300':'text-gray-600'} font-bold`}>{selectedManufacturer?.manufacturer_name}</div>
                                <img
                                    alt={selectedManufacturer?.manufacturer_name}
                                    src={selectedManufacturer?.manufacturer_logo_url}
                                    className='w-12 h-12 rounded-full mx-auto'
                                  /> 
                            </div>
                          </motion.div>
                          
                          }

                      {addNewManufacturer &&
                      /* Add new MFG */
                        <motion.div
                            key='newManufacturerSection'
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                          >
                            <div className={`border-b-[1px] border-dashed pb-4 ${isDark?'border-gray-700':'border-gray-200'}`}>
                              <div className="pt-4">
                                <GeneralFormInput label='Manufacturer Name' type='text' placeholder='' name='manufacturerName' onChange={handleGeneralFormChange} />
                              </div>
                              <div className="pt-4">
                                <GeneralFormInput label='Manufacturer Description' type='text' placeholder='' name='manufacturerDescription' onChange={handleGeneralFormChange} />
                              </div>
                              <div className="pt-4">
                                <GeneralFormInput label='Manufacturer Homepage URL' type='text' placeholder='' name='manufacturerUrl' onChange={handleGeneralFormChange} />
                              </div>
                            </div>
                          </motion.div>
                      }
                      


                      <div >
                        <div className='mb-4'>
                          <div className="pt-4">
                            <GeneralFormInput label='Machine Name' type='text' placeholder='' name='machineName' onChange={handleGeneralFormChange} />
                          </div>
                          <div className="pt-4">
                            <GeneralFormInput label='Machine Description' type='text' placeholder='' name='machineDescription' onChange={handleGeneralFormChange} />
                          </div>
                          <div className="pt-4">
                            <GeneralFormInput label='Machine Identifier' type='text' placeholder='' name='machineIdentifier' onChange={handleGeneralFormChange} />
                          </div>
                          <div className="pt-4">
                            <DropdownSelectFormMachineType handleOnChange={handleChangeMachineType} label={'Machine Type'} value={selectedMachineType.machine_type || ''} optionsList={allMachineTypes} name='machineType' />
                          </div>
                          <div key='newMachineSection'>
                            <div className='pt-2'>
                              <DepartmentInput selectedDepartment={selectedDepartment} removeDepartment={removeDepartment} handleAddDepartmentFromList={handleAddDepartmentFromList}/>
                            </div>
                            <div className='pt-2'>
                              <TagInput tags={selectedTags} onKeyDown={handleTagKeydown} addTag={addTag} removeTag={removeTag} handleAddTagFromList={handleAddTagFromList}/>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </motion.div>
                }

                </AnimatePresence>

                <form >
                    <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                      <button
                        type="button"
                        className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className={`relative ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                        disabled={Object.keys(selectedDepartment).length === 0 || loading || Object.keys(selectedMachine).length === 0 }
                        onClick={submitAddAsset}
                      >
                        Add Asset
                        {loading && 
                            <div >
                                <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                </svg>
                            </div>
                          }
                      </button>
                    </div>
                  </form>
                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      
    </>
  )
}

export default AddAssetModal