import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { PlusSmIcon } from "@heroicons/react/solid"
import { useRecoilState, useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { motion, AnimatePresence } from "framer-motion";
import GeneralFormInput from '../../forms/GeneralFormInput';
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import { getData, postData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import { SearchIcon } from '@heroicons/react/outline';
import ImageZoomCrop from '../ImageZoomCrop';


function AddMachineModal({btnType, remoteOpenAssetModal}) {
    let [isOpen, setIsOpen] = useState(false)
    const isDark = useRecoilValue(darkModeState);
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const [loadingMfgMachine, setLoadingMfgMachine] = useState(false)
    const [loadingMachine, setLoadingMachine] = useState(false)
    const [machineForm, setMachineForm] = useState({
      machineName: '',
      machineDescription: '',
      machineIdentifier: '',
      machineHomepageUrl: '',
    });
    const [mfgForm, setMfgForm] = useState({
      manufacturerName: '',
      manufacturerDescription: '',
      manufacturerHomepageUrl: '',
      manufacturerSupportHomepageUrl: '',
      manufacturerSupportEmail: '',
      manufacturerSupportPhone: '',
    });
    const [mfgLogo, setMfgLogo] = useState(null)
    const [machineImage, setMachineImage] = useState(null)
    const [showManufacturersPage, setShowManufacturersPage] = useState(true)
    const [showMfgList, setshowMfgList] = useState(false)
    const [mfgSearchText, setMfgSearchText] = useState('')
    const [mfgList, setMfgList] = useState([])
    const [filteredMfgList, setFilteredMfgList] = useState([])
    const [selectedMfg, setSelectedMfg] = useState([])
    const [machineList, setMachineList] = useState([])
    const [newMfg, setNewMfg] = useState(false)


    useEffect(() => {
      if(remoteOpenAssetModal){
        setIsOpen(true)
      }
    },[remoteOpenAssetModal])
    

    function closeModal() {
      setIsOpen(false)
      setMachineForm({
        machineName: '',
        machineDescription: '',
        machineIdentifier: '',
        machineHomepageUrl: '',
      })
      setMfgForm({
        manufacturerName: '',
        manufacturerDescription: '',
        manufacturerHomepageUrl: '',
        manufacturerSupportHomepageUrl: '',
        manufacturerSupportEmail: '',
        manufacturerSupportPhone: '',
      })
      setMfgLogo(null)
      setMachineImage(null)
      setShowManufacturersPage(true)
      setSelectedMfg([])
    }

    function openModal() {
      setIsOpen(true)
    }

  useEffect(() => {
      try {
        if( selectedOrganization){
          // Get list of machines
          getData(apiLink + '/v1/machines')
          .then(async response => {
            if(response.ok ){
              const data = await response.json();
              if(data.data){
                setMachineList(data.data)
              }
            }
          });
  
          // Get list of gateways (not sensors)
          getData(apiLink + '/v1/manufacturers')
          .then(async response => {
            if(response.ok ){
              const data = await response.json();
              if(data && data.data){
                setMfgList(data.data)
              }
            }
          });

        }
      } catch (error) {
        console.log(error.message)
      }
    },[remoteOpenAssetModal, selectedOrganization])




  // **** Functions for General Input forms **** 
  const handleGeneralMfgFormChange = (event) => {
    // Get the name of the field that caused this change event
    // Get the new value of this field
    const { name, value } = event.target;

    // Assign new value to the appropriate form field
    const updatedForm = {
      ...mfgForm,
      [name]: value
    };

    // console.log('Form changed: ', updatedForm);

    // Update state
    setMfgForm(updatedForm);

  }

  const handleGeneralMachineFormChange = (event) => {
    // Get the name of the field that caused this change event
    // Get the new value of this field
    const { name, value } = event.target;

    // Assign new value to the appropriate form field
    const updatedForm = {
      ...machineForm,
      [name]: value
    };

    // console.log('Form changed: ', updatedForm);

    // Update state
    setMachineForm(updatedForm);

  }

  const submitMachine = () => {
    try {
      if(!loadingMachine){
        setLoadingMachine(true)
        postData(apiLink + '/v1/machines', 
        { "machine_name": machineForm.machineName,
          "machine_description": machineForm.machineDescription,
          "machine_identifier": machineForm.machineIdentifier,
          "machine_picture": machineImage,
          "machine_homepage_url": machineForm.machineHomepageUrl,
          "manufacturer_uuid": selectedMfg.manufacturer_uuid
        })
          .then(async response => {
            const data = await response.json();
            if(response.ok){
              setLoadingMachine(false)
              toast.success('Machine added')
              closeModal()
            }
            else{
              try{
                const data = await response.json();
                setLoadingMachine(false)
                toast.error(data.message)
              }catch (e){
                toast.error('Something went wrong')
                setLoadingMachine(false)
              }
            }
          })
      }
      
    } catch (error) {
      
    }
  }


  const submitMfgMachine = () => {
    try {
      if(!loadingMfgMachine){
        setLoadingMfgMachine(true)

        postData(apiLink + '/v1/manufacturers_machines', 
        {'manufacturer_name': mfgForm.manufacturerName,
          "manufacturer_description": mfgForm.manufacturerDescription,
          "manufacturer_logo_picture": mfgLogo,
          "manufacturer_homepage_url": mfgForm.manufacturerHomepageUrl,
          "manufacturer_support_homepage_url": mfgForm.manufacturerSupportHomepageUrl,
          "manufacturer_support_email": mfgForm.manufacturerSupportEmail,
          "manufacturer_support_phone": mfgForm.manufacturerSupportPhone,
          "machine_name": machineForm.machineName,
          "machine_description": machineForm.machineDescription,
          "machine_identifier": machineForm.machineIdentifier,
          "machine_picture": machineImage,
          "machine_homepage_url": machineForm.machineHomepageUrl,
        })
          .then(async response => {
            const data = await response.json();
            if(response.ok){
              setLoadingMfgMachine(false)
              toast.success('Manufacturer & Machine have been added')
              closeModal()
            }
            else{
              try{
                const data = await response.json();
                setLoadingMfgMachine(false)
                toast.error(data.message)
              }catch (e){
                toast.error('Something went wrong')
                setLoadingMfgMachine(false)
              }
            }
          })
      }
      
    } catch (error) {
      
    }
  }

  useEffect(() => {
    if(mfgSearchText && mfgSearchText.length > 0){
      setshowMfgList(true)
      const filtered = mfgList.filter(manufacturer => {
        if(manufacturer.manufacturer_name.toLowerCase().includes(mfgSearchText.toLowerCase()) || manufacturer.manufacturer_description.toLowerCase().includes(mfgSearchText.toLowerCase()) || manufacturer.manufacturer_homepage_url.toLowerCase().includes(mfgSearchText.toLowerCase())){
          return manufacturer
        }
      })
      

      setFilteredMfgList(filtered)
    }else{
      setFilteredMfgList(mfgList)
    }

  },[mfgList, mfgSearchText])

  const handleShowMfgList = () => {
    if(mfgSearchText){
      setshowMfgList(true)
    }else{
      setshowMfgList(!showMfgList)
    }
  }

  const handleSelectMfg = (mfgSelected) => {
    if(mfgSelected === 'newMfg'){
        setNewMfg(true)
        setShowManufacturersPage(false)
        // setIsSensorModalOpen(true)
    }else{
        setSelectedMfg(mfgSelected)
        setNewMfg(false)
        setShowManufacturersPage(false)
    }
  }

  const handleBackBtn = () => {
    setSelectedMfg([])
    setShowManufacturersPage(true)
  }

  const handleUpdateMfgLogo = (image) => {
    setMfgLogo(image)
  }

  const handleUpdateMachineImage = (image) => {
    setMachineImage(image)
  }


  return (
    <>
      <div className="flex items-center justify-center">
      {btnType ==='standardBtn' &&
        <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
            <PlusSmIcon className='btnMainIcon' />
            <span className='btnMainText pl-0.5'>Add Machine</span>
        </motion.button>
        }
      {btnType ==='tableBtn' &&
       <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='flex w-20 text-sm items-baseline ml-2' onClick={openModal} >
          <PlusSmIcon className='text-blue-600 w-4 h-4 mt-auto mb-0.5' />
          <span className=' text-blue-600'>Add</span>
      </motion.button>
      }
      {btnType ==='inlineIconBtn' &&
        <button className={`${isDark?'text-blue-400 hover:text-blue-200':'text-blue-500 hover:text-blue-900'} cursor-pointer flex ml-auto`} onClick={openModal}>
          <PlusSmIcon className={`w-5 h-5`}/>
        </button>
      }
      {btnType ==='hideBtn' &&
        <div className='w-0 h-0'>{''}</div>
      }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-lg transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <Dialog.Title
                    as="h3"
                    className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}
                  >
                    Add Machine
                  </Dialog.Title>
                  <AnimatePresence exitBeforeEnter>

                  {showManufacturersPage &&
                    <motion.div
                      key='selectSensorPage'
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    > 
                      <div className='px-4 mt-3'>
                      <div className='flex mb-4'>
                          <div className='flex flex-col'>
                              <span className={`text-base font-medium ${isDark?'text-gray-300':'text-gray-600'}`}>Select Machine Manufacturer</span>
                              {/* <span className={`text-sm leading-4 ${isDark?'text-gray-400':'text-gray-500'}`}>
                                  This is the IoTFlows sensor that you want to attach to a machine
                              </span> */}
                          </div>
                      </div>

                      {/* // Input search for sensor */}
                      <div className={`${showMfgList ? 'h-72': 'h-24'} min-w-[200px] lg:min-w-[350px] `}>
                            <div className={`flex relative grow`}>
                                
                                <div className='relative rounded-full w-full'>
                                  <div className='absolute inset-y-0 pl-3 flex items-center pointer-events-none'>
                                    <SearchIcon className='h-4 w-4 text-gray-400 ' />
                                  </div>
                                  <input className={`block w-full pl-8 sm:text-sm rounded-md text-ellipsis py-1
                                                  ${isDark?' text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800 bg-slate-700  placeholder:text-gray-500 ':' placeholder:text-gray-400  text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`}
                                          value={mfgSearchText} 
                                          onChange={(e)=> setMfgSearchText(e.target.value)} 
                                          onClick={handleShowMfgList} 
                                          type='text' 
                                          placeholder="Find or add manufacturer..." />
                                </div>

                                {filteredMfgList && Object.keys(filteredMfgList).length > 0 && showMfgList &&
                                    <div className={`${isDark?'bg-slate-900 ':'bg-white '} w-full flex flex-col absolute p-0.5 z-50 shadow-iotflows-lg inset-x-0 top-10 rounded-lg max-h-[220px] overflow-auto scrollbar-hide`}   >
                                        <div className={`w-full h-10 cursor-pointer p-2  ${isDark?'hover:bg-slate-800 ':'hover:bg-slate-200'} rounded`} onClick={() => handleSelectMfg('newMfg')}>
                                            <div className={`flex ${isDark?'text-blue-400':'text-blue-600'}`} >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                                                </svg>
                                                <div className='text-sm pl-2 font-medium '>
                                                    Add new manufacturer
                                                </div>
                                            </div>
                                        </div>
                                      {filteredMfgList.map((manufacturer, index) => (
                                          <div className={`${isDark?'hover:bg-slate-800':'hover:bg-slate-200'} w-full flex cursor-pointer p-2 rounded`} key={manufacturer.manufacturer_uuid} onClick={() => handleSelectMfg(manufacturer)}>
                                              <div>
                                                  <img
                                                  alt={manufacturer.manufacturer_name}
                                                  src={manufacturer.manufacturer_logo_url}
                                                  className='w-10 h-10 rounded-lg'
                                                  />
                                              </div>
                                              <div className='flex flex-col ml-2 justify-between' >
                                                  <div className={`${isDark?'text-gray-300 ':' text-gray-600 '} text-base font-medium `} >
                                                    {manufacturer.manufacturer_name}
                                                  </div>
                                                  <div className={`${isDark?'text-gray-400 ':' text-gray-500 '} text-xs font-light italic max-w-[250px] sm:max-w-[370px] truncate`} >
                                                    {manufacturer.manufacturer_description}
                                                  </div>
                                              </div>
                                          </div>
                                      ))}
                                    </div>
                                }
                            </div>
                        </div>
                        {/* <div className='w-full flex'>
                          <div className={`${isDark?'text-blue-400 hover:text-blue-600 ':'text-blue-600 hover:text-blue-800'} ml-auto text-sm font-medium italic cursor-pointer `} onClick={() => setShowSensorPage(false)}>
                            Skip, I'll do this later.
                          </div>
                        </div> */}
                        
                    </div>
                    </motion.div>
                  }
                  {newMfg && !showManufacturersPage &&
                    <motion.div
                      key='newMfgForm'
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                        <div className='flex flex-col relative min-w-[300px] justify-center align-center text-center px-4 mt-2'>
                          <div className={`flex ${isDark?'text-gray-400':'text-gray-500'} absolute top-0 left-2`}>
                              <div className={`p-1 my-auto rounded-full transition duration-300 ease-in-out ${isDark?'hover:bg-gray-700':'hover:bg-gray-200'} hover:-translate-y-0.5`} onClick={handleBackBtn}>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                      <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                  </svg>
                              </div>
                          </div>
                          <div className='text-left mt-4'>
                            <div className='justify-center text-center'>
                              <p className={`text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`}>
                                Manufacturer Logo
                              </p>
                              <ImageZoomCrop currentPicture={mfgLogo} submitType={'partImage'} title={'Manufacturing Logo'} isDisabled={true} isRounded={false} updateInfo={handleUpdateMfgLogo} />
                            </div>
                            <div className="pt-2">
                              <GeneralFormInput label='Manufacturer Name' type='text' defaultValue={''} name='manufacturerName' onChange={handleGeneralMfgFormChange} />
                            </div>
                            <div className="pt-2">
                              <GeneralFormInput label='Manufacturer Description' type='text'  name='manufacturerDescription' defaultValue={''} onChange={handleGeneralMfgFormChange} />
                            </div>
                            <div className="pt-2">
                              <GeneralFormInput label='Manufacturer Homepage URL' type='text' defaultValue={''} name='manufacturerHomepageUrl' onChange={handleGeneralMfgFormChange} />
                            </div>
                            <div className="pt-2">
                              <GeneralFormInput label='Manufacturer Support Homepage URL' type='text'  name='manufacturerSupportHomepageUrl' defaultValue={''} onChange={handleGeneralMfgFormChange} />
                            </div>
                            <div className="pt-2">
                              <GeneralFormInput label='Manufacturer Support Email' type='text' defaultValue={''} name='manufacturerSupportEmail' onChange={handleGeneralMfgFormChange} />
                            </div>
                            <div className="pt-2">
                              <GeneralFormInput label='Manufacturer Support Phone' type='text'  name='manufacturerSupportPhone' defaultValue={''} onChange={handleGeneralMfgFormChange} />
                            </div>
                          </div>
                        </div>
                    </motion.div>

                  }
                  {!newMfg && !showManufacturersPage &&
                    <motion.div
                      key='newMfgForm'
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                        <div className='flex flex-col relative min-w-[300px] justify-center align-center text-center px-4 mt-2'>
                          <div className={`flex ${isDark?'text-gray-400':'text-gray-500'} absolute top-0 left-2`}>
                              <div className={`p-1 my-auto rounded-full transition duration-300 ease-in-out ${isDark?'hover:bg-gray-700':'hover:bg-gray-200'} hover:-translate-y-0.5`} onClick={handleBackBtn}>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                      <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                  </svg>
                              </div>
                          </div>
                          <div className='mt-2 mx-auto' >
                              <img
                                className='w-14 h-14 rounded-lg'
                                alt={selectedMfg.manufacturer_name}
                                src={selectedMfg.manufacturer_logo_url}
                              />
                          </div>
                          <div className='mt-2 mx-auto' >
                              <div className={`${isDark?'text-gray-300':'text-gray-500'} mt-0.5 text-base font-light`}>{selectedMfg.manufacturer_name}</div>
                          </div>
                        </div>
                    </motion.div>

                  }

                  { !showManufacturersPage &&

                    <motion.div
                      key='machineIsSelectedPage'
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <div className='flex flex-col min-w-[300px] justify-center align-center text-center px-4 mt-4'>
                        <div className='flex flex-col text-left'>
                          <p className={`text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`}>
                            Machine Image
                          </p>
                          <ImageZoomCrop currentPicture={machineImage} submitType={'partImage'} title={'Machine Image'} isDisabled={true} isRounded={false} updateInfo={handleUpdateMachineImage} />
                        </div>
                        <div className='text-left '>
                          <div className="pt-2">
                            <GeneralFormInput label='Machine Name' type='text' defaultValue={''} name='machineName' onChange={handleGeneralMachineFormChange} />
                          </div>
                          <div className="pt-2">
                            <GeneralFormInput label='Machine Description' type='text'  name='machineDescription' defaultValue={''} onChange={handleGeneralMachineFormChange} />
                          </div>
                          <div className="pt-2">
                            <GeneralFormInput label='Machine Identifier' type='text'  name='machineIdentifier' defaultValue={''} onChange={handleGeneralMachineFormChange} />
                          </div>
                          <div className="pt-2">
                            <GeneralFormInput label='Machine Homepage URL' type='text'  name='machineHomepageUrl' defaultValue={''} onChange={handleGeneralMachineFormChange} />
                          </div>
                        </div>
                      </div>
                    </motion.div>
                    }
                  
                 

                  </AnimatePresence>
                  {!newMfg && !showManufacturersPage &&
                  <motion.div
                      key='addMachineForm'
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <form >
                        <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                          <button
                            type="button"
                            className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                            onClick={closeModal}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className={`relative cursor-pointer ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                            onClick={submitMachine}
                            disabled={loadingMachine}
                          >
                            Add Machine
                            {loadingMachine && 
                              <div >
                                  <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                  </svg>
                              </div>
                              }
                          </button>
                        </div>
                      </form>
                    </motion.div>
                  }
                  {newMfg && !showManufacturersPage &&
                  <motion.div
                    key='addMfg&MachineForm'
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <form >
                      <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                        <button
                          type="button"
                          className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className={`relative cursor-pointer ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                          onClick={submitMfgMachine}
                          disabled={loadingMfgMachine}
                        >
                          {`Add Mfg & Machine`}
                          {loadingMfgMachine && 
                            <div >
                                <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                </svg>
                            </div>
                            }
                        </button>
                      </div>
                    </form>

                  </motion.div>
                  }
                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default AddMachineModal