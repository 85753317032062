import { useEffect, useState } from 'react'
import HighchartsStock from 'highcharts/highstock';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
import { useRecoilValue } from 'recoil';
import { darkModeState } from '../../../atoms/darkModeState';
import { putData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import { toast } from 'react-hot-toast';

highchartsMore(Highcharts);


function FinalAnalysisAreaLineChart({finalData, sensor}) {
    const isDark = useRecoilValue(darkModeState);
    const [overallUtilizationOptions, setOverallUtilizationOptions] = useState({
        credits: {enabled: false},
        chart: {
            height: '175px',
            backgroundColor: 'transparent',
        },
        rangeSelector : {
            enabled: false,
            selected : 5,
            inputEnabled: false
        },
    })
    
    useEffect(() => {
        if(sensor && sensor.calibration_results){
            const results_data = sensor.calibration_results
            setOverallUtilizationOptions({
                credits: {enabled: false},
                chart: {
                    // zoomType: 'x',
                    height: '175px',
                    backgroundColor: 'transparent',
                    spacingBottom: 0,
                },
                time: {
                    useUTC: false
                  },
                xAxis: {
                    // type: 'datetime',
                    lineColor: isDark? '#6b7280': '#6b7280',
                    labels: {
                        enabled: false
                        // style: {
                        //     color: isDark? '#d1d5db': '#4b5563'
                        // }
                    }
                },
                yAxis: {
                    lineWidth: 0,
                    minorGridLineWidth: 0,
                    lineColor: 'transparent',
                    // title: 'testsdfv', 
                    // align: 'left',
                    opposite: false,
                    labels: {
                        // enabled: false,
                        style: {
                            color: isDark? '#d1d5db':'#4b5563'
                        },
                        // formatter: function () {
                        //     return this.value + '%';
                        //   }
                    },
                    minorTickLength: 0,
                    tickLength: 0,
                    gridLineWidth: 0,
                    // min:0,
                    // max:0.1,
                    plotBands: [{
                        color: 'rgba(147, 197, 253, 0.3)', // Color value
                        // from: minValue, // Start of the plot band
                        // to: maxValue // End of the plot band
                      }],
                },
                legend: { enabled: false},
                navigator : { enabled : false},
                scrollbar : { enabled : false},
                rangeSelector : {
                    enabled: false,
                    selected : 5,
                    inputEnabled: false
                },
                plotOptions: {
                    area: {
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, Highcharts.getOptions().colors[0]],
                                [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                            ]
                        },
                        marker: {
                            radius: 2
                        },
                        lineWidth: 1,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        threshold: null
                    }
                },
                series: [{
                    type: 'areaspline',
                    name: 'Point',
                    data:results_data
                    // step: true,
                    // data: chartData
                    // data: [
                    //     0.1, 0.3, 0, 0.04, 0.023, 0.0321, 0.056, 0.0566, 0.058, 0.058, 0.08, 0.09, 0.05, 0
                    // ]
                }],
                tooltip: {
                    formatter: function() {
                        return 'Point: '+ this.y 
                    }
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                    }]
                }
            })
        }
    }, [sensor])
    
    useEffect(() => {
        if(finalData && Object.keys(finalData).length > 0){
            setOverallUtilizationOptions({
                credits: {enabled: false},
                chart: {
                    // zoomType: 'x',
                    height: '175px',
                    backgroundColor: 'transparent',
                    spacingBottom: 0,
                },
                time: {
                    useUTC: false
                  },
                xAxis: {
                    // type: 'datetime',
                    lineColor: isDark? '#6b7280': '#6b7280',
                    labels: {
                        enabled: false
                        // style: {
                        //     color: isDark? '#d1d5db': '#4b5563'
                        // }
                    }
                },
                yAxis: {
                    lineWidth: 0,
                    minorGridLineWidth: 0,
                    lineColor: 'transparent',
                    // title: 'testsdfv', 
                    // align: 'left',
                    opposite: false,
                    labels: {
                        // enabled: false,
                        style: {
                            color: isDark? '#d1d5db':'#4b5563'
                        },
                        // formatter: function () {
                        //     return this.value + '%';
                        //   }
                    },
                    minorTickLength: 0,
                    tickLength: 0,
                    gridLineWidth: 0,
                    // min:0,
                    // max:0.1,
                    plotBands: [{
                        color: 'rgba(147, 197, 253, 0.3)', // Color value
                        // from: minValue, // Start of the plot band
                        // to: maxValue // End of the plot band
                      }],
                },
                legend: { enabled: false},
                navigator : { enabled : false},
                scrollbar : { enabled : false},
                rangeSelector : {
                    enabled: false,
                    selected : 5,
                    inputEnabled: false
                },
                plotOptions: {
                    area: {
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, Highcharts.getOptions().colors[0]],
                                [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                            ]
                        },
                        marker: {
                            radius: 2
                        },
                        lineWidth: 1,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        threshold: null
                    }
                },
                series: [{
                    type: 'areaspline',
                    name: 'Point',
                    data:finalData
                    // step: true,
                    // data: chartData
                    // data: [
                    //     0.1, 0.3, 0, 0.04, 0.023, 0.0321, 0.056, 0.0566, 0.058, 0.058, 0.08, 0.09, 0.05, 0
                    // ]
                }],
                tooltip: {
                    formatter: function() {
                        return 'Point: '+ this.y 
                    }
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                    }]
                }
            })
        }

      }, [isDark, finalData])

      

      const formatTime = (seconds) => {
        if(seconds ){
            const integer = Math.floor(seconds)
            const decimal = seconds - Math.floor(seconds);
    
            const minutes = Math.floor(integer / 60);
            const remainingSeconds = integer % 60;
            let string = `${minutes.toString().padStart(2, "0")}:${remainingSeconds
                .toString()
                .padStart(2, "0")}`
            if(decimal){
                let fixedValue = decimal.toFixed(2)
                fixedValue.toString().split(".")
                string += fixedValue[1]+fixedValue[2]
            }
            return string;

        }
      };


  return (
    <div className='flex flex-col lg:flex-row w-full'>
        <div className='flex-1'>
            <HighchartsReact
                highcharts={HighchartsStock}
                constructorType={'stockChart'}
                options={overallUtilizationOptions}
            />

        </div>
        <div className='lg:min-w-[400px] xl:min-w-[500px] flex'>
        {sensor && sensor.calibration_insights && Object.keys(sensor.calibration_insights).length > 0  &&
            <div className='flex flex-col'>
                <h6 className={`font-semibold mb-2 ${isDark? 'text-gray-300' : 'text-gray-600'}`}>Insights</h6>
                <div className='w-full flex items-center'>
                    <div className={`text-left leading-6 text-sm font-medium ${isDark? 'text-gray-400' : 'text-gray-400'}`}>
                        Operation Count: <span className={`text-base pl-1 ${isDark? 'text-white' : 'text-gray-600'}`}>{sensor.calibration_insights.insight_count ? sensor.calibration_insights.insight_count : ''}</span> 
                    </div>
                </div>
                <div className='w-full flex items-center'>
                    <div className={`text-left leading-6 text-sm font-medium ${isDark? 'text-gray-400' : 'text-gray-400'}`}>
                        Average Cycle Time: <span className={`text-base pl-1 ${isDark? 'text-white' : 'text-gray-600'}`}>{sensor.calibration_insights.insight_mean_cycle_time ? formatTime(sensor.calibration_insights.insight_mean_cycle_time) : ''}</span> 
                    </div>
                </div>
                <div className='w-full flex items-center'>
                    <div className={`text-left leading-6 text-sm font-medium ${isDark? 'text-gray-400' : 'text-gray-400'}`}>
                        Total Uptime: <span className={`text-base pl-1 ${isDark? 'text-white' : 'text-gray-600'}`}>{sensor.calibration_insights.insight_up_time ? formatTime(sensor.calibration_insights.insight_up_time) : ''}</span> 
                    </div>
                </div>
                <div className='w-full flex items-center'>
                    <div className={`text-left leading-6 text-sm font-medium ${isDark? 'text-gray-400' : 'text-gray-400'}`}>
                        Total Downtime: <span className={`text-base pl-1 ${isDark? 'text-white' : 'text-gray-600'}`}>{sensor.calibration_insights.insight_down_time ? formatTime(sensor.calibration_insights.insight_down_time) : ''}</span> 
                    </div>
                </div>
            </div>
        }
        </div>
    </div>
  )
}

export default FinalAnalysisAreaLineChart
