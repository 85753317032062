import React, { useState } from 'react'
import useTable from "../components/hooks/useTable";
import TableFooter from "../components/TableFooter";
import LogsTableRow from './LogsTableRow';
import LinearProgress from '@mui/material/LinearProgress';


function LogsTables({data, rowsPerPage, loadingTable}) {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
    
  return (
    <>
      <div className='flex justify-end'>
            <TableFooter range={range} slice={slice} setPage={setPage} page={page} rowsPerPage={rowsPerPage} data={data} />
      </div>
      <div className=''>
        <table className="table-auto w-full">
          <thead >
              <tr className='border-b-[0.01em] dark:border-slate-600 text-left'>
                  <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3'>{`Log`}</th>
                  <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3 text-center'>Event</th>
                  <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3 text-right'>Date</th>
              </tr>
          </thead>
            <tbody >
            {loadingTable &&
              <tr>
                <td colSpan={3}>
                <LinearProgress color="primary" sx={{height:2}}/>
                </td>
              </tr>
            }
            {slice.map((el,index) => (
              <LogsTableRow el={el} index={index} key={index} />
            ))}
          </tbody>
        </table>
      </div>
      <div className='flex justify-end mt-2'>
            <TableFooter range={range} slice={slice} setPage={setPage} page={page} rowsPerPage={rowsPerPage} data={data} />
      </div>
      
    </>
  )
}

export default LogsTables