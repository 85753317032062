
import HighchartsStock from 'highcharts/highstock';
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from 'react';

function PieChartUtilizationList({downtimePercent, uptimePercent}) {
    const [utilizationPieChartOptions, setUtilizationPieChartOptions] = useState({
        chart: {
            // renderTo: 'container',
            backgroundColor: null,
            height: 76 ,
            width:76,
          },
          time: {
            useUTC: false
          },
          colors: ['rgba(22, 163, 74,1)','rgba(220, 38, 38,1)', 'rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
          // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
          credits: {enabled: false},
          title: {
            text: "",
            verticalAlign: 'top',
            style: { 
              color:'#475569',
              fontSize: '14px',
            } 
          },
          exporting: {
            enabled: false
          },
          series: [{
            type: 'pie',
            innerSize: '55%',
            data: [{
              name: 'Running',
              y: 0,
            },{
                name: 'Stopped',
                y: 0,
            },{
              name: '',
              y: 100,
            }]
          }],
          plotOptions: {
            // series: {
            //   animation: false
            // },
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                size: 60,
                dataLabels: {
                    enabled: false
                },
                borderWidth: 0,
                margin: 0,
            }
          },
          legend: {
            itemDistance:0,
          },
          tooltip: {
            pointFormat: '{point.y}%'
          }
        
    })


    useEffect(() => {
      
      if( uptimePercent || downtimePercent){
        setUtilizationPieChartOptions({
          chart: {
            // renderTo: 'container',
            backgroundColor: null,
            height: 76 ,
            width:76,
          },
          time: {
            useUTC: false
          },
          colors: ['rgba(22, 163, 74,1)','rgba(220, 38, 38,1)', 'rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
          // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
          credits: {enabled: false},
          title: {
            text: "",
            verticalAlign: 'top',
            style: { 
              color:'#475569',
              fontSize: '14px',
            } 
          },
          exporting: {
            enabled: false
          },
          series: [{
            type: 'pie',
            innerSize: '55%',
            data: [{
              name: 'Running',
              y: uptimePercent,
            },{
                name: 'Stopped',
                y: downtimePercent,
            },]
          }],
          plotOptions: {
            // series: {
            //   animation: false
            // },
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                size: 60,
                dataLabels: {
                    enabled: false
                },
                borderWidth: 0,
                margin: 0,
            }
          },
          legend: {
            itemDistance:0,
          },
          tooltip: {
            pointFormat: '{point.y}%'
          }
        })
      }

    }, [uptimePercent, downtimePercent])
    
    return (
        <div>
            <HighchartsReact
                highcharts={HighchartsStock}
                constructorType={'chart'}
                options={utilizationPieChartOptions}
            />
            
        </div>
    )
}

export default PieChartUtilizationList
