import { useEffect, Fragment, useState } from 'react'
import { Popover, Transition, PopoverButton, PopoverPanel } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { motion } from "framer-motion"
import { useLocation, useSearchParams } from 'react-router-dom'
import { globalSelectedOrganization } from '../../atoms/orgStates'
import { useRecoilValue } from 'recoil'
import { getData } from '../../ApiCalls/DataApis'
import { apiLink } from '../../ApiCalls/ApisVariables'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {darkModeState} from '../../atoms/darkModeState'
import Box from '@mui/material/Box';
import '../../styles/staticDatePickerStyles.css'
import moment from 'moment';


const datesList =  ['Yesterday', 'Custom']

function ShiftFilterBtn({select}) {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const isDark = useRecoilValue(darkModeState);
    const [tempSelectedShift, setTempSelectedShift] = useState([])
    const [selectedShift, setSelectedShift] = useState([])
    const [shiftList, setShiftList] = useState([])
    const [selectedDay, setSelectedDay] = useState('Custom');
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation();
    const [shiftStartDate, setShiftStartDate] = useState({
        startDate: new Date(),
    });
    const [dateString, setDateString] = useState('')
    const [shiftEntireDay, setShiftEntireDay] = useState(false)
    const [currentShift, setCurrentShift] = useState([])
    const [currentShiftIndex, setCurrentShiftIndex] = useState(null)
    const [isMobile, setIsMobile] = useState(false)
    
    const maxDate= new Date()

    useEffect(() => {
        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
          setIsMobile(true)
        }else{
          setIsMobile(false)
        }
      },[location])


    useEffect(() => {
        if(selectedOrganization){
            getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/shifts' )
            .then(async response => {
                // JSON data parsed by response.json() call
                if(response.ok ){
                    const data = await response.json();
                    if(data && data.data){
                        // console.log('Shifts',data.data)
                        let shifts = data.data
                        shifts.unshift(
                            // {
                            // shift_name: "",
                            // shift_start_time: ''
                            // },
                            {
                            shift_name: "All Day",
                            shift_start_time: '00:00:00'
                            }
                        )
                        setShiftList(shifts)
                    }
                }
            })    

            getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/shifts/current' )
            .then(async response => {
                // JSON data parsed by response.json() call
                if(response.ok ){
                const data = await response.json();
                    if(data && data.data){
                        // console.log('Current shift',data.data)
                        setCurrentShift(data.data[0])
                    }
                }
            }) 
        }

    },[selectedOrganization])



   useEffect(() => {
    // Gettting search parameters from URL
        let currentSearch = location.search
        if(currentSearch && Object.keys(shiftList).length >0){
            // Pulling all parts search parameters into an array
            let shiftName = searchParams.get('shift_name')
            // let shiftStartTime = searchParams.get('shift_start_time')
            let shiftDate = searchParams.get('shift_date')
            let shiftEntireDay = searchParams.get('shift_entire_day')
            let shiftYesterday = searchParams.get('shift_yesterday')

            if(shiftYesterday){
                setSelectedDay('Yesterday')
            }else{
                setSelectedDay('Custom')
            }

            if(!shiftDate){
                setDateString('')
            }
            
            if(shiftEntireDay){
                setShiftEntireDay(shiftEntireDay)
            }
            
            if(shiftYesterday != 'Yesterday' && shiftDate){
                // let date = new Date(shiftDate)
                setDateString(shiftDate)
                let date = new Date(shiftDate + ' ' + (new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]) );
                setShiftStartDate({startDate: date})
            }
            

            let shiftShown = []
            shiftList.map((shift,index) =>{
                if(shift.shift_name == shiftName){
                    shift.index = index
                    setTempSelectedShift(shift)
                    setSelectedShift(shift)
                    shiftShown = shift
                }
            })
            if(currentShift && Object.keys(currentShift).length > 0 && Object.keys(shiftShown).length === 0){
                shiftList.map((shift,index) =>{
                    let shiftname = currentShift.shift_name
                    if(shiftname ==='Default'){
                        shiftname = 'All Day'
                    }
                    if(shift.shift_name == shiftname){
                        shift.index = index
                        setTempSelectedShift(shift)
                        setSelectedShift(shift)
                    }
                })
            }
        }else{
            if(Object.keys(shiftList).length >0){
                let shiftShown = []
                if(currentShift && Object.keys(currentShift).length > 0 && Object.keys(shiftShown).length === 0){
                    shiftList.map((shift,index) =>{
                        let shiftname = currentShift.shift_name
                        if(shiftname ==='Default'){
                            shiftname = 'All Day'
                        }
                        if(shift.shift_name == shiftname){
                            shift.index = index
                            setTempSelectedShift(shift)
                            setSelectedShift(shift)
                        }
                    })
                    setSelectedDay('Custom')
                }
            }
        }

    }, [location, shiftList, currentShift])

    

    


   const handleChangeShift = (e) => {
        setTempSelectedShift(shiftList[e.target.value])
        // setSelectedShift(shiftList[e.target.value])
        // console.log('shiftList[e.target.value]', shiftList[e.target.value])
        if(shiftList[e.target.value].shift_name == 'All Day'){
            setShiftEntireDay(true)
        }else if(shiftList[e.target.value].shift_name === ''){
            setShiftStartDate({startDate: new Date()})
        }else{
            setShiftEntireDay(false)
        }
    }

    const handleChangeDate = (e, id) => {
        let updatedDates = {
            ...shiftStartDate,
            [id]: e
        }
        
        if(e && e.$d){
            let momentTime = moment(e.$d).format('YYYY-MM-DD')
            setDateString(momentTime)
        }
        
        setShiftStartDate(updatedDates);
    }

    const handleApplyFilter = () => {
        let parts = searchParams.getAll('parts')
        let departments = searchParams.getAll('departments')
        let machine_types = searchParams.getAll('machine_types')
        let tags = searchParams.getAll('tags')
        let group_by = searchParams.getAll('group_by')
        let sort_by = searchParams.getAll('sort_by')
        let calculate_oee_by = searchParams.getAll('calculate_oee_by')
        let maximize = searchParams.getAll('maximize')
        let url_token = searchParams.getAll('url_token')
        let operations_expanded = searchParams.getAll('operations_expanded')
        let assets_expanded = searchParams.getAll('assets_expanded')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')

        let formattedDate = dateString

        let shiftYesterday = false
        if(selectedDay == 'Yesterday'){
            shiftYesterday = true
        }else if(dateString.length == 0){
            setShiftStartDate({startDate: new Date()})
            let currentDate = new Date()
            // Extract year, month, and day
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
            const day = String(currentDate.getDate()).padStart(2, '0');

            // Format the date as YYYY-MM-DD
            formattedDate = `${year}-${month}-${day}`;
        }
    

        if(tempSelectedShift && Object.keys(tempSelectedShift).length > 0){
            setSelectedShift(tempSelectedShift)
            if(tempSelectedShift.shift_name){
                if(selectedDay == 'Yesterday'){
                    setSearchParams({select, parts, departments, machine_types,tags, group_by, sort_by, calculate_oee_by, shift_name: tempSelectedShift.shift_name, shift_start_time:tempSelectedShift.shift_start_time, shift_yesterday: shiftYesterday, shift_entire_day:shiftEntireDay, maximize, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled}) 
                }else{
                    setSearchParams({select, parts, departments, machine_types,tags, group_by, sort_by, calculate_oee_by, shift_name: tempSelectedShift.shift_name, shift_start_time:tempSelectedShift.shift_start_time, shift_date: formattedDate, shift_entire_day:shiftEntireDay, maximize, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled}) 
                }
            }else{
                setSearchParams({select, parts, departments, machine_types, tags, group_by, sort_by, calculate_oee_by, maximize, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled}) 
            }
        }
    }


    const handleChangeDay = (event) => {
        setSelectedDay(event.target.value);
      };
    


  return (
    <div >
        <Popover className="relative ">
        {({ open, close }) => (
          <>
            <PopoverButton
              className={`
                ${open ? 'bg-slate-100 dark:bg-slate-700' : ''}
                border border-blue-600 border-dashed rounded-full flex pl-1 pr-3 py-0.5 items-center outline-none `}
                
            >
                <div className='bg-white w-3 h-3 rounded-full m-1'>
                    <svg xmlns="http://www.w3.org/2000/svg" className={`transition ease-in-out duration-300 ${Object.keys(selectedShift).length > 0? ' rotate-45 ':'rotate-0'} h-5 w-5 ml-[-4px] mt-[-4px] text-blue-600 `} viewBox="0 0 20 20" fill="currentColor" >
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                    </svg>
                </div>
                <div className='pl-1 text-sm text-gray-600 dark:text-gray-300 truncate min-w-fit'>
                    Shift
                </div>
                {Object.keys(selectedShift).length > 0 && selectedShift.shift_name &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                        <div className='flex '>
                            <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{selectedShift.shift_name} {selectedDay == 'Yesterday' ? '- Yesterday' : ''}{dateString}</div>
                        </div>
                    <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                </div>
                }
                
            </PopoverButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel  className="absolute left-40 z-20 mt-3 w-[350px] -translate-x-1/2 transform " >
                <div className={`overflow-scroll scrollbar-hide rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-slate-800 p-3 ${isMobile && 'max-h-[400px] overflow-y-scroll'}`}>
                        <div className='text-sm font-medium text-gray-900 dark:text-gray-300'>Select Shift </div>
                        
                        
                        <div className="flex justify-center items-center">
                            <div className="my-3 w-full outline-none">
                                <select 
                                    defaultValue={selectedShift?.index} 
                                    // defaultValue={currentShiftIndex} 
                                    onChange={handleChangeShift}  
                                    className="form-select form-select-sm shadow-iotflows-lg appearance-none block w-full
                                    px-2 py-1 text-sm font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 bg-clip-padding bg-no-repeat border-1 border-slate-50 dark:border-slate-400 rounded
                                    transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-50 focus:outline-none focus:shadow-blue-300" aria-label=".form-select-sm ">
                                    {shiftList.map((shift, index) => (
                                        <option key={shift.shift_name} value={index}  >{shift.shift_name? shift.shift_name : 'Current Shift'}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {(select == 'overview') ? (
                            <div className="flex justify-center items-center">
                                <div className="my-3 w-full outline-none">
                                    <select 
                                        value={selectedDay}
                                        onChange={handleChangeDay}  
                                        className="form-select form-select-sm shadow-iotflows-lg appearance-none block w-full
                                        px-2 py-1 text-sm font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 bg-clip-padding bg-no-repeat border-1 border-slate-50 dark:border-slate-400 rounded
                                        transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-50 focus:outline-none focus:shadow-blue-300" aria-label=".form-select-sm ">
                                        {datesList.map((day, index) => (
                                            <option key={day} value={day}>{day}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        ):null}
                        {(selectedDay === 'Custom') || (select == 'part') ? (
                            <div className="mt-2 w-full outline-none">
                                <div className='text-sm font-medium text-gray-900 dark:text-gray-300'>Select Date </div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <StaticDatePicker
                                    
                                    orientation="portrait"
                                    // displayStaticWrapperAs="desktop"
                                    openTo="day"
                                    label={'Shift Date'}
                                    id={'startDate'}
                                    minDate={''}
                                    maxDate={maxDate}
                                    inputFormat="MM/DD/YYYY"
                                    value={shiftStartDate.startDate}
                                    onChange={(e) => handleChangeDate(e, 'startDate')}
                                    renderInput={({ inputRef, inputProps, InputProps }) => (
                                    <Box sx={{ display: 'flex-col', alignItems: 'center', svg: { color: isDark? '#4b5563' : "#d1d5db", }, color: `${isDark?'text-gray-300':'text-gray-600'}` }}>
                                        <label className={`block text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`} >
                                            {'Shift Date'}
                                        </label>
                                        <div className='flex items-center'>
                                            <input 
                                            className={` block bg-transparent w-full border rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
                                            ref={inputRef} 
                                            {...inputProps} />
                                            <div className='ml-[-6px]'>{InputProps?.endAdornment}</div>
                                            
                                        </div>
                                        
                                    </Box>
                                    )}
                                    />
                                </LocalizationProvider>
                            </div>
                        ): (
                            <div/>
                        )}
                        
                        <motion.button  
                            whileHover={{ scale: 1.02 }} 
                            whileTap={{ scale: 0.98 }} 
                            onClick={ () => {
                                handleApplyFilter()
                                close()
                            }} 
                            className='w-full text-sm p-1 bg-blue-600 text-white rounded-md' >
                                Apply
                        </motion.button>
                </div>
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default ShiftFilterBtn