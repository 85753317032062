import { PencilIcon } from '@heroicons/react/outline'
import { PlusIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import AddEndpointModal from '../components/modals/webhookModals/AddEndpointModal'
import { globalSelectedOrganization } from '../atoms/orgStates'
import { useRecoilValue } from 'recoil'
import { getData } from '../ApiCalls/DataApis'
import { apiLink } from '../ApiCalls/ApisVariables'
import WebhookRow from './WebhookRow'


function WebhooksPage() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [isMobile, setIsMobile] = useState(false)
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [endPointData, setEndPointData] = useState([])
    const [webhookEvents, setWebhookEvents] = useState([])
    const [testJsonData, setTestJsonData] = useState([])


    useEffect(() => {
        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
    },[])

    useEffect(() => {
        if(selectedOrganization){
            getOrgEndpoints()

            getData(apiLink + '/v1/webhooks_events')
            .then(async response => {
              if(response && response.ok ){
                const data = await response.json();
                if(data && data.data){
                //   console.log('EVENts', data.data)
                  setWebhookEvents(data.data)
                } 
              }
            });
            getData('https://integrations.iotflows.com/v1/tests')
            .then(async response => {
              if(response && response.ok ){
                const data = await response.json();
                if(data && data.data){
                  setTestJsonData(data.data)

                } 
              }
            });

            
        }
    },[selectedOrganization])

    const getOrgEndpoints = () => { 
        if(selectedOrganization){
            getData(apiLink + '/v1/organizations/' + selectedOrganization + '/webhooks')
            .then(async response => {
              if(response && response.ok ){
                const data = await response.json();
                if(data && data.data){
                //   console.log('GET Endpointsdata', data)
                  setEndPointData(data.data)
                } 
              }
            });
        }

    }


  return (
    <div className={`pl-6 pr-6 py-1 ${isMobile ? 'max-h-[calc(100vh-1rem)]' : 'max-h-[calc(100vh-5rem)]'}  mx-auto overflow-scroll scrollbar-hide max-w-screen-2xl	`}>
        <div className='mt-4'>
            <div className='flex justify-between border-b-[0px] border-slate-200 dark:border-slate-700 pb-4 items-center'>
                <span className='text-xl font-bold text-gray-800 dark:text-gray-300'>Hosted endpoints</span>
                <AddEndpointModal btnType={'standardBtn'} getOrgEndpoints={getOrgEndpoints} webhookEvents={webhookEvents}/>
            </div>

            <table className="table-auto w-full ">
                <thead className=''>
                    <tr className='invoiceTableRow' >
                        <td className='invoiceTableCellHeader' colSpan={3}>URL</td>
                    </tr>
                </thead>
                <tbody className='tableBodyContainer'>
                    {endPointData && Object.keys(endPointData).length > 0 &&
                    endPointData.map((endpoint, index) => (
                        <WebhookRow key={endpoint.webhook_uuid} endpoint={endpoint} getOrgEndpoints={getOrgEndpoints} webhookEvents={webhookEvents} testJsonData={testJsonData} selectedOrganization={selectedOrganization}/>
                    ))}
                </tbody>
            </table>

        </div>

    </div>
  )
}

export default WebhooksPage