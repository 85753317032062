import { useEffect, useState } from "react"
import { motion } from "framer-motion";
import { NavLink, useSearchParams } from "react-router-dom";


function SubNav({subNav, selectedSubLink}) {
    const [selectedTab, setSelectedTab] = useState(selectedSubLink);
    const [isMobile, setIsMobile] = useState(false)
    const [showMobileNav, setShowMobileNav] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
      let currentIsMobile = searchParams.get('platform')
      let currentSelect = searchParams.get('select')
      if(currentIsMobile === 'mobile'){
        setIsMobile(true)
        if(currentSelect === 'parts_list' || currentSelect === 'part' || currentSelect === 'historical_report' || currentSelect === 'overview' || currentSelect === 'downtimes' || currentSelect === 'advanced_report' || currentSelect === 'members' || currentSelect === 'teams'  ){
          setShowMobileNav(true)
        }
      }else{
        setIsMobile(false)
        setShowMobileNav(false)
      }
    },[])


    useEffect(() =>{
        setSelectedTab(selectedSubLink)
    },[selectedSubLink])

  

    const gridStyle = (subNavigation) => {
      const items = Object.values(subNavigation);

      

      return {
        display: 'grid',
        gridTemplateColumns: `repeat(${Math.min(items.length, 5)}, 1fr)`, // Adjust columns dynamically
        gap: '0px',
        // padding: '20px',
        // paddingRight: '5px',
        // paddingLeft:  '5px',
      }

    }

  
    
    return (
      <>
        {isMobile ?
        (
          <nav className={` px-2 mb-3`}>
              <div style={gridStyle(subNav)} className={`${showMobileNav && 'hidden'}  border-b-[0.01em] border-blue-300 dark:border-blue-800`}>
              {Object.keys(subNav).map((item) => (
                  <NavLink
                  key={subNav[item].url}
                  to={{
                      pathname: subNav[item].pathName,
                      search: `${subNav[item].search}&platform=mobile`,
                    }}
                  className={`relative pt-2.5 pb-2.5 mx-auto w-fit flex justify-center items-center  `}
                  onClick={() => setSelectedTab(subNav[item])}
                  >
                  <span className={`${subNav[item].url === selectedTab.url ? 'font-medium':' font-base'} flex justify-center items-center text-base text-gray-600 dark:text-gray-100 hover:text-gray-900 dark:hover:text-gray-300 outline-none text-[13px] cursor-pointer bg-transparent whitespace-nowrap`}>
                  {/* <span className={`${subNav[item].url === selectedTab.url ? 'font-medium':' font-base'} text-base mr-1 pb-1 text-gray-600 dark:text-gray-100 hover:text-gray-900 dark:hover:text-gray-300 outline-none text-[13px] cursor-pointer bg-transparent whitespace-nowrap`}> */}
                    {`${subNav[item].name}`}
                  </span>
                  {subNav[item].url === selectedTab.url ? (
                      <motion.div className="underline" layoutId="underline" />
                  ) : null}
                  </NavLink>
              ))}
              </div>
          </nav>

        ):(
          <nav >
              <div className={`flex overflow-x-scroll scrollbar-hide space-x-2 md:space-x-4 border-b border-blue-600 w-fit`}>
              {Object.keys(subNav).map((item) => (
                  <NavLink
                  key={subNav[item].url}
                  to={{
                      pathname: subNav[item].pathName,
                      search: subNav[item].search,
                    }}
                  className={`relative pt-0.5 pb-1.5`}
                  onClick={() => setSelectedTab(subNav[item])}
                  >
                  <span className={`${subNav[item].url === selectedTab.url ? 'font-medium':' font-base'} mr-1 pb-1 text-gray-600 dark:text-gray-100 hover:text-gray-900 dark:hover:text-gray-300 outline-none text-[13px] cursor-pointer bg-transparent whitespace-nowrap`}>
                    {`${subNav[item].name}`}
                  </span>
                  {subNav[item].url === selectedTab.url ? (
                      <motion.div className="underline" layoutId="underline" />
                  ) : null}
                  </NavLink>
              ))}
              </div>
          </nav>

        )}
      </>
    )
}

export default SubNav
