import { Avatar } from '@mui/material'
import React, { useState, Fragment, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Menu, Transition, MenuButton, MenuItems, MenuItem } from '@headlessui/react'
import { getData } from '../../ApiCalls/DataApis'
import { apiLink } from '../../ApiCalls/ApisVariables'
import { motion } from 'framer-motion';
import {  formatTimeOrDate } from '../../utils/DateFormating'
import PinnedMessages from '../messagingComponent/PinnedMessages'
import DeleteChatConfirmationModal from '../../components/modals/messagingModals/DeleteChatConfirmationModal'
import LeaveChatConfirmationModal from '../../components/modals/messagingModals/LeaveChatConfirmationModal'
import { globalSelectedOrganization } from '../../atoms/orgStates'
import { useRecoilValue } from 'recoil'


function MessagesSectionHeader({chatInfo, participants, userInfo, getChatList, checkSearchMessage,checkPinnedMessage, pinnedMessages, setPinnedMessages}) {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const [showSearch, setShowSearch] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [loading, setLoading] = useState(false)
    const [chatImage, setChatImage] = useState(null)
    const [chatColor, setChatColor] = useState('')
    const [chatName, setChatName] = useState(null)
    const [chatSubName, setChatSubName] = useState(null)
    const [messageSearchText, setMessageSearchText] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [closeResults, setCloseResults] = useState(true)
    const [isDeleteChatModalOpen, setIsDeleteChatModalOpen] = useState(false)
    const [isLeaveChatModalOpen, setIsLeaveChatModalOpen] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {

        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
  
    },[])

    const handleNavToInfo = () => {

        let select = searchParams.getAll('select')
        let chatId = searchParams.getAll('chatId')
        let platform = searchParams.getAll('platform')

        setSearchParams({select,chatId, section: 'info', platform})

    }

    

    useEffect(() => {
        if(chatInfo.chat_is_direct ){
            
            
            if(participants &&  Object.keys(participants).length > 0 && userInfo && Object.keys(userInfo).length > 0){
                for (const key in participants) {
                    if (key != userInfo.user_username) {
                        let name = `${participants[key].user_first_name} ${participants[key].user_last_name}`
                        setChatImage(participants[key].user_image_url)
                        setChatName(name)
                        setChatSubName('')
                        setChatColor(participants[key].user_color)
                      break;
                    }
                  }
            }

        }else{
            setChatImage(chatInfo.chat_picture_url)
            setChatName(chatInfo.chat_name) 
            // let memberCount = Object.keys(chatInfo.members).length
            let subNameString = chatInfo.current_members_count + ' members'
            setChatSubName(subNameString)
            setChatColor(chatInfo.chat_color)

            
        }

    },[chatInfo, userInfo])
    

    const handleSearchTextChange = (e) => {
        setMessageSearchText(e.target.value)
        if(e.target.value == ''){
            setSearchResults([])
            setCloseResults(true)
        }else{
            getData(apiLink + '/v1/chatrooms/' + chatInfo.chatroom_uuid + `/search_message?q=${e.target.value}`)
                .then(async response => {
                  if(response.ok ){
                    const data = await response.json();
                    if(data && data.data){
                      setSearchResults(data.data)
                      setCloseResults(false)
                    }
                  }
                })

        }

    }

    const handleCloseSearch = () => {
        setMessageSearchText('')
        setSearchResults([])
        setShowSearch(false)
    }

    const handleSearchClick = (uuid) => {
        checkSearchMessage(uuid)
        setCloseResults(true)
    }

    const handleNavToPinMessage = (uuid) => {
        checkPinnedMessage(uuid)
    }

    const handleCloseDeleteChatModal = () => {
        setIsDeleteChatModalOpen(false)
    }

    const handleCloseLeaveChatModal = () => {
        setIsLeaveChatModalOpen(false)
    }

    const handleNavChatList = () => {
        // let select = searchParams.getAll('select')
        // let platform = searchParams.getAll('platform')
        // setSearchParams({platform})

        navigate(`/${selectedOrganization}/chats?platform=mobile`)
    }
    
   
  return (
    <div className={`relative  flex  z-[50]`}>
    {isMobile ? (
        <div style={{zIndex: 2}} className='relative flex flex-1 items-center bg-white dark:bg-slate-900 px-2 py-1.5 border-b-[0.01em] border-slate-200 dark:border-slate-600 ' >
            <ol className='flex mr-1'>
                <li className='breadcrumbsNav flex items-center px-2' onClick={handleNavChatList} >
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-7 h-7">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
                    </div>
                    {/* <span className='text-sm'>Back</span> */}
                </li>
            </ol>
            <div onClick={handleNavToInfo} className='cursor-pointer'>
                <Avatar
                    sx={{ width: 42, height: 42, bgcolor: chatColor }}
                    style={{
                        background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${chatColor} 100%)`,
                        }}
                    alt={chatName}
                    src={chatImage || 'brokenLink.png'}
                />
            </div>
            <div className='flex flex-col pl-2 cursor-pointer grow truncate' onClick={handleNavToInfo}>
                <span className='text-gray-600 dark:text-gray-300 font-medium text-base truncate'>{chatName}</span>
                <span className='text-gray-500 dark:text-gray-400 text-xs truncate'>{chatSubName}</span>
            </div>
            
            <div className='ml-auto flex space-x-4 relative '>
                <button className='text-gray-500 dark:text-gray-400' onClick={() => setShowSearch(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                    </svg>
                </button>
                {/* <Menu >
                    <MenuButton as="button" className=' text-gray-500 dark:text-gray-400 hover:dark:text-blue-400 ' >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                        </svg>
                    </MenuButton>

                    <Transition
                    as={Fragment}
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <MenuItems className="absolute right-4 top-4 flex flex-col mt-1 w-[200px] bg-white/90 dark:bg-zinc-900/90 backdrop-blur-lg shadow-iotflows-lg dark:shadow-xs-invert rounded-lg outline-none z-[49]">
                            <div className="px-1 py-1">
                                <MenuItem>
                                        <button
                                        onClick={handleNavToInfo}
                                        className={`group text-gray-600 dark:text-gray-100 flex w-full items-center rounded-md px-2 py-2 text-sm
                                        data-[active]:bg-zinc-100 data-[active]:dark:bg-zinc-500/30 data-[active]:text-gray-600 data-[active]:dark:text-gray-200`}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="group-hover:scale-110 group-hover:duration-500 w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                        </svg>

                                        <span className='pl-2'>Info</span>
                                    </button>
                                </MenuItem>
                                {!chatInfo.chat_is_direct && (
                                <MenuItem>
                                    <button
                                        className={`group text-red-600 dark:text-red-400 flex w-full items-center rounded-md px-2 py-2 text-sm
                                        data-[active]:bg-zinc-100 data-[active]:dark:bg-zinc-500/30 data-[active]:text-gray-600 data-[active]:dark:text-gray-200`}
                                        onClick={() => setIsLeaveChatModalOpen(true)}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="group-hover:scale-110 group-hover:duration-500 w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
                                        </svg>
                                        <span className='pl-2'>Leave</span>
                                    </button>
                                </MenuItem>
                                )}
                                {chatInfo.chat_is_direct && (
                                    <MenuItem>
                                        <button
                                            className={`relative group text-red-600 dark:text-red-400 flex w-full items-center rounded-md px-2 py-2 text-sm
                                            data-[active]:bg-zinc-100 data-[active]:dark:bg-zinc-500/30 data-[active]:text-gray-600 data-[active]:dark:text-gray-200`}
                                            onClick={() => setIsDeleteChatModalOpen(true)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="group-hover:scale-110 group-hover:duration-500 w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                            </svg>
                                            <span className='pl-2'>Delete Chat</span>
                                        </button>
                                    </MenuItem>
                                )}
                                {(chatInfo && !chatInfo.chat_is_direct && userInfo && userInfo.user_username && participants && participants[userInfo.user_username] && participants[userInfo.user_username].chatroom_member_is_owner) && (
                                    <MenuItem>
                                        <button
                                            className={`relative group text-red-600 dark:text-red-400 flex w-full items-center rounded-md px-2 py-2 text-sm
                                            data-[active]:bg-zinc-100 data-[active]:dark:bg-zinc-500/30 data-[active]:text-gray-600 data-[active]:dark:text-gray-200`}
                                            onClick={() => setIsDeleteChatModalOpen(true)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="group-hover:scale-110 group-hover:duration-500 w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                            </svg>
                                            <span className='pl-2'>Delete Chat</span>
                                        </button>
                                    </MenuItem>
                                )}
                            </div>
                        </MenuItems>
                    </Transition>
                </Menu> */}
            </div>
            
        </div>
        ):(
            <div style={{zIndex: 2}} className='relative flex flex-1 items-center bg-white dark:bg-slate-900 px-2 py-1.5 border-b-[0.01em] border-slate-200 dark:border-slate-600 ' >
            <div onClick={handleNavToInfo} className='cursor-pointer'>
                <Avatar
                    sx={{ width: 38, height: 38, bgcolor: chatColor }}
                    style={{
                        background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${chatColor} 100%)`,
                      }}
                    alt={chatName}
                    src={chatImage || 'brokenLink.png'}
                />
            </div>
            <div className='flex flex-col pl-2 cursor-pointer grow truncate' onClick={handleNavToInfo}>
                <span className='text-gray-600 dark:text-gray-300 font-medium text-sm truncate'>{chatName}</span>
                <span className='text-gray-500 dark:text-gray-400 text-xs truncate'>{chatSubName}</span>
            </div>
            <div className='ml-auto flex space-x-4 relative '>
                <button className='text-gray-500 dark:text-gray-400' onClick={() => setShowSearch(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                    </svg>
                </button>
                <Menu >
                    <MenuButton as="button" className=' text-gray-500 dark:text-gray-400 hover:dark:text-blue-400 ' >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                        </svg>
                    </MenuButton>

                    <Transition
                    as={Fragment}
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <MenuItems className="absolute right-4 top-4 flex flex-col mt-1 w-[200px] bg-white/90 dark:bg-zinc-900/90 backdrop-blur-lg shadow-iotflows-lg dark:shadow-xs-invert rounded-lg outline-none z-[49]">
                            <div className="px-1 py-1">
                                <MenuItem>
                                        <button
                                        onClick={handleNavToInfo}
                                        className={`group text-gray-600 dark:text-gray-100 flex w-full items-center rounded-md px-2 py-2 text-sm
                                        data-[active]:bg-zinc-100 data-[active]:dark:bg-zinc-500/30 data-[active]:text-gray-600 data-[active]:dark:text-gray-200`}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="group-hover:scale-110 group-hover:duration-500 w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                        </svg>

                                        <span className='pl-2'>Info</span>
                                    </button>
                                </MenuItem>
                                {!chatInfo.chat_is_direct && (
                                <MenuItem>
                                    <button
                                        className={`group text-red-600 dark:text-red-400 flex w-full items-center rounded-md px-2 py-2 text-sm
                                        data-[active]:bg-zinc-100 data-[active]:dark:bg-zinc-500/30 data-[active]:text-gray-600 data-[active]:dark:text-gray-200`}
                                        onClick={() => setIsLeaveChatModalOpen(true)}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="group-hover:scale-110 group-hover:duration-500 w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
                                        </svg>
                                        <span className='pl-2'>Leave</span>
                                    </button>
                                </MenuItem>
                                )}
                                {chatInfo.chat_is_direct && (
                                    <MenuItem>
                                        <button
                                            className={`relative group text-red-600 dark:text-red-400 flex w-full items-center rounded-md px-2 py-2 text-sm
                                            data-[active]:bg-zinc-100 data-[active]:dark:bg-zinc-500/30 data-[active]:text-gray-600 data-[active]:dark:text-gray-200`}
                                            onClick={() => setIsDeleteChatModalOpen(true)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="group-hover:scale-110 group-hover:duration-500 w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                            </svg>
                                            <span className='pl-2'>Delete Chat</span>
                                        </button>
                                    </MenuItem>
                                )}
                                {(chatInfo && !chatInfo.chat_is_direct && userInfo && userInfo.user_username && participants && participants[userInfo.user_username] && participants[userInfo.user_username].chatroom_member_is_owner) && (
                                    <MenuItem>
                                        <button
                                            className={`relative group text-red-600 dark:text-red-400 flex w-full items-center rounded-md px-2 py-2 text-sm
                                            data-[active]:bg-zinc-100 data-[active]:dark:bg-zinc-500/30 data-[active]:text-gray-600 data-[active]:dark:text-gray-200`}
                                            onClick={() => setIsDeleteChatModalOpen(true)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="group-hover:scale-110 group-hover:duration-500 w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                            </svg>
                                            <span className='pl-2'>Delete Chat</span>
                                        </button>
                                    </MenuItem>
                                )}
                            </div>
                        </MenuItems>
                    </Transition>
                </Menu>
            </div>
        </div>

        )}
        <PinnedMessages  showSearch={showSearch} messages={pinnedMessages} setPinnedMessages={setPinnedMessages} handleNavToPinMessage={handleNavToPinMessage} />
        <div style={{zIndex: 1}} className={`absolute top-12 left-0 w-full h-12  transition-all duration-500 ease-in-out ${showSearch ? 'translate-y-0 ' : '-translate-y-full '}`}>
            <div className={`flex flex-1 items-center px-1 pb-2 pt-2  bg-white dark:bg-slate-900 ${showSearch ? 'border-b-[0.01em] border-slate-200 dark:border-slate-600  ' : 'border-0'}  `}>
                <div className='relative rounded-full flex-1'>
                    <div className='absolute inset-y-0 pl-3 flex items-center pointer-events-none'>
                        <svg className={`h-4 w-4 dark:text-gray-300 text-gray-500`}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                    </div>
                    <input className={`bg-gray-50 text-gray-600 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-gray-300 dark:placeholder:text-gray-400
                        block w-full pl-8 text-sm rounded-lg text-ellipsis py-1.5`}
                        value={messageSearchText} 
                        onChange={handleSearchTextChange}
                        onClick={() => setCloseResults(false)} 
                        type="text" 
                        placeholder="Search Messages..."
                    />
                </div>
                <div className='flex items-center w-8 justify-center'>
                    <motion.button 
                        whileHover={{scale: 1.05} } 
                        whileTap={{scale:0.95 }}  
                        className='text-gray-500 dark:text-gray-400'
                        onClick={handleCloseSearch}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    </motion.button>

                </div>
            </div>
            <div className={`flex flex-col bg-slate-100 dark:bg-slate-800 divide-y-[0.01em] dark:divide-slate-600 overflow-y-scroll scrollbar-hide ${searchResults && Object.keys(searchResults).length > 0 ? 'max-h-[500px]': ''}`}>
                
                {!closeResults && searchResults && Object.keys(searchResults).length > 0 &&
                    searchResults.map((result) => (
                        searchMessageRow(result, handleSearchClick)
                    ))
                }
            </div>
        </div>
        {isLeaveChatModalOpen &&
            <LeaveChatConfirmationModal remoteOpenModal={isLeaveChatModalOpen} handleCloseLeaveChatModal={handleCloseLeaveChatModal} chatInfo={chatInfo} getChatList={getChatList} chatName={chatName} userInfo={userInfo} title='chat' />
        }
        {isDeleteChatModalOpen &&
            <DeleteChatConfirmationModal remoteOpenModal={isDeleteChatModalOpen} handleCloseDeleteChatModal={handleCloseDeleteChatModal} chatInfo={chatInfo} getChatList={getChatList} chatName={chatName} title='chat' />
        }

    </div>
  )
}

export default MessagesSectionHeader



const searchMessageRow = (result, handleSearchClick) => {

    let addAttachementIcon = false
    let textShown = ''
    if(result && result.file_uuid ){
        if(result.message_text && result.message_text.length > 0){
            textShown = result.message_text
            addAttachementIcon = false
        }else{
            addAttachementIcon = true
            textShown = result.file_name
        }
    }else if(result && !result.file_uuid && !result.isFileUploading && result.message_text && result.message_text.length > 0){
        textShown = result.message_text
        addAttachementIcon = false
    }

    

    return(
        <div 
            key={result.message_uuid} 
            className='flex w-full overflow-hidden justify-between px-2 pt-1 pb-2 items-center hover:bg-slate-200 dark:hover:bg-slate-900/80 cursor-pointer'
            onClick={() => handleSearchClick(result.message_uuid)}
        >
            <div >
                <Avatar
                    sx={{ width: 32, height: 32,bgcolor: result.user_color? result.user_color : '' }}
                    style={{
                        background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${result.user_color? result.user_color:''} 100%)`,
                      }}
                    alt={result.user_first_name || ''}
                    src={result.user_image_url || ''}
                />
            </div>
            <div className='flex flex-col pl-2 mr-auto truncate'>
                <div className='text-sm text-sky-500'>
                {result.user_first_name}{result.user_last_name ? ` ${result.user_last_name}` : ''}
                </div>
                <div className='flex'>
                    {/* {addAttachementIcon &&
                        <div className='pr-2 text-gray-500 dark:text-gray-400'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
                            </svg>
                        </div>
                    } */}
                    <div className='text-sm text-gray-600 dark:text-gray-300 truncate'>
                    {textShown}
                    </div>
                </div>
            </div>
            <div className='text-xs text-gray-500 dark:text-gray-400'>
            {formatTimeOrDate(result.message_created_at)}
            </div>
    </div>
    )

}