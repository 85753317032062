import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts/highstock';
import HighchartsReact from "highcharts-react-official";
import xrange from 'highcharts/modules/xrange';
import highchartsMore from "highcharts/highcharts-more.js"
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';

highchartsMore(Highcharts);
xrange(Highcharts);

function UptimeBarChartLarge({statusBarData, assetId, barChartCategories, selectedOrganization}) {
    const [roundedBarChartOptions, setRoundedBarChartOptions] = useState([])
    const [chartData, setChartData] = useState([])
    const isDark = useRecoilValue(darkModeState)

    useEffect(() => {

        // console.log('statusBarData', statusBarData)
        // console.log('assetId', assetId)
        // console.log('barChartCategories', barChartCategories)
        // console.log('selectedOrganization', selectedOrganization)
        var chartData = []
        statusBarData.map(barData => {
            chartData.push({
                id:barData.u_start_s,
                x: Math.trunc(barData.u_start_s),
                x2: Math.trunc(barData.u_end_s),
                color: barData.utilization_state_color,
            })
        })

        // console.log('chartData',chartData)
        setChartData(chartData)

        setRoundedBarChartOptions({
            chart: {
                type: 'xrange',
                backgroundColor:'transparent',
                zoomType: 'xy'
                
            },
            time: {
              useUTC: false
            },
            title: null,
            credits: {enabled: false },
            scrollbar: {
                enabled: false
            },
            navigator: {
                enabled: false
            },
            // navigator: {
            //     height: 15
            // },
            xAxis: {
                type: 'datetime',
                labels: {
                    style: {
                        color: '#9ca3af',
                    },
                    // formatter: function() {
                    //     return Highcharts.dateFormat("%H:%M", this.value);
                    // }
                },
                lineColor: '#9ca3af',
                lineWidth: 1,
                tickColor: '#9ca3af',
                tickWidth: 1
            },
            yAxis: {
                title: {
                    text: ''
                },
                categories: [''],
                gridLineColor: isDark? 'rgba(100, 116, 139,0)' : 'rgba(100, 116, 139,0)',
                gridLineWidth: 1
            },
            plotOptions: {
                series: {
                    borderRadius: {
                        radius: '50%'
                    },
                    // borderWidth: 0,
                    // borderColor: 'transparent',
                    // stacking: 'normal',
                    //         allowPointSelect: true,
                    // point: {
                    //     events: {
                    //         select: function (event) {
                    //             // var chart = this.series.chart;
                    //             // // console.log('this', this)
                    //             // console.log('event select', event)
                    //             // var isCummulative = false
                    //             // if (event.accumulate) {
                    //             //     isCummulative = true
                    //             // } else {
                    //             //     isCummulative = false
                    //             // }
                    //             // // console.log('selectedPoints', selectedPoints)
                    //             addRemoveSection('addPoint',event.accumulate, event.target.id)
                    //         },
                    //         unselect: function(event) {
                    //             // var p = this.series.chart.getSelectedPoints();
                    //             // console.log('event unselect', event)
                    //             // p.push(this)
                    //             // console.log('p', p)
                    //             // let selectedIdArray = []
                    //             // if(Object.keys(p).length > 0 ) {
                    //             //     console.log('Object.keys(p).length', Object.keys(p).length)
                    //             //     p.map(point => {
                    //             //         console.log('point', point)
                    //             //         console.log('point destroyed', point.destroyed)
                    //             //         if(point.id && point.id != null){
                    //             //             console.log('point ID', point.id)
                    //             //             selectedIdArray.push(point.id)
                    //             //         }
                    //             //     })
                    //             // }
                    //             addRemoveSection('removePoint',false, event.target.id)
                    //         }
                    //     }
                    // },
                    // dataLabels: {
                    //     enabled: false
                    // },
                    
                    // cursor: 'pointer',
                },
            },
            tooltip: {
                // formatter: function (tooltip) {     
                //     console.log('this', this)
                //     const header = `<span style="color: blue;">${this.points[0].key}</span><br/>`
                  
                //     return header + (tooltip.bodyFormatter(this.points).join(''))
                //   }
            },
            rangeSelector:{
                enabled:false
            },
            // rangeSelector: {
            //     allButtonsEnabled: true,
            //     selected:0,
            //     buttons: [{
            //         type: 'all',
            //         text: 'All'
            //     }, {
            //         type: 'second',
            //         count: 60,
            //         text: '60 Sec',
            //         // dataGrouping: {
            //         //     forced: true,
            //         //     units: [['day', [1]]]
            //         // }
            //     },{
            //         type: 'minute',
            //         count: 30,
            //         text: '30 Min',
            //         // dataGrouping: {
            //         //     forced: true,
            //         //     units: [['day', [1]]]
            //         // }
            //     }, {
            //         type: 'hour',
            //         count: 6,
            //         text: '6 Hours',
            //         // dataGrouping: {
            //         //     forced: true,
            //         //     units: [['week', [1]]]
            //         // }
            //     }, {
            //         type: 'hour',
            //         count: 12,
            //         text: 'Day',
            //         // dataGrouping: {
            //         //     forced: true,
            //         //     units: [['week', [1]]]
            //         // }
            //     }],
            //     buttonTheme: { // styles for the buttons
            //       width: 60,
            //       fill: 'none',
            //       stroke: 'none',
            //       'stroke-width': 0,
            //       r: 8,
            //       style: {
            //         color: '#2563eb',
            //         fontWeight: 'bold'
            //       },
            //       states: {
            //         hover: {
            //         fill: '#94a3b8',
            //         },
            //         select: {
            //           fill: '#2563eb',
            //           style: {
            //             color: 'white'
            //           }
            //         },
            //         disabled: { 
            //           // fill: '#2563eb',
            //           style: {
            //             color: '#d1d5db',
            //           }
            //         }
            //       }
            //     },
            //   inputStyle: {
            //     color: '#9ca3af',
            //     fontWeight: 'bold'
            //   },
            //   labelStyle: {
            //     color: '#6b7280',
            //     fontWeight: 'bold'
            //   },
            //   selected: 0,
            // },
            series: [{
                borderColor: 'transparent',
                // pointWidth: 30,
                // allowPointSelect: true,
                borderWidth: 0,
                data: chartData,
                // data: [{
                //     id:'1',
                //     x: Date.UTC(2022, 10, 21),
                //     x2: Date.UTC(2022, 11, 2),
                //     color: '#4ade80',
                //     borderRadiusTopLeft: '100%',
                //     borderRadiusTopRight: '100%'
                // }, {
                //     id:'2',
                //     x: Date.UTC(2022, 11, 2),
                //     x2: Date.UTC(2022, 11, 5),
                //     color:'#16a34a'
                // }, {
                //     id:'3',
                //     x: Date.UTC(2022, 11, 5),
                //     x2: Date.UTC(2022, 11, 9),
                //     color:'#dc2626'
                // }, {
                //     id:'4',
                //     x: Date.UTC(2022, 11, 9),
                //     x2: Date.UTC(2022, 11, 19),
                //     color:'#94a3b8'
                // },{
                //     id:'5',
                //     x: Date.UTC(2022, 11, 19),
                //     x2: Date.UTC(2022, 12, 2),
                //     color:'#dc2626'
                //     /* partialFill: 0.25 */
                // }, {
                //     id:'6',
                //     x: Date.UTC(2022, 12, 2),
                //     x2: Date.UTC(2022, 12, 15),
                //     color:'#dc2626'
                // }, {
                //     id:'7',
                //     x: Date.UTC(2022, 12, 15),
                //     x2: Date.UTC(2023, 1, 9),
                //     color:'#dc2626'
                // }, {
                //     id:'8',
                //     x: Date.UTC(2023, 1, 9),
                //     x2: Date.UTC(2023, 1, 28),
                //     color:'#dc2626'
                // }, {
                //     id:'9',
                //     x: Date.UTC(2023, 1, 28),
                //     x2: Date.UTC(2023, 2, 5),
                //     color:'#dc2626',
                //     borderRadiusBottomLeft: '100%',
                //     borderRadiusBottomRight: '100%'

                // }],
                dataLabels: {
                    enabled: true
                }
            }]
        })
    },[statusBarData])


  return (
    <div className='mt-10'>
        {chartData && Object.keys(chartData).length > 0 &&
        <HighchartsReact
            constructorType={"stockChart"}
            containerProps={{ style: {height:'200px', maxWidth:'100%'} }} 
            highcharts={Highcharts}
            options={roundedBarChartOptions}               
        />
        }
    </div>
  )
}

export default UptimeBarChartLarge