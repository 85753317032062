import React, { useState, useEffect } from 'react';
import { format, getWeekOfMonth, addMonths, getDay, isLastDayOfMonth,
    lastDayOfMonth, setDate, eachMonthOfInterval, addYears, startOfMonth,
    endOfMonth, subDays, isLastWeekOfMonth, isSameMonth, addWeeks } from 'date-fns';
import { useRecoilValue } from 'recoil';
import { darkModeState } from '../../atoms/darkModeState';  
import { ChevronDownIcon } from '@heroicons/react/solid'      
import CustomRecurrenceSelectors from './CustomRecurrenceSelectors';


const RecurrenceSelector = ({date, setRecurrenceFrequency, setRecurrenceInterval, setRecurrenceDaysOfWeek, setRecurrenceMonthOrder, setRecurrenceEndDate, setRecurrenceEndTime}) => {
  const [selectedDate, setSelectedDate] = useState(new Date()); // Fifth Thursday
  const [adjustedDate, setAdjustedDate] = useState(null);
  const [recurrenceType, setRecurrenceType] = useState('none');
  const [isOpen, setIsOpen] = useState(false);
  const isDark = useRecoilValue(darkModeState);
  const [nextOccurrences, setNextOccurrences] = useState([]);
  const [adjustmentMessage, setAdjustmentMessage] = useState('');
  const [recurrenceOptions, setRecurrenceOptions] = useState([]);

  console.log('date',date )
  useEffect(() => {
      setRecurrenceOptions([])
    if(selectedDate){
        const options = getRecurrenceOptions(selectedDate);
        setRecurrenceOptions(options)
    }
  },[selectedDate])
  

  useEffect(() => {
    if(date){
        setSelectedDate(new Date(date))
    }

  },[date])
// Helper function to find last specific weekday of month
const findLastWeekdayInMonth = (date) => {
    const endOfMonthDate = endOfMonth(date);
    const targetWeekday = getDay(date);
    let currentDate = endOfMonthDate;

    while (getDay(currentDate) !== targetWeekday) {
      currentDate = subDays(currentDate, 1);
    }

    return currentDate;
  };

  // Helper function to find the nth occurrence of a weekday in a month
  const findNthWeekdayInMonth = (date, n) => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const targetDay = getDay(date);
    let count = 0;
    let currentDate = new Date(firstDayOfMonth);
    
    while (currentDate.getMonth() === date.getMonth()) {
      if (getDay(currentDate) === targetDay) {
        count++;
        if (count === n) {
          return new Date(currentDate);
        }
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return null;
  };

  // Helper function to check if it's the last occurrence of this weekday in the month
  const isLastWeekdayOccurrence = (date) => {
    const lastSameWeekday = findLastWeekdayInMonth(date);
    return date.getDate() === lastSameWeekday.getDate();
  };

  // Calculate next occurrences based on pattern
  const calculateNextOccurrences = (baseDate, pattern) => {
    switch (pattern) {
      case 'daily':
        return Array.from({length: 3}, (_, i) => 
          format(addDays(baseDate, i + 1), 'MMMM d, yyyy')
        );
      case 'weekly':
        return Array.from({length: 3}, (_, i) => 
          format(addDays(baseDate, (i + 1) * 7), 'MMMM d, yyyy')
        );
      case 'monthly-fourth':
        return Array.from({length: 3}, (_, i) => {
          const nextMonth = addMonths(baseDate, i + 1);
          const fourthOccurrence = findNthWeekdayInMonth(nextMonth, 4);
          return format(fourthOccurrence, 'MMMM d, yyyy');
        });
      case 'monthly-last':
        return Array.from({length: 3}, (_, i) => {
          const nextMonth = addMonths(baseDate, i + 1);
          const lastOccurrence = findLastWeekdayInMonth(nextMonth);
          return format(lastOccurrence, 'MMMM d, yyyy');
        });
      case 'annually':
        return Array.from({length: 3}, (_, i) => {
          const nextYear = addYears(baseDate, i + 1);
          return format(nextYear, 'MMMM d, yyyy');
        });
      case 'weekday':
        let count = 0;
        let date = new Date(baseDate);
        const weekdayOccurrences = [];
        while (weekdayOccurrences.length < 3) {
          date = addDays(date, 1);
          if (getDay(date) !== 0 && getDay(date) !== 6) {
            weekdayOccurrences.push(format(date, 'MMMM d, yyyy'));
          }
        }
        return weekdayOccurrences;
      default:
        return [];
    }
  };

  // Helper function to add days to a date
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };



  
  
  // Generate recurrence options based on the selected date
  const getRecurrenceOptions = (selectedDate) => {
      const weekday = format(selectedDate, 'EEEE'); // e.g., Tuesday
      const month = format(selectedDate, 'MMMM'); // e.g., December
      const day = format(selectedDate, 'd'); // e.g., 3
      
      const recurrenceDay = getDay(selectedDate); // Numeric day of the week (0 = Sunday, 6 = Saturday)
  
    const options = [
      { value: 'none', label: 'Does not repeat' },
      { value: 'daily', label: 'Daily', frequency: 'DAILY', interval: 1, reccurenceDaysOfWeek: null, recurrenceMonthOrder:null },
      { value: 'weekly', label: `Weekly on ${weekday}`, frequency: 'WEEKLY', interval: 1, reccurenceDaysOfWeek: [recurrenceDay], recurrenceMonthOrder:null },
      { value: 'weekday', label: 'Every Weekday (Monday to Friday)', frequency: 'WEEKLY', interval: 1, reccurenceDaysOfWeek: [1, 2, 3, 4, 5], recurrenceMonthOrder:null },
      { value: 'annually', label: `Annually on ${month} ${day}`, frequency: 'YEARLY', interval: 1, reccurenceDaysOfWeek: null, recurrenceMonthOrder:null },
    ];
  
    // Count occurrences of this weekday in the month
    const monthStart = startOfMonth(selectedDate);
    const monthEnd = endOfMonth(selectedDate);
  
    let currentDate = monthStart;
    let weekdayOccurrences = 0; // Total occurrences of the weekday in the month
    let specificWeekOccurrence = 0; // Specific occurrence for the selected date
  
    while (currentDate <= monthEnd) {
      if (getDay(currentDate) === recurrenceDay) {
        weekdayOccurrences++;
        if (currentDate.getDate() === selectedDate.getDate()) {
          specificWeekOccurrence = weekdayOccurrences;
        }
      }
      currentDate = addDays(currentDate, 1);
    }
  
    // console.log('Weekday Occurrences:', weekdayOccurrences);
    // console.log('Specific Week Occurrence:', specificWeekOccurrence);
    // console.log('ordinal(specificWeekOccurrence)', ordinal(specificWeekOccurrence));
  
    // Add recurrence options based on weekday occurrences
    if (specificWeekOccurrence === 1) {
      // Only one occurrence of the weekday
      options.splice(2, 0, {
        value: 'monthly-first',
        label: `Monthly on the first ${weekday}`,
        frequency: 'MONTHLY',
        interval: 1,
        reccurenceDaysOfWeek: null,
        recurrenceMonthOrder: 'FIRST',
      });
    } else if (specificWeekOccurrence > 1 && specificWeekOccurrence < 4) {
      // Specific 2nd and 3rd occurrence (e.g., second, third)
      options.splice(2, 0, {
        value: `monthly-${specificWeekOccurrence}`,
        label: `Monthly on the ${ordinal(specificWeekOccurrence)} ${weekday}`,
        frequency: 'MONTHLY',
        interval: 1,
        reccurenceDaysOfWeek: null,
        recurrenceMonthOrder: getOrdinalText(specificWeekOccurrence),
      });
    } else if (specificWeekOccurrence > 3 && specificWeekOccurrence < 5) {
        // Specific 4th occurrence 
        options.splice(2, 0, {
          value: `monthly-${specificWeekOccurrence}`,
          label: `Monthly on the ${ordinal(specificWeekOccurrence)} ${weekday}`,
          frequency: 'MONTHLY',
          interval: 1,
          reccurenceDaysOfWeek: null,
          recurrenceMonthOrder: getOrdinalText(specificWeekOccurrence),
        });
        options.splice(2, 0, {
            value: 'monthly-last',
            label: `Monthly on the last ${weekday}`,
            frequency: 'MONTHLY',
            interval: 1,
            reccurenceDaysOfWeek: null,
            recurrenceMonthOrder: 'LAST',
          });
    } else if (specificWeekOccurrence === 5) {
        options.splice(2, 0, {
            value: `monthly-fourth`,
            label: `Monthly on the 4th ${weekday}`,
            frequency: 'MONTHLY',
            interval: 1,
            reccurenceDaysOfWeek: null,
            recurrenceMonthOrder: 'FORTH',
          });
        // Last occurrence
        options.splice(2, 0, {
            value: 'monthly-last',
            label: `Monthly on the last ${weekday}`,
            frequency: 'MONTHLY',
            interval: 1,
            reccurenceDaysOfWeek: null,
            recurrenceMonthOrder: 'LAST',
        });
    }
   
    options.push({ 
        value: 'custom', 
        label: 'Custom',
        frequency: null,
        interval: null,
        reccurenceDaysOfWeek: null,
        recurrenceMonthOrder: null,
    });
  
    return options;
  };
  
  // Helper function for ordinal suffixes
  function ordinal(n) {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  }

  const getOrdinalText = (number) => {
    switch (number) {
      case 1:
        return 'FIRST';
      case 2:
        return 'SECOND';
      case 3:
        return 'THIRD';
      case 4:
        return 'FOURTH';
      default:
        console.error('Invalid input: number must be 1, 2, 3, or 4');
        return null;
    }
  };


  // Update next occurrences when recurrence type changes
  useEffect(() => {
    if (recurrenceType === 'none') {
      setNextOccurrences([]);
      return;
    }

    const occurrences = calculateNextOccurrences(selectedDate, recurrenceType);
    setNextOccurrences(occurrences);
  }, [recurrenceType, selectedDate]);

  
  
  

  return (
    <div className={``}>
      <div >
        <label className={`block text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`} htmlFor={'workOrderDescription'}>
            Recurrence Pattern
          </label>
        <div className="relative ">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className={`w-full flex justify-between px-4 py-2 text-left text-sm ${isDark?'text-gray-200 bg-slate-600 border-slate-400':'text-gray-700 bg-white border-slate-50'} shadow-iotflows-lg rounded-lg focus:outline-none focus:ring-[1px] focus:ring-blue-500`}
          >
            {recurrenceType === 'none' 
              ? 'Does not repeat' 
              : recurrenceOptions.find(opt => opt.value === recurrenceType)?.label}

            <ChevronDownIcon className={`h-5 w-5 transition-transform duration-300 ${isOpen ? '':'rotate-180'}`} />
          </button>

          {isOpen && (
            <div className={`absolute z-10 w-full mt-1 p-1 space-y-1 ${isDark?'text-gray-200 bg-slate-600 border-slate-400':'text-gray-700 bg-white border-slate-50'} rounded-xl shadow-iotflows-lg`}>
              {recurrenceOptions.map((option) => (
                <button
                  key={option.value}
                  className={`w-full text-left px-2 py-1 text-sm ${isDark?'hover:bg-gray-700':'hover:bg-gray-100'} rounded-md`} 
                  onClick={() => {
                    setRecurrenceType(option.value);
                    setIsOpen(false);
                    if(option.value === 'none'){
                        setRecurrenceFrequency(null) 
                        setRecurrenceInterval(null) 
                        setRecurrenceDaysOfWeek(null) 
                        setRecurrenceMonthOrder(null) 
                    }else{
                        setRecurrenceFrequency(option.frequency) 
                        setRecurrenceInterval(option.interval) 
                        setRecurrenceDaysOfWeek(option.reccurenceDaysOfWeek) 
                        setRecurrenceMonthOrder(option.recurrenceMonthOrder) 
                    }
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>
          )}
        </div>

        {nextOccurrences.length > 0 && (
          <div className={`mt-2 p-4 ${isDark?'bg-slate-700':'bg-slate-50'} rounded-lg`}>
            <h3 className={`text-sm font-medium ${isDark?'text-white':'text-gray-900'} mb-2`}>
              Next 3 occurrences:
            </h3>
            <ul className="space-y-1">
              {nextOccurrences.map((date, index) => (
                <li key={index} className={`text-sm ${isDark?'text-gray-300':' text-gray-600'}`}>
                  {date}
                </li>
              ))}
            </ul>
          </div>
        )}

        {recurrenceType === 'custom' && (
          <div className={`mt-4 p-4 ${isDark?'bg-slate-700 text-gray-300':'bg-white text-gray-500'} rounded-lg shadow-iotflows-lg`}>
            
              <CustomRecurrenceSelectors initialDate={selectedDate} setRecurrenceFrequency={setRecurrenceFrequency} setRecurrenceInterval={setRecurrenceInterval} setRecurrenceDaysOfWeek={setRecurrenceDaysOfWeek} setRecurrenceMonthOrder={setRecurrenceMonthOrder} setRecurrenceEndDate={setRecurrenceEndDate} setRecurrenceEndTime={setRecurrenceEndTime}/>
            
          </div>
        )}
      </div>
    </div>
  );
};

export default RecurrenceSelector;

