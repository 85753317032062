import SubNav from "../components/SubNav"
import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {globalSelectedOrganization} from '../atoms/orgStates'
import MembersPage from "./MembersPage";
import TeamsPage from "./TeamsPage";


function DirectoryPage({userInfo}) {
    let location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedSubLink, setSelectedSubLink] = useState(null) 
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [isMobile, setIsMobile] = useState(false)

    const subNav = {
        members : {
            name:'Members',
            pathName:`/${selectedOrganization}/members`,
            search:'?select=members',
            url:'members',
        },
        teams: {
            name:'Teams',
            // name:'Part',
            pathName:`/${selectedOrganization}/members`,
            search:'?select=teams',
            url:'teams',
        },
        
    }

    useEffect(() => {
      let currentIsMobile = searchParams.get('platform')
      if(currentIsMobile === 'mobile'){
        setIsMobile(true)
      }else{
        setIsMobile(false)
      }
    },[])

    useEffect(() => {
        if(selectedOrganization) {
            setSelectedSubLink(subNav['members'])
        }

    }, [selectedOrganization])
    
    useEffect(() => {
        // let currentPath = location.pathname.split('/')
        let currentSearch = location.search
        if(currentSearch){
            let queryParams = new URLSearchParams(currentSearch)
            let selected = queryParams.get("select")
            console.log('selected', selected)
            if(selected){
                setSelectedSubLink(subNav[selected])
            }
        }
    }, [location, selectedOrganization])
    
    
    return (
        <div className={` ${isMobile ? 'max-h-[calc(100vh-0.5rem)]' : ' max-h-[calc(100vh-5rem)]'} overflow-scroll scrollbar-hide`}>
            <div className={` ${isMobile ? '' : 'py-1 pl-6 pr-3 '} `}>
                {/* nagivations links within assets page */}
                {selectedOrganization && selectedSubLink && <SubNav subNav={subNav} selectedSubLink={selectedSubLink} />}
            </div>
            <div className={` py-1 pl-6 pr-3`}>
                {selectedOrganization && selectedSubLink && subLinkPage(selectedSubLink, userInfo) }

            </div>

            
        </div>
    )
}

export default DirectoryPage

const subLinkPage = (link, userInfo) => {
    let linkUrl = link.url
    const allLinks = {
        members: { link: "members", content: <MembersPage /> },
        teams: { link: "teams", content: <TeamsPage userInfo={userInfo} /> },
    };
   return (
    <AnimatePresence mode='wait'>
        <motion.div key={allLinks[linkUrl].link}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
           
          {allLinks[linkUrl].content} 
        </motion.div>
      </AnimatePresence>
   )
            
}