import { useEffect, useState } from 'react'
import HighchartsStock from 'highcharts/highstock';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
// import {data} from '../../MyOverviewPage/testData.js'

highchartsMore(Highcharts)


function LineChartComparison({chartData}) {
    const [chartOptions, setChartOptions] = useState({})
    const isDark = useRecoilValue(darkModeState);

      useEffect(() => {
        if(chartData){
            // console.log('chartData', chartData)
            let categories = []
            if(chartData.categories && Object.keys(chartData.categories).length > 0){
                categories = chartData.categories
            }
            let currentPeriod = []
            if(chartData.this_period && Object.keys(chartData.this_period).length > 0){
                chartData.this_period.map(period => {
                    let runtime = 0
                    if(period.runtime_h){
                        runtime = +period.runtime_h
                        if(runtime.toFixed(2)){
                            runtime = +runtime.toFixed(2)
                        }
                    }
                    let dataPoint = {y:runtime, custom: { extraInformation: formatDate(period.date)}}
                    currentPeriod.push(dataPoint)
                })
            }

            let lastPeriod = []
            if(chartData.last_period && Object.keys(chartData.last_period).length > 0){
                chartData.last_period.map(period => {
                    let runtime = 0
                    if(period.runtime_h){
                        runtime = +period.runtime_h
                        if(runtime.toFixed(2)){
                            runtime = +runtime.toFixed(2)
                        }
                    }
                    let dataPoint = {y:runtime, custom: { extraInformation: formatDate(period.date)}}
                    lastPeriod.push(dataPoint)
                })
            }
            setChartOptions({
                credits: { enabled: false},
                chart: {
                    // zoomType: 'x',
                    height: '350px',
                    backgroundColor: 'transparent',
                    // type:'spline'
                },
                time: {
                    useUTC: false
                  },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: categories,
                    accessibility: {
                        description: 'Days of the week'
                    },
                    min:0,
                    tickInterval:1,
                    maxPadding:0,
                    endOnTick:false,
                    startOnTick:false,
                    labels: {
                        style: {
                            color: isDark? '#d1d5db': '#4b5563'
                        }
                    }
                },
                yAxis: {
                    lineWidth: 0,
                    minorGridLineWidth: 0,
                    lineColor: 'transparent',
                    title: '', 
                    labels: {
                        style: {
                            color: isDark? '#d1d5db':'#4b5563'
                        }
                    },
                    minorTickLength: 0,
                    tickLength: 0,
                    gridLineWidth: 0,
                },
                legend: { enabled: false},
                navigator : { enabled : false},
                scrollbar : { enabled : false},
                plotOptions: {
                    // spline: {
                    //     marker: {
                    //         radius: 4,
                    //         lineColor: '#666666',
                    //         lineWidth: 1
                    //     }
                    // },
                    areaspline: {
                        fillOpacity: 0.5,
                        marker: {
                            radius: 4,
                            // lineColor: '#666666',
                            lineWidth: 1
                        }
                    }
                },
                series: [{
                    data: currentPeriod,
                    // color: isDark ? '#2563eb':'#3b82f6',
                    type: 'areaspline',
                    // fillOpacity: 1,
                },{
                    data: lastPeriod,
                    color: isDark ? '#71717a':'#a1a1aa',
                    // dashStyle: 'longdash',
                    type: 'areaspline',
                }
                // ,{
                //     data: chartData3,
                //     color: isDark ? '#93c5fd':'#3b82f6',
                //     dashStyle: 'shortdot'
                // }
            ],
                tooltip: {
                    crosshairs: true,
                    formatter: function () {
                        // console.log(this)
                        return this.points.reduce(function (s, point) {
                            // console.log('point', point)
                            // console.log('point, point', point.point.custom.extraInformation)
                            // console.log('series', point.series)
                            return s + '<br/><span style="color:' + point.color + '">\u25CF</span>'+ ' ' + point.point.custom.extraInformation + ': ' + point.y + 'h';
                        }, '<b>' + this.x + '</b>' );
                    },
                    shared: true
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        // chartOptions: {
                        //     yAxis: {
                        //         labels: {
                        //             formatter: function () {
                        //                 return this.value.charAt(0);
                        //             }
                        //         }
                        //     }
                        // }
                    }]
                }
            })
        }

      }, [chartData, isDark])

    const formatDate = (currentDate) => {
        if(currentDate){
            // currentDate = currentDate.replace("Z" , "")
            let date = new Date(currentDate)
            date = date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear().toString().substr(-2)
            return date
        }
    }

  return (
    <div>
        <HighchartsReact
            highcharts={Highcharts}
            constructorType={'chart'}
            // constructorType={'stockChart'}
            options={chartOptions}
        />
    </div>
  )
}

export default LineChartComparison