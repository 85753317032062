import { Dialog, Transition, DialogPanel, TransitionChild, DialogTitle } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { PlusSmIcon } from "@heroicons/react/solid"
import { useRecoilState, useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { motion, AnimatePresence } from "framer-motion";
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import { getData, postData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import { SearchIcon } from '@heroicons/react/outline';
import ImageZoomCrop from '../ImageZoomCrop';
import { Avatar } from '@mui/material';
import { Calendar } from 'react-date-range'
import RecurrenceSelector from '../../workOrderComponents/RecurrenceSelector';
import OptimizeImage from '../../OptimizeImage';
import { convertTo24HourFormat, formatCalendarDate } from '../../../utils/DateFormating';
import WorkOrderImageUploadSection from './WorkOrderImageUploadSection';
import WOFileUploadSection from './WOFileUploadSection';


function NewWorkOrderModal({btnType, remoteOpenAssetModal,userInfo, getWorkOrders }) {
    let [isOpen, setIsOpen] = useState(false)
    const isDark = useRecoilValue(darkModeState);
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const [loading, setLoading] = useState(false)
    const [workOrderTitle, setWorkOrderTitle] = useState('')
    const [workOrderDescription, setWorkOrderDescription] = useState('')
    const [selectedMembers, setSelectedMembers] = useState({}) 
    const [usersList, setUsersList] = useState([])
    const [teamsList, setTeamsList] = useState([])
    const [filteredMemberList, setFilteredMemberList] = useState([])
    const [filteredTeamsList, setFilteredTeamsList] = useState([])
    const [searchMemberText, setSearchMemberText] = useState('')
    const [isHighlighted, setIsHighlighted] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const inputContainerRef = useRef(null);
    const inputRef = useRef(null);
    const dueDateDropdownRef = useRef(null);
    const startDateDropdownRef = useRef(null);
    const [showDueDateCalendar, setShowDueDateCalendar] = useState(false)
    const [showStartDateCalendar, setShowStartDateCalendar] = useState(false)
    const [dueDate, setDueDate] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [dueDateTime, setDueDateTime] = useState('9:00 AM')
    const [startDateTime, setStartDateTime] = useState('9:00 AM')
    const [isAssetDropdownOpen, setIsAssetDropdownOpen] = useState(false)
    const [selectedAsset, setSelectedAsset] = useState(null)
    const [searchAssetText, setSearchAssetText] = useState("")
    const assetDropdownRef = useRef(null)
    const [assetList, setAssetList] = useState([])
    const [filteredAssetList, setFilteredAssetList] = useState([])
    const [priorities, setPriorities] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState([]);
    const [recurrenceFrequency, setRecurrenceFrequency] = useState(null);
    const [recurrenceInterval, setRecurrenceInterval] = useState(null);
    const [recurrenceDaysOfWeek, setRecurrenceDaysOfWeek] = useState(null);
    const [recurrenceMonthOrder, setRecurrenceMonthOrder] = useState(null); //FIRST, SECOND, THIRD, FOURTH, LAST
    const [recurrenceEndDate, setRecurrenceEndDate] = useState(null); 
    const [recurrenceEndTime, setRecurrenceEndTime] = useState(null); 
  

    const [imagesUuid, setImageUuid] = useState([])
    const [filesUuid, setFilesUuid] = useState([])
    
    

    

    useEffect(() => {
      if(selectedOrganization ){
        getData(apiLink + '/v1/workorder_priorities' )
        .then(async response => {
            // JSON data parsed by response.json() call
            if(response.ok ){
            const data = await response.json();
                if(data && data.data){
                  console.log('data.data', data.data)
                  setPriorities(data.data)
                  setSelectedPriority(data.data[0])
                }
            }
        })
        getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/organization_assets' )
        .then(async response => {
            // JSON data parsed by response.json() call
            if(response.ok ){
            const data = await response.json();
                if(data && data.data){
                    setAssetList(data.data)
                    setFilteredAssetList(data.data)
                }
            }
        })

      }

    }, [selectedOrganization])

  

    const handleDueDateTimeChange = (newTime) => {
      setDueDateTime(newTime)
    }
    const handleStartDateTimeChange = (newTime) => {
      setStartDateTime(newTime)
    }


    const timeOptions = Array.from({ length: 48 }, (_, i) => {
      let hour = Math.floor(i / 2)
      const minute = i % 2 === 0 ? '00' : '30'
      const amPm = hour < 12 ? 'AM' : 'PM'
      
      // Convert to 12-hour format
      hour = hour % 12
      // Handle midnight and noon special cases
      hour = hour === 0 ? 12 : hour
      
      return `${hour}:${minute} ${amPm}`
    })

    const handleSelectDueDate = (selectedDate) => {
      setDueDate(selectedDate)
      setShowDueDateCalendar(false)
    }

    const handleSelectStartDate = (selectedDate) => {
      setStartDate(selectedDate)
      setShowStartDateCalendar(false)
    }

    useEffect(() => {
      if(remoteOpenAssetModal){
        setIsOpen(true)
      }
    },[remoteOpenAssetModal])
    

    function closeModal() {
      setIsOpen(false)
      setWorkOrderTitle('')
      setWorkOrderDescription('')
      setSelectedMembers({})
      setRecurrenceFrequency(null)
      setRecurrenceInterval(null)
      setRecurrenceDaysOfWeek(null)
      setRecurrenceMonthOrder(null)
      setRecurrenceEndDate(null)
      setRecurrenceEndTime(null)
    }

    function openModal() {
      setIsOpen(true)
    }


    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dueDateDropdownRef.current && !dueDateDropdownRef.current.contains(event.target)) {
          setShowDueDateCalendar(false)
        }
      }
  
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [])
    
    
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (startDateDropdownRef.current && !startDateDropdownRef.current.contains(event.target)) {
          setShowStartDateCalendar(false)
        }
      }
  
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [])


    const extractOrganizationHandles = (objects) => {
      return Object.entries(objects)
        .map(([key, obj]) => obj.team_organization_handle || obj.user_organization_handle)
        .filter(Boolean); // Remove undefined/null values
    };



  useEffect(() => {
        if(selectedOrganization && userInfo && Object.keys(userInfo).length > 0){
            updatedMemberList()
            updatedTeamsList()
        }
  },[selectedOrganization, userInfo])

const updatedMemberList = () => {
    try {

        if(selectedOrganization ){
        //   getData(apiLink + '/v1/organizations/' + selectedOrganization + '/user_contacts')
          getData(apiLink + '/v1/organizations/' + selectedOrganization + '/members')
          .then(async response => {
            if(response.ok ){
              const data = await response.json();
              if(data && data.data){

                const updatedMembers = removeUsersFromMembers(data.data, userInfo);

                setUsersList(updatedMembers)

              }
            }
          }) 
        }
      } catch (error) {
        console.log(error.message)
      }
}

const updatedTeamsList = () => {
  try {
      if(selectedOrganization){
        getData(apiLink + '/v1/organizations/' + selectedOrganization + '/teams')
        .then(async response => {
          if(response.ok ){
            const data = await response.json();
            if(data && data.data){
              setTeamsList(data.data)
            }
          }
        }) 
      }
    } catch (error) {
      console.log(error.message)
    }
}

function removeUsersFromMembers(members, usersToRemove) {
    // Create an array of usernames to remove for easy lookup
    const usernamesToRemove = usersToRemove.user_username;
    // const usernamesToRemove = usersToRemove.map(user => user.user_username);
    
    // Filter out members whose username is in the usernamesToRemove array
    return members.filter(member => !usernamesToRemove.includes(member.user_username));
  }
  

useEffect(() => {
    if(searchMemberText  && searchMemberText.length > 0){
      const filtered = usersList.filter(member => {
        let fullName = member.user_first_name + ' ' + member.user_last_name
        if(member.user_username.toLowerCase().includes(searchMemberText.toLowerCase()) || fullName.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_first_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_last_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_username_public.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_email.toLowerCase().includes(searchMemberText.toLowerCase())){
          return member
        }
      })
      setFilteredMemberList(filtered)

      const filteredTeam = teamsList.filter(team => {
        if(team.team_name.toLowerCase().includes(searchMemberText.toLowerCase()) || team.team_organization_handle.toLowerCase().includes(searchMemberText.toLowerCase()) || team.team_handle.toLowerCase().includes(searchMemberText.toLowerCase())){
          return team
        }
      })
      setFilteredTeamsList(filteredTeam)

    }else{
      setFilteredMemberList([])
      setFilteredTeamsList([])
    //   setFilteredAllIoTFlowsMemberList([])
    }
  },[searchMemberText])

  const handleAddUser = (e, user, team) => {
    setSearchMemberText('')

    let itemId = ''

    if(user && Object.keys(user).length > 0 ){
      itemId = user.user_username
    }else if(team && Object.keys(team).length > 0){
      itemId = team.team_handle
    }else return

    const newSelectedItems = {...selectedMembers}; // Copy the existing array


    
    const index = Object.keys(newSelectedItems).indexOf(itemId);
    if (index === -1) {
      if(user && Object.keys(user).length > 0 ){
        newSelectedItems[itemId] = user;
      }else if(team && Object.keys(team).length > 0){
        newSelectedItems[itemId] = team;
      }
        // Item not found, add it to the array
        // newSelectedItems.push(itemId);
    } else {
        // Item found, remove it from the array
        // newSelectedItems.splice(index, 1);
        delete newSelectedItems[itemId]
    }
    let count = Object.keys(newSelectedItems).length
    setSelectedMembers(newSelectedItems);
    inputRef.current?.focus();

  }
 

  const handleRemoveUser = (e) => {
    const key = e.key;
    if ((key === 'Backspace' || key === 'Delete') && !e.ctrlKey && !e.metaKey) {
        if(searchMemberText.length === 0){

            if (isHighlighted) {
               // Call delete function with component reference
            //    let newMembers = selectedMembers.filter((element) => element !== isHighlighted);
            //    setSelectedMembers(newMembers)

               let newSelectedItems = {...selectedMembers}; // Copy the existing array
                delete newSelectedItems[isHighlighted]

                setSelectedMembers(newSelectedItems)
                setIsHighlighted(null); // Reset highlight if unrelated key is pressed
            } else {
                const keys = Object.keys(selectedMembers);
                const lastKey = keys[keys.length - 1];
            //   const lastValue = selectedMembers[selectedMembers.length - 1];
                setIsHighlighted(lastKey);
              
            }
            e.preventDefault(); // Prevent default backspace/delete behavior
          } else {
            setIsHighlighted(null); // Reset highlight if unrelated key is pressed
          }
    }else if (key === 'Tab' || key === 'Enter') {
      setIsDropdownOpen(false);
      setSearchMemberText('')
      inputRef.current?.blur(); // Lose focus on the input

  }
  };

  const removeMember = (member) => {
    if(member){
        let newSelectedItems = {...selectedMembers}; // Copy the existing array
        
        delete newSelectedItems[member]

        setSelectedMembers(newSelectedItems)
    }
}


  const handleSearchMemberText = (e) => {
    let text= e.target.value.replace(/,/g, '')
      setSearchMemberText(e.target.value.replace(/,/g, ''))
      if(text.length > 0){
        setIsDropdownOpen(true)
      }
  }

  const handleInputClick = () => {
      setIsDropdownOpen((prev) => !prev);
  };



   // Close dropdown if clicked outside
   const handleClickOutside = (event) => {
        if (inputContainerRef.current && !inputContainerRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
            setSearchMemberText('')
        }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, []);





useEffect(() => {
  if(searchAssetText  && searchAssetText.length > 0){
    const filtered = assetList.filter(asset => {
      // Check if asset is not null or undefined
      if (!asset) return false;

      // Check each property before calling toLowerCase()
      const customName = asset.asset_custom_name ?? '';
      const customIdentifier = asset.asset_custom_identifier ?? '';
      const machineName = asset.machine_name ?? '';
      const machineIdentifier = asset.machine_identifier ?? '';

      // Convert to lowercase and check for inclusion
      return (
        customName.toLowerCase().includes(searchAssetText.toLowerCase()) ||
        customIdentifier.toLowerCase().includes(searchAssetText.toLowerCase()) ||
        machineName.toLowerCase().includes(searchAssetText.toLowerCase()) ||
        machineIdentifier.toLowerCase().includes(searchAssetText.toLowerCase())
      );
    });
    setFilteredAssetList(filtered)


  }else{
    setFilteredAssetList(assetList)
  }
},[searchAssetText])

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (assetDropdownRef.current && !assetDropdownRef.current.contains(event.target)) {
        setIsAssetDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // Handle asset selection
  const handleSelectAsset = (asset) => {
    setSelectedAsset(asset)
    // setSearchAssetText(asset.label)
    setIsAssetDropdownOpen(false)
  }



  const submitNewWorkOrder = () => {
    try {
      if(!loading){
        setLoading(true)
        

        let assetUuid = null
        if(selectedAsset && selectedAsset.asset_uuid){
          assetUuid = selectedAsset.asset_uuid
        }

        let assignee_handles = []
        if(selectedMembers && Object.keys(selectedMembers).length > 0){
          assignee_handles = extractOrganizationHandles(selectedMembers);
        }

        let due_date = null
        let due_date_time = null
        if(dueDate){
          const originalDate = new Date(dueDate);
          due_date = originalDate.toISOString().split('T')[0];
          due_date_time = convertTo24HourFormat(dueDateTime)
        }


        let start_date = null
        let start_date_time = null
        if(startDate){
          const originalDate = new Date(startDate);
          start_date = originalDate.toISOString().split('T')[0];
          start_date_time = convertTo24HourFormat(startDateTime)
        }

        let recurrence_end_date = null
        let recurrence_end_time = null
        if(recurrenceEndDate){
          const originalDate = new Date(recurrenceEndDate);
          recurrence_end_date = originalDate.toISOString().split('T')[0];
          recurrence_end_time = convertTo24HourFormat(recurrenceEndTime)
        }

        let file_uuids = null
        console.log('filesUuid.length', filesUuid.length)
        console.log('imagesUuid.length', imagesUuid.length)
        if(filesUuid.length > 0 || imagesUuid.length > 0){
          file_uuids = [...filesUuid, ...imagesUuid]
        }
        console.log('file_uuids', file_uuids)

        postData(apiLink + '/v1/organizations/' + selectedOrganization + '/workorders', 
            {
                'parent_workorder_uuid': null,
                "workorder_title": workOrderTitle,
                "workorder_description": workOrderDescription,
                "workorder_asset_uuid": assetUuid,
                "workorder_due_date": due_date,
                "workorder_due_time": due_date_time,
                "workorder_start_date": start_date,
                "workorder_start_time": start_date_time,
                "workorder_recurrence_frequency_uuid": recurrenceFrequency,
                "workorder_recurrence_interval": recurrenceInterval,
                "workorder_recurrence_days_of_week": recurrenceDaysOfWeek,
                "workorder_recurrence_month_order_uuid": recurrenceMonthOrder,
                "workorder_recurrence_end_date": recurrence_end_date,
                "workorder_recurrence_end_time": recurrence_end_time,
                "workorder_priority_uuid": selectedPriority.workorder_priority_uuid,
                "assignee_handles": assignee_handles,
                "workorder_file_uuids": file_uuids
                
            })
          .then(async response => {
            const data = await response.json();
            console.log('POST data', data)
            if(response.ok){
              getWorkOrders()
              setLoading(false)
              toast.success('Work order created')
              closeModal()
            }
            else{
              try{
                const data = await response.json();
                setLoading(false)
                toast.error(data.message)
              }catch (e){
                toast.error('Something went wrong')
                setLoading(false)
              }
            }
          })


      }
    } catch (error) {
      
    }
  }

  // console.log('recurrenceFrequency', recurrenceFrequency)
  // console.log('recurrenceInterval', recurrenceInterval)
  // console.log('recurrenceDaysOfWeek', recurrenceDaysOfWeek)
  // console.log('recurrenceMonthOrder', recurrenceMonthOrder)
  // console.log('recurrenceEndDate', recurrenceEndDate)
  // console.log('recurrenceEndTime', recurrenceEndTime)
  
  // console.log('imagesUuid', imagesUuid)
  // console.log('filesUuid', filesUuid) 
  console.log('selectedPriority', selectedPriority) 
  return (
    <>
      <div className="flex items-center justify-center">
      {btnType ==='standardBtn' &&
        <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
            <PlusSmIcon className='btnMainIcon' />
            <span className='btnMainText pl-0.5'>New Work Order</span>
        </motion.button>
        }
      {btnType ==='tableBtn' &&
       <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='flex w-20 text-sm items-baseline ml-2' onClick={openModal} >
          <PlusSmIcon className='text-blue-600 w-4 h-4 mt-auto mb-0.5' />
          <span className=' text-blue-600'>Add</span>
      </motion.button>
      }
      {btnType ==='inlineIconBtn' &&
        <button className={`${isDark?'text-blue-400 hover:text-blue-200':'text-blue-500 hover:text-blue-900'} cursor-pointer flex ml-auto`} onClick={openModal}>
          <PlusSmIcon className={`w-5 h-5`}/>
        </button>
      }
      {btnType ==='hideBtn' &&
        <div className='w-0 h-0'>{''}</div>
      }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100" 
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className={`w-full max-w-3xl transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <DialogTitle
                    as="h3"
                    className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}
                  >
                    New Work Order
                  </DialogTitle>
                  <AnimatePresence mode='wait'>
                    <motion.div
                      key='teamInfoContainer'
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                        <div className='flex flex-col relative min-w-[300px] justify-center align-center text-center px-4 pb-4'>
                        
                          <div className='text-left mt-2'>
                            <div className="pt-2">
                                    <label className={`block text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`} htmlFor={'workOrderTitle'}>
                                        Title
                                    </label>
                                    <input 
                                    className={` block bg-transparent w-full border rounded-md py-2 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
                                    // id={name} 
                                    type={'text'}
                                    name={'workOrderTitle'}
                                    placeholder={'What needs to be done?'}
                                    onChange={(e) => setWorkOrderTitle(e.target.value)}
                                    value={workOrderTitle}
                                    />
                            </div>
                            <div className="pt-2">
                                    <label className={`block text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`} htmlFor={'workOrderDescription'}>
                                        Description
                                    </label>
                                    <textarea 
                                    className={` block bg-transparent w-full border rounded-md py-2 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
                                    // id={name} 
                                    type={'text'}
                                    name={'workOrderDescription'}
                                    placeholder={'Add description'}
                                    onChange={(e) => setWorkOrderDescription(e.target.value)}
                                    value={workOrderDescription}
                                    />
                            </div>
                            <div className="pt-4">
                                  <WorkOrderImageUploadSection setImageUuid={setImageUuid} />
                            </div>
                            <div className="pt-4">
                                <label className={`block text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`} htmlFor={'workOrderDescription'}>
                                    Files
                                </label>
                                  <WOFileUploadSection setFilesUuid={setFilesUuid} />
                            </div>
                            <div className="pt-4">
                                    <label className={`block text-sm font-medium ${isDark?'text-gray-300':'text-gray-600'}`} htmlFor={'assignedToInput'}>
                                        Assigned to
                                    </label>
                                    <div className={`relative `} ref={inputContainerRef}>
                                      <div className=' w-full py-2'>
                                          <div className={`flex flex-wrap ${isDark? 'bg-slate-700/50':'bg-slate-100'} rounded-md px-2 py-1.5`}>
                                            <div className='flex items-center pointer-events-none ml-1 mr-2'>
                                                <SearchIcon className='h-4 w-4 text-gray-400 ' />
                                            </div>
                                              {selectedMembers && Object.keys(selectedMembers).length > 0 && Object.keys(selectedMembers).map((member) => (
                                                  <div key={`${member}-selectedMembers`} className={`${member === isHighlighted ? 'bg-blue-600/40':'bg-blue-600/80'} flex text-white rounded-full pr-1 pl-2 py-0 mr-1 my-0.5 items-center font-light`}>
                                                      <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 20, height: 20, }}  src={selectedMembers[member].user_image_url} />
                                                      <span className='px-2'>{member}</span>
                                                      <button 
                                                      onClick={() => removeMember(member)}
                                                      className={`bg-white/50 ${isDark? 'text-slate-700/50':'text-slate-100'} rounded-full h-4 p-0.5`}>
                                                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="currentColor" className="w-3 h-3">
                                                              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                          </svg>
                                                      </button>
                                                  </div>
                                              ))} 
                                              <input className={`grow py-0 min-w-[150px] bg-transparent text-gray-600 border-0 border-gray-300 
                                                              ${isDark? 'focus:ring-gray-500 text-white placeholder:text-gray-400':'focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 '}
                                                              focus:ring-[0px]  text-sm text-ellipsis `}
                                                  value={searchMemberText} 
                                                  ref={inputRef} 
                                                  // onFocus={handleInputFocus}
                                                  onChange={handleSearchMemberText}
                                                  onKeyDown={handleRemoveUser}
                                                  onClick={handleInputClick}
                                                  type='text' 
                                                  placeholder={selectedMembers && Object.keys(selectedMembers).length > 0 ? '':'Who would you like to add?'}

                                                  />
                                          </div>
                                      </div>
                                      {isDropdownOpen && (
                                          <div className={`absolute ${isDark ? 'bg-slate-700' : 'bg-white'}  rounded-md shadow-lg p-2 w-full z-10 `}>
                                            <div className='overflow-y-scroll scrollbar-hide max-h-[200px] '>
                                                    {searchMemberText === '' && teamsList && Object.keys(teamsList).length > 0 &&
                                                        <motion.div
                                                            key='allTeamsInOrg'
                                                            initial={{ y: 10, opacity: 0 }}
                                                            animate={{ y: 0, opacity: 1 }}
                                                            exit={{ y: -10, opacity: 0 }}
                                                            transition={{ duration: 0.2 }}
                                                        > 
                                                            <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                                                              <div className={`flex font-semibold ${isDark? 'text-white':'text-gray-600'} pl-2 mt-2 mb-1`}>
                                                                Teams
                                                              </div>
                                                            {teamsList.map((team, index) => (
                                                                <button 
                                                                    key={`${team.team_handle}-teamList`} 
                                                                    className={`flex px-2 py-1.5  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center `}
                                                                    onClick={(e) => handleAddUser(e, '', team)}
                                                                >
                                                                    <input 
                                                                        type="checkbox" 
                                                                        checked={Object.keys(selectedMembers).includes(team.team_handle)} 
                                                                        onChange={(e) => handleAddUser(e,'', team)}  
                                                                        className="w-4 h-4 mr-2 rounded-full text-blue-500 hover:-translate-y-px focus:ring-transparent bg-transparent outline-none rounded border-gray-200 border-[1px] shadow disabled:border-gray-200/50 disabled:bg-slate-600"
    
                                                                    />
                                                                    <div className={`relative `}>
                                                                        <Avatar 
                                                                        sx={{  
                                                                            bgcolor: isDark? '#1e3a8a':'#9ca3af', 
                                                                            width: 32, 
                                                                            height: 32, 
                                                                        // filter: memberAlreadyInGroup(`${member.user_username}`)? 'grayscale(100%)':'grayscale(0)'
                                                                    }}  
                                                                        alt={team.team_name}
                                                                        src={'/brokenLink.jpeg'} />
                                                                    </div>
                                                                    <div className='flex flex-col pl-2 grow overflow-hidden'>
                                                                        <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                                            <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                                <h1 className={`text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate `} >{team.team_name}</h1>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                                            <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                                <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'}  truncate italic`}>{team.team_handle}</h1>
                                                                            </div>
    
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </button>
                                                            ))}
    
                                                            </div>
                                                        </motion.div>
                                                    }
                                                    {searchMemberText && searchMemberText.length > 0  && filteredTeamsList && Object.keys(filteredTeamsList).length > 0 &&
                                                        <motion.div
                                                            key='allTeamsInOrg'
                                                            initial={{ y: 10, opacity: 0 }}
                                                            animate={{ y: 0, opacity: 1 }}
                                                            exit={{ y: -10, opacity: 0 }}
                                                            transition={{ duration: 0.2 }}
                                                        > 
                                                            <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                                                              <div className={`flex font-semibold ${isDark? 'text-white':'text-gray-600'} pl-2 mt-2 mb-1`}>
                                                                Teams
                                                              </div>
                                                            {filteredTeamsList.map((team, index) => (
                                                                <button 
                                                                    key={`${team.team_handle}-teamList`} 
                                                                    className={`flex px-2 py-1.5  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center `}
                                                                    onClick={(e) => handleAddUser(e, '', team)}
                                                                >
                                                                    <input 
                                                                        type="checkbox" 
                                                                        checked={Object.keys(selectedMembers).includes(team.team_handle)} 
                                                                        onChange={(e) => handleAddUser(e,'', team)}  
                                                                        className="w-4 h-4 mr-2 rounded-full text-blue-500 hover:-translate-y-px focus:ring-transparent bg-transparent outline-none rounded border-gray-200 border-[1px] shadow disabled:border-gray-200/50 disabled:bg-slate-600"
    
                                                                    />
                                                                    <div className={`relative `}>
                                                                        <Avatar 
                                                                        sx={{  
                                                                            bgcolor: isDark? '#1e3a8a':'#9ca3af', 
                                                                            width: 32, 
                                                                            height: 32, 
                                                                        // filter: memberAlreadyInGroup(`${member.user_username}`)? 'grayscale(100%)':'grayscale(0)'
                                                                    }}  
                                                                        alt={team.team_name}
                                                                        src={'/brokenLink.jpeg'} />
                                                                    </div>
                                                                    <div className='flex flex-col pl-2 grow overflow-hidden'>
                                                                        <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                                            <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                                <h1 className={`text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate `} >{team.team_name}</h1>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                                            <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                                <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'}  truncate italic`}>{team.team_handle}</h1>
                                                                            </div>
    
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </button>
                                                            ))}
    
                                                            </div>
                                                        </motion.div>
                                                    }
                                                    {searchMemberText === '' && usersList && Object.keys(usersList).length > 0 &&
                                                        <motion.div
                                                            key='allMembersInOrg'
                                                            initial={{ y: 10, opacity: 0 }}
                                                            animate={{ y: 0, opacity: 1 }}
                                                            exit={{ y: -10, opacity: 0 }}
                                                            transition={{ duration: 0.2 }}
                                                        >
                                                            <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                                                              <div className={`flex font-semibold ${isDark? 'text-white':'text-gray-600'} pl-2 mt-2 mb-1`}>
                                                                Users
                                                              </div>
                                                            {usersList.map((member, index) => (
                                                                <button 
                                                                    key={`${member.user_username}-usersList`} 
                                                                    className={`flex px-2 py-1.5  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center `}
                                                                    onClick={(e) => handleAddUser(e, member,'')}
                                                                >
                                                                    <input 
                                                                        type="checkbox" 
                                                                        checked={Object.keys(selectedMembers).includes(member.user_username)} 
                                                                        onChange={(e) => handleAddUser(e, member,'')}  
                                                                        className="w-4 h-4 mr-2 rounded-full text-blue-500 hover:-translate-y-px focus:ring-transparent bg-transparent outline-none rounded border-gray-200 border-[1px] shadow disabled:border-gray-200/50 disabled:bg-slate-600"
    
                                                                    />
                                                                    <div className={`relative `}>
                                                                        <Avatar 
                                                                        sx={{  
                                                                            bgcolor: isDark? '#1e3a8a':'#9ca3af', 
                                                                            width: 32, 
                                                                            height: 32, 
                                                                        // filter: memberAlreadyInGroup(`${member.user_username}`)? 'grayscale(100%)':'grayscale(0)'
                                                                    }}  
                                                                        src={member.user_image_url} />
                                                                    </div>
                                                                    <div className='flex flex-col pl-2 grow overflow-hidden'>
                                                                        <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                                            <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                                <h1 className={`text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate `} >{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                                            <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                                <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'}  truncate italic`}>{member.user_username}</h1>
                                                                            </div>
    
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </button>
                                                            ))}
    
                                                            </div>
                                                        </motion.div>
                                                    }
                                                    {searchMemberText && searchMemberText.length > 0  && filteredMemberList && Object.keys(filteredMemberList).length > 0 &&
                                                        <motion.div
                                                            key='filteredListSection'
                                                            initial={{ y: 10, opacity: 0 }}
                                                            animate={{ y: 0, opacity: 1 }}
                                                            exit={{ y: -10, opacity: 0 }}
                                                            transition={{ duration: 0.2 }}
                                                        > 
                                                            <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                                                              <div className={`flex font-semibold ${isDark? 'text-white':'text-gray-600'} pl-2 mt-2 mb-1`}>
                                                                Users
                                                              </div>
                                                                {filteredMemberList.map((member, index) => (
                                                                <button 
                                                                    key={`${member.user_username}-${member.organization_handle}-filteredMembersList`} 
                                                                    className={`flex px-2 py-1.5  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center`}
                                                                    onClick={(e) => handleAddUser(e, member,'')}
                                                                >
                                                                    <input 
                                                                        type="checkbox" 
                                                                        checked={Object.keys(selectedMembers).includes(member.user_username)} 
                                                                        onChange={(e) => handleAddUser(e, member,'')}  
                                                                        className="w-4 h-4 mr-2 rounded-full text-blue-500 hover:-translate-y-px focus:ring-transparent bg-transparent outline-none rounded border-gray-200 border-[1px] shadow disabled:border-gray-200/50 disabled:bg-slate-600"
                                                                    />
                                                                    <div className={`relative`}>
                                                                        <Avatar 
                                                                        sx={{  
                                                                            bgcolor: isDark? '#1e3a8a':'#9ca3af', 
                                                                            width: 32, 
                                                                            height: 32, 
                                                                        // filter: memberAlreadyInGroup(`${member.user_username}`)? 'grayscale(100%)':'grayscale(0)'
                                                                    }}  
                                                                        src={member.user_image_url} />
                                                                    </div>
                                                                    <div className='flex flex-col pl-2 grow overflow-hidden'>
                                                                        <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                                            <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                                <h1 className={`text-sm ${isDark? 'text-gray-300':'text-gray-600'}  font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                                            <div className='max-w-[250px] lg:max-w-[550px]'>
                                                                                <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'}  truncate italic`}>{member.user_username}</h1>
                                                                            </div>
    
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </button>
                                                                ))}
                                                            </div>
                                                        </motion.div>
                                                    }
                                                    {searchMemberText && searchMemberText.length > 0  && filteredMemberList && Object.keys(filteredMemberList).length === 0 && filteredTeamsList && Object.keys(filteredTeamsList).length === 0 &&
                                                    <motion.div
                                                        key='noneFoundContainer'
                                                        initial={{ y: 10, opacity: 0 }}
                                                        animate={{ y: 0, opacity: 1 }}
                                                        exit={{ y: -10, opacity: 0 }}
                                                        transition={{ duration: 0.2 }}
                                                    > 
                                                        <div className='mt-2 flex-flex-col p-4'>
                                                            <h6 className={`${isDark? 'text-gray-300':'text-gray-600'}  text-2lx text-center mb-1`}>
                                                                { `(;-;)`}
                                                            </h6>
                                                            <h6 className={`${isDark? 'text-gray-300':'text-gray-600'}  text-center `}>
                                                            No users or teams was found.
                                                            </h6>
                                                            <h6 className={`${isDark? 'text-gray-300':'text-gray-600'}  text-center `}>
                                                            If you're looking for a new member, consider inviting them!
                                                            </h6>
                                                        </div>
                                                    </motion.div>
                                                    }
    
                                            </div>
                                        </div>
                                      )}
                                  </div>
                            </div>
                            <div className="pt-4 relative" >
                                    <label className={`block text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`} htmlFor={'workOrderDescription'}>
                                        Due Date
                                    </label>
                                    <div className='flex items-center'>
                                      <motion.button  
                                        // whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}  
                                          className={`w-44 items-center flex justify-between pl-3 pr-2 py-1.5 text-sm font-normal ${isDark?'text-gray-200 bg-slate-600 border-slate-400':'text-gray-700 bg-white border-slate-50'}  bg-clip-padding bg-no-repeat  rounded shadow-iotflows-lg`} 
                                          onClick={() => setShowDueDateCalendar(!showDueDateCalendar)}
                                      >
                                          <span className='text-sm tracking-wider'>{formatCalendarDate(dueDate)}</span>
                                      </motion.button>
                                      {dueDate && (
                                        <div className='flex'>
                                          <motion.div
                                            key='selectorDueTimeContainer' 
                                            className={`flex ml-2`}
                                          >
                                            <select
                                              value={dueDateTime}
                                              onChange={(e) => handleDueDateTimeChange(e.target.value)}
                                              className={`pr-10 py-1.5 text-sm font-normal border-[0px]  rounded shadow-iotflows-lg ${isDark ? 'text-gray-200 bg-slate-600 border-slate-400' : 'text-gray-700 bg-white border-slate-50'}`}
                                            >
                                              {timeOptions.map(t => (
                                                <option key={t} value={t}>{t}</option>
                                              ))}
                                            </select>
                                          </motion.div>
                                            <motion.button  
                                              whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  
                                              className={`ml-2 ${isDark?'text-gray-400':'text-gray-600'} `} 
                                              onClick={(e) => {
                                                setDueDate(null)
                                              }}
                                            >
                                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                              </svg>
                                            </motion.button>
                                        </div>
                                      )}
                                    </div>
                                    {showDueDateCalendar && (
                                      <div ref={dueDateDropdownRef} className={`absolute z-20 left-0 bottom-10 flex flex-col bg-slate-700 rounded-xl max-h-[450px] overflow-y-scroll md:max-h-[600px] `}>
                                        <div className='customWrapper pr-2'>
                                          <Calendar
                                              date={dueDate}
                                              onChange={handleSelectDueDate}
                                              color="#3182ce"  // Customizable color
                                          />
                                      </div>
                                  </div>
                                  )}
                            </div>
                            {dueDate &&
                              <div className="pt-6 relative" >
                                      <label className={`block text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`} htmlFor={'workOrderDescription'}>
                                          Start Date
                                      </label>
                                      <div className='flex items-center'>
                                        <motion.button  
                                          // whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}  
                                            className={`w-44 items-center flex justify-between pl-3 pr-2 py-1.5 text-sm font-normal ${isDark?'text-gray-200 bg-slate-600 border-slate-400':'text-gray-700 bg-white border-slate-50'}  bg-clip-padding bg-no-repeat  rounded shadow-iotflows-lg`} 
                                            onClick={() => setShowStartDateCalendar(!showStartDateCalendar)}
                                        >
                                            <span className='text-sm tracking-wider'>{formatCalendarDate(startDate)}</span>
                                        </motion.button>
                                        {startDate && (
                                          <div className='flex '>
                                            <motion.div
                                              key='selectorStartTimeContainer' 
                                              className={`flex ml-2`}
                                            >
                                              <select
                                                value={startDateTime}
                                                onChange={(e) => handleStartDateTimeChange(e.target.value)}
                                                className={`pr-10 py-1.5 text-sm font-normal border-[0px]  rounded shadow-iotflows-lg ${isDark ? 'text-gray-200 bg-slate-600 border-slate-400' : 'text-gray-700 bg-white border-slate-50'}`}
                                              >
                                                {timeOptions.map(t => (
                                                  <option key={t} value={t}>{t}</option>
                                                ))}
                                              </select>
                                              {/* <motion.button  
                                                whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  
                                                className={`${isDark?'text-gray-400':'text-gray-600'}  ml-1`} 
                                                onClick={() => setAddStartDateTime(false)}
                                              >
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                                                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                </svg>
                                              </motion.button> */}
                                            </motion.div>
                                              <motion.button  
                                                whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  
                                                className={`ml-2 ${isDark?'text-gray-400':'text-gray-600'} `} 
                                                onClick={(e) => {
                                                  setStartDate(null)
                                                }}
                                              >
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
                                                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                </svg>
                                              </motion.button>
                                          </div>
                                        )}

                                      </div>
                                      {showStartDateCalendar && (
                                        <div ref={startDateDropdownRef} className={`absolute z-20 left-0 bottom-10 flex flex-col bg-slate-700 rounded-xl max-h-[450px] overflow-y-scroll md:max-h-[600px] `}>
                                            <div className='customWrapper pr-2'>
                                              <Calendar
                                                  date={startDate}
                                                  onChange={handleSelectStartDate}
                                                  color="#3182ce"  // Customizable color
                                              />
                                          </div>
                                      </div>
                                    )}
                              </div>
                            }
                            {dueDate  && (
                            <div className='pt-6'>
                              <RecurrenceSelector date={dueDate} setRecurrenceFrequency={setRecurrenceFrequency} setRecurrenceInterval={setRecurrenceInterval} setRecurrenceDaysOfWeek={setRecurrenceDaysOfWeek} setRecurrenceMonthOrder={setRecurrenceMonthOrder} setRecurrenceEndDate={setRecurrenceEndDate} setRecurrenceEndTime={setRecurrenceEndTime} />
                            </div>
                            )}

                            <div className={`w-full mt-6`}>
                              <label 
                                className={`block text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`}
                              >
                                Priority
                              </label>
                              <div className="flex items-center">
                                <div className={`relative flex items-center ${isDark?'bg-slate-700':'bg-slate-100'}  rounded-full p-0`}>
                                  {priorities && Object.keys(priorities).length > 0 && priorities.map((item) => (
                                    <button
                                      key={item.workorder_priority_name}
                                      onClick={() => setSelectedPriority(item)}
                                        
                                      className={`
                                        relative z-[9] p-0 w-[75px] sm:w-[125px] py-0.5 rounded-full transition-colors duration-500  ${isDark?'text-gray-100':'text-gray-600'}
                                        ${selectedPriority.workorder_priority_name === item.workorder_priority_name ? ` ${selectedPriority.workorder_priority_color_dark_mode} text-white` : 'bg-transparent'}
                                        focus:outline-none
                                      `}
                                    >
                                      {item.workorder_priority_name}
                                    </button>
                                  ))}
                                  {/* Sliding background indicator */}
                                  <div 
                                    className={`absolute left-0 w-1/4 h-full ${isDark?'bg-slate-600':'bg-slate-100'} rounded-full shadow-md transition-all duration-300 ease-in-out`}
                                    style={{
                                      transform: `translateX(${
                                        priorities.findIndex(p => p.workorder_priority_name === selectedPriority.workorder_priority_name) * 100
                                      }%)`,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            

                            <div 
                              ref={assetDropdownRef} 
                              className={`relative w-full ${isAssetDropdownOpen && 'pb-52'} mt-6`}
                            >
                              <label 
                                className={`block text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`}
                              >
                                Asset
                              </label>
                              <div className={`relative flex `}>
                                <input
                                  type="text"
                                  value={searchAssetText}
                                  placeholder={'Search your asset'}
                                  onClick={() => setIsAssetDropdownOpen(true)}
                                  onChange={(e) => {
                                    setSearchAssetText(e.target.value)
                                    setIsAssetDropdownOpen(true)
                                  }}
                                  className={`w-full px-4 py-2 text-sm rounded-lg border-0 shadow-iotflows-lg
                                    ${isDark 
                                      ? 'bg-slate-600 text-gray-200 border-slate-500 placeholder-gray-400' 
                                      : 'bg-white text-gray-700 border-gray-300 placeholder-gray-500'
                                    } 
                                    focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                />
                                
                                <button 
                                  onClick={() => setIsAssetDropdownOpen(!isAssetDropdownOpen)}
                                  className={`absolute right-2 top-1/2 -translate-y-1/2 ${isAssetDropdownOpen ? '':'rotate-180'}`}
                                >
                                  <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    className={`h-5 w-5 ${isDark ? 'text-gray-200' : 'text-gray-700'}`} 
                                    viewBox="0 0 20 20" 
                                    fill="currentColor"
                                  >
                                    <path 
                                      fillRule="evenodd" 
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" 
                                      clipRule="evenodd" 
                                    />
                                  </svg>
                                </button>
                              </div>

                              {!isAssetDropdownOpen && selectedAsset && Object.keys(selectedAsset).length > 0 && (
                                  <div className={`flex space-x-2 rounded-md ${isDark?'bg-slate-900':'bg-slate-100'} max-w-[250px] w-fit px-2 py-1 mt-2 truncate`}>
                                    <OptimizeImage 
                                      alt={selectedAsset.asset_custom_name ? selectedAsset.asset_custom_name : selectedAsset.machine_name} 
                                      source={selectedAsset.asset_custom_picture_url ? selectedAsset.asset_custom_picture_url : selectedAsset.machine_picture_url} 
                                      size={'medium'} 
                                    />
                                    <div className='flex flex-col truncate'>
                                      <div className={`text-left truncate text-sm ${isDark ? 'text-white':'text-gray-600'} font-normal`}>{selectedAsset.asset_custom_name  ? selectedAsset.asset_custom_name :selectedAsset.machine_name}</div>
                                      <div className={`text-left truncate text-xs ${isDark ? 'text-gray-200':'text-gray-400'} font-medium`}>{selectedAsset.asset_custom_identifier  ? selectedAsset.asset_custom_identifier :selectedAsset.asset_uuid}</div>
                                    </div>
                                    <motion.button  
                                      whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  
                                      className={`ml-2 ${isDark?'text-gray-400':'text-gray-600'} `} 
                                      onClick={(e) => {
                                        setSelectedAsset([])
                                      }}
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                      </svg>
                                    </motion.button>
                                  </div>
                                )}

                              {isAssetDropdownOpen && (
                                <div 
                                  className={`absolute z-10 w-full mt-1 rounded-lg shadow-lg border-0
                                    ${isDark 
                                      ? 'bg-slate-600 border-slate-500' 
                                      : 'bg-white border-gray-200'
                                    } `}
                                >
                                  {Object.keys(filteredAssetList).length > 0 ? (
                                    <ul 
                                      className={`max-h-60 overflow-y-auto py-1 text-sm 
                                        ${isDark 
                                          ? 'text-gray-200' 
                                          : 'text-gray-700'
                                        }`}
                                    >
                                      {filteredAssetList.map((asset) => (
                                        <li 
                                          key={asset.asset_uuid}
                                          onClick={() => handleSelectAsset(asset)}
                                          className={`flex px-4 py-2 cursor-pointer 
                                            ${isDark 
                                              ? 'hover:bg-slate-500' 
                                              : 'hover:bg-gray-100'
                                            } 
                                            ${selectedAsset === asset.machine_name 
                                              ? (isDark ? 'bg-slate-500' : 'bg-gray-100') 
                                              : ''
                                            }`}
                                        >
                                          <div className='flex space-x-2'>
                                            <OptimizeImage 
                                              alt={asset.asset_custom_name ? asset.asset_custom_name : asset.machine_name} 
                                              source={asset.asset_custom_picture_url ? asset.asset_custom_picture_url : asset.machine_picture_url} 
                                              size={'medium'} 
                                            />
                                            <div className='flex flex-col'>
                                              <div className={`text-left truncate text-sm ${isDark ? 'text-white':'text-gray-600'} font-normal`}>{asset.asset_custom_name  ? asset.asset_custom_name :asset.machine_name}</div>
                                              <div className={`text-left truncate text-xs ${isDark ? 'text-gray-200':'text-gray-400'} font-medium`}>{asset.asset_custom_identifier  ? asset.asset_custom_identifier :asset.asset_uuid}</div>
                                            </div>
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  ) : (
                                    <div 
                                      className={`px-4 py-2 text-sm text-center 
                                        ${isDark ? 'text-gray-400' : 'text-gray-500'}`}
                                    >
                                      No assets found
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <form >
                            <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                                <button
                                type="button"
                                className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                                onClick={closeModal}
                                >
                                Cancel
                                </button>
                                <button
                                type="button"
                                className={`relative cursor-pointer ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                                onClick={submitNewWorkOrder}
                                disabled={loading }
                                >
                                {`Create Work Order`}
                                {loading && 
                                    <div >
                                        <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                        </svg>
                                    </div>
                                    }
                                </button>
                            </div>
                        </form>
                    </motion.div>
                  
                  
                  
                 

                  </AnimatePresence>
                  
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default NewWorkOrderModal