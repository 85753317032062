import React, { useEffect, useState } from 'react'
import SwipeableViews from 'react-swipeable-views';


function YearPicker({setSelectedYear, selectedYear, setDateString}) {
    const [highlightedYears, setHighlightedYears] = useState([]);
    const [yearList, setYearList] = useState([]);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 2021;
        const years = [];

        for (let year = startYear; year <= currentYear; year++) {
        years.push(year);
        }

        setYearList(years);

    },[])

    useEffect(() => {
        if(selectedYear && Object.keys(selectedYear).length >= 0 && yearList && Object.keys(yearList).length > 0){
        
            let string = ``
            selectedYear.map((dateArr, index) => {
                if(index === 0){
                    string += dateArr.year
                }else {
                    string += ' -> ' + dateArr.year
                }
            })
            setDateString(string)

            let array = []
            if(Object.keys(selectedYear).length === 1){
                array.push(`${selectedYear[0].year}`)
                setHighlightedYears(array)
    
            }else if(Object.keys(selectedYear).length === 2){
                if(selectedYear[0].year == selectedYear[1].year){
                    array.push(`${selectedYear[0].year}`)
    
                }else{
                    for (let year = selectedYear[0].year; year <= selectedYear[1].year; year++) {
                        if ( year >= selectedYear[0].year && year <= selectedYear[1].year ) {
      
                          if(year == selectedYear[0].year){
                              array.push(array.push(`${year}`));
      
                          }else if(year == selectedYear[1].year){
                              array.push(array.push(`${year}`));
      
                          }else if(year > selectedYear[0].year && year < selectedYear[1].year){
                              array.push(array.push(`${year}`));
      
                          }
                        }
                      }
                }
    
                setHighlightedYears(array)
    
            }else{
                setHighlightedYears([])
            }
        }

    }, [selectedYear, yearList])

    

      const handleYearClick = (year) => {
        let currentDate = {
            year
        }
        let dateArray = []
        if(Object.keys(selectedYear).length === 0){
            dateArray.push(currentDate)
            setSelectedYear(dateArray);
        }
        else if(Object.keys(selectedYear).length === 1 ){
            const date1 = new Date(selectedYear[0].year, 1, 1); // December 25, 2024
            const date2 = new Date(year, 1, 1);
            
            if (date1 > date2) {
                // console.log(date1, 'is greater than', date2);
                dateArray.push(currentDate)
                setSelectedYear(dateArray);
              } else if (date1 < date2) {
                    // console.log(date1, 'is less than', date2);
                    dateArray = selectedYear
                    dateArray.push(currentDate)
                    setSelectedYear(dateArray);
              } else {
                    // console.log(date1, 'is equal to', date2);
                    dateArray = selectedYear
                    dateArray.push(currentDate)
                    setSelectedYear(dateArray);
              }
        }else if(Object.keys(selectedYear).length > 1){
            dateArray.push(currentDate)
            setSelectedYear(dateArray);
        }

        let string = ``
        dateArray.map((dateArr, index) => {
            if(index === 0){
                string += dateArr.year
            }else {
                string += ' -> ' + dateArr.year
            }
        })
        setDateString(string)

        let array = []
        if(Object.keys(dateArray).length === 1){
            array.push(`${dateArray[0].year}`)
            setHighlightedYears(array)

        }else if(Object.keys(dateArray).length === 2){
            if(dateArray[0].year == dateArray[1].year){
                array.push(`${dateArray[0].year}`)

            }else{
                for (let year = dateArray[0].year; year <= dateArray[1].year; year++) {
                    if ( year >= dateArray[0].year && year <= dateArray[1].year ) {
  
                      if(year == dateArray[0].year){
                          array.push(array.push(`${year}`));
  
                      }else if(year == dateArray[1].year){
                          array.push(array.push(`${year}`));
  
                      }else if(year > dateArray[0].year && year < dateArray[1].year){
                          array.push(array.push(`${year}`));
  
                      }
                    }
                  }
            }

            setHighlightedYears(array)

        }else{
            setHighlightedYears([])
        }
        
        // Potentially trigger other actions based on month selection
      };


  return (
    <div>
        <div className='grid grid-cols-2 gap-3 mt-8 pb-4'>
            {yearList.map((year) => (
                    <button
                        key={`${year}`}
                        onClick={() => handleYearClick(year)}
                        className={`${highlightedYears.includes(`${year}`)? 'bg-blue-500 text-white':'bg-slate-600 text-gray-300'} px-2 py-0.5  rounded-lg text-sm`}
                    >
                        {year}
                    </button>
            ))}
        </div>
    </div>
  )
}

export default YearPicker