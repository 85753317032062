import { useState, useEffect } from 'react';

const useImage = (imageUrl, crossOrigin) => {
  const [image, setImage] = useState(null);
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    if (!imageUrl) return;

    // Create a new Image object
    const img = new Image();
    // console.log('img', img)
    // console.log('crossOrigin', crossOrigin)
    // if (crossOrigin) {
    //   img.crossOrigin = crossOrigin; // Set crossOrigin if provided
    // }

    // Handle successful loading
    const handleLoad = () => {
      setImage(img);
      setStatus('loaded');
    };

    // Handle loading errors
    const handleError = () => {
      setImage(null);
      setStatus('failed');
    };

    img.src = imageUrl;
    img.onload = handleLoad;
    img.onerror = handleError;

    // Cleanup function to prevent memory leaks
    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [imageUrl, crossOrigin]);

  return [image, status];
};

export default useImage;
