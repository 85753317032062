import React from 'react';

const ProgressCircle = ({ percentage }) => {
  const radius = 45; // Adjust the radius as needed
  const circumference = 2 * Math.PI * radius;
  if(!percentage){
    percentage = 1
  }

  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <svg width="30" height="30" viewBox="0 0 100 100" >
      <svg xmlns="http://www.w3.org/2000/svg" x={10} y={10} width={80} height={80} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-gray-500 dark:text-gray-100">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      <circle cx="50" cy="50" r={radius} fill="transparent" stroke="currentColor" strokeWidth="6" className='text-white dark:text-slate-600/50'  />
      <circle
        cx="50"
        cy="50"
        r={radius}
        className='text-gray-500 dark:text-gray-100'
        fill="transparent"
        stroke="currentColor" // Replace with your desired color
        strokeWidth="6"
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
      />
        
    </svg>
  );
};

export default ProgressCircle;