import React, { useEffect, useState } from 'react'


const loadImage = (setImageDimensions, imageUrl) => {
    const img = new Image();
    img.src = imageUrl;
  
    img.onload = () => {
      setImageDimensions({
        height: img.height,
        width: img.width
      });
    };
    img.onerror = (err) => {
      console.log("img error");
      // console.error(err);
    };
  };

function OptimizeImage({alt, source, size}) {
    const [imageDimensions, setImageDimensions] = useState({});
    const [imageWidth, setImageWidth] = useState(false);
    const [imageHeight, setImageHeight] = useState(false);

    useEffect(() => {
        loadImage(setImageDimensions, source);
    }, [])

    useEffect(() => {
        if(imageDimensions.height > imageDimensions.width){
            setImageHeight(true)
            setImageWidth(false)
        }else{
            setImageHeight(false)
            setImageWidth(true)
        }

    }, [imageDimensions])

   

  return (
    <div className='my-auto'>
        {Object.keys(imageDimensions).length > 0 && imageHeight && 
            <img
                alt={alt}
                src={source}
                className={`${size == 'small' &&'h-8'} ${size == 'medium' &&'h-10'} ${size == 'large' && 'h-12'} ${size === 'xl' && 'h-16'} ${size === '2xl' && 'h-20 '} rounded-full my-auto`}
            />
        }
        {Object.keys(imageDimensions).length > 0 && imageWidth && 
            <img
                alt={alt}
                src={source}
                className={`${size == 'small' &&'w-8'} ${size == 'medium' &&'w-10'} ${size == 'large' && 'w-12'} ${size === 'xl' && 'w-16'}  ${size === '2xl' && 'w-20'} rounded-full my-auto`}
            />
        }
        {Object.keys(imageDimensions).length == 0 && 
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${size == 'small' &&'w-8'} ${size == 'medium' &&'w-10'} ${size == 'large' && 'w-12'} ${size == 'xl' && 'w-16'} ${size === '2xl' && 'w-20'} rounded-full text-gray-500 mx-auto `}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
            </svg>
        }
    </div>
  )
}

export default OptimizeImage