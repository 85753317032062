import React, { useEffect, useState } from 'react'
import Avatar from '@mui/material/Avatar';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { globalSelectedOrganization } from '../../atoms/orgStates';
import { SearchIcon } from '@heroicons/react/outline'
import OptimizeImage from '../../components/OptimizeImage';
import { getData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import DirectChartRow from './DirectChartRow';
import GroupChatRow from './GroupChatRow';
import { formatRelativeTime, formatTimeOrDate } from '../../utils/DateFormating';
import { motion, AnimatePresence } from "framer-motion";

function ChatList({chatList, userInfo, chatUnreadDic, setGlobalSearchMessage}) {
    const [isMobile, setIsMobile] = useState(false)
    const navigate = useNavigate();
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const [selectedChatId, setSelectedChatId] = useState('')
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const [messageSearchText, setMessageSearchText] = useState('')
    const [searchResults, setSearchResults] = useState([])

    useEffect(() => {

        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
  
    },[])
    

    useEffect(() => {
        let currentSearch = location.search
            if(currentSearch){
                // Pulling to see how cards should be grouped
                let selectedChatId = searchParams.get('chatId')

                setSelectedChatId(selectedChatId)
            }

    },[location])

    
    
    const handleStartNewChat = () => {
        let select = searchParams.getAll('select')
        let chatId = searchParams.getAll('chatId')
        let section = searchParams.getAll('section')
        let platform = searchParams.getAll('platform')

        setSearchParams({select,chatId, section,sub_section:'create_new_chat', platform })

    }

    const handleSearchTextChange = (e) => {
        setMessageSearchText(e.target.value)
        if(e.target.value == ''){
            setSearchResults([])
        }else{
            getData(apiLink + '/v1/organizations/' + selectedOrganization + `/chats/search_message?q=${e.target.value}`)
                .then(async response => {
                  if(response.ok ){
                    const data = await response.json();
                    if(data && data.data){
                    //   console.log('data', data)
                      setSearchResults(data.data)
                    }
                  }
                })

        }

    }

    const handleSearchClick = (messageObject) => {
        // checkSearchMessage(uuid)
        setSearchResults([])
        setMessageSearchText('')
        setGlobalSearchMessage(messageObject)
        navigate(`/${selectedOrganization}/chats?chatId=${messageObject.chat_uuid}&section=messages`)
    }

    

  return (
    <div className='flex flex-col space-y-1 px-2 pt-2 border-r-[1px] border-slate-100 dark:border-slate-800 h-screen scrollbar-hide overflow-y-scroll  '>
        <div className='grid grid-cols-3 '>
            <div className=''>
                {``}
            </div>
            <div className='text-center text-gray-500 dark:text-gray-300 font-medium'>
                Chats
            </div>
            <button 
                className='ml-auto text-blue-600 dark:text-blue-400' 
                onClick={handleStartNewChat}
                >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                </svg>
            </button>

        </div>
        <div>
            <div className='flex '>
                <div className='relative rounded-full w-full'>
                    <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none'>
                        <svg className={`${isMobile ? 'h-5 w-5':'h-4 w-4'} dark:text-gray-300 text-gray-500`}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                    </div>
                    <input className={`bg-gray-100 text-gray-600 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-gray-300 dark:placeholder:text-gray-400
                        block w-full   ${isMobile ? 'text-sm pl-10 py-2.5':'text-xs pl-8 py-1.5 '} rounded-lg text-ellipsis `}
                        value={messageSearchText} 
                        // onChange={(e)=> setMessageSearchText(e.target.value)} 
                        onChange={handleSearchTextChange}
                        type="text" 
                        placeholder="Search Chats..."
                    />
                    
                </div>
            </div>
        </div>
        <div className='overflow-y-scroll pb-28 scrollbar-hide'>
            <AnimatePresence mode='wait'>

            
            {(messageSearchText.length === 0 && chatList && Object.keys(chatList).length > 0 && userInfo && Object.keys(userInfo).length > 0) && (

                <motion.div
                    key='chatLists'
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    {chatList.map((convo, index) => 
                        convo.chat_is_direct ? (
                            <DirectChartRow key={convo.chat_uuid} convo={convo} selectedOrganization={selectedOrganization} selectedChatId={selectedChatId} userInfo={userInfo} index={index} chatUnreadDic={chatUnreadDic} />
                        ):(
                            <GroupChatRow key={convo.chat_uuid} convo={convo} selectedOrganization={selectedOrganization} selectedChatId={selectedChatId} userInfo={userInfo} index={index} chatUnreadDic={chatUnreadDic} />
                        )
                    )}

                </motion.div>
            )}
            {(messageSearchText && messageSearchText.length > 0 && Object.keys(searchResults).length > 0) && (
                <motion.div
                    key='resultsLists'
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
               <div className={``}>
                    {/* <div className={`absolute w-full top-8 z-50 shadow-iotflows-lg rounded-lg max-h-[400px] overflow-auto scrollbar-hide dark:bg-slate-800`}> */}
                    {searchResults.map(result => (
                        searchMessageRow(result, handleSearchClick)
                    ))}
                </div>
            </motion.div>
            )}
            {(messageSearchText && messageSearchText.length > 0 && Object.keys(searchResults).length === 0) && (
                <motion.div
                key='resultsLists'
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
            >
            {/* <div className={`absolute w-full top-10  z-50 shadow-iotflows-lg rounded-lg max-h-[400px] overflow-auto scrollbar-hide`}> */}
                <div className={``}>
                    <div className={`bg-white dark:bg-slate-900 p-10 flex flex-col justify-center itemse-center`}>
                    <div className='mx-auto'>
                        <SearchIcon className='h-10 w-10 text-gray-500 dark:text-gray-400 ' />
                    </div>
                    <p className='text-gray-600 dark:text-gray-400 mx-auto'> 
                        No search results found
                    </p>
                    <p className='text-gray-400 dark:text-gray-500 mx-auto'> 
                        Please try again with a different search query
                    </p>

                    </div>
                </div>
            </motion.div>
           ) }
            </AnimatePresence>
        </div>
    </div>
  )
}

export default ChatList


const searchMessageRow = (result, handleSearchClick) => {

    let addAttachementIcon = false
    let textShown = ''
    if(result && result.file_uuid ){
        if(result.message_text && result.message_text.length > 0){
            textShown = result.message_text
            addAttachementIcon = false
        }else{
            addAttachementIcon = true
            textShown = result.file_name
        }
    }else if(result && !result.file_uuid && !result.isFileUploading && result.message_text && result.message_text.length > 0){
        textShown = result.message_text
        addAttachementIcon = false
    }

    let chatImage = ''
    let chatName = ''
    if(result.chat_is_direct){
        // is direct message
        chatImage = result.user_image_url
        chatName = result.user_first_name + ' ' + `${result.user_last_name ? result.user_last_name : ''}`
    }else{
        // is group message
        chatImage = result.chat_picture_url
        chatName = result.chat_name
    }


    return(
        <div 
            key={result.message_uuid} 
            className={`flex hover:bg-slate-100 dark:hover:bg-slate-800 items-center truncate cursor-pointer rounded`}
            onClick={() => handleSearchClick(result)} 
        >
            <div className={`flex w-full items-center truncate pl-1 pr-2 py-1 cursor-pointer border-b-[0.01em] dark:border-slate-600`}>
                <div className='flex items-center'>
                    <Avatar
                        sx={{ width: 48, height: 48}}
                        alt={chatName}
                        src={chatImage || '/brokenLink.png'}
                        >
                    </Avatar>
                </div>
                <div className={`flex flex-col truncate w-full`}>
                    <div className='flex justify-between items-end'>
                        <div className='pl-2 text-gray-600 dark:text-gray-300 truncate text-sm font-medium'>{chatName}</div>
                        <div className='pl-2 text-gray-500 dark:text-gray-400 text-xs'>{formatRelativeTime(result.message_created_at)}</div>
                    </div>
                    <div className='flex pl-2'>
                        {addAttachementIcon &&
                            <div className='pr-2 text-gray-500 dark:text-gray-400'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
                                </svg>
                            </div>
                        }
                        <div className='text-sm text-gray-500 dark:text-gray-400 truncate'>
                        {textShown}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}

