import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { getData, putData } from '../ApiCalls/DataApis'
import { apiLink } from '../ApiCalls/ApisVariables'
import { globalSelectedOrganization } from '../atoms/orgStates';
import { useRecoilValue } from 'recoil';
import toast from 'react-hot-toast';
import StatusButtons from './components/StatusButtons';
import { formatRelativeTimeWithExactTime, isDatePastDue } from '../utils/DateFormating';
import { Avatar } from '@mui/material';
import DueDateComponent from './components/DueDateComponent';
import PriorityComponent from './components/PriorityComponent';
import AssetComponent from './components/AssetComponent';
import AssigneesComponent from './components/AssigneesComponent';
import SelectedRecurrenceComponent from './components/SelectedRecurrenceComponent';

function SelectedWorkOrderPage({userInfo}) {
    const navigate = useNavigate();
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [searchParams, setSearchParams] = useSearchParams()
    const [isMobile, setIsMobile] = useState(false)
    const [selectedWorkOrderUuid, setSelectedWorkOrderUuid] = useState('')
    const [selectedWorkOrder, setSelectedWorkOrder] = useState([])
    const location = useLocation()

    const [isEditingWOTitle, setIsEditingWOTitle] = useState(false)
    const [woTitle, setWOTitle] = useState('')
    const [loadingTitle, setLoadingTitle] = useState(false);
    const [isEditingWODescription, setIsEditingWODescription] = useState(false)
    const [woDescription, setWODescription] = useState('')
    const [loadingDescription, setLoadingDescription] = useState(false);

    
    useEffect(() => {

        if(selectedWorkOrder && selectedWorkOrder.workorder_uuid){

            setWOTitle(selectedWorkOrder.workorder_title)

            if(selectedWorkOrder.workorder_description){
                setWODescription(selectedWorkOrder.workorder_description)
            }
        }
    }, [selectedWorkOrder])

    useEffect(() => {
        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
          setIsMobile(true)
        }else{
          setIsMobile(false)
        }
      },[])

    useEffect(() => {
        let selectedWorkOrder = searchParams.get('selected-wo')
        setSelectedWorkOrderUuid(selectedWorkOrder)
        
      },[location])


    useEffect(() => {
        if(selectedWorkOrderUuid){
            getSelectedWorkOrder()
        }
    }, [selectedWorkOrderUuid])

    const getSelectedWorkOrder = () => {
        if(selectedWorkOrderUuid){
            getData(apiLink + '/v1/workorders/'+ selectedWorkOrderUuid )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            console.log('Selected WO ', data.data[0])
                            setSelectedWorkOrder(data.data[0])
                            
                        }else{
                            setSelectedWorkOrder([])
                        }
                    }else{
                        setSelectedWorkOrder([])
                    }
                })

            getData(apiLink + '/v1/workorder_statuses')
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            // console.log('workorder_statuses ', data.data)
                            // setWorkOrdersList(data.data)
                        }else{
                            // setWorkOrdersList([])
                        }
                    }else{
                        // setWorkOrdersList([])
                    }
                })
        }

    }

    const handleTitleUpdate = (event) => {
        const newTitle = event.target.value;
    
        // If no value, reset to original title
        if (!newTitle) {
            setWOTitle(selectedWorkOrder.workorder_title);
            setIsEditingWOTitle(false);
            return;
        }

        if (loadingTitle) return;
    
        try {
            setLoadingTitle(true);
            setIsEditingWOTitle(false);
    
            putData(apiLink + '/v1/workorders/' + selectedWorkOrder.workorder_uuid, 
            {'workorder_title': event.target.value })
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    // toast.success('Title Change')
                    setLoadingTitle(false)
                    setWOTitle(event.target.value)
                }
                else{
                    setWOTitle(selectedWorkOrder.workorder_title)
                    setLoadingTitle(false)
                    try{
                        toast.error(data.message)
                    }catch (e){
                        toast.error(e)
                    }
                }
            })

        } catch (e) {
            setWOTitle(selectedWorkOrder.workorder_title);
            toast.error(e.toString());
        }
    };


    const handleDescriptionUpdate = (event) => {
        const newTitle = event.target.value;
    
        // If no value, reset to original title
        if (!newTitle) {
            setWOTitle(selectedWorkOrder.workorder_description);
            setIsEditingWODescription(false);
            return;
        }

        if (loadingTitle) return;
    
        try {
            setLoadingDescription(true);
            setIsEditingWODescription(false);
    
            putData(apiLink + '/v1/workorders/' + selectedWorkOrder.workorder_uuid, 
            {'workorder_description': event.target.value })
            .then(async response => {
                console.log('response', response)
                const data = await response.json();
                if(response.ok){
                    // toast.success('Description Change')
                    setLoadingDescription(false)
                    setWODescription(event.target.value)
                }
                else{
                    setWODescription(selectedWorkOrder.workorder_description)
                    setLoadingDescription(false)
                    try{
                        toast.error(data.message)
                    }catch (e){
                        toast.error(e)
                    }
                }
            })

        } catch (e) {
            setWODescription(selectedWorkOrder.workorder_description);
            toast.error(e.toString());
        }
    };
    
  

  return (
    <div className={`${isMobile ? 'max-h-[calc(100vh)]':'min-h-[calc(100vh-5rem)] max-h-[calc(100vh-5rem)]'} overflow-scroll scrollbar-hide pb-10`}>
         {/* Breadcrums */}
         {isMobile ? (
            <nav className='mb-2 flex items-center'>
                <ol className='hidden sm:flex'>
                    <li className='breadcrumbsNav' onClick={() => navigate(`/${selectedOrganization}/work-orders?platform=mobile`)}>Work Orders</li>
                    <li className='breadcrumbsSpacer'>/</li>
                    <li className='breadcrumbsCurrent'>{selectedWorkOrder.workorder_number  ? selectedWorkOrder.workorder_number :selectedWorkOrder.workorder_title}</li>
                </ol>
                <ol className='flex sm:hidden'>
                    <li className='breadcrumbsNav flex text-lg items-center' onClick={() => navigate(`/${selectedOrganization}/work-orders?platform=mobile`)}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                            </svg>
                        </div>
                        <span>Work Orders</span>
                    </li>
                </ol>
            </nav>
        ): (
            <nav className='mb-2 flex items-center'>
                <ol className='hidden sm:flex'>
                    <li className='breadcrumbsNav' onClick={() => navigate(`/${selectedOrganization}/work-orders`)}>Work Orders</li>
                    <li className='breadcrumbsSpacer'>/</li>
                    <li className='breadcrumbsCurrent'>{selectedWorkOrder.workorder_number  ? selectedWorkOrder.workorder_number :selectedWorkOrder.workorder_title}</li>
                </ol>
                <ol className='flex sm:hidden'>
                    <li className='breadcrumbsNav flex text-lg items-center' onClick={() => navigate(`/${selectedOrganization}/work-orders`)}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                            </svg>
                        </div>
                        <span>Work Orders</span>
                    </li>
                </ol>
            </nav>
        )}
        {selectedWorkOrder && selectedWorkOrder.workorder_uuid && 
            (
                <div className='flex flex-col px-2'>
                    <div className='flex w-full border-b-[0.01em] border-slate-300 dark:border-slate-700 pb-3'>
                        <div className='flex flex-col w-full'>
                                <div className='relative'>
                                    <input
                                        type="text"
                                        value={woTitle}
                                        onChange={(e) => setWOTitle(e.target.value)}
                                        // onBlur={handleTitleBlur}
                                        // onKeyDown={handleKeyPressTitle}
                                        onBlur={handleTitleUpdate}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' || e.key === 'Tab') {
                                                handleTitleUpdate(e);
                                            }
                                        }}
                                        className={`py-0 pr-3 pl-1 rounded-md text-2xl placeholder:text-gray-400 dark:placeholder:text-gray-600 font-medium
                                            ${isEditingWOTitle 
                                                ? 'w-full bg-transparent border shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800' 
                                                : 'bg-transparent w-full text-gray-600 dark:text-gray-300 truncate border-none focus:ring-0'}
                                        `}
                                        placeholder={"Work order title"}
                                        onClick={() => setIsEditingWOTitle(true)}
                                        readOnly={!isEditingWOTitle}
                                        autoFocus={isEditingWOTitle}
                                    />
                                    {loadingTitle && 
                                            <div >
                                                <svg aria-hidden="true" className={`absolute top-1/4 left-0 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                                </svg>
                                            </div>
                                            }

                                </div>
                                <div className='relative'>
                                    <input
                                        type="text"
                                        value={woDescription}
                                        onChange={(e) => setWODescription(e.target.value)}
                                        // onBlur={handleTitleBlur}
                                        // onKeyDown={handleKeyPressTitle}
                                        onBlur={handleDescriptionUpdate}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' || e.key === 'Tab') {
                                                handleDescriptionUpdate(e);
                                            }
                                        }}
                                        className={`py-0 pr-3 pl-1 rounded-md text-lg text-gray-500 dark:text-gray-400 placeholder:text-gray-400 dark:placeholder:text-gray-600 font-light
                                            ${isEditingWODescription
                                                ? 'w-full bg-transparent border shadow-sm focus:outline-none focus:bg-transparent focus:ring-1  text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300  dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800' 
                                                : 'bg-transparent w-full  truncate border-none focus:ring-0'}
                                        `}
                                        placeholder={"Work order description"}
                                        onClick={() => setIsEditingWODescription(true)}
                                        readOnly={!isEditingWODescription}
                                        autoFocus={isEditingWODescription}
                                    />
                                    {loadingDescription && 
                                            <div >
                                                <svg aria-hidden="true" className={`absolute top-1/4 left-0 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                                </svg>
                                            </div>
                                            }

                                </div>
                        </div>
                    </div>
                    <div className='w-full border-b-[0.01em] border-slate-300 dark:border-slate-700 py-4'>
                        <StatusButtons selectedWorkOrder={selectedWorkOrder} />
                    </div>
                    <div className='flex  space-x-8 divide-x-[0.01em] divide-slate-300 dark:divide-slate-600 py-4  border-b-[0.01em] border-slate-300 dark:border-slate-700'>
                        <div className='pr-10'>
                            <DueDateComponent selectedWorkOrder={selectedWorkOrder} getSelectedWorkOrder={getSelectedWorkOrder} />
                        </div>
                        <div className='px-10'>
                            <PriorityComponent selectedWorkOrder={selectedWorkOrder} />
                        </div>

                    </div>
                    <div className='flex space-x-8 divide-x-[0.01em] divide-slate-300 dark:divide-slate-600 py-4  border-b-[0.01em] border-slate-300 dark:border-slate-700'>
                        <div className='pr-10 w-full max-w-[800px]'>
                            <AssigneesComponent selectedWorkOrder={selectedWorkOrder} selectedOrganization={selectedOrganization} userInfo={userInfo} />
                        </div>
                        <div className='px-10 w-full max-w-[500px]'>
                            <AssetComponent selectedWorkOrder={selectedWorkOrder} />
                        </div>

                    </div>
                    {selectedWorkOrder && selectedWorkOrder.workorder_uuid &&selectedWorkOrder.workorder_due_timestamp && (
                        <div className='flex space-x-8 divide-x-[0.01em] divide-slate-300 dark:divide-slate-600 py-4  border-b-[0.01em] border-slate-300 dark:border-slate-700'>
                            <div className='pr-10 w-full max-w-[800px]'>
                                <SelectedRecurrenceComponent selectedWorkOrder={selectedWorkOrder} getSelectedWorkOrder={getSelectedWorkOrder} />
                            </div>

                        </div>
                    )}

                </div>
            )
        }
    </div>
  )
}

export default SelectedWorkOrderPage