import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { useRecoilState, useRecoilValue } from 'recoil';
import { darkModeState } from '../atoms/darkModeState';
import { LightTooltip } from '../styles/Tooltip';
import Zoom from '@mui/material/Zoom';
import { useEffect, useState } from 'react';
import MembersListModal from '../components/modals/memberModals/MembersListModal'
import { getData } from '../ApiCalls/DataApis';
import { apiLink } from '../ApiCalls/ApisVariables';
import AddMemberModal from '../components/modals/memberModals/AddMemberModal';
import { userInfo } from '../atoms/userStates';
import { useNavigate } from 'react-router-dom';

function MembersHeader({selectedOrganization}) {
    const isDark = useRecoilValue(darkModeState)
    const [openMemberListModal, setOpenMemberListModal] = useState(false)
    const [openAddMemberModal, setOpenAddMemberModal] = useState(false)
    const [membersList, setMembersList] = useState([])
    const [addOutsideOrgMember, setAddOutsideOrgMember] =useState([])
    const [inviteNewMember, setInviteNewMember] =useState([])
    const user = useRecoilValue(userInfo)
    const navigate = useNavigate();

    useEffect(() => {
        if(selectedOrganization && user){
            updatedMemberList()
        }
      },[selectedOrganization, user])

    const updatedMemberList = () => {
        try {
            if(selectedOrganization && user){
              getData(apiLink + '/v1/organizations/' + selectedOrganization + '/members')
              .then(async response => {
                if(response.ok ){
                  const data = await response.json();
                  if(data && data.data){
                    setMembersList(data.data)
                    // console.log('data.data', data.data)
                  }
                }
              }) 
            }
          } catch (error) {
            console.log(error.message)
          }
    }

    const handleCloseMemberListModal = () =>{
        setOpenMemberListModal(false)
    }

    const handleCloseAddMemberModal = () =>{
        setOpenAddMemberModal(false)
    }

    const handleAddMemberModal = () => {
        setOpenAddMemberModal(true)
    }

    const handleRouteAddOutsideMember = (outsideMember) =>{
        setOpenAddMemberModal(true)
        setAddOutsideOrgMember(outsideMember)
    }
    const handleRouteInviteNewMember = (inviteEmail) =>{
        setOpenAddMemberModal(true)
        setInviteNewMember(inviteEmail)
    }

  return (
    <div className='border-[0.01em] border-slate-300 dark:border-slate-600 rounded flex'>
        <LightTooltip sx={{maxWidth:'150px', textAlign:'center'}} TransitionComponent={Zoom} title="View members of this organization" arrow >
            <button className='hover:bg-gray-200 dark:hover:bg-gray-700 pl-0.5 pr-0.5 py-0.5' onClick={() => navigate(`/${selectedOrganization}/members`)}>
            {/* <button className='hover:bg-gray-200 dark:hover:bg-gray-700 pl-0.5 pr-0.5 py-0.5' onClick={() => setOpenMemberListModal(true)}> */}
                <AvatarGroup spacing={8}  max={3} variant="rounded"
                    sx={{
                    '& .MuiAvatar-root': { width: 22, height: 22, fontSize: 12, border:`0px solid ${isDark? '#9ca3af':'#fff' }`},
                    '& .css-16fxgir-MuiAvatar-root-MuiAvatarGroup-avatar': { bgcolor: isDark? '#475569': '#94a3b8'},
                    }}
                >
                    {membersList && Object.keys(membersList).length > 0 &&
                        membersList.map((members, index) => (
                        <Avatar key={members.user_username} sx={{  bgcolor: isDark? (index === 0 ?'#1e3a8a':'#1d4ed8'):(index === 0 ?'#1d4ed8':'#2563eb') }}   src={members.user_image_url} />
                    ))}
                </AvatarGroup>  
            </button>
        </LightTooltip>
        <LightTooltip sx={{maxWidth:'150px', textAlign:'center'}} TransitionComponent={Zoom} title="Add members to this organization" arrow >
            <button onClick={handleAddMemberModal} className='text-gray-500 dark:text-gray-300 hover:text-gray-600 dark:hover:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-700 border-l-[0.01em] border-slate-300 dark:border-slate-600 py-0.5 px-1 rounded-r-sm'>
                <svg  xmlns="http://www.w3.org/2000/svg"  className="w-5 h-5 " fill="currentColor" viewBox="0 0 16 16" stroke="none">
                    <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                    <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                </svg>
            </button>
        </LightTooltip>

        {openMemberListModal && selectedOrganization &&
        <MembersListModal btnType='hideBtn' remoteOpen={openMemberListModal} membersList={membersList} selectedOrganization={selectedOrganization} 
            handleCloseMemberListModal={handleCloseMemberListModal} 
            updatedMemberList={updatedMemberList}
            handleAddMemberModal={handleAddMemberModal}
            handleRouteAddOutsideMember={handleRouteAddOutsideMember}
            handleRouteInviteNewMember={handleRouteInviteNewMember}
            />
        }
        {openAddMemberModal && selectedOrganization &&
        <AddMemberModal btnType='hideBtn' remoteOpen={openAddMemberModal} membersList={membersList} selectedOrganization={selectedOrganization} 
            handleCloseAddMemberModal={handleCloseAddMemberModal} 
            updatedMemberList={updatedMemberList}
            addOutsideOrgMember={addOutsideOrgMember}
            inviteNewMember={inviteNewMember}
            />
        }
    </div>
  )
}

export default MembersHeader