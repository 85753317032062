import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { apiLink } from '../ApiCalls/ApisVariables'
import { deleteData } from '../ApiCalls/DataApis'
import OptimizeImage from './OptimizeImage'
import { TrashIcon } from '@heroicons/react/solid'
import EditAssetActivityModal from './modals/assetmodals/EditAssetActivityModal'
import { getInitialCropFromCroppedAreaPixels } from 'react-easy-crop'
import moment from "moment"
import { darkModeState } from '../atoms/darkModeState'
import { useRecoilValue } from 'recoil'

function AssetActivityCard({activityItem, refreshActivityList, operationsList, handleCloseModal}) {
    const isDark = useRecoilValue(darkModeState)
    const [deleteCurrentActivity, setDeleteCurrentActivity] = useState(false)
    const [loading, setLoading] = useState(false)
    const [highlightNewTimes, setHighlightNewTimes] = useState(false)
    const [isBouncing, setIsBouncing] = useState(false)

    useEffect(() =>{
        if(activityItem.utilization_end_timestamp){
            var currentTime = new Date().getTime() - 120000
            var currentEndTime = new Date(activityItem.utilization_end_timestamp).getTime()
            if(currentEndTime >  currentTime){
                setHighlightNewTimes(true)
                setIsBouncing(true)
                setTimeout(() => {
                    setIsBouncing(false)
                 }, 1550)
            }

        }

    },[activityItem])

    const formatDate = (end) => {
        if(end){
            // var startDate = new Date(start)
            // var startHours = startDate.getHours();
            // var startMinutes = startDate.getMinutes();
            // var startAmPm = startHours >= 12 ? 'pm' : 'am';
            // startHours = startHours % 12;
            // startHours = startHours ? startHours : 12; // the hour '0' should be '12'
            // startMinutes = startMinutes < 10 ? '0'+startMinutes : startMinutes;
            // var startStrTime = startHours + ':' + startMinutes + ' ' + startAmPm;
            // var shortStartDate = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2)

            var endDate = new Date(end)
            var endHours = endDate.getHours();
            var endMinutes = endDate.getMinutes();
            var endAmPm = endHours >= 12 ? 'pm' : 'am';
            endHours = endHours % 12;
            endHours = endHours ? endHours : 12; // the hour '0' should be '12'
            endMinutes = endMinutes < 10 ? '0'+endMinutes : endMinutes;
            var endStrTime = endHours + ':' + endMinutes + ' ' + endAmPm;
            var shortEndDate = endDate.getMonth()+1 + "/" + endDate.getDate() + "/" + endDate.getFullYear().toString().substr(-2)
            // var endTime = endDate.getMonth()+1 + "/" + endDate.getDate() + "/" + endDate.getFullYear().toString().substr(-2) + " " + endStrTime;
            // var startTime = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2) + " " + startStrTime;
            return shortEndDate + "  " + endStrTime;

            // if(shortStartDate == shortEndDate){
            //     return shortStartDate + "  " + startStrTime +  " - " + endStrTime;
            // }else{
            //     return shortStartDate + " " + startStrTime + " - " + shortEndDate + " " + endStrTime;
            // }
        }
    }

    const submitDeleteActivity = () => {
        try {
            if(!loading){
                setLoading(true)
                deleteData(apiLink + '/v1/utilizations/' + activityItem.utilization_id )
                    .then(async response => {
                      if(response.ok){ 
                        toast.success('Activity has been removed')
                        setLoading(false)
                        setDeleteCurrentActivity(false)
                        refreshActivityList()
                        if(handleCloseModal){
                            handleCloseModal()
                        }
                      }
                      else{
                        try{
                          const data = await response.json();
                          toast.error(data.message)
                          setLoading(false)
                        }catch (e){
                          toast.error('Something went wrong');
                          setLoading(false)
                        }
                      }
                    })
        
              }
            
        } catch (error) {
            
        }
    }

    const calculateDuration = (start, finish) => {
        
        if(start && finish){
            let startMilliSecs = new Date(start).getTime()
            let finishMilliSecs = new Date(finish).getTime()
            let duration = (finishMilliSecs - startMilliSecs)/1000
            let formattedTime = formatTime(duration)
            return formattedTime
        }

    }

    const formatTime = (seconds) => {
        if(seconds){
          let formatted = moment.utc(seconds*1000).format('HH:mm:ss.SS')
          return formatted
        }else{
            let formatted = moment.utc(0).format('HH:mm:ss.SS')
            return formatted
        }
    }

    
  return (
    <div className={`mx-1 my-1 ${highlightNewTimes ? 'bg-blue-200 bg-opacity-70 dark:bg-blue-900 dark:bg-opacity-50':'bg-[#187bcd] bg-opacity-10'} ${isBouncing ? 'animate-bounce':''} p-1 rounded`}>
            {!deleteCurrentActivity && 
            <div >
                {/* {index === 0 ? (
                    <div className='my-1 py-1 border-b-2 border-gray-200 dark:border-gray-700'>
                        <div className='text-blue-900 text-left dark:text-blue-600 font-semibold text-sm uppercase'>{event.date}</div>
                    </div>
                ) : (
                    <div className=''>
                        <div className='text-gray-600 text-left dark:text-gray-400 font-semibold text-sm uppercase'>{event.date}</div>
                    </div>
                )} */}
                <div className='flex flex-col border-l-2 border-blue-300 pl-2 '>
                    <div className='flex items-center' >
                        <div className='flex flex-col pl-3 max-w-[300px]'>
                            <div>
                                <span className={`${isDark? 'text-gray-400':'text-gray-600'} text-left font-normal text-xs uppercase`}>{formatDate(activityItem.utilization_start_timestamp)}</span>
                            </div>
                            <p className={`${isDark? 'text-gray-200':'text-gray-600'} text-sm truncate leading-5 font-medium`}>Running<span className='text-xs pl-1'>unclassified</span></p>
                            <p className={`${isDark? 'text-gray-200':'text-gray-600'} text-sm truncate leading-4 font-medium`}>{formatTime(activityItem.duration_s)}</p>
                            {/* <p className='text-sm truncate leading-4 font-medium text-gray-600 dark:text-gray-200'>{calculateDuration(activityItem.utilization_start_timestamp, activityItem.utilization_end_timestamp)}</p> */}
                        </div>
                        <div className='ml-auto pl-2'>
                            <EditAssetActivityModal btnType='inlineIconBtn' activityItem={activityItem}  refreshActivityList={refreshActivityList} operationsList={operationsList} handleCloseModal={handleCloseModal} />
                        </div>
                        <button className={`${isDark? 'text-blue-400 hover:text-blue-200':' text-blue-500 hover:text-blue-900'} cursor-pointer flex ml-2`} onClick={() => setDeleteCurrentActivity(true)}>
                            <TrashIcon className={`w-5 h-5`}/>
                        </button> 
                    </div>
                </div>
            </div>
            }
            {deleteCurrentActivity && 
            <div className={`flex ml-2 items-center pt-1.5 pb-3 ${isDark? 'border-slate-700':''} border-b-[0.01em]`}>
                <span className={`text-xs text-left font-light ${isDark? 'text-gray-300':'text-gray-600'}`}>
                    Are you sure you want to delete activity at {formatDate(activityItem.utilization_end_timestamp)}?
                </span>
                <div className={`shadow rounded-md py-1 px-2 border-[1px] text-xs ${isDark? 'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} cursor-pointer flex ml-2`} onClick={() => setDeleteCurrentActivity(false)}>
                    Close
                </div>
                <div className={`relative shadow rounded-md ml-1 py-1 px-2 border-[1px] border-red-600 text-xs bg-red-600 hover:bg-red-700 hover:border-red-700 text-white cursor-pointer`} onClick={submitDeleteActivity}>
                    Delete
                    {loading && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-0 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                    }
                </div>
            </div>
        }
        
    </div>
  )
}

export default AssetActivityCard