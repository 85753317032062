import { useEffect, useState } from 'react'
import HighchartsStock from 'highcharts/highstock';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import HighchartsReact from "highcharts-react-official";
import { useRecoilValue } from 'recoil';
import { darkModeState } from '../../atoms/darkModeState';

import HighchartsMore from 'highcharts/highcharts-more';
import SolidGauge from 'highcharts/modules/solid-gauge';
import { createColorPalette } from '../../styles/gaugesColorPalette';

HighchartsMore(Highcharts);
SolidGauge(Highcharts);


function MultiCountGaugeXL({opsList}) {
    const [hoveredSeriesIndex, setHoveredSeriesIndex] = useState(null);
    const isDark = useRecoilValue(darkModeState)
    const [totalPartsMade, setTotalPartsMade] = useState(null)
    const [totalPartsString, setTotalPartsString] = useState(null)
    const [currentPartsMade, setCurrentPartsMade] = useState(null)
    const [currentPartsString, setCurrentPartsString] = useState(null)
    const [countPieChartOptions, setCountPieChartOptions] = useState({ 
        chart: {
            type: 'solidgauge',
            height: '100%',
            backgroundColor: 'transparent',
          },
          credits: {enabled: false},
          title: {
            text: '',
          },
          tooltip: {
            enabled: false, // Disable default tooltip
          },
            pane: [
            {
              startAngle: 0,
              endAngle: 360,
              background: [
                {
                  outerRadius: '100%',
                  innerRadius: '88%',
                  backgroundColor: 'transparent',
                  borderWidth: 0,
                },
              ],
              center: ['50%', '50%'],
              size: '100%',
            },
            {
              startAngle: 0,
              endAngle: 360,
              background: [
                {
                  outerRadius: '87%',
                  innerRadius: '63%',
                  backgroundColor: 'transparent',
                  borderWidth: 0,
                },
              ],
              center: ['50%', '50%'],
              size: '100%',
            },
          ],
        yAxis: [
            {
              min: 0,
              max: 100,
              lineWidth: 0,
              tickPositions: [],
              pane: 0,
            },
            {
              min: 0,
              max: 100,
              lineWidth: 0,
              tickPositions: [],
              pane: 1,
            },
          ],
        plotOptions: {
            solidgauge: {
              dataLabels: {
                enabled: true,
                y: -20,
                borderWidth: 0,
                useHTML: true,
              },
              linecap: 'round',
              rounded: true,
              stickyTracking: false,
            },
          },
        
    })

    useEffect(() => {
        if(opsList && opsList.operations && Object.keys(opsList.operations).length > 1){
            let totalPotential = Number(opsList.possible_operation_count)
            let totalMade = Number(opsList.operation_count) 
            let currentOperation = opsList.operations[0]
            let currentMade = Number(currentOperation.operation_count)
            let currentPotential = Number(currentOperation.possible_operation_count)

            setTotalPartsMade(totalMade)
            setCurrentPartsMade(currentMade)

            let colors = createColorPalette(isDark)
            let totalPrimaryColor =  colors.secondaryPalette.primaryGreen
            let totalSecondaryColor = colors.secondaryPalette.secondaryGreen
            // let totalPrimaryColor =  isDark ? '#22c55e' :'#22c55e' //500
            // let totalSecondaryColor = isDark ?  '#14532d' :'#86efac' //300

            let total_efficiency_percent = Math.round(Number(opsList.operation_production_count_efficiency) * 100)
            let total_goal_percent = Math.round(Number(opsList.asset_oee_goal) * 100)
            if(total_goal_percent > total_efficiency_percent){  
                totalPrimaryColor = colors.secondaryPalette.primaryRed
                totalSecondaryColor = colors.secondaryPalette.secondaryRed
                // totalPrimaryColor = isDark ? '#991b1b' : '#ef4444' //600 
                // totalSecondaryColor = isDark ? '#450a0a' : '#fca5a5' // 950 & 300
            }

            
            let currentPrimaryColor = colors.mainPalette.primaryGreen
            let currentSecondaryColor = colors.mainPalette.secondaryGreen
            // let currentPrimaryColor = isDark ? '#4ade80'    :'#16a34a' //600
            // let currentSecondaryColor = isDark ?  '#166534' : '#4ade80' //400
            let current_efficiency_percent = Math.round(Number(currentOperation.operation_production_count_efficiency) * 100)
            let current_goal_percent = Math.round(Number(currentOperation.asset_oee_goal) * 100)
            if(current_goal_percent > current_efficiency_percent){  
                // currentPrimaryColor = isDark ?  '#b91c1c' : '#dc2626' //500 && 600
                // currentSecondaryColor = isDark ?  '#450a0a' : '#f87171' //900 && 400
                currentPrimaryColor =   colors.mainPalette.primaryRed
                currentSecondaryColor = colors.mainPalette.secondaryRed
            }


            const goalPercentage = opsList.asset_oee_goal; // Goal is set at 80%
            const goalValue = totalPotential * goalPercentage; // Calculate the goal value based on 80%


            setCountPieChartOptions({
                chart: {
                    type: 'solidgauge',
                    height: '100%',
                    backgroundColor: 'transparent',
                  },
                  credits: {enabled: false},
                  title: {
                    text: '',
                  },
                  tooltip: {
                    enabled: false, // Disable default tooltip
                  },
                //   tooltip: {
                //     borderWidth: 0,
                //     backgroundColor: 'none',
                //     shadow: false,
                //     style: {
                //         fontSize: '16px',
                //         fontColor:'#fff'
                //     },
                //     valueSuffix: '%',
                //     pointFormat: '<span style="font-size: 1em; color: {point.color}">{series.name}</span><br> ' +
                //         '<span style="font-size: 2em; color: {point.color}; ' +
                //         'font-weight: bold">{point.y}</span>',
                //     positioner: function (labelWidth) {
                //         return {
                //             x: (this.chart.chartWidth - labelWidth) / 2,
                //             y: (this.chart.plotHeight / 2) - 25
                //         };
                //     }
                // },
                    pane: [
                    {
                      startAngle: 0,
                      endAngle: 360,
                      background: [
                        {
                          outerRadius: '100%',
                          innerRadius: '83%',
                          backgroundColor: totalSecondaryColor,
                          borderWidth: 0,
                        },
                      ],
                      center: ['50%', '50%'],
                      size: '100%',
                    },
                    {
                      startAngle: 0,
                      endAngle: 360,
                      background: [
                        {
                          outerRadius: '80%',
                          innerRadius: '63%',
                          backgroundColor: currentSecondaryColor,
                          borderWidth: 0,
                        },
                      ],
                      center: ['50%', '50%'],
                      size: '100%',
                    },
                  ],
                yAxis: [
                    {
                      min: 0,
                      max: totalPotential,
                      lineWidth: 0,
                      tickPositions: [],
                      pane: 0,
                    //   plotBands: [
                    //     {
                    //       from: goalValue,
                    //       to: goalValue + 100, // Set a small width for the goal indicator
                    //       color: '#FFF', // Blue color to indicate the goal
                    //       thickness: '20%', // Thickness of the goal indicator
                    //       innerRadius: '55%',
                    //       outerRadius: '105%',
                    //       label: {
                    //         text: '',
                    //       },
                    //     },
                    //   ],
                    },
                    {
                      min: 0,
                      max: currentPotential,
                      lineWidth: 0,
                      tickPositions: [],
                      pane: 1,
                    //   plotBands: [
                    //     {
                    //       from: currentGoalValue,
                    //       to: currentGoalValue + 10,
                    //       color: '#FFF',
                    //       thickness: '5%',
                    //       innerRadius: '55%',
                    //       outerRadius: '105%',
                    //     },
                    //   ],
                    },
                  ],
                plotOptions: {
                    // series: {
                    //     borderWidth: 2,
                    //     borderColor: 'black'
                    // },
                    solidgauge: {
                      dataLabels: {
                        enabled: false,
                        
                        // y: -20,
                        // borderWidth: 0,
                        // useHTML: true,
                        // formatter() {
                        //   return `<div style="text-align:center;">
                        //             <span style="font-size:16px;">${this.y}</span><br/>
                        //             <span style="font-size:12px;color:#666;">${
                        //               this.series.name
                        //             }</span>
                        //           </div>`;
                        // },
                      },
                      linecap: 'round',
                      rounded: true,
                      stickyTracking: false,
                      point: {
                        events: {
                          mouseOver() {
                            setHoveredSeriesIndex(this.series.index);
                          },
                          mouseOut() {
                            setHoveredSeriesIndex(null);
                          },
                        },
                      },
                    },
                  },
                  series: [
                    {
                      name: 'Total Ops',
                      data: [
                        {
                          color: totalPrimaryColor,
                          radius: '100%',
                          innerRadius: '83%',
                          y: totalMade
                        },
                      ],
                      yAxis: 0,
                    },
                    {
                      name: currentOperation.operation_name,
                      data: [
                        {
                          color: currentPrimaryColor,
                          radius: '80%',
                          innerRadius: '60%',
                          y: currentMade,
                        },
                      ],
                      yAxis: 1,
                    },
                    // Series for Goal Indicator (Small Dial)
                    {
                        name: 'Goal',
                        type:'gauge',
                        data: [
                        {
                            color: 'transparent',
                            backgroundColor: 'transparent',
                            y: goalValue,
                        },
                        ],
                        dial: {
                            color: '#fdba74',
                            backgroundColor: '#FFF',
                            baseWidth: 0, // Thinner base width for the marker
                            topWidth: 10, // Same width at the top
                            baseLength: '90%', // Keep it short, so it's a small marker
                            radius: '100%', // Position just outside the gauge arc
                        },
                        pivot: {
                            radius: 0, // Hide the central pivot point

                        },
                        tooltip: {
                            enabled: false, // No tooltip for the goal
                        },
                        yAxis: 0,
                        showInLegend: false, // Don't show in legend
                        dataLabels: {
                            enabled: false, // Hide the value for the goal indicator
                          },
                    },
                  ],
            })
        }

    }, [opsList, isDark])

    useEffect(() => {
        if(totalPartsMade){

            let centerTotalValue = totalPartsMade
            let currentTotalCount = totalPartsMade
            
            if(typeof currentTotalCount == 'number'){
                if(currentTotalCount > 1000000){
                centerTotalValue = currentTotalCount/1000000
                centerTotalValue = centerTotalValue.toFixed(1)
                centerTotalValue = centerTotalValue+ 'M'
                }else if(currentTotalCount > 100000){
                centerTotalValue = currentTotalCount/1000
                centerTotalValue = centerTotalValue.toFixed(0)
                centerTotalValue = centerTotalValue+ 'k'
                }else if(currentTotalCount > 10000){
                centerTotalValue = currentTotalCount/1000
                centerTotalValue = centerTotalValue.toFixed(1)
                centerTotalValue = centerTotalValue+ 'k'
                }

                setTotalPartsString(centerTotalValue)
            }
        
        }

    },[totalPartsMade])

    useEffect(() => {
        if(currentPartsMade){

            let centerTotalValue = currentPartsMade
            let currentTotalCount = currentPartsMade
            
            if(typeof currentTotalCount == 'number'){
                if(currentTotalCount > 1000000){
                centerTotalValue = currentTotalCount/1000000
                centerTotalValue = centerTotalValue.toFixed(1)
                centerTotalValue = centerTotalValue+ 'M'
                }else if(currentTotalCount > 100000){
                centerTotalValue = currentTotalCount/1000
                centerTotalValue = centerTotalValue.toFixed(0)
                centerTotalValue = centerTotalValue+ 'k'
                }else if(currentTotalCount > 10000){
                centerTotalValue = currentTotalCount/1000
                centerTotalValue = centerTotalValue.toFixed(1)
                centerTotalValue = centerTotalValue+ 'k'
                }

                setCurrentPartsString(centerTotalValue)
            }
        
        }

    },[currentPartsMade])


    return (
        <div className={`relative w-[190px] h-34 py-1 pr-1 `}>
            <div className='absolute top-[30%] w-full text-center'>
            {hoveredSeriesIndex === 0 ? (
                <h2 className={`${currentPartsMade > 999  ? 'text-4xl':'text-5xl'} text-gray-600 dark:text-gray-200 font-medium`}>{totalPartsString}</h2> // Display Part A otherwise
            ):(
                <h2 className={`${currentPartsMade > 999  ? 'text-4xl':'text-5xl'} text-gray-600 dark:text-gray-200 font-medium`}>{currentPartsString}</h2> // Display Part A otherwise
            )}
            </div>
            <div  className={`absolute top-[52%] w-full text-center`} >
              <span className='text-xl text-gray-400 dark:text-gray-300 font-medium' >made</span>
            </div>
            <div className='mt-[-12px]'>
                <HighchartsReact
                    highcharts={Highcharts}
                    containerProps={{ style: { height: 190, width:190} }} 
                    constructorType={'chart'}
                    options={countPieChartOptions}
                />
            </div>
        </div>
    )
}

export default MultiCountGaugeXL
