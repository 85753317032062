import { Routes, Route } from "react-router-dom";
import ConfirmForgotPasswordPage from "./ConfirmForgotPasswordPage";
import LoginPage from "./LoginPage";
import ResetPage from "./ResetPage";
import SignupPage from "./SignupPage";
import VerifyEmailPage from "./VerifyEmailPage";

function LoginRoutes() {
  return (
    <div>
        <Routes>
            <Route exact path="/" element={<LoginPage />}  />
            <Route exact path="/login" element={<LoginPage />}  />
            <Route exact path="/confirm-forgot-password" element={<ConfirmForgotPasswordPage />} />
            <Route exact path="/verify-email" element={<VerifyEmailPage /> }/>
            <Route exact path="/reset" element={<ResetPage />} />
            <Route exact path="/signup" element={<SignupPage />} />
            <Route exact path="*" element={<LoginPage />}  />

        </Routes>
    </div>
  )
}

export default LoginRoutes