import React, { useEffect, useState } from 'react'
import StackedBarChartSmallParts from '../../components/charts/PartsHistoricalReportsCharts/StackedBarChartSmallParts';
import OptimizeLargeImage from '../../components/OptimizeLargeImage';
import OptimizeImage from '../../components/OptimizeImage';
import { useSearchParams } from 'react-router-dom';

function PartsHistoricalReportTableRow({operation, dateCategories, toggleShowScrap}) {
    const [isMobile, setIsMobile] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
       
        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }

    },[])

    const addCommasInNumbers = (num) => {
        if(num){
            const out = num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

            // const out = num.toLocaleString();
    
            return out
        }else{
            return '0'
        }
    }

    const formatCycleTime = (value) => {
        if(value){
            if(value.includes('.'))
            {
                value = value.split('.')[0] + '.' + value.split('.')[1].slice(0, 2);
            }
            else
            {
                value += ".00"
            }
            return value
        }

    }

  return (
    <div className='mt-2 flex flex-col rounded-xl bg-white dark:bg-slate-800 shadow-iotflows-lg '>
        <div className={`flex ${isMobile ? 'flex-col': 'flex-row items-center'} `}>
            <div className={`text-sm font-normal text-gray-600 dark:text-gray-300 py-2 pr-2 pl-4 ${isMobile ? 'w-[350px]': 'w-[225px]'} `}>
                <div className='flex flex-col'>
                    <p className='text-left leading-5 truncate text-lg text-gray-600 dark:text-gray-300 font-medium'>{operation.operation_name}</p>
                    <p className='text-left leading-5 truncate text-base text-gray-500 dark:text-gray-400 font-medium'>{operation.operation_description}</p>
                    <p className='text-left truncate text-sm text-gray-500 dark:text-gray-400 font-normal'>{operation.part_name}</p>
                </div>
            </div>
            <div className={`pr-4 bg-transparent  ${isMobile ? 'w-full': 'w-[200px]'} `}>
                <div className={`  ${isMobile ? 'grid grid-cols-2 items-center': 'flex flex-col border-l-[1px]'}   dark:border-gray-600 py-2`}>
                    <div className={`text-blue-600 dark:text-blue-300 font-medium ${isMobile ? 'flex justify-center': 'pl-3 '} `} >
                        <div className='flex items-center'>
                            <span className='pr-1.5'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"  className="w-6 h-6" viewBox="0 -960 960 960" >
                                    <path d="M600-160q-134 0-227-93t-93-227q0-133 93-226.5T600-800q133 0 226.5 93.5T920-480q0 134-93.5 227T600-160Zm0-80q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Zm91-91 57-57-108-108v-144h-80v177l131 132ZM80-600v-80h160v80H80ZM40-440v-80h200v80H40Zm40 160v-80h160v80H80Zm520-200Z"/>
                                </svg>
                            </span>
                            <span className='text-lg leading-4 '>{formatCycleTime(operation.cycle_time)}</span>
                        </div>
                    </div>
                    <div className={`text-sky-600 dark:text-sky-400 font-medium flex ${isMobile ? ' justify-center': ' mt-3  pl-3 '}`} >
                        <div className='flex items-center'>
                            <span className='pr-1.5'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"  className="w-6 h-6" viewBox="0 -960 960 960">
                                    <path d="M220-160q-25 0-42.5-17.5T160-220q0-25 17.5-42.5T220-280q25 0 42.5 17.5T280-220q0 25-17.5 42.5T220-160Zm173 0q-25 0-42.5-17.5T333-220q0-25 17.5-42.5T393-280q25 0 42.5 17.5T453-220q0 25-17.5 42.5T393-160Zm174 0q-25 0-42.5-17.5T507-220q0-25 17.5-42.5T567-280q25 0 42.5 17.5T627-220q0 25-17.5 42.5T567-160Zm173 0q-25 0-42.5-17.5T680-220q0-25 17.5-42.5T740-280q25 0 42.5 17.5T800-220q0 25-17.5 42.5T740-160ZM220-333q-25 0-42.5-17.5T160-393q0-25 17.5-42.5T220-453q25 0 42.5 17.5T280-393q0 25-17.5 42.5T220-333Zm173 0q-25 0-42.5-17.5T333-393q0-25 17.5-42.5T393-453q25 0 42.5 17.5T453-393q0 25-17.5 42.5T393-333Zm174 0q-25 0-42.5-17.5T507-393q0-25 17.5-42.5T567-453q25 0 42.5 17.5T627-393q0 25-17.5 42.5T567-333Zm173 0q-25 0-42.5-17.5T680-393q0-25 17.5-42.5T740-453q25 0 42.5 17.5T800-393q0 25-17.5 42.5T740-333ZM220-507q-25 0-42.5-17.5T160-567q0-25 17.5-42.5T220-627q25 0 42.5 17.5T280-567q0 25-17.5 42.5T220-507Zm173 0q-25 0-42.5-17.5T333-567q0-25 17.5-42.5T393-627q25 0 42.5 17.5T453-567q0 25-17.5 42.5T393-507Zm174 0q-25 0-42.5-17.5T507-567q0-25 17.5-42.5T567-627q25 0 42.5 17.5T627-567q0 25-17.5 42.5T567-507Zm173 0q-25 0-42.5-17.5T680-567q0-25 17.5-42.5T740-627q25 0 42.5 17.5T800-567q0 25-17.5 42.5T740-507ZM220-680q-25 0-42.5-17.5T160-740q0-25 17.5-42.5T220-800q25 0 42.5 17.5T280-740q0 25-17.5 42.5T220-680Zm173 0q-25 0-42.5-17.5T333-740q0-25 17.5-42.5T393-800q25 0 42.5 17.5T453-740q0 25-17.5 42.5T393-680Zm174 0q-25 0-42.5-17.5T507-740q0-25 17.5-42.5T567-800q25 0 42.5 17.5T627-740q0 25-17.5 42.5T567-680Zm173 0q-25 0-42.5-17.5T680-740q0-25 17.5-42.5T740-800q25 0 42.5 17.5T800-740q0 25-17.5 42.5T740-680Z"/>
                                </svg>
                            </span>
                            <span className='text-lg leading-4'>{`${addCommasInNumbers(operation.overridden_good_count)}`}</span>
                        </div>
                    </div>
                </div> 
                    
            </div>
            <div className={`flex-1 ${isMobile && 'pl-1 pb-1'}`}>
                <StackedBarChartSmallParts toggleShowScrap={toggleShowScrap} countArray={operation.data_good} countArrayBad={operation.data_bad} dateCategories={dateCategories}  /> 
            </div>

        </div>
        {Object.keys(operation.assets).length > 0 && operation.assets.map((asset, index) => (
            <div 
                key={`${asset.asset_uuid}-${index}`} 
                className={`flex ${isMobile ? 'flex-col mt-1': 'flex-row items-center'} `}
            >
                <div className={`text-sm font-normal text-gray-600 dark:text-gray-300 py-2 pr-2 pl-4 ${isMobile ? 'w-[350px]': 'w-[225px]'} `}>
                    <div className='flex items-center my-auto'>
                        <div className={`h-16 w-16 `}>
                            <OptimizeLargeImage alt={asset.asset_custom_name} source={asset.asset_custom_picture_url  ? asset.asset_custom_picture_url : asset.machine_picture_url} size='lg' />
                        </div>
                        <div className='flex flex-col pl-2 truncate'>
                            <p className='text-left truncate leading-5 text-lg text-gray-600 dark:text-gray-300 font-medium'>{asset.asset_custom_name ? asset.asset_custom_name : asset.machine_name}</p>
                            <p className='text-left truncate text-base text-gray-500 dark:text-gray-400 font-medium'>{asset.asset_custom_identifier ? asset.asset_custom_identifier : asset.machine_machine_type_identifier}</p>
                        </div>
                    </div>
                </div>
                <div className={`pr-4 bg-transparent  ${isMobile ? 'w-full': 'w-[200px]'} `}>
                    <div className={`  ${isMobile ? 'grid grid-cols-2 items-center': 'flex flex-col border-l-[1px]'} dark:border-gray-600 py-2`}>
                        <div className={`text-blue-600 dark:text-blue-300 font-medium ${isMobile ? 'flex justify-center': 'pl-3 '} `} >
                            <div className='flex items-center'>
                                <span className='pr-1.5'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"  className="w-6 h-6" viewBox="0 -960 960 960" >
                                        <path d="M600-160q-134 0-227-93t-93-227q0-133 93-226.5T600-800q133 0 226.5 93.5T920-480q0 134-93.5 227T600-160Zm0-80q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Zm91-91 57-57-108-108v-144h-80v177l131 132ZM80-600v-80h160v80H80ZM40-440v-80h200v80H40Zm40 160v-80h160v80H80Zm520-200Z"/>
                                    </svg>
                                </span>
                                <span className='text-lg leading-4'>{formatCycleTime(asset.cycle_time)}</span>
                            </div>
                        </div>
                        <div className={`text-sky-600 dark:text-sky-400 font-medium flex ${isMobile ? ' justify-center': ' mt-3  pl-3 '}`} >
                            <div className='flex items-center '>
                                <span className='pr-1.5'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"  className="w-6 h-6" viewBox="0 -960 960 960">
                                        <path d="M220-160q-25 0-42.5-17.5T160-220q0-25 17.5-42.5T220-280q25 0 42.5 17.5T280-220q0 25-17.5 42.5T220-160Zm173 0q-25 0-42.5-17.5T333-220q0-25 17.5-42.5T393-280q25 0 42.5 17.5T453-220q0 25-17.5 42.5T393-160Zm174 0q-25 0-42.5-17.5T507-220q0-25 17.5-42.5T567-280q25 0 42.5 17.5T627-220q0 25-17.5 42.5T567-160Zm173 0q-25 0-42.5-17.5T680-220q0-25 17.5-42.5T740-280q25 0 42.5 17.5T800-220q0 25-17.5 42.5T740-160ZM220-333q-25 0-42.5-17.5T160-393q0-25 17.5-42.5T220-453q25 0 42.5 17.5T280-393q0 25-17.5 42.5T220-333Zm173 0q-25 0-42.5-17.5T333-393q0-25 17.5-42.5T393-453q25 0 42.5 17.5T453-393q0 25-17.5 42.5T393-333Zm174 0q-25 0-42.5-17.5T507-393q0-25 17.5-42.5T567-453q25 0 42.5 17.5T627-393q0 25-17.5 42.5T567-333Zm173 0q-25 0-42.5-17.5T680-393q0-25 17.5-42.5T740-453q25 0 42.5 17.5T800-393q0 25-17.5 42.5T740-333ZM220-507q-25 0-42.5-17.5T160-567q0-25 17.5-42.5T220-627q25 0 42.5 17.5T280-567q0 25-17.5 42.5T220-507Zm173 0q-25 0-42.5-17.5T333-567q0-25 17.5-42.5T393-627q25 0 42.5 17.5T453-567q0 25-17.5 42.5T393-507Zm174 0q-25 0-42.5-17.5T507-567q0-25 17.5-42.5T567-627q25 0 42.5 17.5T627-567q0 25-17.5 42.5T567-507Zm173 0q-25 0-42.5-17.5T680-567q0-25 17.5-42.5T740-627q25 0 42.5 17.5T800-567q0 25-17.5 42.5T740-507ZM220-680q-25 0-42.5-17.5T160-740q0-25 17.5-42.5T220-800q25 0 42.5 17.5T280-740q0 25-17.5 42.5T220-680Zm173 0q-25 0-42.5-17.5T333-740q0-25 17.5-42.5T393-800q25 0 42.5 17.5T453-740q0 25-17.5 42.5T393-680Zm174 0q-25 0-42.5-17.5T507-740q0-25 17.5-42.5T567-800q25 0 42.5 17.5T627-740q0 25-17.5 42.5T567-680Zm173 0q-25 0-42.5-17.5T680-740q0-25 17.5-42.5T740-800q25 0 42.5 17.5T800-740q0 25-17.5 42.5T740-680Z"/>
                                    </svg>
                                </span>
                                <span className='text-lg leading-4'>{`${addCommasInNumbers(asset.overridden_good_count)}`}</span>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className={`flex-1 ${isMobile && 'pl-1 pb-1'}`}>
                    <StackedBarChartSmallParts type='assets' toggleShowScrap={toggleShowScrap} countArray={asset.data_good} countArrayBad={asset.data_bad} dateCategories={dateCategories}   /> 
                </div>
            </div>
        ))}
    </div>
  )
}

export default PartsHistoricalReportTableRow