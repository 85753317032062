import { useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil';
import {darkModeState} from '../../atoms/darkModeState'
import { motion } from "framer-motion";
import TagListForInput from './TagListForInput';
import { getData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { globalSelectedOrganization } from '../../atoms/orgStates'


function TagInput({tags, onKeyDown, removeTag, handleAddTagFromList, addTag}) {
    const isDark = useRecoilValue(darkModeState);
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [showTagList, setShowTagList] = useState(false)
    const [databaseTagList, setDatabaseTagList] = useState([])

    useEffect(() => {
        try {
          if( selectedOrganization){
            // Get list of machines
            getData(apiLink + '/v1/organizations/' + selectedOrganization + '/organization_assets/tags')
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data.data){
                //   console.log('Tag list',data.data)
                  setDatabaseTagList(data.data)
                }
              }
            });
  
          }
        } catch (error) {
          console.log(error.message)
        }
      },[selectedOrganization])

    const handleShowTagList = () =>{
        setShowTagList(!showTagList)
    }

  return (
    <div className={`mt-2`}>
        <label className={`block text-left text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`} htmlFor="inputTags">
            Tags
        </label>
        {/* tag input container */}
        <div className={`w-full flex flex-wrap gap-0.5 my-auto rounded-md border-[1px] py-1 ${isDark?' border-gray-600 bg-slate-700':'border-gray-300'}`}>
            {/* tag inputs that will autopopulate  */}
            {tags?.map((tag,index) => (
                <div key={`${tag}-input`} className={`h-7 w-fit inline-block flex text-white ${isDark?' bg-slate-900':'bg-slate-500'} text-xs rounded-md items-center justify-center ml-1 pl-3 pr-2`}>
                    <span >{tag}</span>
                    <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  onClick={() => removeTag(index)}
                                    className='inline-flex rounded-full bg-white text-gray-500 ml-2 pb-0.5 w-4 h-4 items-center justify-center text-base cursor-pointer'
                    >
                        &times;
                    </motion.button>
                </div>
            ))}
            

            <input className={`block border-0 grow min-w-[50px] ml-1 pl-1 sm:text-sm rounded-md text-ellipsis py-1 
                        ${isDark?'placeholder:text-gray-500 text-gray-300 focus:border-transparent focus:ring-transparent bg-slate-700':' placeholder:text-gray-400 text-gray-600 focus:border-transparent focus:ring-transparent'}`}
                id='inputTags'
                onKeyDown={onKeyDown}
                // onChange={} 
                onClick={handleShowTagList} 
                type='text' 
                placeholder="Select or start typing tags" 
            />
        </div>
        
        {databaseTagList && Object.keys(databaseTagList).length > 0 && showTagList &&
            <div className='mt-2'>
                <div className={`${isDark?'bg-slate-700 ':'bg-white '} w-full flex flex-col p-0.5 shadow-iotflows-lg rounded-lg max-h-[220px] overflow-auto scrollbar-hide`}   >
                    {databaseTagList?.map((tag, index) => (
                        <div key={tag.tag_id}>
                            <TagListForInput tag={tag} handleAddTagFromList={handleAddTagFromList} />
                        </div>
                    ))}
                </div>
            </div>
        }
        
        
    </div>
  )
}

export default TagInput