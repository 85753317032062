import SubNav from "../components/SubNav"
import DevicesManagementPage from "./DevicesManagementPage";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {globalSelectedOrganization} from '../atoms/orgStates'

// const subNav = {
//     management: {
//         name:'Management',
//         pathName:'/management',
//         search:'?select=management',
//         url:'management',
//     },
//     // sensors: {
//     //     name:'Sensors',
//     //     pathName:'/sensors',
//     //     search:'?select=sensors',
//     //     url:'sensors',
//     // },
//     // gateways: {
//     //     name:'Gateways',
//     //     pathName:'/gateways',
//     //     search:'?select=gateways',
//     //     url:'gateways',
//     // },
// }

function DevicesPage() {
    const [selectedSubLink, setSelectedSubLink] = useState(null)
    let location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);

    const subNav = {
        management: {
            name:'Management',
            pathName:`/${selectedOrganization}/devices`, 
            search:'?select=management',
            url:'management',
        },
        // sensors: {
        //     name:'Sensors',
        //     pathName:`/${selectedOrganization}/devices`,
        //     search:'?select=sensors',
        //     url:'sensors',
        // },
        // gateways: {
        //     name:'Gateways',
        //     pathName:`/${selectedOrganization}/devices`,
        //     search:'?select=gateways',
        //     url:'gateways',
        // },
    }

    useEffect(() => {
        if(selectedOrganization) {
            setSelectedSubLink(subNav['management'])
        }

    }, [selectedOrganization])
    
    useEffect(() => {
        // let currentPath = location.pathname.split('/')
        let currentSearch = location.search
        if(currentSearch){
            // Pulling to see how cards should be grouped
            let selected = searchParams.get('select')
            
            if(selected){
                setSelectedSubLink(subNav[selected])
            }
        }

    }, [location, selectedOrganization])

    return (
        <div className='pl-6 pr-3 py-1 max-h-[calc(100vh-5rem)] overflow-scroll scrollbar-hide'> 
            {/* nagivations links within assets page */}
            {selectedOrganization && selectedSubLink && <SubNav subNav={subNav} selectedSubLink={selectedSubLink} />}

            {selectedOrganization && selectedSubLink && subLinkPage(selectedSubLink) }
            
        </div>
    )
}

export default DevicesPage

const subLinkPage = (link) => {
    let linkUrl = link.url
    const allLinks = {
        management: { link: "management", content: <DevicesManagementPage /> },
        // sensors: { link: "sensors", content: <div>SENSOR table and its logs 🥬</div>},
        // gateways: { link: "management", content: <div>Gateway table and its logs</div>},
    };

      return ( 
        <AnimatePresence mode='wait'>
            <motion.div key={allLinks[linkUrl].link}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
            >
                {allLinks[linkUrl].content} 
            </motion.div>
      </AnimatePresence>
    )  
            
}

