export const triggerVibration = (pattern = 50) => {
    if ('vibrate' in navigator) {
        console.log('vibrate')
      navigator.vibrate(pattern);
    }
  };


export const shouldAddPin = (message, userInfo) => {
    if (message && message.message_is_pinned_for_all) {
        return(
          <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4 rotate-45 pr-0.5'  viewBox="0 -960 960 960"  fill="currentColor">
            <path d="M640-760v280l68 68q6 6 9 13.5t3 15.5v23q0 17-11.5 28.5T680-320H520v234q0 17-11.5 28.5T480-46q-17 0-28.5-11.5T440-86v-234H280q-17 0-28.5-11.5T240-360v-23q0-8 3-15.5t9-13.5l68-68v-280q-17 0-28.5-11.5T280-800q0-17 11.5-28.5T320-840h320q17 0 28.5 11.5T680-800q0 17-11.5 28.5T640-760ZM354-400h252l-46-46v-314H400v314l-46 46Zm126 0Z"/>
          </svg>
        )
    }else if(message && message.message_is_pinned_for_usernames && message.message_is_pinned_for_usernames.length > 0 ){
      const userHasMessagePinned = message.message_is_pinned_for_usernames.some((username) => username === userInfo.user_username);
      if(userHasMessagePinned){
        return(
          <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4 rotate-45 pr-0.5'  viewBox="0 -960 960 960"  fill="currentColor">
            <path d="M640-760v280l68 68q6 6 9 13.5t3 15.5v23q0 17-11.5 28.5T680-320H520v234q0 17-11.5 28.5T480-46q-17 0-28.5-11.5T440-86v-234H280q-17 0-28.5-11.5T240-360v-23q0-8 3-15.5t9-13.5l68-68v-280q-17 0-28.5-11.5T280-800q0-17 11.5-28.5T320-840h320q17 0 28.5 11.5T680-800q0 17-11.5 28.5T640-760ZM354-400h252l-46-46v-314H400v314l-46 46Zm126 0Z"/>
          </svg>
        )
      }
    }
  };