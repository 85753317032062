import React from 'react'
import InteractionsComponent from '../utils/InteractionsComponent'
import { formatDateToAmPm } from '../../../utils/DateFormating'
import { Avatar } from '@mui/material'
import DOMPurify from 'dompurify';
import { shouldAddPin } from '../../../utils/messagingUtils';

function ReceiverEmojisOnlyMessageComponent({isMobile, message, scrollToMessage, emojiTextSize, userInfo, updateEmoji, participants, isGroup, showImage, handleUserBGColor}) {
    const cleanHTML = DOMPurify.sanitize(message.message_text_html || message.message_text); // Sanitize the HTML content
    // var cleanReplyHTML = ''; // Sanitize the HTML content

    // if(message.message_replied_from && Object.keys(message.message_replied_from).length > 0 && message.message_replied_from.message_uuid){
    //     cleanReplyHTML = DOMPurify.sanitize(message.message_replied_from.message_text);
    // }

  return (
    <>
    <div 
        className={` pl-4 pr-4 py-1.5 rounded-2xl ${isMobile ? 'max-w-[300px]':'max-w-[500px]'} flex flex-wrap items-center ml-2 order-2 bg-transparent`}
    >
        {message.message_replied_from && Object.keys(message.message_replied_from).length > 0 && message.message_replied_from.message_uuid &&
            <div  className='bg-slate-100 dark:bg-slate-800 w-full rounded-t-2xl rounded flex mt-1 cursor-pointer text-sm px-3 py-2' onClick={() => scrollToMessage(message.message_replied_from.message_uuid)}>
                <div className={`bg-sky-400 dark:bg-sky-600 w-[3px] min-w-[3px] rounded-l-full`} />
                <div className={`bg-sky-400/20 dark:bg-sky-600/20  rounded-r-lg pl-2 py-0.5 pr-2 flex flex-col truncate overflow-hidden grow`}>
                    <span className={`text-sky-600 dark:text-sky-500 `}>{`${message.message_replied_from.user_first_name? message.message_replied_from.user_first_name : ''} ${message.message_replied_from.user_last_name ? message.message_replied_from.user_last_name : ''}`}</span>
                    <span className={`text-gray-500 dark:text-gray-400 truncate `} >{message.message_replied_from.message_text}</span>
                    {/* <div
                        className="text-gray-500 dark:text-gray-400 h-5"
                        dangerouslySetInnerHTML={{ __html: cleanReplyHTML }} // Set the HTML content directly
                    /> */}
                </div>
            </div>
        }
        <div className='flex flex-col w-full '>
            {/* <span style={{fontSize: emojiTextSize}} className=''>{message.message_text}</span> */}
            <div
                style={{fontSize: emojiTextSize}} 
                className="message-content"
                dangerouslySetInnerHTML={{ __html: cleanHTML }} // Set the HTML content directly
            />
            <span className={`flex text-gray-500 dark:text-gray-300 text-xs ml-auto mt-auto text-right italic pl-3  pr-2 text-nowrap`}>
            {shouldAddPin(message, userInfo)}{message.message_is_edited && 'edited  '}{formatDateToAmPm(message.message_created_at)}
            </span>
                <div className='flex pt-1'>
                    <InteractionsComponent message={message} userInfo={userInfo} updateEmoji={updateEmoji} participants={participants} />
                </div>
            
        </div>
    </div>
     {/*THIS WILL SHOW THE SENDERS IMAGE ON THE LEFT SIDE BUT I DONT THINK ITS NEEDED */}   
        <div className={`${isGroup ? 'w-8 h-8': 'hidden'} order-1 `}>
            {showImage &&
                <Avatar
                    sx={{ 
                        bgcolor: participants && participants[message.user_username] && participants[message.user_username].user_color ? participants[message.user_username].user_color:'',  
                        width: 32, 
                        height: 32 }}
                    style={{
                        background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${participants && participants[message.user_username] && participants[message.user_username].user_color ? participants[message.user_username].user_color:''} 100%)`,
                        }}
                    alt={participants[message.user_username].user_first_name? participants[message.user_username].user_first_name : ''}
                    src={participants[message.user_username].user_image_url}
                />
            }
        </div>
    </>
  )
}

export default ReceiverEmojisOnlyMessageComponent