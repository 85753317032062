import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import { useState, useEffect } from "react";
import {machineData} from '../../MachineTestData'
import { useRecoilValue } from "recoil";
import { darkModeState } from "../../atoms/darkModeState";

// highchartsMap(Highcharts);
HighchartsHeatmap(Highcharts);


function getPointCategoryName(point, dimension) {  
    
    var series = point.series,
        isY = dimension === 'y',
        axis = series[isY ? 'yAxis' : 'xAxis'];  
    if(isY){
       return axis.categories[point['y']].slice(0,2)
    }else{
        return axis.categories[point['x']]
    }
  }

var dataName = function(data) {
    // return '<span>IMages<img src=' + imgSrc + ' ' + 'style="width: 40px; height: 40px;"/><br></span>';
    var machineArray = []
    data.map((machine,index) => (
        machineArray.push([machine.name, machine.machineId,machine.imageURL,])
    ))
    return machineArray
};

function generateData() {
    var data = [];
    var dateInit = 1536796800000;
  
    for (var i = 0; i < 30; i++) {
      var date = 1536796800000 + i * 1000 * 60 * 60 * 24;
      for (var j = 1; j < 24; j++) {
        var hour = j * 1000 * 60 * 60
        var value = Math.round(Math.random() * 10);
        // console.log(data, hour, value)
        data.push([
          date,
          hour,
          value
        ]);
      }
    }
    // console.log('Generated data', data)
    return data;
  }





function HeatMapLarge({data, vibrationTfm}) {
    const isDark = useRecoilValue(darkModeState);
    const [heatmapOptions, setHeatmapOptions] = useState({
        chart: {
            type: 'heatmap',    
            backgroundColor:'transparent'        
        },
        time: {
          useUTC: false
        },
        title: {
            text: null
        },
        credits: {enabled: false},
        
    })

    useEffect(() => {
        setHeatmapOptions({
            chart: {
                type: 'heatmap',
                backgroundColor:'transparent' 
              },
            time: {
              useUTC: false
            },
            title: {
                text: null
            },
            tooltip: {
                enabled: false
            },
            credits: {
              text: 'iotflows.com',
              href: 'http://www.iotflows.com'
            },
            boost: {
                useGPUTranslations: true
            },
            xAxis: {
                // type: 'datetime',
                categories: vibrationTfm.timestamps,
                // labels: {
                //     align: 'left',
                //     x: 5,
                //     y: 14,
                //     format: '{value:%b, %e, %y, %k:%M}' // long month
                // },
                // showLastLabel: false,
                lineWidth: 0,
                minorGridLineWidth: 0,
                minorTickLength: 0,
                tickLength: 0,
                gridLineWidth: 0,
            },
            yAxis: {
                categories: vibrationTfm.frequencies, //['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
                title: {
                    text: null
                },
                labels: {
                    format: '{value} Hz'
                },
                // startOnTick: false,
                // endOnTick: false,
                min: 0,
                // max: 1600
                lineWidth: 0,
                minorGridLineWidth: 0,
                minorTickLength: 0,
                tickLength: 0,
                gridLineWidth: 0,
                showFirstLabel: true,
                showLastLabel: false,
            },
            // tooltip: {
            //     formatter: function() {
            //     var date = Highcharts.dateFormat('%e. %b', this.point.x);
            //         return 'Time: ' + date + '<br>Frequency: ' + this.point.y + '<br>Magnitude: ' + this.point.value;
            //     }
            // },
            // tooltip: {
            //     formatter: function () {
            //         return '<b>' + getPointCategoryName(this.point, 'x') + '</b> sold <br><b>' +
            //             this.point.value + '</b> items on <br><b>' + getPointCategoryName(this.point, 'y') + '</b>';
            //     }
            // },
            colorAxis: {
                min: 0,
                max: 2,
                stops: [
                    [0, '#eff6ff'],
                    // [0.5, '#fef9c3'],
                    [1, '#1e3a8a']
                ],
                // stops: [
                //     [0, '#fff'],
                //     [0.5, '#bfdbfe'],
                //     [0.9, '#3b82f6'],
                //     [1, '#1e3a8a']
                // ],
                // stops: [
                //     [0, '#2563eb'],
                //     [0.5, '#fef9c3'],
                //     [0.9, '#c4463a'],
                //     [1, '#c4463a']
                // ],
                // startOnTick: false,
                // endOnTick: false,
                // labels: {
                //     format: '{value}'
                // }
            },
            series: [{
                // boostThreshold: 100,
                // borderWidth: 0,
                // nullColor: '#EFEFEF',
                // rowsize: 10,
                // colsize: 2*60*1000, // two minute
                data: vibrationTfm.heatmap_data,
                // data: generateData()
                // tooltip: {
                //     headerFormat: 'Magnitude<br/>',
                //     pointFormat: '{point.x:%e %b, %Y, %k:%M}, {point.y}Hz, Mag: <b>{point.value} </b>'
                // },
                turboThreshold: Number.MAX_VALUE // #3404, remove after 4.0.5 
              }],
            legend: {
              align: 'right',
              layout: 'vertical',
              margin: 0,
              verticalAlign: 'top',
            //   y: 25,
            //   symbolHeight: 280
            },
        })

    },[])





  return (
    <div>
        {/* Heatmap */}
        <HighchartsReact
            // constructorType={"mapChart"}  
            containerProps={{ style: { height: "100%" } }}
            highcharts={Highcharts}
            options={heatmapOptions}              
            />
    </div>
  )
}

export default HeatMapLarge