import { BellIcon } from '@heroicons/react/solid'
import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'

function NotificationDropdown() {
  return (
    <div>
        
        <Popover className="relative">
            {({ open, close }) => (
            <>
                <Popover.Button className='outline-none focus:outline-none'>
                    <BellIcon className="navBtn"/>
                </Popover.Button>
                <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
                >
                <Popover.Panel className="absolute right-[-10px] origin-top-right mt-1 w-[30px] md:w-[420px] divide-y dark:divide-slate-500 bg-white dark:bg-slate-700 shadow-iotflows-lg rounded-lg outline-none p-2">
                    <div className='flex justify-between items-baseline py-2'>
                        <div className='text-base font-bold text-gray-900 dark:text-gray-100'>
                            Notifications
                        </div>
                        <div className='text-xs text-gray-500 dark:text-gray-400 '>
                            Mark all as read
                        </div>
                    </div>
                    <div className='py-4 px-3 text-gray-400 text-center text-xs'>
                        No notifications yet
                        <br/>
                        Messages and alerts about your IoTFlows organization will show up here.
                    </div>
                    
                </Popover.Panel>
                </Transition>
            </>
            )}
        </Popover>
    </div>
  )
}

export default NotificationDropdown