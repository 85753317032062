import SubNav from "../components/SubNav"
import PartProductionPage from "./PartProductionPage";
import ProjectProductionPage from "./ProjectProductionPage";
import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {globalSelectedOrganization} from '../atoms/orgStates'
import PartsHistoricalReportPage from "./MyHistoricalReportPage/PartsHistoricalReportPage";
import JobsPage from "./JobsPage";
import PartsListPage from "./PartsListPage";


function ProductionPage() {
    let location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedSubLink, setSelectedSubLink] = useState(null) 
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [isMobile, setIsMobile] = useState(false)

    const subNav = {
        parts_list : {
            name:'Parts List',
            pathName:`/${selectedOrganization}/production`,
            search:'?select=parts_list',
            url:'parts_list',
        },
        part: {
            name:'Shift Production',
            // name:'Part',
            pathName:`/${selectedOrganization}/production`,
            search:'?select=part',
            url:'part',
        },
        // project : {
        //     name:'Project',
        //     pathName:`/${selectedOrganization}/production`,
        //     search:'?select=project',
        //     url:'project',
        // },
        // jobs : {
        //     name:'Jobs',
        //     pathName:`/${selectedOrganization}/production`,
        //     search:'?select=jobs',
        //     url:'jobs',
        // },
        historical_report : {
            name:'Historical',
            pathName:`/${selectedOrganization}/production`,
            search:'?select=historical_report',
            url:'historical_report',
        },
        
    }

    useEffect(() => {
      let currentIsMobile = searchParams.get('platform')
      if(currentIsMobile === 'mobile'){
        setIsMobile(true)
      }else{
        setIsMobile(false)
      }
    },[])

    useEffect(() => {
        if(selectedOrganization) {
            setSelectedSubLink(subNav['parts_list'])
        }

    }, [selectedOrganization])
    
    useEffect(() => {
        // let currentPath = location.pathname.split('/')
        let currentSearch = location.search
        if(currentSearch){
            let queryParams = new URLSearchParams(currentSearch)
            let selected = queryParams.get("select")
            setSelectedSubLink(subNav[selected])
        }
    }, [location, selectedOrganization])
    
    
    return (
        <div className={` ${isMobile ? 'max-h-[calc(100vh-0.5rem)]' : ' max-h-[calc(100vh-5rem)]'} overflow-scroll scrollbar-hide`}>
            <div className={` ${isMobile ? '' : 'py-1 pl-6 pr-3 '} `}>
                {/* nagivations links within assets page */}
                {selectedOrganization && selectedSubLink && <SubNav subNav={subNav} selectedSubLink={selectedSubLink} />}
            </div>
            <div className={` py-1 pl-6 pr-3`}>
                {selectedOrganization && selectedSubLink && subLinkPage(selectedSubLink) }

            </div>

            
        </div>
    )
}

export default ProductionPage

const subLinkPage = (link) => {
    let linkUrl = link.url
    const allLinks = {
        parts_list: { link: "parts_list", content: <PartsListPage /> },
        part: { link: "part", content: <PartProductionPage /> },
        // project: { link: "project", content:<ProjectProductionPage  />  },
        // jobs: { link: "jobs", content:<JobsPage  />  }, 
        historical_report: { link: "historical_report", content: <PartsHistoricalReportPage /> },
    };
   return (
    <AnimatePresence mode='wait'>
        <motion.div key={allLinks[linkUrl].link}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
           
          {allLinks[linkUrl].content} 
        </motion.div>
      </AnimatePresence>
   )
            
}