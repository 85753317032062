import { Avatar, AvatarGroup } from '@mui/material'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { globalSelectedOrganization } from '../atoms/orgStates';
import { useRecoilValue } from 'recoil';

function TeamsTableRow({currentTeam, selectedOrganization, isMobile}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [memberNumber, setMemberNumber] = useState(0)
    const [membersList, setMembersList] = useState([])
    const navigate = useNavigate();


    useEffect(() => {
        if(currentTeam && currentTeam.members && Object.keys(currentTeam.members).length > 0){
            setMembersList(currentTeam.members)
            let count = Object.keys(currentTeam.members).length
            console.log('count', count)
            setMemberNumber(count)

        }

    },[currentTeam])

    const copyToClipboard = (e, text) => {
        e.stopPropagation();
        if (!navigator.clipboard) {
          console.error("Clipboard API not supported");
          toast.error("Clipboard not supported");
          return;
        }
      
        navigator.clipboard.writeText(text)
          .then(() => {
            toast.success("Team handle copied!");
            // console.log("Text copied to clipboard successfully!");
          })
          .catch((err) => {
            toast.error("Failed to copy handle:", err);
            console.error("Failed to copy text:", err);
          });
      }
    
    const handleNavigation = () => {
        if(isMobile){
            navigate(`/${selectedOrganization}/members/teams/${currentTeam.team_uuid}?platform=mobile`)
        }else{
            navigate(`/${selectedOrganization}/members/teams/${currentTeam.team_uuid}`)
        }
    }


  return (
    <tr  className='border-b-[0.01em]  dark:border-zinc-500  hover:bg-slate-100 dark:hover:bg-slate-800'>
        <td className="text-sm font-light text-gray-600 dark:text-gray-200 pr-2 py-2 cursor-pointer" onClick={handleNavigation}>
            <div className={`flex w-full items-center truncate `}>
                <div className='flex items-center'>
                    <Avatar
                        sx={{ width: 40, height: 40, bgcolor: currentTeam.team_color? currentTeam.team_color : ''}}
                        style={{
                            background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${currentTeam.team_color? currentTeam.team_color:''} 100%)`,
                        }}
                        alt={currentTeam.team_name}
                        src={currentTeam.team_picture_url || '/brokenLink.png'}
                        >
                    </Avatar>
                </div>
                <div className='flex '>
                    <div className={` pl-2 text-gray-600 dark:text-gray-300 truncate text-sm font-medium`}>{currentTeam.team_name}</div>
                </div>
            </div>
        </td>
        <td className="text-sm font-light text-gray-600 dark:text-gray-200 px-4 py-1 italic cursor-pointer" onClick={handleNavigation}>
            <div className='flex'>
                <span>
                    {currentTeam.team_organization_handle}
                </span>
                <button 
                    className='ml-2 text-gray-600 dark:text-gray-200 hover:text-gray-400 dark:hover:text-gray-600 '
                    onClick={(e)=> copyToClipboard(e, `${currentTeam.team_organization_handle}`)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                        </svg>
                    </button>
            </div>
        </td>
        <td className="text-sm font-light text-gray-600 dark:text-gray-200 px-2 py-1 cursor-pointer" onClick={handleNavigation}>
            <div className={`flex items-center`}>
                <div className='flex w-[90px]'>
                    <AvatarGroup max={4}  spacing='medium'  sx={{ '& .MuiAvatar-root': { border: '0px solid rgba(203, 213, 225, 0.1)', width:28, height:28, fontSize:'16px' } }} >
                        {membersList.map(member =>(
                            <Avatar
                                key={`avatar-group-${member.user_username}`}
                                sx={{ 
                                    bgcolor: member.user_color? member.user_color : '' , 
                                    fontSize:'12px',
                                    width: 28, 
                                    height: 28,
                                }}
                                style={{
                                    background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${member.user_color? member.user_color:''} 100%)`,
                                }}
                                alt={member.user_username}
                                src={member.user_image_url}
                            />
                        ))}
                    </AvatarGroup>

                </div>
                <div className='truncate pl-4'>
                        {`${memberNumber} ${memberNumber > 1 ? 'members':'member'}`}
                </div>
            </div>
            
        </td>
    </tr>
  )
}

export default TeamsTableRow