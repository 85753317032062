import { PlusSmIcon, XCircleIcon} from "@heroicons/react/outline"
import { CogIcon } from "@heroicons/react/solid"
import MetalPart from '../images/Testing/metal_parts.webp'
import GearPart from '../images/Testing/gear.jpeg'
import BarChartList from "../components/charts/BarChartList"
import ProgressGaugeLarge from '../components/charts/ProgressGaugeLarge'
import StackedBarChart from "../components/charts/StackedBarChart"
import CalendarCard from "../components/CalendarCard"
import AssetActivityCard from "../components/AssetActivityCard"
import OptimizeImage from "../components/OptimizeImage"
import moment from "moment"
import { useEffect, useState } from "react"
import { globalSelectedOrganization } from "../atoms/orgStates"
import { useRecoilValue } from "recoil"
import { getData } from "../ApiCalls/DataApis"
import { apiLink } from "../ApiCalls/ApisVariables"
import { useParams } from "react-router-dom"
import SetCurrentOperationModal from "../components/modals/assetmodals/AutoClassifyOperationsModal"
import SenseAI from '../images/SenseAI.svg'
import { WifiIcon } from '@heroicons/react/solid'
import AreaRangeLineChart from "../components/charts/AreaRangeLineChart"
import HeatMapLarge from "../components/charts/HeatMapLarge"
import { objectEach } from "highcharts"
import SimpleLineChartNoTime from "../components/charts/SimpleLineChartNoTime"




function SelectedAssetPageHealthTab({selectedAsset}) {
    const { assetId } = useParams()
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const [vibrationData, setVibrationData] = useState([])
    const [vibrationDataRaw, setVibrationDataRaw] = useState([])
    const [vibrationDataRawReceived, setVibrationDataRawReceived] = useState('')
    const [vibrationTfm, setVibrationTfm] = useState([])
    const [vibMean, setVibMean] = useState(null)
    const [vibMeanData, setVibMeanData] = useState(null)
    const [vibMeanDataIntervals, setVibMeanDataIntervals] = useState(null)
    const [vibStdDev, setVibStdDev] = useState(null)
    const [vibStdDevData, setVibStdDevData] = useState(null)
    const [vibStdDevDataIntervals, setVibStdDevDataIntervals] = useState(null)
    const [vibRms, setVibRms] = useState(null)
    const [vibRmsData, setVibRmsData] = useState(null)
    const [vibRmsDataIntervals, setVibRmsDataIntervals] = useState(null)
    
    

    useEffect(() => {
        if(selectedOrganization && selectedAsset && selectedAsset.node_uuid){
            getData(apiLink + '/v1/nodes/'+ selectedAsset.node_uuid + '/data/vibrations' )
            .then(async response => {
                // JSON data parsed by response.json() call
                if(response.ok ){
                const data = await response.json();
                    if(data && data.data){
                        setVibrationData(data.data)
                        let dataLength = Object.keys(data.data).length - 1
                        // console.log('vibration data',data.data)
                        // console.log('vibration last',data.data[dataLength])
                        // console.log(' data',data.data[dataLength].data)
                        if(dataLength && Object.keys(data.data[dataLength]).length > 0){
                            setVibrationDataRaw(data.data[dataLength].data)
                            let receivedAt = new Date(data.data[dataLength].received_at)

                            let receivedHours = receivedAt.getHours();
                            let receivedMins = receivedAt.getMinutes();
                            let receivedAmPm = receivedHours >= 12 ? 'pm' : 'am';
                            receivedHours = receivedHours % 12;
                            receivedHours = receivedHours ? receivedHours : 12; // the hour '0' should be '12'
                            receivedMins = receivedMins < 10 ? '0'+receivedMins : receivedMins;
                            let receivedStrTime = receivedHours + ':' + receivedMins + ' ' + receivedAmPm;
                            let receivedshortDate = receivedAt.getMonth()+1 + "/" + receivedAt.getDate() + "/" + receivedAt.getFullYear().toString().substr(-2)
                            let completeDate = receivedshortDate + "  " + receivedStrTime;
                            setVibrationDataRawReceived(completeDate)

                        }
                        let lastData = data.data[dataLength]
                        if(lastData && Object.keys(lastData).length > 0){
                            setVibMean(lastData.mean)
                            setVibStdDev(lastData.stdev)
                            setVibRms(lastData.rms)
                        }
                        let vibrationMeanData = []
                        let vibrationMeanDataIntervals = []
                        let vibrationRmsData = []
                        let vibrationRmsDataIntervals = []
                        let vibrationStdDevData = []
                        let vibrationStdDevDataIntervals = []
                        data.data.map((dataPoint, index) => {
                            let nextIndex = index + 1
                            let date = new Date(dataPoint.received_at)
                            let time = date.getTime()
                            let mean = dataPoint.mean
                            let rms = dataPoint.rms
                            let stdev = dataPoint.stdev

                            let mean_upper = mean*1.1
                            let mean_lower = mean*0.9

                            let rms_upper = rms*1.1
                            let rms_lower = rms*0.9

                            let stdev_upper = stdev*1.1
                            let stdev_lower = stdev*0.9

                            if(index < dataLength ){
                                let mean_mean = data.data[nextIndex].mean_mean
                                let rms_mean = data.data[nextIndex].rms_mean
                                let stdev_mean = data.data[nextIndex].stdev_mean
    
                                let mean_stdev = data.data[nextIndex].mean_stdev
                                let rms_stdev = data.data[nextIndex].rms_stdev
                                let stdev_stdev = data.data[nextIndex].stdev_stdev
                                
                                let sigma = 6
                                mean_upper = mean_mean + sigma*mean_stdev
                                mean_lower = mean_mean - sigma*mean_stdev
    
                                rms_upper = rms_mean + sigma*rms_stdev
                                rms_lower = rms_mean - sigma*rms_stdev
    
                                stdev_upper = stdev_mean + sigma*stdev_stdev
                                stdev_lower = stdev_mean - sigma*stdev_stdev
                                
                            }
                            vibrationStdDevData.push([time,stdev])
                            vibrationStdDevDataIntervals.push([time,stdev_lower,stdev_upper])


                            vibrationMeanData.push([time,mean])
                            vibrationRmsData.push([time,rms])
                            
                            vibrationMeanDataIntervals.push([time,mean_lower,mean_upper])
                            vibrationRmsDataIntervals.push([time,rms_lower,rms_upper])
                            


                        })
                        setVibStdDevData(vibrationStdDevData)
                        setVibStdDevDataIntervals(vibrationStdDevDataIntervals)
                        
                        
                        setVibMeanData(vibrationMeanData)
                        setVibMeanDataIntervals(vibrationMeanDataIntervals)
                        setVibRmsData(vibrationRmsData)
                        setVibRmsDataIntervals(vibrationRmsDataIntervals)

                    }
                }
            }) 

            getData(apiLink + '/v1/nodes/'+ selectedAsset.node_uuid + '/data/vibrations_tfm' )
            .then(async response => {
                // JSON data parsed by response.json() call
                if(response.ok ){
                const data = await response.json();
                    if(data && data.data){
                        // console.log('Vibration TFM',data.data)
                        setVibrationTfm(data.data)
                    }
                }
            })   
        }

    },[selectedOrganization, selectedAsset])

    const formatDecimals = (num) => {
        if(num && num.toFixed(3)){
            return num.toFixed(3)
        }else{
            return num
        }
    }


  return (
    <div className='m-4 h-full'>
        <div className='flex flex-col space-y-2.5 md:space-y-0 md:space-x-2.5 md:flex-row md:mb-2.5 '>
            <div className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 flex flex-col text-center py-6 px-4 w-full'>
                <span className='leading-7 text-2xl font-bold text-gray-600 dark:text-gray-300'>{formatDecimals(vibMean)}</span>
                <span className='leading-7 text-lg font-light text-gray-500 dark:text-gray-400'>Vibration Mean (g)</span>
            </div>
            <div className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 flex flex-col text-center py-6 px-4 w-full'>
                <span className='leading-7 text-2xl font-bold text-gray-600 dark:text-gray-300'>{formatDecimals(vibStdDev)}</span>
                <span className='leading-7 text-lg font-light text-gray-500 dark:text-gray-400'>Vibration Std Dev (g)</span>
            </div>
            <div className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 flex flex-col text-center py-6 px-4 w-full'>
                <span className='leading-7 text-2xl font-bold text-gray-600 dark:text-gray-300'>{formatDecimals(vibRms)}</span>
                <span className='leading-7 text-lg font-light text-gray-500 dark:text-gray-400'>Vibration RMS (g)</span>
            </div>
        </div>
        {vibrationDataRaw && Object.keys(vibrationDataRaw).length > 0 &&
        <div className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 flex flex-col text-center py-4 w-full '>
            <div className='flex flex-col px-4 pb-2 border-b-[1px] border-gray-200 dark:border-gray-700'>
                <div className='text-gray-700 text-center md:text-left dark:text-gray-200 font-medium text-xl'>Vibration (g)</div>
                <div className='text-gray-500 text-center md:text-left dark:text-gray-400 font-medium text-sm'>256 data sampled at {vibrationDataRawReceived}</div>
            </div>
            <div className='px-2'>
                <SimpleLineChartNoTime values={vibrationDataRaw} chartHeight='225px' />
            </div>
        </div>
        }
        {vibrationTfm && Object.keys(vibrationTfm).length > 0 &&
        <div className='flex my-2.5'>
            <div className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 flex flex-col text-center py-3 px-1 w-full'>
                <div className='flex px-4 pb-2 border-b-[1px] border-gray-200 dark:border-gray-700'>
                    <div className='text-gray-700 text-center md:text-left dark:text-gray-200 font-medium text-xl'>Vibration Spectrogram</div>
                </div>
                <HeatMapLarge data={vibrationData} vibrationTfm={vibrationTfm} />
            </div>
        </div>
        }
        
        
        <div className='flex-flex-col space-y-2.5'>
            {vibMeanData && Object.keys(vibMeanData).length > 0 &&
            <div className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 flex flex-col text-center py-4 w-full '>
                <div className='flex px-4 pb-2 border-b-[1px] border-gray-200 dark:border-gray-700'>
                    <div className='text-gray-700 text-center md:text-left dark:text-gray-200 font-medium text-xl'>Vibration Mean (g)</div>
                </div>
                <div className='px-2'>
                    <AreaRangeLineChart values={vibMeanData} intervals={vibMeanDataIntervals} chartHeight='275px' />
                </div>
            </div>
            }
            {vibStdDevData && Object.keys(vibStdDevData).length > 0 &&
            <div className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 flex flex-col text-center py-4 w-full '>
                <div className='flex px-4 pb-2 border-b-[1px] border-gray-200 dark:border-gray-700'>
                    <div className='text-gray-700 text-center md:text-left dark:text-gray-200 font-medium text-xl'>Vibration Std Dev (g)</div>
                </div>
                <div className='px-2'>
                    <AreaRangeLineChart values={vibStdDevData} intervals={vibStdDevDataIntervals} chartHeight='275px' />
                </div>
            </div>
            }
            {vibRmsData && Object.keys(vibRmsData).length > 0 &&
            <div className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 flex flex-col text-center py-4 w-full '>
                <div className='flex px-4 pb-2 border-b-[1px] border-gray-200 dark:border-gray-700'>
                    <div className='text-gray-700 text-center md:text-left dark:text-gray-200 font-medium text-xl'>Vibration RMS (g)</div>
                </div>
                <div className='px-2'>
                    <AreaRangeLineChart values={vibRmsData} intervals={vibRmsDataIntervals} chartHeight='275px' />
                </div>
            </div>
            }
        </div>
        {/* <div className='flex grid lg:grid-cols-10 xl:grid-cols-10 gap-2.5'>
            <div className='flex flex-col lg:col-span-3 xl:col-span-3 2xl:col-span-2 space-y-2.5'>
                <div className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 flex flex-col text-center py-4 w-full h-[370px]'>
                    <div className='flex px-4 pb-2 border-b-[1px] border-gray-200 dark:border-gray-700'>
                        <div className='text-gray-700 text-center md:text-left dark:text-gray-200 font-medium text-xl'>Vibration Data (RAW)</div>
                    </div>
                    <div className='px-2'>
                        <AreaRangeLineChart chartHeight='320px' />
                    </div>
                </div>
            </div>
            <div className='flex flex-col lg:col-span-7 xl:col-span-7 2xl:col-span-8 space-y-2.5'>
                <div className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 flex flex-col text-center py-4 w-full '>
                    <div className='flex px-4 pb-2 border-b-[1px] border-gray-200 dark:border-gray-700'>
                        <div className='text-gray-700 text-center md:text-left dark:text-gray-200 font-medium text-xl'>Avg Vibration Data</div>
                    </div>
                    <div className='px-2'>
                        <AreaRangeLineChart chartHeight='225px' />
                    </div>
                </div>
                <div className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 flex flex-col text-center py-4 w-full '>
                    <div className='flex px-4 pb-2 border-b-[1px] border-gray-200 dark:border-gray-700'>
                        <div className='text-gray-700 text-center md:text-left dark:text-gray-200 font-medium text-xl'>Avg Std Deviation Data</div>
                    </div>
                    <div className='px-2'>
                        <AreaRangeLineChart chartHeight='225px' />
                    </div>
                </div>
            </div>
        </div> */}
    </div>
  )
}

export default SelectedAssetPageHealthTab