import React, { useEffect, useState } from 'react'
import { getData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { useRecoilValue } from 'recoil';
import AssetOperationRow from './AssetOperationRow';
import { useLocation, useParams } from 'react-router-dom';

function AssetOperationsList({opsList,getOpsList, selectedAsset, dateCategories}) {

    
  return (
    <div className='max-h-[500px] xl:max-h-[260px]  overflow-y-scroll scrollbar-hide px-2 pb-2 overflow-hidden '>
        {opsList && Object.keys(opsList).length > 0 &&
            opsList.map((operation) => (
                <AssetOperationRow operation={operation} getOpsList={getOpsList} selectedAsset={selectedAsset} key={operation.operation_uuid} dateCategories={dateCategories} />
            ))
        }
    </div>
  )
}

export default AssetOperationsList