import { useEffect } from 'react'
import { useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Fragment } from 'react'
import { motion } from "framer-motion"
import { useLocation, useSearchParams } from 'react-router-dom'
import { globalSelectedOrganization } from '../../atoms/orgStates'
import { useRecoilValue } from 'recoil'
import { getData } from '../../ApiCalls/DataApis'
import { apiLink } from '../../ApiCalls/ApisVariables'

const severities = [
    {
        severity_id: 'sev_unclassified',
        severity_name: 'Unclassified',
    },
    {
        severity_id: 'sev_none',
        severity_name: 'None',
    },
    {
        severity_id: 'sev_low',
        severity_name: 'Low',
    },
    {
        severity_id: 'sev_medium',
        severity_name: 'Medium',
    },
    {
        severity_id: 'sev_high',
        severity_name: 'High',
    },
]
function DowntimeSeverityFilterBtn({select}) {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
   const [allSeverities, setAllSeverities] = useState(severities)
   const [selectedSeverity, setSelectedSeverity] = useState([])
   const [checkedSeverityState, setCheckedSeverityState] = useState([])
   const [searchParams, setSearchParams] = useSearchParams()
   const location = useLocation();


//    useEffect(() =>{
//         try {
//             if(selectedOrganization){
                
//                 getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/departments' )
//                 .then(async response => {
//                     // JSON data parsed by response.json() call
//                     if(response.ok ){
//                     const data = await response.json();
//                         if(data && data.data){
//                             console.log(data.data)
//                             setAllSeverities(data.data)
//                         }
//                     }
//                 })
//             }
            
//         } catch (error) {
//         console.log(error.message)
//         }
//     }, [selectedOrganization])


   useEffect(() => {
    // Gettting search parameters from URL
        let currentSearch = location.search
        if(currentSearch){
            // Pulling all parts search parameters into an array
            let searchSeverities = searchParams.getAll('downtime_severity_uuids')

            let updatedCheckedState = allSeverities.map((item, index) =>
                searchSeverities.includes(item.severity_id) ? true : false
            );

            let selectedSearchedDepartments = [] 
            updatedCheckedState.map((checked,index) =>
                checked ? selectedSearchedDepartments.push(allSeverities[index]) : null
            )
            setSelectedSeverity(selectedSearchedDepartments)
            setCheckedSeverityState(updatedCheckedState)
        }else {
            setCheckedSeverityState(new Array(allSeverities.length).fill(false))
        }

    }, [location, allSeverities])

    // Currently donb't need because I'm just using the dates of the array and don't need the entire json of dep info
//    useEffect(() => {
//     let checkedDep = []
//         if(checkedSeverityState.includes(true)){
//             allSeverities.map((dep, index) =>{
//                 if(checkedSeverityState[index]){
//                     checkedDep.push(dep)
//                 }
//             })
//         }
//         console.log('checkedDep', checkedDep)
//    }, [checkedSeverityState])


   const handleChangeSeverityState = (position) => {
        let updatedCheckedState = checkedSeverityState.map((item, index) =>
        index === position ? !item : item
        );
        setCheckedSeverityState(updatedCheckedState)
        
    }

    const handleApplyFilter = () => {
        // putting all selected parts into the button for visualization
        let tempDepartment = []
        checkedSeverityState.map((state, index) =>{
            if(state){
                tempDepartment.push(allSeverities[index]) 
            }
        })
        setSelectedSeverity(tempDepartment)

        // putting each part ID into an array to push it to the URL
        let parameters = []
        tempDepartment.map(dep => {
            parameters.push(dep.severity_id)
        })
        let parts = searchParams.getAll('parts')
        let departments = searchParams.getAll('departments')
        let machines = searchParams.getAll('machines') 
        let machine_types = searchParams.getAll('machine_types') 
        let tags = searchParams.getAll('tags')
        let group_by = searchParams.getAll('group_by')
        let sort_by = searchParams.getAll('sort_by')
        let calculate_oee_by = searchParams.getAll('calculate_oee_by')
        let shift_name = searchParams.getAll('shift_name')
        let shift_start_time = searchParams.getAll('shift_start_time')
        let shift_date = searchParams.getAll('shift_date')
        let shift_entire_day = searchParams.getAll('shift_entire_day')
        let maximize = searchParams.getAll('maximize')
        let view = searchParams.getAll('view')
        let url_token = searchParams.getAll('url_token')
        let operations_expanded = searchParams.getAll('operations_expanded')
        let assets_expanded = searchParams.getAll('assets_expanded')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')
        let from = searchParams.getAll('from')
        let to = searchParams.getAll('to')
        let only_with_notes = searchParams.getAll('only_with_notes')
        let time_period = searchParams.getAll('time_period')
        
        setSearchParams({select,parts, departments,machines,  machine_types, tags, group_by, sort_by,calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, maximize, view, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled,from, to,  downtime_severity_uuids: parameters, only_with_notes, time_period})
        
       
        
        

    }

  return (
    <div >
        <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? 'bg-slate-100 dark:bg-slate-700' : ''}
                border border-blue-600 border-dashed rounded-full flex pl-1 pr-3 py-0.5 items-center outline-none max-w-[250px] `}
            >
                <div className='bg-white w-3 h-3 rounded-full m-1'>
                    <svg xmlns="http://www.w3.org/2000/svg" className={`transition ease-in-out duration-300 ${Object.keys(selectedSeverity).length > 0? ' rotate-45 ':'rotate-0'} h-5 w-5 ml-[-4px] mt-[-4px] text-blue-600 `} viewBox="0 0 20 20" fill="currentColor" >
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                    </svg>
                </div>
                <div className='pl-1 text-sm text-gray-600 dark:text-gray-300'>
                    Severity
                </div>
                {Object.keys(selectedSeverity).length > 0 && Object.keys(selectedSeverity).length < 3 &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                    {selectedSeverity.map((filtered,index) => (
                        <div key={filtered.severity_id} className='flex '>
                        {index > 0 && <div className='text-blue-600 dark:text-blue-400 text-xs'>,</div>}
                        <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{filtered.severity_name}</div>
                        </div>
                    ))}
                    <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                </div>
                }
                {Object.keys(selectedSeverity).length >= 3 &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                    <div className='flex '>
                        <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{selectedSeverity[0].severity_name} and {Object.keys(selectedSeverity).length - 1} more</div>
                    </div>
                    <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                </div>
                }
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-32 z-20 mt-3 w-[260px] -translate-x-1/2 transform ">
                <div className=" overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-slate-800 p-3">
                        <div className='text-sm font-bold text-gray-900 dark:text-gray-300'>Filter by Severity</div>
                        {allSeverities.map((severity, index)=>(
                            <div key={severity.severity_id} className="flex items-center my-3">
                                <input id={`severity-checkbox-${index}`} type="checkbox" checked={checkedSeverityState[index]} onChange={() => handleChangeSeverityState(index)}  className="checkboxBtn"/>
                                <label htmlFor={`severity-checkbox-${severity.severity_id}`} className="ml-2 text-xs font-medium text-gray-600 dark:text-gray-400">{severity.severity_name}</label>
                            </div>
                        ))}
                        

                        <motion.button  
                            whileHover={{ scale: 1.02 }} 
                            whileTap={{ scale: 0.98 }} 
                            onClick={ () => {
                                handleApplyFilter()
                                close()
                            }} 
                            className='w-full text-sm p-1 bg-blue-600 text-white rounded-md' >
                                Apply
                        </motion.button>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default DowntimeSeverityFilterBtn