import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion';
import MqttService from '../../../core/services/MqttService';
import { Avatar, AvatarGroup } from '@mui/material';
import { formatDateToAmPm } from '../../../utils/DateFormating';
import ProgressCircle from '../../../utils/ProgressCircle';
import { darkModeState } from '../../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { createMessagingMQTT } from '../../../core/services/MqttMessaging';
import toast from 'react-hot-toast';
import { removeMessage } from '../../../utils/MessageDataSorting';
import { formatFileSize } from '../../../utils/FileFormating';
import SenderCompressedImageInMessageComponent from './SenderCompressedImageInMessageComponent';
import SenderEmojisOnlyMessageComponent from './SenderEmojisOnlyMessageComponent';
import SenderMessageOnlyComponent from './SenderMessageOnlyComponent';
import SenderFileMessageComponent from './SenderFileMessageComponent';
import SenderFileIsUploading from './SenderFileIsUploading';
import SenderVoiceMessageComponent from './SenderVoiceMessageComponent';
import '../../../styles/message-content.css'
import { useSearchParams } from 'react-router-dom';
import { triggerVibration } from '../../../utils/messagingUtils';
import { getData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';

function SenderMessageComponent({message, userInfo, contextMenu, styles, participants, showImage, onRightClick, isGroup, showName, scrollToMessage, emojiTextSize, messages, setMessages, handleDownloadFile, isLoadingFile, updateEmoji,shouldRoundTopCorner,shouldRoundBottomCorner, interactionsEmojis, onReply, onEdit, allEmojis, pinnedMessages, setPinnedMessages}) {
    const isDark = useRecoilValue(darkModeState);
    const [messageStatus, setMessageStatus] = useState('loading'); // Initial status is loading
    const [searchParams, setSearchParams] = useSearchParams()
    const [isMobile, setIsMobile] = useState(false)
    // const [doubleTapMes, setDoubleTapMes] = useState('')
    const longPressTimer = useRef(null);
    const longPressDuration = 200; // milliseconds
    const tapTimer = useRef(null);
    const tapCount = useRef(0);
    const doubleTapDuration = 300; // milliseconds

    const [selectedMessage, setSelectedMessage] = useState(null);
    const [emojiSearchText, setEmojiSearchText] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [expandEmojis, setExpandEmojis] = useState(false)
    const [filteredEmojis, setFilteredEmojis] = useState([]);
    const [loadingEmojis, setLoadingEmojis] = useState(false);




    useEffect(() => {

      let currentIsMobile = searchParams.get('platform')
      if(currentIsMobile === 'mobile'){
          setIsMobile(true)
      }else{
          setIsMobile(false)
      }

  },[])

    useEffect(() => {

        if(message && !message.file_uuid && message.message_is_only_emoji_count && message.message_is_only_emoji_count > 0){
            setMessageStatus('only_emojis')
        }else if(message && message.isFileUploading ){
            setMessageStatus('message_is_file_uploading')
        }else if(message && message.file_uuid && message.file_is_voice ){
            setMessageStatus('message_is_voice')
        }else if(message && message.file_uuid && !message.isFileUploading  && message.file_is_compressed){
            setMessageStatus('message_is_uncompressed_image')
        }else if(message && message.file_uuid && !message.isFileUploading  && !message.file_is_compressed){
            setMessageStatus('message_is_file')
        }else if(message && !message.file_uuid && !message.isFileUploading && message.message_text && message.message_text.length > 0){
            setMessageStatus('only_message')
        }

    },[message])


 

    const handleTouchStart = useCallback((e) => {
      if (e.touches.length > 1) return; // Ignore multi-touch
  
      // Long press logic
      longPressTimer.current = setTimeout(() => {
      
        const activeElement = document.activeElement;
        const isKeyboardCurrentlyOpen = activeElement && (
          activeElement.tagName === 'INPUT' ||
          activeElement.tagName === 'TEXTAREA' ||
          activeElement.isContentEditable
        );
        
        if (isKeyboardCurrentlyOpen) {
          if (document.activeElement) {
            activeElement.blur(); // This will close the keyboard
          }
        }

        triggerVibration();
        setSelectedMessage(message);

      
        tapCount.current = 0; // Reset tap count on long press
      }, longPressDuration);
  
      // Double tap logic
      tapCount.current += 1;
      if (tapCount.current === 1) {
        tapTimer.current = setTimeout(() => {
          tapCount.current = 0;
        }, doubleTapDuration);
      } else if (tapCount.current === 2) {
        clearTimeout(tapTimer.current);
        handleDoubleTap(message);
        tapCount.current = 0;
      }
    }, [message]);

    const handleDoubleTap = (message) => {

      try{
        if (MqttService.isConnected) {
          let msg = {}
          if(message && message.emoji_interactions_usernames && Object.keys(message.emoji_interactions_usernames).length > 0 ){
            const userHasEmoji = message.emoji_interactions_usernames.includes(userInfo.user_username); // true
            if(userHasEmoji){
              msg = {
                  from_username: userInfo.user_username,
                  action: 'update_emoji_interaction',
                  message_uuid: message.message_uuid,
                  emoji_codepoints: ''
              }
            }else{
              msg = {
                from_username: userInfo.user_username,
                action: 'update_emoji_interaction',
                message_uuid: message.message_uuid,
                emoji_codepoints: "1F44D"
            }

            }

          }else{
            msg = {
              from_username: userInfo.user_username,
              action: 'update_emoji_interaction',
              message_uuid: message.message_uuid,
              emoji_codepoints: "1F44D"
            }
          }
          
          msg = createMessagingMQTT(msg);
  
          if(msg.topic && msg.payload){
              MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
          }
        }
      }catch (e){
        toast.error(e)
      }

    };


  
    const handleTouchEnd = useCallback(() => {
      if (longPressTimer.current) {
        clearTimeout(longPressTimer.current);
      }
    }, []);




    const handleTailCss = (emojiCount) => {
        if(emojiCount && emojiCount > 0){
            return{
                color:'transparent'
            }
        }else {
            return{
                color: isDark ? '#075985':'#0284c7',
            }

        }
    }

    

    const renderMessage = () => {
        switch (messageStatus) {
          case 'loading':
            return <div/>;
          case 'message_is_uncompressed_image':
            return <SenderCompressedImageInMessageComponent isMobile={isMobile} message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage}  handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} handleDownloadFile={handleDownloadFile}/>;
          case 'message_is_file':
            return <SenderFileMessageComponent isMobile={isMobile} message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage} showName={showName} handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} handleDownloadFile={handleDownloadFile} isLoadingFile={isLoadingFile} />;
          case 'message_is_voice':
            return <SenderVoiceMessageComponent isMobile={isMobile} message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage} showName={showName} handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} handleDownloadFile={handleDownloadFile} isLoadingFile={isLoadingFile} />;
          case 'only_message':
            return <SenderMessageOnlyComponent isMobile={isMobile} message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage} showName={showName} handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji}  shouldRoundTopCorner={shouldRoundTopCorner} shouldRoundBottomCorner={shouldRoundBottomCorner}/>;
          case 'only_emojis':
            return <SenderEmojisOnlyMessageComponent isMobile={isMobile} message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage}  handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} emojiTextSize={emojiTextSize}/>;
          case 'message_is_file_uploading':
            return <SenderFileIsUploading isMobile={isMobile} messages={messages} setMessages={setMessages} message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage}  handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} emojiTextSize={emojiTextSize}/>;
          default:
            return <div />;
        }
    }

    const handleCloseOverlay = () => {
      setSelectedMessage(null);
      setExpandEmojis(false)
    };

    const handleEmojiClick = (emoji) => {
      setSelectedMessage(null)
      onAddEmoji(emoji)
    };
  
    const handleInputChange = (e) => {
      setEmojiSearchText(e.target.value)
      setIsTyping(true); // User started typing
      setLoadingEmojis(true)
      if(e.target.value ===  0){
        setLoadingEmojis(false)
      }
    };
  
    const debounceCommand = (ms) => {
      let timeout;
      return (text) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          setIsTyping(false); // User stopped typing after delay
          // Run your command here
          let searchText = text.toLowerCase();
          if(searchText && searchText.length > 0){
  
            getData(apiLink + '/v1/emojis?q=' + searchText)
            .then(async response => {
              const data = await response.json();
              if(response.ok ){
                if(data && data.data){
                  setFilteredEmojis(data.data)
                  setLoadingEmojis(false)
                }
              }
            })
          }else{
            setLoadingEmojis(false)
          }
        }, ms);
      };
    };
  
    const delayedCommand = debounceCommand(300); // Debounce for 500ms
  
    useEffect(() => {
      if (isTyping) {
        delayedCommand(emojiSearchText); // Trigger debounced command with current input
      }
    }, [isTyping, emojiSearchText]);
  
    const deleteMessageEveryone = (message) => {
      setSelectedMessage(null)
      // console.log('DELETE message', message)
  
      // Delete MQTT MESSAGE
      if (MqttService.isConnected) {
          let msg = {
              from_username: userInfo.user_username,
              action: 'delete_message',
              message_uuid: message.message_uuid,
          }
          
          msg = createMessagingMQTT(msg);
          if(msg.topic && msg.payload)
          {
              MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
          }
          
      }else{
          toast.error('Waiting for Network')
      }
    }
  
    const deleteForMe = (message) => {
      setSelectedMessage(null)
      // Delete MQTT MESSAGE
      if (MqttService.isConnected) {
          let msg = {
              from_username: userInfo.user_username,
              action: 'delete_message_for_me',
              message_uuid: message.message_uuid,
          }
          
          msg = createMessagingMQTT(msg);
          if(msg.topic && msg.payload)
          {
              MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
          }
  
          const updatedMessages = removeMessage(messages, message)
          setMessages({...updatedMessages})
          
      }else{
          toast.error('Waiting for Network')
      }
    }
  
    const pinForMe = (message) => {
      setSelectedMessage(null)
      if (MqttService.isConnected) {
        let msg = {
          from_username: userInfo.user_username,
          action: 'pin_message_for_me',
          message_uuid: message.message_uuid,
          should_pin: true,
        }
        
        
        msg = createMessagingMQTT(msg);
        // console.log('Pinned Me msg', message)
        let uuid = message.message_uuid
        let pins = pinnedMessages
        pins[uuid] = message
        console.log('Pins', pins)
  
        setPinnedMessages({...pins})
        if(msg.topic && msg.payload)
          {
              MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
          }
  
          // const updatedMessages = removeMessage(messages, messInfo.message)
          // setMessages({...updatedMessages})
          
      }else{
          toast.error('Waiting for Network')
      }
    }
  
    const pinForAll = (message) => {
      setSelectedMessage(null)
      // Delete MQTT MESSAGE
      if (MqttService.isConnected) {
        let msg = {
          from_username: userInfo.user_username,
          action: 'pin_message',
          message_uuid: message.message_uuid,
          should_pin: true,
        }
        
        
        msg = createMessagingMQTT(msg);
        
        if(msg.topic && msg.payload)
          {
              MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
          }
  
          // const updatedMessages = removeMessage(messages, messInfo.message)
          // setMessages({...updatedMessages})
          
      }else{
          toast.error('Waiting for Network')
      }
    }
  
    const copyToClipboard = async (message) => {
      try {
        let text = message.message_text || message.message_text_html
        if (navigator.clipboard && window.ClipboardItem) {
          // Use the Clipboard API to write both HTML and plain text to the clipboard
          await navigator.clipboard.write([
            new ClipboardItem({
              "text/html": new Blob([text], { type: "text/html" }),
              "text/plain": new Blob([text], { type: "text/plain" }),
            }),
          ]);
          setSelectedMessage(null);
          // console.log('Content copied to clipboard with formatting!');
        } else {
          // Fallback for browsers that do not support the Clipboard API
          const tempElement = document.createElement('div');
          tempElement.innerHTML = text;
          document.body.appendChild(tempElement);
  
          // Select the text
          const range = document.createRange();
          range.selectNodeContents(tempElement);
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(range);
  
          // Copy the selected content
          document.execCommand('copy');
          setSelectedMessage(null);
          // console.log('Content copied to clipboard with fallback!');
  
          // Cleanup
          document.body.removeChild(tempElement);
          selection.removeAllRanges();
        }
      } catch (err) {
        console.error('Failed to copy content:', err);
      }
    };


    const onAddEmoji = (emoji) => {
      // console.log('Adding emoji to:', contextMenu.message);
      // Implement the add emoji functionality
      // console.log('emoji', emoji)

      if (MqttService.isConnected) {

        let msg = {
            from_username: userInfo.user_username,
            action: 'update_emoji_interaction',
            message_uuid: message.message_uuid,
            emoji_codepoints: emoji.emoji_codepoints
        }
        
        msg = createMessagingMQTT(msg);

        if(msg.topic && msg.payload){
            MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
        }
        setSelectedMessage(null);
      }else{
       toast.error('Trying to connect')
      }
    }

    const handleReply = (msg) => {
      onReply(msg)
      setSelectedMessage(null);
    }

    const handleEdit = (msg) => {
      onEdit(msg)
      setSelectedMessage(null);
    }



    const handleExpandEmojis = () => {
      setExpandEmojis(true)
      setSelectedMessage(message);
    }


  return (
    <div>
      {isMobile ? (
        <div 
            style={styles}
            className={`w-full flex items-end relative justify-end mobile-message-functions
            `} 
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchEnd}
            // onContextMenu={(e) => onRightClick(e, message,message.user_username, userInfo.user_username, 'onContextMenu' )}
        >
            {renderMessage()}
        </div>
      ):(
        <div 
            style={styles}
            className={`w-full flex items-end relative justify-end
            ${contextMenu && contextMenu.message && contextMenu.message.message_uuid == message.message_uuid && 'bg-slate-500/20 rounded-xl'}
            `} 
            onContextMenu={(e) => onRightClick(e, message,message.user_username, userInfo.user_username )}
        >
            {renderMessage()}
        </div>

      )}
      {isMobile && selectedMessage && (
        <AnimatePresence mode='wait'>

          <div
          //  animate={{ scale: [0, 1] }}
          //  transition={{
          //   duration: 0.1,
          // }}
           className={`fixed left-0 bottom-0 w-full h-full z-[999] bg-black/70 backdrop-blur-lg`}
           onClick={handleCloseOverlay}
         >
           <div 
              className='relative flex flex-col backdrop-blur-lg'
            >
               {expandEmojis ? (
                     <motion.div
                     key='expandEmojisContainer'
                     initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className='flex flex-col'
                     >
                       <div 
                        onClick={(e) => e.stopPropagation()} 
                         className={`ml-auto m-2 relative w-[300px] h-[350px] scrollbar-hide bg-white/90 dark:bg-zinc-900/90 rounded-xl flex flex-col p-3 shadow-iotflows-lg dark:shadow-xs-invert `}
                         >
                           <div className='absolute top-1 left-0 right-0 flex rounded-full w-full h-8 items-center px-2'>
                             <div className='pl-1 flex items-center pointer-events-none'>
                                 <svg className={`h-4 w-4 dark:text-gray-300 text-gray-500`}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                     <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                 </svg>
                             </div>
                             <input className={`bg-transparent  text-gray-600 border-0 focus:ring-[0px]  placeholder:text-gray-500 dark:text-gray-300 dark:placeholder:text-gray-400
                                 block w-full text-sm rounded-lg text-ellipsis py-1`}
                                 value={emojiSearchText} 
                                 onChange={handleInputChange} 
                                 type="text" 
                                 placeholder="Search Emojis..."
                             />
                             
                             <div className={`${loadingEmojis ? 'opacity-100': 'opacity-0'} `}>
                               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="animate-spin h-5 w-5 text-gray-500 dark:text-gray-300">
                                 <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
                               </svg>
                             </div>
                           </div>
                           <div className='mt-7 overflow-y-scroll scrollbar-hide'>
                             {emojiSearchText.length > 0 && Object.keys(filteredEmojis).length > 0 &&
                               <div
                               key='filteredList'
                               initial={{ opacity: 0 }}
                               animate={{ opacity: 1 }}
                               exit={{ opacity: 0 }} className='flex flex-wrap pb-2'>
                                 {filteredEmojis.map(emoji => (
                                   <span key={emoji.emoji_codepoints} onClick={() => handleEmojiClick(emoji)} className='text-2xl p-1 cursor-pointer'>
                                       {/* {String.fromCodePoint(parseInt("0x" + emoji.u, 16))} */}
                                       <div className={`${emoji.emoji_codepoints == '2764 FE0F' && 'text-red-500'}`}>{emoji.emoji_emoji}</div>
                                   </span>
                                 ))}
                               </div>
                             }
                           {emojiSearchText.length === 0 && Object.keys(allEmojis).map((emojiSection) => (
                               <div key={emojiSection}>
                                   <h6 className='text-gray-600 dark:text-gray-300 text-sm pb-1 pt-2'>
                                       {emojiSection}
                                   </h6>
                                   <div className='flex flex-wrap'>
                                   {allEmojis[emojiSection].map(emoji => (
                                       <span key={emoji.emoji_codepoints} onClick={() => handleEmojiClick(emoji)} className='text-2xl p-1 cursor-pointer'>
                                           <div className={`${emoji.emoji_codepoints == '2764 FE0F' && 'text-red-500'}`}>{emoji.emoji_emoji}</div>
                                       </span>
                                   ))}
                                   </div>
                               </div>
                               
                           ))}
 
                           </div>
                       </div>
                       <div 
                        className='flex justify-end m-2 max-h-[50vh] overflow-hidden'
                        >
                          {renderMessage()}
                        </div>
                     </motion.div>
               
 
               ):(
                 <motion.div
                     key='mobileContextSenderContainer'
                     initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className=' flex flex-col mt-2'>
                  
                    <div
                      onClick={(e) => e.stopPropagation()} 
                      className={`flex ml-auto  m-2 items-center space-x-2 bg-white/90 dark:bg-zinc-900/90 backdrop-blur-lg shadow-iotflows-lg dark:shadow-xs-invert rounded-full px-2`}>
                      {interactionsEmojis.slice(0,7).map(emoji => (
                        <button
                          key={emoji.emoji_codepoints} onClick={() => onAddEmoji(emoji)} className={`text-3xl p-1 rounded-full`}>
                            {/* {String.fromCodePoint(parseInt("0x" + emoji.u, 16))} */}
                            <div className={`${emoji.emoji_codepoints == '2764 FE0F' && 'text-red-500'}`}>{emoji.emoji_emoji}</div>
                        </button>
                      ))} 
                     <button
                         onClick={handleExpandEmojis} className='p-1 w-7 h-7 rounded-full bg-slate-50 dark:bg-slate-800 text-gray-600 dark:text-gray-300 '
                       >
                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                             <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                           </svg>
                       </button>
                   </div>
                   <div
                    className='flex justify-end m-2 max-h-[50vh] overflow-hidden'>
                      {renderMessage()}
                     </div>
                   <div 
                    onClick={(e) => e.stopPropagation()} 
                    className='flex flex-col ml-auto m-2 w-[250px] bg-white/90 dark:bg-zinc-900/90 backdrop-blur-lg rounded-xl p-2 shadow-iotflows-lg dark:shadow-xs-invert'>
                     <button 
                       className={`group flex w-full items-center rounded-md px-2 py-1 text-sm 
                       hover:bg-zinc-100 hover:dark:bg-zinc-500/30 text-gray-600 dark:text-gray-300/80 hover:dark:text-gray-200 hover:text-gray-600`}
                       onClick={()=> handleReply(message)}>
                       <div className='w-5 group-hover:scale-110 group-hover:duration-500'>
                         <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 122.88 98.86" fill='currentColor' stroke='none' strokeWidth='8px' fillRule='evenodd' clipRule='evenodd' className='w-4 h-4 ' xmlSpace='preserve'>
                           <g><path  d="M0,49.43l48.93,49.43V74.23c30.94-6.41,55.39,0.66,73.95,24.19c-3.22-48.4-36.29-71.76-73.95-73.31V0L0,49.43 L0,49.43L0,49.43z"/></g>
                         </svg>
                       </div>
                         <span className='pl-2'>Reply</span>
                     </button>   
                       <button 
                         className={`group flex w-full items-center rounded-md px-2 py-1 text-sm 
                         hover:bg-zinc-100 hover:dark:bg-zinc-500/30 text-gray-600 dark:text-gray-300/80 hover:dark:text-gray-200 hover:text-gray-600`}
                         onClick={() => copyToClipboard(message)}>
                         <div className='w-5 items-center group-hover:scale-110 group-hover:duration-500'>
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-4">
                           <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                         </svg>
                         </div>
                           <span className='pl-2'>Copy Text</span>
                       </button>    
                     <div className='w-full border-b-[0.1em] border-zinc-100 dark:border-zinc-600/30 my-1' />        
                     <button 
                       className={`group flex w-full items-center rounded-md px-2 py-1 text-sm 
                       hover:bg-zinc-100 hover:dark:bg-zinc-500/30 text-gray-600 dark:text-gray-300/80 hover:dark:text-gray-200 hover:text-gray-600`}
                       onClick={() => pinForMe(message)}>
                       <div className='w-5 items-center group-hover:scale-110 group-hover:duration-500'>
                         <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 -960 960 960" fill="currentColor" className="w-5 h-4">
                           <path d="M640-760v280l68 68q6 6 9 13.5t3 15.5v23q0 17-11.5 28.5T680-320H520v234q0 17-11.5 28.5T480-46q-17 0-28.5-11.5T440-86v-234H280q-17 0-28.5-11.5T240-360v-23q0-8 3-15.5t9-13.5l68-68v-280q-17 0-28.5-11.5T280-800q0-17 11.5-28.5T320-840h320q17 0 28.5 11.5T680-800q0 17-11.5 28.5T640-760ZM354-400h252l-46-46v-314H400v314l-46 46Zm126 0Z"/>
                         </svg>
                       </div>
                         <span className='pl-2'>Pin for Me</span>
                     </button>   
                     <button 
                       className={`group flex w-full items-center rounded-md px-2 py-1 text-sm 
                       hover:bg-zinc-100 hover:dark:bg-zinc-500/30 text-gray-600 dark:text-gray-300/80 hover:dark:text-gray-200 hover:text-gray-600`}
                       onClick={() => pinForAll(message)}>
                       <div className='w-5 items-center group-hover:scale-110 group-hover:duration-500'>
                         <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 -960 960 960" fill="currentColor" className="w-5 h-4">
                           <path d="M640-760v280l68 68q6 6 9 13.5t3 15.5v23q0 17-11.5 28.5T680-320H520v234q0 17-11.5 28.5T480-46q-17 0-28.5-11.5T440-86v-234H280q-17 0-28.5-11.5T240-360v-23q0-8 3-15.5t9-13.5l68-68v-280q-17 0-28.5-11.5T280-800q0-17 11.5-28.5T320-840h320q17 0 28.5 11.5T680-800q0 17-11.5 28.5T640-760ZM354-400h252l-46-46v-314H400v314l-46 46Zm126 0Z"/>
                         </svg>
                       </div>
                         <span className='pl-2'>Pin for All</span>
                     </button>   
                     {/* {contextMenu.message_username === contextMenu.user_username   &&  */}
                     <button 
                       className={`group flex w-full items-center rounded-md px-2 py-1 text-sm 
                       hover:bg-zinc-100 hover:dark:bg-zinc-500/30 text-gray-600 dark:text-gray-300/80 hover:dark:text-gray-200 hover:text-gray-600`}
                       onClick={() => handleEdit(message)}
                     >
                       <div className='w-5 items-center group-hover:scale-110 group-hover:duration-500'>
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-4">
                           <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                         </svg>
                       </div>
                         <span className='pl-2'>Edit</span>
                     </button> 
                     {/* }          */}
                     <button 
                       className={`group flex w-full items-center rounded-md px-2 py-1 text-sm 
                       hover:bg-zinc-100 hover:dark:bg-zinc-500/30 text-gray-600 dark:text-gray-300/80 hover:dark:text-gray-200 hover:text-gray-600`}
                       onClick={() => deleteForMe(message)}
                       >
                       <div className='w-5 items-center group-hover:scale-110 group-hover:duration-500'>
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                             <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                         </svg>
                       </div>
                         <span className='pl-2'>Delete for Myself</span>
                     </button>    
                     {/* {contextMenu.message_username === contextMenu.user_username   &&   */}
                     <button 
                       className={`group flex w-full items-center rounded-md px-2 py-1 text-sm 
                       hover:bg-zinc-100 hover:dark:bg-zinc-500/30 text-red-600 dark:text-red-400 hover:dark:text-gray-200 hover:text-gray-600`}
                       onClick={() => deleteMessageEveryone(message)}
                       >
                       <div className='w-5 items-center group-hover:scale-110 group-hover:duration-500'>
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                             <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                         </svg>
                       </div>
                         <span className='pl-2'>Delete for Everyone</span>
                     </button>        
                     {/* } */}
                   </div>
                 </motion.div>
 
               )}
           </div>
         </div>
        </AnimatePresence>
      )}
    </div>
  )
}

export default SenderMessageComponent