import { useEffect, Fragment, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { motion } from "framer-motion"
import { useLocation, useSearchParams } from 'react-router-dom'
import { globalSelectedOrganization } from '../../../atoms/orgStates'
import { useRecoilValue } from 'recoil'
import { getData } from '../../../ApiCalls/DataApis'
import { apiLink } from '../../../ApiCalls/ApisVariables'
import {darkModeState} from '../../../atoms/darkModeState'
import moment from 'moment';

const shiftByMinutes = [
    {
        name:'00:00am - 23:59am',
        id: 0,
    },
    {
        name:'00:30am - 00:29am',
        id: 30,
    },
    {
        name:'01:00am - 00:59am',
        id: 60,
    },
    {
        name:'01:30am - 01:29am',
        id: 90,
    },
    {
        name:'02:00am - 01:59am',
        id: 120,
    },
    {
        name:'02:30am - 02:29am',
        id: 150,
    },
    {
        name:'03:00am - 02:59am',
        id: 180,
    },
    {
        name:'03:30am - 03:29am',
        id: 210,
    },
    {
        name:'04:00am - 03:59am',
        id: 240,
    },
    {
        name:'04:30am - 04:29am',
        id: 270,
    },
    {
        name:'05:00am - 04:59am',
        id: 300,
    },
    {
        name:'05:30am - 05:29am',
        id: 330,
    },
    {
        name:'06:00am - 05:59am',
        id: 360,
    },
    {
        name:'06:30am - 06:29am',
        id: 390,
    },
    {
        name:'07:00am - 06:59am',
        id: 420,
    },
    {
        name:'07:30am - 07:29am',
        id: 450,
    },
    {
        name:'08:00am - 07:59am',
        id: 480,
    },
    {
        name:'08:30am - 08:29am',
        id: 510,
    },
    {
        name:'09:00am - 08:59am',
        id: 540,
    },
    {
        name:'09:30am - 09:29am',
        id: 570,
    },
    {
        name:'10:00am - 09:59am',
        id: 600,
    },
    {
        name:'10:30am - 10:29am',
        id: 630,
    },
    {
        name:'11:00am - 10:59am',
        id: 660,
    },
    {
        name:'11:30am - 11:29am',
        id: 690,
    },
    {
        name:'12:00pm - 11:59am',
        id: 720,
    },
    {
        name:'12:30pm - 12:29pm',
        id: 750,
    },
    {
        name:'01:00pm - 12:59pm',
        id: 780,
    },
    {
        name:'01:30pm - 01:29pm',
        id: 810,
    },
    {
        name:'02:00pm - 01:59pm',
        id: 840,
    },
    {
        name:'02:30pm - 02:29pm',
        id: 870,
    },
    {
        name:'03:00pm - 02:59pm',
        id: 900,
    },
    {
        name:'03:30pm - 03:29pm',
        id: 930,
    },
    {
        name:'04:00pm - 03:59pm',
        id: 960,
    },
    {
        name:'04:30pm - 04:29pm',
        id: 990,
    },
    {
        name:'05:00pm - 04:59pm',
        id: 1020,
    },
    {
        name:'05:30pm - 05:29pm',
        id: 1050,
    },
    {
        name:'06:00pm - 05:59pm',
        id: 1080,
    },
    {
        name:'06:30pm - 06:29pm',
        id: 1110,
    },
    {
        name:'07:00pm - 06:59pm',
        id: 1140,
    },
    {
        name:'07:30pm - 07:29pm',
        id: 1170,
    },
    {
        name:'08:00pm - 07:59pm',
        id: 1200,
    },
    {
        name:'08:30pm - 08:29pm',
        id: 1230,
    },
    {
        name:'09:00pm - 08:59pm',
        id: 1260,
    },
    {
        name:'09:30pm - 09:29pm',
        id: 1290,
    },
    {
        name:'10:00pm - 09:59pm',
        id: 1320,
    },
    {
        name:'10:30pm - 10:29pm',
        id: 1350,
    },
    {
        name:'11:00pm - 10:59pm',
        id: 1380,
    },
    {
        name:'11:30pm - 11:29pm',
        id: 1410,
    },
]

function ShiftFilterBtn({select}) {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const isDark = useRecoilValue(darkModeState);
    const [tempSelectedShift, setTempSelectedShift] = useState([])
    const [selectedShift, setSelectedShift] = useState([])
    const [shiftList, setShiftList] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation();
    const [shiftEntireDay, setShiftEntireDay] = useState(false)
    const [currentShift, setCurrentShift] = useState([])
    const [currentShiftIndex, setCurrentShiftIndex] = useState(null)
    const [shiftedMinutes , setShiftedMinutes] = useState(shiftByMinutes[0])
    

    useEffect(() => {
        if(selectedOrganization){
            getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/shifts' )
            .then(async response => {
                // JSON data parsed by response.json() call
                if(response.ok ){
                    const data = await response.json();
                    if(data && data.data){
                        // console.log('Shifts',data.data)
                        let shifts = data.data
                        shifts.unshift(
                            // {
                            // shift_name: "",
                            // shift_start_time: ''
                            // },
                            {
                            shift_name: "All Day",
                            shift_start_time: '00:00:00',
                            total_shift_s: 86400
                            }
                        )
                        setShiftList(shifts)
                        setSelectedShift(shifts[0])
                        setTempSelectedShift(shifts[0])
                        setShiftEntireDay(true)
                    }
                }
            })    

            // getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/shifts/current' )
            // .then(async response => {
            //     // JSON data parsed by response.json() call
            //     if(response.ok ){
            //     const data = await response.json();
            //         if(data && data.data){
            //             // console.log('Current shift',data.data)
            //             setCurrentShift(data.data[0])
            //         }
            //     }
            // }) 
        }

    },[selectedOrganization])

  
    // useEffect(() => {
    //     if(currentShift && Object.keys(currentShift).length && shiftList && Object.keys(shiftList).length ){
    //         // This Code was to see what is the current shift and have that as the default parameter
    //         // var shiftIndex = 0
    //         // shiftList.map((shift, index) => {
    //         //     if(shift.shift_name == currentShift.shift_name){
    //         //         shiftIndex = index
    //         //     }
    //         // })
    //         setCurrentShiftIndex(0)
            
    //     }

    // },[currentShift, shiftList])


   useEffect(() => {
    // Gettting search parameters from URL
        let currentSearch = location.search
        if(currentSearch && Object.keys(shiftList).length >0){
            // Pulling all parts search parameters into an array
            let shiftName = searchParams.get('shift_name')
            // let shiftStartTime = searchParams.get('shift_start_time')
            let shiftEntireDay = searchParams.get('shift_entire_day')
            
            if(shiftEntireDay){
                setShiftEntireDay(shiftEntireDay)
            }

            let shiftShown = []
            shiftList.map((shift,index) =>{
                if(shift.shift_name == shiftName){
                    shift.index = index
                    setTempSelectedShift(shift)
                    setSelectedShift(shift)
                    shiftShown = shift
                }
            })
            if(currentShift && Object.keys(currentShift).length > 0 && Object.keys(shiftShown).length === 0){
                shiftList.map((shift,index) =>{
                    let shiftname = currentShift.shift_name
                    if(shiftname ==='Default'){
                        shiftname = 'All Day'
                    }
                    if(shift.shift_name == shiftname){
                        shift.index = index
                        setTempSelectedShift(shift)
                        setSelectedShift(shift)
                    }
                })
            }
        }else{
            if(Object.keys(shiftList).length >0){
                let shiftShown = []
                if(currentShift && Object.keys(currentShift).length > 0 && Object.keys(shiftShown).length === 0){
                    shiftList.map((shift,index) =>{
                        let shiftname = currentShift.shift_name
                        if(shiftname ==='Default'){
                            shiftname = 'All Day'
                        }
                        if(shift.shift_name == shiftname){
                            shift.index = index
                            setTempSelectedShift(shift)
                            setSelectedShift(shift)
                        }
                    })
                }
            }
        }

    }, [location, shiftList, currentShift])

    

    


   const handleChangeShift = (e) => {
        setTempSelectedShift(shiftList[e.target.value])
        // setSelectedShift(shiftList[e.target.value])
        // console.log('shiftList[e.target.value]', shiftList[e.target.value])
        if(shiftList[e.target.value].shift_name == 'All Day'){
            setShiftEntireDay(true)
        }else{
            setShiftEntireDay(false)
            setShiftedMinutes(shiftByMinutes[0])
        }
    }


    const handleApplyFilter = () => {
        
        let parts = searchParams.getAll('parts')
        let departments = searchParams.getAll('departments')
        let machine_types = searchParams.getAll('machine_types')
        let tags = searchParams.getAll('tags')
        let group_by = searchParams.getAll('group_by')
        let sort_by = searchParams.getAll('sort_by')
        let calculate_oee_by = searchParams.getAll('calculate_oee_by')
        // let shift_date = searchParams.getAll('shift_date')
        let maximize = searchParams.getAll('maximize')
        let url_token = searchParams.getAll('url_token')
        let operations_expanded = searchParams.getAll('operations_expanded')
        let assets_expanded = searchParams.getAll('assets_expanded')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')
        let to = searchParams.getAll('to')
        let from = searchParams.getAll('from')
        let machines = searchParams.getAll('machines')
        let group_by_advanced = searchParams.getAll('group_by_advanced')
        let sort_by_advanced = searchParams.getAll('sort_by_advanced')
        let time_period = searchParams.getAll('time_period')
        let show_percentage = searchParams.getAll('show_percentage')
        let exclude_none_in_oee = searchParams.getAll('exclude_none_in_oee')
    
        // console.log('selectedShift', selectedShift)
        // console.log('tempSelectedShift', tempSelectedShift)
        if(tempSelectedShift && Object.keys(tempSelectedShift).length > 0){
            setSelectedShift(tempSelectedShift)
            if(tempSelectedShift.shift_name){
                setSearchParams({select, parts, departments, machine_types,tags, group_by, sort_by, calculate_oee_by, shift_name: tempSelectedShift.shift_name, shift_start_time:tempSelectedShift.shift_start_time, shift_entire_day:shiftEntireDay,shift_by_min: shiftedMinutes.id, maximize, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled, to, from, machines, group_by_advanced, sort_by_advanced, time_period, show_percentage, exclude_none_in_oee}) 
            }else{
                setSearchParams({select, parts, departments, machine_types, tags, group_by, sort_by, calculate_oee_by, maximize, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled, to, from, machines, group_by_advanced, sort_by_advanced, time_period, show_percentage, exclude_none_in_oee}) 
            }
        }
    }

    const handleChangeShiftByMinutes = (e) => {
        setShiftedMinutes(shiftByMinutes[e.target.value])
    }


  return (
    <div >
        <Popover className="relative ">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? 'bg-slate-100 dark:bg-slate-700' : ''}
                border border-blue-600 border-dashed rounded-full flex pl-1 pr-3 py-0.5 items-center outline-none `}
                
            >
                <div className='bg-white w-3 h-3 rounded-full m-1'>
                    <svg xmlns="http://www.w3.org/2000/svg" className={`transition ease-in-out duration-300 ${Object.keys(selectedShift).length > 0? ' rotate-45 ':'rotate-0'} h-5 w-5 ml-[-4px] mt-[-4px] text-blue-600 `} viewBox="0 0 20 20" fill="currentColor" >
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                    </svg>
                </div>
                <div className='pl-1 text-sm text-gray-600 dark:text-gray-300 truncate min-w-fit'>
                    Shift
                </div>
                {Object.keys(selectedShift).length > 0 && selectedShift.shift_name &&
                <div className='flex items-center truncate'>
                    <div className='text-blue-600 pl-1 dark:text-blue-400 text-xs'>|</div>
                        <div className='flex '>
                            <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-xs '>{selectedShift.shift_name}</div>
                        </div>
                    <ChevronDownIcon className='text-gray-400 w-4 h-4' />
                </div>
                }
                
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel  className="absolute left-40 z-20 mt-3 w-[350px] -translate-x-1/2 transform " >
                <div className=" overflow-scroll scrollbar-hide rounded-lg shadow-lg dark:shadow-lg-invert ring-1 ring-black ring-opacity-5 bg-white dark:bg-slate-800 p-3">
                        <div className='text-sm font-medium text-gray-900 dark:text-gray-300'>Select Work Shift </div>
                        
                        
                        <div className="flex justify-center items-center">
                            <div className="my-3 w-full outline-none">
                                <select 
                                    defaultValue={selectedShift?.index} 
                                    // defaultValue={currentShiftIndex} 
                                    onChange={handleChangeShift}  
                                    className="form-select form-select-sm shadow-iotflows-lg appearance-none block w-full
                                    px-2 py-1 text-sm font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 bg-clip-padding bg-no-repeat border-1 border-slate-50 dark:border-slate-400 rounded
                                    transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-50 focus:outline-none focus:shadow-blue-300" aria-label=".form-select-sm ">
                                    {shiftList.map((shift, index) => (
                                        <option key={shift.shift_name} value={index}>{shift.shift_name? shift.shift_name : 'Current Shift'}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* {shiftList && Object.keys(shiftList).length > 0 && (Object.keys(selectedShift).length == 0 || (Object.keys(tempSelectedShift).length > 0 && tempSelectedShift.shift_name == "All Day")) &&
                        <div className='my-2'>
                            <div className='text-sm font-medium text-gray-900 dark:text-gray-300'>Time Shift </div>
                            <div className=" my-3 w-full outline-none">
                                <select defaultValue={0} onChange={handleChangeShiftByMinutes}  
                                className="form-select form-select-sm shadow-iotflows-lg appearance-none block w-full
                                px-2 py-1 text-sm font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 bg-clip-padding bg-no-repeat border-1 border-slate-50 dark:border-slate-400 rounded
                                transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-50 focus:outline-none focus:shadow-blue-300" aria-label=".form-select-sm ">
                                    {shiftByMinutes.map((shiftChange, index) => (
                                        <option key={shiftChange.id} value={index}  className='tracking-wider'>{shiftChange.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        } */}
                        
                        
                        <motion.button  
                            whileHover={{ scale: 1.02 }} 
                            whileTap={{ scale: 0.98 }} 
                            onClick={ () => {
                                handleApplyFilter()
                                close()
                            }} 
                            className='w-full text-sm p-1 bg-blue-600 text-white rounded-md' >
                                Apply
                        </motion.button>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default ShiftFilterBtn