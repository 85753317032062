import { Dialog, Transition, DialogPanel, TransitionChild } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { PlusSmIcon } from "@heroicons/react/solid"
import { SearchIcon } from "@heroicons/react/outline"
import { motion, AnimatePresence } from "framer-motion";
import { useRecoilValue } from 'recoil';
import {darkModeState} from '../../../atoms/darkModeState'
import { apiLink } from '../../../ApiCalls/ApisVariables';
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import { getData } from '../../../ApiCalls/DataApis';
import Avatar from '@mui/material/Avatar';
import {MemberTooltip} from '../../../styles/Tooltip'
import { Zoom } from '@mui/material';
import RemoveMemberModal from './RemoveMemberModal';
import EditMemberRoleModal from './EditMemberRoleModal';

const emailCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


function MembersListModal({btnType, remoteOpen,handleAddMemberModal, handleCloseMemberListModal, membersList, selectedOrganization, updatedMemberList, handleRouteAddOutsideMember, handleRouteInviteNewMember}) {
  const isDark = useRecoilValue(darkModeState)
  const [isOpen, setIsOpen] = useState(false)
  const [openRemoveMemberModal, setOpenRemoveMemberModal] = useState(false)
  const [openEditMemberRoleModal, setOpenEditMemberRoleModal] = useState(false)
  const [selectedMember, setSelectedMember] = useState([])
  const [filteredMemberList, setFilteredMemberList] = useState(membersList)
  const [filteredAllIoTFlowsMemberList, setFilteredAllIoTFlowsMemberList] = useState([])
  const [searchMemberText, setSearchMemberText] = useState('')
  const [noMatchedName, setNoMatchedName] = useState(false)
  const [isTypingEmail, setIsTypingEmail] = useState(false)
  const [validEmail, setValidEmail] = useState(false)

  function openModal() {
    console.log('open')
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
    handleCloseMemberListModal()
  }

  useEffect(() => {
    if(remoteOpen){
      setIsOpen(true)
    }
  },[remoteOpen])

  useEffect(() => {
    if(searchMemberText  && searchMemberText.length > 0){
      const filtered = membersList.filter(member => {
        let fullName = member.user_first_name + ' ' + member.user_last_name
        if(fullName.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_first_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_last_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_username_public.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_email.toLowerCase().includes(searchMemberText.toLowerCase())){
          return member
        }
      })
      setFilteredMemberList(filtered)

      // Get members from API
      getData(apiLink + '/v1/users/search/containing?q='+ searchMemberText )
      .then(async response => {
        // JSON data parsed by response.json() call
        if(response.ok ){
          const data = await response.json();
          let newList = data.data
          newList.map((list, index) => {
            filtered.map(memberFilter => {
              if(memberFilter.user_username == list.user_username){
                newList.splice(index, 1)
              }
            })
          })
          
          setFilteredAllIoTFlowsMemberList(newList)
        }
      })



    }else{
      setFilteredMemberList([])
      setFilteredAllIoTFlowsMemberList([])
    }
  },[searchMemberText])

  useEffect(() => {
    if(searchMemberText && searchMemberText.length > 0 && Object.keys(filteredAllIoTFlowsMemberList).length == 0 && Object.keys(filteredMemberList).length == 0 ){
      if(searchMemberText.includes('@')){
        setNoMatchedName(false)
        setIsTypingEmail(true)
        if( emailCheck.test(searchMemberText) ){
          setValidEmail(true)
        }else {
          setValidEmail(false)
        }
      }else{
        setNoMatchedName(true)
        setValidEmail(false)
        setIsTypingEmail(false)
      }
    }else {
      setNoMatchedName(false)
      setValidEmail(false)
      setIsTypingEmail(false)
    }
  },[filteredAllIoTFlowsMemberList, filteredMemberList, searchMemberText])

  
  const handleRemoveMemberModal = (memberSelected) => {
    setSelectedMember(memberSelected)
    setOpenRemoveMemberModal(true)
  }

  const handleCloseRemoveModal = () => {
    setOpenRemoveMemberModal(false)
    setSelectedMember([])
  }

  const handleEditMemberModal = (memberSelected) => {
    setSelectedMember(memberSelected)
    setOpenEditMemberRoleModal(true)
    // setIsOpen(false)
  }

  
  const handleCloseEditRoleModal = () => {
    setOpenEditMemberRoleModal(false)
    setSelectedMember([])
  }

  const handleSearchMember = (e) => {
    setSearchMemberText(e.target.value)
  }

  const handleOpenAddMemberModal = () => {
    handleCloseMemberListModal()
    handleAddMemberModal()
  }

  const handleAddMember = (member) => {
    handleRouteAddOutsideMember(member)
    closeModal()
  }
  const handleInviteMember = (inviteEmail) => {
    handleRouteInviteNewMember(inviteEmail)
    closeModal()
  }

  

  return (
    <>
      <div className="flex items-center justify-center">
        {btnType ==='standardBtn' &&
          <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
              <PlusSmIcon className='btnMainIcon' />
              <span className='btnMainText'>Member list</span>
          </motion.button>
          }
        {btnType ==='tableBtn' &&
        <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='flex w-20 text-sm items-baseline ml-2' onClick={openModal} >
            <PlusSmIcon className='text-blue-600 w-4 h-4 mt-auto mb-0.5' />
            <span className=' text-blue-600'>Add</span>
        </motion.button>
          }
        {btnType ==='hideBtn' &&
          <div className='w-0 h-0'>{''}</div>
          }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-scroll">
            <div className="flex min-h-full overflow-hidden items-center justify-center p-4 text-center ">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className={`w-full max-w-lg h-[75vh] min-h-[300px] max-h-[800px] scrollbar-hide transform overflow-x-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'} `}>
                  <div className={`flex mb-1 p-3 border-b-[1px] ${isDark?'border-gray-700':' border-gray-200'}`}>
                    <div
                      className={` text-lg pl-1 font-medium leading-6 ${isDark?'text-gray-100 ':'text-gray-700 '}`}
                      >
                      Members
                    </div>
                    <button
                      type="button"
                      className={`ml-auto p-1 rounded-full bg-transparent ${isDark?' text-gray-400 hover:bg-slate-600':' text-gray-600 hover:bg-gray-100'}`}
                      onClick={closeModal}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                  
                  {/* Search bar for searching members in org and in all of IoTFlows */}
                  <div className='relative rounded-full w-full px-4 my-2'>
                    <div className='absolute inset-y-0 pl-3 flex items-center pointer-events-none'>
                      <SearchIcon className='h-4 w-4 text-gray-400 ' />
                    </div>
                    <input className={`block w-full pl-8 sm:text-sm rounded-md text-ellipsis py-1.5
                                    ${isDark?' text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800 bg-slate-700  placeholder:text-gray-500 ':' placeholder:text-gray-400  text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`}
                            value={searchMemberText} 
                            onChange={handleSearchMember}  
                            type='text' 
                            placeholder="Find members" 
                            />
                  </div>
                  {!noMatchedName && !isTypingEmail &&
                  // Add Member section
                  <button className={`flex px-5 py-3 items-center w-full  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} cursor-pointer items-center`} onClick={handleOpenAddMemberModal}>
                    <div className={`${isDark? 'text-blue-300  hover:text-gray-200':'text-blue-700 hover:bg-gray-200'} border-0 rounded-full  ml-2.5`}>
                        <svg  xmlns="http://www.w3.org/2000/svg"  className="w-7 h-7" fill="currentColor" viewBox="0 0 16 16" stroke="none">
                            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                            <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                        </svg>
                    </div>
                    <div className='flex flex-col pl-3 grow overflow-hidden'>
                      <div className='flex items-baseline'>
                        <div className='max-w-[250px]'>
                          <h1 className={`text-base ${isDark? 'text-blue-100':'text-blue-700'} font-semibold truncate`}>Add member</h1>
                        </div>
                      </div>
                    </div>
                  </button>
                  }
                  {isTypingEmail && 
                  // Add Member section
                  <button className={`flex px-5 py-3 items-center w-full  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} items-center`} 
                          onClick={() => handleInviteMember(searchMemberText)} 
                          disabled={!validEmail}>
                        {validEmail? (
                          <div className={`${isDark? 'text-blue-300  ':'text-blue-600 '} border-0 rounded-full  ml-2.5`}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                              </svg>
                          </div>  
                        ):(
                          <div className={`${ isDark? 'text-gray-400  ':'text-gray-500 '} border-0 rounded-full  ml-2.5`}>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                              </svg>
                          </div>
                        )}
                    
                    <div className='flex flex-col pl-3 grow overflow-hidden'>
                      <div className='flex items-baseline'>
                        <div className='max-w-[250px]'>
                          <div className={`text-base font-semibold truncate`}>
                           {validEmail ? (
                            <span className={`${isDark? 'text-gray-300':'text-gray-600'}`}>Invite {searchMemberText}</span>
                           ):(
                            <span className={`${isDark? 'text-gray-400':'text-gray-500'}`}>Keep typing full email</span>
                           )} 
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                  }
                  {searchMemberText && searchMemberText.length > 0 && noMatchedName &&
                  // No member found section
                  <div className='text-center'>
                    <div className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} py-2 w-full text-center`}>
                      <span>No matches found for {searchMemberText}</span>
                    </div>
                    <button
                      type="button"
                      className={`rounded-md ml-auto bg-transparent border ${isDark?' border-gray-600 text-gray-400 hover:bg-gray-700':'border-gray-400 text-gray-600 hover:bg-gray-100'} px-4 py-1 text-sm font-medium `}
                      onClick={handleOpenAddMemberModal}
                    >
                      Add Member
                    </button>
                  </div>
                  }
                  <AnimatePresence mode='wait'>
                  {/* All members in organization */}
                  {searchMemberText === '' && membersList && Object.keys(membersList).length > 0 &&
                  <motion.div
                        key='allMembersInOrg'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      > 

                      {/* List of members */}
                        <div className='flex flex-col'>
                         {membersList.map((member, index) => (
                            <MemberTooltip placement="right"  key={member.user_username} TransitionComponent={Zoom}
                              title={
                                <div className={`flex flex-col rounded-lg ${isDark? ' bg-slate-900':' bg-slate-50'} `}>
                                  <div className={`w-full text-center py-1.5 rounded-t-lg text-sm ${isDark? ' bg-slate-700 text-gray-400':' bg-slate-200 text-gray-700'}`}>
                                    <span>{member.organization_member_role_name}</span>
                                  </div>
                                  <div className='flex px-2 py-2'>
                                    <div>
                                      <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 80, height: 80, }}  src={member.user_image_url} />
                                    </div>
                                    <div className='flex flex-col pl-4 justify-between'>
                                        <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-800'} font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                        <h3 className={`pr-1 text-sm ${isDark? 'text-gray-400':'text-gray-600'} font-light truncate italic truncate`}>{member.user_username_public}</h3>
                                        <h3 className={`pr-1 text-sm ${isDark? 'text-gray-400':'text-gray-500'} font-light truncate truncate`}>{member.user_email}</h3>
                                    </div>
                                  </div>
                                  <div className='flex justify-between px-2 pt-4 pb-2'>
                                    <button
                                      type="button"
                                      className={`rounded-md bg-transparent border ${isDark?' border-gray-600 text-gray-400 hover:bg-gray-700':'border-gray-400 text-gray-600 hover:bg-gray-200'} px-4 py-1 text-sm font-medium `}
                                      onClick={() => handleEditMemberModal(member)}
                                    >
                                      Edit Role
                                    </button>
                                    <button
                                      type="button"
                                      className={`rounded-md bg-transparent border ${isDark?'border-red-900 text-red-700 hover:text-red-600':'border-red-600 text-red-600 hover:text-red-400'} px-4 py-1 text-sm font-medium `}
                                      onClick={() => handleRemoveMemberModal(member)}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              }
                            >
                          <div key={member.user_username} className={`flex px-5 py-3  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} cursor-pointer items-center`}>
                              <div>
                                <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 40, height: 40, }}  src={member.user_image_url} />
                              </div>
                              <div className='flex flex-col pl-2 grow overflow-hidden'>
                                <div className='flex items-baseline'>
                                  <div className='max-w-[250px]'>
                                    <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                  </div>
                                  <div className='pl-3 max-w-[200px]'>
                                    <h3 className={`pr-1 text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-light truncate italic truncate`}>{member.user_username_public}</h3>
                                  </div>
                                </div>
                              </div>
                              <div className='w-14 pl-2'>
                                <button className={`text-xs text-blue-500 ${isDark? 'hover:text-blue-400':'hover:text-blue-700'}`} onClick={() => handleRemoveMemberModal(member)}>
                                  Remove
                                </button>
                              </div>
                            </div>
                            </MemberTooltip>
                        ))}

                        </div>
                    </motion.div>
                    }
                  {searchMemberText && searchMemberText.length > 0  && filteredMemberList && Object.keys(filteredMemberList).length > 0 &&
                  <motion.div
                        key='membersListSection'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      > 
                      <div className='pl-3 pb-1 pt-1'>
                        <h6 className={`text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-bold`}>In this organization</h6>
                      </div>

                      {/* List of members */}
                        <div className='flex flex-col'>
                         {filteredMemberList.map((member, index) => (
                            <MemberTooltip placement="right"  key={`${member.user_username}-filteredMember`} TransitionComponent={Zoom}
                              title={
                                <div className={`flex flex-col rounded-lg ${isDark? ' bg-slate-900':' bg-slate-50'} `}>
                                  <div className={`w-full text-center py-1.5 rounded-t-lg text-sm ${isDark? ' bg-slate-700 text-gray-400':' bg-slate-200 text-gray-700'}`}>
                                    <span>{member.organization_member_role_name}</span>
                                  </div>
                                  <div className='flex px-2 py-2'>
                                    <div>
                                      <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 80, height: 80, }}  src={member.user_image_url} /> 
                                    </div>
                                    <div className='flex flex-col pl-4 justify-between'>
                                        <span className={`text-base ${isDark? 'text-gray-300':'text-gray-800'} font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</span>
                                        <span className={`pr-1 text-sm ${isDark? 'text-gray-400':'text-gray-600'} font-light truncate italic truncate`}>{member.user_username_public}</span>
                                        <span className={`pr-1 text-sm ${isDark? 'text-gray-400':'text-gray-500'} font-light truncate truncate`}>{member.user_email}</span>
                                    </div>
                                  </div>
                                  <div className='flex justify-between px-2 pt-4 pb-2'>
                                    <button
                                      type="button"
                                      className={`rounded-md bg-transparent border ${isDark?' border-gray-600 text-gray-400 hover:bg-gray-700':'border-gray-400 text-gray-600 hover:bg-gray-200'} px-4 py-1 text-sm font-medium `}
                                      onClick={() => handleEditMemberModal(member)}
                                    >
                                      Edit Role
                                    </button>
                                    <button
                                      type="button"
                                      className={`rounded-md bg-transparent border ${isDark?'border-red-900 text-red-700 hover:text-red-600':'border-red-600 text-red-600 hover:text-red-400'} px-4 py-1 text-sm font-medium `}
                                      onClick={() => handleRemoveMemberModal(member)}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              }
                            >
                          <div key={member.user_username} className={`flex px-5 py-3  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} cursor-pointer items-center`}>
                              <div>
                                <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 40, height: 40, }}  src={member.user_image_url} />
                              </div>
                              <div className='flex flex-col pl-2 grow overflow-hidden'>
                                <div className='flex items-baseline'>
                                  <div className='max-w-[250px]'>
                                    <span className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</span>
                                  </div>
                                  <div className='pl-3 max-w-[200px]'>
                                    <span className={`pr-1 text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-light truncate italic truncate`}>{member.user_username_public}</span>
                                  </div>
                                </div>
                              </div>
                              <div className='w-14 pl-2'>
                                <button className={`text-xs text-blue-500 ${isDark? 'hover:text-blue-400':'hover:text-blue-700'}`} onClick={() => handleRemoveMemberModal(member)}>
                                  Remove
                                </button>
                              </div>
                            </div>
                            </MemberTooltip>
                        ))}

                        </div>
                    </motion.div>
                    }
                  {searchMemberText && searchMemberText.length > 0  && filteredAllIoTFlowsMemberList && Object.keys(filteredAllIoTFlowsMemberList).length > 0 &&
                  <motion.div
                        key='allIoTFlowsmembersListSection'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className={`${isDark? 'bg-slate-700 ':'bg-slate-100 '} h-full mt-2`}
                      > 
                      <div className='pl-3 pt-2 pb-1'>
                        <h6 className={`text-sm ${isDark? 'text-gray-100':'text-gray-700'} font-bold`}>Not in this organization</h6>
                      </div>

                      {/* List of members */}
                        <div className='flex flex-col h-full w-full'>
                         {filteredAllIoTFlowsMemberList.map((member, index) => (
                            <MemberTooltip placement="right"  key={`${member.user_username}-iotflows-member`} TransitionComponent={Zoom}
                              title={
                                <div className={`flex flex-col rounded-lg ${isDark? ' bg-slate-900':' bg-slate-50'} `}>
                                  <div className={`w-full text-center py-1.5 rounded-t-lg text-sm ${isDark? ' bg-slate-600 text-gray-400':' bg-slate-200 text-gray-700'}`}>
                                    {/* <span>{member.organization_member_role_name}</span> */}
                                  </div>
                                  <div className='flex px-2 py-2'>
                                    <div>
                                      <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 80, height: 80, }}  src={member.user_image_url} /> 
                                    </div>
                                    <div className='flex flex-col pl-4 justify-between'>
                                        <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-800'} font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                        <h3 className={`pr-1 text-sm ${isDark? 'text-gray-400':'text-gray-600'} font-light truncate italic truncate`}>{member.user_username_public}</h3>
                                        <h3 className={`pr-1 text-sm ${isDark? 'text-gray-400':'text-gray-500'} font-light truncate truncate`}>{member.user_email}</h3>
                                    </div>
                                  </div>
                                  <div className='flex justify-between px-2 pt-4 pb-2'>
                                    {/* <button
                                      type="button"
                                      className={`rounded-md bg-transparent border ${isDark?' border-gray-600 text-gray-400 hover:bg-gray-700':'border-gray-400 text-gray-600 hover:bg-gray-200'} px-4 py-1 text-sm font-medium `}
                                      onClick={() => handleEditMemberModal(member)}
                                    >
                                      Edit Role
                                    </button> */}
                                    <button
                                      type="button"
                                      className={`rounded-md ml-auto bg-transparent border ${isDark?' border-gray-600 text-gray-400 hover:bg-gray-700':'border-gray-400 text-gray-600 hover:bg-gray-200'} px-4 py-1 text-sm font-medium `}
                                      onClick={() => handleAddMember(member)}
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                              }
                            >
                          <div key={member.user_username} className={`flex px-5 py-3  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-200'} cursor-pointer items-center`}>
                              <div>
                                <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 40, height: 40, }}  src={member.user_image_url} />
                              </div>
                              <div className='flex flex-col pl-2 grow overflow-hidden'>
                                <div className='flex items-baseline'>
                                  <div className='max-w-[250px]'>
                                    <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                  </div>
                                  <div className='pl-3 max-w-[200px]'>
                                    <h3 className={`pr-1 text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-light truncate italic truncate`}>{member.user_username_public}</h3>
                                  </div>
                                </div>
                              </div>
                              <div className='w-9 pl-2'>
                                <button className={`text-xs text-blue-500 ${isDark? 'hover:text-blue-400':'hover:text-blue-700'}`} onClick={() => handleAddMember(member)}>
                                  Add
                                </button>
                              </div>
                            </div>
                            </MemberTooltip>
                        ))}

                        </div>
                    </motion.div>
                    }

                  </AnimatePresence>
                  
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      {openRemoveMemberModal && selectedMember && Object.keys(selectedMember).length > 0 &&
        <RemoveMemberModal btnType='hideBtn' remoteOpen={openRemoveMemberModal} selectedRemoveMember={selectedMember} handleCloseRemoveModal={handleCloseRemoveModal} selectedOrganization={selectedOrganization} updatedMemberList={updatedMemberList}/>
        }
      {openEditMemberRoleModal && selectedMember && Object.keys(selectedMember).length > 0 &&
        <EditMemberRoleModal btnType='hideBtn' remoteOpen={openEditMemberRoleModal} selectedEditMemberRole={selectedMember} handleCloseEditRoleModal={handleCloseEditRoleModal} selectedOrganization={selectedOrganization} updatedMemberList={updatedMemberList}/>
        }
    </>
  )
}

export default MembersListModal