import React from 'react'
import { darkModeState } from '../../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { Avatar, AvatarGroup } from '@mui/material'
import { motion } from 'framer-motion';

function InteractionsComponent({message, userInfo, updateEmoji, participants}) {
    const isDark = useRecoilValue(darkModeState);

    const interactionsCSS =  (mess, user, interaction) => {

        if(mess.user_username === user.user_username){
            let hasUserInteracted = interaction.usernames.includes(user.user_username)
            if(hasUserInteracted){
                return{
                    backgroundColor: isDark ? 'rgba(226, 232, 240, 1)' : "rgba(226, 232, 240, 1)",
                    color: isDark ? 'rgb(2, 133, 199)':'rgb(2, 133, 199)'
                  }

            }else{
                return{
                    backgroundColor: isDark ? 'rgba(226, 232, 240, 0.2)' : "rgba(226, 232, 240, 0.2)",
                  }
            }

        }else{
            let hasUserInteracted = interaction.usernames.includes(user.user_username)
            if(hasUserInteracted){
                return{
                    backgroundColor: isDark ? "rgba(96, 165, 250, 0.9)" : 'rgba(37, 100, 235, 0.8)',
                    color: isDark ? 'rgb(255, 255, 255)':'rgb(255, 255, 255)'
                  }
            }else{
                return{
                    backgroundColor: isDark ? "rgba(96, 165, 250, 0.3)" : 'rgba(37, 100, 235, 0.2)',
                    color: isDark ? 'rgb(125, 212, 252)':'rgb(2, 133, 199)'
                  }
            }
        }
      }

    //   if(message && message.emoji_interactions && Object.keys(message.emoji_interactions).length > 0){
    //       console.log('message.emoji_interactions', message.emoji_interactions)

    //   }


  return (
    <div className={`flex flex-wrap `}>
    {message && message.emoji_interactions && Object.keys(message.emoji_interactions).length > 0 && message.emoji_interactions.map((interaction) => (
        <motion.button 
            animate={{
                scale: [1.2,  1],
            }}
            // whileTap={{ scale: 1  }}  
            // whileHover={{ scale: 1.03  }}  
            key={interaction.emoji_codepoints}
            className={`flex pl-2 mr-1 mt-1 space-x-1 items-center rounded-full cursor-pointer`}
            style={interactionsCSS(message, userInfo, interaction)}
            onClick={() => updateEmoji(message, userInfo, interaction)}
            
        >
            <div 
                className={`text-base items-center ${interaction.emoji_codepoints == '2764 FE0F' && 'text-red-500'}`}>
                {interaction.emoji_emoji}
            </div>
            {message.emoji_interactions_count >= 5 ? (
                <div className={`w-5 h-5 items-center ml-auto`}>
                    {Object.keys(interaction.users).length}
                </div>
            ):(
                <div className='pr-1'>
                    <AvatarGroup  spacing='medium' sx={{ '& .MuiAvatar-root': { border: '0px solid rgba(255, 255, 255, 0.2)' } }} >
                        {interaction.users.map(users =>(
                            <Avatar
                                key={users.user_username}
                                sx={{ 
                                    bgcolor: users.user_color? users.user_color : '',
                                    fontSize:'12px',
                                    width: 20, 
                                    height: 20,
                                }}
                                style={{
                                    background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${users.user_color? users.user_color:''} 100%)`,
                                  }}
                                alt={users.user_first_name? users.user_first_name : ''}
                                src={users.user_image_url ? users.user_image_url : ''}
                            />
                        ))}
                    </AvatarGroup>
                </div>
            )}
        </motion.button>
    ))}
    </div>
  )
}

export default InteractionsComponent