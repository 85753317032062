export function formatFileSize(bytes) {
        if (bytes === 0) return '0 Bytes';
      
        const kibiByte = 1024;
        const megaByte = kibiByte * 1024;
        const gigaByte = megaByte * 1024;
      
        const thresholds = [
          { threshold: gigaByte, divisor: gigaByte, unit: 'GB' },
          { threshold: megaByte, divisor: megaByte, unit: 'MB' },
          { threshold: kibiByte, divisor: kibiByte, unit: 'KB' },
        ];
      
        const divisor = thresholds.find((threshold) => bytes >= threshold.threshold)?.divisor;
        const unit = thresholds.find((threshold) => bytes >= threshold.threshold)?.unit;
      
        if (!divisor) return `${bytes} Bytes`;
      
        return `${Math.round(bytes / divisor)} ${unit}`;
      }