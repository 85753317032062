import { useEffect } from 'react';

const useViewportFix = () => {
  useEffect(() => {
    const metaViewport = document.querySelector('meta[name=viewport]');
    const viewportContent = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0';
    
    if (metaViewport) {
      metaViewport.setAttribute('content', viewportContent);
    } else {
      const newMetaViewport = document.createElement('meta');
      newMetaViewport.name = 'viewport';
      newMetaViewport.content = viewportContent;
      document.head.appendChild(newMetaViewport);
    }

    const handleResize = () => {
      const viewport = document.querySelector('meta[name=viewport]');
      viewport.setAttribute('content', viewportContent);
      
      // Force redraw
      requestAnimationFrame(() => {
        document.body.style.opacity = '0.99';
        setTimeout(() => {
          document.body.style.opacity = '1';
        }, 0);
      });
    };

    window.addEventListener('resize', handleResize);

    // iOS-specific fix
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (isIOS) {
      window.addEventListener('focusin', () => {
        // window.scrollTo(0, 0); //Commeted this out because it was messing my code it and 
      });
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      if (isIOS) {
        window.removeEventListener('focusin', () => {});
      }
    };
  }, []);
};

export default useViewportFix;