import Header from "./components/Header";
import { Routes, Route, useSearchParams, useLocation, useNavigate } from "react-router-dom";
import AssetsPage from "./MyAssetsPage/AssetsPage";
import SelectedAssetPage from "./MyAssetsPage/SelectedAssetPage";
import { useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import {darkModeState} from './atoms/darkModeState'
import ProductionPage from "./MyProductionPage/ProductionPage";
import DevicesPage from "./MyDevicesPage/DevicesPage";
import {addOrgModalState, addAssetModalState, addSensorModalState, addGatewayModalState, sendUrlLinkModalState} from './atoms/modalStates'
import AddOrgModal from "./components/modals/organizationModals/AddOrgModal";
import AddSensorModal from "./components/modals/sensorModals/AddSensorModal";
import AddGatewayModal from "./components/modals/gatewayModals/AddGatewayModal";
import AddAssetModal from "./components/modals/assetmodals/AddAssetModal";
// import OrgPage from "./MySettingsPage/OrgPage";
import ProfilePage from "./MySettingsPage/ProfilePage";
import BillingPage from "./MySettingsPage/BillingPage";
import { useEffect } from "react";
import { getData } from "./ApiCalls/DataApis";
import { apiLink } from "./ApiCalls/ApisVariables";
import {userAuthorization, userInfo} from './atoms/userStates'
import { Toaster } from 'react-hot-toast';
import SendUrlLinkModal from "./components/modals/screenModals/SendUrlLinkModal";
import ErrorPage from "./ErrorPage";
import { refreshOrganizationApis } from "./atoms/refreshStates";
import { globalSelectedOrganization } from "./atoms/orgStates";
// import MqttService from './core/services/MqttService';
// import IoTFlowsLogoLightTransparent from "./images/IoTFlowsLogoLightTransparent.png";
import IoTFlowsLogoBlackFont from "./images/IoTFlowsLogoBlackFont.png";
import MembersPage from "./MyMembers/MembersPage";
// import SelectedAssetTrainingPage from "./MyAssetsPage/SelectedAssetTrainingPage";
// import ReportsPage from "./MyReportsPage/ReportsPage";
import DashboardsPage from "./MyDashboardsPage/DashboardsPage";
import MainSettingPage from "./MySettingsPage/MainSettingPage";
import LogsPage from "./MyLogsPage/LogsPage";
// import MainMessagingPage from "./MyMessaging/MainMessagingPage";
import { motion } from "framer-motion";
import { Auth } from 'aws-amplify';
import AssetsLayout from "./components/AssetsLayout";
// import LeftSideNav from "./components/messagingComponents/LeftSideNav";
import ChatsPage from "./MyMessaging/ChatsPage";
import NavigationBridge from "./utils/NavigationBridge";
import ConversationsPage from "./MyMessaging/ConversationsPage";
import CanvasPage from "./MyCanvasPages/CanvasPage";
import WorkOrdersPage from "./MyWorkOrdersPage/WorkOrdersPage";
import DirectoryPage from "./MyMembers/DirectoryPage";
import SelectedTeamPage from "./MyMembers/SelectedTeamPage";



var subNav = {}

function App({mqttMessage}) {
  const [isDark, setIsDark] = useRecoilState(darkModeState)
  const selectedOrganization = useRecoilValue(globalSelectedOrganization);
  const [isOrganizationApisRefreshed, setIsOrganizationApisRefreshed] = useRecoilState(refreshOrganizationApis)
  const isAddOrgModalOpen = useRecoilValue(addOrgModalState);
  const isAssetModalOpen = useRecoilValue(addAssetModalState);
  const isSensorModalOpen = useRecoilValue(addSensorModalState);
  const isGatewayModalOpen = useRecoilValue(addGatewayModalState);
  const isSendUrlLinkModalOpen = useRecoilValue(sendUrlLinkModalState);
  const [orgList, setOrgList] = useState([])
  const [user, setUser] = useRecoilState(userInfo)
  const [isUserAuthorized, setIsUserAuthorized] = useRecoilState(userAuthorization)
  const [justRefreshed, setJustRefreshed] = useState(false)
  const [refreshAfterTimer, setRefreshAfterTimer] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  let location = useLocation();
  const [isLoadingOrgs, setIsLoadingOrgs] = useState(false)
  const [userHasNoOrgs, setUserHasNoOrgs] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate();
  const [userOrgInfo, setUserOrgInfo] = useState([])
  const [isMobile, setIsMobile] = useState(false)

  



  
  
  useEffect(() => {
    
    updateUserInformation()
    updateOrganizationsList()
    

  }, [])

  useEffect(() => {
    // This is setting the isDark in the window so Mahmoud can grab it for the MacOS
    const event = new CustomEvent('darkModeStateChanged', { detail: isDark }); 
  
    window.dispatchEvent(event);
  },[isDark])


  useEffect(() => {
    
    if(selectedOrganization){ 
      if(selectedOrganization === 'org_f8ec590d72e0bb7f7f89a5ded8361de7'){
        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
          subNav = {
            overview: {
                name:'Overview',
                pathName:`/${selectedOrganization}/assets`,
                search:'?select=overview&group_by=department',
                url:'overview',
            },
            downtimes: {
                name:'Downtimes',
                pathName:`/${selectedOrganization}/assets`,
                search:'?select=downtimes',
                url:'downtimes',
            },
            advanced_report: {
                name:'Report',
                pathName:`/${selectedOrganization}/assets`,
                search:`?select=advanced_report`,
                url:'advanced_report',
            },
            usage_report: {
                name:'Usage',
                pathName:`/${selectedOrganization}/assets`,
                search:'?select=usage_report',
                url:'usage_report',
            },
            // weekly_reports: {
            //     name:'Weekly Reports',
            //     pathName:`/${selectedOrganization}/assets`,
            //     search:'?select=weekly_reports',
            //     url:'weekly_reports',
            // },
            // performance : {
            //     name:'Performance',
            //     pathName:`/${selectedOrganization}/assets`,
            //     search:'?select=performance',
            //     url:'performance',
            // },
        }
        }else{
          console.log('isRanpak')
          subNav = {
              overview: {
                  name:'Overview',
                  pathName:`/${selectedOrganization}/assets`,
                  search:'?select=overview&group_by=department',
                  url:'overview',
              },
              downtimes: {
                  name:'Downtimes',
                  pathName:`/${selectedOrganization}/assets`,
                  search:'?select=downtimes',
                  url:'downtimes',
              },
              advanced_report: {
                  name:'Advanced Report',
                  pathName:`/${selectedOrganization}/assets`,
                  search:`?select=advanced_report`,
                  url:'advanced_report',
              },
              canvas: {
                  name:'Canvas (NEW)',
                  pathName:`/${selectedOrganization}/assets`,
                  search:`?select=canvas`,
                  url:'canvas',
              },
              usage_report: {
                  name:'Usage Reports',
                  pathName:`/${selectedOrganization}/assets`,
                  search:'?select=usage_report',
                  url:'usage_report',
              },
              // weekly_reports: {
              //     name:'Weekly Reports',
              //     pathName:`/${selectedOrganization}/assets`,
              //     search:'?select=weekly_reports',
              //     url:'weekly_reports',
              // },
              // performance : {
              //     name:'Performance',
              //     pathName:`/${selectedOrganization}/assets`,
              //     search:'?select=performance',
              //     url:'performance',
              // },
          }
      }
        
    }else{
      let currentIsMobile = searchParams.get('platform')
      if(currentIsMobile === 'mobile'){
        subNav = {
            overview: {
                name:'Overview',
                pathName:`/${selectedOrganization}/assets`,
                search:'?select=overview&group_by=department',
                url:'overview',
            },
            downtimes: {
                name:'Downtimes',
                pathName:`/${selectedOrganization}/assets`,
                search:'?select=downtimes',
                url:'downtimes',
            },
            advanced_report: {
                name: 'Report',
                pathName:`/${selectedOrganization}/assets`,
                search:`?select=advanced_report&exclude_none_in_oee=true&exclude_unknown_in_oee=true`,
                url:'advanced_report',
            },
            // usage_report: {
            //     name:'Usage Reports',
            //     pathName:`/${selectedOrganization}/assets`,
            //     search:'?select=usage_report',
            //     url:'usage_report',
            // },
            // weekly_reports: {
            //     name:'Weekly Reports',
            //     pathName:`/${selectedOrganization}/assets`,
            //     search:'?select=weekly_reports',
            //     url:'weekly_reports',
            // },
            // performance : {
            //     name:'Performance',
            //     pathName:`/${selectedOrganization}/assets`,
            //     search:'?select=performance',
            //     url:'performance',
            // },
        }
      }else{
        subNav = {
            overview: {
                name:'Overview',
                pathName:`/${selectedOrganization}/assets`,
                search:'?select=overview&group_by=department',
                url:'overview',
            },
            downtimes: {
                name:'Downtimes',
                pathName:`/${selectedOrganization}/assets`,
                search:'?select=downtimes',
                url:'downtimes',
            },
            advanced_report: {
                name: 'Advanced Report',
                pathName:`/${selectedOrganization}/assets`,
                search:`?select=advanced_report&exclude_none_in_oee=true&exclude_unknown_in_oee=true`,
                url:'advanced_report',
            },
            canvas: {
              name:'Canvas (NEW)',
              pathName:`/${selectedOrganization}/assets`,
              search:`?select=canvas`,
              url:'canvas',
            },
            // usage_report: {
            //     name:'Usage Reports',
            //     pathName:`/${selectedOrganization}/assets`,
            //     search:'?select=usage_report',
            //     url:'usage_report',
            // },
            // weekly_reports: {
            //     name:'Weekly Reports',
            //     pathName:`/${selectedOrganization}/assets`,
            //     search:'?select=weekly_reports',
            //     url:'weekly_reports',
            // },
            // performance : {
            //     name:'Performance',
            //     pathName:`/${selectedOrganization}/assets`,
            //     search:'?select=performance',
            //     url:'performance',
            // },
        }
      }

    }

      getData(apiLink + '/v1/organizations/' + selectedOrganization + '/user')
      .then(async response => {
        if(response && response.ok ){
          const data = await response.json();
          if(data && data.data){
            let userInfo = data.data[0]
            setUserOrgInfo(data.data[0])
            if(userInfo && Object.keys(userInfo).length > 0 && userInfo.organization_member_role_name &&  userInfo.organization_member_role_name == 'Organization Owner' || userInfo.organization_member_role_name == 'Organization Administrator'){
              setIsUserAuthorized(true)
            }else {
              setIsUserAuthorized(false)
            }
          } 
        }
      });  
      }
    

  }, [selectedOrganization])

  

  const updateUserInformation = () => {
    try{
      let currentSearch = location.search
  
      getData(apiLink + '/v1/users' + currentSearch)
      .then(async response => {
        if(response && response.ok ){
          const data = await response.json();
          if(data && data.data.length > 0 && data.data[0]){
            // console.log('APP.js',data.data[0])
            setUser(data.data[0])
            if(data.data[0].user_prefers_dark_mode){
              setIsDark(true)
            }else{
              setIsDark(false)
            }
          } 
        }
      });
    }catch(e){
      console.log(e.message)
    }
    
  }

  const updateOrganizationsList = () => {
    try{
      if(!isLoadingOrgs){
        setIsLoadingOrgs(true)
        let currentSearch = location.search
    
        getData(apiLink + '/v1/organizations' + currentSearch)
          .then(async response => {
            if(response.ok ){
              const data = await response.json();
              
              if(data && data.data){
                setOrgList(data.data)
                if(data.data.length === 0){
                  setUserHasNoOrgs(true)
                }
                setIsLoadingOrgs(false)
              }
            }else{
              setIsLoadingOrgs(false)
            }
            
          });
      }

    }catch(e){
      console.log(e.message)
      setIsLoadingOrgs(false)
    }
  }

  
  const signOut = () => {
    // delete any passed tokens
    localStorage.removeItem('passedToken')

    // sign out with amplify
    Auth.signOut()
      .then(() => { 
        navigate('/')
        window.location.reload()
        

        
      })
      .catch(err => { 
        setErrorMessage(err)
        console.log('error is .......' + err)
      })
  }

  

  
  return (
      <div className={`${isDark? 'dark': ''} scrollbar-hide `} >
        <Toaster />
        {orgList && Object.keys(orgList).length > 0 && 
        <div className={`dark:bg-slate-900  max-h-screen h-screen overflow-hidden scrollbar-hide relative`}>
          <Header orgList={orgList}  user={user} updateOrganizationsList={updateOrganizationsList} />
          <main className='scrollbar-hide w-full'>
                <NavigationBridge orgList={orgList} />
              <Routes>
                <Route path="/" element={<AssetsLayout>{subNav && Object.keys(subNav).length > 0 && <AssetsPage user={user} subNav={subNav}  />}</AssetsLayout>} />
                  {/* <Route path="/" element={<AssetsPage user={user}  />} /> */}
                <Route path="/:org_uuid" element={<AssetsLayout>{subNav && Object.keys(subNav).length > 0 && <AssetsPage user={user} subNav={subNav}  />}</AssetsLayout>} />
                  {/* <Route path="/:org_uuid" element={<AssetsPage user={user} />} /> */}
                <Route path="/:org_uuid/assets" element={<AssetsLayout>{subNav && Object.keys(subNav).length > 0 && <AssetsPage user={user} subNav={subNav}  />}</AssetsLayout>} />
                  {/* <Route path="/:org_uuid/assets" element={<AssetsPage user={user} />} /> */}
                  <Route path="/:org_uuid/assets/selected-asset/:assetId" element={<AssetsLayout><SelectedAssetPage  /></AssetsLayout>} />
                  {/* <Route path="/:org_uuid/assets/selected-asset/:assetId" element={<SelectedAssetPage />} /> */}
                  {/* <Route path="/:org_uuid/assets/selected-asset/:assetId/train" element={<SelectedAssetTrainingPage />} /> */}
                  <Route path="/:org_uuid/devices" element={<AssetsLayout><DevicesPage  /></AssetsLayout>} />
                  {/* <Route path="/:org_uuid/devices" element={<DevicesPage />} /> */}
                  <Route path="/:org_uuid/production" element={<AssetsLayout><ProductionPage  /></AssetsLayout>} />
                  {/* <Route path="/:org_uuid/production" element={<ProductionPage />} /> */}
                  <Route path="/:org_uuid/members" element={<AssetsLayout><DirectoryPage userInfo={userOrgInfo}  /></AssetsLayout>} />
                  <Route path="/:org_uuid/members/teams/:teamId" element={<AssetsLayout><SelectedTeamPage userInfo={userOrgInfo}  /></AssetsLayout>} />
                  {/* <Route path="/:org_uuid/members" element={<MembersPage />} /> */}
                  {/* <Route path="/:org_uuid/reports" element={<ReportsPage />} /> */}
                  <Route path="/:org_uuid/logs" element={<AssetsLayout><LogsPage  /></AssetsLayout>} />
                  <Route path="/:org_uuid/work-orders" element={<AssetsLayout><WorkOrdersPage userInfo={userOrgInfo}  /></AssetsLayout>} />
                  {/* <Route path="/:org_uuid/logs" element={<LogsPage />} /> */}
                  {/* <Route path="/:org_uuid/dashboards" element={<AssetsLayout><DashboardsPage  /></AssetsLayout>} /> */}
                  {/* Testing */}
                  {/* <Route path="/:org_uuid/canvas" element={<AssetsLayout><CanvasPage  /></AssetsLayout>} /> */}

                  <Route path="/:org_uuid/settings/organization" element={<AssetsLayout><MainSettingPage user={user} orgList={orgList} updateOrganizationsList={updateOrganizationsList} /></AssetsLayout>} />
                  {/* <Route path="/:org_uuid/settings/organization" element={<MainSettingPage user={user} orgList={orgList} updateOrganizationsList={updateOrganizationsList} />} /> */}
                  <Route path="/:org_uuid/settings/user" element={<AssetsLayout><ProfilePage user={user} updateUserInformation={updateUserInformation} /></AssetsLayout>} />
                  {/* <Route path="/:org_uuid/settings/user" element={<ProfilePage user={user} updateUserInformation={updateUserInformation} />} /> */}
                  <Route path="/:org_uuid/settings/billing" element={<AssetsLayout><BillingPage/></AssetsLayout>} />
                  {/* <Route path="/:org_uuid/settings/billing" element={<BillingPage />} /> */}

                  {/* <Route path="/:org_uuid/messaging" element={selectedOrganization && <MainMessagingPage selectedOrganization={selectedOrganization}/>} /> */}
                  <Route path="/:org_uuid/chats" element={<AssetsLayout><ChatsPage mqttMessage={mqttMessage} userInfo={userOrgInfo} /></AssetsLayout>} />
                  <Route path="/:org_uuid/conversations" element={<AssetsLayout><ConversationsPage mqttMessage={mqttMessage} userInfo={userOrgInfo} /></AssetsLayout>} />

                  <Route path="*" element={<ErrorPage />} />

              </Routes>
          </main>
          {isAddOrgModalOpen && <AddOrgModal btnType={'hideBtn'} remoteOpenAssetModal={isAddOrgModalOpen} updateOrganizationsList={updateOrganizationsList} />}
          {isAssetModalOpen && <AddAssetModal btnType={'hideBtn'} remoteOpenAssetModal={isAssetModalOpen} />}
          {isSensorModalOpen && <AddSensorModal btnType={'hideBtn'} remoteOpenSensorModal={isSensorModalOpen} />}
          {isGatewayModalOpen && <AddGatewayModal btnType={'hideBtn'} remoteOpenGatewayModal={isGatewayModalOpen} />}
          {isSendUrlLinkModalOpen && <SendUrlLinkModal btnType={'hideBtn'} remoteOpenGatewayModal={isSendUrlLinkModalOpen} />}
        </div>
        }
        {userHasNoOrgs && orgList && Object.keys(orgList).length === 0 &&
          <div className='flex justify-center mt-20'>
            <div className='flex flex-col'>
              <img src={IoTFlowsLogoBlackFont} className='w-48 mx-auto' alt='IoTFlows Logo'   />
              <div className='text-gray-600 max-w-[600px] bg-slate-50 shadow-iotflows-lg px-4 py-6 rounded-xl mt-4'>
                <h3 className='font-semibold pb-1 text-xl text-center'>
                  No Organization Found
                </h3>
                <p className='pb-4'>
                  {`Hi there! Looks like you don't currently have an organization set up on our platform. An organization allows you to gain real-time visibility into your assets, analyze production, and optimize operations for your entire fleet.`}
                </p>
                <h3 className='font-semibold pb-1'>
                  To join an organization:
                </h3>
                <p className='pb-4'>
                  Please reach out to a member of the desired organization and request an invitation. They can send the invitation to your email address.
                </p>
                <h3 className='font-semibold pb-1'>
                  To create an organization:
                </h3>
                <p className='pb-1'>
                  Please contact our support team at <a className='text-blue-500' href= "mailto: support@iotflows.com"> support@iotflows.com</a>. They'll be happy to guide you through the onboarding process.
                </p>
                <p className='pb-4'>
                  {`We look forward to welcoming you to our platform!`}
                </p>
                <p className='pb-1 font-medium'>
                 {` Best,`}
                </p>
                <p className='pb-1'>
                {`The IoTFlows Team`}
                </p>
              </div>
              <motion.button 
                whileHover={{ scale: 1.02 }} 
                whileTap={{ scale: 0.98 }} 
                className='w-full bg-blue-600 text-white text-center mt-2 rounded-lg py-1 font-medium'
                onClick={signOut}
                >
                Sign out
              </motion.button>
              {!errorMessage &&
                  <div className={`mt-2 ${isDark?'text-rose-400':'text-rose-600'} `}>{errorMessage}</div>
                }
            </div>
          </div>
        }
        
      </div>
    
  );
}

export default App;

