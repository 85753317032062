import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { darkModeState } from '../../../atoms/darkModeState'
import { PencilIcon, TrashIcon } from '@heroicons/react/solid';
import toast from 'react-hot-toast';
import { deleteData, putData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';

function ScreenRow({screen, selectedScreen, handleSelectedScreen, refreshScreenList}) {
    const isDark = useRecoilValue(darkModeState)
    const [loadingRemove, setLoadingRemove] = useState(false)
    const [editScreen, setEditScreen] = useState(false)
    const [deleteScreen, setDeleteScreen] = useState(false)
    const [tempName, setTempName] = useState(screen.tv_name)
    const [loadingSave, setLoadingSave] = useState(false)

    const handleCancelNewName = () => {
        setTempName(screen.tv_name)
        setEditScreen(false)
    }

    const handleSaveNewScreenName = () => {
        try {
            if(!loadingSave){
                setLoadingSave(true)
                console.log('tempName', tempName)
                putData(apiLink + '/v1/tvs/' + screen.tv_uuid , 
                {"tv_name": tempName })
                .then(async response => {
                    if(response.ok){
                        const data = await response.json();
                        toast.success('Screen name changed')
                        setLoadingSave(false)
                        setEditScreen(false)
                        refreshScreenList()
                    }
                    else{
                        try{
                            const data = await response.json();
                            setLoadingSave(false)
                            toast.error(data.message)
                        }catch (e){
                            toast.error('Something went wrong')
                            setLoadingSave(false)
                            }
                    }
                })
            }
            
        } catch (error) {
            console.log(error.message)
        }
    }


      const handleDeleteScreen = () => {
        try {
            if(!loadingRemove){
                setLoadingRemove(true)
                deleteData(apiLink + '/v1/tvs/' + screen.tv_uuid )
                .then(async response => {
                    if(response.ok){
                        const data = await response.json();
                        toast.success('Screen deleted')
                        setLoadingRemove(false)
                        setDeleteScreen(false)
                        refreshScreenList()
                    }
                    else{
                        try{
                            const data = await response.json();
                            setLoadingRemove(false)
                            toast.error(data.message)
                        }catch (e){
                            toast.error('Something went wrong')
                            setLoadingRemove(false)
                            }
                    }
                })
            }
            
        } catch (error) {
            console.log(error.message)
        }
    }
  return (
    <div >
        {!editScreen && !deleteScreen && 
        <div className={`flex px-1 py-1.5 border-b-[0.01em] ${isDark? 'border-slate-700 hover:bg-slate-600/50':'hover:bg-slate-100'} cursor-pointer items-center  ${selectedScreen.tv_uuid === screen.tv_uuid && isDark && 'bg-slate-600'} ${selectedScreen.tv_uuid === screen.tv_uuid && !isDark && 'bg-slate-200/80'}`} >
            <div className='flex flex-col pl-2 grow overflow-hidden ' onClick={() => handleSelectedScreen(screen)}>
            <div className='flex items-baseline'>
                <div className='max-w-[250px]'>
                <h1 className={`text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate`}>{screen.tv_name}</h1>
                </div>
                {/* <div className='pl-3 max-w-[200px]'>
                <h3 className={`pr-1 text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-light truncate italic truncate`}>{screen.tv_uuid}</h3>
                </div> */}
            </div>
            </div>
            <button className={`${isDark?'text-blue-400 hover:text-blue-200':'text-blue-600 hover:text-blue-900'} cursor-pointer flex ml-auto`} onClick={() => setEditScreen(true)}>
                <PencilIcon className={`w-4 h-4`}/>
            </button>
            <button className={`${isDark?'text-blue-400 hover:text-blue-200':'text-blue-600 hover:text-blue-900'} cursor-pointer flex ml-2`} onClick={() => setDeleteScreen(true)}>
                <TrashIcon className={`w-4 h-4`}/>
            </button> 
        </div>
        }
        {editScreen && !deleteScreen && 
            <div className={`flex px-1 py-1.5 border-b-[0.01em] ${isDark? 'border-slate-700 ':''}`}>
                <input 
                    className={`w-fit block bg-transparent border rounded-md py-0.5 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
                    type="text" 
                    defaultValue={tempName}
                    onChange={(e) => setTempName(e.target.value)}
                />
                <button className={`shadow rounded-md py-1 px-2 border-[1px] text-xs ${isDark?'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} flex ml-2`} onClick={handleCancelNewName}>
                    Cancel
                </button>
                <button disabled={loadingSave} className={`relative shadow rounded-md ml-1 py-1 px-2 border-[1px] border-blue-600 text-xs bg-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white`} onClick={handleSaveNewScreenName}>
                    Save
                    {loadingSave && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-0 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                    }
                </button>

            </div>
        }
        {!editScreen && deleteScreen && 
            <div className={`flex ml-2 items-center px-1 py-1.5 border-b-[0.01em] ${isDark? 'border-slate-700 ':''}`}>
                <span className={`text-sm text-left font-medium ${isDark?'text-gray-300':'text-gray-600'}`}>Are you sure you want to delete {screen.tv_name}?</span>
                <button className={`shadow rounded-md py-1 px-2 border-[1px] text-xs ${isDark?'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} flex ml-2`} onClick={() => setDeleteScreen(false)}>
                    Cancel
                </button>
                <button disabled={loadingRemove} className={`relative shadow rounded-md ml-1 py-1 px-2 border-[1px] border-red-600 text-xs bg-red-600 hover:bg-red-700 hover:border-red-700 text-white `} onClick={handleDeleteScreen}>
                    Delete
                    {loadingRemove && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-0 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                    }
                </button>
            </div>
        }
    </div>
  )
}

export default ScreenRow