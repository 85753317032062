import React, { useState } from 'react'
import useTable from '../../components/hooks/useTable';
import { LinearProgress } from '@mui/material';
import TableFooter from '../../components/TableFooter';
import DowntimeTableRow from './DowntimeTableRow';
import Papa from 'papaparse';


function DowntimeClassificationTable({data, rowsPerPage, loading}) {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);

    const DownloadCsvButton = ({ data, filename, field1, field2, field3, field4, field5, field6, field7, field8 }) => {
      const handleDownloadCsv = () => {
        const csvData = Papa.unparse([
          [field1, field2, field3, field4, field5, field6, field7, field8], // CSV header
          ...data.map(item => [item[field1], item[field2], item[field3], item[field4], item[field5], item[field6], item[field7], item[field8]])
        ]);


        
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${filename}.csv`;
        link.click();
      };
    
      return (
        <button 
          className='text-gray-600 dark:text-gray-300 mx-2 flex text-sm items-center space-x-1 border-[0em] border-gray-600 dark:border-gray-300 rounded-lg px-3 hover:text-gray-500 dark:hover:text-gray-400'
          onClick={handleDownloadCsv}
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
            </svg>

            <span className='leading-3'>CSV</span>
        </button>
      );
    };


  return (
    <>
      <div className='flex justify-end'>
              <DownloadCsvButton
              data={data}
              filename={`Downtime Classification Data`}
              field1="asset_identifier"
              field2="asset_name"
              field3="utilization_start_timestamp"
              field4="utilization_end_timestamp"
              field5="downtime_duration"
              field6="downtime_duration_seconds"
              field7="downtime_category_name"
              field8="utilization_downtime_note"
            />
            <TableFooter range={range} slice={slice} setPage={setPage} page={page} rowsPerPage={rowsPerPage} data={data} />
      </div>
      <div >
        <table className="table-auto w-full">
          <thead >
            <tr>
              <th colSpan={5} >
                <div className='font-light text-gray-500 dark:text-gray-400 italic text-sm text-left px-0.5 pt-3 pb-0'>
                  {`This table shows scheduled downtimes that overlap with your selected dates and shifts. Please note that only the first 1,000 downtimes will be displayed. Downtimes that began before this timeframe or exceed this limit won't be included.`}
                </div>
              </th>
            </tr>
              <tr className='border-b-[0.01em] dark:border-slate-600 text-left'>
                  <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3'>{``}</th>
                  <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3'>{`Duration`}</th>
                  <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3'>{`Category`}</th>
                  <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3'>{`Note`}</th>
                  <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-0.5 py-3'>{`Severity`}</th>
              </tr>
          </thead>
            <tbody >
            {loading &&
              <tr>
                <td colSpan={6}>
                <LinearProgress color="primary" sx={{height:2}}/>
                </td>
              </tr>
            }
            {slice.map((el,index) => (
              <DowntimeTableRow el={el} index={index} key={index} />
            ))}
          </tbody>
        </table>
      </div>
      <div className='flex justify-end mt-2'>
            <TableFooter range={range} slice={slice} setPage={setPage} page={page} rowsPerPage={rowsPerPage} data={data} />
      </div>
      
    </>
  )
}

export default DowntimeClassificationTable