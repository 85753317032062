
import gantt from "highcharts/highcharts-gantt";
import HighchartsReact from "highcharts-react-official";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import {darkModeState} from '../../atoms/darkModeState'
import { useEffect } from "react";
import Highcharts from "highcharts/highcharts.js";
import { getData } from "../../ApiCalls/DataApis";
import { apiLink } from "../../ApiCalls/ApisVariables";



var today = new Date(),
    day = 1000 * 60 * 60 * 24;

// Set to 00:00:00:000 today
today.setUTCHours(0);
today.setUTCMinutes(0);
today.setUTCSeconds(0);
today.setUTCMilliseconds(0);


const converDate = (longDate) => {
  let date = new Date(longDate)
  let time = date.getTime()
  return time
}

const converProgress = (progress) => {
  if(progress){
    let shortProgress = progress.toFixed(3)
    let percent = parseFloat(shortProgress)
    return percent
    
  }
}

var testDataName = function(project_data, data, isDark) {
  var projectArray = []
  var projectProgress = Number(project_data.production_time_progress_percentage)
  let name = ''
  name = project_data.production_name
  let fillColor = '#dc2626';

  // get total operations wanted
  let projectTotalCount = 0
  let projectTotalRequired = 0
  data.map(ops => {
    if(ops.operation_made_count <= ops.production_has_operation_operation_quantity){
      projectTotalCount += Number(ops.operation_made_count)
    }else{
      projectTotalCount += Number(ops.production_has_operation_operation_quantity)
    }
    projectTotalRequired += Number(ops.production_has_operation_operation_quantity)
  })
  let projectRation = (projectTotalCount/projectTotalRequired)
  if(projectRation >= projectProgress){
      fillColor = '#16a34a';
  }
  projectArray.push({
    name: project_data.production_name, 
    id: project_data.production_uuid,
    type: 'project',
    ratio: `${projectTotalCount}/${projectTotalRequired}`,
    start: converDate(project_data.production_start_date),
    end: converDate(project_data.production_end_date),
    color: isDark? '#334155' : '#e2e8f0',
    collapsed: false,
    completed: {
      amount: converProgress(projectRation),
      fill: fillColor
      }
  })
    data.map((operation,index) =>{
        let fillColor = '#dc2626';
        if(operation.progress >= projectProgress){
            fillColor = '#16a34a';
        }
      let operationCount = Number(operation.operation_made_count) ? Number(operation.operation_made_count) : 0
      projectArray.push({
        name: operation.operation_name, 
        id: operation.operation_uuid,
        type: 'part',
        ratio: `${operationCount}/${operation.production_has_operation_operation_quantity}`,
        // dependency: project.parts[prevIndex].production_uuid,
        // parent: project.production_uuid,
        start: converDate(project_data.production_start_date),
        end: converDate(project_data.production_end_date),
        color: isDark? '#334155' : '#e2e8f0',
        collapsed: false,
        completed: {
          amount: converProgress(operation.progress),
          fill: fillColor
          }
      })
    })
  let project = [{
    name: name,
    data: projectArray,
  }]
  return project
};


var defined = Highcharts.defined
var isObject = Highcharts.isObject

function GanttChartProjects({projectData}) {
    const isDark = useRecoilValue(darkModeState);
  const [ganttChartOptions, setGanttChartOptions] = useState([])
  const [operationsData, setOperationsData] = useState([])
  const [isDataReady, setIsDataReady] = useState(false)

  useEffect(() => {
    if(projectData && projectData.production_uuid){
      getData(apiLink + `/v1/productions/${projectData.production_uuid}/operations-progress` )
          .then(async response => {
              // JSON data parsed by response.json() call
              if(response.ok ){
              const data = await response.json();
                  if(data && data.data){
                    // console.log('operations-progress', data.data)
                    setOperationsData(data.data)
                  }
              }
          })
    }
    
  }, [projectData])

  useEffect(() => {
    if(operationsData && Object.keys(operationsData).length > 0 && ganttChartOptions && Object.keys(ganttChartOptions).length > 0 ){
      setIsDataReady(true)
    }
  },[operationsData, ganttChartOptions])

  const converTooltipDate = (date) =>{
    if(date){
      let convertedDate = new Date(date)
      const year = convertedDate.getFullYear();
// 👇️ getMonth returns integer from 0(January) to 11(December)
      const month = convertedDate.getMonth() + 1;
      const day = convertedDate.getDate();

      const withSlashes = [year, month, day].join('/');
      return withSlashes
    }
  }


  useEffect(() =>{
    if(operationsData && Object.keys(operationsData).length > 0){
      let data = testDataName(projectData, operationsData, isDark)
      setGanttChartOptions({
        chart: { 
            backgroundColor:'transparent',
          },
          time: {
            useUTC: false
          },
          title: {
              text: null
          },
          credits: {enabled: false},
          yAxis: {
            gridLineColor: isDark ?  '#334155' : '#D1D5DB',
            lineColor: isDark ?  '#334155' : '#D1D5DB',
            grid: {
                borderColor: isDark ?  '#334155' : '#D1D5DB',
            },
            labels: {
              formatter: function() {
                if(this.axis.series[0].data[this.pos] != undefined){
                  let data = this.axis.series[0].data[this.pos]
                  let currentValues = []
                  

                  if(this.axis.series[0].data[this.pos].id){
                    data = this.axis.series[0].data[this.pos].id
                    if(projectData.production_uuid == this.axis.series[0].data[this.pos].id){
                      currentValues = [projectData.production_name, '', projectData.production_uuid, this.axis.series[0].data[this.pos].type, this.axis.series[0].data[this.pos].ratio]
                    } 
                    operationsData.map((operation,index) =>{
                        if(operation.operation_uuid == this.axis.series[0].data[this.pos].id){
                          currentValues = [operation.operation_name, operation.part_picture_url, operation.operation_uuid, this.axis.series[0].data[this.pos].type, this.axis.series[0].data[this.pos].ratio]
                        }
                          // part.operations.map((operation, index) =>{
                          //   if(operation.operation_uuid == this.axis.series[0].data[this.pos].id){
                          //     currentValues = [operation.operation_name, operation.operation_description, operation.operation_uuid, this.axis.series[0].data[this.pos].type, this.axis.series[0].data[this.pos].ratio]
                          //   }
                          //   operation.assets.map((asset, index) => {
                          //     let assetID = `${asset.asset_uuid} - ${operation.operation_uuid}`
                          //     if(assetID == this.axis.series[0].data[this.pos].id){
                          //       currentValues = [asset.machine_name, asset.machine_picture_url, asset.asset_uuid, this.axis.series[0].data[this.pos].type, this.axis.series[0].data[this.pos].ratio, 'Department']
                          //     }
                          //   })
                          // })
                      })
      
                  }

                  

                  if(currentValues[3] === 'project'){
                      return (
                        `<div key='${currentValues[2]}' style="display:flex; align-items:center; width:350px; overflow: hidden; background: transparent; ">
                           <div style="display:flex; width:100%; align-items:center ">
                                <div style="font-weight: 700;font-size: 18px;line-height: 18px;color: ${isDark ? '#D1D5DB':'#4b5563'};text-overflow: ellipsis">${currentValues[0]}</div>
                                <div style="font-weight: 300;font-size: 14px;line-height: 14px;margin-left: auto; color: ${isDark ? '#60a5fa':'#2563eb'};text-overflow: ellipsis">${currentValues[4]}</div>
                            </div>
                        </div>`
                    )
                  } else if(currentValues[3] === 'part'){
                    // Detecting if image is valid is not working
                    var useIcon = false
                    const img = new Image();
                    img.src = currentValues[1];
                      
                    img.onload = () => {
                      console.log('onload')
                      useIcon = false

                    };
                    img.onerror = (err) => {
                      console.log('ERROR')
                      img.src = 'https://png.pngtree.com/png-clipart/20190920/original/pngtree-cartoon-gear-icon-download-png-image_4602942.jpg';
                    };
                    console.log('img',img)
                    return (
                      `<div key='${currentValues[2]}' style="display:flex; align-items:center; width:350px; overflow: hidden; background: transparent; padding-left:8px">
                      ${useIcon ? 
                        `<svg style="background-color:transparent;color:#d1d5db; vertical-align: middle; width: 30px; height: 30px;border-radius:50px; margin-right:0px; " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
                              <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                          </svg>` : `
                                <img src=${img.src} alt="" style="background-color:#fff; vertical-align: middle; width: 35px; height: 35px;border-radius:50px; margin-right:0px; onError "/>
                          `}`
                          + `<div style="display:flex; margin-left:4px; width:100%; align-items:center ">
                                <div style="font-weight: 600;font-size: 16px;line-height: 18px; color: ${isDark ? '#D1D5DB':'#4b5563'};text-overflow: ellipsis">${currentValues[0]}</div>
                                <div style="font-weight: 300;font-size: 13px;line-height: 14px;margin-left: auto; color: ${isDark ? '#60a5fa':'#2563eb'};text-overflow: ellipsis">${currentValues[4]}</div>
                              </div>
                      </div>`
                    )
                  }else if(currentValues[3] === 'operation'){
                    return (
                      `<div key='${currentValues[2]}' style="display:flex; align-items:center; width:350px; overflow: hidden; background: transparent; padding-left:'28px">
                        <div style="display:flex; width:100%; align-items:center ">
                              <div style="font-weight: 400;font-size: 15px;line-height: 16px;margin-left:52px; color: ${isDark ? '#D1D5DB':'#4b5563'};overflow: hidden; white-space: nowrap; text-overflow: ellipsis">${currentValues[0]} <span style="font-weight: 300;font-size: 13px;line-height: 16px;margin-left:4px; color: ${isDark ? '#D1D5DB':'#4b5563'};">${currentValues[1]}</span> </div>
                              <div style="font-weight: 300;font-size: 12px;line-height: 14px;margin-left: auto; color: ${isDark ? '#60a5fa':'#2563eb'};text-overflow: ellipsis">${currentValues[4]}</div>
                              </div>
                      </div>`
                    )
                  }else{
                      return (
                        `<div key='${currentValues[2]}' style="display:flex; align-items:center; width:300px; overflow: hidden; background: transparent; margin-left:'8px">
                            ${currentValues[1]!="" ?` <img src=${currentValues[1]} alt="" style="background-color:#fff; vertical-align: middle; width: 35px; height: 35px;border-radius:50px; margin-right:5px"/>`: ''}`
                            + `<div style="display:flex; flex-direction: column; justify-content: center; padding-left:8px">
                                <div style="font-weight: 300;font-size: 18px;line-height: 18px;margin-left: 18px;color: ${isDark ? '#D1D5DB':'#4b5563'};text-overflow: ellipsis">${currentValues[0]}</div>
                                ${currentValues[5] ? `<div style="font-weight: 400;font-size: 12px;line-height: 14px;color: ${isDark ? '#9ca3af':'#6b7280'};text-overflow: ellipsis">${currentValues[3]}</div>` : ''}
                                </div>
                        </div>`
                    )
                  }
                    
                }
                
              },
              // align: 'left',
              // symbol:{
              //   height:7,
              //   width: 15,
              //   padding: 10,
              //   y:-10,
              //   // x:-10,
              // },
                useHTML: true
            },
            title: null,
            reversed: true,
        },
        navigator: {
          enabled: true,
          liveRedraw: true,
          height:20,
          margin:10,
          series: {
              type: 'gantt',
              pointPlacement: 0.5,
              pointPadding: 0.25,
              accessibility: {
                  enabled: false
              }
          },
          yAxis: {
              min: 0,
              max: 3,
              reversed: true,
              categories: []
          },
        },
      
        scrollbar: {
            enabled: false
        },
      
        rangeSelector: {
            enabled: true,
            selected: 4,
            buttons: [{
                type: 'week',
                count: 1,
                text: '1w',
                title: 'View 1 week'
            },{
                type: 'week',
                count: 2,
                text: '2w',
                title: 'View 2 weeks'
            }, {
                type: 'month',
                count: 1,
                text: '1m',
                title: 'View 1 month'
            }, {
                type: 'month',
                count: 3,
                text: '3m',
                title: 'View 3 months'
            },{
                type: 'all',
                text: 'All',
                title: 'View all'
            }],
            buttonTheme: { // styles for the buttons
                fill: 'none',
                stroke: 'none',
                'stroke-width': 0,
                r: 8,
                style: {
                    color: '#2563eb',
                    fontWeight: 'bold'
                },
                states: {
                    hover: {
                    },
                    select: {
                        fill: '#2563eb',
                        style: {
                            color: 'white'
                        }
                    },
                    disabled: { 
                        // fill: '#2563eb',
                        style: {
                            color: isDark ? '#374151':'#d1d5db',
                        }
                    }
                }
            },
            inputStyle: {
                color: '#9ca3af',
                fontWeight: 'bold'
            },
            labelStyle: {
                color: '#6b7280',
                fontWeight: 'bold'
            },
        },
        plotOptions: {
            series: {
                borderColor: isDark ?  '#334155' : '#D1D5DB',
                borderRadius: 15,
            },
            gantt: {
                borderColor: isDark ?  '#334155' : '#D1D5DB',
                
            }
        },
        tooltip: {
          pointFormatter: function () {
                  
              var point = this,
                  format = '%e. %b',
                  options = point.options,
                  completed = options.completed,
                  amount = isObject(completed) ? completed.amount : completed,
                  status = ((amount || 0) * 100).toFixed(1) + '%', 
                  lines;
              lines = [{
                  value: point.name,
                  style: 'font-weight: bold;'
              }, {
                  title: 'Start',
                  value: converTooltipDate(point.start)
              }, {
                  title: 'End',
                  value: converTooltipDate(point.end)
              }, 
              {
                  title: 'Completed',
                  value: status
              }];
  
              return lines.reduce(function (str, line) {
                  var s = '',
                      style = (
                          defined(line.style) ? line.style : 'font-size: 0.8em;'
                      );
                  if (line.visible !== false) {
                      s = (
                          '<span style="' + style + '">' +
                          (defined(line.title) ? line.title + ': ' : '') +
                          (defined(line.value) ? line.value : '') +
                          '</span><br/>'
                      );
                  }
                  return str + s;
              }, '');
          }
      },
        series:data,
        xAxis: [
            { // first x-axis
              // min: today.getTime() - (1 * day),
              // max: today.getTime() + (10 * day),
            lineColor: isDark ?  '#334155' : '#D1D5DB',
            tickColor: isDark ?  '#334155' : '#D1D5DB',
            currentDateIndicator: {
                width: 2,
                dashStyle: 'LongDash',
                color: '#94a3b8',
                label: {
                    style: {
                        color: isDark ? '#d1d5db': '#4b5563'
                    }
                }
            },
              labels: {
                style: {
                    color: isDark ? '#9ca3af': '#4b5563'
                }
            },
              tickInterval: 1000 * 60 * 60 * 24 // 1 month
            }, { // second x-axis
              // min: today.getTime() - (2 * day),
              // max: today.getTime() + (10 * day),
                lineColor: isDark ?  '#334155' : '#D1D5DB',
                tickColor: isDark ?  '#334155' : '#D1D5DB',
            currentDateIndicator: {
                width: 2,
                dashStyle: 'LongDash',
                color: '#94a3b8',
                label: {
                    style: {
                        color: isDark ? '#d1d5db': '#4b5563'
                    }
                }
            },
              labels: {
                style: {
                    color: isDark ? '#9ca3af': '#4b5563'
                }
            },
              tickInterval: 1000 * 60 * 60 * 24 * 30, // 1 day
            }
        ],
      })
    }

  },[isDark, operationsData])


  
  return (
    <div>
      {!isDataReady &&
          <div className="border-0 border-blue-300 shadow-iotflows-lg dark:shadow-lg-invert rounded-md p-4 w-full mx-auto">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-6 py-1">
                <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded"></div>
                <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                    <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                  <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded"></div>
                  <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        }
      {isDataReady && ganttChartOptions && Object.keys(ganttChartOptions).length > 0 && 
        <HighchartsReact
            constructorType={"ganttChart"}
            highcharts={gantt}
            options={ganttChartOptions}
        />
      }
    </div>
  )
}

export default GanttChartProjects


function handleIsImage (image) {
  // const img = new Image();
  // img.src = image;
  var isError = false
    console.log('image', image)
  
  // img.onload = () => {
  //   console.log('img ONLOAD ', img)
  
  // };
  // img.onerror = (err) => {
  //   isError = true
  // };

  return (
    <div>
      <svg className={`w-8 h-8 rounded-full mx-auto`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
           <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
           <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
       </svg>
      {/* <img src={image} alt="" style="background-color:#fff; vertical-align: middle; width: 35px; height: 35px;border-radius:50px; margin-right:0px; "/> */}
    </div>
  )
  
  // if(isError){
  //   return (
  //     <svg className={`w-8 h-8 rounded-full mx-auto`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
  //         <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
  //         <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
  //     </svg>
  //   )
  // }else {
  //   console.log('ELSE')
  //   return (
  //     <img src={image} alt="" style="background-color:#fff; vertical-align: middle; width: 35px; height: 35px;border-radius:50px; margin-right:0px; "/>
  //   )
  // }
}