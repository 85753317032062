import React, { useEffect, useState } from 'react'
import moment from "moment"
import { putData } from '../../ApiCalls/DataApis'
import { apiLink } from '../../ApiCalls/ApisVariables'
import toast from 'react-hot-toast';
import { LightTooltip, MemberTooltip } from '../../styles/Tooltip'
import ArchiveOperationModal from '../../components/modals/partModals/ArchiveOperationModal'
import DeleteOperationModal from '../../components/modals/partModals/DeleteOperationModal'
import { Zoom } from '@mui/material';
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';

function CycleTimeInputCell({operation, operationAlgorithmId, getOpsList}) {
    const isDark = useRecoilValue(darkModeState)
    const [sdTimes, setSdTimes] = useState('')
    const [hours, setHours] = useState('')
    const [minutes, setMinutes] = useState('')
    const [seconds, setSeconds] = useState('')
    const [milliSeconds, setMilliSeconds] = useState('')
    const [stdevPercent, setStdevPercent] = useState(10)
    const [totalCycleTime, setTotalCycleTime] = useState('')
    // const [sdMinutes, setSdMinutes] = useState(null)
    // const [sdSeconds, setSdSeconds] = useState(null)
    // const [sdMilliSeconds, setSdMilliSeconds] = useState(null)
    const [loading, setLoading] = useState(false)
    const [openArchiveOpModal, setOpenArchiveOpModal] = useState(false)
    const [openDeleteOpModal, setopenDeleteOpModal] = useState(false)


    useEffect(() => {
        if(operation && operation.operation_cycle_time ){

            convertSecondsToTime(operation.operation_cycle_time)

            if(operation.operation_operation_detection_algorithm_id == 100 || operation.operation_operation_detection_algorithm_id == 300){
                let stPercent = (operation.operation_stdev_percentage * 100).toFixed(0)
                setStdevPercent(stPercent)
                
                    let sd_from_time = operation.operation_cycle_time - (operation.operation_cycle_time * operation.operation_stdev_percentage)
                    let sd_to_time = operation.operation_cycle_time + (operation.operation_cycle_time * operation.operation_stdev_percentage)
                    
                    
                    let sd_from_time_formated = moment.utc(sd_from_time*1000).format('HH:mm:ss.S')
                    let sd_to_time_formated = moment.utc(sd_to_time*1000).format('HH:mm:ss.S')
                    let string_times = `(${sd_from_time_formated} - ${sd_to_time_formated})`
                    
                    setSdTimes(string_times)
                
            }
        }
    },[operation])


    const convertSecondsToTime = (secs) => {
        
        let seconds = parseFloat(secs)
        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds % 3600) / 60);
        let remainingSeconds = Math.floor(seconds % 60);
        let removeSecs = (seconds % 60) - remainingSeconds
        let milliseconds = Math.floor((removeSecs) * 100); // Convert remaining seconds to milliseconds

        setHours(hours)
        setMinutes(minutes)
        setSeconds(remainingSeconds)
        setMilliSeconds(milliseconds)

    }

    const updateCycleTimes = (e, selectedInput) => {
        

            var hours_to_s = 0
            if(selectedInput === 'operation_cycle_time_h'){
                if(e.target.value){
                    // const newValue = e.target.value.replace(/^0+/, '').slice(0, 1) // Don't allow leading zeros and only 1 character
                    const newValue = e.target.value.slice(0, 1) // Only allow 1 character
                    setHours(parseInt(newValue))
                    if(parseInt(e.target.value) >= 0){
                        hours_to_s = parseInt(newValue) * 60 * 60
                    }
                }else{
                    setHours('')
                }
            }else {
                if(hours){
                    hours_to_s = hours * 60 * 60
                }
            }
    
            var min_to_s = 0
            if(selectedInput === 'operation_cycle_time_m'){
                // console.log('e.target.value)', e.target.value)
                if(e.target.value){
                    const newValue = parseInt(e.target.value.slice(0, 2)) // Only allow 2 character
                    if(newValue > 59){
                        setMinutes(59)
                    }else{
                        setMinutes(newValue)
                    }
                    if(newValue > 0 && newValue < 60){
                        min_to_s = newValue * 60 
                    }
                }else{
                    setMinutes('')
                    min_to_s = 0
                }
            }else {
                if(minutes){
                    min_to_s = minutes * 60 
                }
            }
    
            let secs = 0
            if(selectedInput === 'operation_cycle_time_s'){
                if(e.target.value){
                    const newValue = parseInt(e.target.value.slice(0, 2)) // Don't allow leading zeros and only 1 character
                    if(newValue > 59){
                        setSeconds(59)
                        secs = 59
                    }else{
                        setSeconds(newValue)
                        secs = newValue
                    }
                }else{
                    setSeconds('')
                }
            }else {
                if(seconds){
                    secs = seconds
                }
            }

            let milliSecs = 0.0
            if(selectedInput === 'operation_cycle_time_ms'){
                if(e.target.value){
                    const newValue = parseInt(e.target.value.slice(0, 2)) // Don't allow leading zeros and only 1 character
                    setMilliSeconds(newValue)
                    // setMilliSeconds(newValue)
                    if(newValue > 0){
                        let percent = '.'+ newValue
                        milliSecs = parseFloat(percent)
                        // milliSecs = parseFloat(percent) * 1000
                        // milliSecs = newValue / 100.0
                    }
                }else{
                    setMilliSeconds('')
                }
            }else {
                if(milliSeconds){
                    milliSecs = milliSeconds / 100.0
                }
            }

            let st_dev_percent = 0
            if(selectedInput === 'operation_cycle_time_stdev_percent'){
                // console.log('e.target.value st_dev_percent', e.target.value)
                if(e.target.value){
                    // const newValue = e.target.value // Don't allow leading zeros and only 1 character
                    const newValue = parseFloat(e.target.value) // Don't allow leading zeros and only 1 character
                    if(newValue > 99){
                        setStdevPercent(99)
                        st_dev_percent = 99/100
                    }else if(newValue > 0 && newValue <= 99){
                        setStdevPercent(newValue)
                        st_dev_percent = newValue/100
                    }else{
                        st_dev_percent = 1/100
                        setStdevPercent(1)
                    }
                }else{
                    setStdevPercent(e.target.value)
                }
            }else{
                st_dev_percent = parseFloat(stdevPercent)/100
            }


            // console.log('hours_to_s', hours_to_s)
            // console.log('min_to_s', min_to_s)
            // console.log('secs', secs)
            // console.log('milliSecs', milliSecs)
            let totalTime =  hours_to_s + min_to_s + secs + milliSecs
            // console.log('totalTime', totalTime)
            setTotalCycleTime(totalTime)

            

            if(operationAlgorithmId === 100 || operationAlgorithmId === 300){
                let sd_from_time = totalTime - (totalTime * st_dev_percent)
                let sd_to_time = totalTime + (totalTime * st_dev_percent)

                
                var sd_from_time_formated = moment.utc(sd_from_time*1000).format('HH:mm:ss.SS')
                var sd_to_time_formated = moment.utc(sd_to_time*1000).format('HH:mm:ss.SS')
                var string_times = `(${sd_from_time_formated} - ${sd_to_time_formated})`

                
                setSdTimes(string_times)
            }

            
            

    }

    const handleCloseArchiveOperationModal = () => {
        setOpenArchiveOpModal(false)
    }
    const handleCloseDeleteOperationModal = () => {
        setopenDeleteOpModal(false)
    }

    const handleStDevKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleInputsBlur(event)
            event.target.blur();
        }
    }

    const handleInputsBlur = (event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          // Focus moved outside the div (not to a child input)
        //   console.log('Blurred outside the div'); // Your desired action
            let percent = stdevPercent
            if(operationAlgorithmId === 200){
                percent = 0
            }else{
                if(percent == 0 || !percent){
                    percent = 0.1
                    setStdevPercent(10)
                }else{
                    percent = percent/100
                }
            }
            if(!loading){
                setLoading(true)
                putData(apiLink + '/v2/operations/' + operation.operation_uuid , 
                {'operation_cycle_time': totalCycleTime,
                'operation_stdev_percentage': percent})
                .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                        // console.log('data', data.data[0])
                        setLoading(false)
                        if(totalCycleTime != data.data[0].operation_cycle_time){
                            // console.log('NOT THE SAME')
                            convertSecondsToTime(data.data[0].operation_cycle_time)
                        }
                    }
                    else{
                        try{
                            convertSecondsToTime(operation.operation_cycle_time)
                            setLoading(false)
                            toast.error(data.message)
                        }catch (e){
                            convertSecondsToTime(operation.operation_cycle_time)
                            toast.error(e.message)
                            setLoading(false)
                        }
                    }
                })
            }
        }
      };

      

  return (
        <td className='relative font-light text-gray-600 w-[400px] dark:text-gray-300 h-6 px-2 py-0.5 border-[0.01em] dark:border-zinc-600'>
            <div className='flex pl-3 pr-2 items-center' >
                    <div className='flex items-center' onBlur={handleInputsBlur}>
                        <div className='flex flex-col max-w-[20px] text-center'>
                            <input 
                                className={`text-center block bg-transparent w-full border-0 rounded-md py-1 px-0 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800`} 
                                min={0}
                                max={9}
                                maxLength={1}
                                placeholder={`0`}
                                type={'number'}
                                name={'operation_cycle_time_h'}
                                value={hours}
                                onChange={(e) => updateCycleTimes(e, 'operation_cycle_time_h')}
                            />
                            <div className={`text-xs text-gray-500 dark:text-gray-400`}>
                                <span>{`hr`}</span>
                            </div>
                        </div>
                        <div className='flex flex-col text-xl mx-1'>
                            :
                            <div className={`h-4 text-xs text-gray-500 dark:text-gray-400`}>
                                <span>{``}</span>
                            </div>
                        </div>
                        <div className='flex flex-col max-w-[20px] text-center'>
                            <input 
                                className={`text-center block bg-transparent w-full border-0 rounded-md py-1 px-0 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800`} 
                                min={0}
                                max={59}
                                placeholder={`00`}
                                type={'number'}
                                name={'operation_cycle_time_m'}
                                value={minutes}
                                onChange={(e) => updateCycleTimes(e, 'operation_cycle_time_m')}
                            />
                            <div className={`text-xs text-gray-500 dark:text-gray-400`}>
                                <span>{`min`}</span>
                            </div>
                        </div>
                        <div className='flex flex-col text-xl mx-1'>
                            :
                            <div className={`h-4 text-xs text-gray-500 dark:text-gray-400`}>
                                <span>{``}</span>
                            </div>
                        </div>
                        <div className='flex flex-col max-w-[20px] text-center'>
                            <input 
                                className={`text-center block bg-transparent w-full border-0 rounded-md py-1 px-0 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800`} 
                                min={0}
                                placeholder={`00`}
                                type={'number'}
                                name={'operation_cycle_time_s'}
                                value={seconds}
                                onChange={(e) => updateCycleTimes(e, 'operation_cycle_time_s')}
                            />
                            <div className={`text-xs text-gray-500 dark:text-gray-400`}>
                                <span>{`sec`}</span>
                            </div>
                        </div>
                        <div className='flex flex-col text-xl mx-1'>
                            .
                            <div className={`h-4 text-xs text-gray-500 dark:text-gray-400`}>
                                <span>{``}</span>
                            </div>
                        </div>
                        <div className='flex flex-col max-w-[20px] text-center'>
                            <input 
                                className={`text-center block bg-transparent w-full border-0 rounded-md py-1 px-0 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800`} 
                                min={0}
                                type={'number'}
                                max={99}
                                name={'operation_cycle_time_ms'}
                                placeholder={`0`}
                                value={milliSeconds}
                                maxLength={2}
                                onChange={(e) => updateCycleTimes(e, 'operation_cycle_time_ms')}
                            />
                            <div className={`h-4 text-xs text-gray-500 dark:text-gray-400`}>
                                <span>{``}</span>
                            </div>
                        </div>
                        {(operationAlgorithmId == 100 || operationAlgorithmId == 300) &&
                        <div className='flex'>
                            <div className={`block items-baseline ml-2 mr-1 my-auto text-gray-500 dark:text-gray-400`}>
                                <svg xmlns="http://www.w3.org/2000/svg" className={`w-3 h-3 `} fill={`currentColor`} viewBox="0 0 384 512">
                                    <path d="M224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V144H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H160V320c0 17.7 14.3 32 32 32s32-14.3 32-32V208H336c17.7 0 32-14.3 32-32s-14.3-32-32-32H224V32zM0 480c0 17.7 14.3 32 32 32H352c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32z"/>
                                </svg>
                            </div>
                            <MemberTooltip TransitionComponent={Zoom} 
                              title={
                                <div className={`flex rounded-lg  text-center justify-center ${isDark? ' bg-slate-700':' bg-slate-50'} `}>
                                  <div className={`flex flex-col w-fit px-6 py-1.5  text-sm font-light ${isDark? 'text-gray-100':'text-gray-700'}`}>
                                    <span>Cycle Time Range</span>
                                    <span className={`${isDark? 'text-blue-400':'text-blue-600'}`}>{sdTimes}</span>
                                  </div>
                                </div>
                              }
                            >
                            <div className='flex flex-col'>
                                <div className='flex max-w-[40px] text-center items-center'>
                                    <input 
                                        className={`text-center block bg-transparent w-full border-0 rounded-md py-1 px-0 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800`} 
                                        min={0}
                                        type={'number'}
                                        max={99}
                                        name={'operation_cycle_time_stdev_percent'}
                                        placeholder={`0`}
                                        value={stdevPercent}
                                        onChange={(e) => updateCycleTimes(e, 'operation_cycle_time_stdev_percent')}
                                        onKeyDown={handleStDevKeyDown}
                                    />
                                    <div className={`text-sm text-gray-500 dark:text-gray-400 pl-0.5`}>
                                        <span>{`%`}</span>
                                    </div>
                                </div>
                            </div>
                            </MemberTooltip>
                        </div>
                        }
                    </div>
                <LightTooltip title='Archive Operation'>
                <button 
                    className={`text-gray-500 dark:text-gray-400 hover:text-gray-400 dark:hover:text-gray-500 ml-auto opacity-0 ease-in-out duration-300 group-hover:opacity-100`}
                    onClick={() => setOpenArchiveOpModal(true)}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0-3-3m3 3 3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                    </svg>
                </button>
                </LightTooltip>
                <LightTooltip title='Delete Operation'>
                <button 
                    className={`text-gray-500 dark:text-gray-400 hover:text-gray-400 dark:hover:text-gray-500 ml-2 opacity-0 ease-in-out duration-300 group-hover:opacity-100`}
                    onClick={() => setopenDeleteOpModal(true)}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                    </svg>
                </button>
                </LightTooltip>
            </div>
            {loading && 
                <div >
                    <svg aria-hidden="true" className={`absolute top-1/4 right-1/2 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                    </svg>
                </div>
                }
            {openArchiveOpModal && 
                <ArchiveOperationModal operation={operation} remoteOpenModal={openArchiveOpModal} handleCloseArchiveOperationModal={handleCloseArchiveOperationModal} getOpsList={getOpsList} />
                }
            {openDeleteOpModal && 
                <DeleteOperationModal operation={operation} remoteOpenModal={openDeleteOpModal} handleCloseDeleteOperationModal={handleCloseDeleteOperationModal} getOpsList={getOpsList} />
                }
        </td>
  )
}

export default CycleTimeInputCell