import React, { useState } from 'react'
import AssetDowntimeCard from '../AssetDowntimeCard'
import AssetUnclassifiedActivityCard from '../AssetUnclassifiedActivityCard'
import AssetActivityCard from '../AssetActivityCard'
import { darkModeState } from '../../atoms/darkModeState'
import { useRecoilValue } from 'recoil'
import StatusSectionPopupModal from '../modals/assetmodals/StatusSectionPopupModal'
import { MemberTooltip } from '../../styles/Tooltip'
import { Zoom } from '@mui/material'

function StatusBarSection({index, activity, refreshActivityList, operationsList, downtimeCategories, refreshDowntimeCategories}) {
    // const [showToolTip, setShowToolTip] = useState(false)
    const [openStatusSectionPopupModal, setOpenStatusSectionPopupModal] = useState(false)
    const isDark = useRecoilValue(darkModeState)

    const handleCloseModal = () => {
        setOpenStatusSectionPopupModal(false)
    }

    

    const fillerStyles = (index, width, color) => { 
        // let currentWidth = '2%'
        let currentWidth = width
        // console.log('color', color)
        // let stringNum = currentWidth.replace('%', '');
        // let num = parseFloat(stringNum)
        // if(num < 0 ){
        //   currentWidth = '0%'
        // }
        // widthCalc = num+widthCalc

        // Ensureing that theres enough width to add a border in the left side in order to ensure the progress bar doesn't go > 100%
        let seconds = Number(activity.duration_s)
        // console.log('seconds', seconds)

        if(activity && activity.utilization_state_identifier === 'RUNNING_OPC' && seconds > 15){
          // console.log('activity', activity)
          return{
            height: '100%',
            width: currentWidth,
            boxSizing: 'border-box',
            borderLeft: '0.01em solid #15803d',
            backgroundColor: color ? color : "#94a3b8",
            borderRadius: index === 0 ?'999px 0px 0px 999px' :'0rem 0rem 0rem 0rem',
            textAlign: 'right',
            transition: 'width 1s ease-in-out',
          }
        }else{
          return{
            height: '100%',
            width: currentWidth,
            backgroundColor: color ? color : "#94a3b8",
            borderRadius: index === 0 ?'999px 0px 0px 999px' :'0rem 0rem 0rem 0rem',
            textAlign: 'right',
            transition: 'width 1s ease-in-out',
          }
        }

      }

    //   console.log('activity', activity)

    const formatDate = (end) => {
      if(end){
          // var startDate = new Date(start)
          // var startHours = startDate.getHours();
          // var startMinutes = startDate.getMinutes();
          // var startAmPm = startHours >= 12 ? 'pm' : 'am';
          // startHours = startHours % 12;
          // startHours = startHours ? startHours : 12; // the hour '0' should be '12'
          // startMinutes = startMinutes < 10 ? '0'+startMinutes : startMinutes;
          // var startStrTime = startHours + ':' + startMinutes + ' ' + startAmPm;
          // var shortStartDate = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2)

          var endDate = new Date(end)
          var endHours = endDate.getHours();
          var endMinutes = endDate.getMinutes();
          var endAmPm = endHours >= 12 ? 'pm' : 'am';
          endHours = endHours % 12;
          endHours = endHours ? endHours : 12; // the hour '0' should be '12'
          endMinutes = endMinutes < 10 ? '0'+endMinutes : endMinutes;
          var endStrTime = endHours + ':' + endMinutes + '' + endAmPm;
          var shortEndDate = endDate.getMonth()+1 + "/" + endDate.getDate() + "/" + endDate.getFullYear().toString().substr(-2)
          // var endTime = endDate.getMonth()+1 + "/" + endDate.getDate() + "/" + endDate.getFullYear().toString().substr(-2) + " " + endStrTime;
          // var startTime = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2) + " " + startStrTime;
          return endStrTime;
          // return shortEndDate + "  " + endStrTime;

          // if(shortStartDate == shortEndDate){
          //     return shortStartDate + "  " + startStrTime +  " - " + endStrTime;
          // }else{
          //     return shortStartDate + " " + startStrTime + " - " + shortEndDate + " " + endStrTime;
          // }
      }
  }


  return (
        // <MemberTooltip  
        //     TransitionComponent={Zoom}
        //     title={
        //         <div className={`flex flex-col rounded-lg p-2 ${isDark? ' bg-slate-700':' bg-slate-100'} `}>
        //           <div className={`w-full text-center py-1 text-sm ${isDark? ' text-gray-400':'text-gray-700'} `}>
        //               <span>{activity.utilization_state_identifier}</span>
        //           </div>
        //           <div className={`w-full text-center py-1 text-sm ${isDark? ' text-gray-400':'text-gray-700'} `}>
        //               <span>{formatDate(activity.utilization_start_timestamp)} - {formatDate(activity.utilization_end_timestamp)} </span>
        //           </div>
        //         </div>
        //     }
        //     key={`tooltip-${index}`}
        //     >
        //   {/* <div key={`status-${index}`} style={fillerStyles(index, activity.amount, activity.utilization_state_color)} /> */}
        // </MemberTooltip>
          <div className='cursor-pointer' style={fillerStyles(index, activity.amount, activity.utilization_state_color)} onClick={() => setOpenStatusSectionPopupModal(true)}>
              {openStatusSectionPopupModal &&
                  <StatusSectionPopupModal remoteOpen={openStatusSectionPopupModal} activity={activity} refreshActivityList={refreshActivityList} operationsList={operationsList} downtimeCategories={downtimeCategories} refreshDowntimeCategories={refreshDowntimeCategories} handleCloseModal={handleCloseModal}  />
              }
          </div>
  )
}

export default StatusBarSection


