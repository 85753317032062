import LinkifyIt from 'linkify-it';
import tlds from 'tlds';

const linkify = new LinkifyIt();
linkify.tlds(tlds);

export function createHyperlinks(message_html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(message_html, 'text/html');
  
    function processTextNode(node) {
        const matches = linkify.match(node.textContent);
        if (!matches) return;
    
        const fragment = document.createDocumentFragment();
        let lastIndex = 0;
    
        matches.forEach(match => {
          // Text before the link
          if (match.index > lastIndex) {
            fragment.appendChild(document.createTextNode(node.textContent.slice(lastIndex, match.index)));
          }
    
          // Create anchor for the link
          const anchor = document.createElement('a');
          anchor.href = match.url;
          anchor.textContent = match.text;
          anchor.target = '_blank';
          anchor.rel = 'noopener noreferrer';
          fragment.appendChild(anchor);
    
          lastIndex = match.lastIndex;
        });
    
        // Remaining text after the last link
        if (lastIndex < node.textContent.length) {
          fragment.appendChild(document.createTextNode(node.textContent.slice(lastIndex)));
        }
    
        node.parentNode.replaceChild(fragment, node);
    }
  
    function walkTree(node) {
        const childNodes = Array.from(node.childNodes);
        childNodes.forEach(child => {
            if (child.nodeType === Node.TEXT_NODE) {
            processTextNode(child);
            } else if (child.nodeType === Node.ELEMENT_NODE) {
            walkTree(child);
            }
        });
    }
  
    walkTree(doc.body);
  
    return doc.body.innerHTML;
  }