import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import HighchartsReact from "highcharts-react-official";
import HighchartsStock from 'highcharts/highstock';
import highchartsMore from "highcharts/highcharts-more.js";
import Highcharts from "highcharts/highcharts.js";
import { darkModeState } from '../../../atoms/darkModeState';

highchartsMore(Highcharts);

function StackedBarChartLarge({totalUtilizations, toggleShowPercentData, toggleOnlyUptimeData}) {
    const [hourCountBarChartOptions, setHourCountBarChartOptions] = useState({
        credits: {enabled: false},
        chart: {
            type: 'column',
            zoomType: 'x',
            backgroundColor:'transparent',
            // height:'110px',
        },
        time: {
            useUTC: false
          },
        title:{
            text:''
        },
    })
    const isDark = useRecoilValue(darkModeState);
    
    useEffect(() => {
        let red = 'rgba(220, 38, 38, 0.3)'
        let sky = '#0ea5e9'
        let skyLight = 'rgba(14, 165, 233,0.5)'
        let gray = 'rgba(107, 114, 128, 0.3)'
        
        if(!isDark){
            gray = '#d1d5db'
            red = 'rgba(220, 38, 38, 0.5)' //red 600
            // red = '#fb7185'
            // red = 'rgba(251, 113, 133, 0.6)'


            // red = 'rgba(220, 38, 38, 0.3)'
            sky = '#60a5fa'
            // sky = '#38bdf8'
            skyLight = 'rgba(96, 165, 250,0.5)'
            // skyLight = 'rgba(56, 189, 248,0.5)'
        }
        if(totalUtilizations && Object.keys(totalUtilizations).length > 0){
            let uptimeData = toggleShowPercentData ? [...totalUtilizations.uptime_percentages] : [...totalUtilizations.uptime_hours]
            let downtimeData = toggleShowPercentData ? [...totalUtilizations.downtime_percentages] : [...totalUtilizations.downtime_hours]
            let unknownData = toggleShowPercentData ? [...totalUtilizations.unknown_percentages] : [...totalUtilizations.unknown_hours]  
               
            if(toggleOnlyUptimeData){
                downtimeData = []
                unknownData = []
            }
            
            setHourCountBarChartOptions({
                credits: {enabled: false},
                chart: {
                    type: 'column',
                    zoomType: 'x',
                    backgroundColor:'transparent',
                    // height:'110px',
                },
                time: {
                    useUTC: false
                  },
                title:{
                    text:''
                },
                xAxis: {
                    categories: totalUtilizations.categories,
                    // type: 'datetime',
                    labels: {
                        style: {
                            color: isDark? '#d1d5db':'#4b5563'
                        }
                    }
                },
                // xAxis: {
                //     categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun']
                // },
                yAxis: [{
                    visible: false,
                    // max: 10
                }, {
                    visible: false,
                    // max: 10,
                    opposite: true
                }],
                legend: {
                    enabled: false 
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        backgroundColor:'#F3F4F6',
                        dataLabels: {
                            enabled: true,
                            style: {
                                // fontSize: '18px',
                                color: isDark ? '#fff' :'#fff',
                                // color: isDark ? '#fff' :'rgba(75, 85, 99, 0.5)',
                            },
                        },
                        borderWidth:0
                    },
                    series: {
                        borderRadius: {
                            radius: 10
                        },
                        dataLabels: {
                            enabled: true,
                            format: toggleShowPercentData ? '{y}%' : '{y}h'
                        },
                        stacking: 'normal'
                    }
                },
                series: [
                //     {
                //     name: '',
                //     color: isDark ? '#374151' :'#F3F4F6',
                //     data: backgroundSeries,
                //     dataLabels: {
                //         enabled: false
                //     },
                //     enableMouseTracking: false,
                //     yAxis: 1
                // },
                {
                    name: 'Unknown',
                    color: gray,
                    // data: unknownSeries,
                    data: unknownData,
                    dataLabels: {
                        style: {
                            // fontSize: '18px',
                            color: isDark ? '#fff' :'#fff',
                        },
                    },
                },{
                    name: 'Downtime',
                    color: red,
                    // data: downtimeSeries,
                    data: downtimeData,
                    dataLabels: {
                        style: {
                            // fontSize: '18px',
                            // color: isDark ? '#fff' :'#4b5563',
                            color: isDark ? '#fff' :'#fff',
                        },
                    },
                },{
                    name: 'Uptime',
                    // data: uptimeSeries,
                    data: uptimeData,
                    color: {
                        linearGradient: {
                          x1: 0,
                          x2: 0,
                          y1: 0,
                          y2: 1
                        },
                        stops: [
                          [0, skyLight],
                        //   [1, 'rgba(22, 163, 74,0.5)']
                        // [0, Highcharts.getOptions().colors[0]],
                        // [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                        [1, sky]
                        ]
                      }
                },{
                    name: 'SeriesToExclude',
                    type: 'spline',
                    dashStyle:'longdash',
                    data: uptimeData,
                    dataLabels: {
                        enabled: false // Disable data labels for this specific series
                    }
                }],
                tooltip: {
                    formatter: function () {
                        // The first returned item is the header, subsequent items are the
                        // points
                        let icon = 'h'
                        if(toggleShowPercentData){
                            icon = '%'
                        }
                        var tooltipText = '<b>' + this.x + '</b>';
                        this.points.forEach(point => {
                            if (point.series.name !== 'SeriesToExclude') {
                                tooltipText += '<br/>' + point.series.name + ': ' + point.y + icon;
                            }
                        });
                        return tooltipText;
                    },
                    shared: true
                },
            })
        }

    }, [isDark, totalUtilizations, toggleShowPercentData, toggleOnlyUptimeData])

    
  return (
    <div className='mb-[-5px]'>
        <HighchartsReact
            constructorType={"chart"}
            containerProps={{ style: { height: '300px', maxWidth:'100%'} }} 
            highcharts={HighchartsStock}
            options={hourCountBarChartOptions}               
        />
    </div>
  )
}

export default StackedBarChartLarge