import FilterButtons from "../components/FilterButtons"
import { ArrowsExpandIcon } from "@heroicons/react/outline"
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import { useState, useEffect } from "react";
import {machineData} from '../MachineTestData'
import { useRecoilValue } from "recoil";
import {darkModeState} from '../atoms/darkModeState'
import PartFilterBtn from "../components/filterButtons/PartFilterBtn"
import DepartmentFilterBtn from "../components/filterButtons/DepartmentFilterBtn"
import MachineTypeFilterBtn from "../components/filterButtons/MachineTypeFilterBtn"
import SortByFilterBtn from "../components/filterButtons/SortByFilterBtn"

highchartsMap(Highcharts);

const filters = [
    {
        name: 'Part',
        selected:''
    },{
        name: 'Department',
        selected:''
    },{
        name: 'Machine Type',
        selected:['CNC', 'Robot']
    },{
        name: 'Group By',
        selected:['Department']
    },{
        name: 'Sort By',
        selected:['Status']
    },

]

function getPointCategoryName(point, dimension) {  
    
    var series = point.series,
        isY = dimension === 'y',
        axis = series[isY ? 'yAxis' : 'xAxis'];  
    if(isY){
       return axis.categories[point['y']].slice(0,2)
    }else{
        return axis.categories[point['x']]
    }
  }

var dataName = function(data) {
    // return '<span>IMages<img src=' + imgSrc + ' ' + 'style="width: 40px; height: 40px;"/><br></span>';
    var machineArray = []
    data.map((machine,index) => (
        machineArray.push([machine.name, machine.machineId,machine.imageURL,])
    ))
    return machineArray
};

function AssetsPerformancePage() {
    const isDark = useRecoilValue(darkModeState);
    const [heatmapOptions, setHeatmapOptions] = useState({
        chart: {
            type: 'heatmap',    
            backgroundColor:'transparent'        
        },

        title: {
            text: null
        },
    
        credits: {
          text: 'iotflows.com',
          href: 'http://www.iotflows.com'
        },
        xAxis: {
            categories: ['Mon Aug 1', 'Tue Aug 2', 'Wed Aug 3', 'Thur Aug 4', 'Fri Aug 5', 'Sat Aug 6', 'Sun Aug 7', 'Mon Aug 8', 'Tue Aug 9', 'Wed Aug 10','Thur Aug 11'],
            // categories: self.props.data.hc_x_categories, //['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            labels: {
                style: {
                    color: isDark? '#9CA3AF':'#6B7280'
                }
            }
        },
    
        yAxis: {
            categories: dataName(machineData),
            labels: {
                formatter: function() {
                    return (
                        `<div style="display:flex; align-items:center; width:236px; overflow: hidden; background: ${isDark ? '#1E293B':'#E2E8F0'};border-radius: 30.5px 0px 0px 30.5px;padding:4px">
                            <img src=${this.value[2]} alt="" style="background-color:#fff; vertical-align: middle; width: 52px; height: 52px;border-radius:50px"/>`
                            + `<div style="display:flex; flex-direction: column; padding-left:8px">
                                <div style="font-weight: 600;font-size: 18px;line-height: 22px;color: ${isDark ? '#BFDBFE':'#1E40AF'};text-overflow: ellipsis">` + this.value[0] + '</div>' +
                                `<div style="font-weight: 600;font-size: 14px;line-height: 17px;color: ${isDark ? '#CBD5E1':'#64748B'};text-overflow: ellipsis">` + this.value[1] + '</div>' +
                                '</div>' +
                        '</div>'
                    )
                },
                useHTML: true
            },
            // categories: self.props.data.hc_y_categories,// ["CONTROLLER1-SPINDLE10","CONTROLLER1-SPINDLE11","CONTROLLER1-SPINDLE12","CONTROLLER1-SPINDLE13","CONTROLLER1-SPINDLE6"],
            title: null,
            reversed: true,
        },
    
        accessibility: {
            point: {
                descriptionFormatter: function (point) {
                    var ix = point.index + 1,
                        xName = getPointCategoryName(point, 'x'),
                        yName = getPointCategoryName(point, 'y'),
                        val = point.value;
                    return ix + '. ' + xName + ' OEE ' + yName + ', ' + val + '.';
                }
            }
        },
    
        colorAxis: {
            min: 0,
            minColor: '#FFFFFF',
            maxColor: Highcharts.getOptions().colors[0]
        },
    
        tooltip: {
            formatter: function () {
                return `<div ><b>${getPointCategoryName(this.point, 'x')}</b><br>OEE: <b>${this.point.value}</b> <br>on <b>${getPointCategoryName(this.point, 'y')}</b></div>`
            }
        },
    
        series: [{
            name: 'OEE per Day',   
            borderWidth: 10,  
            borderColor: isDark ? '#0f172a': '#fff',       
            // data: self.props.data.hc_series_data, //[[0,0,0],[0,1,19],[0,2,93],[0,3,0],[0,4,0],[1,0,0],[1,1,93],[1,2,32],[1,3,0],[1,4,0],[2,0,84],[2,1,35],[2,2,0],[2,3,83],[2,4,0],[3,0,0],[3,1,0],[3,2,0],[3,3,0],[3,4,0],[4,0,0],[4,1,100],[4,2,83],[4,3,75],[4,4,80],[5,0,0],[5,1,0],[5,2,0],[5,3,0],[5,4,0],[6,0,0],[6,1,0],[6,2,0],[6,3,0],[6,4,0]],
            data: [[0,0,65],[0,1,19],[0,2,93],[0,3,87],[0,4,57],[1,0,76],[1,1,93],[1,2,32],[1,3,65],[1,4,87],[2,0,84],[2,1,35],[2,2,76],[2,3,83],[2,4,69],[3,0,93],[3,1,98],[3,2,89],[3,3,0],[3,4,0],[4,0,0],[4,1,100],[4,2,83],[4,3,75],[4,4,80],[5,0,0],[5,1,0],[5,2,0],[5,3,0],[5,4,0],[6,0,0],[6,1,0],[6,2,0],[6,3,0],[6,4,0]],
            dataLabels: {
                enabled: true,
                color: '#000000'
            }
        }],

        legend: {
          align: 'right',
          layout: 'vertical',
          margin: 0,
          verticalAlign: 'top',
          y: 25,
          symbolHeight: 280
      },
    })

    useEffect(() => {
        setHeatmapOptions({
            chart: {
                type: 'heatmap',    
                backgroundColor:'transparent'        
            },
    
            title: {
                text: null
            },
        
            credits: {
              text: 'iotflows.com',
              href: 'http://www.iotflows.com'
            },
            xAxis: {
                categories: ['Mon Aug 1', 'Tue Aug 2', 'Wed Aug 3', 'Thur Aug 4', 'Fri Aug 5', 'Sat Aug 6', 'Sun Aug 7', 'Mon Aug 8', 'Tue Aug 9', 'Wed Aug 10','Thur Aug 11'],
                labels: {
                    style: {
                        color: isDark? '#9CA3AF':'#6B7280'
                    }
                }
            },
        
            yAxis: { 
                categories: dataName(machineData),
                labels: {
                    formatter: function() {
                        return (
                            `<div style="display:flex; align-items:center; width:236px; overflow: hidden; background: ${isDark ? '#1E293B':'#E2E8F0'};border-radius: 30.5px 0px 0px 30.5px;padding:4px">
                                <img src=${this.value[2]} alt="" style="background-color:#fff; vertical-align: middle; width: 52px; height: 52px;border-radius:50px"/>`
                                + `<div style="display:flex; flex-direction: column; padding-left:8px">
                                    <div style="font-weight: 600;font-size: 18px;line-height: 22px;color: ${isDark ? '#BFDBFE':'#1E40AF'};text-overflow: ellipsis">` + this.value[0] + '</div>' +
                                    `<div style="font-weight: 600;font-size: 14px;line-height: 17px;color: ${isDark ? '#CBD5E1':'#64748B'};text-overflow: ellipsis">` + this.value[1] + '</div>' +
                                    '</div>' +
                            '</div>'
                        )
                    },
                    useHTML: true
                },
                title: null,
                reversed: true,
            },
        
            accessibility: {
                point: {
                    descriptionFormatter: function (point) {
                        var ix = point.index + 1,
                            xName = getPointCategoryName(point, 'x'),
                            yName = getPointCategoryName(point, 'y'),
                            val = point.value;
                        return ix + '. ' + xName + ' OEE ' + yName + ', ' + val + '.';
                    }
                }
            },
        
            colorAxis: {
                min: 0,
                minColor: '#FFFFFF',
                maxColor: Highcharts.getOptions().colors[0]
            },
        
            tooltip: {
                formatter: function () {
                    return `<div ><b>${getPointCategoryName(this.point, 'x')}</b><br>OEE: <b>${this.point.value}</b> <br>on <b>${getPointCategoryName(this.point, 'y')}</b></div>`
                }
            },
        
            series: [{
                name: 'OEE per Day',   
                borderWidth: 10,  
                borderColor: isDark ? '#0f172a': '#fff',       
                // data: self.props.data.hc_series_data, //[[0,0,0],[0,1,19],[0,2,93],[0,3,0],[0,4,0],[1,0,0],[1,1,93],[1,2,32],[1,3,0],[1,4,0],[2,0,84],[2,1,35],[2,2,0],[2,3,83],[2,4,0],[3,0,0],[3,1,0],[3,2,0],[3,3,0],[3,4,0],[4,0,0],[4,1,100],[4,2,83],[4,3,75],[4,4,80],[5,0,0],[5,1,0],[5,2,0],[5,3,0],[5,4,0],[6,0,0],[6,1,0],[6,2,0],[6,3,0],[6,4,0]],
                data: [[0,0,65],[0,1,19],[0,2,93],[0,3,87],[0,4,57],[1,0,76],[1,1,93],[1,2,32],[1,3,65],[1,4,87],[2,0,84],[2,1,35],[2,2,76],[2,3,83],[2,4,69],[3,0,93],[3,1,98],[3,2,89],[3,3,0],[3,4,65],[4,0,42],[4,1,100],[4,2,83],[4,3,75],[4,4,80],[5,0,12],[5,1,43],[5,2,10],[5,3,21],[5,4,10],[6,0,35],[6,1,65],[6,2,96],[6,3,98],[6,4,43],[7,0,38],[7,1,34],[7,2,74],[7,3,54],[7,4,62],[8,0,54],[8,1,54],[8,2,46],[8,3,55],[8,4,42],[9,0,65],[9,1,65],[9,2,98],[9,3,41],[9,4,12],[10,0,65],[10,1,65],[10,2,18],[10,3,41],[10,4,12]],
                dataLabels: {
                    enabled: true,
                    color: '#000000'
                }
            }],
    
            legend: {
              align: 'right',
              layout: 'vertical',
              margin: 0,
              verticalAlign: 'top',
              y: 25,
              symbolHeight: 280
          },
           
        })
    }, [isDark])


    return (
        <div className='mt-2 p-1 '>
            {/* Filter section */}
            <div className=' flex items-center mb-4'>
                <div className='hidden space-x-2  lg:inline-flex'>
                    <PartFilterBtn select={'performance'} />
                    <DepartmentFilterBtn select={'performance'} />
                    <MachineTypeFilterBtn select={'performance'} />
                    <SortByFilterBtn select={'performance'} />
                </div>

                <div className='items-center ml-auto'>
                    <ArrowsExpandIcon className='executeIcon hidden sm:inline-flex' />
                </div>
            </div>

            {/* Heatmap */}
            <HighchartsReact
                // constructorType={"mapChart"}  
                containerProps={{ style: { height: "100%" } }}
                highcharts={Highcharts}
                options={heatmapOptions}              
                />
            
        </div>
    )
}

export default AssetsPerformancePage
