export function updateOrInsertMessageByDate(messages, newMessage) {
    
    // Update message if already exists
    if(messages[newMessage.message_uuid])
    {
      console.log('Update old message')
      messages[newMessage.message_uuid] = {...newMessage}
    }
    // Otherwise, insert
    else
    {
        
      
      // Sort  
      // Get the keys of the object
      const allKeys = Object.keys(messages);
      // const lastKey = allKeys[allKeys.length - 1];
      

      for (let i = allKeys.length - 1; i >= 0; i--) {
        if (new Date(newMessage.message_created_at) >= new Date(messages[allKeys[i]].message_created_at)) {
            if(i === allKeys.length - 1){
              // console.log('TRUEEE',i, allKeys.length - 1)
              messages[newMessage.message_uuid]  = newMessage
              break;
            } 
        }else{
          // This will be worst case scenario where it needs to sort messages
          
          messages[newMessage.message_uuid]  = newMessage

          let newObject = Object.fromEntries(
            Object.entries(messages).sort(([, a], [, b]) => a.message_created_at - b.message_created_at)
            );

            // console.log('newObject', newObject)
          messages = newObject


          // Sort the keys based on the timestamp values
          // allKeys.sort((a, b) => new Date(messages[a].message_created_at) - new Date(messages[b].message_created_at));

          // // Create a new object with the keys in the sorted order
          // const sortedJsonObject = {};
          // allKeys.forEach(key => {
          //   sortedJsonObject[key] = messages[key];
          // });

          // messages = {...sortedJsonObject}

          break;
        } 
      }

      // There are no messages in the chat 
      if(allKeys.length === 0){
        messages[newMessage.message_uuid]  = newMessage
      }
      
    }
    
  
    return messages; // Return the updated message list
  }


export function removeMessage(messages, newMessage) {
  

  const newData = { ...messages }; // Create a copy of the data object
  delete newData[newMessage.message_uuid]; // Delete the property using the ID as the key
  
  // console.log('removeMessage',newMessage.message_uuid)


  return newData; // Return the updated message list
}
// export function insertMessageByDate(messages, newMessage) {
//   let inserted = false;
//   for (let i = Object.keys(messages).length - 1; i >= 0; i--) {
//       if (new Date(newMessage.message_created_at) >= new Date(messages[i].message_created_at)) {
//           messages.splice(i + 1, 0, newMessage); // Insert at position i + 1
//           inserted = true;
//           break;
//     }
//   }

//   // If not inserted yet, add to the beginning (message is very old)
//   if (!inserted) {
//     messages.unshift(newMessage);
//   }

//   return messages; // Return the updated message list
// }