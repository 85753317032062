import { useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil';
import {darkModeState} from '../../atoms/darkModeState'
import { motion } from "framer-motion";
import DepartmentListForInput from './DepartmentListForInput'; 
import { getData, postData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { globalSelectedOrganization } from '../../atoms/orgStates';
import toast from 'react-hot-toast';


function DepartmentInput({selectedDepartment, removeDepartment, handleAddDepartmentFromList}) {
    const isDark = useRecoilValue(darkModeState);
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [showDepartmentList, setShowDepartmentList] = useState(false)
    const [databaseDepartmentList, setDatabaseDepartmentList] = useState([])
    const [loading, setLoading] = useState(false)
    const depRef = useRef(null)

    useEffect(() => {
        try {
          if( selectedOrganization){
            getDepartmentList()
  
          }
        } catch (error) {
          console.log(error.message)
        }
      },[selectedOrganization])


    const getDepartmentList = () => {
        if( selectedOrganization){
            // Get list of machines
            getData(apiLink + '/v1/organizations/' + selectedOrganization + '/departments')
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data.data){
                  setDatabaseDepartmentList(data.data)
                }
              }
            });
  
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
    }, [])
    
    const handleClickOutside = (e) => {
        if(depRef.current && !depRef.current.contains(e.target)){
            if(depRef.current.value){
                postNewDepartment(depRef.current.value)
                depRef.current.value = ''
                setShowDepartmentList(false)
            }
        }
        // else {
        //     console.log("Clicked inside INPUT ...")
        // }
    }

    const handleShowDepartmentList = () =>{
        setShowDepartmentList(!showDepartmentList)
    }

    const handleDepartmentKeydown = (e) => {
        if(e.key === 'Enter' || e.key === 'Tab') {
          const value = e.target.value
        //   console.log('adding new tag', value)
        //   if(!value.trim()) return
        //   setSelectedDepartment([value])
            postNewDepartment(value)
            e.target.value= '' 
        }
    }
    const postNewDepartment = (value) => {
        try {
            if(!loading){
                setLoading(true)
                postData(apiLink + '/v1/organizations/' + selectedOrganization + '/departments', 
                {"department_name": value})
                .then(async response => {
                    if(response.ok){
                        const data = await response.json();
                        let dep = {
                            department_name: value,
                            department_uuid:data.data.department_uuid
                        }
                        handleAddDepartmentFromList(dep)
                        toast.success('Department has been added')
                        setLoading(false)
                        
                        getDepartmentList()
                    }
                    else{
                    try{
                        const data = await response.json();
                        setLoading(false)
                        toast.error(data.message)
                    }catch (e){
                        toast.error('Something went wrong')
                        setLoading(false)
                    }
                    }
                })
            }
            
        } catch (error) {
            console.log(error.message)
        }

    }

  return (
    <div className={`mt-2`}>
        <label className={`block text-left text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`} htmlFor="inputDepartment">
            Select Department
        </label>
        {/* Department input container */}
        <div className={`relative w-full flex flex-wrap gap-0.5 my-auto rounded-md border-[1px] py-1 ${isDark?' border-gray-600 bg-slate-700':'border-gray-300'}`}>
            {/* Department inputs that will autopopulate  */}
            {selectedDepartment && Object.keys(selectedDepartment).length > 0 &&
                <div className={`h-7 w-fit inline-block flex text-white ${isDark?' bg-slate-900':'bg-slate-500'} text-xs rounded-md items-center justify-center ml-1 pl-3 pr-2`}>
                    <span >{selectedDepartment.department_name}</span>
                    <motion.button  
                        whileHover={{ scale: 1.05 }} 
                        whileTap={{ scale: 0.95 }}  
                        onClick={removeDepartment}
                        className='inline-flex rounded-full bg-white text-gray-500 ml-2 pb-0.5 w-4 h-4 items-center justify-center text-base cursor-pointer'
                    >
                        &times;
                    </motion.button>
                </div>
            }
            

            <input 
                className={`block border-0 grow min-w-[50px] ml-1 pl-1 sm:text-sm rounded-md text-ellipsis py-1 ${isDark?'placeholder:text-gray-500 text-gray-300 focus:border-transparent focus:ring-transparent bg-slate-700':' placeholder:text-gray-400 text-gray-600 focus:border-transparent focus:ring-transparent'}`}
                id='inputDepartment'
                ref={depRef}
                onKeyDown={handleDepartmentKeydown}
                onClick={handleShowDepartmentList} 
                type='text' 
                placeholder="Select or start typing department" 
                disabled={loading}
            />
            {loading && 
                <div >
                    <svg aria-hidden="true" className={`absolute top-1/4 left-0 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                    </svg>
                </div>
            }
        </div>
        
        {databaseDepartmentList && Object.keys(databaseDepartmentList).length > 0 && showDepartmentList &&
        <div className='mt-2'>
            <div className={`${isDark?'bg-slate-700 ':'bg-white '} w-full flex flex-col p-0.5 shadow-iotflows-lg rounded-lg max-h-[220px] overflow-auto scrollbar-hide`}   >
                {databaseDepartmentList.map((databaseDepartment, index) => (
                    <DepartmentListForInput key={databaseDepartment.department_uuid} databaseDepartment={databaseDepartment} handleAddDepartmentFromList={handleAddDepartmentFromList} handleShowDepartmentList={handleShowDepartmentList} getDepartmentList={getDepartmentList} />
                ))}
            </div>
            </div>
        }
    </div>
  )
}

export default DepartmentInput