
import { useEffect, useState, Fragment } from 'react';
import { getData } from '../ApiCalls/DataApis';
import { apiLink } from '../ApiCalls/ApisVariables';
import { globalSelectedOrganization } from '../atoms/orgStates';
import { useRecoilValue } from 'recoil';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/solid";
import SetWeeklyReportNotificationsModal from '../components/modals/reportModals/SetWeeklyReportNotificationsModal';




function AssetReportsPage() {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const [yearSelected, setYearSelected] = useState(null)
    const [reportList, setReportList] = useState([])
    const [loadingReportList, setLoadingReportList] = useState(false)
    const [yearList, setYearList] = useState([]);

    useEffect(() => {
      const currentYear = new Date().getFullYear();
      const startYear = 2021;
      const years = [];

      for (let year = startYear; year <= currentYear; year++) {
      years.push({name: year});
      }

      setYearList(years);
      let lastIndex = Object.keys(years).length -1
      setYearSelected(years[lastIndex])

  },[])



    

    useEffect(() => {
        try {
            if(selectedOrganization && yearSelected){
               getReportsList()
            } 
        } catch (error) {
            
        }

    }, [selectedOrganization, yearSelected])

   

    const getReportsList = () => {
        try {
            if(selectedOrganization && !loadingReportList){

              setLoadingReportList(true)
              let yearSelectedURL = `?filter_year=${yearSelected.name}`
              

              getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/reports' + yearSelectedURL)
              .then(async response => {
                  // JSON data parsed by response.json() call
                  if(response.ok ){
                  const data = await response.json();
                    if(data && data.data){
                      setReportList(data.data)
                      setLoadingReportList(false)
                    }
                  }else{
                    setLoadingReportList(false)
                  }
              })
            }
            
        } catch (error) {
          console.log(error.message)
          setLoadingReportList(false)
        }

    }

  // const formatDate = (date) => {
  //   if(date){
  //       var currentDate = new Date(date)
  //       var month = currentDate.toLocaleString('en-US', { month: 'short' })
  //       var day = currentDate.getDate()
  //       var year = currentDate.getFullYear().toString()

  //       var fullDate= month + ' ' + day + ', ' + year

  //       return fullDate
  //   }
  // }

  const handleOpenPDF = (report_uuid) => {
    try {
      if(report_uuid){
          var windowReference = window.open();
          getData(apiLink + '/v1/reports/'+ report_uuid )
          .then(async response => {
              // JSON data parsed by response.json() call
              if(response.ok ){
              const data = await response.json();
              // console.log('Report Url', data.data)
              if(data && data.data){
                // window.open(data.data.report_url, "_blank");
                windowReference.location = data.data.report_url
              }
              }
          })
      }
    } catch (error) {
      console.log(error.message)
    }

  }

  return (
    <div className='mt-2 p-1 overflow-scroll h-screen scrollbar-hide ' >    
      <div className='shadow-iotflows-lg dark:shadow-lg-invert rounded-lg p-4 mb-4'>
        <div>
          <div className='flex px-3 py-2 rounded-lg items-center'>
          {/* <div className='flex px-3 py-2 bg-slate-300 bg-opacity-50 dark:bg-slate-600 dark:bg-opacity-50 rounded-lg items-center'> */}
            <span className='font-bold tracking-wider px-2 py-0.5 text-2xl text-left text-gray-600 dark:text-gray-300'>
            {/* <span className='text-gray-600 dark:text-gray-300'> */}
              Weekly Reports
            </span>
            <div className="ml-4 w-28">
              {yearSelected &&
              <Listbox value={yearSelected} onChange={setYearSelected}>
                <div className="relative">
                  <Listbox.Button className="relative w-full cursor-default rounded-lg bg-slate-100 dark:bg-slate-500 pb-0.5 pt-0.5 pl-3 pr-12 text-left  focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 text-base sm:text-sm">
                    <span className="block truncate font-medium text-blue-600 dark:text-blue-300">{yearSelected.name}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5  text-blue-600 dark:text-blue-400" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                      </svg>
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-slate-600 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
                      {yearList.map((year, yearIdx) => (
                        <Listbox.Option
                          key={yearIdx}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-6 pr-2 text-center ${
                              active ? 'bg-slate-100 dark:bg-slate-700' : 'text-gray-600'
                            }`
                          }
                          value={year}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate text-gray-600 dark:text-gray-300 ${
                                  selected ? 'font-medium' : 'font-normal'
                                }`}
                              >
                                {year.name}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600 dark:text-gray-400">
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
              }
            </div>
            <div className='flex mb-1 sm:mb-0 ml-4'>
                <SetWeeklyReportNotificationsModal btnType='iconBtn'  />
            </div>
          </div>
        </div>
        <div className='w-full mt-4 px-2'>
          <table className="table-auto w-full">
              <thead >
                <tr className='border-b-[0.01em] dark:border-slate-700 text-left'>
                  <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3'>Date</th>
                  <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3'>Type</th>
                  {/* <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3'>Size</th> */}
                  <th className='text-sm font-light text-gray-500 dark:text-gray-400 h-6 px-2 py-3 text-right'>Save</th>
                </tr>
              </thead>
              <tbody >
              {!loadingReportList && reportList.map((report, index) => (
                <tr key={report.report_uuid} className='border-b-[0.01em]  dark:border-slate-400 '>
                  {/* <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3'>{formatDate(report.report_date)}</td> */}
                  <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3'>{report.report_name}</td>
                  <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3'>{'Weekly Report'}</td>
                  {/* <td className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-3'>{`4 pages`}</td> */}
                  <td className='flex justify-end my-2'>
                    {/* <div>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-gray-600 dark:text-gray-400">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    </div>
                    <div className='px-1 text-gray-600 dark:text-gray-400'>/</div> */}
                    <button className='rounded-full p-1.5 hover:bg-gray-100 hover:dark:bg-gray-600' onClick={() => handleOpenPDF(report.report_uuid)}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 text-gray-600 dark:text-gray-400">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                      </svg>
                    </button>

                  </td>
                </tr>
              ))}
              {loadingReportList &&
                <tr>
                  <td>
                    <div className="p-4 w-full">
                      <div className="animate-pulse flex space-x-4">
                        <div className="flex-1 space-y-6 py-1">
                          <div className="h-2 bg-slate-400 dark:bg-slate-700 rounded"></div>
                          <div className="space-y-3">
                            <div className="grid grid-cols-3 gap-4">
                              <div className="h-2 bg-slate-400 dark:bg-slate-700 rounded col-span-2"></div>
                              <div className="h-2 bg-slate-400 dark:bg-slate-700 rounded col-span-1"></div>
                            </div>
                            <div className="h-2 bg-slate-400 dark:bg-slate-700 rounded"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="p-4 w-full">
                      <div className="animate-pulse flex space-x-4">
                        <div className="flex-1 space-y-6 py-1">
                          <div className="h-2 bg-slate-400 dark:bg-slate-700 rounded"></div>
                          <div className="space-y-3">
                            <div className="grid grid-cols-3 gap-4">
                              <div className="h-2 bg-slate-400 dark:bg-slate-700 rounded col-span-2"></div>
                              <div className="h-2 bg-slate-400 dark:bg-slate-700 rounded col-span-1"></div>
                            </div>
                            <div className="h-2 bg-slate-400 dark:bg-slate-700 rounded"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="p-4 w-full">
                      <div className="animate-pulse flex space-x-4">
                        <div className="flex-1 space-y-6 py-1">
                          <div className="h-2 bg-slate-400 dark:bg-slate-700 rounded"></div>
                          <div className="space-y-3">
                            <div className="grid grid-cols-3 gap-4">
                              <div className="h-2 bg-slate-400 dark:bg-slate-700 rounded col-span-2"></div>
                              <div className="h-2 bg-slate-400 dark:bg-slate-700 rounded col-span-1"></div>
                            </div>
                            <div className="h-2 bg-slate-400 dark:bg-slate-700 rounded"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              }
              </tbody>
            </table>
        </div>
      </div> 
    </div>
  )
}

export default AssetReportsPage

