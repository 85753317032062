import React from 'react'
import { darkModeState } from '../../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';

function Waveform({ audioData, progress }) {
    const isDark = useRecoilValue(darkModeState);
  return (
    <div className='flex items-center justify-center h-6 w-full bg-transparent rounded overflow-hidden'>
        {audioData.map((amplitude, index) => {
            const barProgress = (progress - index / audioData.length) * audioData.length;
            const backgroundPosition = Math.min(Math.max(barProgress, 0), 1) * 100;
            return (
                <div className='w-[1px] bg-slate-500 mr-[1px] '
                key={index} 
                style={{
                    transition: 'background-position 0.1s ease',
                    backgroundSize: '200% 100%',
                    height: `${amplitude * 100}%`,
                    backgroundImage: isDark ?  'linear-gradient(to right, rgba(203, 213, 225,1) 50%, rgba(148, 163, 184,0.5) 50%)':'linear-gradient(to right, rgba(255,255,255,1) 50%, rgba(148, 163, 184,1) 50%)',
                    // backgroundColor: index / audioData.length < progress ? 'rgba(148, 163, 184,1)' : 'rgba(148, 163, 184,0.6)',
                    backgroundPosition: `${100 - backgroundPosition}% 0`,
                }}
                />
            )
        })}
    </div>
  )
}

export default Waveform