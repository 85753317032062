import React, { useEffect, useState, Fragment, useRef } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { globalSelectedOrganization } from '../atoms/orgStates';
import { apiLink } from '../ApiCalls/ApisVariables';
import { deleteData, getData, putData } from '../ApiCalls/DataApis';
import { Avatar } from '@mui/material';
import toast from 'react-hot-toast';
import { darkModeState } from '../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { Menu, Transition, MenuButton, MenuItems, MenuItem } from '@headlessui/react'
import { LightTooltip } from '../styles/Tooltip';
import { motion } from 'framer-motion';
import AddUserToTeamModal from '../components/modals/teamsModals/AddUserToTeamModal';
import DeleteTeamConfirmationModal from '../components/modals/teamsModals/DeleteTeamConfirmationModal';

function SelectedTeamPage({userInfo}) {
    const navigate = useNavigate();
    const isDark = useRecoilValue(darkModeState)
    const { teamId } = useParams()
    const [teamInfo, setTeamInfo] = useState([])
    const [teamMembers, setTeamMembers] = useState([])
    const [currentTeamMemberInfo, setCurrentTeamMemberInfo] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const [isMobile, setIsMobile] = useState(false)
    let location = useLocation();
    const [openAddUserToTeamModal, setOpenAddUserToTeamModal] = useState(false)
    const [isEditingTeamName, setisEditingTeamName] = useState(false)
    const [loadingTeamName, setLoadingTeamName] = useState(false);
    const [teamName, setTeamName] = useState('')
    const [isEditingTeamHandle, setisEditingTeamHandle] = useState(false)
    const [loadingTeamHandle, setLoadingTeamHandle] = useState(false);
    const [teamHandle, setTeamHandle] = useState('')

    const [isDeleteTeamModalOpen, setIsDeleteTeamModalOpen] = useState(false)
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [isTyping, setIsTyping] = useState(true);
    const typingTimer = useRef(null);
    const typingInterval = 500; 
    const [isUserHandleApproved, setIsUserHandleApproved] = useState(false)

    useEffect(() => {
        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
          setIsMobile(true)
        }else{
          setIsMobile(false)
        }
      },[location])


    useEffect(() => {
        if(teamId && userInfo){
            updatedTeamInfo()
        }
      },[teamId, userInfo])

    const updatedTeamInfo = () => {
        try {
            if(teamId){
              getData(apiLink + '/v1/teams/' + teamId )
              .then(async response => {
                if(response.ok ){
                  const data = await response.json();
                  if(data && data.data){
                    console.log('team info', data.data)
                    setTeamInfo(data.data[0])
                    setTeamName(data.data[0].team_name)
                    setTeamHandle(data.data[0].team_handle)
                    setTeamMembers(data.data[0].members)
                    data.data[0].members.map(member => {
                        if(member.user_username == userInfo.user_username ){
                            setCurrentTeamMemberInfo(member)
                        }
                    })
                    
                  }
                }
              }) 
            }
          } catch (error) {
            console.log(error.message)
          }
    }

    const copyToClipboard = (e, text) => {
        e.stopPropagation();
        if (!navigator.clipboard) {
          console.error("Clipboard API not supported");
          toast.error("Clipboard not supported");
          return;
        }
      
        navigator.clipboard.writeText(text)
          .then(() => {
            toast.success("Team handle copied!");
            // console.log("Text copied to clipboard successfully!");
          })
          .catch((err) => {
            toast.error("Failed to copy handle:", err);
            console.error("Failed to copy text:", err);
          });
      }

      const handleDeleteUser = (user) => {
        try {
            deleteData(apiLink + '/v1/teams/' + teamInfo.team_uuid + `/members/${user.user_username}`,{})
            .then(async response => {
                  const data = await response.json();
                  if(response.ok){ 
                    toast.success('User has been removed')
                    if(data && data.data ){
                        setTeamInfo(data.data[0])
                        setTeamMembers(data.data[0].members)
                        data.data[0].members.map(member => {
                            if(member.user_username == userInfo.user_username ){
                                setCurrentTeamMemberInfo(member)
                            }
                        })
                    }
                  }
                  else{
                    try{
                      toast.error(data.message)
                      // setLoading(false)
                    }catch (e){
                      toast.error(e.message);
                      // setLoading(false)
                    }
                  }
                })
            
          } catch (error) {
            console.log(error.message)
            toast.error(error.message)
          }
    }


      const handleMakeOwner = (user, ownerState) => {
        try {
            
            putData(apiLink + '/v1/teams/' + teamInfo.team_uuid + '/members/' + user.user_username,
            {'is_owner': ownerState})
            .then(async response => {
                  const data = await response.json();
                  if(response.ok){ 
                    if(data && data.data ){
                        setTeamInfo(data.data[0])
                        setTeamMembers(data.data[0].members)
                        data.data[0].members.map(member => {
                            if(member.user_username == userInfo.user_username ){
                                setCurrentTeamMemberInfo(member)
                            }
                        })
                      if(ownerState){
                        toast.success('Owner Added')
                      }else{
                        toast.success('Owner Removed')
                      }
                    }
                  }
                  else{
                    try{
                      toast.error(data.message)
                      // setLoading(false)
                    }catch (e){
                      toast.error(e.message);
                      // setLoading(false)
                    }
                  }
                })
          } catch (error) {
            console.log(error.message)
            toast.error(error.message)
          }
    }


    const handleOpenAddUserToTeamModal = () => {
        setOpenAddUserToTeamModal(false)
    }

    const handleNameBlur = (event) => {
        if(!event.target.value) {
            setTeamName(teamInfo.team_name)
            setisEditingTeamName(false);
            return;
        }

        if(!loadingTeamName){
            setLoadingTeamName(true)
            setisEditingTeamName(false);
            console.log('event.target.value', event.target.value)
            putData(apiLink + '/v1/teams/' + teamInfo.team_uuid , 
            {
                'team_name': event.target.value,
            })
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoadingTeamName(false)
                    setTeamName(event.target.value)
                }
                else{
                    try{
                        setTeamName(teamInfo.team_name)
                        setLoadingTeamName(false)
                        toast.error(data.message)
                    }catch (e){
                        setTeamName(teamInfo.team_name)
                        toast.error(e.message)
                        setLoadingTeamName(false)
                    }
                }
            })
        }

    }

    const handleKeyPressName = (event) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            if(!event.target.value) {
                setTeamName(teamInfo.team_name)
                setisEditingTeamName(false);
                return;
            }
    
            if(!loadingTeamName){
                setLoadingTeamName(true)
                setisEditingTeamName(false);
                putData(apiLink + '/v1/teams/' + teamInfo.team_uuid , 
                {
                    'team_name': event.target.value,
                })
                .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                        console.log('data', data)
                        setLoadingTeamName(false)
                        setTeamName(event.target.value)
                    }
                    else{
                        try{
                            setTeamName(teamInfo.team_name)
                            setLoadingTeamName(false)
                            toast.error(data.message)
                        }catch (e){
                            setTeamName(teamInfo.team_name)
                            toast.error(e.message)
                            setLoadingTeamName(false)
                        }
                    }
                })
            }
        }
      };


    const handleTeamHandleBlur = (event) => {
        if(!isUserHandleApproved || !event.target.value) {
            setTeamHandle(teamInfo.team_handle)
            setisEditingTeamHandle(false);
            return;
        }

        if(isUserHandleApproved && !loadingTeamHandle){
            setLoadingTeamHandle(true)
            setisEditingTeamHandle(false);
            
            putData(apiLink + '/v1/teams/' + teamInfo.team_uuid , 
            {
                'team_handle': event.target.value,
            })
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoadingTeamHandle(false)
                    setTeamHandle(event.target.value)
                }
                else{
                    try{
                        setTeamHandle(teamInfo.team_handle)
                        setLoadingTeamHandle(false)
                        toast.error(data.message)
                    }catch (e){
                        setTeamHandle(teamInfo.team_handle)
                        toast.error(e.message)
                        setLoadingTeamHandle(false)
                    }
                }
            })
        }

    }

    const handleKeyPressHandle = (event) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            if(!isUserHandleApproved || !event.target.value) {
                setTeamHandle(teamInfo.team_handle)
                setisEditingTeamHandle(false);
                return;
            }
    
            if(isUserHandleApproved && !loadingTeamHandle){
                setLoadingTeamHandle(true)
                setisEditingTeamHandle(false);

                putData(apiLink + '/v1/teams/' + teamInfo.team_uuid , 
                {
                    'team_handle': event.target.value,
                })
                .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                        console.log('data', data)
                        setLoadingTeamHandle(false)
                        setTeamHandle(event.target.value)
                    }
                    else{
                        try{
                            setTeamHandle(teamInfo.team_handle)
                            setLoadingTeamHandle(false)
                            toast.error(data.message)
                        }catch (e){
                            setTeamHandle(teamInfo.team_handle)
                            toast.error(e.message)
                            setLoadingTeamHandle(false)
                        }
                    }
                })
            }
        }
      };

    const handleInput = (e) => {
        let handle = e.target.value.toLowerCase().replace(/[^\w\s]/gi, '')
        setTeamHandle(handle)
        if (!isTyping) {
            setIsTyping(true);
        }
        clearTimeout(typingTimer.current);
        // typingTimer.current = setTimeout(handleUserStoppedTyping, typingInterval);
        typingTimer.current = setTimeout(() => {
            // console.log('User has stopped typing for more than 0.5 second.');
            setIsTyping(false);
            getData(apiLink + `/v1/verify_user_organization_handle?user_has_organization_user_handle=${handle}&organization_uuid=${selectedOrganization}`)
            .then(async response => {
                if(response.ok ){
                const data = await response.json();
                if(data && data.data){
                    if(data.data[0].is_available){
                        setIsUserHandleApproved(true)
                        toast.success('Team handle available')
                    }else{
                        setIsUserHandleApproved(false)
                        toast.error('Team handle not available')
                    }
                }
                }
                
            });

        }, typingInterval);
    };

    const handleCloseDeleteTeamModal = () => {
        setIsDeleteTeamModalOpen(false)
    }

    const handleGoBack = () => {
        if(isMobile){
            navigate(`/${selectedOrganization}/members?select=teams&platform=mobile`)
        }else{
            navigate(`/${selectedOrganization}/members?select=teams`)
        }
      };

    

  return (
    <div className=''>
        <div className='border-b-[0.01em] border-slate-200 dark:border-slate-600 px-2 py-1.5 bg-white dark:bg-slate-900'>
            <div className='grid grid-cols-3 '>
                <ol className='flex '>
                    <li className='breadcrumbsNav flex items-center' onClick={handleGoBack} >
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                            </svg>
                        </div>
                        <span className='text-sm'>Back</span>
                    </li>
                </ol>
                <div className='text-gray-600 dark:text-gray-300 text-center'>Team</div>
                <div/>
            </div>
        </div>
        {teamInfo && Object.keys(teamInfo).length > 0 &&
        <div className='mx-2'>
         <div className='flex flex-col justify-center text-center mt-20'>
            <div className='mx-auto'>
                <Avatar
                    sx={{ width: 120, height: 120,bgcolor: teamInfo.team_color? teamInfo.team_color:'', fontSize: 42}}
                    style={{
                        background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${teamInfo.team_color? teamInfo.team_color:''} 100%)`,
                        }}
                    alt={teamInfo.team_name}
                    src={teamInfo.team_image_url || '/brokenLink.png'}
                    >
                </Avatar>
            </div>
            <div className='flex justify-center text-center mt-4'>
                {isEditingTeamName ? (
                    <div className='flex' >
                        <input
                            type="text"
                            value={teamName}
                            onChange={(e) => setTeamName(e.target.value)}
                            onBlur={handleNameBlur} // Handle blur
                            onKeyDown={handleKeyPressName}
                            className={`block bg-transparent border rounded-md py-1 px-2 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-xl placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800`}
                            size={teamName.length || 1}
                            style={{
                                width: 'auto',
                                minWidth: '50px',
                            }}
                            autoFocus // Focus on input upon render
                            />
                    </div>
                ):(
                    <div 
                        className='relative  cursor-pointer'  
                        onClick={() => setisEditingTeamName(true)}
                    >
                        <div className={`text-blue-600 dark:text-blue-300 font-medium text-xl`} >
                        {/* <div className={`text-gray-600 dark:text-gray-300 font-medium text-xl`} > */}
                            {teamName}
                        </div>
                        {loadingTeamName && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-1/4 right-0 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                        }
                    </div>
                )}
            </div>
            <div className='flex justify-center text-center mt-2'>
                {isEditingTeamHandle ? (
                    <div className='flex items-center' >
                        <input
                            type="text"
                            value={teamHandle}
                            // onChange={(e) => setTeamHandle(e.target.value)}
                            onInput={handleInput}
                            onBlur={handleTeamHandleBlur} 
                            onKeyDown={handleKeyPressHandle}
                            className={`block bg-transparent border rounded-md py-0 pl-2 pr-0 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-lg placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800`}
                            size={teamHandle.length || 1}
                            style={{
                                width: 'auto',
                                minWidth: '50px',
                            }}
                            autoFocus // Focus on input upon render
                            />
                        <span className={`text-gray-600 dark:text-gray-300 text-lg`}>
                            {`:${teamInfo.organization_handle}`}
                        </span>
                        <div className=' pl-2 w-6'>
                            {teamHandle && teamHandle.length > 0 && !isTyping && isUserHandleApproved &&
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-green-500">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                            }
                            {!isTyping && !isUserHandleApproved &&
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-red-500">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                                </svg>
                            }
                        </div>
                    </div>
                ):(
                    <div 
                        className='relative cursor-pointer'  
                        onClick={() => setisEditingTeamHandle(true)}
                    >
                        <div className={`text-gray-600 dark:text-gray-300 italic flex text-lg`} >
                            <div className='flex tracking-wide'>
                                <span className='text-blue-600 dark:text-blue-300'>
                                    {teamHandle}
                                </span>
                                <span>
                                    {`:${teamInfo.organization_handle}`}
                                </span>
                            </div>
                            <button 
                                className='ml-2 text-gray-600 dark:text-gray-200 hover:text-gray-400 dark:hover:text-gray-600 '
                                onClick={(e)=> copyToClipboard(e, `${teamInfo.team_organization_handle}`)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                                </svg>
                            </button>
                        </div>
                        {loadingTeamHandle && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-1/4 right-0 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                        }
                    </div>
                )}
            </div>
        </div>
        <div className='max-w-4xl mx-auto mt-3'>
            <div className='flex space-x-2 mx-4 lg:mx-10 mb-4 '>
                    <button 
                        onClick={() => setOpenAddUserToTeamModal(true)}
                        className='rounded-xl bg-slate-100 dark:bg-slate-800 flex flex-col text-blue-600 dark:text-blue-500 py-4 px-4 grow text-center'
                    >
                        <div className='mx-auto'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                            </svg>
                        </div>
                        <div className='text-sm mx-auto'>
                            Add
                        </div>
                    </button>
                <div className='grow relative flex'>
                    <Menu >
                        <MenuButton 
                            as="button" 
                            className=' rounded-xl bg-slate-100 dark:bg-slate-800 flex flex-col text-blue-600 dark:text-blue-500 py-4 px-4 w-full text-center'
                        >
                            <div className='mx-auto'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                                </svg>
                            </div>
                            <div className='text-sm mx-auto'>
                                More
                            </div>
                        </MenuButton>

                        <Transition
                            as={Fragment}
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <MenuItems className="absolute right-8 top-10 flex flex-col mt-1 w-[200px] bg-white dark:bg-slate-700 shadow-iotflows-lg rounded-lg outline-none z-50">
                                <div className="px-1 py-1">
                                {/* {!chatInfo.chat_is_direct && (
                                    <MenuItem>
                                            <button
                                            onClick={() => setIsEditingGroup(true)}
                                            className={` text-blue-600 dark:text-blue-400 flex w-full items-center rounded-md px-2 py-2 text-sm
                                            data-[active]:bg-slate-50 data-[active]:dark:bg-slate-500 data-[active]:text-gray-600 data-[active]:dark:text-gray-200`}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                            </svg>
                                            <span className='pl-1'>Edit</span>
                                        </button>
                                    </MenuItem>
                                )} */}
                                    <MenuItem>
                                        <button
                                            className={`text-red-600 dark:text-red-400 flex w-full items-center rounded-md px-2 py-2 text-sm
                                            data-[active]:bg-slate-50 data-[active]:dark:bg-slate-500 data-[active]:text-gray-600 data-[active]:dark:text-gray-200`}
                                            onClick={() => handleDeleteUser(currentTeamMemberInfo)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
                                            </svg>
                                            <span className='pl-1'>Leave Team</span>
                                        </button>
                                    </MenuItem>
                                    {(currentTeamMemberInfo && currentTeamMemberInfo.team_has_user_is_owner) ? (
                                        <MenuItem>
                                            <button
                                                className={`relative text-red-600 dark:text-red-400 flex w-full items-center rounded-md px-2 py-2 text-sm
                                                data-[active]:bg-slate-50 data-[active]:dark:bg-slate-500 data-[active]:text-gray-600 data-[active]:dark:text-gray-200`}
                                                onClick={() => setIsDeleteTeamModalOpen(true)}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                </svg>
                                                <span className='pl-1'>Delete Team</span>
                                            </button>
                                        </MenuItem>
                                    ):<div/>}
                                </div>
                            </MenuItems>
                        </Transition>
                    </Menu>
                </div>
                
            </div>
            <div className='bg-slate-100 dark:bg-slate-800 rounded-xl px-4 py-2'>
                {teamMembers && Object.keys(teamMembers).length > 0 && teamMembers.map((member) => (
                    <div key={member.user_username} className={` flex text-gray-600 dark:text-gray-300 items-center py-1 group`}>
                        <div className='relative'>
                            <Avatar 
                            sx={{  bgcolor: member.user_color? member.user_color : '' , width: 44, height: 44, }} 
                            style={{
                                background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${member.user_color? member.user_color:''} 100%)`,
                            }}
                            alt={member.user_first_name || ''}
                            src={member.user_image_url || ''} 
                            />
                            {member.team_has_user_is_owner &&
                            <LightTooltip title='Owner'>
                                <div className='absolute rounded-full bg-green-500 dark:bg-green-300 text-green-900 right-[-3px] top-[-3px] p-0.5'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor" className="w-3 h-3">
                                    <path d="M480-440q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0-80q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0 40Zm0-315-240 90v189q0 54 15 105t41 96q42-21 88-33t96-12q50 0 96 12t88 33q26-45 41-96t15-105v-189l-240-90Zm0 515q-36 0-70 8t-65 22q29 30 63 52t72 34q38-12 72-34t63-52q-31-14-65-22t-70-8Zm0 196q-7 0-13-1t-12-3q-135-45-215-166.5T160-516v-189q0-25 14.5-45t37.5-29l240-90q14-5 28-5t28 5l240 90q23 9 37.5 29t14.5 45v189q0 140-80 261.5T505-88q-6 2-12 3t-13 1Z"/>
                                    </svg>
                                </div>
                            </LightTooltip>
                            }
                        </div>
                        <div className='flex ml-2 grow overflow-hidden border-b-[0.01em] border-slate-200 dark:border-slate-600 py-1 items-center truncate'>
                            <div className='flex flex-col truncate'>
                                <div className='flex items-center truncate'>
                                        <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                </div>
                                <div className='flex items-baseline truncate'>
                                        <p className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'} truncate `}>{member.user_username}</p>

                                </div>
                            </div>
                            <div className='ml-auto items-center flex space-x-2'>
                                {member && !member.team_has_user_is_owner && 
                                    <motion.button 
                                    whileHover={{ scale:1.05 }} 
                                    whileTap={{ scale: 0.95  }}  
                                        className=' flex items-center text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-500 opacity-0  ease-in-out duration-300 group-hover:opacity-100 rounded px-2 py-0.5 min-w-[25px]'
                                        onClick={() => handleMakeOwner(member, true)}
                                    >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"  className={`${isMobile ? 'w-3 h-3':'w-4 h-4 '}`}>
                                        <path d="M480-440q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0-80q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0 40Zm0-315-240 90v189q0 54 15 105t41 96q42-21 88-33t96-12q50 0 96 12t88 33q26-45 41-96t15-105v-189l-240-90Zm0 515q-36 0-70 8t-65 22q29 30 63 52t72 34q38-12 72-34t63-52q-31-14-65-22t-70-8Zm0 196q-7 0-13-1t-12-3q-135-45-215-166.5T160-516v-189q0-25 14.5-45t37.5-29l240-90q14-5 28-5t28 5l240 90q23 9 37.5 29t14.5 45v189q0 140-80 261.5T505-88q-6 2-12 3t-13 1Z"/>
                                    </svg>
                                    {isMobile ? (
                                        <div className='flex flex-col text-center'>
                                            <span className='text-xs'>Make</span>
                                            <span className='text-xs'>Owner</span>
                                        </div>
                                    ):(
                                        <span className='text-sm ml-1'>Make Owner</span>

                                    )}

                                    </motion.button>
                                }
                                {member && member.team_has_user_is_owner && 
                                    <motion.button 
                                    whileHover={{ scale:1.05 }} 
                                    whileTap={{ scale: 0.95  }}  
                                        className=' flex items-center text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-500 opacity-0  ease-in-out duration-300 group-hover:opacity-100 rounded px-2 py-0.5 min-w-[25px]'
                                        onClick={() => handleMakeOwner(member, false)}
                                    >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"  className={`${isMobile ? 'w-3 h-3':'w-4 h-4 '}`}>
                                        <path d="M480-440q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0-80q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0 40Zm0-315-240 90v189q0 54 15 105t41 96q42-21 88-33t96-12q50 0 96 12t88 33q26-45 41-96t15-105v-189l-240-90Zm0 515q-36 0-70 8t-65 22q29 30 63 52t72 34q38-12 72-34t63-52q-31-14-65-22t-70-8Zm0 196q-7 0-13-1t-12-3q-135-45-215-166.5T160-516v-189q0-25 14.5-45t37.5-29l240-90q14-5 28-5t28 5l240 90q23 9 37.5 29t14.5 45v189q0 140-80 261.5T505-88q-6 2-12 3t-13 1Z"/>
                                    </svg>
                                        
                                        {isMobile ? (
                                            <div className='flex flex-col text-center'>
                                                <span className='text-xs'>Remove</span>
                                                <span className='text-xs'>Owner</span>
                                            </div>
                                        ):(
                                            <span className='text-sm ml-1'>Remove Owner</span>

                                        )}

                                    </motion.button>
                                }
                                <motion.button 
                                whileHover={{ scale:1.05 }} 
                                whileTap={{ scale: 0.95  }} 
                                    className='flex items-center text-red-500 dark:text-red-400 hover:text-red-400 dark:hover:text-red-600 opacity-0  ease-in-out duration-300 group-hover:opacity-100 min-w-[25px]'
                                    onClick={() => handleDeleteUser(member)}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${isMobile ? 'w-3 h-3':'w-4 h-4 '}`}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                    </svg>
                                    <span className={`${isMobile ? 'text-xs':'text-sm'} ml-1`}>Delete</span>

                                </motion.button>
                            </div>
                        </div>
                    </div>
                ))}

            </div>
        </div>
            {openAddUserToTeamModal && 
                <AddUserToTeamModal btnType='hideBtn' teamInfo={teamInfo} remoteOpenAssetModal={openAddUserToTeamModal} teamMembers={teamMembers} setTeamMembers={setTeamMembers} handleOpenAddUserToTeamModal={handleOpenAddUserToTeamModal} />
            }
            {isDeleteTeamModalOpen &&
                <DeleteTeamConfirmationModal remoteOpenModal={isDeleteTeamModalOpen} handleCloseDeleteTeamModal={handleCloseDeleteTeamModal} teamInfo={teamInfo} />
            }
        </div>
        }
    </div>
  )
}

export default SelectedTeamPage