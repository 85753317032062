
import gantt from "highcharts/highcharts-gantt";
import HighchartsReact from "highcharts-react-official";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import {darkModeState} from '../../atoms/darkModeState'
import { useEffect } from "react";
import Highcharts from "highcharts/highcharts.js";



var today = new Date(),
    day = 1000 * 60 * 60 * 24;

// Set to 00:00:00:000 today
today.setUTCHours(0);
today.setUTCMinutes(0);
today.setUTCSeconds(0);
today.setUTCMilliseconds(0);


const converDate = (longDate) => {
  let date = new Date(longDate)
  let time = date.getTime()
  return time
}

const converProgress = (progress) => {
  if(progress){
    let shortProgress = progress.toFixed(3)
    let percent = parseFloat(shortProgress)
    return percent
    
  }
}

var testDataName = function(data, isDark) {
  var projectArray = []
  let name = ''
  name = data.production_name
  let fillColor = '#dc2626';
  if(data.progress >= data.production_time_progress_percentage){
      fillColor = '#16a34a';
  }
  let totalCount = data.sum_part_count ? data.sum_part_count : 0
  projectArray.push({
    name: data.production_name, 
    id: data.production_uuid,
    type: 'project',
    ratio: `${totalCount}/${data.production_total_part_count_required}`,
    start: converDate(data.production_start_date),
    end: converDate(data.production_end_date),
    color: isDark? '#334155' : '#e2e8f0',
    collapsed: false,
    completed: {
      amount: converProgress(data.progress),
      fill: fillColor
      }
  })
    data.parts.map((part,index) =>{
        let fillColor = '#dc2626';
        if(part.progress >= part.production_has_part_time_progress_percentage){
            fillColor = '#16a34a';
        }
      let partCount = part.part_count ? part.part_count : 0
      if(index > 0){
        let prevIndex = index - 1
        projectArray.push({
          name: part.part_name, 
          id: part.part_uuid,
          type: 'part',
          ratio: `${partCount}/${part.production_has_part_part_quantity}`,
          // dependency: project.parts[prevIndex].production_uuid,
          // parent: project.production_uuid,
          start: converDate(part.production_has_part_part_start_date),
          end: converDate(part.production_has_part_part_end_date),
          color: isDark? '#334155' : '#e2e8f0',
          collapsed: false,
          completed: {
            amount: converProgress(part.progress),
            fill: fillColor
            }
        })
      }else {
        projectArray.push({
          name: part.part_name, 
          id: part.part_uuid,
          type: 'part',
          ratio: `${partCount}/${part.production_has_part_part_quantity}`,
          // parent: project.production_uuid,
          start: converDate(part.production_has_part_part_start_date),
          end: converDate(part.production_has_part_part_end_date),
          color: isDark? '#334155' : '#e2e8f0',
          collapsed: false,
          completed: {
            amount: converProgress(part.progress),
            fill: fillColor
        }
        })
      }
        part.operations.map((operation, index) =>{
            let fillColor = '#dc2626';
            if(operation.progress >= part.production_has_part_time_progress_percentage){
                fillColor = '#16a34a';
            }
            let opCount = operation.operation_count? operation.operation_count : 0
          if(index > 0){
            let prevIndex = index - 1
            projectArray.push({
              name: operation.operation_name, 
              id: operation.operation_uuid,
              type: 'operation',
              ratio: `${opCount}/${part.production_has_part_part_quantity}`,
              // dependency: part.operations[prevIndex].part_uuid,
              // parent: part.part_uuid,
              start: converDate(part.production_has_part_part_start_date),
              end: converDate(part.production_has_part_part_end_date),
              color: isDark? '#334155' : '#e2e8f0',
              collapsed: false,
              completed: {
                amount: converProgress(operation.progress),
                fill: fillColor
            }
            })
          }else {
            projectArray.push({
              name: operation.operation_name, 
              id: operation.operation_uuid,
              type: 'operation',
              ratio: `${opCount}/${part.production_has_part_part_quantity}`,
              // dependency: part.part_uuid,
              // parent: part.part_uuid,
              start: converDate(part.production_has_part_part_start_date),
              end: converDate(part.production_has_part_part_end_date),
              color: isDark? '#334155' : '#e2e8f0',
              collapsed: false,
              completed: {
                amount: converProgress(operation.progress),
                fill: fillColor
            }
            })
          }
          
          // operation.assets.map((asset, index) => {
          //   let fillColor = '#dc2626';
          //   if(asset.progress > 0.4){
          //       fillColor = '#16a34a';
          //   }
          //   if(operation.operation_uuid ){
          //     projectArray.push({
          //       name: asset.machine_name, 
          //       id: `${asset.asset_uuid} - ${operation.operation_uuid}`,
          //       // dependency: operation.operation_uuid,
          //       parent: operation.operation_uuid,
          //       start: 1667692800000,
          //       end: 1668556800000,
          //       color: isDark? '#334155' : '#e2e8f0',
          //       completed: {
          //           amount: converProgress(asset.progress),
          //           fill: fillColor
          //       }
          //     })
          //   }else {
          //     console.log("operation.operation_uuid FALSEEEEE", operation.operation_uuid)
          //   }

          // })
        })
    })
  let project = [{
    name: name,
    data: projectArray,
  }]
  return project
};


var defined = Highcharts.defined
var isObject = Highcharts.isObject

function GanttChart({projectData}) {
    const isDark = useRecoilValue(darkModeState);
  const [ganttChartOptions, setGanttChartOptions] = useState([])
  const [isDataReady, setIsDataReady] = useState(false)

  useEffect(() => {
    if(projectData && Object.keys(projectData).length > 0 && ganttChartOptions && Object.keys(ganttChartOptions).length > 0 ){
      setIsDataReady(true)
    }
  },[projectData, ganttChartOptions])

  const converTooltipDate = (date) =>{
    if(date){
      let convertedDate = new Date(date)
      const year = convertedDate.getFullYear();
// 👇️ getMonth returns integer from 0(January) to 11(December)
      const month = convertedDate.getMonth() + 1;
      const day = convertedDate.getDate();

      const withSlashes = [year, month, day].join('/');
      return withSlashes
    }
  }

  useEffect(() =>{
    if(projectData && Object.keys(projectData).length > 0){
      let data = testDataName(projectData, isDark)
      setGanttChartOptions({
        chart: { 
            backgroundColor:'transparent',
          },
          time: {
            useUTC: false
          },
          title: {
              text: null
          },
          credits: {
            text: 'iotflows.com',
            href: 'http://www.iotflows.com'
          },
          yAxis: {
            gridLineColor: isDark ?  '#334155' : '#D1D5DB',
            lineColor: isDark ?  '#334155' : '#D1D5DB',
            grid: {
                borderColor: isDark ?  '#334155' : '#D1D5DB',
            },
            labels: {
              formatter: function() {
                if(this.axis.series[0].data[this.pos] != undefined){
                  let data = this.axis.series[0].data[this.pos]
                  let currentValues = []
                  if(this.axis.series[0].data[this.pos].id){
                    data = this.axis.series[0].data[this.pos].id
                    if(projectData.production_uuid == this.axis.series[0].data[this.pos].id){
                      currentValues = [projectData.production_name, '', projectData.production_uuid, this.axis.series[0].data[this.pos].type, this.axis.series[0].data[this.pos].ratio]
                    } 
                      projectData.parts.map((part,index) =>{
                        if(part.part_uuid == this.axis.series[0].data[this.pos].id){
                          currentValues = [part.part_name, part.part_picture_url, part.part_uuid, this.axis.series[0].data[this.pos].type, this.axis.series[0].data[this.pos].ratio]
                        }
                          part.operations.map((operation, index) =>{
                            if(operation.operation_uuid == this.axis.series[0].data[this.pos].id){
                              currentValues = [operation.operation_name, operation.operation_description, operation.operation_uuid, this.axis.series[0].data[this.pos].type, this.axis.series[0].data[this.pos].ratio]
                            }
                            operation.assets.map((asset, index) => {
                              let assetID = `${asset.asset_uuid} - ${operation.operation_uuid}`
                              if(assetID == this.axis.series[0].data[this.pos].id){
                                currentValues = [asset.machine_name, asset.machine_picture_url, asset.asset_uuid, this.axis.series[0].data[this.pos].type, this.axis.series[0].data[this.pos].ratio, 'Department']
                              }
                            })
                          })
                      })
      
                  }

                  if(currentValues[3] === 'project'){
                      return (
                        `<div key='${currentValues[2]}' style="display:flex; align-items:center; width:400px; overflow: hidden; background: transparent; ">
                           <div style="display:flex; width:100%; align-items:center ">
                                <div style="font-weight: 700;font-size: 18px;line-height: 18px;color: ${isDark ? '#D1D5DB':'#4b5563'};text-overflow: ellipsis">${currentValues[0]}</div>
                                <div style="font-weight: 300;font-size: 14px;line-height: 14px;margin-left: auto; color: ${isDark ? '#60a5fa':'#2563eb'};text-overflow: ellipsis">${currentValues[4]}</div>
                            </div>
                        </div>`
                    )
                  } else if(currentValues[3] === 'part'){
                    return (
                      `<div key='${currentValues[2]}' style="display:flex; align-items:center; width:400px; overflow: hidden; background: transparent; padding-left:8px">
                          ${currentValues[1]!="" ?` <img src=${currentValues[1]} alt="" style="background-color:#fff; vertical-align: middle; width: 35px; height: 35px;border-radius:50px; margin-right:0px; "/>`: ''}`
                          + `<div style="display:flex; margin-left:8px; width:100%; align-items:center ">
                                <div style="font-weight: 600;font-size: 16px;line-height: 18px; color: ${isDark ? '#D1D5DB':'#4b5563'};text-overflow: ellipsis">${currentValues[0]}</div>
                                <div style="font-weight: 300;font-size: 13px;line-height: 14px;margin-left: auto; color: ${isDark ? '#60a5fa':'#2563eb'};text-overflow: ellipsis">${currentValues[4]}</div>
                              </div>
                      </div>`
                    )
                  }else if(currentValues[3] === 'operation'){
                    return (
                      `<div key='${currentValues[2]}' style="display:flex; align-items:center; width:400px; overflow: hidden; background: transparent; padding-left:'28px">
                        <div style="display:flex; width:100%; align-items:center ">
                              <div style="font-weight: 400;font-size: 15px;line-height: 16px;margin-left:52px; color: ${isDark ? '#D1D5DB':'#4b5563'};overflow: hidden; white-space: nowrap; text-overflow: ellipsis">${currentValues[0]} <span style="font-weight: 300;font-size: 13px;line-height: 16px;margin-left:4px; color: ${isDark ? '#D1D5DB':'#4b5563'};">${currentValues[1]}</span> </div>
                              <div style="font-weight: 300;font-size: 12px;line-height: 14px;margin-left: auto; color: ${isDark ? '#60a5fa':'#2563eb'};text-overflow: ellipsis">${currentValues[4]}</div>
                              </div>
                      </div>`
                    )
                  }else{
                      return (
                        `<div key='${currentValues[2]}' style="display:flex; align-items:center; width:300px; overflow: hidden; background: transparent; margin-left:'8px">
                            ${currentValues[1]!="" ?` <img src=${currentValues[1]} alt="" style="background-color:#fff; vertical-align: middle; width: 35px; height: 35px;border-radius:50px; margin-right:5px"/>`: ''}`
                            + `<div style="display:flex; flex-direction: column; justify-content: center; padding-left:8px">
                                <div style="font-weight: 300;font-size: 18px;line-height: 18px;margin-left: 18px;color: ${isDark ? '#D1D5DB':'#4b5563'};text-overflow: ellipsis">${currentValues[0]}</div>
                                ${currentValues[5] ? `<div style="font-weight: 400;font-size: 12px;line-height: 14px;color: ${isDark ? '#9ca3af':'#6b7280'};text-overflow: ellipsis">${currentValues[3]}</div>` : ''}
                                </div>
                        </div>`
                    )
                  }
                    
                }
                
              },
              // align: 'left',
              // symbol:{
              //   height:7,
              //   width: 15,
              //   padding: 10,
              //   y:-10,
              //   // x:-10,
              // },
                useHTML: true
            },
            title: null,
            reversed: true,
        },
        navigator: {
          enabled: true,
          liveRedraw: true,
          height:20,
          margin:10,
          series: {
              type: 'gantt',
              pointPlacement: 0.5,
              pointPadding: 0.25,
              accessibility: {
                  enabled: false
              }
          },
          yAxis: {
              min: 0,
              max: 3,
              reversed: true,
              categories: []
          },
        },
      
        scrollbar: {
            enabled: false
        },
      
        rangeSelector: {
            enabled: true,
            selected: 4,
            buttons: [{
                type: 'week',
                count: 1,
                text: '1w',
                title: 'View 1 week'
            },{
                type: 'week',
                count: 2,
                text: '2w',
                title: 'View 2 weeks'
            }, {
                type: 'month',
                count: 1,
                text: '1m',
                title: 'View 1 month'
            }, {
                type: 'month',
                count: 3,
                text: '3m',
                title: 'View 3 months'
            },{
                type: 'all',
                text: 'All',
                title: 'View all'
            }],
            buttonTheme: { // styles for the buttons
                fill: 'none',
                stroke: 'none',
                'stroke-width': 0,
                r: 8,
                style: {
                    color: '#2563eb',
                    fontWeight: 'bold'
                },
                states: {
                    hover: {
                    },
                    select: {
                        fill: '#2563eb',
                        style: {
                            color: 'white'
                        }
                    },
                    disabled: { 
                        // fill: '#2563eb',
                        style: {
                            color: isDark ? '#374151':'#d1d5db',
                        }
                    }
                }
            },
            inputStyle: {
                color: '#9ca3af',
                fontWeight: 'bold'
            },
            labelStyle: {
                color: '#6b7280',
                fontWeight: 'bold'
            },
        },
        plotOptions: {
            series: {
                borderColor: isDark ?  '#334155' : '#D1D5DB',
                borderRadius: 15,
            },
            gantt: {
                borderColor: isDark ?  '#334155' : '#D1D5DB',
                
            }
        },
        tooltip: {
          pointFormatter: function () {
                  
              var point = this,
                  format = '%e. %b',
                  options = point.options,
                  completed = options.completed,
                  amount = isObject(completed) ? completed.amount : completed,
                  status = ((amount || 0) * 100).toFixed(1) + '%', 
                  lines;
              lines = [{
                  value: point.name,
                  style: 'font-weight: bold;'
              }, {
                  title: 'Start',
                  value: converTooltipDate(point.start)
              }, {
                  title: 'End',
                  value: converTooltipDate(point.end)
              }, 
              {
                  title: 'Completed',
                  value: status
              }];
  
              return lines.reduce(function (str, line) {
                  var s = '',
                      style = (
                          defined(line.style) ? line.style : 'font-size: 0.8em;'
                      );
                  if (line.visible !== false) {
                      s = (
                          '<span style="' + style + '">' +
                          (defined(line.title) ? line.title + ': ' : '') +
                          (defined(line.value) ? line.value : '') +
                          '</span><br/>'
                      );
                  }
                  return str + s;
              }, '');
          }
      },
        series:data,
        xAxis: [
            { // first x-axis
              // min: today.getTime() - (1 * day),
              // max: today.getTime() + (10 * day),
            lineColor: isDark ?  '#334155' : '#D1D5DB',
            tickColor: isDark ?  '#334155' : '#D1D5DB',
            currentDateIndicator: {
                width: 2,
                dashStyle: 'LongDash',
                color: '#94a3b8',
                label: {
                    style: {
                        color: isDark ? '#d1d5db': '#4b5563'
                    }
                }
            },
              labels: {
                style: {
                    color: isDark ? '#9ca3af': '#4b5563'
                }
            },
              tickInterval: 1000 * 60 * 60 * 24 // 1 month
            }, { // second x-axis
              // min: today.getTime() - (2 * day),
              // max: today.getTime() + (10 * day),
                lineColor: isDark ?  '#334155' : '#D1D5DB',
                tickColor: isDark ?  '#334155' : '#D1D5DB',
            currentDateIndicator: {
                width: 2,
                dashStyle: 'LongDash',
                color: '#94a3b8',
                label: {
                    style: {
                        color: isDark ? '#d1d5db': '#4b5563'
                    }
                }
            },
              labels: {
                style: {
                    color: isDark ? '#9ca3af': '#4b5563'
                }
            },
              tickInterval: 1000 * 60 * 60 * 24 * 30, // 1 day
            }
        ],
      })
    }

  },[isDark, projectData])


  
  return (
    <div>
      {!isDataReady &&
          <div className="border-0 border-blue-300 shadow-iotflows-lg dark:shadow-lg-invert rounded-md p-4 w-full mx-auto">
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-6 py-1">
                <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded"></div>
                <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                    <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                  <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded"></div>
                  <div className="h-8 bg-slate-300 dark:bg-slate-700 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        }
      {isDataReady && ganttChartOptions && Object.keys(ganttChartOptions).length > 0 && 
        <HighchartsReact
            constructorType={"ganttChart"}
            highcharts={gantt}
            options={ganttChartOptions}
        />
      }
    </div>
  )
}

export default GanttChart