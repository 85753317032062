import React from 'react'
import { formatFileSize } from '../../../utils/FileFormating'
import { formatDateToAmPm } from '../../../utils/DateFormating'
import InteractionsComponent from '../utils/InteractionsComponent'
import { motion } from 'framer-motion';
import DOMPurify from 'dompurify';
import LinkifyIt from 'linkify-it';
import tlds from 'tlds';
import { createHyperlinks } from '../utils/HyperlinkUtils';
import { shouldAddPin } from '../../../utils/messagingUtils';


const linkify = new LinkifyIt();
linkify.tlds(tlds);

function SenderFileMessageComponent({isMobile, message, scrollToMessage, isGroup, showImage, handleTailCss, participants, userInfo, updateEmoji, isLoadingFile, handleDownloadFile}) {
    let cleanHTML = DOMPurify.sanitize(message.message_text_html || message.message_text); // Sanitize the HTML content

    const links = linkify.match(cleanHTML);
    if(links && links.length > 0){
        cleanHTML = createHyperlinks(message.message_text_html);
    }

    // var cleanReplyHTML = ''; // Sanitize the HTML content
    // if(message.message_replied_from && Object.keys(message.message_replied_from).length > 0 && message.message_replied_from.message_uuid){
    //     cleanReplyHTML = DOMPurify.sanitize(message.message_replied_from.message_text);
    // }


  return (
    <>
    <div 
        className={` pl-4 pr-4 py-1.5 rounded-2xl min-w-[250px] ${isMobile ? 'max-w-[300px]':'max-w-[500px]'} flex flex-col mr-2 order-1 bg-sky-600 dark:bg-sky-800 text-white`}
    >
    {message.message_replied_from && Object.keys(message.message_replied_from).length > 0 && message.message_replied_from.message_uuid &&
        <div className='w-full rounded-xl flex my-1 cursor-pointer text-sm' onClick={() => scrollToMessage(message.message_replied_from.message_uuid)}>
            <div className={`bg-white dark:bg-slate-200 w-[3px] min-w-[3px] rounded-l-full`} />
            <div className={`bg-white/20 dark:bg-slate-200/20 rounded-r-lg pl-2 py-0.5 pr-2 flex flex-col truncate overflow-hidden grow`}>
                <span className={`text-white dark:text-slate-100`}>{`${message.message_replied_from.user_first_name? message.message_replied_from.user_first_name : ''} ${message.message_replied_from.user_last_name ? message.message_replied_from.user_last_name : ''}`}</span>
                <span className={`text-gray-200 dark:text-gray-200 truncate `} >{message.message_replied_from.message_text}</span>
                {/* <div
                    className="text-gray-200 dark:text-gray-200 h-5"
                    dangerouslySetInnerHTML={{ __html: cleanReplyHTML }} // Set the HTML content directly
                /> */}
            </div>
        </div>
    }
    {message.emoji_interactions && Object.keys(message.emoji_interactions).length > 0 ? (
                <div className='flex flex-col w-full pt-1'>
                    <div className='flex items-center pb-1'>
                        <motion.button 
                        whileHover={{ scale: 1.05  }} 
                        whileTap={{ scale: 0.98  }} 
                        className={`h-8 w-8 p-1 items-center justify-center  rounded-full bg-slate-400  dark:bg-slate-500/80 text-white`} onClick={() => handleDownloadFile(message)}> 
                        {isLoadingFile ? (
                            <svg class="animate-spin h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        ):(
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                            </svg>

                        )}
                            
                        </motion.button>
                        <div className='pl-2 flex flex-col'>
                            <h2 className={`text-sm `}>
                                {message.file_name? message.file_name : 'unknow'}
                            </h2>
                            <span className={`text-xs font-light text-gray-200 dark:text-gray-300`}>
                                {formatFileSize(message.file_size)}
                            </span>
                        </div>
                    </div>
                    {/* <span className='text-sm'>{message.message_text}</span> */}
                    <div
                        className={`message-content ${isMobile ? ' message-content-mobile ':' message-content-web '}`}
                        dangerouslySetInnerHTML={{ __html: cleanHTML }} // Set the HTML content directly
                    />
                    <div className='flex items-end'>
                        <InteractionsComponent message={message} userInfo={userInfo} updateEmoji={updateEmoji} participants={participants} />
                        <span className={`${message.user_username === userInfo.user_username ?  'text-gray-300':'text-gray-500 dark:text-gray-400'} flex text-xs ml-auto mt-auto mb-[-5px] min-w-[60px] italic pl-3 py-0.5`}>
                        {shouldAddPin(message, userInfo)}{message.message_is_edited && 'edited  '} {formatDateToAmPm(message.message_created_at)}
                        </span>
                    </div>
                </div>
    ):(
        <div className='flex flex-col pt-1'>      
            <div className='flex items-center pb-1'>
                <motion.button 
                whileHover={{ scale: 1.05  }} 
                whileTap={{ scale: 0.98  }} 
                className={`h-8 w-8 p-1 items-center justify-center  rounded-full bg-slate-400 dark:bg-slate-500/80 text-white`} onClick={() => handleDownloadFile(message)}> 
                {isLoadingFile ? (
                    <svg class="animate-spin h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                ):(
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg>

                )}
                    
                </motion.button>
                <div className='pl-2 flex flex-col'>
                    <h2 className={`text-sm `}>
                        {message.file_name? message.file_name : 'unknow'}
                    </h2>
                    <span className={`text-xs font-light text-gray-200 dark:text-gray-300`}>
                        {formatFileSize(message.file_size)}
                    </span>
                </div>
            </div>
            <div className='flex flex-wrap w-full'>
                {/* <span className='text-sm'>{message.message_text}</span>  */}
                <div
                    className={`message-content ${isMobile ? ' message-content-mobile ':' message-content-web '}`}
                    dangerouslySetInnerHTML={{ __html: cleanHTML }} // Set the HTML content directly
                />
                <span className={`flex text-gray-300 text-xs ml-auto mt-auto text-right mb-[-5px] italic pl-3 py-0.5`}>
                {shouldAddPin(message, userInfo)}{message.message_is_edited && 'edited  '}{formatDateToAmPm(message.message_created_at)}
                </span>
            </div>
    </div>
    )}
    </div>
     {/*THIS WILL SHOW THE SENDERS IMAGE ON THE LEFT SIDE BUT I DONT THINK ITS NEEDED */}   
     {/* <div className={`${isGroup ? 'w-8 h-8': 'hidden'} order-2`}>
        {showImage &&
            <Avatar
                sx={{ 
                    bgcolor: handleUserBGColor(message.user_username), 
                    width: 32, 
                    height: 32 }}
                alt={participants[message.user_username].user_first_name? participants[message.user_username].user_first_name : ''}
                src={participants[message.user_username].user_image_url}
            />
        }
    </div> */}
    {/*THIS tail is for when the is an image showing and we need to move the tail to the left of the screen*/}   
        {/* {showImage && isGroup &&
        <div 
            className={`absolute bottom-[-13px] right-[20px] rotate-6 text-sky-600 dark:text-sky-800`}
            style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
        >
            <svg width="30" height="30" viewBox="0 0 20 10">
                <path d={`${message.user_username === userInfo.user_username ? 'M 0 0 L 10 5 L 0 5 Z':'M 10 5 L 10 -5 L 0 -10 Z'}`} fill="currentColor" />
            </svg>
        </div>
        } */}
    {/*THIS TAIL IS FOR WHEN THERE IS NO IMAGE AND WE NEED THE TAIL CLOSER TO THE RIGHT OF THE SCREEN*/}
    {showImage && 
    <div 
        className={`absolute bottom-[-13px] right-[-16px] rotate-6 text-sky-600 dark:text-sky-800`}
        style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
        >
        <svg width="30" height="30" viewBox="0 0 20 10">
            <path d={`M 0 0 L 10 5 L 0 5 Z`} fill="currentColor" />
        </svg>
    </div>
    }
</>
)
}

export default SenderFileMessageComponent