
import React, { useEffect, useState } from 'react'
import SwipeableViews from 'react-swipeable-views';

const quarters = ["Q1", "Q2", "Q3", "Q4"];

function QuarterPicker({setSelectedQuarter, selectedQuarter, setDateString}) {
    const [highlightedQuarters, setHighlightedQuarters] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [currentYearIndex, setCurrentYearIndex] = useState(0);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 2021;
        const years = [];

        for (let year = startYear; year <= currentYear; year++) {
        years.push(year);
        }

        setYearList(years);
        setCurrentYearIndex(years.length - 1)

    },[])

    useEffect(() => {
        // This code is used to properly index and highlight date when pulled from the URL
        if(selectedQuarter && Object.keys(selectedQuarter).length >= 0 && yearList && Object.keys(yearList).length > 0){
            let string = ``
            selectedQuarter.map((dateArr, index) => {
                if(index === 0){
                    string += dateArr.year + ' ' + dateArr.q
                }else {
                    string += ' -> ' + dateArr.year + ' ' + dateArr.q
                }
            })
            setDateString(string)
            let array = []
            if(Object.keys(selectedQuarter).length === 1){
                array.push(`${selectedQuarter[0].year}-${selectedQuarter[0].q}`)
                setHighlightedQuarters(array)
                let indexYear = yearList.indexOf(selectedQuarter[0].year)
                setCurrentYearIndex(indexYear)

            }else if(Object.keys(selectedQuarter).length === 2){
                let indexYear = yearList.indexOf(selectedQuarter[1].year)
                setCurrentYearIndex(indexYear)
                if(selectedQuarter[0].year == selectedQuarter[1].year){
                    if(selectedQuarter[0].q == selectedQuarter[1].q){
                        array.push(`${selectedQuarter[0].year}-${selectedQuarter[0].q}`)

                    }else {
                        for (let q_i = 0; q_i < 4; q_i++) {
                            if(q_i >= quarters.indexOf(selectedQuarter[0].q) && q_i <= quarters.indexOf(selectedQuarter[1].q)){
                                array.push(array.push(`${selectedQuarter[0].year}-${quarters[q_i]}`));
                            }
                        }
                    }

                }else{
                    for (let year = selectedQuarter[0].year; year <= selectedQuarter[1].year; year++) {
                        for (let q_i = 0; q_i < 12; q_i++) {
        
                        if ( year >= selectedQuarter[0].year && year <= selectedQuarter[1].year ) {
        
                            if(year == selectedQuarter[0].year && q_i >= quarters.indexOf(selectedQuarter[0].q)){
                                array.push(array.push(`${year}-${quarters[q_i]}`));
        
                            }else if(year == selectedQuarter[1].year && q_i <= quarters.indexOf(selectedQuarter[1].q)){
                                array.push(array.push(`${year}-${quarters[q_i]}`));
        
                            }else if(year > selectedQuarter[0].year && year < selectedQuarter[1].year){
                                array.push(array.push(`${year}-${quarters[q_i]}`));
        
                            }
                        }
                        }
                    }
                }

                setHighlightedQuarters(array)

            }else{
                setHighlightedQuarters([])
            }
        
        }

    }, [selectedQuarter, yearList])

    const handleYearChange = (newYearIndex) => {
        if (newYearIndex >= 0 && newYearIndex < yearList.length) {
          setCurrentYearIndex(newYearIndex);
          
        }
      };

      const handleQuarterClick = (q, year) => {
        let currentDate = {
            q,
            year
        }
        let dateArray = []
        if(Object.keys(selectedQuarter).length === 0){
            dateArray.push(currentDate)
            setSelectedQuarter(dateArray);
        }
        else if(Object.keys(selectedQuarter).length === 1 ){
            const date1 = new Date(selectedQuarter[0].year, quarters.indexOf(selectedQuarter[0].q), 1); // December 25, 2024
            const date2 = new Date(year, quarters.indexOf(q), 1);
            
            if (date1 > date2) {
                // console.log(date1, 'is greater than', date2);
                dateArray.push(currentDate)
                setSelectedQuarter(dateArray);
              } else if (date1 < date2) {
                    // console.log(date1, 'is less than', date2);
                    dateArray = selectedQuarter
                    dateArray.push(currentDate)
                    setSelectedQuarter(dateArray);
              } else {
                    // console.log(date1, 'is equal to', date2);
                    dateArray = selectedQuarter
                    dateArray.push(currentDate)
                    setSelectedQuarter(dateArray);
              }
        }else if(Object.keys(selectedQuarter).length > 1){
            dateArray.push(currentDate)
            setSelectedQuarter(dateArray);
        }

        let string = ``
        dateArray.map((dateArr, index) => {
            if(index === 0){
                string += dateArr.year + ' ' + dateArr.q
            }else {
                string += ' -> ' + dateArr.year + ' ' + dateArr.q
            }
        })
        setDateString(string)

        let array = []
        if(Object.keys(dateArray).length === 1){
            array.push(`${dateArray[0].year}-${dateArray[0].q}`)
            setHighlightedQuarters(array)

        }else if(Object.keys(dateArray).length === 2){
            if(dateArray[0].year == dateArray[1].year){
                if(dateArray[0].q == dateArray[1].q){
                    array.push(`${dateArray[0].year}-${dateArray[0].q}`)

                }else {
                    for (let q_i = 0; q_i < 4; q_i++) {
                        if(q_i >= quarters.indexOf(dateArray[0].q) && q_i <= quarters.indexOf(dateArray[1].q)){
                            array.push(array.push(`${dateArray[0].year}-${quarters[q_i]}`));
                        }
                    }
                }

            }else{
                for (let year = dateArray[0].year; year <= dateArray[1].year; year++) {
                    for (let q_i = 0; q_i < 12; q_i++) {
    
                      if ( year >= dateArray[0].year && year <= dateArray[1].year ) {
    
                        if(year == dateArray[0].year && q_i >= quarters.indexOf(dateArray[0].q)){
                            array.push(array.push(`${year}-${quarters[q_i]}`));
    
                        }else if(year == dateArray[1].year && q_i <= quarters.indexOf(dateArray[1].q)){
                            array.push(array.push(`${year}-${quarters[q_i]}`));
    
                        }else if(year > dateArray[0].year && year < dateArray[1].year){
                            array.push(array.push(`${year}-${quarters[q_i]}`));
    
                        }
                      }
                    }
                  }
            }

            setHighlightedQuarters(array)

        }else{
            setHighlightedQuarters([])
        }
      };


  return (
    <div>
        <div className='flex justify-between items-center'>
            <button
            className='bg-slate-600 rounded px-2.5 py-0.5 text-gray-300'
                onClick={() => handleYearChange(currentYearIndex - 1)}
            >
                &lt;
            </button>
            <span className='text-gray-300'>
                {yearList[currentYearIndex]}
            </span>
            <button 
                className='bg-slate-600 rounded px-2.5 py-0.5 text-gray-300'
                onClick={() => handleYearChange(currentYearIndex + 1)}>
                &gt;
            </button>
        </div>
        <SwipeableViews
            index={currentYearIndex}
            onChangeIndex={handleYearChange}
        >
            {yearList.map((year, index) => (
                <div key={year} className='grid grid-cols-2 gap-3 mt-8 pb-4'>
                    {quarters.map((q, index) => (
                    <button
                        key={`${year}-${q}`}
                        onClick={() => handleQuarterClick(q, year)}
                        className={`${highlightedQuarters.includes(`${year}-${q}`)? 'bg-blue-500 text-white':'bg-slate-600 text-gray-300'} px-2 py-0.5  rounded-lg text-sm`}
                    >
                        {q}
                    </button>
                    ))}
                </div>
            ))}
        </SwipeableViews>
    </div>
  )
}

export default QuarterPicker