import { useEffect, useRef, useState } from 'react'
import { motion } from "framer-motion";
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import CycleTimeInputCell from './CycleTimeInputCell';
import { LightTooltip } from '../../styles/Tooltip';
import { putData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { toast } from 'react-hot-toast';
import ConfirmAlgoChangeModal from '../../components/modals/partModals/ConfirmAlgoChangeModal';

const algorithm = [
    { id: 1, name: 'Sequence Analysis' },
    { id: 2, name: 'Continuous Uptime Analysis' },
  ]

function OpsTableRow({ operation, algoTypes, getOpsList }) {
    const [isEditingName, setIsEditingName] = useState(false);
    const [loadingName, setLoadingName] = useState(false);
    const [loadingDescription, setLoadingDescription] = useState(false);
    const [loadingQuantityPerCycle, setLoadingQuantityPerCycle] = useState(false);
    const [loadingAlgo, setLoadingAlgo] = useState(false);
    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const [isEditingQuantityPerCycle, setIsEditingQuantityPerCycle] = useState(false);
    const [operationName, setOperationName] = useState(operation.operation_name || ''); // Handle initial value
    const [operationDescription, setOperationDescription] = useState(operation.operation_description || ''); // Handle initial value
    const [operationQuantityPerCycle, setOperationQuantityPerCycle] = useState(operation.operation_quantity_per_cycle || ''); // Handle initial value
    const [operationAlgorithmId, setOperationAlgorithmId] = useState(operation.operation_detection_algorithm_id || ''); // Handle initial value
    const [operationAlgorithmName, setOperationAlgorithmName] = useState(operation.operation_detection_algorithm_name || ''); // Handle initial value
    const [openConfirmAlgoChangeModal, setOpenConfirmAlgoChangeModal] = useState(false);
    const [conflictingAssets, setConflictingAssets] = useState([]);
    const [conflictingInfo, setConflictingInfo] = useState('');

    useEffect(() => {
        if(operation && Object.keys(operation).length > 0){
            if(operation.operation_name){
                setOperationName(operation.operation_name)
            }
            if(operation.operation_description){
                setOperationDescription(operation.operation_description)
            }
            if(operation.operation_quantity_per_cycle){
                setOperationQuantityPerCycle(operation.operation_quantity_per_cycle)
            }
            if(operation.operation_detection_algorithm_id){
                setOperationAlgorithmId(operation.operation_detection_algorithm_id)
                setOperationAlgorithmName(operation.operation_detection_algorithm_name)
            }
        }

    }, [operation])
  
    const handleKeyPressName = (event) => {
      if (event.key === 'Enter' || event.key === 'Tab') {
        if(!event.target.value) {
            setOperationName(operation.operation_name)
            setIsEditingName(false);
            return;
        }
        if(!loadingName){
            setLoadingName(true)
            setIsEditingName(false);
            putData(apiLink + '/v2/operations/' + operation.operation_uuid , 
            {'operation_name': event.target.value})
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoadingName(false)
                    setOperationName(event.target.value)
                }
                else{
                    try{
                        setOperationName(operation.operation_name)
                        setLoadingName(false)
                        toast.error(data.message)
                    }catch (e){
                        setOperationName(operation.operation_name)
                        toast.error(e.message)
                        setLoadingName(false)
                    }
                }
            })
        }
      }
    };

    const handleNameBlur = (event) => {
        if(!event.target.value) {
            setOperationName(operation.operation_name)
            setIsEditingName(false);
            return;
        }
        if(!loadingName){
            setLoadingName(true)
            setIsEditingName(false);
            putData(apiLink + '/v2/operations/' + operation.operation_uuid , 
            {'operation_name': event.target.value})
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoadingName(false)
                    setOperationName(event.target.value)
                }
                else{
                    try{
                        setOperationName(operation.operation_name)
                        setLoadingName(false)
                        toast.error(data.message)
                    }catch (e){
                        setOperationName(operation.operation_name)
                        toast.error(e.message)
                        setLoadingName(false)
                    }
                }
            })
        }

    }
    const handleKeyPressDescription = (event) => {
      if (event.key === 'Enter' || event.key === 'Tab') {
        if(!loadingDescription){
            setOperationDescription(event.target.value)
            setLoadingDescription(true)
            setIsEditingDescription(false);
            putData(apiLink + '/v2/operations/' + operation.operation_uuid , 
            {'operation_description': event.target.value})
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoadingDescription(false)
                    setOperationDescription(event.target.value)
                }
                else{
                    try{
                        setOperationDescription(operation.operation_description)
                        setLoadingDescription(false)
                        toast.error(data.message)
                    }catch (e){
                        setOperationDescription(operation.operation_description)
                        toast.error(e.message)
                        setLoadingDescription(false)
                    }
                }
            })
        }
      }
    };

    const handleDescriptionBlur = (event) => {
        if(!loadingDescription){
            setOperationDescription(event.target.value)
            setLoadingDescription(true)
            setIsEditingDescription(false);
            putData(apiLink + '/v2/operations/' + operation.operation_uuid , 
            {'operation_description': event.target.value})
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoadingDescription(false)
                    setOperationDescription(event.target.value)
                }
                else{
                    try{
                        setOperationDescription(operation.operation_description)
                        setLoadingDescription(false)
                        toast.error(data.message)
                    }catch (e){
                        setOperationDescription(operation.operation_description)
                        toast.error(e.message)
                        setLoadingDescription(false)
                    }
                }
            })
        }

    }
    const handleKeyPressQuantityPerCycle = (event) => {
      if (event.key === 'Enter' || event.key === 'Tab') {
        if(!loadingQuantityPerCycle){
            let cycleNumber = parseFloat(event.target.value.replace(/[^0-9.-]/g, "")) 
            setOperationQuantityPerCycle(cycleNumber)
            setLoadingQuantityPerCycle(true)
            setIsEditingQuantityPerCycle(false);
            // if(cycleNumber < 1){
            //     cycleNumber = 1
            // }
            putData(apiLink + '/v2/operations/' + operation.operation_uuid , 
            {'operation_quantity_per_cycle': cycleNumber})
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoadingQuantityPerCycle(false)
                    setOperationQuantityPerCycle(cycleNumber)
                }
                else{
                    try{
                        setOperationQuantityPerCycle(operation.operation_quantity_per_cycle)
                        setLoadingQuantityPerCycle(false)
                        toast.error(data.message)
                    }catch (e){
                        setOperationQuantityPerCycle(operation.operation_quantity_per_cycle)
                        toast.error(e.message)
                        setLoadingQuantityPerCycle(false)
                    }
                }
            })
        }
      }
    };

    const handleQuantityPerCycleBlur = (event) => {
        if(!loadingQuantityPerCycle){
            let cycleNumber = parseFloat(event.target.value.replace(/[^0-9.-]/g, "")) 
            setOperationQuantityPerCycle(cycleNumber)
            setLoadingQuantityPerCycle(true)
            setIsEditingQuantityPerCycle(false);
            // if(cycleNumber < 1){
            //     cycleNumber = 1
            // }
            putData(apiLink + '/v2/operations/' + operation.operation_uuid , 
            {'operation_quantity_per_cycle': cycleNumber})
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoadingQuantityPerCycle(false)
                    setOperationQuantityPerCycle(cycleNumber)
                }
                else{
                    try{
                        setOperationQuantityPerCycle(operation.operation_quantity_per_cycle)
                        setLoadingQuantityPerCycle(false)
                        toast.error(data.message)
                    }catch (e){
                        setOperationQuantityPerCycle(operation.operation_quantity_per_cycle)
                        toast.error(e.message)
                        setLoadingQuantityPerCycle(false)
                    }
                }
            })
        }

    }


    const handleChangeAlgorithm = (e) => {
        if(!loadingAlgo){
            setLoadingAlgo(true)
            setIsEditingDescription(false);
            putData(apiLink + '/v2/operations/' + operation.operation_uuid , 
            {'operation_operation_detection_algorithm_id': e.operation_detection_algorithm_id})
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoadingAlgo(false)
                    setOperationAlgorithmId(e.operation_detection_algorithm_id)
                    setOperationAlgorithmName(e.operation_detection_algorithm_name)
                    getOpsList()
                }else if(response.status == 409){
                    setLoadingAlgo(false)
                    setOpenConfirmAlgoChangeModal(true)
                    setConflictingAssets(data.conflicting_assets)
                    let info = {
                        message: data.message,
                        operation_detection_algorithm_id: e.operation_detection_algorithm_id
                    }
                    setConflictingInfo(info)

                }else{
                    try{
                        setOperationAlgorithmId(operation.operation_detection_algorithm_id)
                        setOperationAlgorithmName(operation.operation_detection_algorithm_name)
                        setLoadingAlgo(false)
                        toast.error(data.message)
                    }catch (e){
                        console.log('e', e)
                        setOperationAlgorithmId(operation.operation_detection_algorithm_id)
                        setOperationAlgorithmName(operation.operation_detection_algorithm_name)
                        toast.error(e.message)
                        setLoadingAlgo(false)
                    }
                }
            })
        }
    }


    const handleCloseConfirmAlgoChangeModal = () => {
        setOpenConfirmAlgoChangeModal(false)
    }

   

  
    return (
      <tr className='group'>
        {isEditingName ? (
          <td 
            key={`${operation.operation_uuid}-operation_name`}
            className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-1 border-[0.01em] dark:border-zinc-600'
          >
            <input
              type="text"
              name='operation_name'
              value={operationName}
              onChange={(e) => setOperationName(e.target.value)}
              onBlur={handleNameBlur} // Handle blur
              onKeyDown={handleKeyPressName}
              className={`block bg-transparent w-full border rounded-md py-0.5 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800`}
              autoFocus // Focus on input upon render
            />
          </td>
        ) : (
          <td 
            key={`${operation.operation_uuid}-operation_name`} 
            disabled={loadingName}
            onClick={() => setIsEditingName(true)}
            className='relative text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-1 border-[0.01em] dark:border-zinc-600'
            >
                <div
                    className={`bg-transparent w-full border border-transparent rounded-md py-0.5 px-3  sm:text-sm  text-gray-600 dark:text-gray-300`}
                >
                    {operationName}
                </div>
            {loadingName && 
            <div >
                <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                </svg>
            </div>
            }
          </td>
        )}
        {isEditingDescription ? (
          <td 
            key={`${operation.operation_uuid}-operation_description`}
            className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-1 border-[0.01em] dark:border-zinc-600'
          >
            <input
              type="text"
              name='operation_description'
              value={operationDescription}
              onChange={(e) => setOperationDescription(e.target.value)}
              onBlur={handleDescriptionBlur} // Handle blur
              onKeyDown={handleKeyPressDescription}
              className={`block bg-transparent w-full border rounded-md py-0.5 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800`}
              autoFocus // Focus on input upon render
            />
          </td>
        ) : (
          <td 
            key={`${operation.operation_uuid}-operation_description`} 
            onClick={() => setIsEditingDescription(true)}
            className='relative text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-1 border-[0.01em] dark:border-zinc-600'
            >
                <div
                    className={`bg-transparent w-full border border-transparent rounded-md py-0.5 px-3  sm:text-sm  text-gray-600 dark:text-gray-300`}
                >
                    {operationDescription}
                </div>
                {loadingDescription && 
                <div >
                    <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                    </svg>
                </div>
                }
          </td>
        )}
        {isEditingQuantityPerCycle ? (
          <td 
            key={`${operation.operation_uuid}-operation_quantity_per_cycle`}
            className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-1 border-[0.01em] dark:border-zinc-600'
          >
            <input
              type="number"
              name='operation_quantity_per_cycle'
              value={operationQuantityPerCycle}
              onChange={(e) => setOperationQuantityPerCycle(e.target.value)}
              onBlur={handleQuantityPerCycleBlur} // Handle blur
              onKeyDown={handleKeyPressQuantityPerCycle}
              className={`block bg-transparent w-full border rounded-md py-0.5 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800`}
              autoFocus // Focus on input upon render
            />
          </td>
        ) : (
          <td 
            key={`${operation.operation_uuid}-operation_quantity_per_cycle`} 
            onClick={() => setIsEditingQuantityPerCycle(true)}
            className='relative text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-1 border-[0.01em] dark:border-zinc-600'
            >
                <div
                    className={`bg-transparent text-center w-full border border-transparent rounded-md py-0.5 px-3  sm:text-sm  text-gray-600 dark:text-gray-300`}
                >
                    {operationQuantityPerCycle}
                </div>
                {loadingQuantityPerCycle && 
                <div >
                    <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                    </svg>
                </div>
                }
          </td>
        )}
        <td 
        className='relative text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-1 border-[0.01em] dark:border-zinc-600'
        >
        <Listbox  value={operationAlgorithmName} onChange={handleChangeAlgorithm}>
            <ListboxButton className='w-full justify-center text-center'>
                {operationAlgorithmId == '' &&
                <div
                    className={`items-center mx-auto w-fit flex px-4 py-0.5 text-sm font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 rounded-full shadow-iotflows-lg`}
                >
                        Select Algorithm
                        <ChevronDownIcon className='text-gray-400 w-5 h-5 pl-1' />
                </div>
                }
                {operationAlgorithmId == 100 &&
                <div
                className={`mx-auto w-fit text-center px-4 py-0.5 text-sm font-normal text-white dark:text-white bg-cyan-600 dark:bg-cyan-600/80 rounded-full`}>
                    {operationAlgorithmName}
                </div>
                }
                {operationAlgorithmId == 200 &&
                <div
                className={`mx-auto w-fit px-4 py-0.5 text-sm font-normal text-white dark:text-white bg-sky-600 dark:bg-sky-600/80 rounded-full`}>
                    {operationAlgorithmName}
                </div>
                }
                {operationAlgorithmId == 300 &&
                <div
                className={`mx-auto w-fit px-4 py-0.5 text-sm font-normal text-white dark:text-white bg-cyan-500 dark:bg-cyan-500/80 rounded-full`}>
                    {operationAlgorithmName}
                </div>
                }
            </ListboxButton>
            <ListboxOptions className='absolute top-10 inset-x-0 mx-0 md:mx-0 lg:mx-0 2xl:mx-10 bg-white shadow-iotflows-lg dark:bg-slate-700/95 z-50 px-2 py-4 rounded-xl space-y-3'>
                {algoTypes.map((algo) => (
                <ListboxOption
                    key={algo.operation_detection_algorithm_id}
                    value={algo}
                    className={`${algo.operation_detection_algorithm_id == 100 && 'bg-cyan-600 dark:bg-cyan-600/80 ui-active:bg-cyan-500 ui-active:dark:bg-cyan-500/90'} 
                                ${algo.operation_detection_algorithm_id == 200 && 'bg-sky-600 dark:bg-sky-600/80 ui-active:bg-sky-500 ui-active:dark:bg-sky-500/90'} 
                                ${algo.operation_detection_algorithm_id == 300 && 'bg-cyan-500 dark:bg-cyan-500/80 ui-active:bg-cyan-600 ui-active:dark:bg-cyan-500/50'}
                                text-center rounded-full text-white text-sm font-normal cursor-pointer`}
                >
                    <LightTooltip title={`${algo.operation_detection_algorithm_description}`} placement='right-start' arrow >
                        <span className={`mx-auto w-fit text-center px-4 py-1`}>
                        {algo.operation_detection_algorithm_name}
                        </span>

                    </LightTooltip>
                </ListboxOption>
                ))}
            </ListboxOptions>
            </Listbox>
            {loadingAlgo && 
                <div >
                    <svg aria-hidden="true" className={`absolute top-1/4 right-1/2 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                    </svg>
                </div>
            }
        {openConfirmAlgoChangeModal &&
            <ConfirmAlgoChangeModal 
                remoteOpenModal={openConfirmAlgoChangeModal} 
                conflictingAssets={conflictingAssets}
                conflictingInfo={conflictingInfo} 
                handleCloseConfirmAlgoChangeModal={handleCloseConfirmAlgoChangeModal} 
                getOpsList={getOpsList} 
                operation={operation} 
                setOperationAlgorithmId={setOperationAlgorithmId} 
                setOperationAlgorithmName={setOperationAlgorithmName}/>
        }
        </td>
       <CycleTimeInputCell operation={operation} operationAlgorithmId={operationAlgorithmId} getOpsList={getOpsList} />
       {/* <td>
        Auto Detect
       </td> */}
        
      </tr>
    );
}

export default OpsTableRow