import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState, Fragment, memo, useMemo, useCallback } from "react"
import EmojiPicker from "../../components/messagingComponents/EmojiPicker";
import MqttService from '../../core/services/MqttService'; 
import { createMessagingMQTT } from "../../core/services/MqttMessaging";
import { toast } from "react-hot-toast";
import { Menu, Transition, MenuButton, MenuItems, MenuItem } from '@headlessui/react'
import CameraModal from "../../components/modals/communicationModals/CameraModal";
import FilePreviewModal from "../../components/modals/communicationModals/FilePreviewModal";
import RecordVoiceButton from "./RecordVoiceButton";
import { updateOrInsertMessageByDate } from "../../utils/MessageDataSorting";
import { getData } from "../../ApiCalls/DataApis";
import { apiLink } from "../../ApiCalls/ApisVariables";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../styles/chatInputStyles.css'
import { useSearchParams } from "react-router-dom";
import { darkModeState } from "../../atoms/darkModeState";
import { useRecoilValue } from "recoil";

const imageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml'];




function ChatInput({chatInfo, userInfo, messagesRef, goToBottomButtonRef, replyMessage, setReplyMessage,editMessage, setEditMessage, allEmojis, messages, setMessages, setRemoveMessageObject, droppedFiles, setDroppedFiles}) {
    const [chatInputMessageText, setChatInputMessageText] = useState('')
    const isDark = useRecoilValue(darkModeState);
    const [closeEverything, setCloseEverything] = useState(false)
    // const textAreaRef = useRef(null);
    const quillRef = useRef(null);
    const [selectedImagesVideos, setSelectedImagesVideos] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const hiddenImageVideoInput = useRef(null);
    const hiddenFileInput = useRef(null);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [capturedImage, setCapturedImage] = useState(null);
    const [isStreaming, setIsStreaming] = useState(false);
    const [isFilePreviewModalOpen, setIsFilePreviewModalOpen] = useState(false);
    const [audioURL, setAudioURL] = useState('');
    const hapticAudioRef = useRef(new Audio('/audio/new-notification-7-210334.mp3'));
    const [isMobile, setIsMobile] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {

        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
  
    },[])

    

    useEffect(() => {
        // Focus on the textarea when the component mounts without scrolling
        // if (textAreaRef.current) {
        //   textAreaRef.current.focus({ preventScroll: true });
        // }
        if (quillRef.current) {
            quillRef.current.getEditor().focus({ preventScroll: true });
        }
      }, []);

    useEffect(() => {
        if(chatInfo && chatInfo.chatroom_uuid){
            getData(apiLink + `/v1/chatrooms/${chatInfo.chatroom_uuid}/draft_message`)
            .then(async response => {
                if(response && response.ok ){
                    const data = await response.json();
                    if(data && data.data && data.data[0] && data.data[0].message_text_html && data.data[0].message_text_html.length > 0 ){
                        // console.log('data.data[0].message_text_html', data.data[0])
                        if(data.data[0].message_text_html =='<p><br></p>'){
                            setChatInputMessageText('')    
                        }else{
                            setChatInputMessageText(data.data[0].message_text_html)
                        }
                        // quillRef.current.getEditor().setContents([{ insert: data.data[0].message_text_html}])
                    }
                }
            });

        }
      }, [chatInfo]);

    useEffect(() => {
        if(editMessage && Object.keys(editMessage).length > 0){
            // textAreaRef.current.value = editMessage.message_text
            setChatInputMessageText(editMessage.message_text_html)
            // quillRef.current.getEditor().setContents([{ insert: editMessage.message_text_html  }])
        }

    }, [editMessage])
    
    useEffect(() => {
        const adjustHeight = () => {
        //   const element = textAreaRef.current;
          const element = quillRef.current;
          const messagesContainerElement = messagesRef.current
          const goToButtomElement = goToBottomButtonRef.current
          if (element && messagesContainerElement) {

            // element.style.height = 'auto';
            // let scrollHeight = element.scrollHeight;
            let scrollHeight = quillRef.current.getEditor().root.scrollHeight
            if(scrollHeight > 208){
                scrollHeight = 208
            }else if(scrollHeight <= 24){
                // this helps ignore the chat input when there is only one line but if the user start more than one line than the entire messages window will shift up. E.X. 24 is the px height for only one line, 44 is for 2 lines
                scrollHeight = 0
            }
            const minHeight = 28; // Set a minimum height
            // Adjust height based on scrollHeight, with a minimum
            // let newHeight =  chatInputMessageText ? Math.max(minHeight, scrollHeight) + 'px' : minHeight + 'px';
            // element.style.height = newHeight
            // Padding for the messages section, defgault is 208px 
            let replyHeight = 0
            if((replyMessage && Object.keys(replyMessage).length > 0) || (editMessage && Object.keys(editMessage).length > 0)){
                replyHeight = 52
                // textAreaRef.current.focus();
                quillRef.current.getEditor().focus({ preventScroll: true });
            }else{
                replyHeight = 0
            }
            // removing the 180 because I don't need this padding anymore, I'm adjusting the height of the div to account for the chat input
            // let newPadding = 180 + scrollHeight + replyHeight + 'px'
            
            let newPadding = scrollHeight + replyHeight + 'px'
            messagesContainerElement.style.paddingBottom = newPadding
            if(goToButtomElement){
                // removing the 180 because I don't need this padding anymore, I'm adjusting the height of the div to account for the chat input
                // goToButtomElement.style.bottom = 200 + scrollHeight + replyHeight + 'px'
                
                goToButtomElement.style.bottom = element.scrollHeight + replyHeight + 'px'
            }

          }
        };
    
        adjustHeight();
        window.addEventListener('resize', adjustHeight);
    
        return () => window.removeEventListener('resize', adjustHeight);
      }, [chatInputMessageText, replyMessage, editMessage, quillRef]);


      

    const addMessage =(e, submitType) => {
        
        
        let mess = ''
        setCloseEverything(true)
        if(submitType == 'submitClick' || submitType == 'userEnter'){
            mess = chatInputMessageText
        }else{
            e.preventDefault();
            mess = e.target.value
        }
        // Access the Quill editor instance using the ref
        const editor = quillRef.current.getEditor();
        
        // Get the plain text from the editor
        const plainText = editor.getText().replace(/[\n\r]/g, '');
        // console.log('message', mess)
        // console.log('plainText', plainText)
        // POST MQTT MESSAGE
        if (MqttService.isConnected) {
            let reply_uuid =  ''
            if(replyMessage && replyMessage.message_uuid){
                reply_uuid = replyMessage.message_uuid
                setReplyMessage([])
            }

            let edit_uuid =  ''
            let msg = {}
            if(editMessage && editMessage.message_uuid){
                edit_uuid = editMessage.message_uuid
                setEditMessage([])
                msg = {
                    from_username: userInfo.user_username,
                    to_chatroom_uuid: chatInfo.chatroom_uuid,
                    action: 'edit_message',
                    message_text_html: mess,
                    message_text: plainText,
                    message_uuid: edit_uuid,
                }
            }else{
                msg = {
                    from_username: userInfo.user_username,
                    to_chatroom_uuid: chatInfo.chatroom_uuid,
                    action: 'post_message',
                    message_text_html: mess,
                    message_text: plainText,
                    // message_file_uuid: file_uuid, (OPTIONAL)
                    "reply_from_message_uuid": reply_uuid,
                    // "reply_from_message_uuid": "msg_123123213" (OPTIONAL)
                }

                
            }
            
            msg = createMessagingMQTT(msg);
            // console.log('createMessagingMQTT,', JSON.stringify(msg))
            
            if(msg.topic && msg.payload)
            {
                // console.log('msg.topic,', msg.topic)

                MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
                
            }
            setChatInputMessageText('')
            console.log('removing text and refocusing')
            // Set the editor's content to empty after sending
            // if (quillRef.current) {
            //     quillRef.current.getEditor().setText('');
            // }
            // Focus the editor again to allow capitalization
            quillRef.current.focus();
            

            const audio = hapticAudioRef.current;
            audio.currentTime = 0; // Start the audio from the beginning
            audio.play();

            setTimeout(() => {
                audio.pause();
            }, 1000);

        }else{
            toast.error('Waiting for Network')
        }
    }

    

    const handleInsertEmoji = (emoji) => {
        // console.log(chatInputMessageText + emoji)
        // setChatInputMessageText(chatInputMessageText + emoji); // Insert emoji into input field
        // textAreaRef.current.focus();
        
        // const quill = quillRef.current.getEditor();
        // const range = quill.getSelection();
        // let position = range ? range.index : 0;
        // quill.insertText(position, emoji);
        const quill = quillRef.current.getEditor();
        const range = quill.getSelection(true);
        console.log('range', range)
        if (range) {
            quill.insertText(range.index, emoji);
        } else {
            quill.insertText(quill.getLength() - 1, emoji);
        }


        quillRef.current.getEditor().focus({ preventScroll: true });

      }; 

    // const handleKeyDown = (e) => {
    //     if (e.key === 'Enter' && e.shiftKey) {
    //         e.preventDefault(); // Prevent form submission
    //         setChatInputMessageText(chatInputMessageText + '\n'); // Add a new line character

    //     }else if(e.keyCode === 13) { // Enter key pressed    
    //         e.preventDefault(); // Prevent default submission for Enter
    //         addMessage(e, 'userEnter');
    //     }
    //     // else if (e.key === 'Tab') {
    //     //     e.preventDefault();
    //     //     const start = chatInputMessageText.slice(0, e.target.selectionStart);
    //     //     const end = chatInputMessageText.slice(e.target.selectionEnd);
    //     //     setChatInputMessageText(`${start}\t${end}`);
            
    //     //   }
    // }

    useEffect(() => {
        if(droppedFiles && Object.keys(droppedFiles).length > 0){
            // this is used for when a user dragges and drops a file on the chat screen to send a file
            let files = Array.from(droppedFiles)
            if (files.every(file => imageTypes.includes(file.type))) {
                setSelectedImagesVideos(Array.from(droppedFiles))
                setIsFilePreviewModalOpen(true)
                setDroppedFiles([])

                // setIsFileCompressible(true)
                // const newPreviews = selectedFiles.map(file => URL.createObjectURL(file))
                // setImgPreviews(newPreviews);
             
              } else{
                  setSelectedFiles(Array.from(droppedFiles));
                  setIsFilePreviewModalOpen(true)
                  setDroppedFiles([])
              }
            
        }

    }, [droppedFiles])



      const handleImageVideoClick = event => {
        hiddenImageVideoInput.current.click();
      };

      const handleFileClick = event => {
        hiddenFileInput.current.click();
      };

      const handleImageVideoChange = event => {
        setSelectedImagesVideos(Array.from(event.target.files));
        setIsFilePreviewModalOpen(true)
        
        // Reset input value
        event.target.value = '';
      };

      const handleFileChange = event => {
        // const fileUploaded = event.target.files[0];
        // setSelectedFile(fileUploaded);
        setSelectedFiles(Array.from(event.target.files));



        setIsFilePreviewModalOpen(true)

        // Reset input value
        event.target.value = '';
      };


    //   **** Camera Functions******
    useEffect(() => {
        if (isStreaming) {
          const getVideo = async () => {
            try {
              const stream = await navigator.mediaDevices.getUserMedia({ video: true });
              videoRef.current.srcObject = stream;
            } catch (err) {
              console.error("Error accessing webcam: ", err);
            }
          };
    
          getVideo();
        } else {
          if (videoRef.current && videoRef.current.srcObject) {
            const stream = videoRef.current.srcObject;
            const tracks = stream.getTracks();
    
            tracks.forEach(track => {
              track.stop();
            });
    
            videoRef.current.srcObject = null;
          }
        }
      }, [isStreaming]);
    
    
      const handleStartStop = () => {
        setIsStreaming(prevState => !prevState);
      };

      const stopStream = () => {
        // if (!isStreaming) return; // No stream to stop
        // isStreaming.getTracks().forEach((track) => track.stop());
        // setIsStreaming(null); // Update state to reflect stopped stream

        if (!videoRef.current || !videoRef.current.srcObject) return; // No stream to stop

        const stream = videoRef.current.srcObject;
        stream.getTracks().forEach((track) => track.stop());
        videoRef.current.srcObject = null; // Remove stream from video element
        setIsStreaming(null); // Update state to reflect stopped stream
      };

    //   **** Camera Functions******


    const handleCloseFilePreviewModal = () => {
        setIsFilePreviewModalOpen(false)
        setCapturedImage(null)
        setSelectedImagesVideos(null)
        // setSelectedFile(null)
        setSelectedFiles(null)
    }

    const handleChatInputMessageText = (e) => {
        let text_html = e
        // if(text_html === '<p><br></p>'){
        //     setChatInputMessageText('')
        // }else {
        //     setChatInputMessageText(text_html)
        // }
        text_html = text_html.replace(/^<p><br><\/p>/, ''); 

        setChatInputMessageText(text_html)
        

        // Access the Quill editor instance using the ref
        const editor = quillRef.current.getEditor();
        
        // Get the plain text from the editor
        const plainText = editor.getText();

        let msg = {
            from_username: userInfo.user_username,
            to_chatroom_uuid: chatInfo.chatroom_uuid,
            action: 'draft_message',
            message_text_html: text_html,
            message_text: plainText,
        }

        msg = createMessagingMQTT(msg);
        if(msg.topic && msg.payload)
        {

            MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
        }


    }


     
    // const modules = {
    //     toolbar: false, // Remove the toolbar by setting it to false
    //     keyboard: {
    //       bindings: {
    //         // Custom binding for Enter key to send the message
    //         sendOnEnter: {
    //           key: 'Enter',
    //           handler: (range, context) => {
    //             // Use the native JavaScript event handler to get the event
                
    //             if (!context.shiftKey) {
    //                 addMessage('', 'submitClick'); // Pass the event to sendMessage function
    //               return false; // Prevent the default behavior
    //             }
    //             return true; // Allow default behavior (new line) if Shift is pressed
    //           },
    //         },
    //       },
    //     },
    //   };


    const modules = useMemo(
        () => ({
        toolbar: false, // Remove the toolbar by setting it to false
        keyboard: {
            bindings: {
                // Custom binding for Enter key to send the message
                sendOnEnter: {
                    key: 'Enter',
                    handler: (range, context) => {
                    if (!context.shiftKey) {
                        return false; // Prevent the default behavior
                    }
                    return true; // Allow default behavior (new line) if Shift is pressed
                    },
                },
            },
        },
        clipboard: {
            matchVisual: false, // Disable visual matching of pasted content
        },
        }),[]);



    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            addMessage(event, 'userEnter');
        }
    }, [chatInputMessageText]);

    
  return (
    <AnimatePresence  mode='wait'>
        <div className={`flex flex-col border-t-[0.01em] border-slate-200 dark:border-slate-600 px-2 py-2  ${isMobile ? 'bg-white dark:bg-slate-900':'bg-white dark:bg-slate-900'} items-center`}>
                {replyMessage && Object.keys(replyMessage).length > 0 &&
                    <motion.div 
                        key='replyMessageDiv' 
                        className='flex w-full '
                        initial={{ x: 10, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        <div className='flex mb-1 truncate rounded-l grow' >
                            <div className={`bg-sky-400 dark:bg-sky-600 w-[3px] min-w-[3px] rounded-l-full`} />
                            <div className={`bg-sky-400/5 dark:bg-sky-600/5 rounded-r-lg pl-2 py-1 pr-2 flex flex-col overflow-hidden grow`}>
                                <span className={`text-sky-600 dark:text-sky-500 text-sm`}>{`${replyMessage.user_first_name? replyMessage.user_first_name : ''}`}</span>
                                <span className={`text-gray-500 dark:text-gray-400 text-sm truncate`} >{replyMessage.message_text}</span>
                            </div>
                        </div>
                        <motion.button
                         whileHover={{ scale:1.1 }} 
                         whileTap={{ scale: 0.95  }}  
                         className='ml-2 text-gray-500 dark:text-gray-400 items-center flex '
                         onClick={() => setReplyMessage([])}
                         >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-7 h-7">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            
                        </motion.button>

                    </motion.div>
                }
                {editMessage && Object.keys(editMessage).length > 0 &&
                    <motion.div 
                        key='editMessageDiv' 
                        className='flex w-full '
                        initial={{ x: 10, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        <div className='flex mb-1 truncate rounded-l grow' >
                            <div className='text-sky-600 dark:text-sky-500 px-2 items-center my-auto'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                </svg>
                            </div>
                            <div className={`bg-sky-400 dark:bg-sky-600 w-[3px] min-w-[3px] rounded-l-full`} />
                            <div className={`bg-sky-400/5 dark:bg-sky-600/5 rounded-r-lg pl-2 py-1 pr-2 flex flex-col overflow-hidden grow`}>
                                <span className={`text-sky-600 dark:text-sky-500 text-sm`}>{`Edit Message`}</span>
                                <span className={`text-gray-500 dark:text-gray-400 text-sm truncate`} >{editMessage.message_text}</span>
                            </div>
                        </div>
                        <motion.button
                         whileHover={{ scale:1.1 }} 
                         whileTap={{ scale: 0.95  }}  
                         className='ml-2 text-gray-500 dark:text-gray-400 items-center flex '
                         onClick={() => setEditMessage([])}
                         >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-7 h-7">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            
                        </motion.button>

                    </motion.div>
                }
                <div className="flex w-full">
                    {/* Placing Input outside of headless UI MenuItem because it wasn't triggering properly  */}
                    <input
                        type="file"
                        multiple
                        accept="image/*,video/*"
                        style={{ display: 'none' }}
                        onChange={handleImageVideoChange}
                        ref={hiddenImageVideoInput}
                    />
                    <input
                        type="file"
                        multiple
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        ref={hiddenFileInput}
                    />
                    {/* Placing Input outside of headless UI MenuItem because it wasn't triggering properly  */}
                    
                    <div className={`flex items-center relative ${audioURL && 'hidden'}`}>
                    {isMobile ? (
                        <button className='text-gray-600 dark:text-gray-400 items-center pl-1' onClick={handleFileClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-8 h-8">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
                            </svg>
                        </button>

                    ):(

                        <Menu >
                        <MenuButton as="button" className='text-gray-600 dark:text-gray-400 items-center pl-1' >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-7 h-7">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
                            </svg>
                        </MenuButton>

                        <Transition
                            as={Fragment}
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <MenuItems className="absolute left-3 bottom-4 flex flex-col mt-1 w-[200px] bg-white/90 dark:bg-zinc-900/90 backdrop-blur-lg shadow-iotflows-lg dark:shadow-xs-invert rounded-lg outline-none z-50">
                                <div className="px-1 py-1">
                                    <MenuItem as='div'>
                                            <button
                                            onClick={handleImageVideoClick}
                                            className={`group text-gray-600 dark:text-gray-100 flex w-full items-center rounded-md px-2 py-2 text-sm
                                            hover:bg-zinc-100 hover:dark:bg-zinc-500/30 hover:text-gray-600 hover:dark:text-gray-200`}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="group-hover:scale-110 group-hover:duration-500 w-4 h-4">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                                                </svg>
                                                <span className='pl-2'>Photo or Video</span>
                                            </button>
                                    </MenuItem>
                                    <MenuItem as='div'>
                                        <button
                                            onClick={handleFileClick}
                                            className={`group text-gray-600 dark:text-gray-100 flex w-full items-center rounded-md px-2 py-2 text-sm
                                            hover:bg-zinc-100 hover:dark:bg-zinc-500/30 hover:text-gray-600 hover:dark:text-gray-200`}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="group-hover:scale-110 group-hover:duration-500 w-4 h-4">
                                             <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                            </svg>
                                            <span className='pl-2'>File</span>
                                        </button>
                                    </MenuItem>
                                    <MenuItem>
                                        <button
                                            className={`group text-gray-600 dark:text-gray-100 flex w-full items-center rounded-md px-2 py-2 text-sm
                                            data-[active]:bg-zinc-100 data-[active]:dark:bg-zinc-500/30 data-[active]:text-gray-600 data-[active]:dark:text-gray-200`}
                                            onClick={handleStartStop}
                                        >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="group-hover:scale-110 group-hover:duration-500 w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                                            </svg>

                                            <span className='pl-2'>Camera</span>
                                        </button>
                                    </MenuItem>
                                </div>
                            </MenuItems>
                        </Transition>
                    </Menu>
                    )}
                    </div>
                    <div className={`flex flex-1 min-w-0 items-center ${isMobile && 'mx-2'} ${audioURL && 'hidden'} `}>
                        
                        <ReactQuill
                            ref={quillRef}
                            value={chatInputMessageText}
                            onChange={handleChatInputMessageText} 
                            onKeyDown={handleKeyDown}
                            // modules={{ toolbar: false }} // Disable toolbar
                            modules={modules} // Apply custom keyboard bindings
                            // formats={[]} // Disable all formats if needed
                            placeholder="Write a message..."
                            spellCheck="true"
                            inputProps={{
                                inputMode: 'text', 
                                autoCapitalize: 'sentences', // or 'words' if you want each word capitalized
                                spellCheck: true,
                             }}
                            className={`w-full ${isMobile ? `mobile-custom-quill ${isDark ? 'dark' : 'light'}`:'custom-quill'}  text-gray-600 dark:text-gray-300 placeholder:text-gray-400 dark:placeholder:text-gray-500`}
                            formats={[
                                'header',
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote',
                                'list',
                                'bullet',
                                'link',
                                'image',
                              ]}
                        />
                        {/* <textarea
                            rows="1"
                            ref={textAreaRef}
                            style={{height: '28px'}}
                            className={`w-full font-light text-sm resize-none max-h-52 overflow-y-scroll scrollbar-hide border-0 focus:ring-[0px] 
                                text-gray-600 dark:text-gray-300 placeholder:text-gray-400 dark:placeholder:text-gray-500
                                py-0.5 disabled:cursor-not-allowed dark:bg-slate-900`}
                            value={chatInputMessageText} 
                            // onChange={(e)=> setChatInputMessageText(e.target.value)} 
                            onChange={handleChatInputMessageText} 
                            onKeyDown={handleKeyDown}
                            type="text" 
                            placeholder="Write a message..."
                            spellCheck="true"
                        /> */}
                    </div>
                    <div className={`flex items-center mr-3 ${audioURL && 'hidden'}`}>
                        <EmojiPicker onEmojiSelect={handleInsertEmoji} allEmojis={allEmojis} closeEverything={closeEverything} setCloseEverything={setCloseEverything} />
                    </div>
                    <div className={`flex items-center ${audioURL && 'w-full'}`}>
                        {/* <motion.div
                            key='voiceButton'
                            animate={{
                                x: 0,
                                y: 0,
                                scale: chatInputMessageText ? 0 : 1,
                                rotate: chatInputMessageText ? 0 : 0,
                            }}
                            className='flex items-center'
                        >
                            <button 
                                type='submit'
                                disabled={chatInputMessageText}
                                className='text-gray-600 dark:text-gray-400 disabled:hidden'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-7 h-7">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z" />
                                </svg>
                            </button>
                        </motion.div> */}
                        <RecordVoiceButton chatInputMessageText={chatInputMessageText} setAudioURL={setAudioURL} audioURL={audioURL} userInfo={userInfo} chatInfo={chatInfo}  messages={messages} setMessages={setMessages}/>
                        <motion.div
                            key='submitButton'
                            animate={{
                                x: 0,
                                y: 0,
                                scale: !chatInputMessageText ? 0 : 1,
                                rotate: !chatInputMessageText ? 0 : 0,
                            }}
                            className='flex items-center'
                        >
                            <button 
                                onClick={(e) => addMessage(e, 'submitClick')}
                                disabled={!chatInputMessageText}
                                className='text-blue-500 disabled:hidden '
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                    <path d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
                                </svg>
                            </button>
                        </motion.div>
                    </div>
                </div>
                {(isStreaming ) &&
                    <CameraModal btnType='hideBtn' videoRef={videoRef} canvasRef={canvasRef} isStreaming={isStreaming} setIsStreaming={setIsStreaming} stopStream={stopStream} remoteOpen={isStreaming} capturedImage={capturedImage} setCapturedImage={setCapturedImage} setIsFilePreviewModalOpen={setIsFilePreviewModalOpen} />
                }
                {isFilePreviewModalOpen && 
                    <FilePreviewModal btnType='hideBtn' remoteOpen={isFilePreviewModalOpen} 
                        replyMessage={replyMessage} 
                        setReplyMessage={setReplyMessage} 
                        handleCloseFilePreviewModal={handleCloseFilePreviewModal} 
                        capturedImage={capturedImage} 
                        selectedImagesVideos={selectedImagesVideos}
                        setSelectedImagesVideos={setSelectedImagesVideos}
                        selectedFiles={selectedFiles} 
                        setSelectedFiles={setSelectedFiles} 
                        setIsFilePreviewModalOpen={setIsFilePreviewModalOpen} 
                        messages={messages} 
                        setMessages={setMessages} 
                        userInfo={userInfo} 
                        allEmojis={allEmojis} 
                        closeEverything={closeEverything} 
                        setCloseEverything={setCloseEverything} 
                        chatInfo={chatInfo} 
                        setRemoveMessageObject={setRemoveMessageObject}
                    />
                }

        </div>
    </AnimatePresence>
  )
}

export default ChatInput