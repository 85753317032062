import React, { useEffect, useState } from 'react'
import MobileRightDrawer from '../components/MobileRightDrawer'
import { useLocation, useSearchParams } from 'react-router-dom'
import WorkOrderViewSelector from '../components/filterButtons/WorkOrderFilters/WorkOrderViewSelector'
import { motion, AnimatePresence } from 'framer-motion'
import WorkOrderTableView from './tableViewComponents/WorkOrderTableView'
import { globalSelectedOrganization } from '../atoms/orgStates'
import { getData } from '../ApiCalls/DataApis'
import { apiLink } from '../ApiCalls/ApisVariables'
import { useRecoilValue } from 'recoil'
import NewWorkOrderModal from '../components/modals/workOrderModals/NewWorkOrderModal'
import SelectedWorkOrderPage from './SelectedWorkOrderPage'


function WorkOrdersPage({userInfo}) {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const [searchParams, setSearchParams] = useSearchParams()
    const [isMobile, setIsMobile] = useState(false)
    const [selectedWorkOrderUuid, setSelectedWorkOrderUuid] = useState('')
    const location = useLocation();
    const [workOrdersList, setWorkOrdersList] = useState([])

    useEffect(() => {
        let currentIsMobile = searchParams.get('platform')
        let selectedWorkOrder = searchParams.get('selected-wo')
        setSelectedWorkOrderUuid(selectedWorkOrder)
        if(currentIsMobile === 'mobile'){
          setIsMobile(true)
        }else{
          setIsMobile(false)
        }
      },[location])


    useEffect(() => {
        if(selectedOrganization){
            let selectedWorkOrder = searchParams.get('selected-wo')

            if(selectedWorkOrder) return
            getWorkOrders()
        }
    }, [selectedOrganization, location])

    const getWorkOrders = () => {

        if(selectedOrganization){
            let currentSearch = location.search
            console.log('selectedOrganization', selectedOrganization)
            getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/workorders' + currentSearch)
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            console.log('Workorders ', data.data)
                            setWorkOrdersList(data.data)
                            
                        }else{
                            setWorkOrdersList([])
                        }
                    }else{
                        setWorkOrdersList([])
                    }
                })
            getData(apiLink + '/v1/workorder_statuses')
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            console.log('workorder_statuses ', data.data)
                            // setWorkOrdersList(data.data)
                        }else{
                            // setWorkOrdersList([])
                        }
                    }else{
                        // setWorkOrdersList([])
                    }
                })
        }

    }



  return (
    <div className={`pt-4 pl-10 pr-8  ${isMobile ? 'max-h-[calc(100vh-0.5rem)]' : ' max-h-[calc(100vh-5rem)]'} overflow-scroll scrollbar-hide`}>
        <AnimatePresence mode='wait'>
            {selectedWorkOrderUuid && selectedWorkOrderUuid.length > 0 ? (
                <motion.div 
                    key={'SelectedWorkOrderContainer'}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                  <SelectedWorkOrderPage userInfo={userInfo} />
              </motion.div>
            ): (
                <motion.div 
                    key={'WorkOrderContainer'}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                <div className={`${isMobile ? 'flex':'flex md:hidden'} justify-end mr-4`}>
                    <MobileRightDrawer >
                        <div className='flex flex-col space-y-3'>
                        {/* Filters for workorders */} 
                        </div>

                    </MobileRightDrawer>
                </div>
                {/* Filter section */}
                <div className={`${isMobile && 'hidden'} flex flex-col xl:flex-row xl:items-center mb-4 justify-between `}>
                    {/* Filters for workorders */} 
                    <WorkOrderViewSelector />
                    <NewWorkOrderModal userInfo={userInfo} btnType='standardBtn' getWorkOrders={getWorkOrders} />
                </div>
                <div className='mt-2'>

                    {showView('table_view', isMobile, selectedOrganization, workOrdersList, setWorkOrdersList)}

                </div>
                </motion.div>
            )}
        </AnimatePresence>
    </div>
  )
}

export default WorkOrdersPage


const showView = (view, isMobile, selectedOrganization, workOrdersList, setWorkOrdersList ) =>{
    const allLinks = {
        todo_view: { content: <div>To do </div>},
        table_view: { content: <WorkOrderTableView isMobile={isMobile} selectedOrganization={selectedOrganization} workOrdersList={workOrdersList} setWorkOrdersList={setWorkOrdersList}  /> },
        calendar_view: { content: <div>Calendar </div>},
        workload_view: { content: <div>Workload </div>},
    };
    

    return ( 
        <AnimatePresence mode='wait'>
        <motion.div key={view}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
          className='pb-10'
        >
            {allLinks[view].content} 
        </motion.div>
      </AnimatePresence>
    )  
}