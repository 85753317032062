import React, { useEffect, useState } from 'react'
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid"
import { getData } from '../../ApiCalls/DataApis'
import { apiLink } from '../../ApiCalls/ApisVariables'
import { globalSelectedOrganization } from '../../atoms/orgStates'
import { useRecoilValue } from 'recoil'
import OptimizeLargeImage from '../../components/OptimizeLargeImage';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import SectionedPieChartSmall from '../../components/charts/AdvancedReportsCharts/SectionedPieChartSmall'
import StackedBarChartSmall from '../../components/charts/AdvancedReportsCharts/StackedBarChartSmall'

function AdvanceReportTableRow({asset, toggleShowPercentData, toggleOnlyUptimeData}) {
    const location = useLocation();
    const navigate = useNavigate();
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const [oeeChartData, setOeeChartData] = useState([])
    const [isMobile, setIsMobile] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
      

        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
  
    },[])

    useEffect(() => {
        getDailyOEE()
    },[])

    const getDailyOEE = () => {
        try {
            if(selectedOrganization){
                const startTime = performance.now();

                let currentSearch = location.search
                getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/daily-oee' + currentSearch )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            let chartData = []
                            data.data.oee_days.map(oeeData => {
                                let time = new Date(oeeData.d)
                                let seconds = time.getTime()
                                let oeePercent = 0
                                if(oeeData.oee){
                                    oeePercent = (oeeData.oee*100).toFixed(1)
                                    oeePercent = parseFloat(oeePercent)
                                }
                                let array = [seconds, oeePercent]
                                chartData.push(array)
                            })
                            setOeeChartData(chartData)

                        }
                    }
                })

            }
        } catch (error) {
            
        }
    }

    const addCommasInNumbers = (num) => {
        if(num){
            const out = num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

            // const out = num.toLocaleString();
    
            return out
        }else{
            return '0'
        }
    }



  return (
        <tr className='w-full overflow-hidden' >
            <td 
                onClick={() => navigate(`/${selectedOrganization}/assets/selected-asset/${asset.asset_uuid}`)}
                className='cursor-pointer text-sm font-normal bg-slate-50 dark:bg-slate-800 text-gray-600 dark:text-gray-300 p-2 rounded-l-full'
            >
                <div className={`rounded-full bg-white z-10 h-24 w-24 `}>
                    <OptimizeLargeImage alt={asset.machine_name} source={asset.asset_custom_picture_url  ? asset.asset_custom_picture_url : asset.machine_picture_url} size='xl' />
                </div>
            </td>
            <td 
                onClick={() => navigate(`/${selectedOrganization}/assets/selected-asset/${asset.asset_uuid}`)}
                className='cursor-pointer text-sm font-normal bg-slate-50  dark:bg-slate-800 text-gray-600 dark:text-gray-300 p-2'
            >
                <div className='flex flex-col truncate max-w-[100px] lg:max-w-[150px] xl:max-w-[225px] 2xl:max-w-[275px]'>
                    <p className='text-left truncate text-lg text-gray-600 dark:text-gray-300 font-medium'>{asset.asset_custom_name  ? asset.asset_custom_name :asset.machine_name}</p>
                    <p className='text-left truncate text-base text-gray-500 dark:text-gray-400 font-medium'>{asset.asset_custom_identifier  ? asset.asset_custom_identifier :asset.asset_uuid}</p>
                    <p className='text-left truncate text-base text-gray-500 dark:text-gray-400 font-normal'>{asset.department_name}</p>
                </div>
            </td>
            <td className='bg-slate-50 dark:bg-slate-800'>
                <StackedBarChartSmall totalUtilizations={asset.asset_utilizations} toggleShowPercentData={toggleShowPercentData} toggleOnlyUptimeData={toggleOnlyUptimeData} /> 
                
            </td>
            <td className='bg-slate-50 dark:bg-slate-800 overflow-hidden items-center justify-center px-1 py-1'>
                <SectionedPieChartSmall downtimePercent={asset.total_downtime_hours} uptimePercent={asset.total_uptime_hours} unknownPercent={asset.total_unknown_hours} totalUtilizationPercent={asset.total_utilization_percentage}/>
            </td>
            <td className='bg-slate-50 dark:bg-slate-800 rounded-r-xl pl-1 pr-4 '>
                <div className='flex flex-col'>
                    <div className='text-xl text-blue-600 dark:text-sky-500 font-medium flex items-center justify-end' >
                        {`${addCommasInNumbers(asset.total_uptime_hours)}h`}
                        {/* <ArrowSmUpIcon className='h-6 w-6 text-blue-600 dark:text-sky-500' /> */}
                    </div>
                    <div className='text-xl text-rose-600 dark:text-rose-500 font-medium flex items-center justify-end' >
                        {`${addCommasInNumbers(asset.total_downtime_hours)}h`}
                        {/* <ArrowSmDownIcon className='h-6 w-6 text-rose-600 dark:text-rose-500' /> */}
                    </div>
                    <div className='text-xl text-gray-500 dark:text-gray-400 font-medium flex items-center justify-end' >
                        {`${addCommasInNumbers(asset.total_unknown_hours)}h`}
                        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className='h-6 w-6'>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
                        </svg> */}
                    </div>
                </div> 
            </td>
        </tr>
  )
}

export default AdvanceReportTableRow

