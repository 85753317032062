import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import HighchartsReact from "highcharts-react-official";
import HighchartsStock from 'highcharts/highstock';
import highchartsMore from "highcharts/highcharts-more.js";
import Highcharts from "highcharts/highcharts.js";
import { darkModeState } from '../../../atoms/darkModeState';

highchartsMore(Highcharts);

function RanpakStackedBarChartSmall({totalUtilizations}) {
    const [hourCountBarChartOptions, setHourCountBarChartOptions] = useState({
        credits: {enabled: false},
        chart: {
            // type: 'areaspline',
            type: 'column',
            zoomType: 'x',
            backgroundColor:'transparent',
            // height:'110px',
        },
        time: {
            useUTC: false
          },
        title:{
            text:''
        },
    })
    const isDark = useRecoilValue(darkModeState); 

    useEffect(() => {
        let red = 'rgba(220, 38, 38, 0.3)'
        let sky = '#0ea5e9'
        let skyLight = 'rgba(14, 165, 233,0.5)'
        let gray = 'rgba(107, 114, 128, 0.3)'
        
        if(!isDark){
            red = 'rgba(220, 38, 38, 0.5)' //red 600
            gray = '#d1d5db'
            sky = '#60a5fa'
            skyLight = 'rgba(96, 165, 250,0.5)'
        }

        if(totalUtilizations && Object.keys(totalUtilizations).length > 0){ 
            let uptimeData = [...totalUtilizations.uptime_hours]
          

            setHourCountBarChartOptions({
                credits: {enabled: false},
                chart: {
                    type: 'column',
                    // type: 'areaspline',
                    zoomType: 'x',
                    backgroundColor:'transparent',
                    // height:'110px',
                },
                time: {
                    useUTC: false
                  },
                title:{
                    text:''
                },
                xAxis: {
                    categories: totalUtilizations.categories,
                    // type: 'datetime',
                    labels: {
                        style: {
                            color: isDark? '#d1d5db':'#4b5563'
                        }
                    }
                },
                // xAxis: {
                //     categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun']
                // },
                yAxis: [{
                    visible: false,
                    // max: 10
                }, {
                    visible: false,
                    // max: 10,
                    opposite: true
                }],
                legend: {
                    enabled: false 
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        backgroundColor:'#F3F4F6',
                        dataLabels: {
                            enabled: true,
                            style: {
                                // fontSize: '18px',
                                color: isDark ? '#fff' :'#fff',
                                // color: isDark ? '#fff' :'rgba(75, 85, 99, 0.5)',
                            },
                        },
                        borderWidth:0
                    },
                    series: {
                        borderRadius: {
                            radius: 5
                        },
                        dataLabels: {
                            enabled: true,
                            format: '{y} feet'
                        },
                        stacking: 'normal'
                    }
                },
                series: [{
                        name: '',
                        // data: uptimeSeries,
                        data: uptimeData,
                        marker: {
                            symbol: 'circle'
                        },
                        color: {
                            linearGradient: {
                              x1: 0,
                              x2: 0,
                              y1: 0,
                              y2: 1
                            },
                            stops: [
                              [0, skyLight],
                            //   [1, 'rgba(22, 163, 74,0.5)']
                            // [0, Highcharts.getOptions().colors[0]],
                            // [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                            [1, sky]
                            ]
                          }
                    },{
                        name: 'SeriesToExclude',
                        type: 'spline',
                        dashStyle:'longdash',
                        data: uptimeData,
                        dataLabels: {
                            enabled: false // Disable data labels for this specific series
                        }
                    }],
                    tooltip: {
                        formatter: function () {
                            // The first returned item is the header, subsequent items are the
                            // points
                            let icon = 'feet'
                            var tooltipText = '<b>' + this.x + '</b>';
                            this.points.forEach(point => {
                                if (point.series.name !== 'SeriesToExclude') {
                                    tooltipText += '<br/>' + point.series.name + '' + point.y + ' ' + icon;
                                }
                            });
                            return tooltipText;
                        },
                        shared: true
                    },
            })
        }


    }, [isDark, totalUtilizations])

    
  return (
    <div className='mb-[-15px]'>
        <HighchartsReact
            constructorType={"chart"}
            containerProps={{ style: { height: '120px', maxWidth:'100%'} }} 
            highcharts={HighchartsStock}
            options={hourCountBarChartOptions}               
        />
    </div>
  )
}

export default RanpakStackedBarChartSmall