import React from 'react'

function FilterButtons({filters}) {
  return (
    <div className='hidden space-x-2  lg:inline-flex'>
        {filters.map(filter => (
                <div key={filter.name} className='border border-blue-600 border-dashed rounded-full flex pl-1 pr-3 py-0.5 items-center'>
                    
                    <div className='bg-white w-3 h-3 rounded-full m-1'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-[-4px] mt-[-4px] text-blue-600 " viewBox="0 0 20 20" fill="currentColor" >
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div className='pl-1 text-sm text-gray-600 dark:text-gray-300'>
                        {filter.name}
                    </div>
                    {filter.selected.length > 0 &&
                    <div className='flex items-center'>
                        <div className='text-blue-600 pl-1 dark:text-blue-400 text-sm'>|</div>
                        {filter.selected.map((filtered,index) => (
                            <div key={index} className='flex '>
                            {index > 0 && <div className='text-blue-600 dark:text-blue-400 text-sm'>,</div>}
                            <div className='text-blue-600 dark:text-blue-400 pl-0.5 text-sm '>{filtered}</div>
                            </div>
                        ))}
                    </div>
                    }

                </div>
            ))}
    </div>
  )
}

export default FilterButtons