import React from 'react'
import { Arrow, Circle } from 'react-konva';

function ArrowObject({obj, objects, handleSelect, setObjects, selectedObject, setSelectedObject, i }) {
   

      
  return (
    <div>
    <React.Fragment>
        <Arrow
            key={obj.id}
            id={obj.id}
            {...obj}
            points={obj.points}
            fill={obj.fill}
            stroke={obj.stroke}
            strokeWidth={obj.strokeWidth}
            pointerLength={obj.pointerLength}
            pointerWidth={obj.pointerWidth}
            hitStrokeWidth={15}  // Increase the hit area to make the line easier to click
            strokeScaleEnabled={false}
            draggable
            onClick={(e) => handleSelect(e, obj)}
            onTap={(e) => handleSelect(e, obj)}
            onDragEnd={(e) => {
                const arrow = e.target;
            
                // Calculate the delta of the drag
                const dx = arrow.x();
                const dy = arrow.y();
            
                // Adjust points by the delta
                const newPoints = obj.points.map((point, index) =>
                    index % 2 === 0 ? point + dx : point + dy
                );
            
                // const roundedPoints = newPoints.map(value => Math.round(value));
                // Reset arrow position to (0, 0) after the drag
                const newObjects = objects.slice();
                newObjects[i] = {
                    ...obj,
                    points: newPoints,
                //   x: 0, // Reset X position
                //   y: 0, // Reset Y position
                };
            
                setObjects(newObjects);
                setSelectedObject(newObjects[i])
                // Reset the node's position on the canvas
                arrow.position({ x: 0, y: 0 });
                }}
            />

     {/* Invisible Circle for easier clicking */}
    <Circle
        x={obj.points[0]}
        y={obj.points[1]}
        radius={25}  // Larger radius for easier selection
        fill="transparent"
        draggable={false}  // This one should not be draggable, only for hit detection
        onClick={(e) => handleSelect(e, obj)}
        onTap={(e) => handleSelect(e, obj)}
    />
  
    {/* Render Control Point for First Endpoint (x1, y1) */}
    <Circle
      x={obj.points[0]}
      y={obj.points[1]}
      radius={5}
      fill={selectedObject && selectedObject.id === obj.id ? obj.stroke:"transparent"}
      draggable
      onDragMove={(e) => {
        handleSelect(e, obj)
        const newX1 = e.target.x();
        const newY1 = e.target.y();
  
        // Update the points array with the new values for the first point
        const newPoints = [
          newX1, newY1,
          obj.points[2], obj.points[3]  // Keep the second point unchanged
        ];
  
        // Update state
        const newObjects = objects.map((object) =>
          object.id === obj.id ? { ...object, points: newPoints } : object
        );
  
        setObjects(newObjects);
        setSelectedObject({ ...obj, points: newPoints });
      }}
    />

    {/* Invisible Circle for easier clicking */}
    <Circle
        x={obj.points[2]}
        y={obj.points[3]}
        radius={25}  // Larger radius for easier selection
        fill="transparent"
        draggable={false}  // This one should not be draggable, only for hit detection
        onClick={(e) => handleSelect(e, obj)}
        onTap={(e) => handleSelect(e, obj)}
    />
  
    {/* Render Control Point for Second Endpoint (x2, y2) */}
    <Circle
      x={obj.points[2]}
      y={obj.points[3]}
      radius={5}
      fill={selectedObject && selectedObject.id === obj.id ? obj.stroke:"transparent"}
      draggable
      onDragMove={(e) => {
        handleSelect(e, obj)
        const newX2 = e.target.x();
        const newY2 = e.target.y();
  
        // Update the points array with the new values for the second point
        const newPoints = [
          obj.points[0], obj.points[1],  // Keep the first point unchanged
          newX2, newY2
        ];
  
        // Update state
        const newObjects = objects.map((object) =>
          object.id === obj.id ? { ...object, points: newPoints } : object
        );
  
        setObjects(newObjects);
        setSelectedObject({ ...obj, points: newPoints });
      }}
    />
  </React.Fragment>
  </div>
  )
}

export default ArrowObject