import React, { useEffect, useState } from 'react'


const loadImage = (setImageDimensions, imageUrl) => {
    const img = new Image();
    img.src = imageUrl;
  
    img.onload = () => {
      setImageDimensions({
        height: img.height,
        width: img.width
      });
    };
    img.onerror = (err) => {
      console.log("img error");
    //   console.error(err);
    };
  };

function OptimizePartImage({alt, source, size}) {
    const [imageDimensions, setImageDimensions] = useState({});
    const [imageWidth, setImageWidth] = useState(false);
    const [imageHeight, setImageHeight] = useState(false);

    useEffect(() => {
        loadImage(setImageDimensions, source);
    }, [])

    useEffect(() => {
        if(imageDimensions.height > imageDimensions.width){
            setImageHeight(true)
            setImageWidth(false)
        }else{
            setImageHeight(false)
            setImageWidth(true)
        }

    }, [imageDimensions])


  return (
    <div className='my-auto'>
        {Object.keys(imageDimensions).length > 0 && imageHeight && 
            <img
                alt={alt}
                src={source}
                className={`${size == 'small' &&'h-8'} ${size == 'medium' &&'h-10'} ${size == 'large' && 'h-12'} ${size == 'xl' && 'h-16'} rounded-full my-auto`}
            />
        }
        {Object.keys(imageDimensions).length > 0 && imageWidth && 
            <img
                alt={alt}
                src={source}
                className={`${size == 'small' &&'w-8'} ${size == 'medium' &&'w-10'} ${size == 'large' && 'w-12'} ${size == 'xl' && 'w-16'} rounded-full my-auto`}
            />
        }
        {Object.keys(imageDimensions).length == 0 && 
            // <GearIcon
            //     className={`${size == 'small' &&'w-8'} ${size == 'medium' &&'w-10'} ${size == 'large' && 'w-12'} ${size == 'xl' && 'w-16'} rounded-full dark:text-gray-400 text-gray-600 mx-auto`}
            //     aria-hidden="true"
            // />
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${size == 'small' &&'w-8'} ${size == 'medium' &&'w-10'} ${size == 'large' && 'w-12'} ${size == 'xl' && 'w-16'} rounded-full dark:text-gray-400 text-gray-600 mx-auto `}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
            </svg>
        } 
    </div>
  )
}

export default OptimizePartImage



function GearIcon(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      
      
    )
}