import { Dialog, Transition, DialogTitle, TransitionChild, DialogPanel } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { motion, AnimatePresence } from "framer-motion";
import { getData, putData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import {LightTooltip} from '../../../styles/Tooltip'
import Switch from '@mui/material/Switch';


function SetCurrentOperationModal({btnType, remoteOpen, asset}) {
    let [isOpen, setIsOpen] = useState(false)
    const isDark = useRecoilValue(darkModeState);
    const [loading, setLoading] = useState(false)
    const [loadingUptimeNotification, setLoadingUptimeNotification] = useState(false)
    const [toggleEmailNotifications, setToggleEmailNotifications] = useState(true)
    const [toggleUptimeNotification, setToggleUptimeNotification] = useState(true)
    // const [toggleDeviceOfflineNotification, setToggleDeviceOfflineNotification] = useState(true)
    const [toggleAbnormalShockNotification, setToggleAbnormalShockNotification] = useState(true)
    // const [loadingDeviceOffline, setLoadingDeviceOffline] = useState(false)
    const [loadingAbnormalShockNotification, setLoadingAbnormalShockNotification] = useState(false)
    const [isNotificationOn, setIsNotificationOn] = useState(true)
    const [shockThreshhold, setShockThreshhold] = useState(2)


    useEffect(() => {
      if(remoteOpen){
        setIsOpen(true)
      }
    },[remoteOpen])

    useEffect(() => {
      try{
        getData(apiLink + '/v1/organization_assets/' + asset.asset_uuid + '/notifications/down/preference')
            .then(async response => {
                if(response && response.ok ){
                const data = await response.json();
                if(data && data.data){
                    if(Object.keys(data.data).length > 0 && data.data[0].asset_downtime_subscribers_is_email_subscribed){
                      setToggleEmailNotifications(true)
                    }else{
                      setToggleEmailNotifications(false)
                    }
                } 
                }
            });

        getData(apiLink + '/v1/organization_assets/' + asset.asset_uuid + '/notifications/up/preference')
            .then(async response => {
                if(response && response.ok ){
                const data = await response.json();
                if(data && data.data){
                    if(Object.keys(data.data).length > 0 && data.data[0].asset_downtime_subscribers_asset_up_is_email_subscribed){
                      setToggleUptimeNotification(true)
                    }else{
                      setToggleUptimeNotification(false)
                    }
                } 
                }
            });
        getData(apiLink + '/v1/organization_assets/' + asset.asset_uuid + '/notifications/shock/preference')
            .then(async response => {
                if(response && response.ok ){
                const data = await response.json();
                if(data && data.data){
                  
                    if(Object.keys(data.data).length > 0 && data.data[0].node_shock_threshold){
                      setShockThreshhold(data.data[0].node_shock_threshold)
                    }

                    if(Object.keys(data.data).length > 0 && data.data[0].asset_shock_subscribers_is_email_subscribed){
                      setToggleAbnormalShockNotification(true)
                    }else{
                      setToggleAbnormalShockNotification(false)
                    }

                } 
                }
            });
      } catch (e) {
        console.log(e.message)
      }
    }, [asset])
    

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  useEffect(() => {
    if(toggleEmailNotifications || toggleAbnormalShockNotification){
      setIsNotificationOn(true)
    }else {
      setIsNotificationOn(false)
    }
  }, [toggleEmailNotifications, toggleAbnormalShockNotification])


  const handleChangeDowntimeNotification = (e) => {
    let status = e.target.checked? 'ON':'OFF'
    setToggleEmailNotifications(e.target.checked)
    if(!loading){
        setLoading(true)
        putData(apiLink + '/v1/organization_assets/' + asset.asset_uuid + '/notifications/down/preference' , 
        {'asset_downtime_subscribers_is_email_subscribed': e.target.checked})
        .then(async response => {
            // const data = await response.json();
            if(response.ok){
                setToggleEmailNotifications(e.target.checked)
                
                setLoading(false)
                toast.success(`Downtime notifications are ${status}`)
            }
            else{
                try{
                    const data = await response.json();
                    setLoading(false)
                    toast.error(data.message)
                }catch (e){
                    toast.error('Something went wrong')
                    setLoading(false)
                }
            }
        })
    }
    if(!e.target.checked){
      if(!loadingUptimeNotification){
        setLoadingUptimeNotification(true)
        putData(apiLink + '/v1/organization_assets/' + asset.asset_uuid + '/notifications/up/preference' , 
        {'asset_downtime_subscribers_asset_up_is_email_subscribed': e.target.checked})
        .then(async response => {
            // const data = await response.json();
            if(response.ok){
                setToggleUptimeNotification(e.target.checked)
                setLoadingUptimeNotification(false)
                toast.success(`Uptime notifications are ${status}`)
            }
            else{
                try{
                    const data = await response.json();
                    setLoadingUptimeNotification(false)
                    toast.error(data.message)
                }catch (e){
                    toast.error('Something went wrong')
                    setLoadingUptimeNotification(false)
                }
            }
        })
      }
    }
}

const handleChangeUptimeNotification = (e) => {
  let getUptimeNotification = e.target.checked? 'ON':'OFF'
  setToggleUptimeNotification(e.target.checked)
  if(!loadingUptimeNotification){
    setLoadingUptimeNotification(true)
    putData(apiLink + '/v1/organization_assets/' + asset.asset_uuid + '/notifications/up/preference' , 
    {'asset_downtime_subscribers_asset_up_is_email_subscribed': e.target.checked})
    .then(async response => {
        // const data = await response.json();
        if(response.ok){
            setToggleUptimeNotification(e.target.checked)
            setLoadingUptimeNotification(false)
            toast.success(`Uptime notifications are ${getUptimeNotification}`)
        }
        else{
            try{
                const data = await response.json();
                setLoadingUptimeNotification(false)
                toast.error(data.message)
            }catch (e){
                toast.error('Something went wrong')
                setLoadingUptimeNotification(false)
            }
        }
    })
  }
}

// const handleChangeDeviceOfflineNotification = (e) => {
//   let getDeviceOfflineNotification = e.target.checked? 'ON':'OFF'
//   setToggleDeviceOfflineNotification(e.target.checked)
//   if(!loadingDeviceOffline){
//     setLoadingDeviceOffline(true)
//     putData(apiLink + '/v1/organization_assets/' + asset.asset_uuid + '/notifications/shock/preference' , 
//     {'asset_shock_subscribers_is_email_subscribed': e.target.checked})
//     .then(async response => {
//         // const data = await response.json();
//         if(response.ok){
//             setToggleDeviceOfflineNotification(e.target.checked)
//             setLoadingDeviceOffline(false)
//             toast.success(`Abnormal shock detection notifications are ${getDeviceOfflineNotification}`)
//         }
//         else{
//             try{
//                 const data = await response.json();
//                 setLoadingDeviceOffline(false)
//                 toast.error(data.message)
//             }catch (e){
//                 toast.error('Something went wrong')
//                 setLoadingDeviceOffline(false)
//             }
//         }
//     })
//   }
// }
const handleChangeAbnormalShockNotification = (e) => {
  let getAbnormalShockNotification = e.target.checked? 'ON':'OFF'
  setToggleAbnormalShockNotification(e.target.checked)
  if(!loadingAbnormalShockNotification){
    setLoadingAbnormalShockNotification(true)
    putData(apiLink + '/v1/organization_assets/' + asset.asset_uuid + '/notifications/shock/preference' , 
    {'asset_shock_subscribers_is_email_subscribed': e.target.checked})
    .then(async response => {
        // const data = await response.json();
        if(response.ok){
            setToggleAbnormalShockNotification(e.target.checked)
            setLoadingAbnormalShockNotification(false)
            toast.success(`Abnormal shock detection notifications are ${getAbnormalShockNotification}`)
        }
        else{
            try{
                const data = await response.json();
                setLoadingAbnormalShockNotification(false)
                toast.error(data.message)
            }catch (e){
                toast.error('Something went wrong')
                setLoadingAbnormalShockNotification(false)
            }
        }
    })
  }
}


  return (
    <div className='scrollbar-hide'>
      <div className="flex items-center justify-center">
      {btnType ==='standardBtn' &&
        <LightTooltip title="Set the upcoming operation being performed on this machine">
          <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
              {/* <PlusSmIcon className='btnMainIcon' /> */}
              {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className='btnMainIcon '>
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg> */}
              <span className='btnMainText pl-2'>Set Upcoming Operation</span>
          </motion.button>
        </LightTooltip>
        }
      {btnType ==='iconBtn' && !isNotificationOn &&
      <LightTooltip title="Asset Notifications">
        <motion.button  whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.90 }} className={`${isDark?'text-gray-400 hover:text-gray-300':'text-gray-500 hover:text-gray-700'} cursor-pointer flex ml-auto`} onClick={openModal}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8 sm:w-5 sm:h-5 md:w-6 md:h-6">
            <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM20.57 16.476c-.223.082-.448.161-.674.238L7.319 4.137A6.75 6.75 0 0118.75 9v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206z" />
            <path fillRule="evenodd" d="M5.25 9c0-.184.007-.366.022-.546l10.384 10.384a3.751 3.751 0 01-7.396-1.119 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z" clipRule="evenodd" />
          </svg>
        </motion.button>
      </LightTooltip>
      }
      {btnType ==='iconBtn' && isNotificationOn &&
      <LightTooltip title="Asset Notifications">
        <motion.button  whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.90 }} className={`${isDark?'text-blue-400 hover:text-blue-300':'text-blue-400 hover:text-blue-500'} cursor-pointer flex ml-auto`} onClick={openModal}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8 sm:w-5 sm:h-5 md:w-6 md:h-6">
          <path fillRule="evenodd" d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z" clipRule="evenodd" />
        </svg>
        </motion.button>
      </LightTooltip>
      }
        {btnType ==='hideBtn' &&
          <div className='w-0 h-0'>{''}</div>
          }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 scrollbar-hide" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto scrollbar-hide">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className={`w-full max-w-xl overflow-scroll scrollbar-hide transform rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <DialogTitle
                    as="h3"
                    className={`mb-1 p-4 text-xl font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}
                  >
                    Asset Notifications
                  </DialogTitle>
                  <AnimatePresence mode='wait'>
                    <motion.div
                    key='notificationOptions'
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className='px-4 pb-6 pt-4 mt-2 max-h-[500px] overflow-y-scroll scrollbar-hide grid grid-cols-4 '
                    >
                      <div className={`col-span-3 border-b-[0.01em] pb-2 ${isDark?' border-gray-700/75':' border-gray-200/75'}`} />
                      <div className={`col-span-1 border-b-[0.01em] pb-2 ${isDark?'text-gray-400 border-gray-700/75':'text-gray-600 border-gray-200/75'} text-center `}><span className=' text-base'>Email</span></div>
                      <div className={`col-span-3 border-b-[0.01em] py-4 pl-2 ${isDark?' border-gray-700/75':' border-gray-200/75'} flex flex-col`}>
                        <p className={`${isDark?'text-gray-300 ':' text-gray-600 '} text-base font-semibold`}>{`Machine Down`}</p>
                        <p className={`${isDark?'text-gray-300 ':' text-gray-600 '} text-sm font-normal `}>
                          {`Asset downtime notification method - Time: ${asset.asset_alert_downtime_after_min} min(s)`}
                        </p>
                      </div>
                      <div className={`col-span-1 flex border-b-[0.01em] py-4 ${isDark?' border-gray-700/75':' border-gray-200/75'} `}>
                          <div className='relative mx-auto my-auto'>
                            <Switch
                                color="primary"
                                checked={toggleEmailNotifications}
                                onChange={handleChangeDowntimeNotification}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            {loading && 
                              <div >
                                  <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                  </svg>
                              </div>
                            }
                          </div>
                      </div>
                      <div className={`col-span-3 border-b-[0.01em] py-4 pl-2 ${isDark?' border-gray-700/75':' border-gray-200/75'} flex flex-col`}>
                        <p className={`${isDark?'text-gray-300 ':' text-gray-600 '} text-base font-semibold`}>{`Machine Up`}</p>
                        <p className={`${isDark?'text-gray-300 ':' text-gray-600 '} text-sm font-normal `}>
                          {`Get notification once asset is running again`}
                        </p>
                      </div>
                      <div className={`col-span-1 flex border-b-[0.01em] py-4 ${isDark?' border-gray-700/75':' border-gray-200/75'} `}>
                          <div className='relative mx-auto my-auto'>
                          <Switch
                                color="primary"
                                checked={toggleUptimeNotification}
                                onChange={handleChangeUptimeNotification}
                                inputProps={{ 'aria-label': 'controlled' }}
                                disabled={!toggleEmailNotifications}
                            />
                            {loadingUptimeNotification && 
                              <div >
                                  <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                  </svg>
                              </div>
                            }
                          </div>
                      </div>
                      {/* <div className={`col-span-3 border-b-[0.01em] py-4 pl-2 ${isDark?' border-gray-700/75':' border-gray-200/75'} flex flex-col`}>
                        <p className={`${isDark?'text-gray-300 ':' text-gray-600 '} text-base font-semibold`}>{`Device Offline`}</p>
                        <p className={`${isDark?'text-gray-300 ':' text-gray-600 '} text-sm font-normal `}>
                          {`Get notification once device is offline longer than 5 minutes`}
                        </p>
                      </div>
                      <div className={`col-span-1 flex border-b-[0.01em] py-4 ${isDark?' border-gray-700/75':' border-gray-200/75'} `}>
                          <div className='relative mx-auto my-auto'>
                          <Switch
                                color="primary"
                                checked={toggleDeviceOfflineNotification}
                                onChange={handleChangeDeviceOfflineNotification}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            {loadingDeviceOffline && 
                              <div >
                                  <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                  </svg>
                              </div>
                            }
                          </div>
                      </div> */}
                      <div className={`col-span-3 border-b-[0.01em] py-4 pl-2 ${isDark?' border-gray-700/75':' border-gray-200/75'} flex flex-col`}>
                        <p className={`${isDark?'text-gray-300 ':' text-gray-600 '} text-base font-semibold`}>{`Abnormal Shock Detection`}</p>
                        <p className={`${isDark?'text-gray-300 ':' text-gray-600 '} text-sm font-normal `}>
                          {`Get notification once asset experiences abnormal shocks. Vibration > ${shockThreshhold}g`}
                        </p>
                      </div>
                      <div className={`col-span-1 flex border-b-[0.01em] py-4 ${isDark?' border-gray-700/75':' border-gray-200/75'} `}>
                          <div className='relative mx-auto my-auto'>
                          <Switch
                                color="primary"
                                checked={toggleAbnormalShockNotification}
                                onChange={handleChangeAbnormalShockNotification}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            {loadingAbnormalShockNotification && 
                              <div >
                                  <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                  </svg>
                              </div>
                            }
                          </div>
                      </div>
                      
                     
                    </motion.div>

                </AnimatePresence>
                  
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default SetCurrentOperationModal