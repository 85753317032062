import React, { useEffect, useRef, useState } from 'react'
import { getData, putData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { toast } from 'react-hot-toast';

function OrganizationHandleRow({orgInfo, orgHandle, setOrgHandle, editOrg}) {
    const [isEditingName, setIsEditingName] = useState(editOrg);
    const [loadingName, setLoadingName] = useState(false);
    // const [userOrgHandle, setUserOrgHandle] = useState(orgHandle); // Handle initial value
    const [isUserHandleApproved, setIsUserHandleApproved] = useState(false)

    const [isTyping, setIsTyping] = useState(true);
    const typingTimer = useRef(null);
    const typingInterval = 500; // 1 second

    useEffect(() => {
        setIsEditingName(editOrg)
    }, [editOrg])



      const copyToClipboard = (text) => {
        if (!navigator.clipboard) {
          console.error("Clipboard API not supported");
          toast.error("Clipboard not supported");
          return;
        }
      
        navigator.clipboard.writeText(text)
          .then(() => {
            toast.success("Organization handle copied!");
            // console.log("Text copied to clipboard successfully!");
          })
          .catch((err) => {
            toast.error("Failed to copy handle:", err);
            console.error("Failed to copy text:", err);
          });
      }

    
      const handleInput = (e) => {
        // let handle = e.target.value.toLowerCase().replace(/[^\w\s]/gi, '')
        let handle = e.target.value.toLowerCase().replace(/[^\w]/gi, '')
        setOrgHandle(handle)
        if (!isTyping) {
          setIsTyping(true);
        }
        clearTimeout(typingTimer.current);
        // typingTimer.current = setTimeout(handleUserStoppedTyping, typingInterval);
        typingTimer.current = setTimeout(() => {
            // console.log('User has stopped typing for more than 0.5 second.');
            setIsTyping(false);
            if(handle != orgInfo.organization_handle){
                getData(apiLink + `/v1/verify_organization_handle?organization_handle=${handle}`)
                .then(async response => {
                    if(response.ok ){
                    const data = await response.json();
                    if(data && data.data){
                        if(data.data[0].is_available){
                            setIsUserHandleApproved(true)
                            // toast.success('User handle available')
                        }else{
                            setIsUserHandleApproved(false)
                            // toast.error('User handle not available')
                        }
                    }
                    }
                    
                });
            }else{
                setIsUserHandleApproved(true)
            }

        }, typingInterval);
      };

      

  return (
    <div className='group scrollbar-hide'>
        {isEditingName ? (
            <div
                className='flex items-center text-sm'
            >
                <input 
                    className={` bg-transparent w-full max-w-[300px] xl:max-w-[800px] border rounded-md py-2 px-1 sm:py-1 sm:px-3 shadow-sm text-lg sm:text-sm
                    focus:outline-none focus:bg-transparent focus:ring-1 focus:border-blue-300 focus:ring-blue-300
                    dark:placeholder:text-blue-500 dark:text-blue-400 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800 
                    placeholder:text-blue-400 text-blue-600 border-gray-200 
                    disabled:border-transparent disabled:shadow-none disabled:dark:border-transparent`} 
                    id={'orgHandle'} 
                    type={'text'}
                    name={'orgHandle'}
                    value={orgHandle}
                    // onChange={(e) => setOrgHandle(e.target.value)}
                    // onBlur={handleNameBlur} // Handle blur
                    // onKeyDown={handleKeyPressName}
                    onInput={handleInput}
                    />
                <div className='text-left ml-1 flex'>
                    <div className='ml-2'>
                        {!isTyping && isUserHandleApproved &&
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-green-500">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                          </svg>
                          
                        }
                        {!isTyping && !isUserHandleApproved &&
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-red-500">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                            </svg>
                        }
                    </div>
                </div>
            </div>
        ) : (
            <div
                disabled={loadingName}
                className='flex justify-end items-end relative text-sm font-light text-gray-600 dark:text-gray-300  border-0 dark:border-zinc-600'
                >
                    <div
                        className={`bg-transparent items-end text-left w-full border border-transparent rounded-md text-gray-600 dark:text-gray-300`}
                    >
                        <span 
                            // onClick={() => setIsEditingName(true)}
                            className='text-blue-500 dark:text-blue-400 font-medium py-2 px-1 sm:py-1 sm:px-3'>
                                {orgHandle}
                        </span>
                        <button 
                        className='ml-2 text-gray-500 dark:text-gray-400 hover:text-gray-400 dark:hover:text-gray-600 opacity-0  ease-in-out duration-300 group-hover:opacity-100'
                        onClick={()=> copyToClipboard(`${orgHandle}`)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                            </svg>
                        </button>
                    </div>
                {loadingName && 
                <div >
                    <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                    </svg>
                </div>
                }
            </div>
        )}
    </div>
  )
}

export default OrganizationHandleRow