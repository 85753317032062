import React, { useEffect, useState } from 'react'
import { getData, postData } from '../../ApiCalls/DataApis'
import { apiLink } from '../../ApiCalls/ApisVariables'
import { globalSelectedOrganization } from '../../atoms/orgStates';
import { useRecoilValue } from 'recoil';
import { darkModeState } from '../../atoms/darkModeState';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SearchIcon } from "@heroicons/react/outline"
import { motion, AnimatePresence } from "framer-motion";
import Avatar from '@mui/material/Avatar';
import { toast } from 'react-hot-toast';
import ImageZoomCrop from '../../components/modals/ImageZoomCrop'



function CreateNewConversationSection({getConversationsList}) {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const isDark = useRecoilValue(darkModeState)
    const [selectedMembers, setSelectedMembers] = useState({})
    const [usersList, setUsersList] = useState([])
    const [filteredMemberList, setFilteredMemberList] = useState([])
    const [searchMemberText, setSearchMemberText] = useState('')
    const [userCount, setUserCount] = useState(null)
    const [selectedUserCount, setSelectedUserCount] = useState(0)
    const [isHighlighted, setIsHighlighted] = useState(null);
    const [userDoesNotExist, setUserDoesNotExist] = useState(false)
    const [isCreatingGroup, setIsCreatingGroup] = useState(false)
    let location = useLocation();
    // const [groupImage, setGroupImage] = useState(null)
    const [conversationSubject, setConversationSubject] = useState('')
    const [loadingGroupCreation, setLoadingGroupCreation] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        let currentSearch = location.search
            if(currentSearch && selectedOrganization){
                updatedMemberList()
            }
      },[selectedOrganization, location])

    const updatedMemberList = () => {
        try {

            if(selectedOrganization ){
              getData(apiLink + '/v1/organizations/' + selectedOrganization + '/user_contacts')
              .then(async response => {
                if(response.ok ){
                  const data = await response.json();
                  if(data && data.data){
                    //   console.log('Chat memebrs',data.data)
                    let count = Object.keys(data.data).length
                    if(count){
                        setUserCount(count)
                    }
                      setUsersList(data.data)
                  }
                }
              }) 
            }
          } catch (error) {
            console.log(error.message)
          }
    }

    useEffect(() => {
        if(searchMemberText  && searchMemberText.length > 0){
          const filtered = usersList.filter(member => {
            let fullName = member.user_first_name + ' ' + member.user_last_name
            if(member.user_organization_handle.toLowerCase().includes(searchMemberText.toLowerCase()) || fullName.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_first_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_last_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_username_public.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_email.toLowerCase().includes(searchMemberText.toLowerCase())){
              return member
            }
          })
          setFilteredMemberList(filtered)
    
        }else{
          setFilteredMemberList([])
        //   setFilteredAllIoTFlowsMemberList([])
        }
      },[searchMemberText])

      const handleAddUser = (e, user) => {
        setSearchMemberText('')

        const itemId = user.user_organization_handle
        const newSelectedItems = {...selectedMembers}; // Copy the existing array

        
        const index = Object.keys(newSelectedItems).indexOf(itemId);
        if (index === -1) {
            // Item not found, add it to the array
            newSelectedItems[itemId] = user;
            // newSelectedItems.push(itemId);
        } else {
            // Item found, remove it from the array
            // newSelectedItems.splice(index, 1);
            delete newSelectedItems[itemId]
        }
        let count = Object.keys(newSelectedItems).length
        setSelectedUserCount(count)
        setSelectedMembers(newSelectedItems);

      }


      const handleRemoveUser = (e) => {
        const key = e.key;
        if (key === 'Enter' || key === ',' || key === 'Tab') {
            getData(apiLink + '/v1/handles/' + searchMemberText + '/exists' )
              .then(async response => {
                if(response.ok ){
                  const data = await response.json();
                  if(data && data.data && data.data.exists){

                    const itemId = searchMemberText
                    const newSelectedItems = {...selectedMembers}; // Copy the existing array

                    
                    const index = Object.keys(newSelectedItems).indexOf(itemId);
                    console.log('index', index)
                    if (index === -1) {
                        // Item not found, add it to the array
                        newSelectedItems[itemId] = {
                            user_image_url: '',
                            user_organization_handle: itemId,
                            user_first_name:''
                        };
                        // newSelectedItems.push(itemId);
                    } else {
                        // Item found, remove it from the array
                        // newSelectedItems.splice(index, 1);
                        // delete newSelectedItems[itemId]
                        toast('User already added')
                    }
                    let count = Object.keys(newSelectedItems).length
                    setSelectedUserCount(count)
                    setSelectedMembers(newSelectedItems);
                    setSearchMemberText('')
                    setUserDoesNotExist(false)


                  }else{
                    setUserDoesNotExist(true)
                  }
                  
                }
              })
        }else if ((key === 'Backspace' || key === 'Delete') && !e.ctrlKey && !e.metaKey) {
            if(searchMemberText.length === 0){

                if (isHighlighted) {
                   // Call delete function with component reference
                //    let newMembers = selectedMembers.filter((element) => element !== isHighlighted);
                //    setSelectedMembers(newMembers)

                   let newSelectedItems = {...selectedMembers}; // Copy the existing array
                    delete newSelectedItems[isHighlighted]

                    setSelectedMembers(newSelectedItems)
                    setIsHighlighted(null); // Reset highlight if unrelated key is pressed
                    let count = Object.keys(newSelectedItems).length
                    setSelectedUserCount(count)
                } else {
                    const keys = Object.keys(selectedMembers);
                    const lastKey = keys[keys.length - 1];
                //   const lastValue = selectedMembers[selectedMembers.length - 1];
                    setIsHighlighted(lastKey);
                  
                }
                e.preventDefault(); // Prevent default backspace/delete behavior
              } else {
                setIsHighlighted(null); // Reset highlight if unrelated key is pressed
              }
        }
      };

    const removeMember = (member) => {
        if(member){
            let newSelectedItems = {...selectedMembers}; // Copy the existing array
            delete newSelectedItems[member]

            setSelectedMembers(newSelectedItems)
            let count = Object.keys(newSelectedItems).length
            setSelectedUserCount(count)
        }
    }

    const handleDoesUserExist = (e) => {
        const key = e.key;
        if (key === 'Enter' || key === ',' || key === 'Tab') {
            console.log('e.target.value', e.target.value); // Replace with your desired action
            console.log('searchMemberText:', searchMemberText); // Replace with your desired action
            getData(apiLink + '/v1/handles/' + searchMemberText + '/exists' )
              .then(async response => {
                if(response.ok ){
                  const data = await response.json();
                  if(data && data.data && data.data.exists){

                    const itemId = searchMemberText
                    const newSelectedItems = {...selectedMembers}; // Copy the existing array

                    
                    const index = Object.keys(newSelectedItems).indexOf(itemId);
                    console.log('index', index)
                    if (index === -1) {
                        // Item not found, add it to the array
                        newSelectedItems[itemId] = newSelectedItems[itemId] = {
                            user_image_url: '',
                            user_organization_handle: itemId,
                            user_first_name:''
                        };;
                        // newSelectedItems.push(itemId);
                    } else {
                        // User Already added
                        toast('User Already Added')
                    }
                    let count = Object.keys(newSelectedItems).length
                    setSelectedUserCount(count)
                    setSelectedMembers(newSelectedItems);
                    setSearchMemberText('')
                    setUserDoesNotExist(false)

                  }else{
                    setUserDoesNotExist(true)
                  }
                  
                }
              })
        }
    }

    const handleSearchMemberText = (e) => {
        setSearchMemberText(e.target.value.replace(/,/g, ''))
        if(userDoesNotExist){
            setUserDoesNotExist(false)
        }

    }

    const handleCreateGroup = () => {
        if(selectedMembers && Object.keys(selectedMembers).length > 0){
            setIsCreatingGroup(true)
        }else{
            toast.error('Select users')
        }
    }

    const submitCreateGroup = () => {
        try{
            if(!loadingGroupCreation){
                setLoadingGroupCreation(true)
                let membersArray = Object.keys(selectedMembers)
                postData(apiLink + '/v1/organizations/' + selectedOrganization + '/conversations', 
                {'conversation_subject': conversationSubject,
                  'chatroom_members': membersArray,})
                  .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                        if(data && data.data && data.data[0]){
                            let select = searchParams.getAll('select')
                            let platform = searchParams.getAll('platform')
                            setSearchParams({select,chatId:data.data[0].chat_uuid, section:'messages', platform})
                            getConversationsList()
                            setIsCreatingGroup(false)
                            setSelectedMembers({})
                            setConversationSubject('')
                            // setGroupImage(null)
                            setLoadingGroupCreation(false)
                        }
                    }else{
                      try{
                        toast.error(data.message)
                      }catch (e){
                        toast.error(e.message)
                      }
                    }
                  })
            }
        }catch (e){
            toast.error(e.message)
            setLoadingGroupCreation(false)
        }



    }

    // const handleUpdateGroupImage = (image) => {
    //     setGroupImage(image)
    // }

    const handleNavBack = () => {
        let select = searchParams.getAll('select')
        let chatId = searchParams.getAll('chatId')
        let section = searchParams.getAll('section')
        let platform = searchParams.getAll('platform')

        setSearchParams({select,chatId, section, platform})
    }


  return (
    <div className=' pb-24 h-screen scrollbar-hide overflow-y-scroll '>
        <AnimatePresence mode='wait'>
            {!isCreatingGroup ? (
                <motion.div
                        key='selectMembersDiv'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className='relative'
                    >
                    <div className='sticky top-0 border-b-[0.01em] border-slate-200 dark:border-slate-600 px-2 py-1.5 bg-white dark:bg-slate-900 z-10'>
                        <div className='grid grid-cols-3 '>
                            <ol className='flex ' onClick={handleNavBack}>
                                <li className='breadcrumbsNav flex items-center'  >
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                        </svg>
                                    </div>
                                    <span className='text-sm'>Back</span>
                                </li>
                            </ol>
                            <div className='text-gray-600 dark:text-gray-300 text-center flex items-center justify-center'>
                                <span>Select Users</span>
                                <span className='text-gray-500 dark:text-gray-400 text-center pl-4'>{userCount && `${selectedUserCount}/${userCount}`}</span>
                            </div>
                            <div  className='flex justify-end items-center'>
                                <button 
                                    className='text-right text-sm pl-2  text-blue-500 dark:text-blue-400 enabled:hover:text-blue-600 enabled:dark:hover:text-blue-500 disabled:text-blue-400/80 disabled:dark:text-blue-500/80' 
                                    disabled={Object.keys(selectedMembers).length === 0 }
                                    onClick={handleCreateGroup}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='sticky top-9 pb-1 px-4 mb-2 bg-slate-100 dark:bg-slate-900 z-10 border-b-[0.01em] border-slate-200 dark:border-slate-700'>
                        {selectedMembers && Object.keys(selectedMembers).length > 0 ? (
                            <div className='rounded-full w-full py-2'>
                                <div className='flex flex-wrap bg-slate-100 dark:bg-slate-700/50 rounded-xl px-2 py-1 '>
                                    {Object.keys(selectedMembers).map((member) => (
                                        <div key={member} className={`${member === isHighlighted ? 'bg-blue-600/40':'bg-blue-600/80'} flex text-white rounded-full pr-1 pl-2 py-0 mr-1 my-0.5 items-center font-light`}>
                                            <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 20, height: 20, }}  src={selectedMembers[member].user_image_url} />
                                            <span className='px-2'>{member}</span>
                                            <button 
                                            onClick={() => removeMember(member)}
                                            className='bg-white/50 text-slate-100 dark:text-slate-700/50 rounded-full h-4 p-0.5'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="currentColor" className="w-3 h-3">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                </svg>
                                            </button>
                                        </div>
                                    ))} 
                                    <input className={`grow py-0 min-w-[150px] bg-transparent text-gray-600 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:focus:ring-gray-500 dark:text-gray-300 dark:placeholder:text-gray-400
                                                text-sm text-ellipsis `}
                                        value={searchMemberText} 
                                        onChange={handleSearchMemberText}
                                        onKeyDown={handleRemoveUser}
                                        type='text' 
                                        />
                                </div>

                            </div>
                        ):(
                            <div className='relative rounded-full w-full py-2'>
                                <div className='absolute inset-y-0 pl-3 flex items-center pointer-events-none'>
                                    <SearchIcon className='h-4 w-4 text-gray-400 ' />
                                </div>
                                <input className={`bg-slate-100 text-gray-600 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 dark:hover:bg-slate-700/80 focus:bg-slate-100 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-gray-300 dark:placeholder:text-gray-400
                                        block w-full pl-8 text-sm rounded-lg text-ellipsis py-2`}
                                    value={searchMemberText} 
                                    onChange={handleSearchMemberText}
                                    onKeyDown={handleDoesUserExist}
                                    type='text' 
                                    placeholder="Who would you like to add?" 
                                    />
                            </div>
                        )
                        }
                    </div>
                    <div className='pb-4 px-4'>
                            {/* Search bar for searching members in org and in all of IoTFlows */}
                        {searchMemberText && searchMemberText.length > 0 && userDoesNotExist &&
                        // No member found section
                        <div className='text-center'>
                            <div className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} py-2 w-full text-center`}>
                                <span>No matches found for {searchMemberText}</span>
                            </div>
                            </div>
                        }
                        <div className='overflow-y-scroll '>
                            <AnimatePresence mode='wait'>
                                {searchMemberText === '' && usersList && Object.keys(usersList).length > 0 &&
                                    <motion.div
                                        key='allMembersInOrg'
                                        initial={{ y: 10, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        exit={{ y: -10, opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                    > 

                                    {/* List of members */}
                                        <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                                        {usersList.map((member, index) => (
                                            <div 
                                                key={member.user_username} 
                                                className={`flex px-2 py-2  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center`}
                                                onClick={(e) => handleAddUser(e, member)}
                                            >
                                                <input 
                                                    type="checkbox" 
                                                    checked={Object.keys(selectedMembers).includes(member.user_organization_handle)} 
                                                    onChange={(e) => handleAddUser(e, member)}  
                                                    className="w-4 h-4 mr-2 rounded-full text-blue-500 hover:-translate-y-px focus:ring-transparent bg-transparent outline-none rounded border-gray-200 border-[1px] shadow"
                                                />
                                                <div className='relative'>
                                                    <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 32, height: 32, }}  src={member.user_image_url} />
                                                    {member.is_external &&
                                                        <div className='absolute rounded-full bg-red-200 text-gray-600 right-[-3px] bottom-[-3px] p-0.5'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" viewBox="0 -960 960 960"  fill="currentColor">
                                                                <path d="m880-194-80-80v-326H474l-74-74v-86h-86l-80-80h246v160h400v486ZM820-28l-94-92H80v-648l-52-52 56-56L876-84l-56 56ZM160-200h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 160h166l-80-80h-86v80Zm240-240h-80v-80h80v80Z"/>
                                                            </svg>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='flex flex-col pl-2 grow overflow-hidden'>
                                                    <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                        <div className='max-w-[250px] lg:max-w-[550px]'>
                                                            <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                                        </div>
                                                        {/* <span className={`pl-3 pr-1 text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-light truncate italic `}>{member.user_organization_handle}</span> */}

                                                    </div>
                                                    <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                        <div className='max-w-[250px] lg:max-w-[550px]'>
                                                            <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'} truncate italic`}>{member.user_organization_handle}</h1>
                                                        </div>

                                                    </div>
                                                </div>
                                                
                                            </div>
                                        ))}

                                        </div>
                                    </motion.div>
                                }
                                {searchMemberText && searchMemberText.length > 0  && filteredMemberList && Object.keys(filteredMemberList).length > 0 &&
                                    <motion.div
                                        key='filteredListSection'
                                        initial={{ y: 10, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        exit={{ y: -10, opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                    > 
                                        <div className='flex flex-col overflow-y-scroll scrollbar-hide'>
                                            {filteredMemberList.map((member, index) => (
                                            <div 
                                                key={member.user_username} 
                                                className={`flex px-2 py-2  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center`}
                                                onClick={(e) => handleAddUser(e, member)}
                                            >
                                                <input 
                                                    type="checkbox" 
                                                    // checked={selectedMembers.includes(member.user_organization_handle)} 
                                                    checked={Object.keys(selectedMembers).includes(member.user_organization_handle)} 
                                                    onChange={(e) => handleAddUser(e, member)}  
                                                    className="w-4 h-4 mr-2 rounded-full text-blue-500 hover:-translate-y-px focus:ring-transparent bg-transparent outline-none rounded border-gray-200 border-[1px] shadow"
                                                />
                                                <div className='relative'>
                                                    <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 32, height: 32, }}  src={member.user_image_url} />
                                                    {member.is_external &&
                                                        <div className='absolute rounded-full bg-red-200 text-gray-600 right-[-3px] bottom-[-3px] p-0.5'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" viewBox="0 -960 960 960"  fill="currentColor">
                                                                <path d="m880-194-80-80v-326H474l-74-74v-86h-86l-80-80h246v160h400v486ZM820-28l-94-92H80v-648l-52-52 56-56L876-84l-56 56ZM160-200h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 160h166l-80-80h-86v80Zm240-240h-80v-80h80v80Z"/>
                                                            </svg>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='flex flex-col pl-2 grow overflow-hidden'>
                                                    <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                        <div className='max-w-[250px] lg:max-w-[550px]'>
                                                            <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                                        </div>
                                                        <span className={`pl-3 pr-1 text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-light truncate italic `}>{member.user_organization_handle}</span>

                                                    </div>
                                                    <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                        <div className='max-w-[250px] lg:max-w-[550px]'>
                                                            <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'} truncate italic`}>{member.user_organization_handle}</h1>
                                                        </div>

                                                    </div>
                                                </div>
                                                
                                            </div>
                                            ))}
                                        </div>
                                    </motion.div>
                                }

                            </AnimatePresence>
                        </div>
                    </div>
                </motion.div>
            ):(
                <motion.div 
                    key='createGroupDiv'
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    <div className='sticky top-0 border-b-[0.01em] border-slate-200 dark:border-slate-600 px-2 py-1.5 bg-white dark:bg-slate-900 z-10'>
                        <div className='grid grid-cols-3 '>
                            <ol className='flex '>
                                <li className='breadcrumbsNav flex items-center' onClick={() => setIsCreatingGroup(false)} >
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                        </svg>
                                    </div>
                                    <span className='text-sm'>Back</span>
                                </li>
                            </ol>
                            <div className='text-gray-600 dark:text-gray-300 text-center '>
                                <span>New Conversation</span>
                            </div>
                            <div className='flex items-center justify-end'>
                                <button 
                                    className='relative text-right text-sm pl-2 text-blue-500 dark:text-blue-400 enabled:hover:text-blue-600 enabled:dark:hover:text-blue-500 disabled:text-blue-400/80 disabled:dark:text-blue-500/80' 
                                    disabled={conversationSubject.length === 0 }
                                    onClick={submitCreateGroup}
                                >
                                    Create
                                    {loadingGroupCreation && 
                                        <div >
                                            <svg aria-hidden="true" className={`absolute top-0 right-0 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                            </svg>
                                        </div>
                                    }
                                </button>

                            </div>
                        </div>
                    </div>
                    <div className='max-w-2xl mx-auto pb-5'>
                        <div className='bg-slate-100 dark:bg-slate-800 rounded-xl px-4 py-2 mt-4 mx-4'>
                            <div className='flex items-center'>
                                {/* <div className='h-14 w-14'>
                                    <ImageZoomCrop currentPicture={groupImage} submitType={'groupImage'} title={'Group Image'} isDisabled={true} isRounded={false} updateInfo={handleUpdateGroupImage} />
                                </div> */}
                                <input className={`bg-transparent text-gray-600 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 
                                    dark:text-gray-300 dark:placeholder:text-gray-400
                                    block w-full pl-4 text-sm rounded-lg text-wrap py-2`}
                                value={conversationSubject} 
                                onChange={(e) => setConversationSubject(e.target.value)}
                                type='text' 
                                placeholder="Conversation Subject" 
                                autoFocus
                                />
                            </div>
                        </div>
                        <div className='bg-slate-100 dark:bg-slate-800 rounded-xl px-4 py-2 mt-4 mx-4'>
                            {Object.keys(selectedMembers).map((member) => (
                                <div key={member} className={`flex text-gray-600 dark:text-gray-300 items-center py-1 `}>
                                    <div className='relative'>
                                        <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 44, height: 44, }}  src={selectedMembers[member].user_image_url} />
                                        {!selectedMembers[member].is_external &&
                                            <div className='absolute rounded-full bg-red-200 text-gray-600 right-[-3px] bottom-[-3px] p-0.5'>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" viewBox="0 -960 960 960"  fill="currentColor">
                                                    <path d="m880-194-80-80v-326H474l-74-74v-86h-86l-80-80h246v160h400v486ZM820-28l-94-92H80v-648l-52-52 56-56L876-84l-56 56ZM160-200h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 160h166l-80-80h-86v80Zm240-240h-80v-80h80v80Z"/>
                                                </svg>
                                            </div>
                                        }
                                    </div>
                                    <div className='flex flex-col ml-2 grow overflow-hidden border-b-[0.01em] border-slate-200 dark:border-slate-600 py-1'>
                                        <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate`}>{selectedMembers[member].user_first_name}&nbsp;{selectedMembers[member].user_last_name}</h1>
                                        </div>
                                        <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                                <p className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'} truncate italic`}>{selectedMembers[member].user_organization_handle}</p>

                                        </div>
                                    </div>
                                </div>
                            ))} 

                        </div>

                    </div>

                </motion.div>
            )}
        </AnimatePresence >
    </div>
  )
}

export default CreateNewConversationSection
