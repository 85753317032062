import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { PlusSmIcon } from "@heroicons/react/solid"
import { motion, AnimatePresence } from "framer-motion";
import { useRecoilState, useRecoilValue } from 'recoil';
import {darkModeState} from '../../../atoms/darkModeState'
import GatewayIoTFlows from '../../../images/GatewayIoTFlows.svg'
import { SearchIcon } from '@heroicons/react/outline';
import GeneralFormInput from '../../forms/GeneralFormInput';
import {addSensorModalState, addGatewayModalState, addAssetModalState} from '../../../atoms/modalStates'
import { globalSelectedOrganization } from '../../../atoms/orgStates';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import { getData, postData } from '../../../ApiCalls/DataApis';
import toast from 'react-hot-toast';



function AddSensorModal({btnType, remoteOpenSensorModal, refreshSensorList}) {
  const isDark = useRecoilValue(darkModeState);
  const selectedOrganization = useRecoilValue(globalSelectedOrganization);
  const [isOpen, setIsOpen] = useState(false)
  const [showGatewayPage, setShowGatewayPage] = useState(true)
  const [showGatewayList, setShowGatewayList] = useState(false)
  const [selectedGateway, setSelectedGateway] = useState([])
  const [nodeUuid, setNodeUuid] = useState('')
  const [nodeName, setNodeName] = useState('')
  const [isSensorModalOpen, setIsSensorModalOpen] = useRecoilState(addSensorModalState)
  const [isGatewayModalOpen, setIsGatewayModalOpen] = useRecoilState(addGatewayModalState)
  const [isAssetModalOpen, setIsAssetModalOpen] = useRecoilState(addAssetModalState)
  const [gatewaySearchText, setGatewaySearchText] = useState('')
  const [gatewayList, setGatewayList] = useState([])
  const [filteredGatewayList, setFilteredGatewayList] = useState([])
  const [loading, setLoading] = useState(false)
  const [subscriptionType, setSubscriptionType] = useState('senseai-node-monthly')

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
    setIsSensorModalOpen(false)
  }

  useEffect(() => {
    if(remoteOpenSensorModal){
      setIsOpen(true)
    }
  },[remoteOpenSensorModal])

  useEffect(() => {
    try {
      if(selectedOrganization){
        // Get list of gateways 
        getData(apiLink + '/v1/organizations/' + selectedOrganization + '/devices/senseai')
        .then(async response => {
          if(response.ok ){
            const data = await response.json();
            if(data && data.data){
              // console.log('gateway list', data.data)
              setGatewayList(data.data)
            }
          }
        });
      }
    } catch (error) {
      console.log(error.message)
    }
  },[selectedOrganization])

  // useEffect to filter through search for machines
  useEffect(() => {
    if(gatewaySearchText && gatewaySearchText.length > 0){
      const filtered = gatewayList.filter(gateway => {
        if(gateway.device_name.toLowerCase().includes(gatewaySearchText.toLowerCase()) || gateway.device_uuid.toLowerCase().includes(gatewaySearchText.toLowerCase()) ){
          return gateway
        }
      })
      setFilteredGatewayList(filtered)
    }else{
      setFilteredGatewayList(gatewayList)
    }

  },[gatewayList, gatewaySearchText])

  const handleShowGatewayList = () => {
    if(gatewaySearchText){
      setShowGatewayList(true)
    }else{
      setShowGatewayList(!showGatewayList)
    }
  }

  const handleSelectGateway = (gateway) => {
    if(gateway === 'newGateway'){
        setIsOpen(false)
        setIsGatewayModalOpen(true)
    }else{
        setSelectedGateway(gateway)
        setShowGatewayPage(false)
    }
  }

  const backToGatewayPage = () => {
    setShowGatewayPage(true)
    setSelectedGateway([])
  }

  const handleSensorIdCode = (e) => {
    let value = e.target.value
    setNodeUuid(value)
  }

  const handleSensorName = (e) => {
    let value = e.target.value
    setNodeName(value)
  }

  const addSensorOpenAssetsModal = () => {

    // Write post API for sensors 
    try {
      if(!loading){
        setLoading(true)
        postData(apiLink + '/v1/iotflows/device-management/registration/nodes/' + nodeUuid + '/assign/senseai', 
        {'organization_uuid': selectedOrganization,
          "node_name": nodeName,
          "subscription_plan_identifier": subscriptionType,
          "device_uuid": selectedGateway.device_uuid})
          .then(async response => {
            const data = await response.json();
            if(response.ok){
              // const data = await response.json();
              setLoading(false)
              setNodeUuid('')
              setNodeName('')
              setSubscriptionType('senseai-node-monthly')
              setSelectedGateway([])
              toast.success('Sensor has been added')
              if(refreshSensorList){
                refreshSensorList()
              }
              closeModal()
              setIsAssetModalOpen(true)
            }
            else{
              try{
                const data = await response.json();
                setLoading(false)
                toast.error(data.message)
              }catch (e){
                toast.error('Something went wrong')
                setLoading(false)
              }
            }
          })
      }
    } catch (error) {
      
    }
  }


  const submitAddSensor =() => {
    try {
      if(!loading){
        setLoading(true)
        postData(apiLink + '/v1/iotflows/device-management/registration/nodes/' + nodeUuid + '/assign/senseai', 
        {'organization_uuid': selectedOrganization,
          "node_name": nodeName,
          "subscription_plan_identifier": subscriptionType,
          "device_uuid": selectedGateway.device_uuid})
          .then(async response => {
            const data = await response.json();
            if(response.ok){
              // const data = await response.json();
              setLoading(false)
              setNodeUuid('')
              setNodeName('')
              setSubscriptionType('senseai-node-monthly')
              setSelectedGateway([])
              toast.success('Sensor has been added')
              if(refreshSensorList){
                refreshSensorList()
              }
              closeModal()
            }
            else{
              try{
                const data = await response.json();
                setLoading(false)
                toast.error(data.message)
              }catch (e){
                toast.error('Something went wrong')
                setLoading(false)
              }
            }
          })
      }
    } catch (error) {
      
    }
  }


  return (
    <>
      <div className="flex items-center justify-center">
        {btnType ==='standardBtn' &&
          <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
              <PlusSmIcon className='btnMainIcon' />
              <span className='btnMainText'>Add Sensor</span>
          </motion.button>
          }
        {btnType ==='tableBtn' &&
        <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='flex w-20 text-sm items-baseline ml-2' onClick={openModal} >
            <PlusSmIcon className='text-blue-600 w-4 h-4 mt-auto mb-0.5' />
            <span className=' text-blue-600'>Add</span>
        </motion.button>
          }
        {btnType ==='hideBtn' &&
          <div className='w-0 h-0' >{''}</div>
          }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-lg transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <Dialog.Title
                    as="h3"
                    className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}
                  >
                    Add Sensor
                  </Dialog.Title>
                  <AnimatePresence exitBeforeEnter>

                    {/* Select Gateway */}
                    {Object.keys(selectedGateway).length === 0 && showGatewayPage &&
                      <motion.div
                        key='selectGatewayPage'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      > 
                        <div className='px-4 mt-3'>
                          <div className='flex mb-4'>
                              <div className='flex flex-col'>
                                  <span className={`text-base font-medium ${isDark?'text-gray-300':'text-gray-600'}`}>Select Gateway</span>
                                  <span className={`text-sm leading-4 ${isDark?'text-gray-400':'text-gray-500'}`}>
                                      A gateway is required to properly connect your sensor
                                  </span>
                              </div>
                          </div>

                          {/* // Input search for Gateway */}
                          <div className={`${showGatewayList ? 'h-64': 'h-24'} min-w-[200px] lg:min-w-[350px] `}>
                              <div className={`flex relative grow`}>
                                  <div className='relative rounded-full w-full'>
                                    <div className='absolute inset-y-0 pl-3 flex items-center pointer-events-none'>
                                      <SearchIcon className='h-4 w-4 text-gray-400 ' />
                                    </div>
                                    <input className={`block w-full pl-8 sm:text-sm rounded-md text-ellipsis py-1
                                                    ${isDark?' text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800 bg-slate-700  placeholder:text-gray-500 ':' placeholder:text-gray-400  text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`}
                                            value={gatewaySearchText} 
                                            onChange={(e)=> setGatewaySearchText(e.target.value)} 
                                            onClick={handleShowGatewayList} 
                                            type='text' 
                                            placeholder="Find or add a gateway..." 
                                    />
                                  </div>
                                  {filteredGatewayList && Object.keys(filteredGatewayList).length > 0 && showGatewayList &&
                                      <div className={`${isDark?'bg-slate-900 ':'bg-white '} w-full flex flex-col absolute p-0.5 z-50 shadow-iotflows-lg inset-x-0 top-10 rounded-lg max-h-[220px] overflow-auto scrollbar-hide`}   >
                                          <div className={`w-full h-10 cursor-pointer p-2  ${isDark?'hover:bg-slate-800 ':'hover:bg-slate-200'} rounded`} onClick={() => handleSelectGateway('newGateway')}>
                                              <div className={`flex ${isDark?'text-blue-400':'text-blue-600'}`} >
                                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                                                  </svg>
                                                  <div className='text-sm pl-2 font-medium '>
                                                      Add new gateway
                                                  </div>
                                              </div>
                                          </div>
                                        {filteredGatewayList.map((gateway, index) => (
                                            <div className={`${isDark?'hover:bg-slate-800':'hover:bg-slate-200'} w-full flex cursor-pointer p-2 rounded`} key={gateway.device_uuid} onClick={() => handleSelectGateway(gateway)}>
                                                <div>
                                                    <img
                                                    alt={gateway.device_name}
                                                    src={GatewayIoTFlows}
                                                    className='w-10 h-10 rounded'
                                                    />
                                                </div>
                                                <div className='flex flex-col ml-2 justify-between' >
                                                    <div className={`${isDark?'text-gray-300 ':' text-gray-600 '} text-base font-medium `} >
                                                      {gateway.device_name}
                                                    </div>
                                                    <div className={`${isDark?'text-gray-400 ':' text-gray-500 '} text-xs font-light italic`} >
                                                      {gateway.device_uuid}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                      </div>
                                  }
                              </div>
                          </div>
                      </div>
                      </motion.div>
                    }
                    {Object.keys(selectedGateway).length > 0 && !showGatewayPage &&
                      <motion.div
                        key='AddSensorPage'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <div className='px-4'>
                          <div className='mb-4'>
                              <div onClick={backToGatewayPage}>
                                <svg xmlns="http://www.w3.org/2000/svg" className={`h-7 w-7 p-0.5 rounded-full transition duration-300 ease-in-out ${isDark?'text-gray-400 hover:bg-gray-700':'text-gray-500 hover:bg-gray-200'} hover:-translate-y-0.5 ml-[-10px] mb-1`} viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                </svg>
                              </div>
                              <div className='flex flex-col'>
                                  <span className={`text-base font-medium ${isDark?'text-gray-300':'text-gray-600'}`}>Add Sensor</span>
                                  <span className={`text-sm leading-4 ${isDark?'text-gray-400':'text-gray-500'}`}>
                                    This is the IoTFlows sensor that you want to attach to a machine
                                  </span>
                              </div>

                              {/* Sensor QR code */}
                              <div className='pt-6'>
                              <GeneralFormInput  label='Sensor ID Code' type='text' placeholder='' name='nodeUuid' onChange={handleSensorIdCode} />
                              </div>
                              <div className='pt-2'>
                              <GeneralFormInput  label='Sensor Name' type='text' placeholder='' name='nodeName' onChange={handleSensorName} />
                              </div>
                              <p className={`${isDark?'text-gray-300 ':' text-gray-600 '} text-sm font-medium mt-4`}>Select subscription</p>
                              <div className='flex ml-2'>
                                  <div className="form-check mt-2">
                                    <input className={`form-check-input appearance-none rounded-full h-4 w-4 border ${isDark?'bg-slate-500 border-gray-300':'bg-white border-gray-300'} checked:bg-blue-600 checked:border-blue-500 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer`}
                                          type="radio" 
                                          value='senseai-node-monthly'
                                          id='senseai-node-monthly'
                                          checked={subscriptionType == 'senseai-node-monthly'}
                                          onChange={(e) => setSubscriptionType(e.target.value)}
                                      />
                                        <label className={`form-check-label inline-block text-sm ${isDark?'text-gray-400':'text-gray-600'} `} htmlFor='senseai-node-monthly'>
                                          Monthly ($99/month)
                                        </label>
                                  </div>
                                  <div className="form-check mt-2 ml-4">
                                    <input className={`form-check-input appearance-none rounded-full h-4 w-4 border ${isDark?'bg-slate-500 border-gray-300':'bg-white border-gray-300'} checked:bg-blue-600 checked:border-blue-500 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer`}
                                          type="radio" 
                                          value='senseai-node-yearly'
                                          id='senseai-node-yearly'
                                          checked={subscriptionType == 'senseai-node-yearly'}
                                          onChange={(e) => setSubscriptionType(e.target.value)}
                                      />
                                        <label className={`form-check-label inline-block text-sm ${isDark?'text-gray-400':'text-gray-600'} `} htmlFor='senseai-node-yearly'>
                                          Yearly ($999/year)
                                        </label>
                                  </div>
                              </div>

                              <div className='w-full flex mt-6'>
                                <button 
                                  className={`${isDark?'text-blue-400 hover:text-blue-600 disabled:text-blue-300 ':'text-blue-600 hover:text-blue-800 disabled:text-blue-500 '} ml-auto text-sm font-medium italic  `} 
                                  onClick={addSensorOpenAssetsModal} 
                                  disabled={nodeName.length === 0 || nodeUuid.length === 0 || loading}
                                >
                                  Add sensor and create asset
                                </button> 
                              </div>
                          </div>
                        </div>
                      </motion.div>
                    }


                  </AnimatePresence>
                  <form >
                    <div className={`flex p-4 px-5 items-center mt-4 border-t-[1px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                      <button
                        type="button"
                        className={`inline-flex rounded-md  bg-transparent border  ${isDark?' border-slate-600 text-gray-400 hover:bg-slate-600':'border-gray-100 text-gray-600 hover:bg-gray-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2`}
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className={`relative ml-auto inline-flex rounded-md border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                        onClick={submitAddSensor}
                        disabled={nodeName.length === 0 || nodeUuid.length === 0 || loading}
                      >
                        Add Sensor
                        {loading && 
                            <div >
                                <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                </svg>
                            </div>
                          }
                      </button>
                    </div>
                  </form>
                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default AddSensorModal