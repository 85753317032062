import React, { useState } from 'react'
import { useRecoilValue } from 'recoil';
import { darkModeState } from '../atoms/darkModeState';
import LogInfoModal from '../components/modals/logModals/LogInfoModal';

function LogsTableRow({el, index}) {
    const isDark = useRecoilValue(darkModeState)
    const [openLogInfoModal, setOpenLogInfoModal] = useState(false)

    const handleCloseModal = () => {
        setOpenLogInfoModal(false)
    }

    const handleDateFormat = (timestamp) => {
        if(timestamp){
            var currentDate = new Date(timestamp)
            var currentHours = currentDate.getHours();
            var currentMinutes = currentDate.getMinutes();
            var currentAmPm = currentHours >= 12 ? 'pm' : 'am';
            currentHours = currentHours % 12;
            currentHours = currentHours ? currentHours : 12; // the hour '0' should be '12'
            currentMinutes = currentMinutes < 10 ? '0'+currentMinutes : currentMinutes;
            var currentStrTime = currentHours + ':' + currentMinutes + '' + currentAmPm;
            var shortcurrentDate = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2)
            // var currentTime = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString().substr(-2) + " " + currentStrTime;
            // var startTime = startDate.getMonth()+1 + "/" + startDate.getDate() + "/" + startDate.getFullYear().toString().substr(-2) + " " + startStrTime;
            return currentStrTime + "  " + shortcurrentDate  ;
        }
    }


  return (
    <tr className='border-b-[0.01em] hover:bg-slate-200 dark:border-slate-700 dark:hover:bg-slate-700 cursor-pointer' onClick={() => setOpenLogInfoModal(true)}>
        <td className='text-sm font-normal text-gray-600 dark:text-gray-300 px-1 py-3 '>
            {el.log_message}
        </td>
        <td className='text-sm font-normal text-gray-600 dark:text-gray-300 px-1 py-3 text-center'>
            <span 
                style={{backgroundColor: isDark? el.http_request_method_color_dark: el.http_request_method_color_light}}
                className={`px-2 py-0.5 rounded-md text-xs ${isDark? 'opacity-90':'opacity-90'} text-gray-600`}
            >
                {el.http_request_method_descriptive_name}
            </span>
        </td>
        <td className='text-sm font-normal text-gray-600 dark:text-gray-300 px-1 py-3 text-right'>
            {handleDateFormat(el.log_timestamp)}
        {openLogInfoModal &&
                <LogInfoModal remoteOpen={openLogInfoModal}  handleCloseModal={handleCloseModal} data={el} /> 
            }
        </td>

    </tr>

  )
}

export default LogsTableRow