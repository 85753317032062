import React, { useEffect, useRef } from 'react'
import OptimizePartImage from '../../../OptimizePartImage'
import OperationTimeCellData from './OperationTimeCellData';

function ModifyOperationCountSection({operation, dateCategories, isDark,  assetId}) {
    const tableRef = useRef(null);

    useEffect(() => {
        if (tableRef.current) {
            // tableRef.current.scrollIntoView({ behavior: 'smooth', inline: 'end' });
            tableRef.current.scrollLeft = tableRef.current.scrollWidth;

        }
      }, []); 

  return (
    <div className={`flex flex-col px-4 overflow-scroll message_window_scrollbar ${isDark ? 'scrollbar_dark' : 'scrollbar_light'}`}>
        <div className='flex items-center'>
                <div className='w-12'>
                    {operation && Object.keys(operation).length > 0 &&
                        <OptimizePartImage alt={operation.part_name} source={operation.part_picture_url} size='large' />
                    }
                </div>
                <div className={`text-sm font-normal ${isDark ? 'text-gray-300':'text-gray-600'} py-2 pr-2 pl-2 `}>
                    <div className='flex flex-col'>
                        <p className={`text-left leading-5 truncate text-base ${isDark ? 'text-gray-300':'text-gray-600'} font-medium`}>{operation.operation_name}</p>
                        <p className={`text-left leading-5 truncate text-sm ${isDark ? 'text-gray-400':'text-gray-500'} font-medium`}>{operation.operation_description}</p>
                        <p className={`text-left truncate text-xs ${isDark ? 'text-gray-400':'text-gray-500'} font-normal`}>{operation.part_name}</p>
                    </div>
                </div>
        </div>
        <div className={`flex flex-col w-full overflow-x-scroll message_window_scrollbar ${isDark ? 'scrollbar_dark' : 'scrollbar_light'}`} ref={tableRef} >
                <table className="table-fixed w-full" >
                    <tbody >
                        <tr >
                        <td 
                            style={{gridColumn: 1 }}
                            className={`w-[120px] z-10 sticky left-0 ${isDark ? 'text-gray-300 bg-slate-800':'text-gray-600 bg-white'} border-0 dark:border-zinc-600  overflow-hidden`}
                        >
                            <div className={`text-left text-sm leading-5 my-1 tracking-wide font-light ${isDark ? 'text-gray-400 border-zinc-500':'text-gray-500 '} border-b-[0] border-transparent`}>
                            &nbsp; 
                            </div>
                           <div className={`flex items-center h-[28px] text-sm leading-7 }`}>Auto-Detected</div>
                            <div className={`h-[36px] text-sm flex items-center ${isDark ? 'text-sky-400':'text-sky-500'}`}>
                                Override Good
                            </div>
                            <div className={`h-[32px] text-sm flex items-center ${isDark ? 'text-rose-400':'text-rose-500'}`}>
                                Override Scrap
                            </div>
                            <div className={`h-10 text-sm flex items-center ${isDark ? 'text-rose-400':'text-rose-500'}`}>
                            &nbsp; 
                            </div>
                        </td>
                        {operation.operations_made.map((opTimeData, index) => (
                            <OperationTimeCellData opTimeData={opTimeData} index={index} dateCategories={dateCategories} isDark={isDark} key={`${opTimeData.interval_timestamp}-${opTimeData.operation_uuid}`} operation={operation} assetId={assetId} />
                        ))}
                            
                        </tr>

                    </tbody>
                </table>
            </div>
    </div>
  )
}

export default ModifyOperationCountSection