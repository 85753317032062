import {atom} from 'recoil'

export const globalSelectedOrganization = atom({
    key:'globalSelectedOrganization',
    default: '',
})
export const globalSelectedOrganizationTimezone = atom({
    key:'globalSelectedOrganizationTimezone',
    default: {
        name: '',
        value:''
    },
})