import React, { useState } from 'react'
import { useRecoilValue } from "recoil";
import {darkModeState} from '../../../atoms/darkModeState'
import { TrashIcon } from '@heroicons/react/solid'
import EditPartModal from "../../../components/modals/partModals/EditPartModal";
import SenseAI from '../../../images/SenseAI.svg'
import { deleteData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import EditSensorModal from './EditSensorModal';

function SensorRowForModal({index, sensor, getSensorList, getAssetsNodesGateways, showGODview}) {
    const isDark = useRecoilValue(darkModeState)
    const [deleteCurrentSensor, setDeleteCurrentSensor] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    // const [loadDefaultImg, setLoadDefaultImg] = useState(false)

    //in your component
    // const addDefaultSrc = (ev) => {
    //     setLoadDefaultImg(true)
    // }

    const unassignSensor = () => {
        try {
            if(!loadingDelete ){
                setLoadingDelete(true)
                deleteData(apiLink + '/v1/iotflows/device-management/registration/nodes/' + sensor.node_uuid + '/assign')
                .then(async response => {
                const data = await response.json();
                if(response.ok){
                    // const data = await response.json();
                    setDeleteCurrentSensor(false)
                    setLoadingDelete(false)
                    getSensorList()
                    getAssetsNodesGateways()
                    toast.success('Sensor deleted')
                }
                else{
                    try{
                        const data = await response.json();
                        setLoadingDelete(false)
                        toast.error(data.message)
                    }catch (e){
                        toast.error('Something went wrong')
                        setLoadingDelete(false)
                    }
                }
                })
    
            }
        
      } catch (error) {
        console.log(error.message)
      }
    }

  return (
    <div className='my-2' >
      {!deleteCurrentSensor && 
            <div className={`${isDark ? ' border-slate-700': ''} border-b-[0.01em] pt-1 pb-2 px-2 flex`}>
                <div className='my-auto'>
                    <img
                        alt={'SenseAI'}
                        src={SenseAI}
                        className='h-10 rounded-full'
                    />
                </div>
                <div className='flex flex-col ml-2 justify-between overflow-hidden ' >
                    <div className={`${isDark?'text-gray-300 ':' text-gray-600 '} text-base font-medium truncate pr-2`} >
                    {sensor.node_name}
                    </div>
                    <div className={`${isDark?'text-gray-400 ':' text-gray-500 '} text-xs font-light italic truncate pr-2`} >
                    {sensor.node_uuid}
                    </div>
                    <div className='flex pt-0.5'>
                        <div className={`${isDark?'text-gray-400 ':' text-gray-500 '} text-xs font-light truncate`}>{sensor.asset_custom_name  ? sensor.asset_custom_name :sensor.machine_name}&nbsp;</div>
                        <div className={`${isDark?'text-gray-400 ':' text-gray-500 '} text-xs font-light italic truncate pr-2`}>{sensor.asset_custom_identifier ? `- ${sensor.asset_custom_identifier}` : `- ${sensor.asset_uuid}`}</div>
                    </div>
                </div>
                <div className='ml-auto my-auto w-5'>
                    <EditSensorModal  btnType='inlineIconBtn' sensor={sensor} getSensorList={getSensorList} /> 
                </div>
                {showGODview &&
                    <button className={`w-5 ${isDark?'text-blue-400 hover:text-blue-200':'text-blue-500 hover:text-blue-900'} flex ml-2 my-auto`} onClick={() => setDeleteCurrentSensor(true)}>
                        <TrashIcon className={`w-5 h-5`}/>
                    </button> 
                }
            </div>
        }

        {deleteCurrentSensor && 
            <div className={`flex ml-2 items-center pt-1 pb-2 ${isDark ? ' border-slate-700': ''} border-b-[0.01em]`}>
                <span className={`text-sm text-left font-medium ${isDark?'text-gray-300':'text-gray-600'}`}>Are you sure you want to delete {sensor.name}?</span>
                <div className={`shadow rounded-md py-1 px-2 border-[1px] text-xs ${isDark?'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} cursor-pointer flex ml-2`} onClick={() => setDeleteCurrentSensor(false)}>
                    Close
                </div>
                <div className={`relative shadow rounded-md ml-1 py-1 px-2 border-[1px] border-red-600 text-xs bg-red-600 hover:bg-red-700 hover:border-red-700 text-white cursor-pointer`} onClick={unassignSensor}>
                    Delete
                    {loadingDelete && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-0 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                        }
                </div>
            </div>
        }
    </div>
  )
}

export default SensorRowForModal


function GearIcon(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      
      
    )
}