import { useEffect, useState } from 'react'
import { SearchIcon } from "@heroicons/react/outline"
import { PencilIcon, TrashIcon } from "@heroicons/react/solid"
import { motion, AnimatePresence } from "framer-motion";
import { useRecoilValue } from 'recoil';
import { apiLink } from '../ApiCalls/ApisVariables';
import { darkModeState } from '../atoms/darkModeState';
import { globalSelectedOrganization } from '../atoms/orgStates';
import { getData } from '../ApiCalls/DataApis';
import AddMemberModal from '../components/modals/memberModals/AddMemberModal';
import { MemberTooltip } from '../styles/Tooltip';
import { Zoom } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import RemoveMemberModal from '../components/modals/memberModals/RemoveMemberModal';
import EditMemberRoleModal from '../components/modals/memberModals/EditMemberRoleModal';
import { useLocation, useSearchParams } from 'react-router-dom';

const emailCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function MembersPage() {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [membersList, setMembersList] = useState([])
    const isDark = useRecoilValue(darkModeState)
    const [isOpen, setIsOpen] = useState(false)
    const [openRemoveMemberModal, setOpenRemoveMemberModal] = useState(false)
    const [openEditMemberRoleModal, setOpenEditMemberRoleModal] = useState(false)
    const [selectedMember, setSelectedMember] = useState([])
    const [filteredMemberList, setFilteredMemberList] = useState(membersList)
    const [filteredAllIoTFlowsMemberList, setFilteredAllIoTFlowsMemberList] = useState([])
    const [searchMemberText, setSearchMemberText] = useState('')
    const [noMatchedName, setNoMatchedName] = useState(false)
    const [isTypingEmail, setIsTypingEmail] = useState(false)
    const [validEmail, setValidEmail] = useState(false)
    const [openAddMemberModal, setOpenAddMemberModal] = useState(false)
    const [inviteNewMember, setInviteNewMember] =useState([])
    const [addOutsideOrgMember, setAddOutsideOrgMember] =useState([])
    let location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
          setIsMobile(true)
        }else{
          setIsMobile(false)
        }
      },[location])


    useEffect(() => {
        updatedMemberList()
      },[selectedOrganization])

    const updatedMemberList = () => {
        try {
            if(selectedOrganization){
              getData(apiLink + '/v1/organizations/' + selectedOrganization + '/members')
              .then(async response => {
                if(response.ok ){
                  const data = await response.json();
                  if(data && data.data){
                    setMembersList(data.data)
                  }
                }
              }) 
            }
          } catch (error) {
            console.log(error.message)
          }
    }

    useEffect(() => {
        if(searchMemberText  && searchMemberText.length > 0){
          const filtered = membersList.filter(member => {
            let fullName = member.user_first_name + ' ' + member.user_last_name
            if(fullName.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_first_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_last_name.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_username_public.toLowerCase().includes(searchMemberText.toLowerCase()) || member.user_email.toLowerCase().includes(searchMemberText.toLowerCase())){
              return member
            }
          })
          setFilteredMemberList(filtered)
    
          // Get members from API
          getData(apiLink + '/v1/users/search/containing?q='+ searchMemberText )
          .then(async response => {
            // JSON data parsed by response.json() call
            if(response.ok ){
              const data = await response.json();
              let newList = data.data
              newList.map((list, index) => {
                filtered.map(memberFilter => {
                  if(memberFilter.user_username == list.user_username){
                    newList.splice(index, 1)
                  }
                })
              })
              
              setFilteredAllIoTFlowsMemberList(newList)
            }
          })
    
    
    
        }else{
          setFilteredMemberList([])
          setFilteredAllIoTFlowsMemberList([])
        }
      },[searchMemberText])

    useEffect(() => {
        if(searchMemberText && searchMemberText.length > 0 && Object.keys(filteredAllIoTFlowsMemberList).length == 0 && Object.keys(filteredMemberList).length == 0 ){
            if(searchMemberText.includes('@')){
            setNoMatchedName(false)
            setIsTypingEmail(true)
            if( emailCheck.test(searchMemberText) ){
                setValidEmail(true)
            }else {
                setValidEmail(false)
            }
            }else{
            setNoMatchedName(true)
            setValidEmail(false)
            setIsTypingEmail(false)
            }
        }else {
            setNoMatchedName(false)
            setValidEmail(false)
            setIsTypingEmail(false)
        }
    },[filteredAllIoTFlowsMemberList, filteredMemberList, searchMemberText])

    const handleSearchMember = (e) => {
        setSearchMemberText(e.target.value)
    }

    const handleAddMemberModal = () => {
        setOpenAddMemberModal(true)
    }

    const handleRouteInviteNewMember = (inviteEmail) =>{
        setOpenAddMemberModal(true)
        setInviteNewMember(inviteEmail)
    }

    const handleCloseAddMemberModal = () =>{
        setOpenAddMemberModal(false)
    }

    const handleAddMember = (member) => {
        setOpenAddMemberModal(true)
        setAddOutsideOrgMember(member)
    }

    const handleEditMemberModal = (memberSelected) => {
        setSelectedMember(memberSelected)
        setOpenEditMemberRoleModal(true)
    }
    
    const handleRemoveMemberModal = (memberSelected) => {
        setSelectedMember(memberSelected)
        setOpenRemoveMemberModal(true)
    }

    const handleCloseRemoveModal = () => {
        setOpenRemoveMemberModal(false)
        setSelectedMember([])
    }

    const handleCloseEditRoleModal = () => {
        setOpenEditMemberRoleModal(false)
        setSelectedMember([])
    }

    
  return (
    <div className={`mt-2 overflow-y-scroll scrollbar-hide ${isMobile ? 'max-h-[calc(100vh-0.5rem)]' : 'max-h-[calc(100vh-5rem)]'}`}>
        <div className='pb-4'>
            {/* Search bar for searching members in org and in all of IoTFlows */}
            <div className='relative rounded-full w-full'>
                <div className='absolute inset-y-0 pl-3  flex items-center pointer-events-none'>
                    <svg className={`h-5 w-5 ${isDark? 'text-gray-300' : 'text-gray-500'} `}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                </div>
                <input className={`${isDark? 'dark:hover:bg-slate-700/80 dark:focus:bg-slate-700/80 dark:bg-slate-700/50 dark:focus:ring-gray-500 dark:text-gray-300 dark:placeholder:text-gray-400' : 'bg-gray-100 text-gray-600 focus:ring-slate-200 placeholder:text-gray-500 focus:bg-slate-100 '}    border-0 border-gray-300 focus:ring-[0px]  focus:border-slate-300    
                    block w-full text-sm pl-10 py-2.5 rounded-lg text-ellipsis `}
                    value={searchMemberText} 
                    // onChange={(e)=> setMessageSearchText(e.target.value)} 
                    onChange={handleSearchMember} 
                    type="text" 
                    placeholder="Search by username or email, or invite members via email"
                />
            </div>
            
            {!noMatchedName && !isTypingEmail &&
                // Add Member section
                <button className={`flex px-5 py-3 items-center w-full  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center`} onClick={handleAddMemberModal}>
                <div className={`${isDark? 'text-blue-300  hover:text-gray-200':'text-blue-700 hover:bg-gray-200'} border-0 rounded-full  ml-2.5`}>
                    <svg  xmlns="http://www.w3.org/2000/svg"  className="w-7 h-7" fill="currentColor" viewBox="0 0 16 16" stroke="none">
                        <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                        <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                    </svg>
                </div>
                <div className='flex flex-col pl-3 grow overflow-hidden'>
                    <div className='flex items-baseline'>
                    <div className='max-w-[250px] lg:max-w-[550px]'>
                        <h1 className={`text-base ${isDark? 'text-blue-100':'text-blue-700'} font-semibold truncate`}>Add member</h1>
                    </div>
                    </div>
                </div>
                </button>
            }
            {isTypingEmail && 
                // Add Member section
                <button className={`flex px-5 py-3 items-center w-full  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} items-center`} 
                        onClick={() => handleRouteInviteNewMember(searchMemberText)} 
                        disabled={!validEmail}>
                    {validEmail? (
                        <div className={`${isDark? 'text-blue-300  ':'text-blue-600 '} border-0 rounded-full  ml-2.5`}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                            <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                            </svg>
                        </div>  
                    ):(
                        <div className={`${ isDark? 'text-gray-400  ':'text-gray-500 '} border-0 rounded-full  ml-2.5`}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                            </svg>
                        </div>
                    )}
                
                <div className='flex flex-col pl-3 grow overflow-hidden'>
                    <div className='flex items-baseline'>
                    <div className='max-w-[250px] lg:max-w-[550px]'>
                        <div className={`text-base font-semibold truncate`}>
                        {validEmail ? (
                        <span className={`${isDark? 'text-gray-300':'text-gray-600'}`}>Invite {searchMemberText}</span>
                        ):(
                        <span className={`${isDark? 'text-gray-400':'text-gray-500'}`}>Keep typing full email</span>
                        )} 
                        </div>
                    </div>
                    </div>
                </div>
                </button>
            }
            {searchMemberText && searchMemberText.length > 0 && noMatchedName &&
                // No member found section
                <div className='text-center'>
                <div className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} py-2 w-full text-center`}>
                    <span>No matches found for {searchMemberText}</span>
                </div>
                <button
                    type="button"
                    className={`rounded-md ml-auto bg-transparent border ${isDark?' border-gray-600 text-gray-400 hover:bg-gray-700':'border-gray-400 text-gray-600 hover:bg-gray-100'} px-4 py-1 text-sm font-medium `}
                    onClick={handleAddMemberModal}
                >
                    Add Member
                </button>
                </div>
            }
            <AnimatePresence mode='wait'>
                {/* All members in organization */}
                {searchMemberText === '' && membersList && Object.keys(membersList).length > 0 &&
                <motion.div
                        key='allMembersInOrg'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    > 

                    {/* List of members */}
                        <div className='flex flex-col'>
                        {membersList.map((member, index) => (
                        <div key={member.user_username} className={`flex px-5 py-3  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} rounded-xl cursor-pointer items-center`}>
                            <div>
                                <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 40, height: 40, }}  src={member.user_image_url} />
                            </div>
                            <div className='flex flex-col pl-2 grow overflow-hidden'>
                                <div className='flex items-baseline max-w-[250px] sm:max-w-[400px] md:max-w-[600px] lg:max-w-[800px]'>
                                    <div className='max-w-[250px] sm:max-w-[400px] md:max-w-[600px] lg:max-w-[800px]'>
                                        <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                    </div>
                                    <span className={`pl-3 pr-1 text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-light truncate italic `}>{member.user_username_public}</span>

                                </div>
                                <div className='flex items-baseline'>
                                    <div className=''>
                                        <h1 className={`text-sm ${isDark? 'text-gray-400':'text-gray-500'} truncate italic`}>{member.organization_member_role_name}</h1>
                                    </div>

                                </div>
                            </div>
                            <div className='ml-auto flex'>
                                <button className={`${isDark?'text-blue-400 hover:text-blue-200':'text-blue-500 hover:text-blue-900'} cursor-pointer flex ml-auto`} onClick={() => handleEditMemberModal(member)}>
                                    <PencilIcon className={`w-5 h-5`}/>
                                </button>
                                <button className={`w-5 ${isDark?'text-blue-400 hover:text-blue-200':'text-blue-500 hover:text-blue-900'} flex ml-2 my-auto`} onClick={() => handleRemoveMemberModal(member)}>
                                    <TrashIcon className={`w-5 h-5`}/>
                                </button> 
                            </div>
                        </div>
                        ))}

                        </div>
                    </motion.div>
                    }
                {searchMemberText && searchMemberText.length > 0  && filteredMemberList && Object.keys(filteredMemberList).length > 0 &&
                <motion.div
                        key='membersListSection'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    > 
                    <div className='pl-3 pb-1 pt-1'>
                        <h6 className={`text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-bold`}>In this organization</h6>
                    </div>

                    {/* List of members */}
                        <div className='flex flex-col'>
                        {filteredMemberList.map((member, index) => (
                        <div key={member.user_username} className={`flex px-5 py-3  ${isDark? 'hover:bg-slate-600/50':'hover:bg-slate-100'} cursor-pointer items-center`}>
                            <div>
                                <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 40, height: 40, }}  src={member.user_image_url} />
                            </div>
                            <div className='flex flex-col pl-2 grow overflow-hidden'>
                                <div className='flex items-baseline max-w-[238px] lg:max-w-[500px]'>
                                    <div className='max-w-[250px] lg:max-w-[550px]'>
                                        <span className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</span>
                                    </div>
                                    <span className={`pl-3 pr-1 text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-light truncate italic`}>{member.user_username_public}</span>
                                </div>
                            </div>
                            <div className='ml-auto flex'>
                                <button className={`${isDark?'text-blue-400 hover:text-blue-200':'text-blue-500 hover:text-blue-900'} cursor-pointer flex ml-auto`} onClick={() => handleEditMemberModal(member)}>
                                    <PencilIcon className={`w-5 h-5`}/>
                                </button>
                                <button className={`w-5 ${isDark?'text-blue-400 hover:text-blue-200':'text-blue-500 hover:text-blue-900'} flex ml-2 my-auto`} onClick={() => handleRemoveMemberModal(member)}>
                                    <TrashIcon className={`w-5 h-5`}/>
                                </button> 
                            </div>
                            </div>
                        ))}

                        </div>
                    </motion.div>
                    }
                {searchMemberText && searchMemberText.length > 0  && filteredAllIoTFlowsMemberList && Object.keys(filteredAllIoTFlowsMemberList).length > 0 &&
                <motion.div
                    key='allIoTFlowsmembersListSection'
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className={`${isDark? 'bg-slate-700 ':'bg-slate-100 '} h-full mt-2`}
                > 
                <div className='pl-3 pt-2 pb-1'>
                    <h6 className={`text-sm ${isDark? 'text-gray-100':'text-gray-700'} font-bold`}>Not in this organization</h6>
                </div>

                {/* List of members */}
                    <div className='flex flex-col h-full w-full'>
                    {filteredAllIoTFlowsMemberList.map((member, index) => (
                        <MemberTooltip  key={`${member.user_username}-iotflows-member`} TransitionComponent={Zoom}
                        title={
                            <div className={`flex flex-col rounded-lg ${isDark? ' bg-slate-900':' bg-slate-50'} `}>
                            <div className={`w-full text-center py-1.5 rounded-t-lg text-sm ${isDark? ' bg-slate-600 text-gray-400':' bg-slate-200 text-gray-700'}`}>
                                {/* <span>{member.organization_member_role_name}</span> */}
                            </div>
                            <div className='flex px-2 py-2'>
                                <div>
                                <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 80, height: 80, }}  src={member.user_image_url} /> 
                                </div>
                                <div className='flex flex-col pl-4 justify-between'>
                                    <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-800'} font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                                    <h3 className={`pr-1 text-sm ${isDark? 'text-gray-400':'text-gray-600'} font-light truncate italic `}>{member.user_username_public}</h3>
                                    <h3 className={`pr-1 text-sm ${isDark? 'text-gray-400':'text-gray-500'} font-light truncate `}>{member.user_email}</h3>
                                </div>
                            </div>
                            <div className='flex justify-between px-2 pt-4 pb-2'>
                                {/* <button
                                type="button"
                                className={`rounded-md bg-transparent border ${isDark?' border-gray-600 text-gray-400 hover:bg-gray-700':'border-gray-400 text-gray-600 hover:bg-gray-200'} px-4 py-1 text-sm font-medium `}
                                onClick={() => handleEditMemberModal(member)}
                                >
                                Edit Role
                                </button> */}
                                <button
                                type="button"
                                className={`rounded-md ml-auto bg-transparent border ${isDark?' border-gray-600 text-gray-400 hover:bg-gray-700':'border-gray-400 text-gray-600 hover:bg-gray-200'} px-4 py-1 text-sm font-medium `}
                                onClick={() => handleAddMember(member)}
                                >
                                Add
                                </button>
                            </div>
                            </div>
                        }
                        >
                    <div key={member.user_username} className={`flex px-5 py-3  ${isDark? 'hover:bg-slate-900/50':'hover:bg-slate-200'} rounded-xl cursor-pointer items-center`}>
                        <div>
                            <Avatar sx={{  bgcolor: isDark? '#1e3a8a':'#9ca3af', width: 40, height: 40, }}  src={member.user_image_url} />
                        </div>
                        <div className='flex flex-col pl-2 grow overflow-hidden'>
                            <div className='flex items-baseline'>
                            <div className='max-w-[250px] lg:max-w-[550px]'>
                                <h1 className={`text-base ${isDark? 'text-gray-300':'text-gray-600'} font-medium truncate`}>{member.user_first_name}&nbsp;{member.user_last_name}</h1>
                            </div>
                            <div className='pl-3 max-w-[200px]'>
                                <h3 className={`pr-1 text-sm ${isDark? 'text-gray-300':'text-gray-600'} font-light truncate italic truncate`}>{member.user_username_public}</h3>
                            </div>
                            </div>
                        </div>
                        <div className='w-9 pl-2'>
                            <button className={`text-xs text-blue-500 ${isDark? 'hover:text-blue-400':'hover:text-blue-700'}`} onClick={() => handleAddMember(member)}>
                            Add
                            </button>
                        </div>
                        </div>
                        </MemberTooltip>
                    ))}

                    </div>
                </motion.div>
                }

            </AnimatePresence>
        </div>

        {openAddMemberModal && selectedOrganization &&
        <AddMemberModal btnType='hideBtn' remoteOpen={openAddMemberModal} membersList={membersList} selectedOrganization={selectedOrganization} 
            handleCloseAddMemberModal={handleCloseAddMemberModal} 
            updatedMemberList={updatedMemberList}
            addOutsideOrgMember={addOutsideOrgMember}
            inviteNewMember={inviteNewMember}
            />
        }
        {openRemoveMemberModal && selectedMember && Object.keys(selectedMember).length > 0 &&
        <RemoveMemberModal btnType='hideBtn' remoteOpen={openRemoveMemberModal} selectedRemoveMember={selectedMember} handleCloseRemoveModal={handleCloseRemoveModal} selectedOrganization={selectedOrganization} updatedMemberList={updatedMemberList}/>
        }
        {openEditMemberRoleModal && selectedMember && Object.keys(selectedMember).length > 0 &&
        <EditMemberRoleModal btnType='hideBtn' remoteOpen={openEditMemberRoleModal} selectedEditMemberRole={selectedMember} handleCloseEditRoleModal={handleCloseEditRoleModal} selectedOrganization={selectedOrganization} updatedMemberList={updatedMemberList}/>
        }
    </div>
  )
}

export default MembersPage