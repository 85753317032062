import { useEffect, useState, Fragment,  } from 'react'
import { getData } from '../ApiCalls/DataApis'
import { Menu, Transition, MenuButton } from '@headlessui/react'

function MemberRoleButton({updateOrganizationsList}) {
    const [memberRoles, setMemberRoles] = useState([])

    useEffect(()=> {
        
        getData('https://api.iotflows.com/v1/users/iotflows')
        .then(async response => {
        if(response.ok ){
            const data = await response.json();
            if(data && data.data && (data.data.length>0) && data.data[0].iotflows_member_role_id){
                data.data.unshift({'iotflows_member_role_id': '','iotflows_member_role_name': 'Normal View' })
                setMemberRoles(data.data)
            }    
        }
        });
    },[])

    const handleMemberRole = (role) => {
        console.log('role.iotflows_member_role_id', role.iotflows_member_role_id)
        if(role.iotflows_member_role_id === 'IOTFLOWS_GOD'){
            localStorage.setItem('iotflows_member_role_id', role.iotflows_member_role_id)
            updateOrganizationsList()
        }else{
            localStorage.removeItem('iotflows_member_role_id')
            updateOrganizationsList()
        }
    }

    
  return (
    <div>
        {memberRoles && 
        <Menu>
            <MenuButton as="div" className='relative cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.2} stroke="currentColor" className="w-6 h-6 text-blue-500">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.115 5.19l.319 1.913A6 6 0 008.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 002.288-4.042 1.087 1.087 0 00-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 01-.98-.314l-.295-.295a1.125 1.125 0 010-1.591l.13-.132a1.125 1.125 0 011.3-.21l.603.302a.809.809 0 001.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 001.528-1.732l.146-.292M6.115 5.19A9 9 0 1017.18 4.64M6.115 5.19A8.965 8.965 0 0112 3c1.929 0 3.716.607 5.18 1.64" />
                </svg>
            </MenuButton>

            <Transition
            as={Fragment}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Menu.Items className="absolute origin-top-right mt-2 divide-y dark:divide-slate-500 flex flex-col w-40 bg-white dark:bg-slate-700 shadow-iotflows-lg rounded-lg outline-none ">
                    <div className="px-1 py-1 ">
                    {memberRoles.map((role,index) => (
                        <Menu.Item key={index}  >
                            <div onClick={() => handleMemberRole(role)} className={`hover:bg-slate-50 hover:dark:bg-slate-500 hover:text-gray-600 hover:dark:text-gray-200 text-blue-600 dark:text-blue-500 flex w-full items-center rounded-md px-2 py-2 text-sm cursor-pointer`}>
                                {role.iotflows_member_role_name}
                            </div>
                        </Menu.Item>
                    ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
        }
    </div>
  )
}

export default MemberRoleButton