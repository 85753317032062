import React from 'react';
import { Image as KonvaImage } from 'react-konva';
import useImage from 'use-image';

const ImageObject = ({ obj, handleSelect, handleDragEnd, handleTransformEnd }) => {
  const [image] = useImage(obj.src);

  return (
    <KonvaImage
      key={obj.id}
      id={obj.id}
      image={image}
      x={obj.x}
      y={obj.y}
      width={obj.width}
      height={obj.height}
      rotation={obj.rotation}
      {...obj}
      draggable
      onClick={handleSelect}
      onTap={handleSelect}
      onDragEnd={handleDragEnd}
      onTransformEnd={handleTransformEnd}
      dragBoundFunc={(pos) => {
        const gridSize = 20;
        const newX = Math.round(pos.x / gridSize) * gridSize;
        const newY = Math.round(pos.y / gridSize) * gridSize;
        return { x: newX, y: newY };
      }}
    />
  );
};

export default ImageObject;
