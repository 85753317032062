import { motion } from "framer-motion";
import { useRecoilValue } from "recoil";
import {darkModeState} from '../atoms/darkModeState'

function AnimateLine({operation, isCollapsedStates, isCollapsed}) {
    const isDark = useRecoilValue(darkModeState);
    const draw = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: (i) => {
          const delay =  i * 0.2;
          return {
            pathLength: 1,
            opacity: 1,
            transition: {
              pathLength: { delay, type: "spring", duration: 1, bounce: 0 },
              opacity: { delay, duration: 0.01 }
            }
          };
        }
      };

    let verticalLineCoord = []
    let horizontalLineCoords = []
    var rows =[]
    let addSpace = 0
    let prevAddSpace = 0
    for(var i=0; i < Object.keys(operation.assets).length; i++){
        if(i==0){
            verticalLineCoord.push({x1: 22,x2:22, y1:20, y2:95.5 })
            horizontalLineCoords.push({x1: 22,x2:40, y1:95, y2:95 })
        }else{
            verticalLineCoord.push({x1: 22,x2:22, y1:(95.5+((i-1)*81) + prevAddSpace), y2:(95.5+(i*81)  + addSpace) })
            horizontalLineCoords.push({x1: 22,x2:40, y1:(95.5+(i*81)  + addSpace), y2:(95.5+(i*81)  + addSpace) })
        }
        // check is dropwdown is true to create spacing for the next one
        // if(isCollapsedStates[i] == true && Object.keys(operation.operations[i].assets).length > 0){
        //     let machineCount = Object.keys(operation.operations[i].assets).length
        //     prevAddSpace = addSpace
        //     addSpace += machineCount*81
        // } 
        
        rows.push(<motion.svg
            key={i}
            className='absolute'
            width="50"
            height="3000"
            viewBox="0 0 50 3000"
            initial={`${!isCollapsed &&  'hidden'}`}
            animate={`${isCollapsed && 'visible'}`}
            >
                <motion.line
                    x1={verticalLineCoord[i].x1}
                    y1={verticalLineCoord[i].y1}
                    x2={verticalLineCoord[i].x2}
                    y2={verticalLineCoord[i].y2}
                    stroke={isDark?'#cbd5e1' :'#334155'}
                    variants={draw}
                    custom={i}
                />
                <motion.line
                    x1={horizontalLineCoords[i].x1}
                    y1={horizontalLineCoords[i].y1}
                    x2={horizontalLineCoords[i].x2}
                    y2={horizontalLineCoords[i].y2}
                    stroke={isDark?'#cbd5e1' :'#334155'}
                    variants={draw}
                    custom={i + 2}
                />
            </motion.svg>
            );
            
    }
    return (<>{rows}</>)
}

export default AnimateLine