import React, { useEffect, useState } from 'react'
import SwipeableViews from 'react-swipeable-views';

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function MonthlyPicker({setSelectedMonth, selectedMonth, setDateString}) {
    const [highlightedMonths, setHighlightedMonths] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [currentYearIndex, setCurrentYearIndex] = useState(0);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 2021;
        const years = [];

        for (let year = startYear; year <= currentYear; year++) {
        years.push(year);
        }

        setYearList(years);
        setCurrentYearIndex(years.length - 1)

    },[])

    useEffect(() => {
        // This code is used to properly index and highlight date when pulled from the URL
        if(selectedMonth && Object.keys(selectedMonth).length >= 0 && yearList && Object.keys(yearList).length > 0){
            
            let string = ``
            selectedMonth.map((dateArr, index) => {
                if(index === 0){
                    string += dateArr.year + ' ' + dateArr.month
                }else {
                    string += ' -> ' + dateArr.year + ' ' + dateArr.month
                }
            })
            setDateString(string)

            let array = []
            if(Object.keys(selectedMonth).length === 1){
                array.push(`${selectedMonth[0].year}-${selectedMonth[0].month}`)
                setHighlightedMonths(array)
                let indexYear = yearList.indexOf(selectedMonth[0].year)
                setCurrentYearIndex(indexYear)
                
            }else if(Object.keys(selectedMonth).length === 2){
                let indexYear = yearList.indexOf(selectedMonth[1].year)
                setCurrentYearIndex(indexYear)
                if(selectedMonth[0].year == selectedMonth[1].year){
                    if(selectedMonth[0].month == selectedMonth[1].month){
                        array.push(`${selectedMonth[0].year}-${selectedMonth[0].month}`)
    
                    }else {
                        for (let month = 0; month < 12; month++) {
                            if(month >= months.indexOf(selectedMonth[0].month) && month <= months.indexOf(selectedMonth[1].month)){
                                array.push(array.push(`${selectedMonth[0].year}-${months[month]}`));
                            }
                        }
                    }
    
                }else{
                    for (let year = selectedMonth[0].year; year <= selectedMonth[1].year; year++) {
                        for (let month = 0; month < 12; month++) {
        
                          if ( year >= selectedMonth[0].year && year <= selectedMonth[1].year ) {
        
                            if(year == selectedMonth[0].year && month >= months.indexOf(selectedMonth[0].month)){
                                array.push(array.push(`${year}-${months[month]}`));
        
                            }else if(year == selectedMonth[1].year && month <= months.indexOf(selectedMonth[1].month)){
                                array.push(array.push(`${year}-${months[month]}`));
        
                            }else if(year > selectedMonth[0].year && year < selectedMonth[1].year){
                                array.push(array.push(`${year}-${months[month]}`));
        
                            }
                          }
                        }
                      }
                }
    
                setHighlightedMonths(array)
    
            }else{
                setHighlightedMonths([])
            }

        }
        

    },[selectedMonth, yearList])


    const handleYearChange = (newYearIndex) => {
        if (newYearIndex >= 0 && newYearIndex < yearList.length) {
          setCurrentYearIndex(newYearIndex);
          
        }
      };

      const handleMonthClick = (month, year) => {
        let currentDate = {
            month,
            year
        }
        let dateArray = []
        if(Object.keys(selectedMonth).length === 0){
            dateArray.push(currentDate)
            setSelectedMonth(dateArray);
        }
        else if(Object.keys(selectedMonth).length === 1 ){
            const date1 = new Date(selectedMonth[0].year, months.indexOf(selectedMonth[0].month), 1); // December 25, 2024
            const date2 = new Date(year, months.indexOf(month), 1);
            
            if (date1 > date2) {
                // console.log(date1, 'is greater than', date2);
                dateArray.push(currentDate)
                setSelectedMonth(dateArray);
              } else if (date1 < date2) {
                    // console.log(date1, 'is less than', date2);
                    dateArray = selectedMonth
                    dateArray.push(currentDate)
                    setSelectedMonth(dateArray);
              } else {
                    // console.log(date1, 'is equal to', date2);
                    dateArray = selectedMonth
                    dateArray.push(currentDate)
                    setSelectedMonth(dateArray);
              }
        }else if(Object.keys(selectedMonth).length > 1){
            dateArray.push(currentDate)
            setSelectedMonth(dateArray);
        }

        let string = ``
        dateArray.map((dateArr, index) => {
            if(index === 0){
                string += dateArr.year + ' ' + dateArr.month
            }else {
                string += ' -> ' + dateArr.year + ' ' + dateArr.month
            }
        })
        setDateString(string)

        let array = []
        if(Object.keys(dateArray).length === 1){
            array.push(`${dateArray[0].year}-${dateArray[0].month}`)
            setHighlightedMonths(array)

        }else if(Object.keys(dateArray).length === 2){
            if(dateArray[0].year == dateArray[1].year){
                if(dateArray[0].month == dateArray[1].month){
                    array.push(`${dateArray[0].year}-${dateArray[0].month}`)

                }else {
                    for (let month = 0; month < 12; month++) {
                        if(month >= months.indexOf(dateArray[0].month) && month <= months.indexOf(dateArray[1].month)){
                            array.push(array.push(`${dateArray[0].year}-${months[month]}`));
                        }
                    }
                }

            }else{
                for (let year = dateArray[0].year; year <= dateArray[1].year; year++) {
                    for (let month = 0; month < 12; month++) {
    
                      if ( year >= dateArray[0].year && year <= dateArray[1].year ) {
    
                        if(year == dateArray[0].year && month >= months.indexOf(dateArray[0].month)){
                            array.push(array.push(`${year}-${months[month]}`));
    
                        }else if(year == dateArray[1].year && month <= months.indexOf(dateArray[1].month)){
                            array.push(array.push(`${year}-${months[month]}`));
    
                        }else if(year > dateArray[0].year && year < dateArray[1].year){
                            array.push(array.push(`${year}-${months[month]}`));
    
                        }
                      }
                    }
                  }
            }

            setHighlightedMonths(array)

        }else{
            setHighlightedMonths([])
        }
        
        // Potentially trigger other actions based on month selection
      };

      

  return (
    <div>
        <div className='flex justify-between items-center'>
            <button
            className='bg-slate-600 rounded px-2.5 py-0.5 text-gray-300'
                onClick={() => handleYearChange(currentYearIndex - 1)}
            >
                &lt;
            </button>
            <span className='text-gray-300'>
                {yearList[currentYearIndex]}
            </span>
            <button 
                className='bg-slate-600 rounded px-2.5 py-0.5 text-gray-300'
                onClick={() => handleYearChange(currentYearIndex + 1)}>
                &gt;
            </button>
        </div>
        <SwipeableViews
            index={currentYearIndex}
            onChangeIndex={handleYearChange}
        >
            {yearList.map((year, index) => (
                <div key={year} className='grid grid-cols-3 gap-3 mt-8 pb-4'>
                    {months.map((month, index) => (
                    <button
                        key={`${year}-${month}`}
                        onClick={() => handleMonthClick(month, year)}
                        className={`${highlightedMonths.includes(`${year}-${month}`)? 'bg-blue-500 text-white':'bg-slate-600 text-gray-300'} px-2 py-0.5  rounded-lg text-sm`}
                    >
                        {month}
                    </button>
                    ))}
                </div>
            ))}
        </SwipeableViews>
    </div>
  )
}

export default MonthlyPicker