import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { PlusSmIcon } from "@heroicons/react/solid"
import { motion, AnimatePresence } from "framer-motion";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import SetupForm from './SetupForm';
import { getData } from '../../../ApiCalls/DataApis';
import { apiLink } from '../../../ApiCalls/ApisVariables';

const stripePromise = loadStripe('pk_live_cv1o2of6G7RjXFImxVlKnzSD00evGTXFFa');


function AddCardModal({btnType, remoteOpenGatewayModal, isDark, selectedOrganization, getPaymentCards}) {
  const [isOpen, setIsOpen] = useState(false)
  const [elementOptions, setElementOptions] = useState({
    appearance: {
      theme: isDark ? 'night': 'stripe',
      labels: 'floating',
    },
  })


  useEffect(() => {
    
    getData(apiLink + '/v1/payment_methods/setup_intent')
    .then(async response => {
      if(response.ok ){
        const data = await response.json();
        // console.log('data', data)
        setElementOptions({
          // passing the client secret obtained in step 2
          clientSecret: data.client_secret,
          // Fully customizable with appearance API.
          appearance: {
            theme: isDark ? 'night': 'stripe',
            labels: 'floating',
          },
        })
      }
    });
  }, [isDark])

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  useEffect(() => {
    if(remoteOpenGatewayModal){
      setIsOpen(true)
    }
  },[remoteOpenGatewayModal])


  return (
    <>
      <div className="flex items-center justify-center">
        {btnType ==='standardBtn' &&
          <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='btnMain' onClick={openModal}>
              <PlusSmIcon className='btnMainIcon' />
              <span className='btnMainText'>Add Card</span>
          </motion.button>
          }
        {btnType ==='tableBtn' &&
        <motion.button  whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  className='flex w-20 text-sm items-baseline ml-2' onClick={openModal} >
            <PlusSmIcon className='text-blue-600 w-4 h-4 mt-auto mb-0.5' />
            <span className=' text-blue-600'>Add</span>
        </motion.button>
          }
        {btnType ==='hideBtn' &&
          <div className='w-0 h-0'>{''}</div>
          }
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full max-w-lg transform overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all ${isDark?'bg-slate-800':'bg-white'}`}>
                  <Dialog.Title
                    as="h3"
                    className={`mb-1 p-4 text-lg font-medium leading-6 border-b-[1px] ${isDark?'text-gray-100 border-gray-700':'text-gray-700 border-gray-200'}`}
                  >
                    Add Payment Card
                  </Dialog.Title>
                  <AnimatePresence exitBeforeEnter>

                  <motion.div
                        key='selectGatewayPage'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className='p-4'
                      > 
                      {elementOptions && 
                        <Elements stripe={stripePromise} options={elementOptions} >
                          <SetupForm isDark={isDark} closeModal={closeModal} selectedOrganization={selectedOrganization} getPaymentCards={getPaymentCards}/>
                        </Elements>
                      }
                    </motion.div> 


                  </AnimatePresence>
                  
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default AddCardModal