import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Switch } from "@mui/material";



function DowntimeWithNotesFilterBtn() {
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation();
    const [toggleDowntimeSummary, setToggleDowntimeSummary] = useState(false)

    useEffect(() => {
        // Gettting search parameters from URL
            let currentSearch = location.search
            if(currentSearch ){
                let selectedDowntimeView = searchParams.get('only_with_notes')

                if(selectedDowntimeView == 'true'){
                    setToggleDowntimeSummary(true)
                }else {
                    setToggleDowntimeSummary(false)
                }
                
            }
    
        }, [location])


    const handleChangeOperationsDropdown = (e) => {
        setToggleDowntimeSummary(e.target.checked)

        
        let select = searchParams.getAll('select')
        let departments = searchParams.getAll('departments')
        let parts = searchParams.getAll('parts')
        let machine_types = searchParams.getAll('machine_types')
        let tags = searchParams.getAll('tags')
        let group_by = searchParams.getAll('group_by')
        let sort_by = searchParams.getAll('sort_by')
        let calculate_oee_by = searchParams.getAll('calculate_oee_by')
        let shift_name = searchParams.getAll('shift_name')
        let shift_start_time = searchParams.getAll('shift_start_time')
        let shift_date = searchParams.getAll('shift_date')
        let shift_entire_day = searchParams.getAll('shift_entire_day')
        let maximize = searchParams.getAll('maximize')
        let view = searchParams.getAll('view')
        let url_token = searchParams.getAll('url_token')
        let operations_expanded = searchParams.getAll('operations_expanded')
        let assets_expanded = searchParams.getAll('assets_expanded')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')
        let users = searchParams.getAll('users')
        let text = searchParams.getAll('text')
        let from = searchParams.getAll('from')
        let to = searchParams.getAll('to')
        let machines = searchParams.getAll('machines')
        let downtime_severity_uuids = searchParams.getAll('downtime_severity_uuids')
        let time_period = searchParams.getAll('time_period')

        setSearchParams({select, departments,machines,  parts, machine_types, tags, group_by, sort_by, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, maximize, view, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled, users, text,from, to, time_period, downtime_severity_uuids, only_with_notes: e.target.checked})
    }


  return (
    <div className='relative max-w-[300px]'>
        <div className='flex items-center mt-2 sm:mt-0 ml-auto'>
            <p className='text-gray-600 dark:text-gray-400 text-sm'>Only with Notes </p>
            {/* <p className='text-gray-600 dark:text-gray-400 text-sm'>{toggleDowntimeSummary? 'Category': 'Severity'} </p> */}
            <Switch
                size="small"
                color="secondary"
                checked={toggleDowntimeSummary}
                onChange={handleChangeOperationsDropdown}
                inputProps={{ 'aria-label': 'controlled' }}
            />
        </div>
    </div>
  )
}

export default DowntimeWithNotesFilterBtn