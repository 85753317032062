
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import HighchartsReact from "highcharts-react-official";
import HighchartsStock from 'highcharts/highstock';
import highchartsMore from "highcharts/highcharts-more.js";
import Highcharts from "highcharts/highcharts.js";
import { darkModeState } from '../../../atoms/darkModeState';

highchartsMore(Highcharts);

function StackedBarChartSmallParts({type, countArray,countArrayBad, dateCategories, toggleShowScrap}) {
    const [chartOptions, setChartOptions] = useState({
        credits: {enabled: false},
        chart: {
            type: 'column',
            zoomType: 'x',
            backgroundColor:'transparent',
            // height:'110px',
        },
        time: {
            useUTC: false
          },
        title:{
            text:''
        },
    })
    const isDark = useRecoilValue(darkModeState); 

    useEffect(() => {
        if(dateCategories && dateCategories.length > 0 && countArray && countArray.length > 0){
            let red = 'rgba(220, 38, 38, 0.5)'
            let sky = '#0ea5e9'
            let skyLight = 'rgba(14, 165, 233,0.5)'
            
            if(!isDark){
                red = 'rgba(220, 38, 38, 0.5)' 
                sky = '#60a5fa'
                skyLight = 'rgba(96, 165, 250,0.5)'
            }
    
            if(type && type === 'assets'){
                red = 'rgba(220, 38, 38, 0.5)' 
                sky = '#a3a3a3'
                skyLight = 'rgba(163, 163, 163,0.5)'
            }

            // let seriesData = []
            // if(toggleShowScrap && countArrayBad && Object.keys(countArrayBad).length > 0){
            //     seriesData = [{
            //         name: 'Good Operations',
            //         data: countArray,
            //         pointPadding: 0,
            //         color: {
            //             linearGradient: {
            //               x1: 0,
            //               x2: 0,
            //               y1: 0,
            //               y2: 1
            //             },
            //             stops: [
            //               [0, skyLight],
            //             //   [1, 'rgba(22, 163, 74,0.5)']
            //             // [0, Highcharts.getOptions().colors[0]],
            //             // [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
            //             [1, sky]
            //             ]
            //           }
            //     },
            //     {
            //         name: 'Bad Operations',
            //         data: countArrayBad,
            //         pointPadding: 0,
            //         color: {
            //             linearGradient: {
            //               x1: 0,
            //               x2: 0,
            //               y1: 0,
            //               y2: 1
            //             },
            //             stops: [
            //               [0, red],
            //             //   [1, 'rgba(22, 163, 74,0.5)']
            //             // [0, Highcharts.getOptions().colors[0]],
            //             // [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
            //             [1, red]
            //             ]
            //           }
            //     },
            //     ]
            // }else{
            //     seriesData = [{
            //         name: 'Good Operations',
            //         data: countArray,
            //         color: {
            //             linearGradient: {
            //               x1: 0,
            //               x2: 0,
            //               y1: 0,
            //               y2: 1
            //             },
            //             stops: [
            //               [0, skyLight],
            //             //   [1, 'rgba(22, 163, 74,0.5)']
            //             // [0, Highcharts.getOptions().colors[0]],
            //             // [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
            //             [1, sky]
            //             ]
            //           }
            //     },
            //     ]
            // }
    
            
            setChartOptions({
                credits: {enabled: false},
                chart: {
                    type: 'column',
                    zoomType: 'x',
                    backgroundColor:'transparent',
                    // height:'110px',
                },
                time: {
                    useUTC: false
                    },
                title:{
                    text:''
                },
                xAxis: {
                    categories: dateCategories,
                    labels: {
                        style: {
                            color: isDark? '#d1d5db':'#4b5563'
                        }
                    }
                },
                yAxis: [{
                    visible: false,
                    // max: 10
                }, {
                    visible: false,
                    // max: 10,
                    opposite: true
                }],
                legend: {
                    enabled: false 
                },
                tooltip: {
                    formatter: function () {
                        // The first returned item is the header, subsequent items are the
                        // points
                        let icon = ''
                        return ['<b>' + this.x + '</b>'].concat(
                            this.points ?
                                this.points.map(function (point) {
                                    return point.series.name + ': ' + point.y + icon;
                                }) : []
                        );
                    },
                    split: true
                },
                plotOptions: {
                    column: {
                        // stacking: 'normal',
                        backgroundColor:'#F3F4F6',
                        dataLabels: {
                            enabled: true,
                            style: {
                                // fontSize: '18px',
                                color: isDark ? '#fff' :'#fff',
                                // color: isDark ? '#fff' :'rgba(75, 85, 99, 0.5)',
                            },
                        },
                        borderWidth:0
                    },
                    series: {
                        borderRadius: {
                            radius: 5
                        },
                        dataLabels: {
                            enabled: true,
                            // format: toggleShowPercentData ? '{y}%' : '{y}h'
                        },
                        // stacking: 'normal'
                    }
                },
                // series: seriesData,
                series: [{
                    name: 'Good Operations',
                    data: countArray,
                    pointPadding: 0,
                    color: {
                        linearGradient: {
                          x1: 0,
                          x2: 0,
                          y1: 0,
                          y2: 1
                        },
                        stops: [
                          [0, skyLight],
                        //   [1, 'rgba(22, 163, 74,0.5)']
                        // [0, Highcharts.getOptions().colors[0]],
                        // [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                        [1, sky]
                        ]
                      }
                },
                {
                    name: 'Bad Operations',
                    data: countArrayBad,
                    pointPadding: 0,
                    visible: toggleShowScrap,
                    color: {
                        linearGradient: {
                          x1: 0,
                          x2: 0,
                          y1: 0,
                          y2: 1
                        },
                        stops: [
                          [0, red],
                        //   [1, 'rgba(22, 163, 74,0.5)']
                        // [0, Highcharts.getOptions().colors[0]],
                        // [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                        [1, red]
                        ]
                      }
                },
                ]
            })

        }

    }, [isDark, toggleShowScrap])


  return (
    <div className='mb-[-15px]'>
        <HighchartsReact
            constructorType={"chart"}
            containerProps={{ style: { height: '120px', maxWidth:'100%'} }} 
            highcharts={HighchartsStock}
            options={chartOptions}               
        />
    </div>
  )
}

export default StackedBarChartSmallParts