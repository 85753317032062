import React, { useEffect, useRef, useState } from 'react'
import TimeAgo from 'timeago-react'
import { getData } from '../ApiCalls/DataApis'
import { apiLink } from '../ApiCalls/ApisVariables'
import { Group, Image as KonvaImage, Text, Circle, Rect } from 'react-konva';
import { darkModeState } from '../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import useImage from '../utils/useImage';

const loadImage = (setImageDimensions, imageUrl) => {
    const img = new Image();
    img.src = imageUrl;
  
    img.onload = () => {
      const aspectRatio = img.width / img.height;
  
      // Set the width or height to 100 and adjust the other accordingly
      const targetWidth = 100;
      const targetHeight = 100;
  
      let width, height;
      if (img.width >= img.height) {
        width = Number(targetWidth);
        height = Number(targetWidth / aspectRatio);
      } else {
        height = Number(targetHeight);
        width = Number(targetHeight * aspectRatio);
      }
  
      setImageDimensions({
        height: height || 0,
        width: width || 0,
      });
    };
  
    img.onerror = (err) => {
      console.log("img error");
      // console.error(err);
    };
  };

function AssetStatus({asset, x, y, scaleX, scaleY, rotation, imageSrc, onDragEnd, onSelect, isSelected, onTransformEnd, setSelectedObject,selectedObject, mqttMessageNodeUUID, mqttMessageTimestamp }) {
    const isDark = useRecoilValue(darkModeState)
    const [assetActivity, setAssetActivity] = useState([])
    const [image, imageStatus] = useImage(imageSrc, 'anonymous');
    const lastRefreshTimeRef = useRef(0);
    const refreshScheduledRef = useRef(false);
    const assetRef = useRef();
    const [timePassed, setTimePassed] = useState('');
    const [timePassedDate, setTimePassedDate] = useState('')

    const [imageDimensions, setImageDimensions] = useState({});

    useEffect(() => {
        if(asset && Object.keys(asset).length > 0){
            let url = asset.asset_custom_picture_url ? asset.asset_custom_picture_url : asset.machine_picture_url
            loadImage(setImageDimensions, url);
        }
    }, [asset])

    

    useEffect(() => {
        try {
            if(asset && asset.asset_uuid){
                refreshAllApis()
            }
        } catch (error) {
            
        }

    }, [asset])

    useEffect(() => {
        try {
            if(mqttMessageNodeUUID == asset.node_uuid && mqttMessageTimestamp > 0 ) {
               
                handleRefreshLogic();
            }
            
        } catch (error) {
            
        }

    }, [mqttMessageTimestamp])

    useEffect(() => {
        if (timePassedDate) {
            // Set up interval to update time passed every minute
            const intervalId = setInterval(() => {
                updateTimePassed();
            }, 60000); // Update every 60 seconds

            // Update immediately when the date changes
            updateTimePassed();

            // Clear interval on component unmount
            return () => clearInterval(intervalId);
        }
    }, [timePassedDate]);

    const updateTimePassed = () => {
        if (timePassedDate) {
            const pastDate = new Date(timePassedDate);
            const now = new Date();
            const differenceInMs = now - pastDate;
    
            const differenceInSeconds = Math.floor(differenceInMs / 1000);
            const differenceInMinutes = Math.floor(differenceInSeconds / 60);
            const differenceInHours = Math.floor(differenceInMinutes / 60);
            const differenceInDays = Math.floor(differenceInHours / 24);
    
            let timeString = '';
            if (differenceInDays > 0) {
                timeString = `${differenceInDays} day(s) ago`;
            } else if (differenceInHours > 0) {
                timeString = `${differenceInHours} hour(s) ago`;
            } else if (differenceInMinutes > 0) {
                timeString = `${differenceInMinutes} min(s) ago`;
            } else {
                timeString = `${differenceInSeconds} sec(s) ago`;
            }
    
            setTimePassed(timeString);
        }
    };

    function handleRefreshLogic() {
        const now = Date.now();
        const timeSinceLastRefresh = now - lastRefreshTimeRef.current;
    
        if (timeSinceLastRefresh >= 5000) {
          // Refresh immediately
          refreshAllApis();
          lastRefreshTimeRef.current = now;
          refreshScheduledRef.current = false;
        } else if (!refreshScheduledRef.current) {
          // Schedule refresh after remaining time
          const timeToWait = 5000 - timeSinceLastRefresh;
          refreshScheduledRef.current = true;
    
          setTimeout(() => {
            refreshAllApis();
            lastRefreshTimeRef.current = Date.now();
            refreshScheduledRef.current = false;
          }, timeToWait);
        }
      }

    const refreshAllApis = () => {
        try {
            if(asset && asset.asset_uuid){


                getData(apiLink + '/v1/assets/'+ asset.asset_uuid + '/activity-state' )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            setAssetActivity(data.data)
                            setTimePassedDate(data.data.activity_finished_at)
                            
                        }else{
                            setAssetActivity([])
                        }
                    }else{
                        setAssetActivity([])
                    }
                })

            }
        } catch (error) {
            
        }

    }

    


  return (
    <div>
        <div className='flex'>
            <Group 
                type='asset'
                ref={assetRef}
                x={x} 
                y={y}
                id={asset.asset_uuid}
                scaleX={scaleX}
                scaleY={scaleY}
                rotation={rotation}
                onClick={() => onSelect(asset.asset_uuid, asset)}
                onTap={() => onSelect(asset.asset_uuid, asset)}
                draggable // Enable dragging
                // dragBoundFunc={(pos) => {
                //     const gridSize = 50;
                //     const newX = Math.round(pos.x / gridSize) * gridSize;
                //     const newY = Math.round(pos.y / gridSize) * gridSize;
                //     return { x: newX, y: newY };
                //   }}
                onDragEnd={(e) => {
                    // Call the onDragEnd callback with new position
                    if (onDragEnd) {
                        let updatedObject = {
                            ...selectedObject, 
                            x: Math.round(e.target.x()),
                            y: Math.round(e.target.y()),
                          }
                    onDragEnd({
                        x: Math.round(e.target.x()),
                        y: Math.round(e.target.y()),
                    });
                    setSelectedObject(updatedObject)
                    }
                }}
                onTransform={(e) => {
                    // const node = e.target;
                    // // Constrain proportions by setting both scaleX and scaleY to the same value
                    // const newScale = Math.max(node.scaleX(), node.scaleY()); // Pick the larger of the two for smooth scaling
                    // node.scaleX(newScale);
                    // node.scaleY(newScale);

                    const node = assetRef.current;

                    // Constrain proportions by setting both scaleX and scaleY to the same value
                    const newScale = Math.max(node.scaleX(), node.scaleY()); // Pick the larger of the two for smooth scaling
                    node.scaleX(newScale);
                    node.scaleY(newScale);

                    // Prevent width from going below 100 pixels
                    const newWidth = node.width() * node.scaleX(); // Calculate effective new width
                    if (newWidth < 350 ) {
                    // If new width is less than the minimum, set it to 100 and reset scaling
                    node.width(350);
                    node.scaleX(1);
                    node.scaleY(1);
                    }
                    
                  }}
                onTransformEnd={(e) => {
                    const node = e.target;
                    const newScaleX = node.scaleX();
                    const newScaleY = node.scaleY();
                    const newRotation = node.rotation();


                    
                    let newAssetHeight = Math.round(120 * newScaleY)
                    let newAssetWidth = Math.round(350 * newScaleX)
                       

                    // Reset the scale to avoid cumulative scaling
                    node.scaleX(1);
                    node.scaleY(1);
                    if (onTransformEnd) {
                        let updatedObject = {
                            ...selectedObject, 
                            id:asset.asset_uuid,
                            x: Math.round(node.x()),
                            y: Math.round(node.y()),
                            scaleX: newScaleX,
                            scaleY: newScaleY,
                            rotation: Math.round(newRotation),
                            assetHeight: newAssetHeight,
                            assetWidth: newAssetWidth,
                          }

                      onTransformEnd(updatedObject);
                      setSelectedObject(updatedObject)
                    }
                }}
                onMouseEnter={(e) => {
                    const container = e.target.getStage().container();
                    container.style.cursor = 'move';
                }}
                onMouseLeave={(e) => {
                const container = e.target.getStage().container();
                container.style.cursor = 'default';
                }}
            >
                <Rect
                    x={-10}
                    y={-10}
                    width={350}
                    height={120}
                    fill={isDark? '#334155':'#f1f5f9'}
                    cornerRadius={15} // Set the corner radius for rounded corners
                    shadowColor="black"
                    shadowBlur={5}
                    shadowOffset={{ x: 2, y: 2 }}
                    shadowOpacity={0.2}
                    // stroke= {assetActivity.state === 'running' ? '#16a34a':'#dc2626'}
                    // strokeWidth={4}
                />
                {imageStatus === 'loaded' && 
                    typeof imageDimensions.width === 'number' && 
                    typeof imageDimensions.height === 'number' &&
                        <KonvaImage 
                            image={image} 
                            width={imageDimensions.width} 
                            height={imageDimensions.height} 
                            // Apply clipping to round the corners
                            cornerRadius={999}
                        />
                    
                }
                {/* {imageStatus === 'failed' && (
                    <Text
                    text="Image failed to load"
                    fontSize={20}
                    fill="red"
                    />
                )} */}
                <Text
                    x={110}
                    y={5}
                    text={asset.asset_custom_name  ? asset.asset_custom_name :asset.machine_name}
                    fontSize={24}
                    fill={isDark? '#d1d5db':'#4b5563'}
                    width={220} // Set maximum width
                    ellipsis={true} // Enable truncation with ellipsis
                    wrap="none" // Prevent text wrapping
                    align="left"
                /> 
                <Text
                    x={110}
                    y={40}
                    text={asset.asset_custom_identifier  ? asset.asset_custom_identifier :asset.asset_uuid}
                    fontSize={22}
                    fill={isDark? '#d1d5db':'#4b5563'}
                    width={220} // Set maximum width
                    ellipsis={true} // Enable truncation with ellipsis
                    wrap="none" // Prevent text wrapping
                    align="left"
                    
                /> 
                {setLastOpTime(asset, assetActivity, timePassed)}
            </Group>
        </div>
    </div>
  )
}

export default AssetStatus


const setLastOpTime = (asset, assetActivity, timePassed) => {
    if(asset && Object.keys(asset).length > 0){
       
    
        if(assetActivity.state === 'running'){
            return (
                <>
                <Text
                    x={140}
                    y={75}
                    text={`Running`}
                    fontSize={24}
                    fill="#16a34a"
                    fontStyle="600"
                />
                <Circle
                    x={120}
                    y={85}
                    radius={15}
                    fill='#16a34a'
                />
                </>
            )
        }else if(assetActivity.state === 'stopped'){
            return (
                <>
                    <Text
                        x={140}
                        y={75}
                        text={`${timePassed}`}
                        fontSize={24}
                        fill="#dc2626"
                        fontStyle="600"
                    />
                    <Circle
                        x={120}
                        y={85}
                        radius={15}
                        fill='#dc2626'
                    />
                </>
                
            )
        }

    }

}
