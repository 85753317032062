import { motion, AnimatePresence } from "framer-motion";
import MessagesSection from "./MessagesSection";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import InfoSection from "./InfoSection";
import CreateNewChatSection from "./CreateNewChatSection";
import { apiLink } from "../../ApiCalls/ApisVariables";
import { getData } from "../../ApiCalls/DataApis";
import { darkModeState } from "../../atoms/darkModeState";
import { useRecoilValue } from "recoil";
import { insertMessageByDate, updateOrInsertMessageByDate, removeMessage } from "../../utils/MessageDataSorting";
import PinnedMessagesListSection from "./PinnedMessagesListSection";

function ChatDetails({chatList, getChatList, userInfo, mqttMessage, membersMqttMessage, allEmojis, interactionsEmojis, chatUnreadDic, setChatUnreadDic, leftColumnWidth, globalSearchMessage, setGlobalSearchMessage}) {
    const [selectedSubNav, setSelectedSubNav] = useState('messages')
    const [selectedSubSection, setSelectedSubSection] = useState('')
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const [currentConvo, setCurrentConvo] = useState([])
    const [currentChatId, setCurrentChatId] = useState('')
    const [pinnedMessages, setPinnedMessages] = useState({})
    const [messages, setMessages] = useState({})
    const [isGroup, setIsGroup] = useState(false)
    const [participants, setParticipants] = useState([])
    const [chatInfo, setChatInfo] = useState([])
    const [loadNewData, setLoadNewData] = useState(false)
    const isDark = useRecoilValue(darkModeState)
    const [loadingChatInfo, setLoadingChatInfo] = useState(false)
    const [loadingMembers, setLoadingMembers] = useState(false)
    const [loadingMessages, setLoadingMessages] = useState(false)
    const [loadingPinnedMessages, setLoadingPinnedMessages] = useState(false)
    const [showMessageData, setShowMessageData] = useState(true)
    
    
    

    useEffect(() => {

      let currentSearch = location.search
      setParticipants([])
      setChatInfo([])
      setMessages({})
      setPinnedMessages({})
      setShowMessageData(false)

      if(currentSearch){

        setLoadNewData(true)
        // Pulling to see how cards should be grouped
        let section = searchParams.get('section')
        let subSection = searchParams.get('sub_section')
        let selectedChatId = searchParams.get('chatId')


        setSelectedSubSection(subSection)
        setSelectedSubNav(section)
        setCurrentChatId(selectedChatId)

        if(selectedChatId){
          
            setLoadingChatInfo(true)
            // getData(apiLink + '/v1/chatrooms/' + selectedChatId)
            getData(apiLink + '/v1/chats/' + selectedChatId)
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data && data.data){
                  setChatInfo(data.data[0])
                  setLoadingChatInfo(false)
                  let chatroomId = data.data[0].chatroom_uuid
                  if(chatroomId){

                    setLoadingMembers(true)
                    // getData(apiLink + '/v1/chats/' + selectedChatId + '/members')
                    getData(apiLink + '/v1/chatrooms/' + chatroomId + '/members')
                    .then(async response => {
                      if(response.ok ){
                        const data = await response.json();
                        if(data && data.data && data.data[0]){
                          setParticipants(data.data[0].members)
                          setLoadingMembers(false)
                        }
                      }
                    })
                  
        
                    
                    setLoadingMessages(true)
                    // getData(apiLink + '/v1/chats/' + selectedChatId + '/messages')
                    getData(apiLink + '/v1/chatrooms/' + chatroomId + '/messages')
                    .then(async response => {
                      if(response.ok ){
                        const data = await response.json();
                        if(data && data.data){
                          setMessages(data.data)
                          // setMessages(data.messages_array)
                          setLoadingMessages(false)
                        }
                      }
                    })
                    
        
                    setLoadingPinnedMessages(true)
                    // getData(apiLink + '/v1/chats/' + selectedChatId + '/messages?message_is_pinned=true')
                    getData(apiLink + '/v1/chatrooms/' + chatroomId + '/messages?message_is_pinned=true')
                    .then(async response => {
                      if(response.ok ){
                        const data = await response.json();
                        if(data){
                          if(data.data){
                            setPinnedMessages(data.data)
                          }else{
                            setPinnedMessages({})
                          }
                          setLoadingPinnedMessages(false)
                        }
                      }
                    })
                  }
                }
              }
            })
          

          
            // setLoadingMembers(true)
            // // getData(apiLink + '/v1/chats/' + selectedChatId + '/members')
            // getData(apiLink + '/v1/chatrooms/' + selectedChatId + '/members')
            // .then(async response => {
            //   if(response.ok ){
            //     const data = await response.json();
            //     if(data && data.data && data.data[0]){
            //       // console.log('Members',data.data)
            //       setParticipants(data.data[0].members)
            //       setLoadingMembers(false)
            //     }
            //   }
            // })
          

            
            // setLoadingMessages(true)
            // // getData(apiLink + '/v1/chats/' + selectedChatId + '/messages')
            // getData(apiLink + '/v1/chatrooms/' + selectedChatId + '/messages')
            // .then(async response => {
            //   if(response.ok ){
            //     const data = await response.json();
            //     if(data && data.data){
            //       // console.log('Main data', data)
            //       // console.log('Messages data', data.data)
            //       // console.log('Messages Length', Object.keys(data.data).length)
            //       setMessages(data.data)
            //       // setMessages(data.messages_array)
            //       setLoadingMessages(false)
            //     }
            //   }
            // })
            

            // setLoadingPinnedMessages(true)
            // // getData(apiLink + '/v1/chats/' + selectedChatId + '/messages?message_is_pinned=true')
            // getData(apiLink + '/v1/chatrooms/' + selectedChatId + '/messages?message_is_pinned=true')
            // .then(async response => {
            //   if(response.ok ){
            //     const data = await response.json();
            //     if(data){
            //       if(data.data){
            //         setPinnedMessages(data.data)
            //       }else{
            //         setPinnedMessages({})
            //       }
            //       setLoadingPinnedMessages(false)
            //     }
            //   }
            // })
          
          
        }
      }else {
        setCurrentChatId('')
        setSelectedSubSection('')
        setSelectedSubNav('')
        setMessages({})
        setPinnedMessages({})
      }

    },[location])


    useEffect(() => {
      if(!loadingMessages && !loadingMembers && !loadingChatInfo){
        setShowMessageData(true)
      }else{
        setShowMessageData(false)
      }

    }, [loadingMessages, loadingMembers, loadingChatInfo])

    
    useEffect(() => {
      if(mqttMessage){
        
        let selectedChatId = searchParams.get('chatId')
        if(mqttMessage && mqttMessage.message && selectedChatId === mqttMessage.message.chat_uuid){
          
          if(mqttMessage.action === 'delete_message'){
            const updatedMessages = removeMessage(messages, mqttMessage.message)
            setMessages({...updatedMessages})
            
          }else if(mqttMessage.action === 'pin_message'){
            
            if(!mqttMessage.message.message_is_pinned_for_all){
              
              const updatedPinnedMessages = removeMessage(pinnedMessages, mqttMessage.message)
              // console.log('updatedMessages', updatedMessages)
              setPinnedMessages({...updatedPinnedMessages})
              
            }else{
              // console.log('Add pin message', mqttMessage)
              const updatedPinnedMessages = updateOrInsertMessageByDate(pinnedMessages, mqttMessage.message)
              // console.log('updatedMessages', updatedMessages)
              setPinnedMessages({...updatedPinnedMessages})
            }

          } else{
            
            // console.log('mqttMessage', mqttMessage)
            const updatedMessages = updateOrInsertMessageByDate(messages, mqttMessage.message)
            // console.log('updatedMessages', updatedMessages)
            setMessages({...updatedMessages})

          }
        }
      }

    },[mqttMessage])

    useEffect(() => {
      if(membersMqttMessage){
        let selectedChatId = searchParams.get('chatId')
        // console.log('Details mqttMessage', membersMqttMessage)
        if(membersMqttMessage.action === "update_members" && selectedChatId === membersMqttMessage.chat_uuid){
          // console.log('update_members mqttMessage', membersMqttMessage.members)
          setParticipants({...membersMqttMessage.members})

        } 

      }

    }, [membersMqttMessage])

    useEffect(() => {
      let selectedChatId = searchParams.get('chatId')
      // I need to make sure that all the states are properly updated before rending anything
      if(selectedChatId == currentChatId){
        if(participants && Object.keys(participants).length > 0 && chatInfo && Object.keys(chatInfo).length > 0){
          setLoadNewData(false)
        }
      }else{
        setLoadNewData(true)
      }

    }, [participants, chatInfo, currentChatId])


    
  return (
    <div className=''>
    {/* <div className='overflow-y-scroll scrollbar-hide'> */}
        {selectedSubSection == 'create_new_chat' && 
            subLinkPage('createNewChat',messages, participants, userInfo, chatInfo, getChatList, mqttMessage,allEmojis, interactionsEmojis, setParticipants, setMessages, chatUnreadDic, setChatUnreadDic, leftColumnWidth, globalSearchMessage, setGlobalSearchMessage, pinnedMessages, setPinnedMessages)
        }
        {selectedSubSection != 'create_new_chat' && showMessageData && !loadNewData &&  selectedSubNav && participants && Object.keys(participants).length > 0 && chatInfo && Object.keys(chatInfo).length > 0 &&
            subLinkPage(selectedSubNav,messages, participants, userInfo, chatInfo, getChatList, mqttMessage,allEmojis, interactionsEmojis, setParticipants, setMessages, chatUnreadDic, setChatUnreadDic, leftColumnWidth, globalSearchMessage, setGlobalSearchMessage, pinnedMessages, setPinnedMessages)
        }
        {!selectedSubSection && !selectedSubNav && !currentChatId &&
          <div className='flex justify-center items-center mt-20'>
            <div className='px-4 py-2 bg-slate-100 dark:bg-slate-700 text-gray-500 dark:text-gray-300 rounded-xl'>
              Select chat to start messaging
            </div>
          </div>
        }
    </div>
  )
}

export default ChatDetails


const subLinkPage = (link, messages, participants, userInfo, chatInfo, getChatList, mqttMessage,allEmojis, interactionsEmojis, setParticipants, setMessages, chatUnreadDic, setChatUnreadDic, leftColumnWidth, globalSearchMessage, setGlobalSearchMessage,pinnedMessages, setPinnedMessages) => {
  
    const allLinks = {
        messages: { link: "messages", content: <MessagesSection messages={messages} participants={participants} userInfo={userInfo} chatInfo={chatInfo} getChatList={getChatList} mqttMessage={mqttMessage} allEmojis={allEmojis} interactionsEmojis={interactionsEmojis} setMessages={setMessages} chatUnreadDic={chatUnreadDic} setChatUnreadDic={setChatUnreadDic} leftColumnWidth={leftColumnWidth} globalSearchMessage={globalSearchMessage} setGlobalSearchMessage={setGlobalSearchMessage} pinnedMessages={pinnedMessages} setPinnedMessages={setPinnedMessages} /> },
        info: { link: "info", content: <InfoSection  participants={participants} chatInfo={chatInfo} userInfo={userInfo} setParticipants={setParticipants} getChatList={getChatList} /> },
        createNewChat: { link: "createNewChat", content: <CreateNewChatSection  getChatList={getChatList} /> },
        pinnedMessagesList: { link: "pinnedMessagesList", content: <PinnedMessagesListSection  getChatList={getChatList} pinnedMessages={pinnedMessages} setPinnedMessages={setPinnedMessages} userInfo={userInfo} participants={participants} chatInfo={chatInfo} messages={messages} setMessages={setMessages} mqttMessage={mqttMessage} allEmojis={allEmojis} interactionsEmojis={interactionsEmojis} /> },
    };

      return (
        <AnimatePresence mode='wait'>
        <motion.div
        key={allLinks[link].link}
          initial={{ x: 10, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {allLinks[link].content}
        </motion.div>
      </AnimatePresence>
    )  

            
}