import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import {status} from'../data/StatusList'
import CardLargeComponent from "./CardLargeComponent";
import { globalSelectedOrganization } from "../atoms/orgStates";
import { useRecoilValue } from "recoil";
import { getData } from "../ApiCalls/DataApis";
import { apiLink } from "../ApiCalls/ApisVariables";
import { refreshOrganizationApis } from "../atoms/refreshStates"

highchartsMore(Highcharts);
solidGauge(Highcharts);




function CardAssetLargeView({assetList, getOrgAssets, grayOutStatus, mqttMessageNodeUUID, mqttMessageTimestamp, isUserAuthorized, isMobile}) {
    const navigate = useNavigate();
    const location = useLocation();
    const isOrganizationApisRefreshed = useRecoilValue(refreshOrganizationApis)
    const [searchParams, setSearchParams] = useSearchParams()
    const [isGrouped, setIsGrouped] = useState('')
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [allMachineTypes, setAllMachineTypes] = useState([])
    const [allDepartments, setAllDepartments] = useState([])


    useEffect(() =>{
        try {
            if(selectedOrganization){
                let currentSearch = location.search
                getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/machine_types' + currentSearch )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            setAllMachineTypes(data.data)
                        }
                    }
                })

                getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/departments' + currentSearch )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            setAllDepartments(data.data)
                        }
                    }
                })
            }
            
        } catch (error) {
        console.log(error.message)
        }
    }, [selectedOrganization, isOrganizationApisRefreshed])

    useEffect(() => {
    // Gettting search parameters from URL
        let currentSearch = location.search
        if(currentSearch){
            // Pulling to see how cards should be grouped
            let selectedGroupBy = searchParams.get('group_by')
            
            if(selectedGroupBy){
                setIsGrouped(selectedGroupBy)
            }else{
                setIsGrouped('')
            }
        }else {
            setIsGrouped('')
        }

    }, [location])

    
    return (
        <div>

            {/* {isGrouped == 'department' && 
                allDepartments.map((department) => (
                    <div key={department.department_uuid} className='mt-4'>
                        {buildHeader(assetList,department, isGrouped)}
                        <div className="flex flex-wrap mt-[-10px]">
                            {assetList.map((asset,index) => (
                                asset.department_name == department.department_name && (
                                <div key={asset.asset_uuid} className="overflow-hidden mr-1 mb-2" >
                                    <CardLargeComponent asset={asset} index={index} getOrgAssets={getOrgAssets} grayOutStatus={grayOutStatus} mqttMessageNodeUUID={mqttMessageNodeUUID} mqttMessageTimestamp={mqttMessageTimestamp} isUserAuthorized={isUserAuthorized} isMobile={isMobile} />
                                </div>
                                )
                            ))}
                        </div>
                    </div>
                    
                ))} */}
            {(isGrouped == 'department') && 
                <div className='flex flex-col'>
                    {allDepartments.map((department) => (
                        <div key={department.department_uuid} className='mt-2'>
                            {buildHeader(assetList,department, isGrouped)}
                            <div className='card-large-container'> 
                                {assetList.map((asset,index) => (
                                    asset.department_name == department.department_name && (
                                        <CardLargeComponent key={asset.asset_uuid}  asset={asset} index={index} getOrgAssets={getOrgAssets} grayOutStatus={grayOutStatus} mqttMessageNodeUUID={mqttMessageNodeUUID} mqttMessageTimestamp={mqttMessageTimestamp} isUserAuthorized={isUserAuthorized} isMobile={isMobile} />
                                    )
                                ))}
                            </div>
                        </div>
                        
                    ))}
                </div>
            }
             {(isGrouped == 'status') && 
            <div className='flex flex-col'>
                {status.map((currentStatus) => (
                    <div key={currentStatus.id} className='mt-2'>
                        {buildHeader(assetList,currentStatus, isGrouped)}
                        <div className="card-large-container">
                            {assetList.map((asset,index) => (
                                <Fragment key={asset.asset_uuid }>
                                    {asset.asset_is_producing && currentStatus.id === 'running' && (
                                            <CardLargeComponent asset={asset} index={index} getOrgAssets={getOrgAssets} grayOutStatus={grayOutStatus} mqttMessageNodeUUID={mqttMessageNodeUUID} mqttMessageTimestamp={mqttMessageTimestamp} isUserAuthorized={isUserAuthorized} isMobile={isMobile} />
                                    )}
                                    {!asset.asset_is_producing && currentStatus.id === 'stopped' && (
                                        <div className='overflow-hidden mr-1 mb-2'>
                                            <CardLargeComponent asset={asset} index={index} getOrgAssets={getOrgAssets} grayOutStatus={grayOutStatus} mqttMessageNodeUUID={mqttMessageNodeUUID} mqttMessageTimestamp={mqttMessageTimestamp} isUserAuthorized={isUserAuthorized} isMobile={isMobile} />
                                        </div>
                                    )}
                                </Fragment>
                            ))}
                        </div>
                    </div>
                    
                ))}
            </div>
            }
            {(isGrouped == 'machine_type' && allMachineTypes && Object.keys(allMachineTypes).length > 0) &&
            <div className='flex flex-col'>
                {allMachineTypes.map((currentType) => (
                    <div key={currentType.machine_type} className='mt-2'>
                        {buildHeader(assetList,currentType, isGrouped)}
                    <div className="card-large-container"> 
                        {assetList.map((asset,index) => (
                            asset.machine_machine_type_identifier == currentType.machine_type && (
                                <CardLargeComponent key={asset.asset_uuid} asset={asset} index={index} getOrgAssets={getOrgAssets} grayOutStatus={grayOutStatus} mqttMessageNodeUUID={mqttMessageNodeUUID} mqttMessageTimestamp={mqttMessageTimestamp} isUserAuthorized={isUserAuthorized} isMobile={isMobile} />
                            )
                        ))}
                    </div>
                    </div>
                    
                ))}
            </div>
            }
            {isGrouped == '' && 
                // <div className='flex flex-wrap mt-[-10px]'> 
                // <div style={{display:"grid", gap:'16px', gridTemplateColumns: 'repeat(auto-fit, minmax(375px, 1fr))'}}> 
                <div className="card-large-container"> 
                    {assetList.map((asset,index) => (
                        <CardLargeComponent key={asset.asset_uuid} asset={asset} index={index} getOrgAssets={getOrgAssets} grayOutStatus={grayOutStatus} mqttMessageNodeUUID={mqttMessageNodeUUID} mqttMessageTimestamp={mqttMessageTimestamp} isUserAuthorized={isUserAuthorized} isMobile={isMobile} />
                        // {/* <div key={asset.asset_uuid} className='overflow-hidden mr-1 mb-2'> */}
                     ))}
                </div>
            }
            
        </div>
    )

    
    
    
}

export default CardAssetLargeView

const buildHeader = (assetList, type, isGrouped) => {
 
    

    for(let i = 0; i < Object.keys(assetList).length; i++){
        if(isGrouped == 'department' && assetList[i].department_uuid == type.department_uuid){
            return (
                <div className='relative mt-5 mb-[15px]'>
                    <div className='mb-[-15px] border-[1px] border-dashed border-t-gray-400 dark:border-slate-800' />
                    <div className='text-sm text-gray-400 dark:text-gray-300 border-2 border-gray-200 dark:border-slate-600  py-0 px-3 w-fit rounded-full bg-white dark:bg-slate-600 ml-6'>{type.department_name}</div>
                </div>
            )
        }
        if(isGrouped == 'status' && assetList[i].asset_is_producing && type.id === 'running'){
            return (
                <div className='relative mt-5 mb-[15px]'>
                    <div className='mb-[-15px] border-[1px] border-dashed border-t-gray-400 dark:border-slate-800' />
                    <div className='text-sm text-gray-400 dark:text-gray-300 border-2 border-gray-200 dark:border-slate-600  py-0 px-3 w-fit rounded-full bg-white dark:bg-slate-600 ml-6'>{type.name}</div>
                </div>
            )
        }
        if(isGrouped == 'status' && !assetList[i].asset_is_producing && type.id === 'stopped'){
            return (
                <div className=' relative mt-5 mb-[15px]'>
                    <div className='mb-[-15px] border-[1px] border-dashed border-t-gray-400 dark:border-slate-800' />
                    <div className='text-sm text-gray-400 dark:text-gray-300 border-2 border-gray-200 dark:border-slate-600  py-0 px-3 w-fit rounded-full bg-white dark:bg-slate-600 ml-6'>{type.name}</div>
                </div>
            )
        }
        if(isGrouped == 'machine_type' && assetList[i].machine_machine_type_identifier == type.machine_type){
            return (
                <div className=' relative mt-5 mb-[15px]'>
                    <div className='mb-[-15px] border-[1px] border-dashed border-t-gray-400 dark:border-slate-800' />
                    <div className='text-sm text-gray-400 dark:text-gray-300 border-2 border-gray-200 dark:border-slate-600  py-0 px-3 w-fit rounded-full bg-white dark:bg-slate-600 ml-6'>{type.machine_type}</div>
                </div>
            )
        }
    }

   
}


