import React, { useEffect, useState } from 'react'
import ImageZoomCrop from '../../components/modals/ImageZoomCrop'
import { useSearchParams } from 'react-router-dom'
import { putData } from '../../ApiCalls/DataApis'
import { apiLink } from '../../ApiCalls/ApisVariables'
import { toast } from 'react-hot-toast'

function EditChat({chatInfo, name, image, setIsEditingGroup}) {
    const [groupImage, setGroupImage] = useState(image)
    const [groupName, setGroupName] = useState(name)
    const [loadingEdit, setLoadingEdit] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        setGroupImage(image)
        setGroupName(name)

    }, [name, image])

    const handleNavToMessages = () => {
        let select = searchParams.getAll('select')
        let chatId = searchParams.getAll('chatId')
        let platform = searchParams.getAll('platform')

        setSearchParams({select,chatId, section: 'messages', platform})
    }


    const handleUpdateGroupImage = (image) => {
        setGroupImage(image)
      }

      function isUrlOrBase64(state) {
        const urlRegex = /^(http|https):\/\//;
        const base64Regex = /^data:image\/(png|jpeg|gif);base64,/;
        if(urlRegex.test(state)){
            return false
        }else if(base64Regex.test(state)){
            return true
        }else{
            return false
        }
      }

    const submitNewEdits = () => {
        try {
            if(!loadingEdit){
              setLoadingEdit(true)
                let newImage = null
              const isBase64 = isUrlOrBase64(groupImage); // true (base64)
              if(isBase64){
                newImage = groupImage
              }

              putData(apiLink + '/v1/chats/' + chatInfo.chat_uuid , 
              {'chat_name': groupName,
              'chat_picture': newImage})
                .then(async response => {
                  const data = await response.json();
                  if(response.ok){
                    setLoadingEdit(false)
                    setIsEditingGroup(false)
                    toast.success('Chat updated')
                  }
                  else{
                    try{
                      setLoadingEdit(false)
                      toast.error(data.message)
                    }catch (e){
                      toast.error(e.message)
                      setLoadingEdit(false)
                    }
                  }
                })
            }
            
          } catch (e) {
            toast.error(e.message)
          }
    }
  


  return (
    <>
        <div className='border-b-[0.01em] border-slate-200 dark:border-slate-600 px-2 py-1.5 bg-white dark:bg-slate-900'>
            <div className='grid grid-cols-3 '>
                <ol className='flex '>
                    <li className='breadcrumbsNav flex items-center' onClick={handleNavToMessages} >
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                            </svg>
                        </div>
                        <span className='text-sm'>Back</span>
                    </li>
                </ol>
                <div className='text-gray-600 dark:text-gray-300 text-center'>Info</div>
                <div className='flex items-center justify-end'>
                    <button 
                        className='relative text-right text-sm pl-2 text-blue-500 dark:text-blue-400 enabled:hover:text-blue-600 enabled:dark:hover:text-blue-500 disabled:text-blue-400/80 disabled:dark:text-blue-500/80' 
                        disabled={groupName.length === 0 }
                        onClick={submitNewEdits}
                    >
                        Done
                        {loadingEdit && 
                            <div >
                                <svg aria-hidden="true" className={`absolute top-0 right-0 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                </svg>
                            </div>
                        }
                    </button>

                </div>
            </div>
        </div>
        <div className='max-w-2xl mx-auto pb-5'>
            <div className='h-40 w-40 mx-auto mt-5'>
                <ImageZoomCrop currentPicture={groupImage} submitType={'largeGroupImage'} title={'Group Image'} isDisabled={true} isRounded={false} updateInfo={handleUpdateGroupImage} />
            </div>
            <div className='bg-slate-100 dark:bg-slate-800 rounded-xl px-4 py-2 mt-4 mx-4'>
                <div className='flex items-center'>
                    <input 
                        className={`bg-transparent text-gray-600 border-0 border-gray-300 focus:ring-[0px] focus:ring-slate-200 focus:border-slate-300  placeholder:text-gray-500 
                        dark:text-gray-300 dark:placeholder:text-gray-400
                        block w-full  text-sm rounded-lg text-wrap py-2`}
                        // className={`block bg-transparent w-full border rounded-md py-0.5 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800`}
                    value={groupName} 
                    onChange={(e) => setGroupName(e.target.value.slice(0, 80))}
                    type='text' 
                    placeholder="Group Name" 
                    autoFocus
                    />
                </div>
            </div>

        </div>
    </>
  )
}

export default EditChat