export const statusOptions = [
    { 
        id:0, 
        workorder_status_name: 'Open', 
        workorder_status_uuid: 'WORKORDER_STATUS_OPEN', 
        color: 'text-white bg-blue-600', 
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960" fill="currentColor">
                <path xmlns="http://www.w3.org/2000/svg" d="M240-160h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM240-160v-400 400Zm0 80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h280v-80q0-83 58.5-141.5T720-920q71 0 124 43t70 108q5 17-6.5 33T880-720q-17 0-28-7t-16-23q-11-38-42.5-64T720-840q-50 0-85 35t-35 85v80h120q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Z"/>            
            </svg> 
            )
    },
    { 
        id:1, 
        workorder_status_name: 'In Progress', 
        workorder_status_uuid: 'WORKORDER_STATUS_IN_PROGRESS', 
        color: 'text-blue-950 bg-blue-200', 
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960" fill="currentColor">
                <path xmlns="http://www.w3.org/2000/svg" d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-70q0-17 11.5-28.5T760-800q17 0 28.5 11.5T800-760v200q0 17-11.5 28.5T760-520H560q-17 0-28.5-11.5T520-560q0-17 11.5-28.5T560-600h128q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q68 0 124.5-34.5T692-367q8-14 22.5-19.5t29.5-.5q16 5 23 21t-1 30q-41 80-117 128t-169 48Z"/>
            </svg> 
            )
    },
    { 
        id:2, 
        workorder_status_name: 'On Hold', 
        workorder_status_uuid: 'WORKORDER_STATUS_ON_HOLD', 
        color: 'text-amber-950 bg-amber-200 ', 
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960" fill="currentColor">
                <path d="M400-320q17 0 28.5-11.5T440-360v-240q0-17-11.5-28.5T400-640q-17 0-28.5 11.5T360-600v240q0 17 11.5 28.5T400-320Zm160 0q17 0 28.5-11.5T600-360v-240q0-17-11.5-28.5T560-640q-17 0-28.5 11.5T520-600v240q0 17 11.5 28.5T560-320ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
            </svg> 
            )
    },
    { 
        id:3, 
        workorder_status_name: 'Done', 
        workorder_status_uuid: 'WORKORDER_STATUS_CLOSED', 
        color: 'text-green-950 bg-green-400 dark:text-white dark:bg-green-700', 
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className='w-4 h-4' viewBox="0 -960 960 960" fill="currentColor">
                <path xmlns="http://www.w3.org/2000/svg" d="m382-354 339-339q12-12 28-12t28 12q12 12 12 28.5T777-636L410-268q-12 12-28 12t-28-12L182-440q-12-12-11.5-28.5T183-497q12-12 28.5-12t28.5 12l142 143Z"/>
            </svg> 
            )
    },
  ];

export const statusXLOptions = [
    { 
        id:0, 
        workorder_status_name: 'Open', 
        workorder_status_uuid: 'WORKORDER_STATUS_OPEN', 
        color: 'text-white bg-blue-600', 
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 -960 960 960" fill="currentColor">
                <path xmlns="http://www.w3.org/2000/svg" d="M240-160h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM240-160v-400 400Zm0 80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h280v-80q0-83 58.5-141.5T720-920q71 0 124 43t70 108q5 17-6.5 33T880-720q-17 0-28-7t-16-23q-11-38-42.5-64T720-840q-50 0-85 35t-35 85v80h120q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Z"/>            
            </svg> 
            )
    },
    { 
        id:1, 
        workorder_status_name: 'In Progress', 
        workorder_status_uuid: 'WORKORDER_STATUS_IN_PROGRESS', 
        color: 'text-blue-950 bg-blue-200', 
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 -960 960 960" fill="currentColor">
                <path xmlns="http://www.w3.org/2000/svg" d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-70q0-17 11.5-28.5T760-800q17 0 28.5 11.5T800-760v200q0 17-11.5 28.5T760-520H560q-17 0-28.5-11.5T520-560q0-17 11.5-28.5T560-600h128q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q68 0 124.5-34.5T692-367q8-14 22.5-19.5t29.5-.5q16 5 23 21t-1 30q-41 80-117 128t-169 48Z"/>
            </svg> 
            )
    },
    { 
        id:2, 
        workorder_status_name: 'On Hold', 
        workorder_status_uuid: 'WORKORDER_STATUS_ON_HOLD', 
        color: 'text-amber-950 bg-amber-200 ', 
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 -960 960 960" fill="currentColor">
                <path d="M400-320q17 0 28.5-11.5T440-360v-240q0-17-11.5-28.5T400-640q-17 0-28.5 11.5T360-600v240q0 17 11.5 28.5T400-320Zm160 0q17 0 28.5-11.5T600-360v-240q0-17-11.5-28.5T560-640q-17 0-28.5 11.5T520-600v240q0 17 11.5 28.5T560-320ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
            </svg> 
            )
    },
    { 
        id:3, 
        workorder_status_name: 'Done', 
        workorder_status_uuid: 'WORKORDER_STATUS_CLOSED', 
        color: 'text-green-900 bg-green-400 dark:text-white dark:bg-green-700', 
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 -960 960 960" fill="currentColor">
                <path xmlns="http://www.w3.org/2000/svg" d="m382-354 339-339q12-12 28-12t28 12q12 12 12 28.5T777-636L410-268q-12 12-28 12t-28-12L182-440q-12-12-11.5-28.5T183-497q12-12 28.5-12t28.5 12l142 143Z"/>
            </svg> 
            )
    },
  ];