import { useRef, useState } from 'react'
import App from './App';
import LoginRoutes from './MyLoginPage/LoginRoutes';
import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useLocation, useSearchParams } from 'react-router-dom'
import MqttService from './core/services/MqttService';
import { toast } from 'react-hot-toast';

function CredentialsRouting() {
    const [user, setUser] = useState(null)
    const [hasReadUser, setHasReadUser] = useState(true)
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const [token, setToken] = useState('')
    // const [isUserOnline, setIsUserOnline] = useState(true)
    const [mqttMessage, setMqttMessage] = useState([])
    const dataFetchedRef = useRef(false);
    // const [isInternetConnected, setIsInternetConnected] = useState(navigator.onLine);
    


    useEffect(() => {
      Auth.currentAuthenticatedUser()
          .then(user => {
              setUser(user)
              setHasReadUser(true)
          })
          .catch(
              setHasReadUser(true)
          )

  }, [])
   

    useEffect(() => {
      const intervalId = setInterval(() => {
        if((user && Object.keys(user).length > 0) || token){
          connectMQTTGlobally()
        }
        }, 2000);
  
      return () => clearInterval(intervalId);
    }, [user, token]);


    // useEffect(() => {
    //   const handleOnlineStatusChange = () => setIsInternetConnected(navigator.onLine);
    //   window.addEventListener('online', handleOnlineStatusChange);
    //   window.addEventListener('offline', handleOnlineStatusChange);
  
    //   // Cleanup function to remove event listeners on unmount
    //   return () => {
    //     window.removeEventListener('online', handleOnlineStatusChange);
    //     window.removeEventListener('offline', handleOnlineStatusChange);
    //   };
    // }, []); // Empty dependency array to run only once
  

   

    useEffect(() => {
        let urlToken = searchParams.getAll('url_token')
        let passedToken = searchParams.getAll('passed_token')
        if(urlToken && urlToken[0]){
            setToken(urlToken[0])
        }else if(passedToken && passedToken[0]){
            setToken(passedToken[0])
        }
    }, [])
    
   

    // useEffect(() => {
    //   // if(isInternetConnected){
    //     if((user && Object.keys(user).length > 0) || token){
    //         if (dataFetchedRef.current) return;
    //         dataFetchedRef.current = true;
    //         connectMQTTGlobally();
            
    //     }
    //   // }
    // }, [token, user])

    const connectMQTTGlobally = () => {
        // console.log('connectMQTTGlobally')
        MqttService.connectClient(
        mqttSuccessHandler,
        mqttConnectionLostHandler
        );
    }

    const mqttSuccessHandler = () => {
        console.log("connected to mqtt");
        // setIsUserOnline(true)
        toast.dismiss(); // Dismiss any existing toast
        if (user && Object.keys(user).length > 0) {
        // if (MqttService.isConnected && user && Object.keys(user).length > 0) {
          // console.log('Subcribe to messaging')
          // console.log(`v1/iotflows/to_user/${user.username}/messaging/#`)
          MqttService.subscribe(`v1/iotflows/to_user/${user.username}/messaging/#`, onMQTTReceived)
        }else if(token){
          try {
            // console.log('token',token)
            // Split the token by '.'
            const tokenParts = token.split('.');
        
            // Get the second part (index 1)
            const base64Payload = tokenParts[1];
        
            // Decode the Base64 string
            const decodedPayload = atob(base64Payload);
        
            // Parse the decoded string into a JSON object
            const payloadObject = JSON.parse(decodedPayload);
        
            // Extract and return the 'username' from the JSON
            MqttService.subscribe(`v1/iotflows/to_user/${payloadObject.username}/messaging/#`, onMQTTReceived)
          } catch (error) {
            console.error("Error decoding token or extracting username:", error);
            return null;
          }
        

        }
    };
    
    const mqttConnectionLostHandler = () => {
        console.log("lost connection to mqtt");
        toast.loading('Trying to connect to the internet...')
        // setIsUserOnline(false)
        // ToDo we probably need to have a timer to keep this trying to connect back to MQTT, or within the fucntion or globally 
        // connectMQTTGlobally()
    };

    const onMQTTReceived = message => {
        try{
            var payloadMessage = message.payloadString
            // console.log('Outside MQTT asset', payloadMessage)
            try { payloadMessage = JSON.parse(payloadMessage) } catch(e){}
            // console.log('payloadMessage', payloadMessage)
            // parse the payload
            if(payloadMessage && payloadMessage.version == 1){
              // console.log('action payloadMessage', payloadMessage.action)
    
              switch (payloadMessage.action)
              {
                case 'post_message':
                  if(payloadMessage.message)
                  {
                    // console.log('New message:', payloadMessage.message)
                    setMqttMessage(payloadMessage)
                    // mqtt_message = payloadMessage.message
                    
    
                  }
                  break;
                case 'edit_message':
                  if(payloadMessage.message)
                  {
                    // console.log('Edit message:', payloadMessage.message)
                    setMqttMessage(payloadMessage)
                    // mqtt_message = payloadMessage.message
                    
    
                  }
                  break;
                case 'delete_message':
                  if(payloadMessage.message)
                  {
                    // console.log('Edit message:', payloadMessage.message)
                    setMqttMessage(payloadMessage)
                    // mqtt_message = payloadMessage.message
                    
    
                  }
                  break;
                case 'pin_message':
                  if(payloadMessage.message)
                  {
                    // console.log('Edit message:', payloadMessage.message)
                    setMqttMessage(payloadMessage)
                    // mqtt_message = payloadMessage.message
                    
    
                  }
                  break;
                case 'update_emoji_interaction':
                  if(payloadMessage.message)
                  {
                    // console.log('New Emoji:', payloadMessage.message)
                    setMqttMessage(payloadMessage)
                    // mqtt_message = payloadMessage.message
                    
    
                  }
                  break;
                case 'delete_member':
                    // console.log('Deleted Members', payloadMessage.message)
                    setMqttMessage(payloadMessage)
                    // mqtt_message = payloadMessage.message
                  break;
                case 'update_members':
                    // console.log('Updated Members', payloadMessage.message)
                    setMqttMessage(payloadMessage)
                  break;
              }
    
            }
    
    
          }
          catch(e){
            console.log("Error in reading MQTT");
            console.log(e.message);
          }
    }

    
    if(location && location.state && location.state.userLogin){
        // console.log('location.state.userLogin', location.state.userLogin)
        setUser(location.state.userLogin)
    }

    //   console.log('Is there internet navigator.onLine', navigator.onLine)

  return (
    <div className='scrollbar-hide'>
        {token || (hasReadUser && user && Object.keys(user).length > 0) ? (
            <App mqttMessage={mqttMessage} />
        ):(
            <LoginRoutes  />
        )}
    </div>
  )
}

export default CredentialsRouting