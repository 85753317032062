import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import '../../styles/ScrollbarStyles.css'
import { formatDateToDayMonthYear } from '../../utils/DateFormating'
import MessageComponent from './MessageComponent'
import { darkModeState } from '../../atoms/darkModeState'
import { useRecoilValue } from 'recoil'
import PinnedContextMenu from '../../utils/PinnedContextMenu'
import MqttService from '../../core/services/MqttService'
import { createMessagingMQTT } from '../../core/services/MqttMessaging'
import { toast } from 'aws-amplify'

function PinnedMessagesListSection({pinnedMessages, setPinnedMessages, messages, setMessages, userInfo, participants, chatInfo, mqttMessage, allEmojis, interactionsEmojis}) {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()
    const isDark = useRecoilValue(darkModeState)
    const [headerDate, setHeaderDate] = useState(null)
    const [isVisible, setIsVisible] = useState(false);
    const timeoutRef = useRef(null); // Ref to hold the timeout ID
    const pinnedMessagesRef = useRef(null); // Ref to hold the timeout ID
    const [contextMenu, setContextMenu] = useState(null);
    const goToBottomButtonRef = useRef(null);
    const processedRef = useRef(null);
    const contextMenuRef = useRef(null);

    let elementsArray = []
    let prevElementsArray = []



    const handleNavToMessages = () => {
        let select = searchParams.getAll('select')
        let chatId = searchParams.getAll('chatId')
        let platform = searchParams.getAll('platform')

        setSearchParams({select,chatId, section: 'messages', platform})
    }


    useEffect(() => {
        const handleScroll = () => {
            clearTimeout(timeoutRef.current);
            setIsVisible(true);
            timeoutRef.current = setTimeout(() => setIsVisible(false), 3000);
        };

          const targetElement = document.getElementById('scrollable-pinned-element');

            // Add scroll event listener when component mounts
            targetElement.addEventListener('wheel', handleScroll);

        // Remove scroll event listener when component unmounts
        return () => {
            targetElement.removeEventListener('wheel', handleScroll);
        };
    }, []);


    useEffect(() => {
        let currentSearch = location.search
            if(currentSearch){
                elementsArray = []
                setHeaderDate(null)
            }

    },[location])


    // Function to handle intersection change
    const handleIntersection = (inView, entry) => {

        const messageDate = entry.target.getAttribute('data-date');
        if (inView) {
          elementsArray.push(messageDate)
          // console.log('elementsArray THis will give the all the dates in the View of the DOM', elementsArray)
        }else {
            const newArray = elementsArray.filter(element => element !== messageDate);
            elementsArray = newArray
        }
        let oldDate = getOldestDate(elementsArray)
        setHeaderDate(oldDate);

        // if(isInitalLoad){
        //   setTimeout(() => {
        //     getNewestDateReadUntil(elementsArray)
        //     setIsInitalLoad(false)
        //   }, 2000);
        // }else{
        //   getNewestDateReadUntil(elementsArray)
        // }
        
    };

    function getOldestDate(dates) {
        // Check if the array is empty or contains non-date objects
        if (elementsArray.length === 0 ) {
          return null; // Return null for invalid input
        }
      
        // Initialize oldestDate with the first element (assuming it's a date)
        let oldestDate = dates[0];
      
        // Loop through the remaining dates
        for (let i = 1; i < dates.length; i++) {
          const currentDate = dates[i];
          const currentDateMilliseconds = new Date(dates[i]); // Parse the first string
            const oldestDateMilliseconds = new Date(oldestDate); // Parse the second string
          if (currentDateMilliseconds < oldestDateMilliseconds) { // Compare timestamps using < operator
            oldestDate = currentDate;
          }
        }
        
        return oldestDate;
    }

    const handleRightClick = (event, message, message_username, user_username) => {
        event.preventDefault();
        // console.log('message', message)
        if(contextMenu){
          setContextMenu(null);
        }else{
          setContextMenu({
            x: event.clientX,
            y: event.clientY,
            message,
            message_username,
            user_username
          });
        }
    };

    const handleCloseContextMenu = () => {
        // console.log('CLOSE MENUY')
        setContextMenu(null);
      };

    const handleAddEmoji = (emoji) => {
      // console.log('Adding emoji to:', contextMenu.message);
      // Implement the add emoji functionality
      

      let replyMessageObject = contextMenu.message
      if (MqttService.isConnected) {

        let msg = {
            from_username: userInfo.user_username,
            action: 'update_emoji_interaction',
            message_uuid: replyMessageObject.message_uuid,
            emoji_codepoints: emoji.emoji_codepoints
        }
        
        msg = createMessagingMQTT(msg);

        if(msg.topic && msg.payload){
            MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
        }
        setContextMenu(null);
      }else{
        toast.error('Trying to connect')
      }
    }

   


  return (
    <div>
        <div className='border-b-[0.01em] border-slate-200 dark:border-slate-600 px-2 py-1.5 bg-white dark:bg-slate-900'>
            <div className='grid grid-cols-3 '>
                <ol className='flex '>
                    <li className='breadcrumbsNav flex items-center' onClick={handleNavToMessages} >
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                            </svg>
                        </div>
                        <span className='text-sm'>Back</span>
                    </li>
                </ol>
                <div className='text-gray-600 dark:text-gray-300 text-center'>Pinned Messages</div>
                <div/>
                {/* <button 
                    className='breadcrumbsNav text-right text-sm'
                    onClick={() => setIsEditingGroup(true)}
                >
                    Edit
                </button> */}
            </div>
        </div>
        <div ref={pinnedMessagesRef} id="scrollable-pinned-element" className={`overflow-x-hidden overflow-y-scroll message_window_scrollbar ${isDark ? 'scrollbar_dark' : 'scrollbar_light'} `} style={{overflowAnchor: 'auto', height: 'calc(100vh - 130px)'}} >
              <div 
                  className={`ease-in-out duration-300  ${isVisible && headerDate ? 'opacity-100':'opacity-0 '} absolute top-16 inset-x-0 text-gray-300 w-full justify-center z-[30]`}
              >
                  <div className='px-4 py-1 text-sm bg-white dark:bg-slate-900/95 w-fit mx-auto rounded-full text-gray-500 dark:text-gray-400'>
                  {formatDateToDayMonthYear(headerDate)}
                  </div>
              </div>
              <div 
                className='mt-2' 
              >
                  <div onClick={handleCloseContextMenu}>
                    {pinnedMessages && Object.keys(pinnedMessages).length > 0 &&
                      Object.keys(pinnedMessages).map((message, index) => (
                        <MessageComponent 
                            key={`${pinnedMessages[message].message_uuid}`}
                            messages={pinnedMessages} 
                            message={pinnedMessages[message]} 
                            participants={participants} 
                            index={index} 
                            onIntersectionChange={handleIntersection}
                            isGroup={!chatInfo.chat_is_direct}
                            chatInfo={chatInfo}
                            userInfo={userInfo}
                            onRightClick={handleRightClick}
                            contextMenu={contextMenu}
                            mqttMessage={mqttMessage}
                            setMessages={setPinnedMessages}
                            lastMessageReadUntilDate={''}
                            lastMessageReadUntilUuid={''}
                            messagesRef={pinnedMessagesRef}
                            goToBottomButtonRef={goToBottomButtonRef}
                            navigateSearchMessageUuid={''}
                            setNavigateSearchMessageUuid={''}
                            setGlobalSearchMessage={null}
                            processedRef={processedRef}
                        />
                      ))
                    }
                </div>
                {contextMenu && (
                  <div ref={contextMenuRef}>
                    <PinnedContextMenu
                      contextMenu={contextMenu}
                      setContextMenu={setContextMenu}
                      x={contextMenu.x}
                      y={contextMenu.y}
                      onAddEmoji={handleAddEmoji}
                      interactionsEmojis={interactionsEmojis}
                      allEmojis={allEmojis}
                      userInfo={userInfo}
                      setMessages={setMessages}
                      messages={messages}
                      pinnedMessages={pinnedMessages}
                      setPinnedMessages={setPinnedMessages}
                    />
                  </div>
                )}
              </div>
              
          </div>
    </div>
  )
}

export default PinnedMessagesListSection