import React, { useEffect, useState } from 'react'
import OpsTableRow from './OpsTableRow'
import { getData, postData, putData } from '../../ApiCalls/DataApis'
import { apiLink } from '../../ApiCalls/ApisVariables'
import moment from "moment"
import ImageZoomCrop from '../../components/modals/ImageZoomCrop'
import { toast } from 'react-hot-toast'
import { Menu, MenuButton, MenuItem, MenuItems, Transition, MenuSeparator, MenuHeading, MenuSection } from '@headlessui/react'
import { darkModeState } from '../../atoms/darkModeState'
import { useRecoilValue } from 'recoil'
import DeletePartModal from '../../components/modals/partModals/DeletePartModal'
import ArchivePartModal from '../../components/modals/partModals/ArchivePartModal'



function PartDataTable({part, algoTypes, getPartsList}) {
    const [opsList, setOpsList] = useState([])
    const [isEditingPartName, setIsEditingPartName] = useState(false)
    const [loadingAddingOp, setLoadingAddingOp] = useState(false);
    const [isAddingOp, setIsAddingOp] = useState(false)
    const [partName, setPartName] = useState(part.part_name)
    const [loadingName, setLoadingName] = useState(false);
    const [partImage, setPartImage] = useState(null)
    const [newImage, setNewImage] = useState(null)
    const isDark = useRecoilValue(darkModeState);
    const [openDeletePartModal, setOpenDeletePartModal] = useState(false)
    const [openArchivePartModal, setOpenArchivePartModal] = useState(false)
    const [newOpName, setNewOpName] = useState('')
    

    useEffect(() => {

        if(part && part.part_uuid){
            setPartName(part.part_name)
            setPartImage(part.part_picture_url)
            getOpsList()
        }
    }, [part])

    const getOpsList = () =>{
        try{
            if(part && part.part_uuid){
                getData(apiLink + '/v2/parts/'+ part.part_uuid + '/operations' )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            // console.log('OPSdata.data', data.data)
                            setOpsList(data.data)
                        }
                    }else{
                        toast.error('Something went wrong')
                    }
                })
            }

        }catch (e){
            toast.error('error',e.message)
        }
    }

      const handleNameBlur = (event) => {
        if(!event.target.value) {
            setPartName(part.part_name)
            setIsEditingPartName(false);
            return;
        }

        if(!loadingName){
            setLoadingName(true)
            setIsEditingPartName(false);

            putData(apiLink + '/v2/parts/' + part.part_uuid , 
            {'part_name': event.target.value,
                // "part_description": form.part_description,
                // "part_picture": newImage,
                // "operations": operationsJson,
                // "tags": selectedTags
            })
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoadingName(false)
                    setPartName(event.target.value)
                }
                else{
                    try{
                        setPartName(part.part_name)
                        setLoadingName(false)
                        toast.error(data.message)
                    }catch (e){
                        setPartName(part.part_name)
                        toast.error(e.message)
                        setLoadingName(false)
                    }
                }
            })
        }

    }

    const handleKeyPressName = (event) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            if(!event.target.value) {
                setPartName(part.part_name)
                setIsEditingPartName(false);
                return;
            }
    
            if(!loadingName){
                setLoadingName(true)
                setIsEditingPartName(false);
    
                putData(apiLink + '/v2/parts/' + part.part_uuid , 
                {'part_name': event.target.value,
                })
                .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                        setLoadingName(false)
                        setPartName(event.target.value)
                    }
                    else{
                        try{
                            setPartName(part.part_name)
                            setLoadingName(false)
                            toast.error(data.message)
                        }catch (e){
                            setPartName(part.part_name)
                            toast.error(e.message)
                            setLoadingName(false)
                        }
                    }
                })
            }
        }
      };


    const handleUpdatePartImage = (image) => {
        if(!loadingName){
            setLoadingName(true)
            putData(apiLink + '/v2/parts/' + part.part_uuid , 
            {"part_picture": image})
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoadingName(false)
                    setPartImage(image)
                    setNewImage(image)
                }
                else{
                    try{
                        setPartImage(part.part_picture_url)
                        setNewImage(part.part_picture_url)
                        setLoadingName(false)
                        toast.error(data.message)
                    }catch (e){
                        setPartImage(part.part_picture_url)
                        setNewImage(part.part_picture_url)
                        toast.error(e.message)
                        setLoadingName(false)
                    }
                }
            })
        }
        
    }

    const handleKeyPressAddOp = (event) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            if(!event.target.value) {
                setIsAddingOp(false);
                return;
            }
    
            if(!loadingName){
                setLoadingAddingOp(true)
                setIsAddingOp(false);

                postData(apiLink + '/v2/parts/' + part.part_uuid + '/operations' , 
                {'operation_name': event.target.value})
                .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                        setLoadingAddingOp(false)
                        setNewOpName('')
                        getOpsList()
                    }
                    else{
                        try{
                            setPartName(event.target.value)
                            setLoadingAddingOp(false)
                            toast.error(data.message)
                        }catch (e){
                            setPartName(event.target.value)
                            toast.error(e.message)
                            setLoadingAddingOp(false)
                        }
                    }
                })
            }
        }
      };

    const handleAddOpBlur = (event) => {
        if(!event.target.value) {
            setIsAddingOp(false);
            return;
        }

        if(!loadingAddingOp){
            setLoadingAddingOp(true)
            setIsAddingOp(false);

            postData(apiLink + '/v2/parts/' + part.part_uuid + '/operations' , 
            {'operation_name': event.target.value})
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoadingAddingOp(false)
                    setNewOpName('')
                    getOpsList()
                }
                else{
                    try{
                        setPartName(event.target.value)
                        setLoadingAddingOp(false)
                        toast.error(data.message)
                    }catch (e){
                        setPartName(event.target.value)
                        toast.error(e.message)
                        setLoadingAddingOp(false)
                    }
                }
            })
        }

    }

    const handleCloseDeletePartModal = () => {
        setOpenDeletePartModal(false)
    }
    const handleCloseArchivePartModal = () => {
        setOpenArchivePartModal(false)
    }


  return (
    <div className={`flex flex-col pb-8 mr-4`}>
        <div className='flex items-center mb-2 group'>
            <div className='ml-[-10px] mr-1'>
                <Menu>
                    <MenuButton className={`opacity-0 ease-in-out duration-300 group-hover:opacity-100 inline-flex items-center rounded-md  bg-gray-100 dark:bg-gray-800 py-1 px-1 text-gray-600 dark:text-white shadow-white/10 focus:outline-none data-[hover]:bg-gray-200 dark:data-[hover]:bg-gray-700 data-[open]:bg-gray-200 dark:data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                    </svg>
                    </MenuButton>
                    <Transition
                    enter="transition ease-out duration-75"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                    >
                    <MenuItems
                        anchor="bottom start"
                        className={`w-48 origin-top-right rounded-xl border border-white/5 ${isDark ? 'bg-slate-800 text-white':'bg-white text-gray-600'} shadow-iotflows-lg p-1 text-sm [--anchor-gap:var(--spacing-1)] focus:outline-none`}
                    >
                        <MenuItem>
                        <button 
                            onClick={() => setOpenArchivePartModal(true)}
                            className={`group ${isDark ? 'data-[focus]:bg-white/10':'data-[focus]:bg-slate-600/10'} flex w-full items-center gap-2 rounded-lg py-1.5 px-3`} 
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0-3-3m3 3 3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                            </svg>
                            Archive this part
                        </button>
                        </MenuItem>
                        <MenuItem>
                        <button 
                            onClick={() => setOpenDeletePartModal(true)}
                            className={`group ${isDark ? 'data-[focus]:bg-white/10':'data-[focus]:bg-slate-600/10'} flex w-full items-center gap-2 rounded-lg py-1.5 px-3`} 
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                            </svg>
                            Delete this part
                        </button>
                        </MenuItem>
                    </MenuItems>
                    </Transition>
                </Menu>
            </div>
            <div className='my-auto items-center h-9 w-9'>
                <ImageZoomCrop currentPicture={partImage} submitType={'InlinePartImage'} title={part.part_name} isDisabled={true} isRounded={true} updateInfo={handleUpdatePartImage} />
            </div>
            {isEditingPartName ? (
                <div className='flex justify-between overflow-hidden ' >
                    <input
                        type="text"
                        name='part_name'
                        value={partName}
                        onChange={(e) => setPartName(e.target.value)}
                        onBlur={handleNameBlur} // Handle blur
                        onKeyDown={handleKeyPressName}
                        className={`block bg-transparent w-full border rounded-md py-0.5 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-xl placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800`}
                        autoFocus // Focus on input upon render
                        />
                </div>
            ):(
                <div 
                    className='relative flex ml-2 justify-between overflow-hidden cursor-pointer'  
                    onClick={() => setIsEditingPartName(true)}
                >
                    <div className={` text-sky-600 dark:text-sky-300 text-base font-medium truncate text-xl`} >
                        {partName}
                    </div>
                    {loadingName && 
                    <div >
                        <svg aria-hidden="true" className={`absolute top-1/4 right-0 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                        </svg>
                    </div>
                    }
                </div>
            )}
        </div>
        <table className="table-fixed w-full min-w-[800px] ml-5 ">
            <thead >
            <tr className='text-left '>
                <th className='text-sm font-light text-gray-500 dark:text-gray-400 pl-4 pr-1 py-1 border-[0.01em] dark:border-zinc-600  text-left'>
                    {`Operation(s)`}  
                </th>
                <th className='text-sm font-light text-gray-500 dark:text-gray-400 pl-4 pr-1 py-1 border-[0.01em] dark:border-zinc-600 text-left'>{`Description`}</th>
                <th className='w-[150px] text-sm font-light text-gray-500 dark:text-gray-400 pl-4 pr-1 py-1 border-[0.01em] dark:border-zinc-600 text-left'>{`Quantity per Cycle`}</th>
                <th className='w-[150px] md:w-auto text-sm font-light text-gray-500 dark:text-gray-400 pl-4 pr-1 py-1 border-[0.01em] dark:border-zinc-600 text-center'>{`Algorithm`}</th>
                <th className='w-[300px] lg:w-auto text-sm font-light text-gray-500 dark:text-gray-400 pl-4 pr-1 py-1 border-[0.01em] dark:border-zinc-600 text-left'>{`Cycle Time`}</th>
                {/* <th className='text-sm font-light text-gray-500 dark:text-gray-400 pl-4 pr-1 py-1 border-[0.01em] dark:border-zinc-600 text-center'>{`Auto-Detect Asset`}</th> */}
            </tr>
            </thead>
        <tbody >
        {opsList.map((operation, index) => (
            <OpsTableRow
            key={operation.operation_uuid}
            index={index}
            operation={operation}
            algoTypes={algoTypes}
            getOpsList={getOpsList}
            />
        ))}
        <tr className='bg-slate-100 dark:bg-slate-800'>
        {isAddingOp ? (
          <td 
          colSpan={5}
            className='text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-1 border-[0.01em] dark:border-zinc-600'
          >
            <input
              type="text"
              name='operation_name'
              value={newOpName}
              onChange={(e) => setNewOpName(e.target.value)}
              onBlur={handleAddOpBlur} // Handle blur
              onKeyDown={handleKeyPressAddOp}
              className={`block bg-transparent w-full border rounded-md py-0.5 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300 dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800`}
              placeholder='+ Add operation'
              autoFocus // Focus on input upon render
            />
          </td>
        ) : (
          <td 
            colSpan={5}
            disabled={loadingAddingOp}
            onClick={() => setIsAddingOp(true)}
            className='relative text-sm font-light text-gray-600 dark:text-gray-300 h-6 px-2 py-1 border-[0.01em] dark:border-zinc-600'
            >
                <div
                    className={`bg-transparent w-full border border-transparent rounded-md py-0.5 px-3  sm:text-sm  text-gray-500 dark:text-gray-400`}
                >
                    + Add operation
                </div>
            {loadingAddingOp && 
            <div >
                <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                </svg>
            </div>
            }
          </td>
        )}
        </tr>
        </tbody>
      </table>
      {openDeletePartModal && 
        <DeletePartModal part={part} remoteOpenModal={openDeletePartModal} handleOpenDeletePartModal={handleCloseDeletePartModal} getPartList={getPartsList} />
        }
      {openArchivePartModal && 
        <ArchivePartModal part={part} remoteOpenModal={openArchivePartModal} handleCloseArchivePartModal={handleCloseArchivePartModal} getPartsList={getPartsList} />
        }
    </div>
  )
}

export default PartDataTable


function GearIcon(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" >
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      
      
    )
}