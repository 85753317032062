import React, { useState, useEffect } from 'react'
import '../../styles/PinnedMessages.css';
import {  motion } from 'framer-motion';
import { useSearchParams } from 'react-router-dom';
import { removeMessage } from '../../utils/MessageDataSorting';
import MqttService from '../../core/services/MqttService';
import { createMessagingMQTT } from '../../core/services/MqttMessaging';
import toast from 'react-hot-toast';
import DOMPurify from 'dompurify';


function PinnedMessages({messages, setPinnedMessages, showSearch, handleNavToPinMessage}) {
    const [currentIndex, setCurrentIndex] = useState(0);
    // const [activeIndex, setActiveIndex] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const [fillHeight, setFillHeight] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams()
    const [sectionHeight, setSectionHeight] = useState(100)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {

        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
  
    },[])

    const handleNavToPinnedList = () => {

        let select = searchParams.getAll('select')
        let chatId = searchParams.getAll('chatId')
        let platform = searchParams.getAll('platform')

        setSearchParams({select,chatId, section: 'pinnedMessagesList', platform})

    }

    useEffect(() => {
        if(Object.keys(messages).length > 0){
            let secHeight = 100 / Object.keys(messages).length;
            setSectionHeight(secHeight)
        }

    }, [messages])
    

    const nextMessage = (uuid) => {
        // console.log('uuid', uuid)
        if(uuid){
            handleNavToPinMessage(uuid)
            if(Object.keys(messages).length == 1){
                // setTimeout(() =>{
                //     handleNavToPinMessage('')
                // },3000)
            }
        }
        if(Object.keys(messages).length > 1){
            if (!isAnimating) {
            setIsAnimating(true);
            setCurrentIndex((prevIndex) => (prevIndex + 1) % Object.keys(messages).length);
            }

        }
    };
    

    useEffect(() => {
        const timer = setTimeout(() => setIsAnimating(false), 300); // Match this with your CSS transition time
        return () => clearTimeout(timer);
    }, [currentIndex]);

    useEffect(() => {
        // console.log('sectionHeight', sectionHeight)
        const newFillHeight = sectionHeight * (currentIndex + 1);
        // console.log('newFillHeight', newFillHeight)
        setFillHeight(sectionHeight);
        // setFillHeight(newFillHeight);
      }, [currentIndex, messages]);

    
      

      const heightCSS = (index, currentHeight, currentIndex) => { 
        
        let bottomCalc = 100 - (index + 1) * sectionHeight

        return{
            height: `${currentHeight}%`,
            bottom: `${bottomCalc}%`,
            // transform: `translateY(${offset}px)`,
            // backgroundColor: `${index === currentIndex ? '#0ea5e9' : '#f43f5e'}`,
            backgroundColor: `#0ea5e9`,
            transition: 'opacity 0.3s ease-out',
        }
      }

    const removePin = (pinnedMsg) => {
        let updatedPinnedMessages = removeMessage(messages, pinnedMsg)
        setPinnedMessages({...updatedPinnedMessages})

        // Remove Pinned Message
        if (MqttService.isConnected) {
            let action = 'pin_message_for_me'
            if(pinnedMsg.message_is_pinned_for_all){
                // checking to see if the message is just for me or if everyone has this pinned
                action = 'pin_message'

            }
            let msg = {
                from_username: pinnedMsg.user_username,
                action: action,
                message_uuid: pinnedMsg.message_uuid,
                should_pin: false,
              }
            
            msg = createMessagingMQTT(msg);
            if(msg.topic && msg.payload)
            {
                MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
            }
            
        }else{
            toast.error('Waiting for Network')
        }

    }

    

  return (
    <div className={`${Object.keys(messages).length == 0 ? 'hidden':'absolute top-12 left-0'} w-full bg-white dark:bg-slate-900 border-b-[0.01em] border-slate-200 dark:border-slate-600 pt-1 pb-0.5 transition-all duration-500 ease-in-out translate-y-0  ${ !showSearch ? 'translate-y-0 ' : '-translate-y-full '}`}> 
        <div className="pinned-messages-container ">
        <div className="pinned-messages ">
            {Object.keys(messages).map((message, index) => (
                <div
                    key={index}
                    className={`pinned-message bg-white dark:bg-slate-900 ${index === currentIndex ? 'active' : ''} ${
                        isAnimating ? 'animating' : ''
                    }`}
                    
                >
                    <div onClick={() => nextMessage(message)} className='highlight-indicator relative'>
                        <div className="absolute w-full left-0 rounded-xl" style={heightCSS(index, fillHeight, currentIndex)}></div>
                    </div>
                    <div
                        onClick={() => nextMessage(message)} 
                        className={`pinned-message-content flex-1 text-sm ${isMobile ? 'max-w-[80%]':'max-w-[90%]'}`}
                    >
                        <div className='flex flex-col truncate '>
                            <span className='text-sky-500'>
                            {`Pinned Message ${Object.keys(messages).length > 1 ? `#${index + 1}`:''}`}
                            </span>
                            {messageRow(messages[message])}
                        </div>
                    </div>
                    <motion.button 
                        whileHover={{scale: 1.05} } 
                        whileTap={{scale:0.95 }}  
                        className={`text-gray-500 dark:text-gray-400 ml-auto ${isMobile ? 'w-7 ':'w-5 '} `}
                        onClick={() => removePin(messages[message])}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${isMobile ? 'w-7 h-7':'w-5 h-5'}`} >
                            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>

                    </motion.button>
                    <motion.button 
                        whileHover={{scale: 1.05} } 
                        whileTap={{scale:0.95 }}  
                        className={`text-gray-500 dark:text-gray-400 ml-2 ${isMobile ? 'w-7 ':'w-5 '} `}
                        onClick={handleNavToPinnedList}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className={`${isMobile ? 'w-7 h-7':'w-5 h-5'}`} viewBox="0 -960 960 960" fill="currentColor">
                            <path d="M640-760v280l68 68q6 6 9 13.5t3 15.5v23q0 17-11.5 28.5T680-320H520v234q0 17-11.5 28.5T480-46q-17 0-28.5-11.5T440-86v-234H280q-17 0-28.5-11.5T240-360v-23q0-8 3-15.5t9-13.5l68-68v-280q-17 0-28.5-11.5T280-800q0-17 11.5-28.5T320-840h320q17 0 28.5 11.5T680-800q0 17-11.5 28.5T640-760ZM354-400h252l-46-46v-314H400v314l-46 46Zm126 0Z"/>
                        </svg>
                    </motion.button>
                </div>
            ))}
            <div>
                

            </div>
        </div>

    </div>
  </div>
  )
}

export default PinnedMessages


const messageRow = (message) => {

    let addAttachementIcon = false
    let textShown = ''
    
    if(message && message.file_uuid ){
        if(message.message_text && message.message_text.length > 0){
            textShown = message.message_text
            addAttachementIcon = true
        }else{
            addAttachementIcon = true
            textShown = message.file_name
        }
    }else if(message && !message.file_uuid && !message.isFileUploading && message.message_text && message.message_text.length > 0){
        textShown = message.message_text
        addAttachementIcon = false
    }


    return(
        <div className='flex truncate'>
        {addAttachementIcon &&
            <div className='pr-2 text-gray-500 dark:text-gray-400'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
                </svg>
            </div>
        }
        <div className='text-sm text-gray-600 dark:text-gray-300 truncate'>
            {textShown}
        </div>
    </div>
    )

}