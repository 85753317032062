import React, { useState } from 'react'
import { formatRelativeTime } from '../../utils/DateFormating';
import { formatFileSize } from '../../utils/FileFormating';
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { getData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';

function FilePageRow({file}) {
    const isDark = useRecoilValue(darkModeState)
    const [isLoadingFile, setIsLoadingFile] = useState(false)

    const handleDownloadFile = (message) => {
        try {
            if(!isLoadingFile){
            setIsLoadingFile(true);
            getData(apiLink + '/v1/files/' + message.file_uuid + '/download_request' )
            .then(async response => {
                const data = await response.json();
                if(response.ok ){
                    if(data && data.data && data.data.signed_url){
                        // console.log('data', data.data.signed_url)
                        let url = data.data.signed_url
                        const response = await fetch(url);
                        const blob = await response.blob();
                        const downloadUrl = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.download = message.file_name || 'download'; // Set the download filename
                        link.click();
                        window.URL.revokeObjectURL(downloadUrl); // Clean up the object URL
                        
                        setIsLoadingFile(false);
                    }
                }else{
                    setIsLoadingFile(false);
                    toast.error('Error downloading file');
                }
            })
            }
        } catch (error) {
          toast.error('Error downloading file:', error);
          setIsLoadingFile(false);
        } 
    }; 

  return (
    <div className='flex items-center w-full px-2 cursor-pointer ' onClick={() => handleDownloadFile(file)}>
        <div
            className={`items-center justify-center rounded-full ${isDark ? 'text-gray-400':'text-gray-500'} py-2`}
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-8 w-8">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
            </svg>
        </div>
        <div className={`pl-2 flex flex-col space-y-0.5 py-2 border-b-[0.01em] ${isDark ? 'border-slate-600':' border-slate-100 '} grow`}>
            <h2 className={`text-sm  ${isDark ? 'text-gray-300':' text-gray-600 '} `}>
                {file.file_name? file.file_name : 'unknow'}
            </h2>
            <div className='flex items-center '>
                <div
                className={`items-center justify-center`} > 
                    {isLoadingFile ? (
                        <svg class={`animate-spin w-4 h-4 ${isDark ? 'text-blue-600':'text-blue-500'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" >
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    ):(
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="currentColor" className={`w-3 h-3 ${isDark ? 'text-blue-600':'text-blue-500'}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
                        </svg>
                    )}
                    
                </div>
                <span className={`text-xs font-light ml-1 ${isDark ? 'text-gray-400':' text-gray-500 '}`}>
                    {formatFileSize(file.file_size)} - {formatRelativeTime(file.message_created_at)}
                </span>
            </div>
        </div>
    </div>
  )
}

export default FilePageRow