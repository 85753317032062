import { useEffect, useState } from 'react'
import HighchartsStock from 'highcharts/highstock';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
// import {data} from '../../MyOverviewPage/testData.js'
import pareto from 'highcharts/modules/pareto';


highchartsMore(Highcharts);
pareto(Highcharts);



function ParetoChartDowntimesSelectedAsset({downtimeSummary}) {
    const isDark = useRecoilValue(darkModeState);
    const [chartOptions, setChartOptions] = useState({
        credits: {enabled: false},
        chart: {
            type: 'column',
            zoomType: 'x',
            backgroundColor:'transparent',
            // height:'110px',
        },
        time: {
            useUTC: false
          },
        title:{
            text:''
        },
    })

      useEffect(() => {
        if(downtimeSummary && Object.keys(downtimeSummary).length > 0){

            // console.log('downtimeSummary', downtimeSummary)
            let downtimeCategories = []
            let catValues = []
            downtimeSummary.map(item => {
                downtimeCategories.push(item.category)
                catValues.push(+item.total_downtime_hours)
            })

            setChartOptions({
                chart: {
                    renderTo: 'container',
                    type: 'column',
                    height: '350px',
                    backgroundColor: 'transparent',
                },
                title:{
                    text:'',
                    // align: 'left',
                    // style: {
                    //     color: isDark ? '#e5e7eb' :'rgba(75, 85, 99, 1)',
                    //     fontWeight: 'semibold'
                    // }
                },
                time: {
                    useUTC: false
                  },
                legend: { enabled: false},
                
                tooltip: {
                    shared: true
                },
                credits:{enabled:false},
                xAxis: {
                    // categories: downtimeSummary.categories,
                    categories: downtimeCategories,
                    // categories: [
                    //     'Lack of Material',
                    //     'Unplanned Maintenance',
                    //     'Broken Tool',
                    //     'Breaks',
                    //     'Shift Change',
                    //     'Part or Tool Change',
                    //     'Machine Setup',
                    //     'No Job'
                    // ],
                    crosshair: true,
                    labels: {
                        style: {
                            color: isDark? '#d1d5db':'#4b5563'
                        }
                    }
                },
                yAxis: [{
                    title: {
                        text: ''
                    },
                    lineWidth: 0,
                    minorGridLineWidth: 0,
                    lineColor: 'transparent',
                    title: '', 
                    minorTickLength: 0,
                    tickLength: 0,
                    gridLineWidth: 0,
                    labels: {
                        style: {
                            color: isDark? '#d1d5db':'#4b5563'
                        }
                    },
                }, {
                    lineWidth: 0,
                    minorGridLineWidth: 0,
                    lineColor: 'transparent',
                    title: '', 
                    minorTickLength: 0,
                    tickLength: 0,
                    gridLineWidth: 0,
                    opposite: true,
                    labels: {
                        format: '{value}%',
                        style: {
                            color: isDark? '#d1d5db':'#4b5563'
                        }
                    },
                    min: 0,
                    max: 100,
                }],
            //     colors: ['#fda4af', '#f9a8d4', '#f0abfc', '#d8b4fe', '#c4b5fd', '#a5b4fc',
            //  '#93c5fd', '#7dd3fc', '#67e8f9', '#5eead4','#6ee7b7', '#86efac', '#bef264', '#fde047', '#fcd34d', '#fdba74', '#fca5a5', '#d6d3d1'],
                plotOptions: {

                    column: {
                        // stacking: 'normal',
                        colorByPoint: true,
                        // backgroundColor:'#F3F4F6',
                        dataLabels: {
                            enabled: true,
                            style: {
                                // fontSize: '18px',
                                color: isDark ? '#fff' :'rgba(75, 85, 99, 1)',
                                // color: isDark ? '#fff' :'rgba(75, 85, 99, 0.5)',
                            },
                            formatter: function () {
                                return `${Highcharts.numberFormat(this.y,2)}hr`;
                            }
                        },
                        borderWidth:0,
                    },
                },
                series: [{
                    type: 'pareto',
                    name: 'Pareto',
                    yAxis: 1,
                    zIndex: 10,
                    baseSeries: 1,
                    tooltip: {
                        valueDecimals: 2,
                        valueSuffix: '%'
                    }
                }, {
                    name: 'Downtime Hours',
                    type: 'column',
                    zIndex: 2,
                    color:'#fecaca',
                    opacity: isDark? 0.9 : 1,
                    // data: seriesData,
                    data: catValues,
                    // data: [1455, 2222, 1151, 486, 372, 551, 336, 140],
                    tooltip: {
                        valueDecimals: 2,
                        valueSuffix: ' hours'
                    }
                }]
            })
        }

      }, [downtimeSummary, isDark])


  return (
    <div>
        <HighchartsReact
            highcharts={Highcharts}
            constructorType={'chart'}
            // constructorType={'stockChart'}
            options={chartOptions}
        />
    </div>
  )
}

export default ParetoChartDowntimesSelectedAsset