import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from "highcharts-react-official";
import HighchartsStock from 'highcharts/highstock';
import { useState } from "react";
import { useRecoilValue } from "recoil";
import {darkModeState} from '../../atoms/darkModeState'
import { useEffect } from "react";

function StackedBarChart({containerHeight, backgroundSeries, uptimeSeries, downtimeSeries, unknownSeries}) {
    const isDark = useRecoilValue(darkModeState);
    const [hourCountBarChartOptions, setHourCountBarChartOptions] = useState({})

    useEffect(() => {
        setHourCountBarChartOptions({
            chart: {
                type: 'column',
                zoomType: 'x',
                backgroundColor:'transparent',
            },
            time: {
                useUTC: false
              },
            title: null,
            credits: {
                text: 'iotflows.com',
                href: 'http://www.iotflows.com'
              },
            xAxis: {
                type: 'datetime',
                labels: {
                    style: {
                        color: isDark? '#d1d5db':'#4b5563'
                    }
                }
            },
            // xAxis: {
            //     categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun']
            // },
            yAxis: [{
                visible: false,
                // max: 10
            }, {
                visible: false,
                // max: 10,
                opposite: true
            }],
            legend: {
                enabled: false 
            },
            // tooltip: {
            //     headerFormat: '<b>{point.x}</b><br/>',
            //     pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
            // },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    backgroundColor:'#F3F4F6',
                    dataLabels: {
                        enabled: true
                    },
                    borderWidth:0
                }
            },
            // rangeSelector: {
            //     allButtonsEnabled: true,
            //     selected: 0,
            //     buttons: [{
            //         type: 'month',
            //         count: 3,
            //         text: 'Day',
            //         dataGrouping: {
            //             forced: true,
            //             units: [['day', [1]]]
            //         }
            //     }, {
            //         type: 'month',
            //         count: 6,
            //         text: 'Week',
            //         dataGrouping: {
            //             forced: true,
            //             units: [['week', [1]]]
            //         }
            //     }, {
            //         type: 'all',
            //         text: 'Month',
            //         dataGrouping: {
            //             forced: true,
            //             units: [['month', [1]]]
            //         }
            //     }],
            //     buttonTheme: { // styles for the buttons
            //       width: 60,
            //       fill: 'none',
            //       stroke: 'none',
            //       'stroke-width': 0,
            //       r: 8,
            //       style: {
            //         color: '#2563eb',
            //         fontWeight: 'bold'
            //       },
            //       states: {
            //         hover: {
            //         fill: '#94a3b8',
            //         },
            //         select: {
            //           fill: '#2563eb',
            //           style: {
            //             color: 'white'
            //           }
            //         },
            //         disabled: { 
            //           // fill: '#2563eb',
            //           style: {
            //             color: '#d1d5db',
            //           }
            //         }
            //       }
            //     },
            //   inputStyle: {
            //     color: '#9ca3af',
            //     fontWeight: 'bold'
            //   },
            //   labelStyle: {
            //     color: '#6b7280',
            //     fontWeight: 'bold'
            //   },
    
            // },
            series: [{
                name: '',
                color: isDark ? '#374151' :'#F3F4F6',
                data: backgroundSeries,
                dataLabels: {
                    enabled: false
                },
                enableMouseTracking: false,
                yAxis: 1
            },{
                name: 'Unknown',
                color: '#94a3b8',
                data: unknownSeries
            },{
                name: 'Downtime',
                color:'#dc2626',
                data: downtimeSeries
            },{
                name: 'Uptime',
                color:'#22c55e',
                data: uptimeSeries
            }]
        })
    }, [isDark, uptimeSeries, downtimeSeries, unknownSeries, backgroundSeries])
  return (
    <div>
        {/* highcharts bar chart */}
        <HighchartsReact
            constructorType={"chart"}
            containerProps={{ style: { height: containerHeight, maxWidth:'100%'} }} 
            highcharts={HighchartsStock}
            options={hourCountBarChartOptions}               
        />
        
    </div>
  )
}

export default StackedBarChart