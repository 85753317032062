import React from 'react'
import ProgressCircle from '../../../utils/ProgressCircle'
import { formatDateToAmPm } from '../../../utils/DateFormating'
import { motion } from 'framer-motion';
import { removeMessage } from '../../../utils/MessageDataSorting';
import DOMPurify from 'dompurify';
import LinkifyIt from 'linkify-it';
import tlds from 'tlds';
import { createHyperlinks } from '../utils/HyperlinkUtils';

const linkify = new LinkifyIt();
linkify.tlds(tlds);


function SenderFileIsUploading({isMobile, message, scrollToMessage, isGroup, showImage, handleTailCss, participants, userInfo, updateEmoji, isLoadingFile, handleDownloadFile, messages, setMessages}) {
    let cleanHTML = DOMPurify.sanitize(message.message_text_html || message.message_text); // Sanitize the HTML content
    
    const links = linkify.match(cleanHTML);
    if(links && links.length > 0){
        cleanHTML = createHyperlinks(message.message_text_html);
    }
    // var cleanReplyHTML = ''; // Sanitize the HTML content
    // if(message.message_replied_from && Object.keys(message.message_replied_from).length > 0 && message.message_replied_from.message_uuid){
    //     cleanReplyHTML = DOMPurify.sanitize(message.message_replied_from.message_text);
    // }


    const handleAbortMessage = (message) => {
        message.xhr.abort()
        const updatedMessages = removeMessage(messages, message)
        setMessages({...updatedMessages})
    }

  return (
    <>
    <div 
        className={` pl-4 pr-4 py-1.5 rounded-2xl ${isMobile ? 'max-w-[300px]':'max-w-[500px]'} flex flex-col items-center  mr-2 order-1 bg-sky-600 dark:bg-sky-800 text-white`}
    >
    {message.message_replied_from && Object.keys(message.message_replied_from).length > 0 && message.message_replied_from.message_uuid &&
        <div className='w-full rounded-xl flex my-1 cursor-pointer text-sm' onClick={() => scrollToMessage(message.message_replied_from.message_uuid)}>
            <div className={`bg-white dark:bg-slate-200 w-[3px] min-w-[3px] rounded-l-full`} />
            <div className={`bg-white/20 dark:bg-slate-200/20 rounded-r-lg pl-2 py-0.5 pr-2 flex flex-col truncate overflow-hidden grow`}>
                <span className={`text-white dark:text-slate-100`}>{`${message.message_replied_from.user_first_name? message.message_replied_from.user_first_name : ''} ${message.message_replied_from.user_last_name ? message.message_replied_from.user_last_name : ''}`}</span>
                <span className={`text-gray-200 dark:text-gray-200 truncate `} >{message.message_replied_from.message_text}</span>
                {/* <div
                    className="text-gray-200 dark:text-gray-200 h-5"
                    dangerouslySetInnerHTML={{ __html: cleanReplyHTML }} // Set the HTML content directly
                /> */}
            </div>
        </div>
    }
        <div className='flex flex-col pt-1'>      
            <div className='flex flex-col'>
                <div className='flex items-center pb-1'>
                    <motion.button 
                    whileHover={{ scale: 1.05  }} 
                    whileTap={{ scale: 0.98  }} 
                    className={`w-fit p-0.5 rounded-full bg-slate-100 dark:bg-slate-500/80 text-white`} onClick={() => handleAbortMessage(message)}> 
                        <ProgressCircle percentage={message.progress} />
                    </motion.button>
                    <div className='pl-2 flex flex-col'>
                        <h2 className={`text-sm `}>
                            {message.file_name? message.file_name : 'Unknown'}
                        </h2>
                        <span className={`text-xs font-light text-gray-300 dark:text-gray-300`}>
                            {`${message.progress ? message.progress.toFixed(0) : '0'}% uploading`}
                        </span>
                    </div>
                </div>
            </div>
            <div className='flex flex-wrap w-full'>
                {/* <span className='text-sm'>{message.message_text}</span>  */}
                <div
                    className={`message-content ${isMobile ? ' message-content-mobile ':' message-content-web '}`}
                    dangerouslySetInnerHTML={{ __html: cleanHTML }} // Set the HTML content directly
                />
                <span className={`text-gray-300 text-xs ml-auto mt-auto text-right mb-[-5px] italic pl-3 py-0.5`}>
                {message.message_is_edited && 'edited  '}{formatDateToAmPm(message.message_created_at)}
                </span>
            </div>
    </div>
    </div>
     {/*THIS WILL SHOW THE SENDERS IMAGE ON THE LEFT SIDE BUT I DONT THINK ITS NEEDED */}   
     {/* <div className={`${isGroup ? 'w-8 h-8': 'hidden'} order-2`}>
        {showImage &&
            <Avatar
                sx={{ 
                    bgcolor: handleUserBGColor(message.user_username), 
                    width: 32, 
                    height: 32 }}
                alt={participants[message.user_username].user_first_name? participants[message.user_username].user_first_name : ''}
                src={participants[message.user_username].user_image_url}
            />
        }
    </div> */}
    {/*THIS tail is for when the is an image showing and we need to move the tail to the left of the screen*/}   
        {/* {showImage && isGroup &&
        <div 
            className={`absolute bottom-[-13px] right-[20px] rotate-6 text-sky-600 dark:text-sky-800`}
            style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
        >
            <svg width="30" height="30" viewBox="0 0 20 10">
                <path d={`${message.user_username === userInfo.user_username ? 'M 0 0 L 10 5 L 0 5 Z':'M 10 5 L 10 -5 L 0 -10 Z'}`} fill="currentColor" />
            </svg>
        </div>
        } */}
    {/*THIS TAIL IS FOR WHEN THERE IS NO IMAGE AND WE NEED THE TAIL CLOSER TO THE RIGHT OF THE SCREEN*/}
    {showImage && 
    <div 
        className={`absolute bottom-[-13px] right-[-16px] rotate-6 text-sky-600 dark:text-sky-800`}
        style={handleTailCss(message.user_username,userInfo.user_username, message.message_is_only_emoji_count )}
        >
        <svg width="30" height="30" viewBox="0 0 20 10">
            <path d={`M 0 0 L 10 5 L 0 5 Z`} fill="currentColor" />
        </svg>
    </div>
    }
</>
  )
}

export default SenderFileIsUploading