import { useState, useRef, useEffect } from 'react';
import GeneralFormInput from '../components/forms/GeneralFormInput';
import IoTFlowsLogo from '../images/IoTFlowsLogoBlackFont.png';
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Amplify, { Auth, Hub } from 'aws-amplify';
import {useLocation} from 'react-router-dom';
import {auth} from '../data/AwsConfigData'

Amplify.configure({
    Auth: auth
});


const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
const lowerCase = new RegExp('(?=.*[a-z])')
const upperCase = new RegExp('(?=.*[A-Z])')
const specialChar = new RegExp('(?=.*[^A-Za-z0-9])')
const num = new RegExp('(?=.*[0-9])')
const eightChar = new RegExp('(?=.{8,})')
const emailCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  


function SignupPage() {
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        username: '',
        newPassword: '',
        confirmNewPassword: '',
      });
    const [email, setEmail] = useState('')
    const [strongPasswordCreated, setStrongPasswordCreated] = useState(false)
    const [passwordContainsLowerCase, setPasswordContainsLowerCase] = useState(false)
    const [passwordContainsUpperCase, setPasswordContainsUpperCase] = useState(false)
    const [passwordContainsSpecialChar, setPasswordContainsSpecialChar] = useState(false)
    const [passwordContainsNum, setPasswordContainsNum] = useState(false)
    const [passwordContainsEightChar, setPasswordContainsEightChar] = useState(false)
    const [passwordMatch, setPasswordMatch] = useState(false)
    const [validEmail, setValidEmail] = useState(true)
    const [issueMessage, setIssueMessage] = useState('')
    const emailRef = useRef(null)
    const navigate = useNavigate();

    useEffect(() => {
        if(form.newPassword !== form.confirmNewPassword){
            setPasswordMatch(false)
        }else{
            setPasswordMatch(true)
        }

        if(strongPassword.test(form.newPassword)){
            setStrongPasswordCreated(true)
        }else{
            setStrongPasswordCreated(false)
        }
        if(lowerCase.test(form.newPassword)){
            setPasswordContainsLowerCase(true)
        }else{
            setPasswordContainsLowerCase(false)
        }
        if(upperCase.test(form.newPassword)){
            setPasswordContainsUpperCase(true)
        }else{
            setPasswordContainsUpperCase(false)
        }
        if(specialChar.test(form.newPassword)){
            setPasswordContainsSpecialChar(true)
        }else{
            setPasswordContainsSpecialChar(false)
        }
        if(num.test(form.newPassword)){
            setPasswordContainsNum(true)
        }else{
            setPasswordContainsNum(false)
        }
        if(eightChar.test(form.newPassword)){
            setPasswordContainsEightChar(true)
        }else{
            setPasswordContainsEightChar(false)
        }

    },[form.newPassword, form.confirmNewPassword])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (emailRef.current && !emailRef.current.contains(event.target)) {
                if ( emailCheck.test(email) ) {
                    
                    setValidEmail(true)
                    
                }
                else {
                    console.log("INVALID email")
                    setValidEmail(false)
                }
            

            }
        };

        document.addEventListener('keypress', handleClickOutside, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('keypress', handleClickOutside, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [validEmail, email]);

    const handleChangeEmail = (event) => {
        setEmail(event.target.value)
        if ( emailCheck.test(event.target.value) ) {
            setValidEmail(true)
        }
    }


   

    const submitSignUp = async (e) => {
    // const submitSignUp = async (email,username, password, firstName, lastName) => {
        e.preventDefault();
        try{
            const { user } = await Auth.signUp({
                username: form.username,
                password: form.newPassword,
                attributes: {
                    email,
                    'name': form.firstName,
                    'family_name': form.lastName,
                    'custom:platform_url': 'https://dashboard.iotflows.com'
                },
            });
            setIssueMessage('')
            navigate('/verify-email', {state:{username: form.username}})
            // this.props.showSuccessSnackbar("Verification link has been sent to your email");
            // console.log(user);

        }catch (error){
            setIssueMessage(error.message)
            console.log(error.message)
        }
    }

    const handleGeneralFormChange = (event) => {
        // Get the name of the field that caused this change event
        // Get the new value of this field
        const { name, value } = event.target;
    
        // Assign new value to the appropriate form field
        const updatedForm = {
          ...form,
          [name]: value
        };
        // Update state
        setForm(updatedForm);
      }

  return (
    <div className='overflow-scroll h-screen'>
        <div className='fixed inset-x-0 top-[-250px] bottom-0 z-[-1] -skew-y-12'>
            <div className='absolute top-[-1000px] left-0 right-0 origin-[0 50%] h-[1630px] bg-slate-100 '>
            </div>
            <div className='top-[630px] left-[-10px] right-[calc(50%+135px)] h-12 absolute bg-blue-300 md:right-[calc(50%+505px)] xl:right-[calc(50%+605px)]'>
            </div>
            <div className='top-[580px] right-[-10px] left-[calc(100%-110px)] h-12 absolute bg-blue-300 md:left-[calc(100%-230px)]'>
            </div>
        </div>
        <div className='flex flex-col mx-auto pt-7 max-w-[382px] sm:pt-14 sm:max-w-[540px] h-full'>
            <div className='text-left ml-3 pl-5 pb-6 sm:pl-4 sm:pb-10 '>
                <img src={IoTFlowsLogo} className='h-10' alt="IoTFlows Inc" loading="lazy" />
            </div>
            <div className='shadow-iotflows-xl w-full bg-white rounded-2xl py-8 px-5 my-0 mx-4 sm:py-14 sm:px-12'>
                <AnimatePresence exitBeforeEnter>
                        <div key={'resetSection'} >
                        <form onSubmit={submitSignUp}>
                            <h1 className={`text-2xl font-medium text-gray-600`}>Create your IoTFlows account</h1>
                            <div  className="pt-4">
                                <div>
                                    <label className={`block text-sm font-medium mb-2 text-gray-600 dark:text-gray-300 `} htmlFor={'email'}>
                                        {'Email'}
                                    </label>
                                    <input 
                                    autoFocus
                                    className={` block bg-transparent w-full border rounded-md py-2 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300`} 
                                    ref={emailRef}
                                    label='Email' 
                                    type='email' 
                                    placeholder='' 
                                    name='email'
                                    id={'email'} 
                                    onChange={handleChangeEmail}
                                    />
                                </div>
                            </div>
                            {!validEmail && email && 
                            <div key={'invalidEmail'} className='pt-1 flex items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" className={`text-rose-600 w-4 h-4`} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                </svg>

                                <span className={`text-sm font-light text-rose-600 `} >&nbsp;Please enter a valid email</span>
                            </div>
                            }
                            <div className="pt-4">
                                <GeneralFormInput label='First Name' type='text' placeholder='' name='firstName' onChange={handleGeneralFormChange} />
                            </div>
                            <div className="pt-4">
                                <GeneralFormInput label='Last Name' type='text' placeholder='' name='lastName' onChange={handleGeneralFormChange} />
                            </div>
                            <div className="pt-4">
                                <GeneralFormInput label='Username' type='text' placeholder='' name='username' onChange={handleGeneralFormChange} />
                            </div>
                            <div className="pt-4">
                                <GeneralFormInput label='New password' type='password' placeholder='' name='newPassword' onChange={handleGeneralFormChange} />
                            </div>
                            <div className="pt-4">
                                <GeneralFormInput label='Confirm new password' type='password' placeholder='' name='confirmNewPassword' onChange={handleGeneralFormChange} />
                            </div>
                            {issueMessage.length > 0 &&
                            <div className='py-1 flex items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" className={`text-rose-600 w-4 h-4`} fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                                <span className={`text-sm font-light text-rose-600 `} >&nbsp;{issueMessage}</span>
                            </div>
                            }

                            {!strongPasswordCreated && form.newPassword &&
                            <>
                                <div className='py-1 mt-1 flex items-center'>
                                    {passwordContainsLowerCase ? (
                                        <svg fill="currentColor" className={`text-green-600 w-3 h-3`} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fillRule="evenodd"></path>
                                        </svg>
                                    ):(
                                        <svg xmlns="http://www.w3.org/2000/svg" className={`text-rose-600 w-4 h-4`} fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    )}
                                    <span className={`text-sm font-light ${passwordContainsLowerCase? 'text-gray-600':'text-rose-600'}`} >&nbsp;Password must contain a lower case letter</span>
                                </div>
                                <div className='py-1 flex items-center'>
                                    {passwordContainsUpperCase ? (
                                        <svg fill="currentColor" className={`text-green-600 w-3 h-3`} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fillRule="evenodd"></path>
                                        </svg>
                                    ):(
                                        <svg xmlns="http://www.w3.org/2000/svg" className={`text-rose-600 w-4 h-4`} fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    )}
                                    <span className={`text-sm font-light ${passwordContainsUpperCase? 'text-gray-600':'text-rose-600'}`} >&nbsp;Password must contain a upper case letter</span>
                                </div>
                                <div className='py-1 flex items-center'>
                                    {passwordContainsSpecialChar ? (
                                        <svg fill="currentColor" className={`text-green-600 w-3 h-3`} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fillRule="evenodd"></path>
                                        </svg>
                                    ):(
                                        <svg xmlns="http://www.w3.org/2000/svg" className={`text-rose-600 w-4 h-4`} fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    )}
                                    <span className={`text-sm font-light ${passwordContainsSpecialChar? 'text-gray-600':'text-rose-600'}`} >&nbsp;Password must contain a special character</span>
                                </div>
                                <div className='py-1 flex items-center'>
                                    {passwordContainsNum ? (
                                        <svg fill="currentColor" className={`text-green-600 w-3 h-3`} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fillRule="evenodd"></path>
                                        </svg>
                                    ):(
                                        <svg xmlns="http://www.w3.org/2000/svg" className={`text-rose-600 w-4 h-4`} fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    )}
                                    <span className={`text-sm font-light ${passwordContainsNum? 'text-gray-600':'text-rose-600'}`} >&nbsp;Password must contain a number</span>
                                </div>
                                <div className='py-1 flex items-center'>
                                    {passwordContainsEightChar ? (
                                        <svg fill="currentColor" className={`text-green-600 w-3 h-3`} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fillRule="evenodd"></path>
                                        </svg>
                                    ):(
                                        <svg xmlns="http://www.w3.org/2000/svg" className={`text-rose-600 w-4 h-4`} fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    )}
                                    <span className={`text-sm font-light ${passwordContainsEightChar? 'text-gray-600':'text-rose-600'}`} >&nbsp;Password must contain at least 8 characters</span>
                                </div>
                            </>
                            }
                            {strongPasswordCreated &&
                                <div className='py-1 flex items-center'>
                                    <svg fill="currentColor" className={`text-green-600 w-3 h-3`} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fillRule="evenodd"></path>
                                    </svg>
                                    <span className={`text-sm font-light text-gray-600`} >&nbsp;Nice work! This is an excellent password.</span>
                                </div>
                            }
                            {passwordMatch && form.newPassword.length > 0 && form.confirmNewPassword.length > 0 && 
                                <div className='py-1 flex items-center'>
                                    <svg fill="currentColor" className={`text-green-600 w-3 h-3`} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fillRule="evenodd"></path>
                                    </svg>
                                    <span className={`text-sm font-light text-gray-600`} >&nbsp;Great job! Passwords match.</span>
                                </div>
                            }

                            {!passwordMatch && form.newPassword.length > 0 && form.confirmNewPassword.length > 0 && 
                                <div className='py-1 flex items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`text-rose-600 w-4 h-4`} fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                    <span className={`text-sm font-light text-rose-600`} >&nbsp;Passwords must match.</span>
                                </div>

                            }

                            <button
                                // onClick={() => submitSignUp(email, form.username, form.newPassword, form.firstName, form.lastName)}
                                type='submit'
                                className='w-full p-1.5 font-medium text-base bg-blue-600 text-white hover:bg-blue-700 rounded-lg mt-4 disabled:bg-blue-400'
                                disabled={email === '' || form.firstName === '' || form.lastName === '' || form.username === '' || form.newPassword === '' || form.confirmNewPassword == '' || !passwordMatch || !strongPasswordCreated || !validEmail} 
                            >
                                Create Account
                            </button>
                            <div className={`w-full text-center mt-4 text-gray-500 text-sm font-medium`} >
                                <span className='text-blue-600 hover:text-blue-800 cursor-pointer' onClick={() => navigate('/login')}>Return to login</span>
                            </div>
                        </form>
                        </div>
                </AnimatePresence>
            </div>

            <div className='grow shrink'/>
            <section className='mx-auto' >
                <div className='p-3'>
                    <a className='text-gray-400 text-center text-sm cursor-pointer hover:text-gray-500 pr-4' href="https://www.iotflows.com/" >©IoTFlows Inc 2024</a>
                    <a className='text-gray-400 text-center text-sm cursor-pointer hover:text-gray-500 pr-4' href="https://www.iotflows.com/policies/terms-of-use/" >Terms of Use</a>
                    <a className='text-gray-400 text-center text-sm cursor-pointer hover:text-gray-500 pr-4' href="https://www.iotflows.com/policies/private-policy/" >Private Policy</a>
                </div>
            </section>

        </div>
    </div>
  )
}

export default SignupPage