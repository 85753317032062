import EditEndpointModal from '../components/modals/webhookModals/EditEndpointModal'
import DeleteEndpointModal from '../components/modals/webhookModals/DeleteEndpointModal'
import TestEventModal from '../components/modals/webhookModals/TestEventModal'
import { useEffect, useState } from 'react'
import Switch from '@mui/material/Switch';
import toast from 'react-hot-toast';
import { apiLink } from '../ApiCalls/ApisVariables';
import { getData, putData } from '../ApiCalls/DataApis';
import { motion } from "framer-motion";

function WebhookRow({endpoint, getOrgEndpoints, webhookEvents, testJsonData, selectedOrganization}) {
    let [loadingTest, setLoadingTest] = useState(false)
    const [expand, setExpand] = useState(true)
    const [checkedEventsState, setCheckedEventsState] = useState([])
    const [subscribedEvents, setSubscribedEvents] = useState([])
    const [isPasswordType, setIsPasswordType] = useState(true)

    useEffect(() => {
        if(webhookEvents){
          let webhookLength = Object.keys(webhookEvents).length
          setCheckedEventsState(new Array(webhookLength).fill(false))
        }
      },[webhookEvents])

      useEffect(() => {
            if(endpoint && Object.keys(endpoint).length > 0 && webhookEvents){
                getData(apiLink + '/v1/webhooks/' + endpoint.webhook_uuid + '/subscriptions')
                    .then(async response => {
                    if(response && response.ok ){
                        const data = await response.json();
                        if(data && data.data){
                            setSubscribedEvents(data.data)
                        } 
                    }
                    });
                
    
                
            }
    
        }, [endpoint])

        useEffect(() => {
            if(subscribedEvents && Object.keys(subscribedEvents).length > 0 && checkedEventsState && Object.keys(checkedEventsState).length > 0){

                let updatedCheckedState = checkedEventsState

                subscribedEvents.map(sub_event => {
                    webhookEvents.map((webEvent,index) => {
                        if(sub_event.webhook_event_name == webEvent.webhook_event_name){
                            updatedCheckedState[index] = sub_event.webhook_subscription_is_subscribed
                        }
                    })
                })
                setCheckedEventsState(updatedCheckedState)
            }

        }, [subscribedEvents])


      const handleChangeEventsState = (position) => {
        let updatedCheckedState = checkedEventsState.map((item, index) =>
        index === position ? !item : item
        );
        setCheckedEventsState(updatedCheckedState)
    
        let webhookData = webhookEvents[position]
    
        putData(apiLink + '/v1/webhooks/'+ endpoint.webhook_uuid + '/subscriptions', 
          {'webhook_event_name': webhookData.webhook_event_name,
          'webhook_subscription_is_subscribed': updatedCheckedState[position]
          })
            .then(async response => {
              const data = await response.json();
              if(response.ok){
                if( updatedCheckedState[position]){
                  toast.success(`Subscribed to ${webhookData.webhook_event_name}`)
                }else{
                  toast.success(`Unsubscribed from ${webhookData.webhook_event_name}`)
                }
                
              }
              else{
                try{
                  const data = await response.json();
                  toast.error(data.message)
                  setLoadingTest(false)
                }catch (e){
                  setLoadingTest(false)
                  console.log('error')
                }
              }
            })
        
      }


  return (
    <>
        <tr key={endpoint.webhook_uuid} className="invoiceTableRow items-center">
            <td className='invoiceTableCellBody '>
                <div className='flex cursor-pointer' onClick={() => setExpand(!expand)}>
                    <motion.div
                        className="box mr-2"
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ type: "spring", stiffness: 400, damping: 17 }}
                                        >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={`w-5 h-5 text-gray-600 dark:text-gray-300 transition ease-in-out duration-500 ${expand? '-rotate-180': 'rotate-0'}`}>
                            <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                        </svg>
                    </motion.div>
                    <div>{endpoint.webhook_url}</div>
                </div>
            </td>
            <td className='invoiceTableCellBody '>
                <div className='flex'>
                    <div className='ml-auto'>
                        <EditEndpointModal btnType='inlineIconBtn' endpoint={endpoint} getOrgEndpoints={getOrgEndpoints}/>
                    </div>
                    <div className='ml-2'>
                        <DeleteEndpointModal endpoint={endpoint} getOrgEndpoints={getOrgEndpoints}/>
                    </div>
                </div>
            </td>
        </tr>
        {expand &&
        <tr className=''>
            <td className='invoiceTableCellBody' colSpan={2}>
                <div className='flex flex-col space-y-2 bg-slate-50/80 dark:bg-slate-800/80 px-4 py-4 rounded-xl shadow-md'>
                    <div className='flex border-b-[0.1em] border-slate-200 dark:border-slate-700/80 py-2'>
                            <span className='w-[110px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium '>URL:</span> 
                            <span className='text-gray-500 dark:text-gray-300 my-auto text-base font-normal '>{endpoint.webhook_url}</span> 
                    </div>
                    <div className='flex border-b-[0.1em] border-slate-200 dark:border-slate-700/80 py-2'>
                            <span className='w-[110px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium '>Description:</span> 
                            <span className='text-gray-500 dark:text-gray-300 my-auto text-base font-normal '>{endpoint.webhook_description}</span> 
                    </div>
                    <div className='flex flex-col border-b-[0.1em] border-slate-200 dark:border-slate-700/80 py-2'>
                        <div className='flex'>
                            <span className='w-[110px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium '>Secret:</span> 
                            <input  
                                defaultValue={endpoint.webhook_secret}
                                type={isPasswordType? 'password' : 'text'}
                                disabled
                                className={` block bg-transparent w-full border-0 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-0 sm:text-sm dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800 placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300`} >
                            </input>
                        </div>
                        <div>
                            <button 
                                onClick={() => setIsPasswordType(!isPasswordType)}
                                className='flex text-blue-500 hover:text-blue-700 dark:text-blue-400 hover:dark:text-blue-100 items-center border-0 border-gray-400 hover:border-gray-500 dark:border-gray-500 dark:hover:border-gray-400 rounded py-0 px-0 w-max '>
                                {isPasswordType? 'Reveal':'Hide'}
                            </button>
                        </div>
                    </div>
                    <div className="pt-10">
                        <div className='grid grid-cols-4 gap 4 items-center border-b-[0.1em] border-slate-200 dark:border-slate-700/80 py-2'>
                            <div className={`flex text-gray-600 dark:text-gray-400 my-auto text-sm font-medium col-span-3`} >
                                Events
                            </div>
                            <div className={`flex text-gray-600 dark:text-gray-400 my-auto text-sm font-medium  col-span-1 ml-auto`} >
                                Subscribe
                            </div>
                        </div>
                        <div>
                        {checkedEventsState && checkedEventsState.length > 0 && webhookEvents && Object.keys(webhookEvents).length > 0 && webhookEvents.map((webhook, index)=>(
                            <div key={index} className='grid grid-cols-4 gap 4 items-center border-b-[0.1em] border-slate-200 dark:border-slate-700/80 py-2'>
                                <div className={`text-gray-500 dark:text-gray-300 my-auto  font-normal col-span-3 `}>
                                    <div className='flex flex-col text-base'>
                                    <span className={`text-gray-500 dark:text-gray-300 `}>{webhook.webhook_event_name}</span>
                                    <span className={`text-gray-600 dark:text-gray-400 text-sm`}>{webhook.webhook_event_description}</span>

                                    </div>
                                </div>
                                <div className='col-span-1 flex items-center'>
                                    <div className='ml-auto mr-2 items-center'>
                                        <TestEventModal testJsonData={testJsonData} endpoint={endpoint} webhook={webhook} selectedOrganization={selectedOrganization} />
                                    </div>
                                    <div>
                                        <Switch
                                        // size="small"
                                        checked={checkedEventsState[index]}
                                        // onChange={(e) => setIsDark(e.target.checked)}
                                        onChange={() => handleChangeEventsState(index)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            </td>

        </tr>
        }
    </>
  )
}

export default WebhookRow