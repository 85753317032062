
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getData } from "../ApiCalls/DataApis";
import { apiLink } from "../ApiCalls/ApisVariables";
import MqttService from "../core/services/MqttService";
import { createMessagingMQTT } from "../core/services/MqttMessaging";
import { removeMessage } from "./MessageDataSorting";
// import MqttService from '../../core/services/MqttService'; 
// import emojis from '../components/messagingComponents/emojis';


const PinnedContextMenu = ({ x, y,userInfo, onAddEmoji, contextMenu, setContextMenu, interactionsEmojis, allEmojis, setMessages, messages,pinnedMessages,  setPinnedMessages }) => {
  const [expandEmojis, setExpandEmojis] = useState(false)
  const [emojiSearchText, setEmojiSearchText] = useState('');
  const [filteredEmojis, setFilteredEmojis] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [loadingEmojis, setLoadingEmojis] = useState(false);
  

  const handleExpandEmojis = () => {
    setExpandEmojis(true)
  }


const copyToClipboard = async (messageObject) => {
    try {
    let text = messageObject.message.message_text_html || messageObject.message.message_text
      if (navigator.clipboard && window.ClipboardItem) {
        // Use the Clipboard API to write both HTML and plain text to the clipboard
        await navigator.clipboard.write([
          new ClipboardItem({
            "text/html": new Blob([text], { type: "text/html" }),
            "text/plain": new Blob([text], { type: "text/plain" }),
          }),
        ]);
        setContextMenu(null);
        // console.log('Content copied to clipboard with formatting!');
      } else {
        // Fallback for browsers that do not support the Clipboard API
        const tempElement = document.createElement('div');
        tempElement.innerHTML = text;
        document.body.appendChild(tempElement);

        // Select the text
        const range = document.createRange();
        range.selectNodeContents(tempElement);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        // Copy the selected content
        document.execCommand('copy');
        setContextMenu(null);
        // console.log('Content copied to clipboard with fallback!');

        // Cleanup
        document.body.removeChild(tempElement);
        selection.removeAllRanges();
      }
    } catch (err) {
      console.error('Failed to copy content:', err);
    }
  };



  const contextMenuCss = (y, x) => {
    let newY = y
    // console.log('y contextMenuCss', y)
    if(y > 600 && y <= 675){
      newY = newY - 100
    }else if(y > 675 && y <= 750){
      newY = newY - 150
    }else if(y > 750){
      newY = newY - 175
    }

    let newX = x
    if(x >= 380 && x < 440){
      newX = newX + 80
    }else if(x < 380){
      newX = newX + 100
    }
    
    return {
      top: newY, 
      left: newX
    }
  }

  const emojisListCss = (y, x) => {
    // right-0 top-[-45px]
    // console.log('y emojisListCss', y)
    let newRight = 10
    let newTop = -100
    
    if(y > 500 && y <= 600){
      newTop = newTop - 130
    }else if(y > 600 && y <= 750){
      newTop = newTop - 150
    }else if(y > 750){
      newTop = newTop - 100
    }

  
    
    return {
      top: newTop, 
      right: newRight
    }
  }

  const handleEmojiClick = (emoji) => {
    onAddEmoji(emoji)
  };

  const handleInputChange = (e) => {
    setEmojiSearchText(e.target.value)
    setIsTyping(true); // User started typing
    setLoadingEmojis(true)
    if(e.target.value ===  0){
      setLoadingEmojis(false)
    }
  };

  const debounceCommand = (ms) => {
    let timeout;
    return (text) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsTyping(false); // User stopped typing after delay
        // Run your command here
        let searchText = text.toLowerCase();
        if(searchText && searchText.length > 0){

          getData(apiLink + '/v1/emojis?q=' + searchText)
          .then(async response => {
            const data = await response.json();
            if(response.ok ){
              if(data && data.data){
                setFilteredEmojis(data.data)
                setLoadingEmojis(false)
              }
            }
          })
        }else{
          setLoadingEmojis(false)
        }
      }, ms);
    };
  };

  const delayedCommand = debounceCommand(300); // Debounce for 500ms

  useEffect(() => {
    if (isTyping) {
      delayedCommand(emojiSearchText); // Trigger debounced command with current input
    }
  }, [isTyping, emojiSearchText]);

  const deleteMessageEveryone = (messInfo) => {
    setContextMenu(null)

    // Delete MQTT MESSAGE
    if (MqttService.isConnected) {
        let msg = {
            from_username: userInfo.user_username,
            action: 'delete_message',
            message_uuid: messInfo.message.message_uuid,
        }
        
        msg = createMessagingMQTT(msg);
        if(msg.topic && msg.payload)
        {
            MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
        }

        let updatedPinnedMessages = removeMessage(pinnedMessages, messInfo.message)
        setPinnedMessages({...updatedPinnedMessages})
        
    }else{
        toast.error('Waiting for Network')
    }
  }

  const deleteForMe = (messInfo) => {
    setContextMenu(null)
    // Delete MQTT MESSAGE
    if (MqttService.isConnected) {
        let msg = {
            from_username: userInfo.user_username,
            action: 'delete_message_for_me',
            message_uuid: messInfo.message.message_uuid,
        }
        
        msg = createMessagingMQTT(msg);
        if(msg.topic && msg.payload)
        {
            MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
        }

        const updatedMessages = removeMessage(messages, messInfo.message)
        setMessages({...updatedMessages})

        let updatedPinnedMessages = removeMessage(pinnedMessages, messInfo.message)
        setPinnedMessages({...updatedPinnedMessages})
        
    }else{
        toast.error('Waiting for Network')
    }
  }

//   const pinForMe = (messInfo) => {
//     setContextMenu(null)
//     if (MqttService.isConnected) {
//       let msg = {
//         from_username: userInfo.user_username,
//         action: 'pin_message_for_me',
//         message_uuid: messInfo.message.message_uuid,
//         should_pin: true,
//       }
      
      
//       msg = createMessagingMQTT(msg);
//       console.log('Pinned Me msg', messInfo.message)
//       let uuid = messInfo.message.message_uuid
//       let pins = pinnedMessages
//       pins[uuid] = messInfo.message
//       console.log('Pins', pins)

//       setPinnedMessages({...pins})
//       if(msg.topic && msg.payload)
//         {
//             MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
//         }

//         // const updatedMessages = removeMessage(messages, messInfo.message)
//         // setMessages({...updatedMessages})
        
//     }else{
//         toast.error('Waiting for Network')
//     }
//   }

//   const pinForAll = (messInfo) => {
//     setContextMenu(null)
//     // Delete MQTT MESSAGE
//     console.log('messInfo', messInfo)
//     if (MqttService.isConnected) {
//       let msg = {
//         from_username: userInfo.user_username,
//         action: 'pin_message',
//         message_uuid: messInfo.message.message_uuid,
//         should_pin: true,
//       }
      
      
//       msg = createMessagingMQTT(msg);
      
//       if(msg.topic && msg.payload)
//         {
//             MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
//         }

//         // const updatedMessages = removeMessage(messages, messInfo.message)
//         // setMessages({...updatedMessages})
        
//     }else{
//         toast.error('Waiting for Network')
//     }
//   }
  const unPinMessage = (pinnedMsg) => {
    setContextMenu(null)

    let updatedPinnedMessages = removeMessage(pinnedMessages, pinnedMsg.message)
    setPinnedMessages({...updatedPinnedMessages})
    
    // Remove Pinned Message
    if (MqttService.isConnected) {
        let action = 'pin_message_for_me'
        if(pinnedMsg.message.message_is_pinned_for_all){
            // checking to see if the message is just for me or if everyone has this pinned
            action = 'pin_message'

        }
        let msg = {
            from_username: pinnedMsg.user_username,
            action: action,
            message_uuid: pinnedMsg.message.message_uuid,
            should_pin: false,
            }
        
        msg = createMessagingMQTT(msg);
        if(msg.topic && msg.payload)
        {
            MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
        }
        
    }else{
        toast.error('Waiting for Network')
    }

  }

    return (
      <motion.div
        animate={{
          scale: [0, 1.2,  1],
          // rotate: [0, 0, 270, 270, 0],
          // borderRadius: ["20%", "20%", "50%", "50%", "20%"],
        }}
        className={`fixed z-[101]`} style={contextMenuCss(y, x)}
      >
        <div className='relative '>
          <AnimatePresence mode="wait">
            {expandEmojis ? (
                  <motion.div
                      key='emojisDiv'
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, scale: [0, 1.05,  1] }}
                      exit={{ opacity: 0 }}
                      className={`absolute ml-8 mt-8 mb-2 mr-2 scrollbar-hide backdrop-blur-lg`}
                      style={emojisListCss(y, x)}
                  >
                    <div 
                      className={`relative w-[300px] h-[350px] scrollbar-hide bg-white/90 dark:bg-zinc-900/90 rounded-xl flex flex-col p-3 shadow-iotflows-lg dark:shadow-xs-invert `}
                      >
                        <div className='absolute top-1 left-0 right-0 flex rounded-full w-full h-8 items-center px-2'>
                          <div className='pl-1 flex items-center pointer-events-none'>
                              <svg className={`h-4 w-4 dark:text-gray-300 text-gray-500`}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                              </svg>
                          </div>
                          <input className={`bg-transparent  text-gray-600 border-0 focus:ring-[0px]  placeholder:text-gray-500 dark:text-gray-300 dark:placeholder:text-gray-400
                              block w-full text-sm rounded-lg text-ellipsis py-1`}
                              value={emojiSearchText} 
                              onChange={handleInputChange} 
                              type="text" 
                              placeholder="Search Emojis..."
                          />
                          
                          <div className={`${loadingEmojis ? 'opacity-100': 'opacity-0'} `}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="animate-spin h-5 w-5 text-gray-500 dark:text-gray-300">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
                            </svg>
                          </div>
                        </div>
                        <div className='mt-7 overflow-y-scroll scrollbar-hide'>
                          {emojiSearchText.length > 0 && Object.keys(filteredEmojis).length > 0 &&
                            <div
                            key='filteredList'
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }} className='flex flex-wrap pb-2'>
                              {filteredEmojis.map(emoji => (
                                <span key={emoji.emoji_codepoints} onClick={() => handleEmojiClick(emoji)} className='text-2xl p-1 cursor-pointer'>
                                    {/* {String.fromCodePoint(parseInt("0x" + emoji.u, 16))} */}
                                    <div className={`${emoji.emoji_codepoints == '2764 FE0F' && 'text-red-500'}`}>{emoji.emoji_emoji}</div>
                                </span>
                              ))}
                            </div>
                          }
                        {emojiSearchText.length === 0 && Object.keys(allEmojis).map((emojiSection) => (
                            <div key={emojiSection}>
                                <h6 className='text-gray-600 dark:text-gray-300 text-sm pb-1 pt-2'>
                                    {emojiSection}
                                </h6>
                                <div className='flex flex-wrap'>
                                {allEmojis[emojiSection].map(emoji => (
                                    <span key={emoji.emoji_codepoints} onClick={() => handleEmojiClick(emoji)} className='text-2xl p-1 cursor-pointer'>
                                        <div className={`${emoji.emoji_codepoints == '2764 FE0F' && 'text-red-500'}`}>{emoji.emoji_emoji}</div>
                                    </span>
                                ))}
                                </div>
                            </div>
                            
                        ))}

                        </div>
                    </div>
                  </motion.div>
            

            ):(
              <div className='backdrop-blur-lg'>
                <div className='absolute right-0 top-[-45px] flex items-center space-x-2 bg-white/90 dark:bg-zinc-900/90 backdrop-blur-lg shadow-iotflows-lg dark:shadow-xs-invert rounded-full px-2 '>
                  {interactionsEmojis.map(emoji => (
                    <motion.button
                      whileHover={{ scale:1.2 }} 
                      whileTap={{ scale: 0.95  }}  
                      key={emoji.emoji_codepoints} onClick={() => onAddEmoji(emoji)} className='text-2xl p-1.5 rounded-full'>
                        {/* {String.fromCodePoint(parseInt("0x" + emoji.u, 16))} */}
                        <div className={`${emoji.emoji_codepoints == '2764 FE0F' && 'text-red-500'}`}>{emoji.emoji_emoji}</div>
                    </motion.button>
                  ))}
                  <motion.button
                      whileHover={{ scale:1.2 }} 
                      whileTap={{ scale: 0.95  }}  
                      onClick={handleExpandEmojis} className='p-1 w-7 h-7 rounded-full bg-slate-50 dark:bg-slate-800 text-gray-600 dark:text-gray-300 '
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>

                        
                    </motion.button>
                </div>
                <div className='absolute right-0 top-[10px] w-[200px] flex flex-col bg-white/90 dark:bg-zinc-900/90 backdrop-blur-lg rounded-xl p-2 shadow-iotflows-lg dark:shadow-xs-invert'>
                  {/* <button 
                    className={`group flex w-full items-center rounded-md px-2 py-1 text-sm 
                    hover:bg-zinc-100 hover:dark:bg-zinc-500/30 text-gray-600 dark:text-gray-300/80 hover:dark:text-gray-200 hover:text-gray-600`}
                    onClick={onReply}>
                    <div className='w-5 group-hover:scale-110 group-hover:duration-500'>
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 122.88 98.86" fill='currentColor' stroke='none' strokeWidth='8px' fillRule='evenodd' clipRule='evenodd' className='w-4 h-4 ' xmlSpace='preserve'>
                        <g><path  d="M0,49.43l48.93,49.43V74.23c30.94-6.41,55.39,0.66,73.95,24.19c-3.22-48.4-36.29-71.76-73.95-73.31V0L0,49.43 L0,49.43L0,49.43z"/></g>
                      </svg>
                    </div>
                      <span className='pl-2'>Reply</span>
                  </button>    */}
                    <button 
                      className={`group flex w-full items-center rounded-md px-2 py-1 text-sm 
                      hover:bg-zinc-100 hover:dark:bg-zinc-500/30 text-gray-600 dark:text-gray-300/80 hover:dark:text-gray-200 hover:text-gray-600`}
                      onClick={() => copyToClipboard(contextMenu)}>
                      <div className='w-5 items-center group-hover:scale-110 group-hover:duration-500'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                      </svg>
                      </div>
                        <span className='pl-2'>Copy Text</span>
                    </button>    
                  <div className='w-full border-b-[0.1em] border-zinc-100 dark:border-zinc-600/30 my-1' />        
                  {/* <button 
                    className={`group flex w-full items-center rounded-md px-2 py-1 text-sm 
                    hover:bg-zinc-100 hover:dark:bg-zinc-500/30 text-gray-600 dark:text-gray-300/80 hover:dark:text-gray-200 hover:text-gray-600`}
                    onClick={() => pinForMe(contextMenu)}>
                    <div className='w-5 items-center group-hover:scale-110 group-hover:duration-500'>
                      <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 -960 960 960" fill="currentColor" className="w-5 h-4">
                        <path d="M640-760v280l68 68q6 6 9 13.5t3 15.5v23q0 17-11.5 28.5T680-320H520v234q0 17-11.5 28.5T480-46q-17 0-28.5-11.5T440-86v-234H280q-17 0-28.5-11.5T240-360v-23q0-8 3-15.5t9-13.5l68-68v-280q-17 0-28.5-11.5T280-800q0-17 11.5-28.5T320-840h320q17 0 28.5 11.5T680-800q0 17-11.5 28.5T640-760ZM354-400h252l-46-46v-314H400v314l-46 46Zm126 0Z"/>
                      </svg>
                    </div>
                      <span className='pl-2'>Pin for Me</span>
                  </button>    */}
                  <button 
                    className={`group flex w-full items-center rounded-md px-2 py-1 text-sm 
                    hover:bg-zinc-100 hover:dark:bg-zinc-500/30 text-gray-600 dark:text-gray-300/80 hover:dark:text-gray-200 hover:text-gray-600`}
                    onClick={() => unPinMessage(contextMenu)}>
                    <div className='w-5 items-center group-hover:scale-110 group-hover:duration-500'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-4" viewBox="0 -960 960 960"  fill="currentColor">
                        <path d="M560-760H400v87L290-783q-5-5-7.5-11t-2.5-12q0-13 9-23.5t24-10.5h327q17 0 28.5 11.5T680-800q0 16-14.5 22.5T640-760v240q0 17-11.5 28.5T600-480q-17 0-28.5-11.5T560-520v-240ZM440-80v-240H296q-25 0-40-17.5T241-377q0-11 4.5-22t14.5-21l60-60v-46L84-764q-11-11-11.5-27.5T84-820q11-11 28-11t28 11l679 679q12 12 11.5 28.5T818-84q-12 11-28 11.5T762-84L526-320h-6v240q0 17-11.5 28.5T480-40q-17 0-28.5-11.5T440-80Zm-86-320h92l-44-44-2-2-46 46Zm126-193Zm-78 149Z"/>
                      </svg>
                    </div>
                      <span className='pl-2'>Unpin</span>
                  </button>   
                  {/* {contextMenu.message_username === contextMenu.user_username   && 
                  <button 
                    className={`group flex w-full items-center rounded-md px-2 py-1 text-sm 
                    hover:bg-zinc-100 hover:dark:bg-zinc-500/30 text-gray-600 dark:text-gray-300/80 hover:dark:text-gray-200 hover:text-gray-600`}
                    onClick={onEdit}
                  >
                    <div className='w-5 items-center group-hover:scale-110 group-hover:duration-500'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                      </svg>
                    </div>
                      <span className='pl-2'>Edit</span>
                  </button> 
                  }          */}
                  <button 
                    className={`group flex w-full items-center rounded-md px-2 py-1 text-sm 
                    hover:bg-zinc-100 hover:dark:bg-zinc-500/30 text-gray-600 dark:text-gray-300/80 hover:dark:text-gray-200 hover:text-gray-600`}
                    onClick={() => deleteForMe(contextMenu)}
                    >
                    <div className='w-5 items-center group-hover:scale-110 group-hover:duration-500'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                      </svg>
                    </div>
                      <span className='pl-2'>Delete for Myself</span>
                  </button>    
                  {contextMenu.message_username === contextMenu.user_username   &&  
                  <button 
                    className={`group flex w-full items-center rounded-md px-2 py-1 text-sm 
                    hover:bg-zinc-100 hover:dark:bg-zinc-500/30 text-red-600 dark:text-red-400 hover:dark:text-gray-200 hover:text-gray-600`}
                    onClick={() => deleteMessageEveryone(contextMenu)}
                    >
                    <div className='w-5 items-center group-hover:scale-110 group-hover:duration-500'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                      </svg>
                    </div>
                      <span className='pl-2'>Delete for Everyone</span>
                  </button>        
                  }
                </div>
              </div>

            )}
          </AnimatePresence>
        </div>
      </motion.div>
    );
  };
  
  export default PinnedContextMenu;