import { useEffect, useState } from 'react'
import HighchartsStock from 'highcharts/highstock';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import HighchartsReact from "highcharts-react-official";
import { useRecoilValue } from 'recoil';
import { darkModeState } from '../../atoms/darkModeState';
import { createColorPalette } from '../../styles/gaugesColorPalette';


function ProgressHalfGauge({number, unit, currentShiftData, shiftGoalCount, grayoutSection, gaugeData}) {
    const isDark = useRecoilValue(darkModeState);
    const [halfPieChartOptions, setHalfPieChartOptions] = useState({
        chart: {
            type: 'solidgauge',
            height: '100%',
            backgroundColor: 'transparent',
          },
        time: {
          useUTC: false
        },
        colors: ['#16a34a','#4ade80','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
        // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
        credits: {enabled: false},
        title: null,
        exporting: {
          enabled: false
        },
        pane: {
          startAngle: -100,
          endAngle: 100,
          background: {
            backgroundColor: 'transparent',
            borderWidth: 0
          }
        },
      //   pane: {
      //     startAngle: 0,
      //     endAngle: 360,
      //     background: [{ // Track for Move
      //         outerRadius: '110%',
      //         innerRadius: '75%',
      //         backgroundColor: '#4ade80',
      //         borderWidth: 0
      //     }]
      // },
          series: [{
              data: [{
              color: 'transparent',
              // radius: 55,
              innerRadius: '75%',
              y: 100
              }],
          },
          {
              data: [{
              color: 'transparent',
              // radius: 55,
              innerRadius: '75%',
              y: 0,
              }],
          }
          ],
        plotOptions: {
          solidgauge: {
              dataLabels: {
                  enabled: false
              },
              linecap: 'round',
              stickyTracking: false,
              rounded: true,
          },
        },
      //   yAxis: {
      //     min: 0,
      //     max: 100,
      //     lineWidth: 0,
      //     tickPositions: []
      // },
      yAxis: {
          labels: {
            enabled: false,
          },
          min: 0,
          max: 100,
          lineWidth: 0,
          minorTickWidth: 0,
          zIndex: 10,
          tickLength: 0,
          offset: 10
        },
        legend: {
          itemDistance:0,
        },
        tooltip: {
          pointFormat: '{series.name}: {point.y}'
        }
        
    })
    const [goalPieChartOptions, setGoalPieChartOptions] = useState({
        chart: {
          type: 'solidgauge',
          height: '100%',
          backgroundColor: 'transparent',
        },
        time: {
          useUTC: false
        },
        colors: ['#16a34a','#4ade80','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
        // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
        credits: {enabled: false},
        title: null,
        exporting: {
          enabled: false
        },
        pane: {
          startAngle: 97,
          endAngle: 180,
          background: {
            backgroundColor: 'transparent',
            borderWidth: 0,
          }
        },
        series: [{
          name: 'Goal',
          data: [{
              color: 'transparent',
              radius: '96%',
              innerRadius: '81%',
              y: 0.1
          }]
        },
        ],
        plotOptions: {
          solidgauge: {
              dataLabels: {
                  enabled: false
              },
              linecap: 'round',
              stickyTracking: false,
              rounded: true,
          },
        },
        yAxis: {
          labels: {
            enabled: false,
          },
          min: 0,
          max: 100,
          lineWidth: 0,
          minorTickWidth: 0,
          zIndex: 10,
          tickLength: 0,
          offset: 10
        },
        legend: {
          itemDistance:0,
        },
        tooltip: {
          pointFormat: '{series.name}: '
        }
        
    })



    // useEffect(() => {
    //     let colors = createColorPalette(isDark)
          
    //     var greenColor = grayoutSection ? 'rgba(100, 116, 139, 0.2)' : colors.mainPalette.primaryGreen
    //     var redColor = grayoutSection ? 'rgba(100, 116, 139, 0.2)'  : colors.mainPalette.primaryRed
    //     // Progressc color for gauge (red/green depending on where it is compared to current goal %)
    //     var progressColor = greenColor
    //     var countProgressPercent = 100
        
    //     if(shiftGoalCount ){
    //       countProgressPercent = (number/shiftGoalCount) *100
    //     }else if(shiftGoalCount === 0){
    //       countProgressPercent = 100
    //     }else{
    //       if(number == 0){
    //         countProgressPercent = 0
    //         progressColor = redColor
    //       }
    //     }
  
    //     if(currentShiftData  && currentShiftData.progress ){
    //       var goalPercentProgress  = Math.abs(currentShiftData.progress)
    //       var panePosition = (97*2)*goalPercentProgress
    //       panePosition = -97 + panePosition
    //       setGoalPieChartOptions({
    //         chart: {
    //           type: 'solidgauge',
    //           height: '100%',
    //           backgroundColor: 'transparent',
    //         },
    //         time: {
    //           useUTC: false
    //         },
    //         colors: ['#16a34a','#4ade80','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
    //         // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
    //         credits: {enabled: false},
    //         title: null,
    //         exporting: {
    //           enabled: false
    //         },
    //         pane: {
    //           startAngle: panePosition,
    //           endAngle: 200,
    //           background: {
    //             backgroundColor: 'transparent',
    //             borderWidth: 0
    //           }
    //         },
    //         series: [{
    //           name: 'Goal',
    //           data: [{
    //               color: 'rgba(255,255,255, 1)',
    //               radius: '98%',
    //               innerRadius: '86%',
    //               y: 0.1
    //           }]
    //         },
    //         ],
    //         plotOptions: {
    //           solidgauge: {
    //               dataLabels: {
    //                   enabled: false
    //               },
    //               linecap: 'round',
    //               stickyTracking: false,
    //               rounded: true,
    //           },
    //         },
    //         yAxis: {
    //           labels: {
    //             enabled: false,
    //           },
    //           min: 0,
    //           max: 100,
    //           lineWidth: 0,
    //           minorTickWidth: 0,
    //           zIndex: 10,
    //           tickLength: 0,
    //           offset: 10
    //         },
    //         legend: {
    //           itemDistance:0,
    //         },
    //         tooltip: {
    //           pointFormat: 'Shift Goal ' + shiftGoalCount
    //         }
    //       })
  
          
    //       let currentGoal = goalPercentProgress *100
    //       if(countProgressPercent < currentGoal){
    //         progressColor = redColor
    //       }
          
    //       setHalfPieChartOptions({
    //         chart: {
    //           type: 'solidgauge',
    //           height: '100%',
    //           backgroundColor: 'transparent',
    //         },
    //         time: {
    //           useUTC: false
    //         },
    //         colors: ['#16a34a','#4ade80','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
    //         // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
    //         credits: {enabled: false},
    //         title: null,
    //         exporting: {
    //           enabled: false
    //         },
    //         pane: {
    //           startAngle: -100,
    //           endAngle: 100,
    //           background: {
    //             backgroundColor: 'transparent',
    //             borderWidth: 0
    //           }
    //         },
    //       //   pane: {
    //       //     startAngle: 0,
    //       //     endAngle: 360,
    //       //     background: [{ // Track for Move
    //       //         outerRadius: '110%',
    //       //         innerRadius: '75%',
    //       //         backgroundColor: '#4ade80',
    //       //         borderWidth: 0
    //       //     }]
    //       // },
    //           series: [{
    //               data: [{
    //               color: isDark? '#334155': '#e2e8f0',
    //               // radius: 55,
    //               innerRadius: '75%',
    //               y: 100
    //               }],
    //           },
    //           {
    //               data: [{
    //               color: progressColor,
    //               // radius: 55,
    //               innerRadius: '75%',
    //               y: countProgressPercent,
    //               }],
    //           }
    //           ],
    //         plotOptions: {
    //           solidgauge: {
    //               dataLabels: {
    //                   enabled: false
    //               },
    //               linecap: 'round',
    //               stickyTracking: false,
    //               rounded: true,
    //           },
    //         },
    //       //   yAxis: {
    //       //     min: 0,
    //       //     max: 100,
    //       //     lineWidth: 0,
    //       //     tickPositions: []
    //       // },
    //       yAxis: {
    //           labels: {
    //             enabled: false,
    //           },
    //           min: 0,
    //           max: 100,
    //           lineWidth: 0,
    //           minorTickWidth: 0,
    //           zIndex: 10,
    //           tickLength: 0,
    //           offset: 10
    //         },
    //         legend: {
    //           itemDistance:0,
    //         },
    //         tooltip: {
    //           pointFormat: '{series.name}: {point.y}'
    //         }
            
    //       })
    //     }else{
    //       setHalfPieChartOptions({
    //         chart: {
    //             type: 'solidgauge',
    //             height: '100%',
    //             backgroundColor: 'transparent',
    //           },
    //         time: {
    //           useUTC: false
    //         },
    //         colors: ['#16a34a','#4ade80','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
    //         // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
    //         credits: {enabled: false},
    //         title: null,
    //         exporting: {
    //           enabled: false
    //         },
    //         pane: {
    //           startAngle: -100,
    //           endAngle: 100,
    //           background: {
    //             backgroundColor: 'transparent',
    //             borderWidth: 0
    //           }
    //         },
    //       //   pane: {
    //       //     startAngle: 0,
    //       //     endAngle: 360,
    //       //     background: [{ // Track for Move
    //       //         outerRadius: '110%',
    //       //         innerRadius: '75%',
    //       //         backgroundColor: '#4ade80',
    //       //         borderWidth: 0
    //       //     }]
    //       // },
    //           series: [{
    //               data: [{
    //               color: isDark? '#334155': '#e2e8f0',
    //               // radius: 55,
    //               innerRadius: '75%',
    //               y: 100
    //               }],
    //           },
    //           {
    //               data: [{
    //               color: progressColor,
    //               // radius: 55,
    //               innerRadius: '75%',
    //               y: countProgressPercent,
    //               }],
    //           }
    //           ],
    //         plotOptions: {
    //           solidgauge: {
    //               dataLabels: {
    //                   enabled: false
    //               },
    //               linecap: 'round',
    //               stickyTracking: false,
    //               rounded: true,
    //           },
    //         },
    //       //   yAxis: {
    //       //     min: 0,
    //       //     max: 100,
    //       //     lineWidth: 0,
    //       //     tickPositions: []
    //       // },
    //       yAxis: {
    //           labels: {
    //             enabled: false,
    //           },
    //           min: 0,
    //           max: 100,
    //           lineWidth: 0,
    //           minorTickWidth: 0,
    //           zIndex: 10,
    //           tickLength: 0,
    //           offset: 10
    //         },
    //         legend: {
    //           itemDistance:0,
    //         },
    //         tooltip: {
    //           pointFormat: '{series.name}: {point.y}'
    //         }
            
    //       })
    //     }

    // }, [isDark, shiftGoalCount,number, currentShiftData])

    useEffect(() => {
      if(gaugeData && Object.keys(gaugeData).length > 0){

        let colors = createColorPalette(isDark)
          
        var primaryColor = grayoutSection ? 'rgba(100, 116, 139, 0.2)' : colors.mainPalette.primaryGreen

        let count_goal = Number(gaugeData.operation_count_goal)
        let efficiency_percent = Math.round(Number(gaugeData.operation_production_count_efficiency) * 100)
        let goal_percent = Math.round(Number(gaugeData.asset_oee_goal) * 100)


        if(goal_percent > efficiency_percent){  
          primaryColor =   grayoutSection ? 'rgba(100, 116, 139, 0.2)'  : colors.mainPalette.primaryRed
      }

        setHalfPieChartOptions({
          chart: {
            type: 'solidgauge',
            height: '100%',
            backgroundColor: 'transparent',
          },
          time: {
            useUTC: false
          },
          colors: ['#16a34a','#4ade80','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
          // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
          credits: {enabled: false},
          title: null,
          exporting: {
            enabled: false
          },
          pane: {
            startAngle: -100,
            endAngle: 100,
            background: {
              backgroundColor: 'transparent',
              borderWidth: 0
            }
          },
        //   pane: {
        //     startAngle: 0,
        //     endAngle: 360,
        //     background: [{ // Track for Move
        //         outerRadius: '110%',
        //         innerRadius: '75%',
        //         backgroundColor: '#4ade80',
        //         borderWidth: 0
        //     }]
        // },
            series: [{
                data: [{
                color: isDark? '#334155': '#e2e8f0',
                // radius: 55,
                innerRadius: '75%',
                y: 100
                }],
            },
            {
                data: [{
                color: primaryColor,
                // radius: 55,
                innerRadius: '75%',
                y: efficiency_percent,
                }],
            }
            ],
          plotOptions: {
            solidgauge: {
                dataLabels: {
                    enabled: false
                },
                linecap: 'round',
                stickyTracking: false,
                rounded: true,
            },
          },
        //   yAxis: {
        //     min: 0,
        //     max: 100,
        //     lineWidth: 0,
        //     tickPositions: []
        // },
        yAxis: {
            labels: {
              enabled: false,
            },
            min: 0,
            max: 100,
            lineWidth: 0,
            minorTickWidth: 0,
            zIndex: 10,
            tickLength: 0,
            offset: 10
          },
          legend: {
            itemDistance:0,
          },
          tooltip: {enabled :false},
          // tooltip: {
          //   pointFormat: `Goal : ${count_goal}`
          // }
          
        })

        // let goalPos = Number(gaugeData.asset_oee_goal)*360

          var goalPercentProgress  = Math.abs(Number(gaugeData.asset_oee_goal))
        var panePosition = (97*2)*goalPercentProgress
        panePosition = -97 + panePosition

        setGoalPieChartOptions({
            chart: {
              type: 'solidgauge',
              height: '100%',
              backgroundColor: 'transparent',
            },
            time: {
              useUTC: false
            },
            colors: ['#16a34a','#4ade80','rgba(0,91,150,0.8)','rgba(100,151,177,0.8)','rgba(179,205,224,0.8)'],
            // colors: ['#1E3A8A', '#1E40AF', '#1D4ED8', '#2563EB', '#3B82F6', '#60A5FA', '#93C5FD','#BFDBFE','#DBEAFE','#EFF6FF',   '#312E81', '#3730A3', '#4338CA', '#4F46E5', '#6366F1', '#818CF8', '#A5B4FC','#C7D2FE','#E0E7FF','#EEF2FF'],
            credits: {enabled: false},
            title: null,
            exporting: {
              enabled: false
            },
            pane: {
              startAngle: panePosition,
              endAngle: 200,
              background: {
                backgroundColor: 'transparent',
                borderWidth: 0
              }
            },
            series: [{
              name: 'Goal',
              data: [{
                  color: 'rgba(255,255,255, 1)',
                  radius: '98%',
                  innerRadius: '86%',
                  y: 0.1
              }]
            },
            ],
            plotOptions: {
              solidgauge: {
                  dataLabels: {
                      enabled: false
                  },
                  linecap: 'round',
                  stickyTracking: false,
                  rounded: true,
              },
            },
            yAxis: {
              labels: {
                enabled: false,
              },
              min: 0,
              max: 100,
              lineWidth: 0,
              minorTickWidth: 0,
              zIndex: 10,
              tickLength: 0,
              offset: 10
            },
            legend: {
              itemDistance:0,
            },
            tooltip: {enabled :false},
            // tooltip: {
            //   pointFormat: `Goal : ${count_goal}`
            // }
          })
      }


    },[isDark, gaugeData])

    // var centerValue = number

    // if(number > 1000000){
    //   centerValue = number/1000000
    //   centerValue = centerValue.toFixed(1)
    //   centerValue = centerValue+ 'M'
    // }else if(number > 100000){
    //   centerValue = number/1000
    //   centerValue = centerValue.toFixed(0)
    //   centerValue = centerValue+ 'k'
    // }else if(number > 10000){
    //   centerValue = number/1000
    //   centerValue = centerValue.toFixed(1)
    //   centerValue = centerValue+ 'k'
    // }


    let centerValue = 0
    let currentCount = 0
    if(gaugeData && gaugeData.overridden_good_count){
        centerValue = gaugeData.overridden_good_count ? Number(gaugeData.overridden_good_count) : 0
        currentCount = gaugeData.overridden_good_count ? Number(gaugeData.overridden_good_count) : 0
        if(typeof currentCount == 'number'){
          if(currentCount > 1000000){
            centerValue = currentCount/1000000
            centerValue = centerValue.toFixed(1)
            centerValue = centerValue+ 'M'
          }else if(currentCount > 100000){
            centerValue = currentCount/1000
            centerValue = centerValue.toFixed(0)
            centerValue = centerValue+ 'k'
          }else if(currentCount > 10000){
            centerValue = currentCount/1000
            centerValue = centerValue.toFixed(1)
            centerValue = centerValue+ 'k'
          }
        }

    }



  return (
    <div className='relative'>
        <div className='absolute top-[40%] w-full text-center'>
            <span className={`${currentCount > 9999  ? 'text-base':'text-lg'} ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-600 dark:text-white'} font-bold`} >{centerValue}</span>
        </div>
        <div  className={`absolute top-[65%] w-full text-center`} >
            <span className={`text-sm ${grayoutSection? 'text-gray-400 dark:text-gray-500' : 'text-gray-400 dark:text-white'} font-bold`} >{unit}</span>
        </div>
        <div className='mt-[-12px] sm:mt-0 lg:mt-[-12px]'>
            <HighchartsReact
                highcharts={Highcharts}
                containerProps={{ style: { height: 80, width:130} }} 
                constructorType={'chart'}
                options={halfPieChartOptions}
            />
            <div className='absolute top-[4%] sm:top-[3%] lg:top-[5%]'>
                <HighchartsReact
                    highcharts={Highcharts}
                    containerProps={{ style: { height: 80, width:130} }} 
                    constructorType={'chart'}
                    options={goalPieChartOptions}
                />
            </div>
        </div>
    </div>
  )
}

export default ProgressHalfGauge