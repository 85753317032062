import React, { useEffect, useState } from 'react'
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilState } from 'recoil';
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/react'
import { statusOptions } from '../statusOptions';
import { Avatar, AvatarGroup } from '@mui/material';
import { formatRelativeTime, formatCalendarTimeWithExactTime, isDatePastDue } from '../../utils/DateFormating';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { putData } from '../../ApiCalls/DataApis';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
  


function WorkOrderTableRow({order, isMobile, selectedOrganization, setWorkOrdersList}) {
    const navigate = useNavigate();
    const [isDark, setIsDark] = useRecoilState(darkModeState)
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(
        statusOptions.find((status) => status.id === order.workorder_workorder_status_id)
      );

    useEffect(()=> {
        if(order && Object.keys(order).length > 0){
            let status = statusOptions.find((status) => status.id === order.workorder_workorder_status_id)
            setSelectedStatus(status)
        }

    },[order])

   

    const handleStatusChange = (newStatus) => {
        setSelectedStatus(newStatus)
        if(!loadingStatus){
            setLoadingStatus(true)
            putData(apiLink + '/v1/workorders/' + order.workorder_uuid , 
            {'workorder_status_uuid': newStatus.workorder_status_uuid})
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoadingStatus(false)
                }
                else{
                    let status = statusOptions.find((status) => status.id === order.workorder_workorder_status_id)
                    setSelectedStatus(status)
                    setLoadingStatus(false)
                    try{
                        toast.error(data.message)
                    }catch (e){
                        toast.error(e)
                    }
                }
            })
        }
      };

  
const handleNavigation = () => {
    if(isMobile){
        navigate(`/${selectedOrganization}/work-orders?selected-wo=${order.workorder_uuid}&platform=mobile`)
    }else{
        navigate(`/${selectedOrganization}/work-orders?selected-wo=${order.workorder_uuid}`)
    }
}

   

  return (
    <tr  className='border-b-[0.01em] border-zinc-300 dark:border-zinc-500 group '>
        <td onClick={handleNavigation} className="rounded-l-xl bg-slate-100 dark:bg-slate-800 cursor-pointer group-hover:bg-slate-200 group-hover:dark:bg-slate-950 text-base text-gray-400 dark:text-gray-400  pl-4 pr-2 py-3 sticky left-0 z-10 truncate">#{order.workorder_number}</td>
        <td onClick={handleNavigation} className="bg-slate-100 dark:bg-slate-800 cursor-pointer group-hover:bg-slate-200 group-hover:dark:bg-slate-950 text-base text-gray-600 dark:text-gray-200 px-2 py-3 truncate">{order.workorder_title}</td>
        <td onClick={handleNavigation} className="bg-slate-100 dark:bg-slate-800 cursor-pointer group-hover:bg-slate-200 group-hover:dark:bg-slate-950 text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-3">
            {order.workorder_priority && Object.keys(order.workorder_priority).length > 0 &&
                <div className='flex space-x-2'>
                    <div  className={`${order.workorder_priority && order.workorder_priority.workorder_priority_icon_color ? order.workorder_priority.workorder_priority_icon_color : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" className='w-5 h-5' viewBox="0 -960 960 960"  fill="currentColor" >
                            <path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-160q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70Z"/>
                        </svg>
                    </div>
                    <span>
                        {order.workorder_priority.workorder_priority_name}
                    </span>
                </div>
            }
        </td>
        <td onClick={handleNavigation} className={`bg-slate-100 dark:bg-slate-800 ${isDatePastDue(order.workorder_due_timestamp) ? 'text-red-600 dark:text-red-200':'text-gray-600 dark:text-gray-200'} cursor-pointer  group-hover:bg-slate-200 group-hover:dark:bg-slate-950 text-sm font-light  h-6 truncate overflow-hidden text-ellipsis px-2 py-3`}>
            {formatCalendarTimeWithExactTime(order.workorder_due_timestamp)}
        </td>
        <td onClick={handleNavigation} className="bg-slate-100 dark:bg-slate-800 cursor-pointer group-hover:bg-slate-200 group-hover:dark:bg-slate-950 text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-3">
            <div className='pl-0 pr-4'>
                {AssignedCell(order)}
            </div>
        </td>
        <td onClick={handleNavigation} className="bg-slate-100 dark:bg-slate-800 cursor-pointer group-hover:bg-slate-200 group-hover:dark:bg-slate-950 text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-3 min-w-[175px] truncate overflow-hidden text-ellipsis">
            {(order.workorder_asset && Object.keys(order.workorder_asset).length > 0) && (
                <div className='flex items-center'>
                    <div className='mr-3'>
                        <Avatar
                            sx={{ 
                                // bgcolor: order.user_color? member.user_color : '' , 
                                fontSize:'12px',
                                width: 32, 
                                height: 32,
                            }}
                            // style={{
                            //     background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${member.user_color? member.user_color:''} 100%)`,
                            // }}
                            alt={order.workorder_asset.asset_custom_name ? order.workorder_asset.asset_custom_name : order.workorder_asset.machine_name }
                            src={order.workorder_asset.asset_custom_picture_url ? order.workorder_asset.asset_custom_picture_url : order.workorder_asset.machine_picture_url }
                        />
                    </div>
                    <div className='flex flex-col '>
                        <span className='text-sm'>
                            {order.workorder_asset.asset_custom_name ? order.workorder_asset.asset_custom_name : order.workorder_asset.machine_name }
                        </span>
                        <span className='text-gray-500 dark:text-gray-400 text-xs'>
                        {order.workorder_asset.asset_custom_identifier ? order.workorder_asset.asset_custom_identifier : order.workorder_asset.machine_identifier }
                        </span>

                    </div>

                </div>
            )}
            
        </td>
        <td onClick={handleNavigation} className="bg-slate-100 dark:bg-slate-800 cursor-pointer group-hover:bg-slate-200 group-hover:dark:bg-slate-950 text-sm font-light h-6 px-2 py-3 truncate ">
            {order.workorder_recurrence_description ? (
                <div className='px-4 py-1 bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white rounded-full w-fit'>
                    {order.workorder_recurrence_description}
                </div>
            ):( 
                <div/>
            )}
        </td>
        <td onClick={handleNavigation} className="bg-slate-100 dark:bg-slate-800 cursor-pointer group-hover:bg-slate-200 group-hover:dark:bg-slate-950 text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-3 min-w-[200px] truncate overflow-hidden text-ellipsis">
            {(order.created_by_user && Object.keys(order.created_by_user).length > 0) && (
                <div className='flex items-center'>
                    <div className='mr-3'>
                        <Avatar
                            sx={{ 
                                bgcolor: order.created_by_user.user_color? order.created_by_user.user_color : '' , 
                                fontSize:'12px',
                                width: 32, 
                                height: 32,
                            }}
                            style={{
                                background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${order.created_by_user.user_color? order.created_by_user.user_color:''} 100%)`,
                            }}
                            alt={order.created_by_user.user_first_name ? order.created_by_user.user_first_name : '' }
                            src={order.created_by_user.user_image_url ? order.created_by_user.user_image_url : '/broken-image.jpg' }
                        />
                    </div>
                    <div className='flex flex-col '>
                        <span className='text-sm'>
                            {order.created_by_user.user_first_name ? order.created_by_user.user_first_name : '' } {' '} {order.created_by_user.user_last_name ? order.created_by_user.user_last_name : '' }
                        </span>
                        <span className='text-gray-500 dark:text-gray-400 text-xs'>
                        {order.created_by_user.user_username_public ? order.created_by_user.user_username_public : order.created_by_user.user_username_public }
                        </span>

                    </div>

                </div>
            )}
            
        </td>
        <td onClick={handleNavigation} className="bg-slate-100 dark:bg-slate-800 cursor-pointer group-hover:bg-slate-200 group-hover:dark:bg-slate-950 text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-3 truncate overflow-hidden text-ellipsis text-center">
            {formatRelativeTime(order.workorder_created_at)}
        </td>
        <td onClick={handleNavigation} className="bg-slate-100 dark:bg-slate-800 cursor-pointer group-hover:bg-slate-200 group-hover:dark:bg-slate-950 text-sm font-light text-gray-600 dark:text-gray-200 h-6 px-2 py-3 truncate overflow-hidden text-ellipsis text-center">
            {formatRelativeTime(order.workorder_updated_at)}
        </td>
        <td className="rounded-r-xl bg-slate-100 dark:bg-slate-800 group-hover:bg-slate-200 group-hover:dark:bg-slate-950 text-sm relative flex justify-center font-light text-gray-600 dark:text-gray-200 px-4 py-4 ">
            <Listbox value={selectedStatus} onChange={handleStatusChange}>
                {({ open }) => (
                <div className=" ">
                    <ListboxButton
                    className={`relative flex w-[140px] justify-center items-center py-1.5 px-1 border-0 rounded-full relative ${selectedStatus.color}`}
                    >
                            <span className='mr-1'>{selectedStatus.icon}</span>
                            <span className='truncate'>{selectedStatus.workorder_status_name}</span>
                    <ChevronDownIcon
                        className={`absolute right-1.5 top-2 w-5 h-5  ${selectedStatus.workorder_status_name == 'Open' ? 'text-white':'text-gray-500'}  transition-transform duration-300 ${
                        open ? 'rotate-180' : 'rotate-0'
                        }`}
                    />
                    {loadingStatus &&
                        <div >
                            <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                    }
                    </ListboxButton>
                    <ListboxOptions className="absolute inset-x-0 max-w-[200px] mx-auto bg-white shadow-iotflows-lg dark:bg-slate-700 z-50  rounded-xl space-y-3 shadow-lg mt-1 p-2">
                    {/* className='absolute top-10 inset-x-0 mx-0 md:mx-0 lg:mx-0 2xl:mx-10 bg-white shadow-iotflows-lg dark:bg-slate-700/95 z-50 px-2 py-4 rounded-xl space-y-3' */}
                    {statusOptions.map((status) => (
                        <ListboxOption
                        key={status.workorder_status_name}
                        value={status}
                        className={({ active }) =>
                            `w-[140px] cursor-pointer flex justify-center  items-center m-1 py-1.5 px-2 mx-auto border-0 rounded-full transition-transform duration-200 ${status.color} ${
                            active ? '-translate-y-0.5 ' : ''
                            }`
                        }
                        >
                        <div className='mr-1'>
                            {status.icon}
                        </div>
                        {status.workorder_status_name}
                        </ListboxOption>
                    ))}
                    </ListboxOptions>
                </div>
                )}
            </Listbox>
        </td>
    </tr>
  )
}

export default WorkOrderTableRow

const AssignedCell = (order) => {

    let membersList = []
    if(order){
        if(order.assigned_to_teams && Object.keys(order.assigned_to_teams).length > 0){
            order.assigned_to_teams.map(team => {
                membersList.push({
                    user_color: team.user_color ? team.user_color:'',
                    name: team.team_name ? team.team_name:'',
                    image_url: '/broken-image.jpg',
                })
            })
        }
        if(order.assigned_to_users && Object.keys(order.assigned_to_users).length > 0){
            order.assigned_to_users.map(user => {
                membersList.push({
                    user_color: user.user_color ? user.user_color:'',
                    name: user.user_first_name ? user.user_first_name:'',
                    image_url: user.user_image_url ? user.user_image_url : '/broken-image.jpg',
                })
            })
        }
            return(
                <div className='flex w-[90px]'>
                    <AvatarGroup max={4} spacing={5} sx={{ '& .MuiAvatar-root': { border: '0px solid rgba(203, 213, 225, 0.1)', width:32, height:32, fontSize:'16px' } }} >
                        {membersList.map((member,index) =>(
                            <Avatar
                                key={`avatar-group-${index}`}
                                sx={{ 
                                    bgcolor: member.user_color? member.user_color : '' , 
                                    fontSize:'12px',
                                    width: 32, 
                                    height: 32,
                                }}
                                style={{
                                    background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${member.user_color? member.user_color:''} 100%)`,
                                }}
                                alt={member.name}
                                src={member.image_url ? member.image_url :'/broken-image.jpg'}
                            />
                        ))}
                    </AvatarGroup>
    
                </div>
            )

        
    }

}

