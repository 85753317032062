import { useEffect, useState } from 'react'
import HighchartsStock from 'highcharts/highstock';
// import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from "highcharts-react-official";
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';

highchartsMore(HighchartsStock);



function SimpleLineChartNoTime({values, chartHeight}) {
    const isDark = useRecoilValue(darkModeState);
    const [chartOptions, setChartOptions] = useState(null)

      useEffect(() => {
        setChartOptions({
          chart: {
            zoomType: 'x',
            height: chartHeight,
            backgroundColor: 'transparent',
        },
        time: {
          useUTC: false
        },
        credits : { enabled : false},
        title: {
            text: null
          },
        tooltip: {
            split: false,
            shared: true,
            formatter: function() {
              return 'Vibration: '+ this.y 
            }
          },
        xAxis: {
          enabled: false,
          labels: {
              enabled: false
          },
        },
        yAxis: {
            // title: {
            //     text: 'KPI',
            //   },
            lineWidth: 0,
            minorGridLineWidth: 0,
            lineColor: 'transparent',
            // labels: {
            //     enabled: false
            // },
            min:0,
            minorTickLength: 0,
            tickLength: 0,
            gridLineWidth: 0,
        },
        legend: { enabled: false},
        navigator : { enabled : false},
        scrollbar : { enabled : false},
        rangeSelector : { enabled : false},
        series: [{
            zoneAxis: 'x',
            name: 'Vibration: ',
            data: values,
            zIndex: 1,
            color:'rgba(147, 197, 253, 1)',
            marker: {
                // enabled: false,
                fillColor: 'white',
                lineWidth: 1,
                lineColor: 'grey',
                // enabled: true,
            },
        }],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                // chartOptions: {
                //     yAxis: {
                //         labels: {
                //             formatter: function () {
                //                 return this.value.charAt(0);
                //             }
                //         }
                //     }
                // }
            }]
        }
        })

      }, [values])


  return (
    <div>
        <HighchartsReact
            highcharts={HighchartsStock}
            constructorType={'stockChart'}
            options={chartOptions}
        />
    </div>
  )
}

export default SimpleLineChartNoTime