import Nav from "./Nav";


const AssetsLayout = ({ children }) => {
    return (
      <div className='flex flex-col'>
        {/* Group 1 Header */}
        <Nav  />
        {children}
      </div>
    );
  };
  
  export default AssetsLayout;