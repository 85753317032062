import React, { useEffect, useRef, useState } from 'react'
import { LightTooltip } from '../../styles/Tooltip'
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { Avatar } from '@mui/material';
import { globalSelectedOrganization } from '../../atoms/orgStates';
import OptimizeImage from '../../components/OptimizeImage';
import { getData, putData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { motion } from "framer-motion";
import toast from 'react-hot-toast';

function AssetComponent({selectedWorkOrder}) {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    const [isEditing, setIsEditing] = useState(false)   
    const isDark = useRecoilValue(darkModeState); 
    const [loading, setLoading] = useState(false);
    const [workOrderAsset, setWorkOrderAsset] = useState(null)
    const [selectedAsset, setSelectedAsset] = useState(null)
    const assetDropdownRef = useRef(null)
    const [isAssetDropdownOpen, setIsAssetDropdownOpen] = useState(false)
    const [searchAssetText, setSearchAssetText] = useState("")
    const [assetList, setAssetList] = useState([])
    const [filteredAssetList, setFilteredAssetList] = useState([])
    
    useEffect(() => {
        if(selectedWorkOrder && selectedWorkOrder.workorder_uuid){
            if(selectedWorkOrder.workorder_asset && Object.keys(selectedWorkOrder.workorder_asset).length > 0){
                setWorkOrderAsset(selectedWorkOrder.workorder_asset)
                setSelectedAsset(selectedWorkOrder.workorder_asset)
                
            }else{
                setWorkOrderAsset(null)
                setSelectedAsset(null)
            }
        }

    },[selectedWorkOrder])

    useEffect(() => {
        getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/organization_assets' )
        .then(async response => {
            // JSON data parsed by response.json() call
            if(response.ok ){
            const data = await response.json();
                if(data && data.data){
                    setAssetList(data.data)
                    setFilteredAssetList(data.data)
                }
            }
        })
    },[])

    useEffect(() => {
        if(searchAssetText  && searchAssetText.length > 0){
            const filtered = assetList.filter(asset => {
                // Check if asset is not null or undefined
                if (!asset) return false;
          
                // Check each property before calling toLowerCase()
                const customName = asset.asset_custom_name ?? '';
                const customIdentifier = asset.asset_custom_identifier ?? '';
                const machineName = asset.machine_name ?? '';
                const machineIdentifier = asset.machine_identifier ?? '';
          
                // Convert to lowercase and check for inclusion
                return (
                  customName.toLowerCase().includes(searchAssetText.toLowerCase()) ||
                  customIdentifier.toLowerCase().includes(searchAssetText.toLowerCase()) ||
                  machineName.toLowerCase().includes(searchAssetText.toLowerCase()) ||
                  machineIdentifier.toLowerCase().includes(searchAssetText.toLowerCase())
                );
              });
          setFilteredAssetList(filtered)
      
      
        }else{
          setFilteredAssetList(assetList)
        }
    },[searchAssetText])

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (assetDropdownRef.current && !assetDropdownRef.current.contains(event.target)) {
            setIsAssetDropdownOpen(false)
          }
        }
    
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
          document.removeEventListener('mousedown', handleClickOutside)
        }
      }, [])

    const handleCancel = () => {
        setIsEditing(false)
        if(selectedWorkOrder.workorder_priority && Object.keys(selectedWorkOrder.workorder_priority).length > 0){
            setWorkOrderAsset(selectedWorkOrder.workorder_asset)
            setSelectedAsset(selectedWorkOrder.workorder_asset)
            
        }else{
            setWorkOrderAsset(null)
            setSelectedAsset(null)
        }
    }

    const handleSelectAsset = (asset) => {
        setSelectedAsset(asset)
        // setSearchAssetText(asset.label)
        setIsAssetDropdownOpen(false)
      }


    const handleUpdate = () => {

        if(!loading){
            let assetUuid = ''
            if(selectedAsset && selectedAsset.asset_uuid){
            assetUuid = selectedAsset.asset_uuid
            }
            setLoading(true)
            setIsEditing(false)
            console.log('selectedAsset', selectedAsset)
            setWorkOrderAsset(selectedAsset)
            putData(apiLink + '/v1/workorders/' + selectedWorkOrder.workorder_uuid , 
            { "workorder_asset_uuid": assetUuid})
            .then(async response => {
                const data = await response.json();
                if(response.ok){
                    setLoading(false)
                    console.log('data.data SELECTRED', data.data)
                    if(data && data.data && data.data[0]){
                        // setWorkOrderAsset(selectedAsset)
                    }else{
                        toast.error('Issues updating priority')
                        setWorkOrderAsset(selectedWorkOrder.workorder_asset)
                        setSelectedAsset(selectedWorkOrder.workorder_asset)
                    }
                }
                else{
                    setWorkOrderAsset(selectedWorkOrder.workorder_asset)
                    setSelectedAsset(selectedWorkOrder.workorder_asset)
                    setLoading(false)
                    try{
                        toast.error(data.message)
                    }catch (e){
                        toast.error(e)
                    }
                }
            })
        }

    }

  return (
    <div className='flex flex-col group' ref={assetDropdownRef} >
        <div className='flex text-gray-800 dark:text-white mb-2'>
                <span>Asset</span>
                {isEditing ?(
                    <div className='flex ml-1 space-x-2'>
                        <div 
                            className={`shadow rounded-md ml-1 py-1 px-2 border-[1px] border-blue-600 text-xs bg-blue-600 hover:bg-blue-700 hover:border-blue-700 text-white cursor-pointer`} 
                            onClick={handleUpdate}
                        >
                            Save
                        </div>
                        <div 
                            className={`shadow rounded-md py-1 px-2 border-[1px] text-xs ${isDark?'text-white hover:text-gray-200 border-gray-400':'text-gray-600 hover:text-gray-900 border-gray-300'} cursor-pointer flex ml-2`} 
                            onClick={handleCancel}
                        >
                            Cancel
                        </div>
                    </div>
                ):(
                    <LightTooltip title='Edit Asset'>
                        <button 
                            className={`text-gray-500 dark:text-gray-400 hover:text-gray-400 dark:hover:text-gray-500 ml-2 opacity-0 ease-in-out duration-300 group-hover:opacity-100`}
                            onClick={() => setIsEditing(true)}
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                            </svg>

                        </button>
                    </LightTooltip>

                )}
            </div>
            {isEditing ? (
                <div className={`relative w-full `}>
                    <div className={`relative flex w-full`}>
                        <input
                            type="text"
                            value={searchAssetText}
                            placeholder={'Search your asset'}
                            onClick={() => setIsAssetDropdownOpen(true)}
                            onChange={(e) => {
                            setSearchAssetText(e.target.value)
                            setIsAssetDropdownOpen(true)
                            }}
                            className={`w-full px-4 py-2 text-sm rounded-lg border-0 shadow-iotflows-lg
                            ${isDark 
                                ? 'bg-slate-600 text-gray-200 border-slate-500 placeholder-gray-400' 
                                : 'bg-white text-gray-700 border-gray-300 placeholder-gray-500'
                            } 
                            focus:outline-none focus:ring-2 focus:ring-blue-500`}
                        />
                    
                        <button 
                            onClick={() => setIsAssetDropdownOpen(!isAssetDropdownOpen)}
                            className={`absolute right-2 top-1/2 -translate-y-1/2 ${isAssetDropdownOpen ? '':'rotate-180'}`}
                        >
                            <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className={`h-5 w-5 ${isDark ? 'text-gray-200' : 'text-gray-700'}`} 
                            viewBox="0 0 20 20" 
                            fill="currentColor"
                            >
                            <path 
                                fillRule="evenodd" 
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" 
                                clipRule="evenodd" 
                            />
                            </svg>
                        </button>
                    </div>
                    {!isAssetDropdownOpen && selectedAsset && Object.keys(selectedAsset).length > 0 && (
                        <div className={`flex space-x-2 rounded-md ${isDark?'bg-slate-900':'bg-slate-100'} max-w-[250px] w-fit px-2 py-1 mt-2 truncate`}>
                        <OptimizeImage 
                            alt={selectedAsset.asset_custom_name ? selectedAsset.asset_custom_name : selectedAsset.machine_name} 
                            source={selectedAsset.asset_custom_picture_url ? selectedAsset.asset_custom_picture_url : selectedAsset.machine_picture_url} 
                            size={'medium'} 
                        />
                        <div className='flex flex-col truncate'>
                            <div className={`text-left truncate text-sm ${isDark ? 'text-white':'text-gray-600'} font-normal`}>{selectedAsset.asset_custom_name  ? selectedAsset.asset_custom_name :selectedAsset.machine_name}</div>
                            <div className={`text-left truncate text-xs ${isDark ? 'text-gray-200':'text-gray-400'} font-medium`}>{selectedAsset.asset_custom_identifier  ? selectedAsset.asset_custom_identifier :selectedAsset.asset_uuid}</div>
                        </div>
                        <motion.button  
                            whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}  
                            className={`ml-2 ${isDark?'text-gray-400':'text-gray-600'} `} 
                            onClick={(e) => {
                            setSelectedAsset([])
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </motion.button>
                        </div>
                    )}
                    {isAssetDropdownOpen && (
                        <div 
                            className={`absolute z-10 w-full mt-1 rounded-lg shadow-lg border-0
                            ${isDark 
                                ? 'bg-slate-600 border-slate-500' 
                                : 'bg-white border-gray-200'
                            } `}
                        >
                            {Object.keys(filteredAssetList).length > 0 ? (
                            <ul 
                                className={`max-h-60 overflow-y-auto py-1 text-sm 
                                ${isDark 
                                    ? 'text-gray-200' 
                                    : 'text-gray-700'
                                }`}
                            >
                                {filteredAssetList.map((asset) => (
                                <li 
                                    key={asset.asset_uuid}
                                    onClick={() => handleSelectAsset(asset)}
                                    className={`flex px-4 py-2 cursor-pointer 
                                    ${isDark 
                                        ? 'hover:bg-slate-500' 
                                        : 'hover:bg-gray-100'
                                    } 
                                    ${selectedAsset === asset.machine_name 
                                        ? (isDark ? 'bg-slate-500' : 'bg-gray-100') 
                                        : ''
                                    }`}
                                >
                                    <div className='flex space-x-2'>
                                    <OptimizeImage 
                                        alt={asset.asset_custom_name ? asset.asset_custom_name : asset.machine_name} 
                                        source={asset.asset_custom_picture_url ? asset.asset_custom_picture_url : asset.machine_picture_url} 
                                        size={'medium'} 
                                    />
                                    <div className='flex flex-col'>
                                        <div className={`text-left truncate text-sm ${isDark ? 'text-white':'text-gray-600'} font-normal`}>{asset.asset_custom_name  ? asset.asset_custom_name :asset.machine_name}</div>
                                        <div className={`text-left truncate text-xs ${isDark ? 'text-gray-200':'text-gray-400'} font-medium`}>{asset.asset_custom_identifier  ? asset.asset_custom_identifier :asset.asset_uuid}</div>
                                    </div>
                                    </div>
                                </li>
                                ))}
                            </ul>
                            ) : (
                            <div 
                                className={`px-4 py-2 text-sm text-center 
                                ${isDark ? 'text-gray-400' : 'text-gray-500'}`}
                            >
                                No assets found
                            </div>
                            )}
                        </div>
                    )}
                </div>

            ):(
                <div>
                    {workOrderAsset && Object.keys(workOrderAsset).length > 0 ? (
                        <div className='flex items-center'>
                            <div className='mr-3'>
                                <Avatar
                                    sx={{ 
                                        // bgcolor: order.user_color? member.user_color : '' , 
                                        // fontSize:'12px',s
                                        width: 40, 
                                        height: 40,
                                    }}
                                    // style={{
                                    //     background: `linear-gradient(180deg, rgba(255,255,255,1) -60%, ${member.user_color? member.user_color:''} 100%)`,
                                    // }}
                                    alt={workOrderAsset.asset_custom_name ? workOrderAsset.asset_custom_name : workOrderAsset.machine_name }
                                    src={workOrderAsset.asset_custom_picture_url ? workOrderAsset.asset_custom_picture_url : workOrderAsset.machine_picture_url }
                                />
                            </div>
                            <div className='flex flex-col '>
                                <span className='text-lg text-gray-600 dark:text-gray-300'>
                                    {workOrderAsset.asset_custom_name ? workOrderAsset.asset_custom_name : workOrderAsset.machine_name }
                                </span>
                                <span className='text-gray-500 dark:text-gray-400 text-sm'>
                                {workOrderAsset.asset_custom_identifier ? workOrderAsset.asset_custom_identifier : workOrderAsset.machine_identifier }
                                </span>
                            </div>
                        </div>

                    ):(
                        <button
                            onClick={() => setIsEditing(true)} 
                            className='text-blue-600 dark:text-blue-400 italic text-sm'>
                            Add asset
                        </button>
                    )}
                </div>
            )}
    </div>
  )
}

export default AssetComponent